import { Box, Grid } from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";
import FormSection from "components_v2/Inputs/FormSection/FormSection";
import { NumberInput } from "components_v2/Inputs/NumberInput/NumberInput";
import SupplierSelect from "components_v2/Inputs/SupplierSelect/SupplierSelect";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import ToggleSwitchButton from "components_v2/Inputs/ToggleSwitchButton/ToggleSwitchButton";
import TotalErrors from "components_v2/Inputs/TotalErrors/TotalErrors";
import VehicleClassPicker from "components_v2/Inputs/VehicleClassPicker/VehicleClassPicker";
import ListModal from "components_v2/ListModal/ListModal";
import SelectActionButton from "components_v2/SelectActionButton/SelectActionButton";
import Typography from "components_v2/Typography/Typography";
import DocumentUpload from "components_v2/Upload/UploadDocument";
import UploadImage from "components_v2/Upload/UploadImage/UploadImage";
import GetDriversByEvent from "gql/Drivers/GetByEvent";
import GetDriversBySupplier from "gql/Drivers/GetBySupplier";
import RadioGroup from "components_v2/Inputs/RadioGroupInput/RadioGroupInput";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { createLocationWithState } from "utils/routerHelpers";
import EmailDisplay from "../../../EmailDisplay/EmailDisplay";
import withFormState from "containers/Wrappers/withFormState";

const driverColumns = [
  {
    field: "firstName",
    headerName: "Name",
    width: 2,
    render: row => `${row.firstName} ${row.lastName}`,
    titleWithText: true
  },
  {
    field: "email",
    headerName: "Email",
    width: 4,
    titleWithText: true,
    render: row => <EmailDisplay email={row.email}></EmailDisplay>
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true
  }
];

const driverColumnsTiny = [
  {
    field: "firstName",
    headerName: "Name",
    width: 3,
    render: row => `${row.firstName} ${row.lastName}`,
    titleWithText: true
  },
  {
    field: "email",
    headerName: "Email",
    width: 6,
    titleWithText: true,
    render: row => <EmailDisplay email={row.email}></EmailDisplay>
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true
  }
];

const AddVehicleForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  classes,
  history,
  location,
  match,
  transferservices,
  setFormState
}) => {
  const handleUpdateDriver = newValues => {
    setFieldValue("drivers", newValues);
  };

  const { projectId, supplierId } = match.params;

  const getDriversQuery = supplierId ? GetDriversBySupplier : GetDriversByEvent;

  const supplierPriceFields = transferservices
    .filter(service => service.enabled)
    .map(({ id, name }) => (
      <Box key={name} className={classes.pricesItem}>
        <NumberInput
          name={id}
          label={name}
          value={values[id]}
          onChange={handleChange}
          step="0.01"
          isDisabled={values.pricingType === "default"}
          icon={() => <AttachMoney className={classes.moneyAdornment} />}
          adornmentPosition="start"
        />
      </Box>
    ));

  const handleSupplierChange = ({ target }) => {
    const { name, value } = target;

    setFieldValue(name, value?.id);
  };

  const handleNewDriverClick = () => {
    const id = projectId || supplierId;
    const type = projectId ? "project" : "supplier";
    const { pathname, state } = createLocationWithState(
      `/${type}/${id}/driver/new`,
      location,
      values,
      null,
      true,
      "drivers"
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } });
  };

  const setPreselectedVehicleClassValue = value => {
    setFieldValue("class", value);
  };

  useEffect(() => {
    setFieldValue(
      "maxPassengers",
      values.class?.maxPassengers || values.maxPassengers || ""
    );
    setFieldValue(
      "maxLuggage",
      values.class?.maxLuggage || values.maxLuggage || ""
    );
  }, [values.class?.id]);

  return (
    <>
      <FormSection defaultExpanded title="Enter the vehicle details">
        <Grid
          alignItems="flex-end"
          className={classes.gridContainer}
          container
          spacing={3}
        >
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              customVariant="bodySmallRegular"
              style={{ color: "#3C3C3C", marginBottom: "8px" }}
            >
              Select the supplier they belong to
            </Typography>
            <SupplierSelect
              value={values.supplier}
              label="Supplier *"
              placeholder="Select supplier"
              name="supplier"
              onChange={handleSupplierChange}
              error={touched.supplier && errors.supplier}
              values={values}
              returnField="supplier"
            />
          </Grid>
          <Grid className={classes.toggleRow} item md={4} sm={6} xs={12}>
            <ToggleSwitchButton
              name="enabled"
              label="Enabled"
              value={values.enabled}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="vehicleNumber"
              label="Vehicle identifier"
              placeholder="Vehicle identifier"
              value={values.vehicleNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="plateNumber"
              label="Plate number *"
              placeholder="Plate number"
              value={values.plateNumber}
              onChange={handleChange}
              error={touched.plateNumber && errors.plateNumber}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="make"
              label="Make *"
              placeholder="Enter make"
              value={values.make}
              onChange={handleChange}
              error={touched.make && errors.make}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="model"
              label="Model *"
              placeholder="Enter model"
              value={values.model}
              onChange={handleChange}
              error={touched.model && errors.model}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <VehicleClassPicker
              name="class"
              label="Vehicle class *"
              placeholder="Select a vehicle type"
              value={values.class}
              onChange={handleChange}
              projectId={projectId}
              error={touched.class && errors.class}
              setPreselectedValue={setPreselectedVehicleClassValue}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="year"
              label="Year *"
              placeholder="Enter year"
              value={values.year}
              onChange={handleChange}
              error={touched.year && errors.year}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="maxPassengers"
              label="Passenger Capacity *"
              placeholder="Enter passenger capacity"
              value={values.maxPassengers}
              onChange={handleChange}
              error={touched.maxPassengers && errors.maxPassengers}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="maxLuggage"
              label="Luggage Capacity *"
              placeholder="Enter luggage capacity"
              value={values.maxLuggage}
              onChange={handleChange}
              error={touched.maxLuggage && errors.maxLuggage}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="color"
              label="Color *"
              placeholder="Enter color"
              value={values.color}
              onChange={handleChange}
              error={touched.color && errors.color}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid className={classes.uploadPhotoRow} item md={4} sm={6} xs={12}>
            <Typography
              customVariant="bodySmallRegular"
              style={{ marginBottom: 8, color: "#3C3C3C" }}
            >
              Vehicle photo
            </Typography>
            <UploadImage
              placeholder="Add a photo of vehicle"
              defaultPreviewImage={values.imageUrl}
              onComplete={data => {
                setFieldValue("imageUrl", data.url);
              }}
            />
          </Grid>
          <Grid className={classes.uploadPhotoRow} item md={4} sm={6} xs={12}>
            <Typography
              customVariant="bodySmallRegular"
              style={{ marginBottom: 8, color: "#3C3C3C" }}
            >
              Vehicle interior photo
            </Typography>
            <UploadImage
              placeholder="Add a photo of interior"
              defaultPreviewImage={values.interiorImageUrl}
              onComplete={data => {
                setFieldValue("interiorImageUrl", data.url);
              }}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Enter vehicle pricing">
        <Box style={{ marginBottom: 40 }}>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginBottom: 8, color: "#3C3C3C" }}
          >
            Select pricing type
          </Typography>
          <RadioGroup
            noMargin
            onChange={handleChange}
            name="pricingType"
            value={values.pricingType}
            options={[
              { value: "default", label: "Default Project Pricing" },
              { value: "custom", label: "Custom Vehicle Pricing" }
            ]}
          />
        </Box>

        <Box className={classes.pricesContainer}>{supplierPriceFields}</Box>
      </FormSection>
      <FormSection title="Select a driver">
        <Typography
          customVariant="bodySmallRegular"
          style={{ marginBottom: 8, color: "#3C3C3C" }}
        >
          Link to a driver
        </Typography>

        <ListModal
          title=""
          searchPlaceholder="Search for a driver"
          query={getDriversQuery}
          singleSelection
          dataSelector={data => data?.users || []}
          queryOptions={{
            variables: { id: projectId || supplierId },
            fetchPolicy: "no-cache"
          }}
          mainAction={{
            label: "Done",
            onClick: items => {
              setFieldValue("drivers", items);
            }
          }}
          secondaryAction={{
            label: "Create a new driver",
            onClick: handleNewDriverClick
          }}
          handleUpdateForm={handleUpdateDriver}
          incomingItems={values.drivers.map(d => d.id)}
          columns={driverColumns}
          renderSelectionColumns={driverColumnsTiny}
          onModalClick={toggleModal => (
            <SelectActionButton onClick={toggleModal}>
              Select driver
            </SelectActionButton>
          )}
        />
      </FormSection>

      <FormSection title="Upload any supporting documents">
        <DocumentUpload
          documents={values.documents}
          onDeleteCallback={document => {
            if (document.identifier) setFieldValue(document.identifier, null);
            else {
              const newDocuments = values.documents.filter(
                loadedDocument => loadedDocument.id !== document.id
              );
              setFieldValue("documents", newDocuments);
            }
          }}
          onComplete={async document => {
            setFieldValue(document.identifier, document);
          }}
        />
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default withFormState(withRouter(AddVehicleForm));
