import { makeStyles } from "@material-ui/core/styles";

import { appointmentBaseStyles, otherAppointmentsBaseStyles } from "../helpers";

export default makeStyles(() => ({
  appointment: {
    ...appointmentBaseStyles,
    cursor: "pointer",
  },
  otherAppointments: {
    ...otherAppointmentsBaseStyles,
  },
  dropdown: {
    position: "absolute",
    background: "#fff",
    zIndex: 10,
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.15)",
  },
  viewLessButton: {
    background: "rgba(64, 1, 197, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.02)",
    borderRadius: 90,
    width: 88,
    padding: 8,
    margin: "16px 8px",
    "&:hover": {
      background: "rgba(64, 1, 197, 0.05)",
    },
    "&:hover span": {
      color: "#000",
    },
    "& span": {
      color: "#000",
    },
  },
  timeTableCellContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    "&>button": {
      visibility: "hidden",
      opacity: 0,
      width: "100%",
      height: "100%",
      padding: 0,
    },
  },
  timeTableCell: {
    height: 72,
    position: "relative",
    "&:hover>div>button": {
      visibility: "visible",
      opacity: 1,
    },
  },
  appointmentsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",

    "&:hover": {
      "& + button": {
        visibility: "hidden !important",
        opacity: "0 !important",
      },
    },
  },
}));
