import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { UserBig } from '../Icons/Icons';

const useStyles = makeStyles(({  typography }) => ({
  iconButton: {
    fontFamily: typography.fontFamily,
    fontSize: '12px',
    fill: 'gray',
    marginLeft: '2px',
    marginRight: '0px',
    paddingLeft: 0,
    paddingRight: 0,

    '&.active': {
      color: '#4001C5',
      backgroundColor: 'transparent',
      borderRadius: '0px',
      boxShadow: '0 3px #4001C5',

      '& svg': {
        fill: '#4001C5',
      },
    },
    '&:hover': {
      color: '#4001C5',
      backgroundColor: 'transparent',
      borderRadius: '0px',
      boxShadow: '0 3px #4001C5',

      '& svg': {
        fill: '#4001C5',
      },
    },
  },
  iconButtonText: {
    paddingLeft: '10px',
    color: 'rgba(0, 0, 0, 0.8)',
    '&:hover': {
      color: '#4001C5',
      fill: '#4001C5',
    },
    '&.active': {
      color: '#4001C5',
    },
  },
}));

const AccountTool = ({ onClick, currentTool }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classNames(classes.iconButton, {
        active: currentTool === 'account',
      })}
      disableRipple
      onClick={onClick}
    >
      <UserBig />
      <span
        className={classNames(classes.iconButtonText, {
          active: currentTool === 'account',
        })}
      >
        My account
      </span>
    </IconButton>
  );
};

export default AccountTool;
