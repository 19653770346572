import gql from "graphql-tag";

export default gql`
  query GetAllShuttleSchedules($id: ID, $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String) {
    storedlocations(where: { event: $id }) {
      id
      identifier
      name
      type
      address
      lat
      long
    }
    shuttleschedulesConnection(where: { event: $id 
      _or: [
        { name_contains: $filterKey }
      ]  
    }
    limit: $pageSize
    start: $startIndex
    sort: $sort
    ) {
      aggregate {
        count
      }
      values{
        id
        name
        startDate
        endDate
        vehicles {
          id
        }
        shuttlescheduleitems {
          id
          when
          name
          time
          location {
            id
          }
        }
      }
    }
    vehicles {
      id
      make
      model
      year
      vehicleNumber
      maxPassengers
      type
      enabled
      notes
      plateNumber
      status
      authorizedDrivers {
        id
        email
        username
        identifier
      }
      assignedDrivers {
        id
        notes
        start
        stop
        wholeEvent
        driver {
          id
          identifier
        }
      }
    }
  }
`;
