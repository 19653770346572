import React from "react";
import Divider from "@material-ui/core/Divider";
import MessagesTool from "./MessagesTool";
import AlertsTool from "./AlertsTool";
import AccountTool from "./AccountTool";
import { useStyles } from "./Tools.styles";

const Tools = ({
  messagesCount,
  notificationsCount,
  onMessagesToolClick,
  onAlertsToolClick,
  onAccountToolClick,
  currentTool,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <MessagesTool
        messagesCount={messagesCount}
        onClick={onMessagesToolClick}
        currentTool={currentTool}
      />
      <AlertsTool
        notificationsCount={notificationsCount}
        onClick={onAlertsToolClick}
        currentTool={currentTool}
      />
      <Divider orientation="vertical" className={classes.divider} />

      <AccountTool onClick={onAccountToolClick} currentTool={currentTool} />
    </div>
  );
};

export default Tools;
