import React from 'react';
import { withResizeDetector } from 'react-resize-detector';

const AutoSizer = ({ children, width, height, noPadding }) => (
  <div
    style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      paddingBottom: noPadding ? 0 : 24,
    }}
  >
    {React.cloneElement(children, { width, height })}
  </div>
);

export default withResizeDetector(AutoSizer);
