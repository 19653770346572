import React, { useEffect, useState } from "react";
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { Mail } from "../../Icons/Icons";
import TextInput from "../../Inputs/TextInput/TextInput";
import _ from "lodash";
const ResendEmailConfirmationModal = ({ data, onClickCancel, onClickSend }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const isNoEmail = _.includes(data?.record?.passenger?.email, "@noemail");
    if (isNoEmail) {
      setEmail("");
    } else {
      setEmail(data?.record?.passenger?.email);
    }
  }, [data]);

  return (
    <Modal size="sm" pageView={!!data}>
      <SuccessCard
        icon={
          <Mail
            style={{
              color: "#FFFF",
              fill: "white",
              width: 40,
              height: 40,
              filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))"
            }}
          />
        }
        mainAction={{
          text: "Send",
          isDisabled: !email,
          onClick: () => {
            onClickSend({
              email,
              id: data?.record?.passengerScheduleId,
              data: {
                event: data?.record?.event.id,
                from: data?.record?.from.id,
                to: data?.record?.to.id
              }
            });
          }
        }}
        secondaryAction={{
          text: "Cancel",
          onClick: onClickCancel
        }}
        headerSize="h4Bold"
        headerText={`Who do you want to send this confirmation to ?`}
      >
        <TextInput
          onChange={event => setEmail(event.target.value)}
          value={email}
          placeholder="Enter the email"
        />
      </SuccessCard>
    </Modal>
  );
};

export default ResendEmailConfirmationModal;
