import gql from 'graphql-tag';

export default gql`
mutation ResendEmailConfirmation(
  $id: ID
  $email: String
  $data: PassengerscheduleInput
) {
  resendEmail(id: $id, email: $email, data: $data) {
    event {
        id
      }
  }
}
`;