import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { Calendar } from "../../Icons/Icons";
import InputLabel from "../InputLabel/InputLabel";
import { useStyles, useCustomStyles } from "./DatepickerInput.styles";
import InputError from "../InputError/InputError";
import "./DatepickerInput.css";

const DatepickerInput = ({
  name,
  value,
  onChange,
  label,
  placeholder,
  error,
  noMargin,
  onClick,
  readOnly
}) => {
  const Icon = Calendar;
  const variant = "outlined";
  const customClasses = useCustomStyles();

  const classes = useStyles();

  return (
    <div
      onClick={(event) => onClick && onClick(event)}
      className={customClasses.inputWrapper}
      style={{ marginBottom: noMargin ? 0 : undefined }}
    >
      <InputLabel label={label} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          readOnly={readOnly}
          autoOk={true}
          disableToolbar
          name={name}
          ampm={false}
          inputVariant={variant}
          variant="inline"
          format="YYYY-MM-DD"
          fullWidth
          value={value}
          placeholder={placeholder}
          onChange={
            // TODO: NEED TO REVISIT
            (event) =>
              onChange({
                persist: () => { },
                target: {
                  type: "change",
                  name,
                  value: moment(event).format(),
                },
              })
          }
          PopoverProps={{
            transformOrigin: { vertical: "top", horizontal: "center" },
          }}
          InputProps={{
            classes,
            disableUnderline: true,
            endAdornment: Icon && (
              <InputAdornment position="end">
                <Icon />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
      <InputError error={error} />
    </div>
  );
};

export default DatepickerInput;
