import React from "react";
import DynamicList from "components_v2/List/DynamicList";
import { MenuFolder } from "components_v2/Icons/Icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../App.helper";

const MainContainer = ({ listConfig, data, numRows, setModuleId, setModuleName, loading,handleListConfigChange }) => {
  
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <DynamicList
      
      noResultsText="No modules"
      isMobileView={isMobileView}
      showHeader
      data={data}
      loading={loading}
      title="Modules"
      enableFreezeHeader
      headerFreezeLeftContent="Locations"
      onListConfigChange={handleListConfigChange}
      numRows={numRows}
      listConfig={listConfig}
      hideActions
      actions={{
        rowClick: (row) => {
          setModuleName(row.name);
          setModuleId(row.id);
        },
      }}
      columns={[
        {
          field: "name",
          headerName: "Module Name",
          width: 3,
          render: (data) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <MenuFolder /> <span style={{ marginLeft: 16 }}>{data.name}</span>{" "}
            </div>
          ),
        },
        {
          field: "documentsCount",
          headerName: "Number of files",
          width: 3,
          render: (data) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              {data.documentsCount} file(s)
            </div>
          ),
        },
      ]}
    />
  );
};

export default MainContainer;
