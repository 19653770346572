import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AlertFill } from '../../Icons/Icons';

const useStyles = makeStyles(() => ({
  inputError: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#C50202',

    '& > svg': {
      marginRight: '5px',
    },
  },
}));

const InputError = ({ error }) => {
  const classes = useStyles();

  return (
    (error && (
      <div className={classes.inputError}>
        <AlertFill /> {error}
      </div>
    )) || <></>
  );
};

InputError.propTypes = {
  error: PropTypes.string,
};

export default InputError;
