import * as Yup from 'yup';

import {
  getUploadDocuments,
  formatChangedFormValues,
  formatValues,
} from 'utils/formHelpers';
import { stringifyJSON } from 'utils/json';


export const validationSchema = Yup.object().shape({
  type: Yup.object().required('Required'),
  dateOfService: Yup.string().required('Required')
});

export const formatSubmissionValues = ({ values, driverId, initialValues, isUpdate }) => {
  const uploadedDocuments = getUploadDocuments(values);

  const formatConfig = {
    type: { name: driverId ? 'drivertrackingtype' : "vehicletrackingtype", format: (vals) => vals?.type?.id} ,
    cost: null,
    vehicle: null,
    driver: null,
    dateOfService: null,
    description: null,
    documents: {
      name: 'documents',
      format: (vals) => {
        const documents = [...(vals.documents || []), ...uploadedDocuments];
        return stringifyJSON(documents, '[]');
      },
      forceSaveOnUpdate: true,
    },
  };

  if (isUpdate) return formatChangedFormValues(values, initialValues, formatConfig);
  return formatValues(values, formatConfig);
};

export const formatIncomingData = ({ data, driverId }) => {

  return {
    type: driverId 
      ? { name: data?.drivertrackingtype?.name, id: data?.drivertrackingtype?.id }
      : { name: data?.vehicletrackingtype?.name, id: data?.vehicletrackingtype?.id },
    dateOfService: data.dateOfService,
    cost: data.cost,
    description: data.description,
    documents: data.documents || [],
  };
};