import React, { useState, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Calendar } from "../../Icons/Icons";
import InputLabel from "../InputLabel/InputLabel";
import "./DatepickerButton.css";
import Tooltip from "components_v2/Tooltip/Tooltip";
import { extraSmallMobileBreakpoint } from "../../../App.helper"
class LocalizedUtils extends MomentUtils {
  getWeekdays() {
    return ["S", "M", "T", "W", "T", "F", "S"];
  }
}

const DatepickerButton = ({
  name,
  value,
  onChange,
  label,
  menuOffset,
  tooltip,
}) => {
  const Icon = Calendar;
  const dateButtonRef = useRef();
  const theme = useTheme();

  const isXSMobileView = useMediaQuery(theme.breakpoints.down(extraSmallMobileBreakpoint));

  const variant = "outlined";
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const getAnchorPosition = (ref) => {
    if (!ref || !ref.current) return { top: 0, left: 0 };

    const { current } = ref;
    const rect = current.getBoundingClientRect();
    const top = rect.y + rect.height + 14;
    const left = rect.x - 142;

    return { top, left };
  };

  const anchorPosition = getAnchorPosition(
    dateButtonRef,
    // (menuOffset && menuOffset.top) || 50,
    0,
    menuOffset && menuOffset.left
  );

  return (
    <>
      <InputLabel label={label} />
      <MuiPickersUtilsProvider utils={LocalizedUtils}>
        <Tooltip title={tooltip} nocontainer>
          <Icon
            style={isOpen ? { fill: "#4001C5" } : {}}
            onClick={handleClick}
            ref={dateButtonRef}
          />
        </Tooltip>
        <DatePicker
          autoOk={true}
          disableToolbar
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          TextFieldComponent={() => null}
          name={name}
          ampm={false}
          inputVariant={variant}
          variant="inline"
          format="YYYY-MM-DD"
          fullWidth
          value={value}
          onChange={
            // TODO: NEED TO REVISIT
            (event) =>
              onChange({
                persist: () => { },
                target: {
                  type: "change",
                  name,
                  value: moment(event).format(),
                },
              })
          }
          PopoverProps={{
            className: `  "date-picker-wrapper" ${isXSMobileView && "date-picker-wrapper-mobile"
              }`,
            anchorEl,
            anchorReference: "anchorPosition",
            anchorPosition,
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default DatepickerButton;
