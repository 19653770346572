/* eslint-disable react/display-name */
import { Collapse } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { useQuery } from "@apollo/react-hooks";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import CustomTypography from "components_v2/Typography/Typography";
import GetAllByUser from "gql/Events/GetAllByUser";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import auth from "utils/auth";
import Skeleton from "@material-ui/lab/Skeleton";
import { mobileViewBreakpoint } from "../../App.helper";
import { setFocusOnElement } from "../../utils/helpers";
import { ChevronDown } from "../Icons/Icons";
import { mockData } from "../Views/Internal/HomeDashboard/HomeDashboard.mock";
const styles = () => ({
  root: {
    display: "inline-flex",
    cursor: "pointer",
    background: "#ffffff",
    "& header": {
      display: "flex",
      justifyContent: "space-between",
      background: "#f8fbfe",
    },

    "&:hover $title": {
      color: "rgba(64, 1, 197, 1)",
    },
  },
  expanded: {
    "& $action": {
      fill: "rgba(64, 1, 197, 1)",
      transform: "rotate(-180deg)",
    },
  },
  action: {
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    fill: "#ccc",
    transition: "all 0.3s",
  },
  title: {},
});

const StyledMenu = withStyles((theme) => ({
  paper: {
    padding: 0,

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "100%",
    },

    "& ul": {
      padding: "0 !important",
    },
  },
  list: {
    height: "auto",
    maxHeight: "200px",
    width: "260px !important",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "100% !important",
    },
  },
}))((props) => <Menu {...props} />);

const SimpleSelect = React.memo(
  // eslint-disable-next-line complexity
  ({
    classes,
    render,
    selectedProjectId,
    showSeeAllProjects,
    route,
    history,
    showProjectsCount,
    menuOffset,
    defaultTitle,
    isHelpTutorial,
    setIsLoading,
    preventDefaultLoadingBar,
  }) => {
    const menuRef = useRef();
    const theme = useTheme();
    const isMobileView = useMediaQuery(
      theme.breakpoints.down(mobileViewBreakpoint)
    );

    const [expanded, setExpanded] = useState(false);

    const handleNewChange = (id) => {
      const fullRoute = `${route || "/project"}/${id}`;
      handleClose();
      history.push(fullRoute);
    };

    const handleClick = (event) => {
      setFocusOnElement(true, event.target.parentNode.parentNode);
      setExpanded(true);
    };

    const handleClose = () => {
      setFocusOnElement(false);
      setExpanded(false);
    };

    const getAnchorPosition = (ref, offsetTop = 0, offsetLeft = 0) => {
      if (!ref || !ref.current) return { top: 0, left: 0 };

      const { current } = ref;
      const rect = current.getBoundingClientRect();
      const top = rect.y + rect.height + offsetTop;
      const left = rect.x + offsetLeft;

      return { top, left };
    };

    const loggedUserInfo = auth.get("userInfo");

    const { loading, data } = useQuery(GetAllByUser,
      {
        variables: { id: loggedUserInfo.id },
        fetchPolicy: "no-cache",
        skip: isHelpTutorial,
      });

    if (
      (loading || (!data && !isHelpTutorial)) &&
      !preventDefaultLoadingBar
    )
      return (
        <Skeleton
          variant="rect"
          width={"80%"}
          height={44}
          style={{ marginTop: '6px'  }}
        />
      );
    if (
      (loading || (!data && !isHelpTutorial)) &&
      setIsLoading &&
      typeof setIsLoading === "function" &&
      preventDefaultLoadingBar
    )
      setIsLoading(false);
    let eventData = [];
    if (isHelpTutorial) {
      eventData =
        mockData?.user?.userEvents.map((userEvent) => ({
          ...userEvent.event,
        })) || [];
    } else {
      eventData =
        data?.user?.userEvents.map((userEvent) => ({
          ...userEvent.event,
        })) || [];
    }

    eventData = eventData.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );

    const eventId = selectedProjectId;
    let event = eventData.find((e) => e.id === eventId);

    _.remove(eventData, (record) => record.id === eventId);

    if (!event) event = { name: defaultTitle || "All Projects" };

    const anchorPosition = getAnchorPosition(
      menuRef,
      // (menuOffset && menuOffset.top) || 50,
      0,
      menuOffset && menuOffset.left
    );

    return (
      <>
        <section
          className={clsx(classes.root, {
            [classes.expanded]: expanded,
            "project-selector__expanded": expanded,
          })}
        >
          <header
            ref={menuRef}
            onClick={(event) => handleClick(event)}
            id="project-selector"
          >
            <div
              className={clsx([classes.title], "project-selector__title")}
            >
              {render(event.name)}{" "}
              {showProjectsCount && (
                <CustomTypography
                  customVariant={(isMobileView && "h4Bold") || "h2Bold"}
                >
                  ({(eventData && eventData.length) || 0})
                </CustomTypography>
              )}
            </div>
            <div
              className={clsx(
                [classes.action],
                "project-selector__action"
              )}
            >
              <ChevronDown />
            </div>
          </header>
        </section>
        <StyledMenu
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          open={expanded}
          onClose={handleClose}
          TransitionComponent={Collapse}
        >
          {eventData.map(
            (event) =>
              (event.id === eventId || event.status !== "Archived") && (
                <MenuItem
                  onClick={() => handleNewChange(event.id)}
                  key={event.id}
                  value={event.id}
                  style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                  }}
                >
                  <>
                    <Typography style={{ fontSize: "16px" }}>
                      {event.name}
                    </Typography>
                  </>
                </MenuItem>
              )
          )}

          {showSeeAllProjects && eventId && (
            <MenuItem
              style={{ background: "rgba(0, 0, 0, 0.02)" }}
              onClick={() => {
                handleClose();
                history.push(route || "/projects");
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "16px",
                      letterSpacing: "0.3px",
                      paddingTop: 12,
                      paddingBottom: 12,
                      color: "#4001C5",
                    }}
                  >
                    See all projects
                  </Typography>
                }
              />
            </MenuItem>
          )}
        </StyledMenu>
      </>
    );
  }
);

export default withRouter(withStyles(styles)(SimpleSelect));
