import { makeStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import React from 'react';
import Typography from 'components_v2/Typography/Typography';
import { ChevronDown } from '../Icons/Icons';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    margin: 0,
    boxShadow: '0px 6px 10px rgba(66, 75, 90, 0.2)',
    padding: 0,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    minHeight: 56,
    transition: 'all 200ms ease-in-out',
    '&:hover > div p': {
      color: '#4001C5',
    },
    '&$expanded': {
      minHeight: 56,
      '& > div p': {
        color: '#4001C5',
      },
      '&:hover': {
        boxShadow: '0px 8px 28px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  content: {
    margin: 0,
    padding: '20px 24px',
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    color: '#000',
    marginRight: 12,
  },
  expanded: {},
});

const AccordionSummary = ({
  summaryComponent,
  summary,
  summaryId,
  ariaControls,
}) => {
  const classes = useStyles();
  return (
    <MuiAccordionSummary
      expandIcon={<ChevronDown />}
      aria-controls={ariaControls}
      id={summaryId}
      classes={classes}
    >
      {summary ? (
        <Typography customVariant="bodyLargeRegular">{summary}</Typography>
      ) : (
        summaryComponent
      )}
    </MuiAccordionSummary>
  );
};
export default AccordionSummary;
