import React from "react";
import Box from "@material-ui/core/Box";
import ScrollContainer from "react-indiana-drag-scroll";
import Typography from "components_v2/Typography/Typography";
import GetAllByProject from 'gql/Modules/GetAllByProject';
import { useQuery } from '@apollo/react-hooks';
import { mockData } from '../../Modules/ModuleSettingsContainer.mock';
import SliderCards from "./SliderCards/SliderCards";
import _ from 'lodash';
import Skeleton from "@material-ui/lab/Skeleton";

const ModulesSlider = ({ projectId, isHelpTutorial, onSelectModule, selectedModule }) => {
  let { data, loading } = useQuery(GetAllByProject, {
    variables: { id: projectId },
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const modules = data ? data.modules || [] : [];

  let dataToDisplay = [{
    id: 0,
    name: 'All Modules'
  }]

  dataToDisplay = _.concat(dataToDisplay, modules);

  return loading ? <div style={{ height: 222 }}>
    <Skeleton variant="text"
      width={200}
      height={50}
      style={{ marginTop: 10, marginBottom: 8 }}
    />
    <div style={{ display: 'flex' }}
    >
      {
        Array.from(new Array(5)).map(() => {
          return <Skeleton
            variant="rect"
            width={'100%'}
            height={104}
            style={{ marginRight: 16 }}
          />
        })
      }
    </div>
  </div> : <div>
      <Box my={2}>
        <Typography customVariant="h4Regular">
          <span>Modules&nbsp;</span>
          <b>({modules.length})</b>
        </Typography>
      </Box>
      <ScrollContainer
        horizontal
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          paddingRight: "30px",

          position: "relative",
          top: "-30px"
        }}
      >
        <SliderCards data={dataToDisplay} onSelectCard={onSelectModule} selectedCard={selectedModule}></SliderCards>
      </ScrollContainer>
    </div>
}

export default ModulesSlider