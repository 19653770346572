import React, { useState } from "react";
import GetAllByEvent from "gql/VehicleClasses/GetAllByEvent";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import VehicleClassesComponent from "./VehicleClasses.Component";
import { mockData } from "./VehicleClasses.mock";
import Delete from "gql/VehicleClasses/DeleteVehicleClasses";
import { NetworkStatus } from "@apollo/client";
import { getFilteredVehicleClases, getSortedVehicleClasses } from "./helper";

const CreateVehicleclass = gql`
  mutation CreateVehicleclass($data: VehicleclassInput) {
    createVehicleclass(input: { data: $data }) {
      vehicleclass {
        id
      }
    }
  }
`;

const UpdateVehicleclass = gql`
  mutation UpdateVehicleclass($id: InputID!, $data: editVehicleclassInput) {
    updateVehicleclass(input: { where: $id, data: $data }) {
      vehicleclass {
        id
      }
    }
  }
`;

const VehicleClassesContainer = ({ projectId, isHelpTutorial }) => {
  let { data, loading, refetch, networkStatus } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: "no-cache",
    skip: isHelpTutorial,
    notifyOnNetworkStatusChange: true
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const [createVehicleClass] = useMutation(CreateVehicleclass);

  const [updateVehicleClass] = useMutation(UpdateVehicleclass);

  // if (data && data.vehicleclasses) data.vehicleclasses[0].icon = 'Dispatch';
  const [
    deleteConfirmationModalData,
    setDeleteConfirmationModalData
  ] = useState(null);

  const [deleteVehicleClasses] = useMutation(Delete);

  const deleteVehicleClassesRecord = async () => {
    const recordToDelete = deleteConfirmationModalData.recordId;

    setDeleteConfirmationModalData(null);

    await deleteVehicleClasses({
      variables: {
        id: recordToDelete
      }
    });

    await refetch();
  };
  const [filterKey, setFilterKey] = useState("");
  const [sort, setSort] = useState(null);
  let finalData = getFilteredVehicleClases({ vehicleClases: data, filterKey });
  finalData = getSortedVehicleClasses({ vehicleClases: finalData, sort });
  return (
    <VehicleClassesComponent
      setFilterKey={setFilterKey}
      setSort={setSort}
      sort={sort}
      data={finalData?.vehicleclasses || []}
      loading={loading || networkStatus === NetworkStatus.refetch}
      createVehicleClass={createVehicleClass}
      updateVehicleClass={updateVehicleClass}
      refetch={refetch}
      projectId={projectId}
      deleteConfirmationModalData={deleteConfirmationModalData}
      setDeleteConfirmationModalData={setDeleteConfirmationModalData}
      deleteVehicleClassesRecord={deleteVehicleClassesRecord}
    />
  );
};
export default withRouter(VehicleClassesContainer);
