import React from "react";
import { RootRef } from "@material-ui/core";
import DesktopNav from "components_v2/Layout/DesktopNav/DesktopNav";
import MobileNav from "components_v2/Layout/MobileNav/MobileNav";
import { withStyles } from "@material-ui/core/styles";
import withMediaQuery from "../withMediaQuery";
import withUserRole from "components_v2/Wrappers/withUserRole";
import { mobileViewBreakpoint } from "../../../../App.helper";



const useStyles = theme => ({
  drawerPaperOpenMobile: {
    right: "0",
    left: "auto",
    width: "280px",
    
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      zIndex: 1201,
      display: 'flex',
      overflow: 'auto'
    }
  }
});


// const Navigation = (props) => {
//   const [showSidebar, setShowSidebar] = useState(false)
//   const { classes, isMobile, openRightSideBar, isHelpTutorial } = props;
//   var userInfo = props.userInfo;
//   return (
//     <>
//         <RootRef>
//           <DesktopNav
//             isMobile={isMobile}
//             userInfo={userInfo}
//             isHelpTutorial={isHelpTutorial}
//             {...(isMobile && {
//               onOpen: () => setShowSidebar(true),
//               onClose: () => setShowSidebar(false),
//               drawerPaperClass: classes.drawerPaperOpenMobile,
//               expanded: true,
//               hidden: !showSidebar,
//               openRightSideBar
//             })}
//           />
//         </RootRef>
//         {isMobile && (
//           <RootRef>
//             <MobileNav
//               userInfo={userInfo}
//               openDesktopNav={() => setShowSidebar(!showSidebar)}
//             />
//           </RootRef>
//         )}
//       </>
//   )
// }

// export default withMediaQuery([
//   ["isMobile", theme => theme.breakpoints.down(mobileViewBreakpoint), { defaultMatches: true }]
// ])(
//   withStyles(useStyles, { withTheme: true })(
//     withUserRole("vehicles", Navigation)
//   )
// );


class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.desktopNavRef = React.createRef();
    this.mobileNavRef = React.createRef();

    this.state = {
      showSidebar: false
    };
  }

  render() {
    const { classes, isMobile, openRightSideBar, isHelpTutorial } = this.props;
    const { desktopNavRef, mobileNavRef } = this;

    var userInfo = this.props.userInfo;

    return (
      <>
        <RootRef rootRef={desktopNavRef}>
          <DesktopNav
            isMobile={isMobile}
            userInfo={userInfo}
            isHelpTutorial={isHelpTutorial}
            {...(isMobile && {
              onOpen: () => this.setState({ showSidebar: true }),
              onClose: () => this.setState({ showSidebar: false }),
              drawerPaperClass: classes.drawerPaperOpenMobile,
              expanded: true,
              hidden: !this.state.showSidebar,
              openRightSideBar
            })}
          />
        </RootRef>
        {isMobile && (
          <RootRef rootRef={mobileNavRef}>
            <MobileNav
              userInfo={userInfo}
              openDesktopNav={() => this.setState({ showSidebar: true })}
            />
          </RootRef>
        )}
      </>
    );
  }
}

export default withMediaQuery([
  ["isMobile", theme => theme.breakpoints.down(mobileViewBreakpoint), { defaultMatches: true }]
])(
  withStyles(useStyles, { withTheme: true })(
    withUserRole("vehicles", Navigation)
  )
);
