import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Hidden, Visible } from '../../Icons/Icons';

import InputLabel from '../InputLabel/InputLabel';
import InputError from '../InputError/InputError';
import { useStyles, useCustomStyles } from './PasswordInput.styles';

const PasswordInput = ({
  label,
  icon,
  placeholder,
  name,
  value,
  onChange,
  isDisabled,
  error,
  className,
  disableAutocomplete,
}) => {
  const variant = 'outlined';
  const Icon = icon;
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [showPassword, setShowPassword] = useState(false);

 

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={customClasses.inputWrapper}>
      <InputLabel label={label} />
      <TextField
        fullWidth
        name={name}
        type={showPassword ? 'text' : 'password'}
        error={!!error}
        placeholder={placeholder}
        variant={variant}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        className={className}
        autoComplete={disableAutocomplete ? 'new-password' : undefined}
        InputProps={{
          classes,
          disableUnderline: true,
          endAdornment: Icon && (
            <InputAdornment position="end">
              <IconButton
                disabled={isDisabled}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visible /> : <Hidden />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <InputError error={error} />
    </div>
  );
};

export default PasswordInput;
