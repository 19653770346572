import React from 'react';
import PropTypes from 'prop-types';

import PopoverFormFooter from './PopoverFormFooter';
import useStyles from './PopoverForm.style';

const PopoverForm = ({
  children,
  currentStep,
  disableLayout,
  disableStepper,
  mainAction,
  numberOfSteps,
  onSubmit,
  secondaryAction,
  pageView,
}) => {
  const classes = useStyles({
    mainAction,
    secondaryAction,
    disableLayout,
    pageView,
  });

  if (disableLayout)
    return (
      <div className={classes.container}>
        <div>{children}</div>
        <PopoverFormFooter
          currentStep={currentStep}
          disableStepper={disableStepper}
          mainAction={mainAction}
          numberOfSteps={numberOfSteps}
          secondaryAction={secondaryAction}
        />
      </div>
    );

  return (
    <form onSubmit={onSubmit} className={classes.container}>
      <div className={classes.layout}>{children}</div>
      <PopoverFormFooter
        currentStep={currentStep}
        disableStepper={disableStepper}
        mainAction={mainAction}
        numberOfSteps={numberOfSteps}
        secondaryAction={secondaryAction}
      />
    </form>
  );
};
PopoverForm.propTypes = {
  children: PropTypes.element,
  currentStep: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  disableLayout: PropTypes.bool,
  disableStepper: PropTypes.bool,
  mainAction: PropTypes.element.isRequired,
  secondaryAction: PropTypes.element,
  onSubmit: PropTypes.func,
  pageView: PropTypes.func,
};

export default PopoverForm;
