import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "components_v2/Loading/CircularProgress";

const CardAction = ({ amount, type, alertsLoading }) => {
  const classes = styles();

  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <div style={{ display: "flex" }}>
        {(alertsLoading && <Loading size={24} />) || (
          <label
            className={classes.amountStyle}
            style={type === "alerts" ? { color: "#C50202" } : null}
          >
            {amount}
          </label>
        )}
      </div>
      <div>
        <label className={classes.typeStyle}>{type}</label>
      </div>
    </div>
  );
};

const styles = makeStyles({
  typeStyle: {
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "400",
    margin: "0 0 0 10%",
  },
  amountStyle: {
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "1.0",
  },
});

export default CardAction;
