import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../App.helper';
import { TablePagination as MuiTablePagination } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  paginationContainer:{
    paddingRight: "24px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '12px 0px 12px 0px',
    },
    "& .MuiTablePagination-toolbar":{
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        paddingLeft:"12px",
        display:"inline-flex",
        marginBottom: "80px"
      },
    },
    "& .MuiSelect-select.MuiSelect-select":{
      fontSize: "14px"
    },
    "& .MuiTablePagination-selectRoot":{
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginRight:"8px"
      },
    },
    "& p":{
      fontSize: "14px"
    },
    "& svg":{
      top:"calc(50% - 14px)"
    },
    "& .MuiTablePagination-actions":{
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginLeft: "0px",
        
      }
    },
    "& button":{
      padding: "12px 6px"
    }

  }
}));

const TablePagination = ({ 
  count,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) => {
 
  const classes = useStyles();
  return (
    <div className={classes.paginationContainer}>
      <MuiTablePagination 
        component="div"
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
    
  )
};

export default TablePagination;