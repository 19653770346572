import { makeStyles } from '@material-ui/core/styles';
import {
  mobileViewBreakpoint,
  smallMobileViewBreakpoint,
} from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  removeDepartmentButtonGrid: {
    display: 'flex',
    marginLeft: 40,
    marginTop: 4,
    [theme.breakpoints.down(smallMobileViewBreakpoint)]: {
      marginLeft: 0,
      marginTop: 0,
      display: 'block',
    },
  },
  gridContainer: {
    marginBottom: 12,
  },
  logoRow: {
    '& > div': {
      height: 300,
    },
  },
  projectTypeRow: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',

      '& .MuiFormControlLabel-root:first-of-type': {
        marginRight: 32,
      },
    },
  },
  moduleInputs: {
    marginBottom: 56,
  },
  addAnotherModuleButtonContainer: {
    marginTop: 40,
    display: 'block',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  addModuleButtonContainer: {
    marginTop: 0,
    display: 'block',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  fleetRequirementsContainer: {
    marginBottom: 56,
  },
  tooltipLabelContainer: { display: 'flex', alignItems: 'center' },
  tooltipIcon: {
    width: 18,
    height: 'auto',
    marginLeft: 5,
  },
  clientPriceFormatContainer: {
    marginBottom: 32,
  },
  clientPriceMarginContainer: {
    marginBottom: 56,
  },
}));

export default useStyles;
