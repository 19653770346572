import React, { useState } from "react";
import { withRouter } from 'react-router';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { NetworkStatus } from '@apollo/client';
import AlertsComponent from './Alerts.Component';
import GetAllGQL from '../../gql/Alerts/GetAllByEvent';
import UpdateAlert from '../../gql/Alerts/UpdateAlert';
import useChat from "hooks/useChat";

const AlertsContainer = ({ projectId, isHelpTutorial }) => {
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })

  let { data, loading, refetch, networkStatus } = useQuery(GetAllGQL, {
    variables: { id: projectId, ...listConfig, sort: listConfig.sort || 'id:desc' },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  const currentData = data?.alertsConnection?.values  || [];
  const count = data?.alertsConnection?.aggregate?.count|| 0;

  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }
  const [
    updateAlert,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateAlert);

  const handleToggleStatus = async (row, status) => {
    await updateAlert({
      variables: {
        id: { id: row.id },
        data: { status },
      },
      notifyOnNetworkStatusChange: true,
    });

    await refetch();
  };

  const [
    getNewAlerts,
  ] = useLazyQuery(GetAllGQL, {
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  useChat(
    "alertCreate",
    async ({ alerts }) => {
      if (alerts[0]?.result?.event?.id=== data.projectId) {
        await getNewAlerts();
      }
    }
  );

  return (
    <AlertsComponent
      onToggleStatus={handleToggleStatus}
      data={currentData}
      loading={
        loading ||
        updateMutationLoading ||
        networkStatus === NetworkStatus.refetch
      }
      refetch={refetch}
      projectId={projectId}
      handleListConfigChange={handleListConfigChange}
      listConfig={{...listConfig, count}}
      numRows={count}
    />
  );
};
export default withRouter(AlertsContainer);
