import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Map from "components_v2/Map/Map";
import ProjectSelector from "components_v2/ProjectSelector/ProjectSelector";
import SearchInput from "components_v2/Inputs/SearchInput/SearchInput";
import Typography from "components_v2/Typography/Typography";
import FAB from "components_v2/Buttons/FAB/FAB";
import { FloatingActionNewProject } from "components_v2/Icons/Icons";
import { withRouter } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Loading from "components_v2/Loading/CircularProgress";
import { mobileViewBreakpoint } from "../../../../App.helper";

const MainContainer = ({ projectId, history, data, locationsData, loading }) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <>
      {loading && <Loading fullPage />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: (isMobileView && "calc(100% - 62px)") || "calc(100% - 80px)",
        }}
      >
        {projectId && (
          <FAB
            label="Add Booking"
            icon={FloatingActionNewProject}
            onClick={() => {
              history.push(`/project/${projectId}/booking/new`);
            }}
          />
        )}

        <Box style={{ minHeight: "", padding: "24px" }}>
          <ProjectSelector
            render={(projectName) => (
              <Typography
                customVariant={(isMobileView && "h4Bold") || "h2Bold"}
              >
                {projectName}
              </Typography>
            )}
            route="/dispatch"
            selectedProjectId={projectId}
            showSeeAllProjects
          />
        </Box>
        <ReactResizeDetector handleWidth handleHeight>
          {({ width, height }) => (
            <Box
              style={{
                flex: 1,
                overflow: "auto",
              }}
            >
              <Map
                locationsData={locationsData}
                isDispatchPage
                width={width}
                height={height}
                fullScreen
                searchKey={searchValue}
                projectId={projectId}
                history={history}
                viewportCoordinates={
                  data?.viewport ? data?.viewport : []
                }
                filterActions={
                  <Box display="flex">
                    {/*
                    <Box>
                      <Filters />
                    </Box>*/}
                    <Box
                      width={(isMobileView && "100%") || "430px"}
                      marginLeft="16px"
                      right={!isMobileView && "140px"}
                    >
                      <SearchInput
                        placeholder="Filter drivers"
                        onChange={(value) => setSearchValue(value)}
                      />
                    </Box>
                  </Box>
                }
              />
            </Box>
          )}
        </ReactResizeDetector>
      </div>
    </>
  );
};
export default withRouter(MainContainer);
