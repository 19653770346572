export const mockDriverData = {
  users: [
    {
      id: "5f289d298d19e3785c525f70",
      email: "james741+90@gmail.com",
      firstName: "James",
      lastName: "White",
      phone: "+971509664754",
      confirmed: false,
      company: null,
      identifier: "B0010",
      username: "1596497193544",
      notes: ":) :)",
      passengerPriority: null,
      role: {
        id: 4,
        name: "Driver",
        __typename: "UsersPermissionsRole"
      },
      __typename: "UsersPermissionsUser"
    },
    {
      id: "5f28a10f7950d03fd1ef5d84",
      email: "jack4912@gmail.com",
      firstName: "Jack",
      lastName: "Reeves",
      phone: "+971509664755",
      confirmed: false,
      company: null,
      identifier: "Number Two",
      username: "1596663786064",
      notes: ":) :)",
      passengerPriority: null,
      role: {
        id: 4,
        name: "Driver",
        __typename: "UsersPermissionsRole"
      },
      __typename: "UsersPermissionsUser"
    }
  ]
};

export const mockData = {
  locationsConnection: {
    groupBy: {
      driverId: [
        {
          key: "5f289d298d19e3785c525f70",
          connection: {
            values: [
              {
                lat: 46.5003427,
                long: 15.7508297,
                h: 30,
                __typename: "Location"
              }
            ],
            __typename: "LocationConnection"
          },
          __typename: "LocationConnectionDriverId"
        },
        {
          key: "5f28a10f7950d03fd1ef5d84",
          connection: {
            values: [
              {
                lat: 46.5003506,
                long: 15.7508336,
                h: 30,
                __typename: "Location"
              }
            ],
            __typename: "LocationConnection"
          },
          __typename: "LocationConnectionDriverId"
        }
      ],
      __typename: "LocationGroupBy"
    },
    __typename: "LocationConnection"
  }
};
