import React from 'react';
import PropTypes from 'prop-types';
import {useStyles} from './InputLabel.styles'
const InputLabel = ({ label }) => {
  const classes = useStyles();

  return (label && <div className={classes.inputLabel}>{label}</div>) || <></>;
};

InputLabel.propTypes = {
  label: PropTypes.string,
};

export default InputLabel;
