import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import GetAllEvents from 'gql/Events/GetAllFilterUsers';
import UserProjectList from './UserProjectList.Component';

const UserProjectContainer = ({ userId }) => {
  const { data, loading, refetch } = useQuery(GetAllEvents, {
    variables: { id: userId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  return (
    <UserProjectList
      loading={loading}
      data={data ? data.events : []}
      userId={userId}
      refetchEvents={refetch}
    />
  );
};

export default UserProjectContainer;
