import React, { useState } from "react";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";

import Button from "components_v2/Button/Button";
import FullScreenPopover from "components_v2/FullScreenPopover/FullScreenPopover";
import CreateUser from "gql/Users/CreateUser";
import UpdateUser from "gql/Users/Update";
import Loading from "components_v2/Loading/CircularProgress";
import SuccessCard from "components_v2/Cards/SuccessCard/SuccessCard";
import { addUpdatedOrCreatedBy } from "utils/formHelpers";
import auth from "utils/auth";
import UserDetailsForm from "./UserDetailsForm";
import UserProjectsForm from "./UserProjectsForm";
import { getContinueBodyAction } from "utils/formHelpers";
import useStyles from "./AddUser.style";

import {
  formatSubmissionValues,
  validationSchema,
  formatIncomingData
} from "./helpers";
import {
  redirectOutOfBoundStep,
  getPathWithoutStep
} from "utils/routerHelpers";
import withFormState from "containers/Wrappers/withFormState";

const userInfo = auth.getUserInfo();

const initialInputValues = {
  autoGenPassword: "MOTUS!123",
  customPassword: "",
  generatePassword: false,
  willSendPassword: false,
  updatePassword: false,
  requirePasswordChange: false,
  sendPasswordEmail: userInfo?.email,
  role: 3, // admin role
  isSuperAdmin: false,
  blocked: false,
  firstName: "",
  lastName: "",
  email: "",
  password: Date.now().toString()
};

const AddUserComponent = ({
  history,
  location,
  data,
  refetchUser,
  stepParam,
  getPreviousFormState,
  setReturnValues
}) => {
  const classes = useStyles();
  const totalSteps = 2;
  const previousFormState = getPreviousFormState(location);

  if (stepParam)
    redirectOutOfBoundStep(stepParam, location, history, totalSteps);

  const [userId, setUserId] = useState(null);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [step, setStep] = useState(stepParam || 1);
  const [nextView, setNextView] = useState(false);
  const formRef = React.useRef(null);

  const navigateNext = () => {
    const pathWithoutStep = getPathWithoutStep(step, location);
    window.history.replaceState(null, "", `${pathWithoutStep}/${step + 1}`);

    setStep(prevState => prevState + 1);
  };

  const isNew = location.pathname
    .toLowerCase()
    .split("/")
    .includes("new");

  const isUpdate = !!data;
  const loadedUserId = userId || data?.id;
  const initialValues = isUpdate
    ? { ...initialInputValues, ...formatIncomingData(data) }
    : initialInputValues;

  const title =
    isUpdate && !isNew ? "Update an existing user" : "Create a new user";
  const successHeader = `User ${isUpdate && !isNew ? "Updated" : "Created"}!`;
  const successDescription = `Congratulations, a user has been ${
    isUpdate && !isNew ? "updated" : "created"
  }.`;

  const [createUser, { loading: createMutationLoading }] = useMutation(
    CreateUser
  );

  const [updateUser, { loading: updateMutationLoading }] = useMutation(
    UpdateUser
  );

  const isLoading = createMutationLoading || updateMutationLoading;

  const handleSubmit = async (values, actions) => {
    // Logic depending on what step the form is on:
    // const formikBag = formRef.current.getFormikBag();

    if (step === 1) {
      const formattedValues = formatSubmissionValues(
        values,
        initialValues,
        isUpdate
      );
      const valuesWithUserId = addUpdatedOrCreatedBy(formattedValues, isUpdate);

      if (isUpdate || userId) {
        const id = data ? data.id : userId;
        const res = await updateUser({
          variables: {
            id: { id },
            data: valuesWithUserId
          }
        });

        if (res?.data?.updateUser) {
          setUserId(res.data.updateUser.user.id);
          if (!nextView) setDisplaySuccess(true);
          else navigateNext();
          if (isUpdate) await refetchUser();
        }
      } else {
        const res = await createUser({
          variables: {
            data: valuesWithUserId
          }
        });

        if (res?.data?.createUser) {
          const createdUserId = res.data.createUser.user.id;
          setUserId(createdUserId);

          if (!_.isEmpty(previousFormState))
            setReturnValues(createdUserId, previousFormState);
          formRef.current.setFieldValue("isUserCreated", true);
          formRef.current.setFieldValue("createdUserId", createdUserId);

          if (!nextView) {
            setDisplaySuccess(true);
            actions.resetForm(initialValues);
          } else
            history.push(`/user/${createdUserId}/new/2`, previousFormState);
        }
      }
    } else if (step === 2) {
      // Empty block
    }

    actions.setSubmitting(false);
  };

  const renderForm = (step, props, classes) => {
    if (step === 1)
      return (
        <UserDetailsForm {...props} classes={classes} isUpdate={isUpdate} />
      );
    else if (step === 2)
      return (
        <UserProjectsForm
          {...props}
          classes={classes}
          userId={loadedUserId || userId}
        />
      );
  };

  const handleNextClick = () => {
    setNextView(true);
    formRef.current.submitForm();
  };

  const handleFinishedClick = () => {
    setDisplaySuccess(true);
  };

  const getMainAction = step => {
    if (step === 1)
      return (
        <Button type="button" variant="contained" onClick={handleNextClick}>
          Add user to projects
        </Button>
      );

    if (step === 2)
      return (
        <Button type="button" variant="contained" onClick={handleFinishedClick}>
          Finish
        </Button>
      );
    return null;
  };

  const getSecondaryAction = step => {
    if (step === 1)
      return (
        <Button
          type="button"
          variant="simple"
          onClick={() => {
            setNextView(false);
            formRef.current.submitForm();
          }}
        >
          Save and close
        </Button>
      );
    return null;
  };

  let mainBodyActions;

  if (!_.isEmpty(previousFormState))
    mainBodyActions = [getContinueBodyAction(history, previousFormState)];
  else
    mainBodyActions = [
      {
        text: "Add Additional Users",
        onClick: () => {
          history.push(`/user/new`);
          setUserId(null);
          setDisplaySuccess(false);
        }
      }
    ];

  if (displaySuccess)
    return (
      <FullScreenPopover
        useHandleClose
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        handleBack={() => {
          history.push("/users");
        }}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={{
            text: "Setup Users",
            onClick: () => {
              history.push(`/users`);
            }
          }}
          secondaryAction={{
            text: "Edit User",
            onClick: () => {
              history.push(`/user/${userId}/edit`);
              setUserId(null);
              setDisplaySuccess(false);
            }
          }}
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        ref={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(isUpdate || !isNew)}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {props => (
          <Form>
            <FullScreenPopover
              useHandleClose
              previousFormState={previousFormState}
              title={title}
              numberOfSteps={totalSteps}
              currentStep={step}
              setCurrentStep={setStep}
              mainAction={getMainAction(step)}
              secondaryAction={getSecondaryAction(step)}
              onCloseCallback={() => {
                history.push("/users");
              }}
              pageView
            >
              {renderForm(step, props, classes)}
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddUserComponent));
