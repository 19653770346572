import { makeStyles } from '@material-ui/core/styles';

const getLoaderColor = (status) => {
  if (status === 'uploading') return '#F7AF5E';
  if (status === 'finalizing') return '#23B60B';
  return 'transparent';
};

const useStyles = makeStyles({
  container: (props) => ({
    padding: props.status === 'completed' ? '16px 24px' : '16px 24px 18px 24px',
    position: 'relative',
    border: '1px solid #d9d9d9',
    marginBottom: 8,
    borderRadius: 6,
    borderBottom: props.status === 'completed' ? '1px solid #d9d9d9' : 'none',
    minHeight: 84,
    backgroundColor: '#fff',
  }),
  rightLayout: (props) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: props.status === 'completed' ? 0 : 6,
  }),
  leftLayout: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  linearProgressContainer: {},
  linearProgress: (props) => ({
    height: '3px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',

    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: '#d9d9d9',
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 6,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: getLoaderColor(props.status),
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 6,
    },
  }),
  fileData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default useStyles;
