import React from 'react';

import PublicLayout from './PublicLayout';

const withPublicLayout = (props, WrappedComponent) =>
  class extends React.Component {
    render() {
      const { ...other } = props;
      return (
        <PublicLayout {...props} noAuthLinks>
          <WrappedComponent {...other} />
        </PublicLayout>
      );
    }
  };

export default withPublicLayout;
