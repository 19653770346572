import gql from "graphql-tag";

export default gql`
  query GetAllUpcomingPassengerSchedules(
    $events: [ID]
    $limit: Int
    $start: Int
    $module: ID
    $status: String
    $recent: Boolean
    $sort: Int
    $columnSort: String
    $search: String
    $driverScheduleIds: [ID]
  ) {
    allBookings(
      where: {
        events: $events
        module: $module
        status: $status
        recent: $recent
        sort: $sort
        columnSort: $columnSort
        search: $search
        driverScheduleIds: $driverScheduleIds
      }
      sort: "start:asc"
      limit: $limit
      start: $start
    ) {
      totalCount
      bookings {
        id
        cancelled
        alerts {
          id: type
          description
          status
        }
        vehicle {
          id
          vehicleNumber
          make
          model
          year
          plateNumber
          color
        }
        driver {
          id
          firstName
          lastName
          fullName
          identifier
          phone
          email
          whatsapp
          languages
          licenseNumber
          nationality
          passportNumber
          visaNumber
          supplier {
            id
            name
          }
        }
        ridestatuses {
          id
          status
          created_at
          location
        }
        unconfirmedAlertSent
        overtimeHours
        waitingTime

        passengerschedule {
          id
          start
          transferservice {
            id
            name
          }
          stop
          stops
          savedStops {
            name
            type
            address
            lat
            long
            id
          }
          products
          rate
          luggageCount
          productNotes
          vehicleClass
          origin
          created_at
          type
          flightNumber
          terminal
          numPassengers
          numberOfVehicles
          bookingId
          billed
          notes
          cancelled
          flightNumber
          terminal
          documents
          supplier {
            id
          }
          savedDocuments {
            id
            size
            url
            name
            uploadedBy {
              id
              firstName
              lastName
            }
          }
          alerts {
            id
            type
            description
            status
          }
          event {
            id
            name
            timezone
          }
          requiredVehicleClass {
            id
            name
            transferPricing
            trackTransferPricing
            fourHourPricing
            sixHourPricing
            twelveHourPricing
            twentyFourHourPricing
            maxPassengers
          }
          department {
            id
            name
            module {
              id
              name
            }
          }
          createdBy {
            id
            firstName
            lastName
            fullName
            email
            phone
          }
          passenger {
            id
            firstName
            lastName
            fullName
            email
            phone
            passengerPriority
            notes
          }
          additionalPassengers {
            id
            firstName
            lastName
            fullName
            email
            phone
            passengerPriority
            notes
          }
          from {
            id
            name
            address
            lat
            long
          }
          to {
            id
            name
            address
            lat
            long
          }
        }
      }
    }
  }
`;
