import React from "react";

export const getMultipleEventsData = (events, getName) => {
  if (!events.length) return ["None", 0];

  const firstEvent = getName ? getName(events) : events[0].event.name;
  const otherEventsCount = events.length - 1;
  if (events.length === 1) return [firstEvent, otherEventsCount];

  return [firstEvent, otherEventsCount];
};

export const getMultipleDriversData = (assignedDrivers, getName) => {
  if (!assignedDrivers.length) return ["None", 0];

  const firstDriver = getName
    ? getName(assignedDrivers)
    : assignedDrivers[0].driver.firstName;
  const otherDriversCount = assignedDrivers.length - 1;
  if (assignedDrivers.length === 1) return [firstDriver, otherDriversCount];

  return [firstDriver, otherDriversCount];
};

export const getMultiplVehiclesData = (assignedVehicles, getName) => {
  if (!assignedVehicles?.length) return ["None", 0];

  const firstVehicle = getName ? (
    getName(assignedVehicles)
  ) : (
    <>
      {assignedVehicles[0].vehicle.model}{" "}
      <small>(Plate {assignedVehicles[0].vehicle.plateNumber})</small>
    </>
  );
  const otherVehiclesCount = assignedVehicles.length - 1;
  if (assignedVehicles?.length === 1) return [firstVehicle, otherVehiclesCount];

  return [firstVehicle, otherVehiclesCount];
};
