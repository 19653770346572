import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  passengerFormContainer: {
    '&:not(:last-of-type)': {
      marginBottom: 40,
    },
  },
  detailPanelContainer: {
    padding: 16,
  },
  addPassengerButtonSpacer: {
    marginBottom: 40,
  },
  photoRow: {
    '& > div': {
      height: 300,
    },
  },
  marginBot: {
    marginBottom: 12,
  },
}));

export default useStyles;
