import React from "react";
import Menu from "../Menu/Menu";
import IconButton from "@material-ui/core/IconButton";
import { MoreHorizontal } from "../Icons/Icons";
import { makeStyles } from "@material-ui/core/styles";
import SendEmail from "./SendEmail";
import AdjustOvertime from './AdjustOvertime'
import DeleteBooking from './DeleteBooking'
import ViewDocumentsOption from './ViewDocuments/ViewDocumentsOption'
import CancelBooking from './CancelBooking'
import { isHistory } from "./helpers";
import AssignDriverOption from "./AssignDriver/AssignDriverOption";
import CopySMS from "./CopySMS";
import SendDriverLink from "./SendDriverLink";
const useStyles = makeStyles({
  moreMenu: {
    marginTop: "10px",
  },
})
const BookingOptions = ({ onSendDriverLinkLoading ,data, history, onOvertimeLoading, onSendEmailLoading, onAssignDriverLoading, isTrackRide, onDelete, onDeleteLoading, onCancelLoading }) => {

  const classes = useStyles();
  return <>
    <Menu
      className={classes.moreMenu}
      render={(handleClick) => (
        <IconButton
          onClick={handleClick}
          disableRipple
          className={classes.footerMoreButton}
        >
          <MoreHorizontal />
        </IconButton>
      )}
      items={[
        {
          label: "Edit",
          onClick: () =>
            history.push(
              `/project/${data.event.id}/booking/${data.passengerScheduleId}/edit`
            )
        },
        {
          label: "Duplicate", onClick: () => {
            history.push(
              `/project/${data.event.id}/booking/${data.passengerScheduleId}/duplicate`
            );
          }
        },
        {
          label: "Share",
          onClick: () => history.push(`/trackride/${data.id}`)
        },
        {
          label: "Copy Link", onClick: () => {
            const { origin } = window.location;
            const { bookingId } = data.passengerschedule || data;
            const projectId = data.event.id;
            navigator.clipboard.writeText(
              `${origin}/project/${projectId}/bookings/${bookingId}`
            );
          }
        },
        ...(isHistory({ data })
          ? [
            {
              label: "Ride Playback",
              onClick: () => {
                // Callback logic here
              },
              className: "mobile-actions-primary-actions"
            }
          ]
          : []),
        {
          label: "Open in new tab",
          onClick: () => {
            const { origin } = window.location;
            const { bookingId } = data.passengerschedule || data;
            const projectId = data.event.id;
            window.open(
              `${origin}/project/${projectId}/bookings/${bookingId}`,
              "_blank"
            );
          },
        },
        {
          isDivider: true
        },

        {
          render: () => {
            return <SendEmail onSendEmailLoading={onSendEmailLoading} data={data} />
          }
        },
        ...(data.driver
          ? [
            {
    
              render: () => {
                return (
                  <SendDriverLink
                    onSendDriverLinkLoading={onSendDriverLinkLoading}
                    data={data}
                    id={`send-driver-link-button-${data.id}`}
                  />
                );
              },
            },]
          : []),
        {
          render: () => {
            return <CopySMS data={data} />
          }
        },

        {
          render: () => {
            return <AdjustOvertime onOvertimeLoading={onOvertimeLoading} data={data} />
          }
        },


        {
          isDivider: true
        },
        ...(
          !isHistory({ data }) ?
            [
              {
                render: () => {
                  return <AssignDriverOption data={data} onAssignDriverLoading={onAssignDriverLoading} />
                }
              }
            ] : []
        ),
        {
          label: "Add documents",
          onClick: () =>
            history.push(
              `/project/${data.event.id}/booking/${data.passengerScheduleId}/edit`
            )
        },
        {
          render: () => {
            return <ViewDocumentsOption data={data} />
          }
        },

        ...(((!isHistory({ data }) &&
          !data.passengerScheduleCancelled &&
          !data.driverScheduleCancelled) || (!isTrackRide))
          ? [{
            isDivider: true
          }] : []),
        ...(
          !isHistory({ data }) &&
            !data.passengerScheduleCancelled &&
            !data.driverScheduleCancelled
            ? [
              {
                render: () => {
                  return <CancelBooking id={`mobile-actions-cancel-${data.id}`} data={data} onCancelLoading={onCancelLoading}></CancelBooking>
                }
              },
            ]
            : []),
        ...(!isTrackRide
          ? [{
            render: () => {
              return <DeleteBooking onDelete={onDelete} onDeleteLoading={onDeleteLoading} id={`mobile-actions-delete-${data.id}`} data={data} ></DeleteBooking>
            }
          }] : [])
      ]}
    />
  </>
}

export default BookingOptions;