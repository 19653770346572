import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useQuery } from "@apollo/react-hooks";
import auth from "utils/auth";
import GetAllByUser from "../../../gql/Events/GetAllByUser";
import AccountHeader from "../AccountHeader";
import DynamicList from "../../List/DynamicList";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../App.helper";
import DeleteConfirmationModal from "components_v2/Modal/DeleteConfirmationModal/DeleteConfirmationModal";
import SideBarSkeletonLoader from "../Loader/SideBarSkeletonLoader";
import { getSortedProjects } from "./helper";
const useStyles = makeStyles(theme => ({
  sidebar: {
    width: "534px",
    background: "#FFFFFF",
    boxShadow: "-16px 0px 25px rgba(0, 0, 0, 0.1)",
    height: "100%",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      height: "100%"
    }
  },
  loaderContainer: {
    width: "534px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "100%"
    }
  },
  body: {
    margin: "40px 24px 0",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingBottom: "24px",
      flex: "1",
      flexWrap: "wrap",
      overflow: "auto"
    }
  },
  projectName: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "134.3%",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.5px",
    color: "rgba(0, 0, 0, 0.9)"
  },
  projectId: {
    fontSize: "14px",
    lineHeight: "134.3%",
    color: "rgba(0, 0, 0, 0.9)",
    wordBreak: "break-all"
  },
  projectRole: { fontSize: "14px", lineHeight: "134.3%", color: "#000000" }
}));

const Projects = ({
  onBackClick,
  onCloseClick,
  onLeaveProject,
  onArchiveProject
}) => {
  const { id } = auth.get("userInfo");
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const [confirmationModalData, setConfirmationModalData] = React.useState(
    null
  );

  const defaultFormState = {
    accidentEmail: true,
    accidentSMS: false,
    delayedFlightEmail: true,
    delayedFlightSMS: true,
    delayedDriverEmail: true,
    delayedDriverSMS: true,
    changeRequestsEmail: false,
    changeRequestsSMS: true,
    newProjectEmail: true,
    newProjectSMS: false,
    newBookingEmail: false,
    newBookingSMS: true,
    requestAccessEmail: true,
    requestAccessSMS: true
  };

  const { data, loading } = useQuery(GetAllByUser, {
    variables: { id: id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  const deleteRecord = async () => {
    const recordToDelete = confirmationModalData.recordId;
    const deleteType = confirmationModalData.deleteType;

    if (deleteType === "leave") {
      onLeaveProject(recordToDelete);
    } else if (deleteType === "archive") {
      onArchiveProject(recordToDelete);
    }
    setConfirmationModalData(null);
  };

  const eventData = data?.user?.userEvents.map(userEvent => ({
    ...userEvent,
    role: userEvent.role
  }));
  const [sort, setSort] = useState(null);
  let finalData = getSortedProjects({ projects: eventData, sort });

  if (loading)
    return (
      <div className={classes.loaderContainer}>
        <SideBarSkeletonLoader />
      </div>
    );

  return (
    <div className={classes.sidebar}>
      <DeleteConfirmationModal
        deleteConfirmationModalData={confirmationModalData}
        onClickCancel={() => {
          setConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteRecord();
        }}
      />
      <AccountHeader
        title="Projects"
        onBackClick={onBackClick}
        onCloseClick={onCloseClick}
      />

      <div className={classes.body}>
        <Formik initialValues={defaultFormState} onSubmit={() => {}}>
          {() => {
            return (
              <Form>
                <DynamicList
                  sortActions={{
                    currentSort: sort,
                    onChangeSort: setSort
                  }}
                  isMobileView={isMobileView}
                  data={finalData}
                  showHeader
                  noResultsText="No projects"
                  getMobileMenuItems={row => {
                    return {
                      right: [
                        {
                          text: "Leave project",
                          onClick: () => {
                            setConfirmationModalData({
                              deleteType: "leave",
                              headerText: `You will leave ${row.event.name}. You can only be re-added by an administrator.`,
                              description: `Do you wish to continue?`,
                              recordName: row.event.name,
                              recordId: row.event.id,
                              confirmationType: "delete"
                            });
                          },
                          className: "mobile-actions-duplicate"
                        },
                        {
                          text: "Archive project",
                          onClick: () => {
                            setConfirmationModalData({
                              deleteType: "archive",
                              headerText: `You will archive ${row.event.name} for all users so it will no longer be visible. This can be un-done at any time.`,
                              description: `Do you wish to continue?`,
                              recordName: row.event.name,
                              recordId: row.event.id,
                              confirmationType: "delete"
                            });
                          },
                          className: "mobile-actions-copy-link"
                        }
                      ]
                    };
                  }}
                  hamburgerMenuItems={[
                    {
                      label: "Leave project",
                      onClick: row => {
                        setConfirmationModalData({
                          deleteType: "leave",
                          headerText: `You will leave ${row.event.name}. You can only be re-added by an administrator.`,
                          description: `Do you wish to continue?`,
                          recordName: row.event.name,
                          recordId: row.event.id,
                          confirmationType: "delete"
                        });
                      }
                    },
                    {
                      label: "Archive project",
                      onClick: row => {
                        setConfirmationModalData({
                          deleteType: "archive",
                          headerText: `You will archive ${row.event.name} for all users so it will no longer be visible. This can be un-done at any time.`,
                          description: `Do you wish to continue?`,
                          recordName: row.event.name,
                          recordId: row.event.id,
                          confirmationType: "delete"
                        });
                      }
                    }
                  ]}
                  columns={[
                    {
                      field: "event.name",
                      headerName: "Project Name",
                      width: 6,
                      render: data => (
                        <div className={classes.projectName}>
                          {data.event.name}
                        </div>
                      )
                    },

                    {
                      field: "role",
                      headerName: "Your Role",
                      width: 4,
                      isSortable: false,
                      render: data => (
                        <div className={classes.projectRole}>
                          {(data.user.isSuperAdmin && "Super Admin") ||
                            (data.accessRight && data.accessRight.name)}
                        </div>
                      )
                    }
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Projects;
