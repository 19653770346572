import ListModal from 'components_v2/ListModal/ListModal';
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import React from 'react';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DeleteConfirmationModal from 'components_v2/Modal/DeleteConfirmationModal/DeleteConfirmationModal';
import { getColumns } from './ListOptions';
import { mobileViewBreakpoint } from '../../../../../App.helper';

const FleetClassSelector = ({
  onDone,
  values,
  setFieldValue,
  error,
  data,
  createVehicleClass,
  updateVehicleClass,
  refetch,
  projectId,
  setDeleteConfirmationModalData,
  deleteVehicleClassesRecord,
  deleteConfirmationModalData,
}) => {
  const customHandleDelete = (setSelectedItems) => (row) => {
    const newFleet = values.fleet.filter(
      (vehicleclass) => vehicleclass.id !== row.id
    );

    setSelectedItems(newFleet.map((vehicleclass) => vehicleclass.id));
    setFieldValue('fleet', newFleet);
  };

  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );


  return (
    <>
      <DeleteConfirmationModal
        deleteConfirmationModalData={deleteConfirmationModalData}
        onClickCancel={() => {
          setDeleteConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteVehicleClassesRecord();
        }}
      />
      <ListModal
        showHeader
        disableQuery
        title="Select vehicle classes"
        searchPlaceholder="Search for a vehicle class"
        mainAction={{
          label: 'Done',
          onClick: onDone,
        }}
        error={error}
        data={data}
        customHandleDelete={customHandleDelete}
        incomingItems={values.fleet.map((vehicleclass) => vehicleclass.id)}
        columns={getColumns(isMobileView, setFieldValue, values)}
        renderSelectionColumns={getColumns(
          isMobileView,
          setFieldValue,
          values,
          true
        )}
        customListActions={{
          edit: {
            onSave: async ({ id, values }) => {
              await updateVehicleClass({
                variables: {
                  id: { id },
                  data: {
                    ...values,
                    // icon: values.icon,
                  },
                },
              });

              await refetch();
            },
          },
          create: {
            onSave: async ({ values }) => {
              await createVehicleClass({
                variables: {
                  data: {
                    ...values,
                    type: values.type,
                    // icon: values.icon,
                    event: projectId,
                  },
                },
              });

              await refetch();
            },
          },
          delete: {
            onClick: async (row) => {
              setDeleteConfirmationModalData({
                recordName: row.name,
                recordId: row.id,
                confirmationType: 'delete',
                isNotDeleteAllowed: row.vehicles.length > 0,
                deleteNotAllowedReason:
                  'This vehicle class cannot be deleted because it is being used by the vehicles.',
              });
            },
          },
        }}
        onModalClick={(toggleModal) => (
          <SelectActionButton onClick={toggleModal}>
            Select vehicle class
          </SelectActionButton>
        )}
      />
    </>
  );
};

export default withRouter(FleetClassSelector);
