import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import Button from 'components_v2/Button/Button';
import FullScreenPopover from 'components_v2/FullScreenPopover/FullScreenPopover';
import Loading from 'components_v2/Loading/CircularProgress';
import CreateClient from 'gql/Clients/Create';
import UpdateClient from 'gql/Clients/Update';
import SuccessCard from 'components_v2/Cards/SuccessCard/SuccessCard';
import { addUpdatedOrCreatedBy } from 'utils/formHelpers';
import AddClientForm from './AddClientForm';
import useStyles from './AddClient.style';
import { getContinueBodyAction } from "utils/formHelpers";

import {
  formatSubmissionValues,
  formatIncomingData,
  validationSchema,
} from './helpers';
import withFormState from "containers/Wrappers/withFormState";

const initialInputValues = {
  companyName: '',
  contactName: '',
  location: null,
  email: '',
};

const AddClient = ({ history, location, data, refetchClient, getPreviousFormState, setReturnValues }) => {
  const classes = useStyles();
  const [clientId, setClientId] = useState(null);
  const previousFormState = getPreviousFormState(location);

  const isUpdate = !!data;
  const initialValues = isUpdate
    ? { ...initialInputValues, ...formatIncomingData(data) }
    : initialInputValues;

  const successHeader = `Client ${isUpdate ? 'Updated' : 'Created'}!`;
  const successDescription = `Congratulations, a client has been ${isUpdate ? 'updated' : 'created'
    }.`;
  const title = isUpdate ? 'Update an existing client' : 'Create a new client';

  const [
    createClient,
    { loading: createMutationLoading },
  ] = useMutation(CreateClient);

  const [
    updateClient,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateClient);

  const isLoading = createMutationLoading || updateMutationLoading;

  const handleSubmit = async (values, actions) => {
    const formatted = formatSubmissionValues(values, initialValues, isUpdate);
    const valuesWithUserId = addUpdatedOrCreatedBy(formatted, isUpdate);

    if (isUpdate) {
      const id = data.id;

      const res = await updateClient({
        variables: {
          id: { id },
          data: valuesWithUserId,
        },
      });

      if (res?.data?.updateClient) {
        setClientId(res.data.updateClient.client.id);
        await refetchClient();
      }
    } else {
      const res = await createClient({
        variables: {
          data: valuesWithUserId,
        },
      });

      if (res?.data?.createClient.client.id) {
        const id = res.data.createClient.client.id;
        setClientId(id);
        if(previousFormState) setReturnValues(id, previousFormState)
        actions.resetForm(initialValues);
      }
    }

    actions.setSubmitting(false);
  };

  let mainBodyActions;

  if (previousFormState) mainBodyActions = [
    getContinueBodyAction(history, previousFormState),
  ];

  else mainBodyActions = [
    {
      text: 'Add Additional Clients',
      onClick: () => {
        if (isUpdate) history.push(`/client/new`);
        setClientId(null);
      },
    },
  ];

  if (clientId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        onCloseCallback={() => {
          history.goBack();
        }}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={{
            text: 'See Client',
            onClick: () => {
              history.push(`/clients`);
            },
          }}
          secondaryAction={{
            text: 'Customize Client',
            onClick: () => {
              if (!isUpdate) history.push(`/client/${clientId}/edit`);
              setClientId(null);
            },
          }}
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={title}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save client
                </Button>
              }
              onCloseCallback={() => {
                history.goBack();
              }}
              pageView
            >
              {AddClientForm({ ...props, classes })}
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddClient));
