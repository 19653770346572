import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import InputLabel from '../InputLabel/InputLabel';
import InputError from '../InputError/InputError';
import { useStyles, useCustomStyles } from './SelectInput.styles';

const SelectInput = ({
  type,
  label,
  placeholder,
  name,
  value,
  onChange,
  isDisabled,
  errorMessage,
  isError,
  options,
  isMultiple,
  isFreeSoloAllowed,
  groupBy,
  renderOption,
  noMargin,
  defaultValue,
  getOptionDisabled,
  autoComplete,
  filterOptions,
}) => {
  const variant = 'outlined';
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const handleOnChange = (value) => {
    let modifiedValue = value;
    if (isFreeSoloAllowed && isMultiple) {
      modifiedValue = _.map(modifiedValue, (record) => {
        if (typeof record === 'string') {
          return { name: record };
        }
        return record;
      });
    }


    onChange({
      persist: () => {},
      target: {
        type: 'change',
        name,
        value: modifiedValue,
      },
    });
  };

  // const handleonBlur = (event) => {

  //   if (event.target.value) {
  //     const modifiedValue = [...value, event.target.value];
  //     handleOnChange(modifiedValue);
  //   }
  // };

  return (
    <div
      className={customClasses.inputWrapper}
      style={{ marginBottom: noMargin ? 0 : undefined }}
    >
      <InputLabel label={label} />
      <Autocomplete
        autoSelect={isFreeSoloAllowed}
        freeSolo={isFreeSoloAllowed}
        value={value}
        onChange={
          // TODO: NEED TO REVISIT
          (event, value) => {
            handleOnChange(value);
          }
        }
        error={isError}
        groupBy={groupBy}
        filterOptions={filterOptions}
        name={name}
        type={type}
        defaultValue={defaultValue}
        options={options || []}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        renderInput={(params) => (
          // params.inputProps.onBlur = handleonBlur;
          <TextField
            {...params}
            variant={variant}
            classes={classes}
            disabled={isDisabled}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={option.name || option}
              {...getTagProps({ index })}
            />
          ))
        }
        multiple={isMultiple}
        variant={variant}
        fullWidth
        InputProps={{
          classes,

          disableUnderline: true,
        }}
        helperText={errorMessage}
        disabled={isDisabled}
      />
      <InputError error={errorMessage} />
    </div>
  );
};

export default SelectInput;
