import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'components_v2/Tooltip/Tooltip';
import { eventStopPropagation } from '../../App.helper';

const CustomAction = ({ action, row }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip title={action.successString} open={isOpen}>
      <IconButton
        onClick={(e) => {
          eventStopPropagation({ event: e })
          const result = action.onClick(row);

          if (result) {
            setIsOpen(true);
            setTimeout(() => setIsOpen(false), 2000);
          }
        }}
      >
        <action.icon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CustomAction;
