import React from "react";
import ReactJoyride from "react-joyride";

const TourGuide = ({ run, steps, callback, stepIndex }) => (
  <ReactJoyride
    stepIndex={stepIndex}
    callback={callback}
    disableCloseOnEsc
    disableOverlayClose
    continuous
    run={run}
    scrollToFirstStep
    showProgress={false}
    showSkipButton
    steps={steps}
    styles={{
      options: {
        zIndex: 10000,
      },
    }}
  />
);

export default TourGuide;
