import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import GetAll from 'gql/AccessRights/GetAll';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';

const AccessRightsPicker = (props) => {
  const { name, label, placeholder, value, onChange, noMargin } = props;

  const { data, loading } = useQuery(GetAll, {
    fetchPolicy: 'no-cache',
  });

  const options =
    !loading &&
    data &&
    data.accessrights.map((right) => ({
      name: right.name,
      id: right.id,
    }));

  return (
    <SelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options || []}
      noMargin={noMargin}
    />
  );
};

export default AccessRightsPicker;
