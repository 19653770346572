import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GetAllByEvent from 'gql/Users/GetAllByEvent';
import CreateUserEvent from "gql/UserEvents/Create";
import UpdateUserEvent from "gql/UserEvents/Update";
import DeleteUserEvent from "gql/UserEvents/Delete";
import UsersListComponent from './UsersList.Component';
import { mockData } from './UsersList.mock';
import { getFilteredUsers, getSortedUsers } from '../../../Views/Internal/Users/helper';

const UsersListContainer = ({ projectId, isHelpTutorial }) => {
  let { data, loading, refetch } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const [sort, setSort] = useState(null);
  const [filterKey, setFilterKey] = useState('')

  let finalData = getFilteredUsers({ users: data?.events?.[0]?.userevents, filterKey });
  finalData = getSortedUsers({ users: finalData, sort });

  const [
    createUserEvent,
  ] = useMutation(CreateUserEvent);

  const [
    updateUserEvent,
  ] = useMutation(UpdateUserEvent);

  const [
    deleteUserEvent,
  ] = useMutation(DeleteUserEvent);


  return (
    <UsersListComponent
      setSort={setSort}
      createUserEvent={createUserEvent}
      updateUserEvent={updateUserEvent}
      deleteUserEvent={deleteUserEvent}
      refetchUsers={refetch}
      sort={sort}
      setFilterKey={setFilterKey}
      loading={loading}
      data={
        finalData || []
      }
      projectId={projectId}
    />
  );
};

export default UsersListContainer;
