import gql from "graphql-tag";

export default gql`
  query GetStoredLocationsByLocationIds($ids: [ID]) {
    storedlocationsConnection(where: { _or: [{ id_in: $ids }] }) {
      values {
        id
        identifier
        name
        type
        address
        contactPhone
        lat
        long
      }
    }
  }
`;
