import React, { useEffect, useState } from "react";
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { Clock } from "../../Icons/Icons";
import AddRemove from 'components_v2/Inputs/AddRemove/AddRemove';

const AdjustOvertimeModal = ({ data, onClickCancel, onClickSend }) => {
  const [overtimeHours , setOvertimeHours ] = useState(0);

  useEffect(() => {
  
    setOvertimeHours(data?.record?.overtimeHours);
  
  }, [data])



  return <Modal
    size="sm"
    pageView={!!data}
  >
    <SuccessCard
      icon={
        <Clock
          style={{
            color: "#ffffff",
            fill: "white",
            width: 40,
            height: 40,
            filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
          }}
        />
      }
      mainAction={{
        text: "Adjust",
        isDisabled:!overtimeHours,
        onClick: () => {
          onClickSend(
          {
            
          id: { id:data?.record?.id},
            data: {
              overtimeHours
            }
          }
          
          )
        }
      }
      }
      secondaryAction={{
        text: "Cancel",
        onClick: onClickCancel,
      }}
      headerSize ="h4Bold"
      headerText={`Adjust overtime hours`}
    >
      <AddRemove
          value={overtimeHours}
          onChange={(event)=>setOvertimeHours(event.target.value)}
        />
    </SuccessCard>
  </Modal>
}

export default AdjustOvertimeModal;