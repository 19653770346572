export const statusGraphData = [{
  name: '5',
  value: '54',
  type: 'star'
},
{
  name: '4',
  value: '98',
  type: 'star'
},
{
  name: '3',
  value: '22',
  type: 'star'
},
{
  name: '2',
  value: '10',
  type: 'star'
},
{
  name: '1',
  value: '96',
  type: 'star'
}]