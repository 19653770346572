import gql from "graphql-tag";

export default gql`
  query GetAllDocuments($id: ID, $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String) {
    documentsConnection (where: { 
      module: $id, 
        _or: [
          {name_contains: $filterKey}
          {size_contains: $filterKey}
        ]
      }
      limit: $pageSize
      start: $startIndex
      sort: $sort
      ) {
        aggregate {
          count
        }
      values{
        id
        name
        url
        size
        uploadedBy {
          id
          firstName
          lastName
          fullName
        }
      }
      
    }
  }
`;
