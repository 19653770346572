import gql from 'graphql-tag';

export default gql`
  mutation UpdateVehicle($id: InputID, $data: editVehicleInput) {
    updateVehicle(input: { where: $id, data: $data }) {
      vehicle {
        id
      }
    }
  }
`;
