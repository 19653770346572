import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  shadows: [
    // 23 default values of 'shadows' array from https://material-ui-1dab0.firebaseapp.com/customization/themes/
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 4px 25px 0 rgba(0,0,0,.1);',
    '0 0 15px 0 rgba(0,0,0,.05);',
    '0 0 15px 0 rgba(0,0,0,.05);',
  ],
  divider: {
    padding: 24,
    margin: 24,
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 21,
    color: '#000',
    h1: {
      fontSize: '60px',
      lineHeight: '70px',
      letterSpacing: '-0.7px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '40px',
      lineHeight: '48px',
      letterSpacing: '-0.4px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '30px',
      lineHeight: '37px',
      letterSpacing: '-0.35px',
      fontWeight: 'bold',
    },
    h4: { fontSize: '24px', lineHeight: '34px', fontWeight: 'bold' },
    h5: { fontSize: '20px', lineHeight: '30px', fontWeight: 'bold' },
    body1: { fontSize: '14px', lineHeight: '134.3%', letterSpacing: '-0.2px' },
    body2: { fontSize: '16px', lineHeight: '134.3%', letterSpacing: '-0.2px' },
  },

  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      light: '#fff',
      main: '#4001C5',
      dark: '#000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#22293B',
      main: '#22293B',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
    error: {
      main: '#C50202'
    }
  },
  overrides: {
    MuiButton: {
      // Name of the component ⚛️ / style sheet
      text: {
        // Name of the rule
        color: '#000', // Some CSS
      },
      color: '#000',
    },
  },
});

export default theme;
