export const mockData = {
  events: [
    {
      id: "5f270baecc54999de0a9f579",
      userevents: [
        {
          id: "5f270bebcc54999de0a9f57a",
          user: {
            id: "5f26eb1dabc1bc0a31f344ff",
            firstName: "Jack",
            lastName: "Reeves",
            email: "jack@gmail.com",
            phone: "+971509664755",
            company: "Global Dealers",
            isSuperAdmin: false,
            __typename: "UsersPermissionsUser"
          },
          role: "client",
          accessRight: null,
          __typename: "Userevent"
        },
        {
          id: "5f397f072541ff4c2c823c8a",
          user: {
            id: "5f397f042541ff4c2c823c89",
            firstName: "James",
            lastName: "White",
            email: "james@gmail.com",
            phone: null,
            company: "Global Dealers",
            isSuperAdmin: true,
            __typename: "UsersPermissionsUser"
          },
          role: "client",
          accessRight: null,
          __typename: "Userevent"
        },

        {
          id: "5f397f9a2541ff4c2c823c90",
          user: {
            id: "5f397f982541ff4c2c823c8f",
            firstName: "Gaby",
            lastName: "Betancourt",
            email: "gaby.betancourt@publicissapient.com",
            phone: null,
            company: "Publicis Sapient",
            isSuperAdmin: false,
            __typename: "UsersPermissionsUser"
          },
          role: "client",
          accessRight: {
            id: "5f397d632541ff4c2c823c88",
            name: "Booking Agent (all bookings)",
            __typename: "Accessright"
          },
          __typename: "Userevent"
        },

        {
          id: "5f3983102541ff4c2c823c96",
          user: {
            id: "5f3982b32541ff4c2c823c94",
            firstName: "Christian",
            lastName: "Waitzinger",
            email: "christian.waitzinger@publicissapient.com",
            phone: null,
            company: "Publicis Sapient",
            isSuperAdmin: false,
            __typename: "UsersPermissionsUser"
          },
          role: "client",
          accessRight: {
            id: 3,
            name: "Event Admin",
            __typename: "Accessright"
          },
          __typename: "Userevent"
        },
        {
          id: "5f39878d2541ff4c2c823c98",
          user: {
            id: "5f39878b2541ff4c2c823c97",
            firstName: "Jose",
            lastName: "Barbosa",
            email: "jb55647@gmail.com",
            phone: "+971509664755",
            company: "Publicis Sapient",
            isSuperAdmin: true,
            __typename: "UsersPermissionsUser"
          },
          role: "client",
          accessRight: null,
          __typename: "Userevent"
        }
      ],
      __typename: "Event"
    }
  ]
};
