import React from "react";
import PhoneDisplay from '../PhoneDisplay/PhoneDisplay'
import FullNameDisplay from "../NameDisplay/FullNameDisplay";
import Typography from "components_v2/Typography/Typography";
import '../Bookings/mobile-actions.css';
import EmailDisplay from '../EmailDisplay/EmailDisplay'
export const getHamburgerMenuItems = ({ history, projectId }) => [
  {
    label: "Edit",
    onClick: (row) =>
      history.push(`/project/${projectId}/passenger/${row.id}/edit`),
  }
];

export const getMobileMenuItems = ({ history, projectId, row }) => ({
  right: [
    {
      text: " ",
      onClick: () =>
        history.push(`/project/${projectId}/passenger/${row.id}/edit`),
      className: 'mobile-actions-edit',
    },
  ],
});

export const getColumns = () => [
  {
    field: "firstName",
    headerName: "Passenger name",
    width: 2,
    render: data => (
      <FullNameDisplay firstName={data.firstName} lastName={data.lastName} />
    )
  },
  {
    field: "email",
    headerName: "Email",
    width: 2,
    hideMobile: true,
    render: data => (
      <EmailDisplay email={data.email}></EmailDisplay>)
  },
  {
    field: "passengerPriority",
    headerName: "Priority",
    width: 1,
    render: data => (
      <Typography customVariant="bodySmallRegular">
        {data.passengerPriority}
      </Typography>
    )
  },
  {
    field: "phone",
    headerName: "Phone number",
    width: 2,
    render: (data) => (
      <PhoneDisplay phone={data.phone}></PhoneDisplay>
    ),

  },
  {
    field: "company",
    headerName: "Company",
    width: 2,
    hideMobile: true,
    render: data => (
      <Typography customVariant="bodySmallRegular">{data.company}</Typography>
    )
  },
  {
    field: "notes",
    headerName: "Notes",
    width: 3,
    hideMobile: true,
    render: data => (
      <Typography customVariant="bodySmallRegular">{data.notes}</Typography>
    )
  }
];

export const actions = {
  rowClick: () => { }
};
