import React from 'react';
import _ from 'lodash';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { getIsDataPresent } from './BarGraph.helper';

const BarGraph = ({ data, config }) => {
  return (
    <>
      {getIsDataPresent(data) ? <ResponsiveContainer minHeight={507}>
        <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey={config.xAxis} />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" align="right" height={36} />
          {_.map(config.bars, (bar, index) => (
            <Bar
              key={index}
              dataKey={bar.dataKey}
              fill={bar.fill}
              name={bar.legend}
            />
          ))}
        </BarChart>
      </ResponsiveContainer> :
        <div style={{padding: 16}}>No data yet available. Please check later.</div>
      }
    </>
  );
}

export default BarGraph;
