import { getFilteredData, getSortedData } from "../../../List/helper"

export const getSortedModules = ({ modules, sort }) => {
  return getSortedData({ data: modules, sort })
}

export const getSortedDocuments = ({ documents, sort }) => {
  return getSortedData({ data: documents, sort })
}

export const getFilteredDocuments = ({ documents, filterKey }) => {
  return getFilteredData({ data: documents, filterKey })
}