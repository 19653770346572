import React from "react";
import { Box } from "@material-ui/core";
import UserProjectList from "components_v2/List/Variations/UserProjectList/UserProjectList.Container";
import FormSection from "components_v2/Inputs/FormSection/FormSection";


const UserProjectsForm = ({
  classes,
  userId,
}) => (
  <>
      <FormSection title="Add user to projects">
        <Box className={classes.userProjectsContainer}>
          <UserProjectList userId={userId} />
        </Box>
      </FormSection>
  </>
);

export default UserProjectsForm;
