import React from 'react';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import { useCustomStyles } from './NumberInputStyles';
import InputError from '../InputError/InputError';
import "./NumberInput.css";

export const NumberInput = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  isDisabled,
  error,
  step,
  min,
  max,
  adornmentPosition,
  icon,
}) => {
  const customClasses = useCustomStyles();

  return (
    <div
      className={`${customClasses.numberInputWrapper} number-input-wrapper-overrider`}
    >
      <TextInput
        label={label}
        name={name}
        isErrored={!!error}
        type="number"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
        step={step}
        min={min}
        max={max}
        adornmentPosition={adornmentPosition}
        icon={icon}
      />
      <InputError error={error} />
    </div>
  );
};
