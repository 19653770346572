import { makeStyles } from '@material-ui/core/styles';

const pricesContainerNoPadding = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

const useStyles = makeStyles({
  gridContainer: {
    marginBottom: 12,
  },
  toggleRow: {
    position: 'relative',
    bottom: 13,
  },
  uploadPhotoRow: {
    '& > div': {
      height: 300,
    },
  },

  pricesContainerNoPadding: {
    ...pricesContainerNoPadding,
    '& .MuiInputBase-root input': {
      padding: '18.5px 0',
    },
  },
  pricesContainer: {
    ...pricesContainerNoPadding,
  },
  pricesItem: {
    width: '10%',
    minWidth: 88,
    padding: '0 4px',
    '&:not(:last-of-type)': {
      marginRight: '2.8%',
    },
    '& .input-wrapper-overrider > div': {
      marginBottom: '24px',
    },
  },
  clientFacingPricesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  addRemoveContainer: {
    margin: '32px 0 48px 0',
  },
  tooltipLabelContainer: { display: 'flex', alignItems: 'center' },
  tooltipIcon: {
    width: 18,
    height: 'auto',
    marginLeft: 5,
  },
  selectDriverContainer: {
    padding: '24px',
  },
  moneyAdornment: {
    width: 16,
    height: 'auto',
    fill: '#3C3C3C',
  },
  enableVehicleContainer: {
    gridRow: 1,
    gridColumn: 2,
    alignItems: 'flex-end',
    display: 'flex',
    paddingBottom: '30px',
  },
});

export default useStyles;
