import React, { useState, useEffect } from "react";
import Typography from "components_v2/Typography/Typography";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import DeleteConfirmationModal from "../Modal/DeleteConfirmationModal/DeleteConfirmationModal";
import DeletePassengerSchedule from "../../gql/PassengerSchedules/Delete";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})
const DeleteBooking = ({ data, id, onDeleteLoading, onDelete }) => {

  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const deleteBookingRecord = async () => {
    const recordToDelete = confirmationModalData.recordId;

    setConfirmationModalData(null);

    await deletePassengerSchedule({
      variables: {
        id: recordToDelete
      },
    });

    onDelete(recordToDelete);

    // await refetchPassengerSchedules();
  };
  const [
    deletePassengerSchedule,
    { loading: deleteMutationLoading }
  ] = useMutation(DeletePassengerSchedule);

  const classes = useStyles();
  useEffect(() => { onDeleteLoading(deleteMutationLoading); }, [deleteMutationLoading])

  return (
    <>
      <DeleteConfirmationModal
        deleteConfirmationModalData={confirmationModalData}
        onClickCancel={() => {
          setConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteBookingRecord();
        }}
      />
      <div
        className={classes.item}
        id={id}
        onClick={() => {
          setConfirmationModalData({
            recordName: data.bookingId,
            recordId: data.passengerScheduleId,
            confirmationType: "delete"
          })
        }}>
        <Typography
          customVariant="bodySmallRegular"
        >
          Delete
      </Typography>
      </div>
    </>
  )
}
export default DeleteBooking