import React from 'react';
import { Box, Grid, LinearProgress, IconButton } from '@material-ui/core';

import Typography from 'components_v2/Typography/Typography';
import { Delete } from 'components_v2/Icons/Icons';
import useStyles from './DocumentStatus.style';

const Label = ({ children }) => (
  <Typography
    customVariant="bodySmallRegular"
    style={{ color: '#6A7783', fontSize: 12 }}
  >
    {children}
  </Typography>
);

const DocumentStatus = ({ status, file, onDelete }) => {
  const classes = useStyles({ status });

  const getStatusMessage = (status) =>
    status === 'uploading'
      ? 'Uploading please wait...'
      : 'Finalizing document...';

  if (status === 'deleted') return null;
  return (
    <Grid className={classes.container} container justify="space-between">
      <Grid className={classes.leftLayout} xs={4}>
        <Label>File name</Label>
        <Typography customVariant="bodySmallRegular">{file.name}</Typography>
      </Grid>
      {status !== 'completed' && (
        <Grid className={classes.rightLayout} xs={4}>
          <Typography customVariant="bodySmallRegular">
            {getStatusMessage(status)}
          </Typography>
        </Grid>
      )}
      {status === 'completed' && (
        <Grid className={classes.fileData} xs={4}>
          <Box>
            <Label>Type</Label>
            <Typography customVariant="bodySmallRegular">
              {file &&
                file.name.substr(file.name.lastIndexOf('.') + 1).toUpperCase()}
            </Typography>
          </Box>

          <Box>
            <Label>Size</Label>
            <Typography customVariant="bodySmallRegular">
              {`${parseInt(file.size).toFixed(0)}kb`}
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              onDelete(file);
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Grid>
      )}
      {(status === 'finalizing' || status === 'uploading') && (
        <LinearProgress
          className={classes.linearProgress}
          variant="indeterminate"
        />
      )}
    </Grid>
  );
};

export default DocumentStatus;
