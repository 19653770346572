import gql from 'graphql-tag';

export default gql`
  mutation DeleteDepartment($id: InputID!) {
    deleteDepartment(input: { where: $id }) {
      department {
        id
      }
    }
  }
`;
