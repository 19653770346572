import React from 'react';
import Card from 'components_v2/Cards/Card/Card';
import Button from 'components_v2/Button/Button';
import { isEmpty } from 'lodash';
import { Done, Add } from '../../Icons/Icons';

const Success = (props) => {
  const {
    children,
    headerText,
    description,
    icon,
    noIcon,
    headerSize ,
    mainAction = {},
    otherMainAction = {},
    secondaryAction = {},
    mainBodyActions = [],
    secondaryBodyAction = {},
    noBodyActions,
  } = props;

  const bodyActionArray =
    mainBodyActions.components ||
    mainBodyActions.map(({ text, onClick, icon }) => (
      <Button
        onClick={onClick}
        style={{ padding: 16, width: '100%' }}
        variant="contained"
        secondary
        startIcon={!icon ? <Add /> : icon}
      >
        {text}
      </Button>
    ));
  const mainBodyActionsElements = noBodyActions ? [] : bodyActionArray;

  const secondaryBodyActionComponent = secondaryBodyAction.component || (
    <Button onClick={secondaryBodyAction.onClick} variant="simple">
      {secondaryBodyAction.text}
    </Button>
  );
  const secondaryBodyActionElement = noBodyActions
    ? null
    : secondaryBodyActionComponent;
  return (
    <Card
      confetti
      headerSize ={headerSize}
      size="large"
      headerText={headerText}
      description={description}
      icon={
        noIcon ? undefined : icon || (
          <Done
            style={{
              color: '#FFF',
              fill: 'white',
              width: 53,
              height: 'auto',
              filter: 'drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))',
            }}
          />
        )
      }
      iconBackground="dark"
      secondaryBodyAction={secondaryBodyActionElement}
      mainAction={
        !isEmpty(mainAction)
          ? mainAction.component || (
              <Button onClick={mainAction.onClick}  disabled ={mainAction.isDisabled} variant="contained">
                {mainAction.text}
              </Button>
            )
          : undefined
      }
      otherMainAction={
        !isEmpty(otherMainAction)
          ? otherMainAction.component || (
              <Button
                onClick={otherMainAction.onClick}
                variant="contained"
                secondary
              >
                {otherMainAction.text}
              </Button>
            )
          : undefined
      }
      secondaryAction={
        !isEmpty(secondaryAction)
          ? secondaryAction.component || (
              <Button onClick={secondaryAction.onClick} variant="simple">
                {secondaryAction.text}
              </Button>
            )
          : undefined
      }
      mainBodyActions={mainBodyActionsElements}
    >
      {children}
    </Card>
  );
};

export default Success;
