export const getColumns = (projectId) => {
  let columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 3,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 3,
      render: (row) => row.duration ? `${row.duration} min` : '0 min',
    },
  ];

  if (projectId) columns = [
    ...columns, 
    {
      field: 'enabled',
      headerName: 'Status',
      width: 3,
      isSortable: false,
      type: 'checkbox',
      label: 'Enabled',
      render: (row) => row.enabled ? "Enabled" : "Disabled",
    }
  ]
  return columns;
};