import gql from 'graphql-tag';

export default gql`
  mutation DeleteUserEvent($id: InputID!) {
    deleteUserevent(input: { where: $id }) {
      userevent {
        id
      }
    }
  }
`;
