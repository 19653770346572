import React from 'react';
import _ from 'lodash';

import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';
import FleetSelector from './FleetSelector/FleetClassSelector.Container';
import StaffSelector from './StaffSelector/StaffSelector';
import FleetSupplierSelector from './FleetSupplierSelector/FleetSupplierSelector';
import ProductSelector from './ProductSelector/ProductSelector.Container';
import { handleSupplierSelectorDone, productSelectedColumns, productSelectionColumns } from './helpers';

const SuppliersForm = ({
  values,
  touched,
  errors,
  setFieldValue,
  projectId,
  formStep,
}) => {
  const handleProductSelectorDone = (products) => {
    setFieldValue('products', products);
  };

  const customHandleProductDelete = (setSelectedItems) => (row) => {
    const newProducts = values.products.filter(
      (product) => product.id !== row.id
    );

    setSelectedItems(newProducts.map((product) => product.id));
    setFieldValue('products', newProducts);
  };


  return (
    <>
      <FormSection defaultExpanded title="Enter your fleet requirements">
        <FleetSelector
          projectId={projectId}
          values={values}
          setFieldValue={setFieldValue}
          onDone={(selectedVehicleclasses) => {
            // Remove unselected vehicle classes
            const vehicleclassesRemoved = values.fleet.filter((vc) =>
              _.find(selectedVehicleclasses, { id: vc.id })
            );

            // Add newly selected vehicle classes
            const newVehicleClasses = selectedVehicleclasses.map((vc) => {
              const matchedVC = _.find(vehicleclassesRemoved, { id: vc.id });
              return matchedVC || { ...vc, quantity: 1 };
            });

            setFieldValue('fleet', newVehicleClasses);
          }}
          error={touched.fleet && errors.fleet}
          // isHelpTutorial={isHelpTutorial}
        />
      </FormSection>
      <FormSection title="Select additional staff suppliers (optional)">
        <StaffSelector values={values} setFieldValue={setFieldValue} />
      </FormSection>
      <FormSection title="Select your fleet suppliers">
        <FleetSupplierSelector
          values={values}
          setFieldValue={setFieldValue}
          onDone={handleSupplierSelectorDone(values, setFieldValue)}
          formStep={formStep}
          error={touched.fleetSuppliers && errors.fleetSuppliers}
        />
      </FormSection>
      <FormSection title="Select additional product or service suppliers (optional)">
        <ProductSelector
          values={values}
          setFieldValue={setFieldValue}
          onDone={handleProductSelectorDone}
          projectId={projectId}
          selectionColumns={productSelectionColumns}
          selectedColumns={productSelectedColumns}
          customHandleDelete={customHandleProductDelete}
        />
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default SuppliersForm;
