export const faqs = [{
  Q: "What is RDE?",
  A: "Short for “Ride” is a multifaceted fleet and driver management platform that streamlines operations from as little as one vehicle to hundreds of vehicles working within multiple modules.",
}, {
  Q: "Why RDE vs. Competitors?",
  A: "MOTUS | ONE is operators at heart, and we created this system exactly how we operate minor and mega projects. We offer features and usability that benefit our clients like no other product on the market.",
}, {
  Q: "Can I try the system before I commit to a subscription?",
  A: "Yes, we have a two-month freemium version that contains all the essential functions and reporting that allow you to run and test your own fleet or projects.",
}, {
  Q: "What are the subscription terms?",
  A: "Please see our pricing section for more details.",
}, {
  Q: "What type of support is available?",
  A: "We provide complete onboarding and 24/7 tech support for even the smallest of requests.",
}, {
  Q: "I forgot my password / can’t access my account?",
  A: "If you are trying to sign in but forgot your password, tap on the “forgot password?” link. An SMS is sent to the registered mobile number with a link to reset the password. When you open this link, you will need to enter the name with which the account was created and then reset the password. To be safe, make sure you create a unique password and do not share your password in emails or other communication.",
}, {
  Q: "What is a module?",
  A: "Within a large project, we find requirements for multiple transport systems that operate in parallel to support the primary objective while working independently. A module could be Staff Transport, Media Transport, Production Transport, or Artist Transport systems.",
}, {
  Q: "Can I assign individual departments within a module?",
  A: "Yes, during project setup, you define the requirements for your operation and client reporting.",
}, {
  Q: "Can I have the same drivers across multiple modules or projects?",
  A: "Yes, and with our schedule builder, the more drivers you have in operation, the more extensive “Hive” you create to service your clients.",
}, {
  Q: "How do I create an account?",
  A: "Our sales team will assign you a username and password for your company, and then it’s as easy as logging into motusone.com/rde",
}, {
  Q: "How do I create my first project?",
  A: "From the dashboard, simply click “New Projects” on the left toolbar to add your first project",
}, {
  Q: "Can I choose how passengers or clients receive updates?",
  A: "Yes, within the project details section of your project, you can assign and create custom messages via SMS, Push Notification, or email confirmations.",
}, {
  Q: "What SMS technology do you use?",
  A: "We integrate with Twilio that allows you to create your own custom SMS messaging.",
}, {
  Q: "How do I enter driver details?",
  A: "Within your project details section, you can start building your driver library with as many or little details as you require",
}, {
  Q: "Can I define the service type on each project?",
  A: "Yes. Within the project details section, you define the vehicle and service type assigned to the project or module",
}, {
  Q: "How do I define pricing?",
  A: "Within your project details section, you define both the client and affiliate pricing modules. This is an excellent tool for you and the end-users to monitor spend or P&L on an instant basis",
}, {
  Q: "Is there a Driver or passenger App?",
  A: "Yes, we have both within a single app and, based on the user’s setup within the project, will dictate their environment once they login for the first time.",
}, {
  Q: "Do the drivers receive booking via the APP?",
  A: "Yes, along with all details, turn-by-turn direction, and contact information.",
}, {
  Q: "How do I know if a drive has received their booking?",
  A: "Once a driver receives their booking, they must “accept” the ride, which notifies dispatch control.",
}, {
  Q: "Are drivers tracked in real-time?",
  A: "Yes, via GPS and by “Ride Cycling,” where the Driver swipes to inform the passenger and dispatch control that they are “Enroute,” “Onsite,” “Passenger in-vehicle,” etc.",
}, {
  Q: "How do I program or resolve alerts?",
  A: "You can pre-define what type of alerts you want to see, who sees them, and how they were resolved. E.g., Driver has not checked in thirty minutes before pickup, dispatch and ground operations are alerted.",
}]