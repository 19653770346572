import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  timeIndicatorContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  timeIndicatorLine: (props) => ({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: `${props.top}%`,
    height: '2px',
    borderTop: `2px #000 solid`,
    width: '100%',
    transform: 'translate(0, -1px)',
  }),
  timeIndicatorTime: {
    left: -22,
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    background: '#000',
    padding: 8,
    borderRadius: 3,
    zIndex: 100,
  },
}));
