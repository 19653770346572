import * as React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import Typography from 'components_v2/Typography/Typography';

const RouteDuration = ({ duration }) => {
  const hours = Math.floor(moment.duration(duration, 'seconds').asHours());
  const remainingMinutes =
    moment.duration(duration, 'seconds').asHours() - hours;
  const minutes = Math.floor(
    moment.duration(remainingMinutes, 'hours').asMinutes()
  );

  const hourLabel = hours > 1 ? 'hours' : 'hour';
  const minuteLabel = minutes > 1 ? 'minutes' : 'minute';
  const formattedHours = hours > 0 ? `${hours} ${hourLabel}` : '';
  const formattedMinutes = `${minutes} ${minuteLabel}`;

  return (
    <Box
      style={{
        position: 'absolute',
        right: 50,
        background: '#000',
        padding: 8,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 100,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {hours > 0 ? (
        <Typography
          customVariant="bodySmallBold"
          style={{ color: '#fff', fontSize: 10 }}
        >
          ETA: {formattedHours}
        </Typography>
      ) : null}
      <Typography
        customVariant="bodySmallBold"
        style={{ color: '#fff', fontSize: 10 }}
      >
        {hours === 0 ? 'ETA: ' : ''}
        {formattedMinutes}
      </Typography>
    </Box>
  );
};

export default RouteDuration;
