export const mockData = {
  alerts: [
    {
      id: "5fb6b09cdcf6a8228d57c6a2",
      created_at: "2020-11-19T17:51:24.564Z",
      type: "high",
      category: "driverNoShow",
      description: "Car has been delayed due to traffic.",

      status: "unresolved",
      concernedItems: {},
      alertcomments: [],
      resolvedBy: null,
      __typename: "Alerts"
    }
  ]
};
