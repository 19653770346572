import gql from "graphql-tag";

export default gql`
  query GetAllAlerts(
    $id: ID
    $sort: String
    $pageSize: Int
    $startIndex: Int
    $filterKey: String
  ) {
    alertsConnection(
      where: {
        event: $id
        _or: [
          { category_contains: $filterKey }
          { description_contains: $filterKey }
          { status_contains: $filterKey }
        ]
      }
      limit: $pageSize
      start: $startIndex
      sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        created_at
        type
        category
        description
        status
        concernedItems
        alertcomments {
          id
          message
          postedBy {
            id
            firstName
            lastName
          }
        }
        resolvedBy {
          firstName
          lastName
        }
      }
    }
  }
`;
