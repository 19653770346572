import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import "./SliderCards.css";

const useStyles = makeStyles(() => ({
  root: {
    width: 340,
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': { boxShadow: '0px 8px 28px rgba(0, 0, 0, 0.2)' },
  },
  avatar: {
    backgroundColor: 'rgba(64, 1, 197, 0.05)',
    color: '#000',
    fontSize: 24,
    lineHeight: '34px',
    width: 72,
    height: 72,
    textTransform: 'uppercase',
  },
  
}));

const SliderCards = ({ data, onSelectCard, selectedCard }) => {
  const classes = useStyles();





  return <div style={{ display: 'flex' }}>
    {
      _.map(data, (card, index) => {
        return <div key={index} style={{ marginRight: 16 }}>
          <Card
            className={classNames(classes.root, {
              'slider-cards-highlight': selectedCard?.id === card?.id,
            })}
            onClick={() => onSelectCard(card)}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {card.name ? card.name.substring(0, 2) : ''}
                </Avatar>

              }
              title={
                <Typography
                  style={{
                    fontWeight: 'normal',
                    marginLeft: 16,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: '2',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                  }}
                  variant="h5"
                >
                  {card.name}
                </Typography>
              }
            />
          </Card>
        </div>
      })
    }
  </div>

}

export default SliderCards