import gql from "graphql-tag";

export default gql`
  mutation UpdateVehiclemaintenancetracking(
    $id: InputID!
    $data: editVehiclemaintenancetrackingInput
  ) {
    updateVehiclemaintenancetracking(input: { where: $id, data: $data }) {
      vehiclemaintenancetracking {
        id
      }
    }
  }
`;
