/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import DynamicList from "components_v2/List/DynamicList";
import {
  getColumns,
  getHamburgerMenuItems,
  getMobileMenuItems
} from "./UsersListConfig";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../App.helper";

const UsersListComponent = ({
  history,
  data,
  projectId = null,
  loading,
  createUserEvent,
  updateUserEvent,
  deleteUserEvent,
  refetchUsers,
  count,
  handleListConfigChange,
  listConfig
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const columns = getColumns(projectId, isMobileView);

  return (
    <>
      <DynamicList
        isMobileView={isMobileView}
        showHeader
        loading={loading}
        data={data}
        title="Users"
        noResultsText="No users found"
        enableFreezeHeader
        headerFreezeLeftContent={"Users"}
        numRows={count}
        listConfig={{ ...listConfig, count }}
        onListConfigChange={handleListConfigChange}
        actions={{
          delete: deleteUserEvent && {
            onClick: async data => {
              const { id } = data;

              await deleteUserEvent({
                variables: {
                  id: { id: id }
                }
              });

              await refetchUsers();
            }
          },
          create: createUserEvent && {
            onSave: async data => {
              const { values } = data;
              const accessRightId = values.accessRight.id;
              const userId = values.user.id;

              await createUserEvent({
                variables: {
                  data: {
                    user: userId,
                    event: projectId,
                    accessRight: accessRightId,
                    role: "client"
                  }
                }
              });

              await refetchUsers();
            }
          },
          edit: updateUserEvent && {
            onSave: async data => {
              const { id, values } = data;

              const accessRightId = values.accessRight.id;
              const userId = values.user.id;

              await updateUserEvent({
                variables: {
                  id: { id: id },
                  data: {
                    accessRight: accessRightId,
                    user: userId
                  }
                }
              });
              await refetchUsers();
            }
          }
        }}
        hamburgerMenuItems={getHamburgerMenuItems({ history })}
        getMobileMenuItems={row => getMobileMenuItems({ history, row })}
        columns={columns}
      />
    </>
  );
};

export default withRouter(UsersListComponent);
