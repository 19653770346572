/* eslint-disable */

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from "@apollo/react-hooks";
import { withRouter } from 'react-router-dom';
import Button from 'components_v2/Button/Button';
import _ from "lodash";

import FullScreenPopover from 'components_v2/FullScreenPopover/FullScreenPopover';
import SuccessCard from 'components_v2/Cards/SuccessCard/SuccessCard';
import Loading from 'components_v2/Loading/CircularProgress';
import AddTrackingForm from './AddTrackingForm';
import { deepCopy } from 'utils/helpers';
import { getFormDisplayText } from 'utils/formHelpers';
import { getContinueBodyAction } from "utils/formHelpers";
import { validationSchema, formatIncomingData, formatSubmissionValues } from './helpers';
import {
  CreateVehicleTracking,
  UpdateVehicleTracking,
  CreateDriverTracking,
  UpdateDriverTracking,
} from './Gql';
import withFormState from "containers/Wrappers/withFormState";

const getUrlBase = (pathname) => pathname.split('/tracking')[0];

const AddTrackingComponent = ({ 
  data,
  loading,
  refetch,
  driverId,
  vehicleId,
  trackingId,
  location,
  history,
  getPreviousFormState
}) => {

  const initialInputValues = {
    type: null,
    dateOfService: null,
    cost: null,
    description: "",
    documents: [],
  };

  if (driverId) initialInputValues.driver = driverId;
  if (vehicleId) initialInputValues.vehicle = vehicleId;

  const [savedTrackingId, setSavedTrackingId] = useState(null);
  const previousFormState = getPreviousFormState(location);

  const isUpdate = !!data;
  // TODO: Format incoming data
  const initialValues = isUpdate
    ? { ...initialInputValues, ...formatIncomingData({ data, driverId }) }
    : initialInputValues;
  
  const { successHeader, successDescription, formTitle } = getFormDisplayText({ isUpdate: trackingId, recordType: 'tracking' });

  const [
    createTracking,
    { loading: createMutationLoading }
  ] = useMutation((driverId && CreateDriverTracking) || CreateVehicleTracking);

  const [
    updateTracking,
    { loading: updateMutationLoading },
  ] = useMutation((driverId && UpdateDriverTracking) || UpdateVehicleTracking);

  const isLoading = createMutationLoading || updateMutationLoading || loading;

  const handleSubmit = async (values, actions) => {
    const localValues = deepCopy(values);
    let res;

    const formatted = formatSubmissionValues({ values: localValues, driverId, initialValues, isUpdate });

    if (isUpdate) {
      res = await updateTracking({
        variables: {
          id: { id: trackingId },
          data: formatted,
        },
      });
    } else
      res = await createTracking({
        variables: {
          data: formatted,
        },
      });

    if (res?.data?.createVehiclemaintenancetracking?.vehiclemaintenancetracking) {
      setSavedTrackingId(res?.data?.createVehiclemaintenancetracking?.vehiclemaintenancetracking.id);
      actions.resetForm({});
    } 
    else if (res?.data?.createDriverongoingtracking?.driverongoingtracking) {
      setSavedTrackingId(res?.data?.createDriverongoingtracking?.driverongoingtracking.id);
      actions.resetForm({});
    } 
    else if (res?.data?.updateVehiclemaintenancetracking?.vehiclemaintenancetracking) {
      setSavedTrackingId(res?.data?.updateVehiclemaintenancetracking?.vehiclemaintenancetracking.id);
      await refetch();
    }
    else if (res?.data?.updateDriverongoingtracking?.driverongoingtracking) {
      setSavedTrackingId(res?.data?.updateDriverongoingtracking?.driverongoingtracking.id);
      await refetch();
    }
    actions.setSubmitting(false);
  }

  let mainBodyActions;

  if (!_.isEmpty(previousFormState)) mainBodyActions = [
    getContinueBodyAction(history, previousFormState),
  ];

  else mainBodyActions = [
    {
      text: 'Add Additional Trackings',
      onClick: () => {
        const urlBase = getUrlBase(pathname);

        if (isUpdate) history.push(`${urlBase}/tracking/new`);
        else setSavedTrackingId(null);
      },
    },
  ];

  const mainAction = previousFormState ?
    null :
    {
      text: 'See Trackings',
      onClick: () => {
        history.goBack();
      },
    };

  const secondaryAction = previousFormState ?
    null :
    {
      text: 'Customize Tracking',
      onClick: async () => {
        const urlBase = getUrlBase(pathname);

        if (!isUpdate) history.push(`${urlBase}/tracking/${savedTrackingId}/edit`);
        setSavedTrackingId(null);

      },
    };

  
    if (savedTrackingId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        onCloseCallback={() => {
          history.goBack();
        }}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={mainAction}
          secondaryAction={secondaryAction}
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={formTitle}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save tracking
                </Button>
              }
              onCloseCallback={() => {
                history.goBack();
              }}
              pageView
            >
              <AddTrackingForm {...props} isDriverTracking={driverId} />
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default withFormState(withRouter(AddTrackingComponent));
