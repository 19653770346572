import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from "@apollo/react-hooks"

import TransferServices from "./TransferServices.Component";
import UpdateService from 'gql/TransferServices/Update';
import CreateService from 'gql/TransferServices/Create';
import DeleteService from 'gql/TransferServices/Delete';
import GetAllByEvent from 'gql/TransferServices/GetAllByEvent';
import { getSortedTransferServices, getFilteredTransferServices } from './helper'
const TransferServicesContainer = (props) => {
  const { data = null, refetch, loading } = useQuery(GetAllByEvent, {
    variables: { id: props.projectId },
    fetchPolicy: 'no-cache',
    skip: !props.projectId,
  });

  const [createService] = useMutation(CreateService);
  const [updateService] = useMutation(UpdateService);
  const [deleteService] = useMutation(DeleteService);

  const services = !props.projectId ? props?.transferservices : data?.transferservices || [];

  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    setServicesData(services);
  }, [JSON.stringify(services)])
  const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
  let finalData = getFilteredTransferServices({ transferServices: servicesData, filterKey });
  finalData = getSortedTransferServices({ transferServices: finalData, sort })

  return (
    <TransferServices
      setSort={setSort}
      sort={sort}
      setFilterKey={setFilterKey}
      loading={loading}
      services={finalData}
      refetch={refetch}
      setServicesData={setServicesData}
      createService={createService}
      updateService={updateService}
      deleteService={deleteService}
      {...props}
    />
  )
}

export default TransferServicesContainer
