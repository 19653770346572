import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import { NetworkStatus } from "@apollo/client";
import GetAllByUser from "gql/Events/GetAllByUser";
import auth from "utils/auth";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Typography from "components_v2/Typography/Typography";
import CustomizedBadges from "../Badge/Badge";
import Search from "../Search/Search";
import Messages from "./Messages";
import Alerts from "./Alerts/Alerts";
import Account from "./Account";
import Tools from "./Tools";
import { useStyles } from "./Tools.styles";
import { getUnresolvedAlerts } from "../Views/Internal/HomeDashboard/HomeDashboard.helper";
import GetAllByEvent from "../../gql/Search/GetAllByEvent";
import { parseRealSearchData } from "./TopToolsHelper";
import CIB from "../Buttons/CIB/CIB";
import { ArrowsChevronLeft } from "../Icons/Icons";
import { mockRealSearchData } from "./TopTools.mock";
import { mockData } from "../Views/Internal/HomeDashboard/HomeDashboard.mock";
import { mobileViewBreakpoint } from "../../App.helper";
import { useDebounce } from "../Hooks/use-debounce.hook";
import useChat from "hooks/useChat";

const generateAutocompleteLinks = options =>
  options.map(option => {
    let link = "";

    if (option.type.toLowerCase().endsWith("bookings")) {
      link = `/project/${option.projectId}/bookings/${option.bookingId}`;
    } else if (option.type.toLowerCase().endsWith("locations")) {
      link = `/project/${option.projectId}/locations`;
    } else if (option.type.toLowerCase().endsWith("passengers")) {
      link = `/project/${option.projectId}/passengers`;
    } else if (option.type.toLowerCase().endsWith("drivers")) {
      link = `/driver/${option.id}`;
    }

    return {
      ...option,
      link
    };
  });

const TopTools = ({ messagesCount, match, openedTool, isHelpTutorial }) => {
  const classes = useStyles();
  const [localQueryData, setLocalQueryData] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    type: null
  });
  const anchor = "right";

  const { id } = auth.get("userInfo");

  const { projectId } = match.params;

  let { data: realSearchData, loading: realSearchLoading } = useQuery(
    GetAllByEvent,
    {
      variables: {
        id: projectId,
        name: debouncedSearchTerm,
        passengerRole: "1",
        driverRole: "4"
      },
      skip:
        !debouncedSearchTerm ||
        debouncedSearchTerm.length === 0 ||
        isHelpTutorial,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache"
    }
  );

  if (isHelpTutorial) {
    realSearchData = mockRealSearchData;
  }

  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  let { data: queryData, loading: queryLoading, networkStatus } = useQuery(
    GetAllByUser,
    {
      variables: { id: id },
      notifyOnNetworkStatusChange: true,
      skip: isHelpTutorial
    }
  );
  useEffect(() => {
    setLocalQueryData(queryData);
  }, [queryData]);

  if (isHelpTutorial) {
    queryData = mockData;
  }

  const eventData = localQueryData?.user?.userEvents.map(userEvent => ({
    ...userEvent?.event,
    role: userEvent?.role
  }));

  const unresolvedAlerts = eventData && getUnresolvedAlerts(eventData);

  const alertsData = unresolvedAlerts;
  const notificationsCount1 = unresolvedAlerts?.length;

  const toggleDrawer = (type, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open, type });
  };

  const options = generateAutocompleteLinks(
    parseRealSearchData(realSearchData),
    projectId
  );

  const renderTitleBar = () => {
    let title = "";
    let count = 0;

    switch (state.type) {
      case "account":
        title = "My Account";
        break;
      case "alerts":
        title = "Alerts";
        count = notificationsCount1;
        break;
      case "messages":
        title = "Messages";
        count = messagesCount && parseInt(messagesCount);
        break;
      default:
        break;
    }

    return (
      <div className={classes.mobileTitleBarWrapper}>
        <div className={classes.mobileTitleBar}>
          <CIB
            onClick={toggleDrawer("account", false)}
            icon={ArrowsChevronLeft}
          />
          <Typography customVariant="h4Bold">{title}</Typography>
          {count > 0 && <CustomizedBadges position="top" value={count} />}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (openedTool && openedTool.tool && openedTool.isOpen) {
      setState({
        ...state,
        [anchor]: openedTool.isOpen,
        type: openedTool.tool
      });
    }
  }, [openedTool]);
  // lazy query for updating schedules
  const [getNewAlerts, { data: lazyAlertsQueryData }] = useLazyQuery(
    GetAllByUser,
    {
      variables: { id: id },
      fetchPolicy: "no-cache",
      skip: isHelpTutorial
    }
  );

  useChat("alertUpdates", async () => {
    await getNewAlerts();
  });
  useChat("alertCreate", async () => {
    await getNewAlerts();
  });
  useEffect(() => {
    setLocalQueryData(lazyAlertsQueryData);
  }, [lazyAlertsQueryData]);
  return (
    <>
      <>
        {!isMobileView && (
          <section className={classes.root} id="top-tools">
            <Search
              groupBy="type"
              options={debouncedSearchTerm && options}
              placeholder="Search.."
              onSearchTermChange={searchTerm => setSearchTerm(searchTerm)}
              onOptionSelect={() => {}}
              favoriteOptions={[]}
              autocomplete
              loading={realSearchLoading}
            />
            <Divider orientation="vertical" className={classes.divider} />
            <Tools
              messagesCount={messagesCount}
              notificationsCount={notificationsCount1}
              onMessagesToolClick={toggleDrawer("messages", true)}
              onAlertsToolClick={toggleDrawer("alerts", true)}
              onAccountToolClick={toggleDrawer("account", true)}
            />
          </section>
        )}
        {state.right && (
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            className={classes.sidebarWrapper}
          >
            <div className={classes.sidebar}>
              <div
                className={classes.header}
                style={
                  (!isMobileView && {
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    paddingTop: "24px",
                    justifyContent: "flex-start",
                    paddingLeft: "96px"
                  }) ||
                  {}
                }
              >
                {(!isMobileView && (
                  <Tools
                    currentTool={state.type}
                    messagesCount={messagesCount}
                    notificationsCount={notificationsCount1}
                    onMessagesToolClick={toggleDrawer("messages", true)}
                    onAlertsToolClick={toggleDrawer("alerts", true)}
                    onAccountToolClick={toggleDrawer("account", true)}
                  />
                )) ||
                  renderTitleBar()}
              </div>
              {state.type === "messages" && (
                <Messages
                  messagesCount={messagesCount}
                  onClick={toggleDrawer("messages", true)}
                />
              )}
              {state.type === "alerts" && <Alerts alertsData={alertsData} />}
              {state.type === "account" && (
                <Account onCloseClick={toggleDrawer("account", false)} />
              )}
            </div>
          </SwipeableDrawer>
        )}

        {(queryLoading || networkStatus === NetworkStatus.refetch) && <> </>}
      </>
    </>
  );
};

TopTools.propTypes = {
  messagesCount: PropTypes.number,
  notificationsCount: PropTypes.number
};

TopTools.defaultProps = {
  messagesCount: 0,
  notificationsCount: 0
};

export default withRouter(TopTools);
