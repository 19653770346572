import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { ClickAwayListener, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { SearchIcon } from '../Icons/Icons';
import { deepCopy } from '../../utils/helpers';

const useStyles = ({ palette, typography }) => ({
  main: {
    transition: 'all 300ms',

    '& $input': {
      visibility: 'hidden',
      opacity: '0',
      transition: 'visibility 0s, opacity 0.5s linear',
    },

    width: '60px',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    display: 'inline-flex',
    alignItems: 'center',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  input: {
    width: '100%',
  },
  iconButton: {
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSize,
    '&:hover': {
      color: '#4001C5',
      fill: '#4001C5',
      backgroundColor: 'transparent',
    },
  },
  searchIconButton: {
    color: palette.primary.dark,
    padding: '8px',
    paddingRight: '3px',

    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  },
  extended: {
    backgroundColor: palette.primary.light,
    borderRadius: '25px',
    padding: '0 0 0 20px',
    width: '420px',

    '& $input': {
      visibility: 'visible',
      opacity: '1',
    },
  },
  autocompleteOpenContainer: {
    background: '#fff !important',
    boxShadow: '0px -10px 10px -10px rgba(66, 75, 90, 0.2)',

    '& $extended': {
      border: '1px solid rgba(0, 0, 0, 0.3)',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    },
  },
  autocompleteContainer: {
    display: 'flex',
    padding: '10px',
    paddingRight: '0',
    background: 'transparent',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    transition: 'all 300ms',
    minHeight: '65px',
    position: 'relative',
  },
  autocompleteItem: {
    padding: 0,
  },
  autocompleteGroupItemContainer: {
    listStyle: 'none',
    padding: 0,
  },
  autocompleteGroupItem: {
    padding: '16px',

    '&:hover': {
      backgroundColor: '#d9d9d9',
    },

    // TODO(PPavlov): To be extracted
    '& strong': {
      paddingTop: '8px',
      fontWeight: '400',
      fontSize: '14px',
    },
    '& p': {
      marginBottom: 0,
      color: 'rgba(0, 0, 0, 0.7)',
      fontSize: '12px',
    },
  },
  autocompleteItemHeader: {
    padding: '8px 0px 0 16px',

    '& h3': {
      margin: 0,
      fontSize: '14px',
    },
  },
  autocompleteRoot: {
    padding: '0',
    margin: '0',
    listStyleType: 'none',
    background: '#fff',
    borderTop: 'none',
    boxShadow: '0px 6px 10px rgba(66, 75, 90, 0.2)',
    borderRadius: '6px',
  },
  autoCompleteLastItem: {
    padding: '16px',

    display: 'flex',
    justifyContent: 'flex-end',

    '& a': {
      textDecoration: 'none',
      color: '4001C5',
      fontWeight: 700,
    },
  },
  autocompleteSection: {
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 100,
    transition: 'height 0.3s ease-out',
    maxHeight: '70vh',
  },
  autocompleteSectionOpened: {
    height: 'auto',
    right: '0px',
    top: '65px',
    overflow: 'auto',
    borderRadius: '6px',
    borderTop: 0,
  },
  autoCompleteRootLoading: {
    margin: '0',
    listStyleType: 'none',
    background: '#fff',
    borderTop: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});

const groupByFunc = function (xs, key) {
  return xs.reduce(function (rv, x) {
    const localRv = deepCopy(rv);
    (localRv[x[key]] = localRv[x[key]] || []).push(x);
    return localRv;
  }, {});
};

const SearchStateType = {
  Collapsed: 0,
  Expanded: 1,
  Autocomplete: 2,
};

const Search = ({
  groupBy,
  options,
  classes,
  onSearchTermChange,
  onOptionSelect,
  icon,
  placeholder,
  loading,
  favoriteOptions,
  history,
}) => {
  const [searchState, setSearchState] = useState(SearchStateType.Collapsed);

  const [searchTerm, setSearchTerm] = useState('');
  const inputContainerRef = useRef();
  const inputRef = useRef();

  useEffect(() => onSearchTermChange(searchTerm), [searchTerm]);

  const searchTermChangeHandler = ({ target }) => setSearchTerm(target.value);
  const autocompleteGroupItemClickHandler = (group, val) => {
    setSearchTerm(val.title);
    onOptionSelect(group, val);

    if (val.link) {
      history.push(val.link);
    }
  };

  const expandSearch = () => {
    setSearchState(SearchStateType.Expanded);
    setTimeout(() => inputRef.current.focus(), 300);
  };

  const optionsGroupedByType = groupByFunc(
    options || favoriteOptions || [],
    groupBy
  );

  if (searchState === SearchStateType.Expanded) {
    if (optionsGroupedByType && Object.keys(optionsGroupedByType).length) {
      setTimeout(() => setSearchState(SearchStateType.Autocomplete), 300);
    }
  }

  return (
    <ClickAwayListener
      onClickAway={() => setSearchState(SearchStateType.Collapsed)}
    >
      <div
        ref={inputContainerRef}
        className={clsx(classes.autocompleteContainer, {
          [classes.autocompleteOpenContainer]:
            searchState === SearchStateType.Autocomplete,
        })}
      >
        <div
          className={clsx(classes.main, {
            [classes.extended]: searchState >= SearchStateType.Expanded,
          })}
        >
          <InputBase
            inputRef={inputRef}
            placeholder={placeholder}
            className={classes.input}
            value={searchTerm}
            onChange={searchTermChangeHandler}
          />
          <IconButton
            className={clsx(classes.iconButton, classes.searchIconButton)}
            onClick={expandSearch}
            disableRipple
          >
            {icon || <SearchIcon />}
          </IconButton>
        </div>

        <section
          style={{
            width:
              inputContainerRef &&
              inputContainerRef.current &&
              inputContainerRef.current.clientWidth,
            marginTop: '-8px',
          }}
          className={clsx(classes.autocompleteSection, {
            [classes.autocompleteSectionOpened]:
              searchState === SearchStateType.Autocomplete,
          })}
        >
          {Object.keys(optionsGroupedByType).length ? (
            <ul className={classes.autocompleteRoot}>
              {Object.keys(optionsGroupedByType).map((key, index) => (
                <li key={index}>
                  <section className={classes.autocompleteItem}>
                    <header className={classes.autocompleteItemHeader}>
                      <Typography variant="h3">{key}</Typography>
                    </header>
                    <ul className={classes.autocompleteGroupItemContainer}>
                      {optionsGroupedByType[key].map((val) => (
                        <>
                          <li
                            className={classes.autocompleteGroupItem}
                            onClick={() =>
                              autocompleteGroupItemClickHandler(
                                optionsGroupedByType[key],
                                val
                              )
                            }
                          >
                            <Typography style={{ fontWeight: 'bold' }}>
                              {val.title}
                            </Typography>
                            {val.subtitle && (
                              <Typography
                                paragraph
                                style={{ padding: '8px 0' }}
                              >
                                {val.subtitle}
                              </Typography>
                            )}
                          </li>
                          <Divider />
                        </>
                      ))}
                    </ul>
                  </section>
                </li>
              ))}
              {!searchTerm && (
                <li className={classes.autoCompleteLastItem}>
                  <a href="#">See all favorites</a>
                </li>
              )}
            </ul>
          ) : (
            <div className={classes.autoCompleteRootLoading}>
              {loading ? <p>Loading...</p> : <p>No Search Results</p>}
            </div>
          )}
        </section>
      </div>
    </ClickAwayListener>
  );
};

Search.propTypes = {
  groupBy: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  onSearchTermChange: PropTypes.func,
  onOptionSelect: PropTypes.func,
  icon: PropTypes.element,
  extended: PropTypes.bool,
  autocomplete: PropTypes.bool,
};

Search.defaultProps = {
  onSearchTermChange: () => { },
  onOptionSelect: () => { },
  icon: null,
  extended: false,
  autocomplete: false,
};

export default withStyles(useStyles)(withRouter(Search));
