import { useMutation } from "@apollo/react-hooks";
// import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState } from "react";
import DynamicList from "components_v2/List/DynamicList";
import DocumentUpload from "components_v2/Upload/UploadDocument";

import Modal from "components_v2/Modal/Modal";
import DocumentPreview from "components_v2/DocumentPreview/DocumentPreview";
// import SortBy from "components_v2/Inputs/SortBy/SortBy";
// import Filters from "components_v2/Inputs/Filters/Filters";
import { FilesLabeled } from "components_v2/Icons/Icons";
import Typography from "components_v2/Typography/Typography";
import { getFileExtFromUrl } from "utils/file";
import auth from "utils/auth";
import CreateDocument from "gql/Documents/CreateDocument";
import DeleteDocument from "gql/Documents/DeleteDocument";
import LinearProgress from "components/Loading/LinearProgress";
import { formatFileSize } from "../../../../utils/file";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  mobileViewBreakpoint,
} from "../../../../App.helper";

// Dummy data for presentational purposes, remove when using real data
// const sortByData = [
//   {
//     label: "Alphabetically",
//     value: "alphabetically",
//     isHighlighted: false,
//   },
// ];

const MainContainer = ({
  sort,
  setSort,
  setFilterKey,
  data,
  moduleName,
  moduleId,
  refetchDocuments,
  setModuleId,
  loading,
}) => {
  const [clickedRow, setClickedRow] = useState({});
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  // const filterActions = (
  //   <div style={{ display: "flex", margin: "8px 0 16px 0" }}>
  //     <div style={{ marginRight: 16 }}>
  //       <Filters />
  //     </div>
  //     <SortBy
  //       name="Sorted by"
  //       value={sortByData[0]}
  //       list={sortByData}
  //       onChange={() => { }}
  //     />
  //   </div>
  // );

  const [
    createDocument,
    { loading: createDocumentLoading },
  ] = useMutation(CreateDocument);

  const [
    deleteDocument,
    { loading: deleteDocumentLoading },
  ] = useMutation(DeleteDocument);

  return (
    <>
      {(createDocumentLoading || deleteDocumentLoading) && <LinearProgress />}
      <DocumentUpload
        onComplete={async (data) => {
          // const { name, url, size } = data.data[0];
          const { name, url, size } = data;
          const { id: uploadedBy } = auth.getUserInfo();

          const values = {
            name,
            url,
            size: size.toString(),
            uploadedBy,
            module: moduleId,
          };

          await createDocument({
            variables: {
              data: values,
            },
          });

          await refetchDocuments();
        }}
      />

      <div style={{ margin: 24 }} />

      <Modal
        size="full"
        onModalClick={(toggleModal) => (
          <DynamicList
            sortActions={{
              currentSort: sort,
              onChangeSort: setSort
            }}
            noResultsText="No documents"
            hideActions={isMobileView}
            isMobileView={isMobileView}
            loading={loading}
            showHeader
            data={data}
            title={
              <>
                <span
                  onKeyPress={(e) => {
                    if (e.key === "Enter") setModuleId(null);
                  }}
                  tabIndex={0}
                  role="button"
                  onClick={() => setModuleId(null)}
                  style={{ cursor: "pointer" }}
                >
                  Modules
                </span>
                {` > ${moduleName}`}
              </>
            }
            numRows={data.length}
            filterActions={[
              {
                type: "text",
                onChange: (val) => {
                  setFilterKey(val)
                },
                label: "Search",
              },
            ]}
            getMobileMenuItems={(row) => {
              return {
                left: [
                  {
                    text: " ",
                    onClick: async () => {
                      await deleteDocument({
                        variables: { inputId: { id: row.id } },
                      });
                      await refetchDocuments();
                    },
                    className: "mobile-actions-delete",
                  },
                ],
              };
            }}
            hamburgerMenuItems={[
              {
                label: "Delete",
                onClick: async (row) => {
                  await deleteDocument({
                    variables: { inputId: { id: row.id } },
                  });
                  await refetchDocuments();
                },
              },
            ]}
            actions={{
              rowClick: (row) => {
                setClickedRow(row);
                toggleModal();
              },
            }}
            columns={[
              {
                field: "name",
                headerName: "Document Name",
                width: 3,
                render: (data) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FilesLabeled />
                    <Typography
                      style={{ marginLeft: 16 }}
                      customVariant="bodySmallRegular"
                    >
                      {data.name}
                    </Typography>
                  </div>
                ),
              },
              {
                field: "url",
                headerName: "File type",
                width: 3,
                render: (data) => {
                  const fileType = getFileExtFromUrl(data.url).toUpperCase();
                  return (
                    <Typography customVariant="bodySmallRegular">
                      {fileType}
                    </Typography>
                  );
                },
              },
              {
                field: "size",
                headerName: "File size",
                width: 3,
                render: (data) => (
                  <Typography customVariant="bodySmallRegular">
                    {`${formatFileSize(data.size)} KB`}
                  </Typography>
                ),
              },
              {
                field: "uploadedBy.fullName",
                headerName: "Uploaded by",
                width: 3,
                hideMobile: true,
                render: (data) => (
                  <Typography customVariant="bodySmallRegular">
                    {data.uploadedBy.fullName}
                  </Typography>
                ),
              },
            ]}
          />
        )}
      >
        <DocumentPreview fileSize={clickedRow.size} src={clickedRow.url} />
      </Modal>
    </>
  );
};

export default MainContainer;
