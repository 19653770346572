import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  tourTooltipDescriptionWrapper: {
    padding: '0 0',
    background: '#000000',
    color: '#ffffff',
  },
  tooltipDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: '#FFFFFF',
    maxWidth: '180px',
    textAlign: 'left',
  },
  tooltipTipCounter: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: '#FFFFFF',
    marginTop: '0px',
    textAlign: 'left',
    marginBottom: '8px',
  },
}));
