
export const iconSelectorOptionsData = [
  {
    value: 'Book',
  },
  {
    value: 'Diamond',
  },
  {
    value: 'Fastfood',
  },
  {
    value: 'Flower',
  },
  {
    value: 'Gift',
  },
  {
    value: 'Light',
  },
  {
    value: 'Simcard',
  },
  {
    value: 'Waterbottle',
  },
];
