import _ from "lodash";
import moment from "moment";
import { deepCopy } from "../../utils/helpers";

export const parseRideRatingsData = (rideRatingsGroupedData) => {
  const { aggregate, groupBy } = rideRatingsGroupedData.driveratingsConnection;
  const statusGraphData = [
    {
      name: "5",
      value: 0,
      type: "star",
    },
    {
      name: "4",
      value: 0,
      type: "star",
    },
    {
      name: "3",
      value: 0,
      type: "star",
    },
    {
      name: "2",
      value: 0,
      type: "star",
    },
    {
      name: "1",
      value: 0,
      type: "star",
    },
  ];

  const localStatusGraphData = _.map(statusGraphData, (status) => {
    const localStatus = deepCopy(status)

    const singleGroupByObj = _.find(
      groupBy.rating,
      (rate) => rate.key === parseInt(localStatus.name)
    );

    if (singleGroupByObj) {
      localStatus.value =
        (singleGroupByObj.connection.aggregate.count / aggregate.count) * 100;
    }
    return localStatus;
  });

  return localStatusGraphData;
};

const getCountRidesTaken = (driverschedules) => {
  const completedDriversSchedules = _.flattenDeep(
    _.filter(driverschedules, (schedule) =>
      _.find(schedule.ridestatuses, { status: "complete" })
    )
  );

  return completedDriversSchedules.length;
};

export const parseRideTakenBookedData = (passengerSchedulesData) => {
  let barData = [];
  let groupedBarData = {};

  const dateSortedPassengerSchedules = _.sortBy(
    passengerSchedulesData?.passengerschedules,
    (schedule) => schedule.start
  );

  _.map(dateSortedPassengerSchedules, (schedule) => {
    barData.push({
      name: moment(schedule.start).format("D MMM"),
      rb: parseInt(schedule.numberOfVehicles),
      rt: getCountRidesTaken(schedule.driverschedules),
    });
  });

  groupedBarData = _.groupBy(barData, "name");

  barData = _.uniqBy(barData, "name");

  barData = _.map(barData, (record) => {
    const localRecord = deepCopy(record)
    localRecord.rb = _.sumBy(groupedBarData[localRecord.name], "rb");

    localRecord.rt = _.sumBy(groupedBarData[localRecord.name], "rt");

    return localRecord;
  });

  return barData;
};

export const parseByTransferTypeData = (passengerSchedulesData) => {
  let barData = [];

  const countedBarData = _.countBy(
    passengerSchedulesData?.passengerschedules,
    "type"
  );
  barData = _.map(countedBarData, (cba, key) => {
    return { name: key, rt: cba };
  });

  return barData;
};

export const parseByVehicleTypeData = (passengerSchedulesData) => {
  let barData = [];
  const countedBarData = _.countBy(
    passengerSchedulesData?.passengerschedules,
    "requiredVehicleClass.name"
  );
  barData = _.map(countedBarData, (cba, key) => {
    return { name: key, rt: cba };
  });

  return barData;
};

export const parseByModuleTypeData = (passengerSchedulesData) => {
  let barData = [];
  const countedBarData = _.countBy(
    passengerSchedulesData?.passengerschedules,
    "department.module.name"
  );
  barData = _.map(countedBarData, (cba, key) => {
    return { name: key, rt: cba };
  });

  return barData;
};

const getRideCounts = (passengerSchedules, status) => {
  let rideCounts = 0;

  const driverschedules = _.flattenDeep(
    _.map(passengerSchedules, _.property("driverschedules"))
  );

  if (driverschedules) {
    const statusDriversSchedules = _.flattenDeep(
      _.filter(driverschedules, (schedule) =>
        _.find(schedule.ridestatuses, { status })
      )
    );

    rideCounts = (statusDriversSchedules && statusDriversSchedules.length) || 0;
  }

  return rideCounts;
};

export const parsePieChartData = (
  alertsGroupedData,
  passengerSchedulesData
) => {
  const noShow =
    (passengerSchedulesData &&
      getRideCounts(passengerSchedulesData?.passengerschedules, "noShow")) ||
    0;
  const complete =
    ((passengerSchedulesData &&
      getRideCounts(passengerSchedulesData?.passengerschedules, "complete")) ||
      0) - noShow;

  return [
    {
      name: "No Show",
      value: noShow,
      color: "rgba(28, 189, 151, 0.3)",
    },
    {
      name: "Successful bookings",
      value: complete,
      color: "#1CBD97",
    },
    {
      name: "Alerts",
      value:
        (alertsGroupedData &&
          alertsGroupedData.alertsConnection.aggregate.count) ||
        0,
      color: "rgba(28, 189, 151, 0.6)",
    },
  ];
};

export const parseAlertsPieChartData = (
  alertsUnresolvedGroupedData,
  alertsResolvedGroupedData
) => [
    {
      name: "Unresolved alerts",
      value:
        (alertsUnresolvedGroupedData &&
          alertsUnresolvedGroupedData.alertsConnection.aggregate.count) ||
        0,
      color: "#1CBD97",
    },
    {
      name: "Resolved alerts",
      value:
        (alertsResolvedGroupedData &&
          alertsResolvedGroupedData.alertsConnection.aggregate.count) ||
        0,
      color: "rgba(28, 189, 151, 0.6)",
    },
  ];

export const parseAllAlertsData = (alertsAllData) => {
  const alertsAll = alertsAllData?.alertsConnection?.values;
  const currentAlertsAllData =
    (alertsAll && deepCopy(alertsAll)) || [];

  const groupedData = {
    unresolvedAlerts: {},
    resolvedAlerts: {},
    allAlerts: {},
  };

  groupedData.unresolvedAlerts = _.groupBy(
    _.filter(currentAlertsAllData, { status: "unresolved" }),
    "category"
  );
  groupedData.resolvedAlerts = _.groupBy(
    _.filter(currentAlertsAllData, { status: "resolved" }),
    "category"
  );
  groupedData.allAlerts = _.groupBy(currentAlertsAllData, "category");

  return groupedData;
};

export const getCategoryList = (categoryValue) => {
  switch (categoryValue) {
    case "lateBooking":
      return "Last minute bookings";
    case "cancelled":
      return "Cancelled bookings";
    case "driverLateToPickup":
      return "Driver late to pickup";
    case "passengerNoShow":
      return "No Show Passengers";
    case "driverNoShow":
      return "No Show Drivers";
    default:
      return "Minor alerts";
  }
};

export const getAlertsCount = (groupedAlerts) =>
  _.flattenDeep(_.values(groupedAlerts)).length;
 
export const getCurrentCounterGraph=({currentCounterGraph ,currentCounter})=>{
    return currentCounterGraph === currentCounter
  }

  export const getCurrentPieChart=({currentPieChart ,pieChart})=>{
    return currentPieChart === pieChart
  }
  export const getCurrentBarChart=({currentBarChart ,barChart})=>{
    return currentBarChart ===barChart
  }