import { useState } from 'react';
import moment from 'moment';

export const useDate = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dayStart = moment(selectedDate).utc().startOf('day').toISOString();
  const dayEnd = moment(selectedDate).utc().endOf('day').toISOString();
  
  return {
    selectedDate,
    setSelectedDate,
    dayStart,
    dayEnd
  }
}