import React,{useState} from 'react';
import VehicleComponent from './Vehicle.Component.jsx';

const VehicleViewContainer = ({  data }) => {
    const [selectedTab, setSelectedTab] = useState('information')
    const handleTabChange = (value) => {
           setSelectedTab(value)
     };
      
  return (
    <div style={{padding:'8px'}}>
    <VehicleComponent
     isInlineView={true}
      vehicleId={data.id}
      selectedTab={selectedTab}
      onChangeTab={handleTabChange}
    /> 
    </div>
  );
};



export default VehicleViewContainer
