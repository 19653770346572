import React from "react";
import { withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import moment from "moment";
import {
  DayView,
  WeekView,
  MonthView,
  // Appointments,
  Scheduler as DXScheduler
} from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import Typography from "components_v2/Typography/Typography";
import Filters from "components_v2/Inputs/Filters/Filters";
import CIB from "../Buttons/CIB/CIB";
import { ArrowsChevronLeft, ArrowsChevronRight } from "../Icons/Icons";
import { useStyles } from "./Scheduler.style";
import { formatData } from "./helpers";
import WeekViewDayScaleCell from "./WeekViewDayScaleCell/WeekViewDayScaleCell";
import WeekViewTimeTableCell from "./WeekViewTimeTableCell/WeekViewTimeTableCell";
import WeekViewTimeScaleLabel from "./WeekViewTimeScaleLabel/WeekViewTimeScaleLabel";
// import ProjectsMonthViewAppointment from './Projects/MonthViewAppointment/MonthViewAppointment';
import ProjectsMonthViewTimeTableCell from "./Projects/MonthViewTimeTableCell/MonthViewTimeTableCell";
import DayViewDayScaleCell from "./DayViewDayScaleCell/DayViewDayScaleCell";
import DayViewTimeScaleLabel from "./DayViewTimeScaleLabel/DayViewTimeScaleLabel";
import DayViewTimeTableCell from "./DayViewTimeTableCell/DayViewTimeTableCell";
import DefaultViewSkeleton from "./Skeletons/DefaultViewSkeleton";
import DayViewSkeleton from "./Skeletons/DayViewSkeleton";
import { mobileViewBreakpoint } from "../../App.helper";

const Scheduler = ({
  numItems,
  label,
  data = [],
  type,
  selectedDate,
  defaultView,
  handleDateChange,
  view,
  setView,
  eventTimeZone,
  loading,
  onDelete,
  onChangeFilter
}) => {
  const classes = useStyles({ label, type });
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const currentDate = moment
    .utc(selectedDate || new Date())
    .format("YYYY-MM-DD");
  const formatted = formatData(type, data);

  // const getAppointmentsComponent = (type) =>
  //   type === 'project' ? (
  //     <Appointments appointmentComponent={ProjectsMonthViewAppointment} />
  //   ) : null;

  const getSchedulerContainerClass = (view, type, classes) => {
    if (view === "month" || (!view && type === "project"))
      return classes.monthSchedulerContainer;
    if (view === "week" || (!view && type === "booking"))
      return classes.weekSchedulerContainer;
    return classes.daySchedulerContainer;
  };

  const schedulerContainerClass = getSchedulerContainerClass(
    view,
    type,
    classes
  );

  const viewType = view || defaultView;

  const handleCurrentViewNameChange = currentViewName => {
    setView(currentViewName);
  };

  const handleNextClick = () => {
    const eventObj = { target: { value: null } };

    if (viewType === "day") {
      eventObj.target.value = moment(selectedDate).add(1, "day");
      handleDateChange(eventObj);
    } else if (viewType === "week") {
      eventObj.target.value = moment(selectedDate).day(7);
      handleDateChange(eventObj);
    } else if (viewType === "month") {
      eventObj.target.value = moment(selectedDate)
        .add(1, "month")
        .startOf("month");
      handleDateChange(eventObj);
    }
  };

  const handlePrevClick = () => {
    const eventObj = { target: { value: null } };

    if (viewType === "day") {
      eventObj.target.value = moment(selectedDate).subtract(1, "day");
      handleDateChange(eventObj);
    } else if (viewType === "week") {
      eventObj.target.value = moment(selectedDate).day(-7);
      handleDateChange(eventObj);
    } else if (viewType === "month") {
      eventObj.target.value = moment(selectedDate)
        .subtract(1, "month")
        .startOf("month");
      handleDateChange(eventObj);
    }
  };

  const viewOptions = [
    { name: "Week", value: "week" },
    { name: "Day", value: "day" }
  ];

  const getValue = viewType => {
    if (viewType === "month") return { name: "Month", value: viewType };
    if (viewType === "week") return { name: "Week", value: viewType };
    if (viewType === "day") return { name: "Day", value: viewType };
    return {};
  };

  const handleViewSelectChange = ({ target }) => {
    const { value: selectedView } = target;
    if (!selectedView) return;
    setView(selectedView.value);
  };

  const dataLoading = type === "booking" ? loading || !eventTimeZone : loading;

  if (dataLoading && viewType === "week") return <DefaultViewSkeleton />;

  if (dataLoading && viewType === "day") return <DayViewSkeleton />;

  return (
    <Box className={classes.scheduleTabContainer}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Box className={classes.titleContainer}>
            <Box>
              <Typography customVariant="h4Regular">
                {moment(currentDate).format(
                  `${view === "day" ? "DD " : ""}MMMM YYYY`
                )}
                {numItems > 0 && <> ({`${numItems} ${label}`})</>}
              </Typography>
            </Box>
          </Box>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <Filters
              filters={[
                {
                  type: "text",
                  onChange: val => {
                    onChangeFilter({ val });
                  },
                  label: "Search"
                }
              ]}
              isProjectsPage
            />
          </div>
        </Box>
        {type === "project" ? null : (
          <Box style={{ width: 200 }}>
            <SelectInput
              name="schedulerView"
              label="View"
              placeholder="Select a view"
              options={viewOptions}
              onChange={handleViewSelectChange}
              value={getValue(viewType)}
            />
          </Box>
        )}
      </Box>

      <Box className={schedulerContainerClass}>
        <Box
          style={{
            position: "absolute",
            left: 0,
            top: isMobileView ? -16 : 0,
            width: "100%",
            height: 44,
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: viewType === "month" || undefined ? undefined : 80,
            paddingBottom: isMobileView ? 40 : 16,
            pointerEvents: "none"
          }}
        >
          <CIB
            onClick={handlePrevClick}
            style={{ pointerEvents: "auto" }}
            icon={ArrowsChevronLeft}
            isDisabled={dataLoading}
          />

          <CIB
            onClick={handleNextClick}
            style={{ pointerEvents: "auto" }}
            icon={ArrowsChevronRight}
            isDisabled={dataLoading}
          />
        </Box>
        <DXScheduler data={formatted}>
          <ViewState
            currentDate={currentDate}
            currentViewName={view || defaultView}
            onCurrentViewNameChange={handleCurrentViewNameChange}
          />
          <MonthView
            name="month"
            timeTableCellComponent={ProjectsMonthViewTimeTableCell}
          />

          <WeekView
            name="week"
            startDayHour={0}
            endDayHour={24}
            cellDuration={60}
            dayScaleCellComponent={props => (
              <WeekViewDayScaleCell
                onDateChange={handleDateChange}
                eventTimeZone={eventTimeZone}
                setView={setView}
                {...props}
              />
            )}
            timeTableCellComponent={props => (
              <WeekViewTimeTableCell
                formatted={formatted}
                eventTimeZone={eventTimeZone}
                {...props}
                onDelete={onDelete}
              />
            )}
            timeScaleLabelComponent={WeekViewTimeScaleLabel}
          />
          <DayView
            name="day"
            startDayHour={0}
            endDayHour={24}
            cellDuration={60}
            dayScaleCellComponent={props => (
              <DayViewDayScaleCell setView={setView} {...props} />
            )}
            timeScaleLabelComponent={DayViewTimeScaleLabel}
            timeTableCellComponent={props => (
              <DayViewTimeTableCell
                onDelete={onDelete}
                formatted={formatted}
                eventTimeZone={eventTimeZone}
                {...props}
              />
            )}
          />
          {/* Removed this code so that the /projects page would stop crashing. Uncomment and save to see the error causing the page to crash */}
          {/* {getAppointmentsComponent(type)} */}
        </DXScheduler>
      </Box>
    </Box>
  );
};

export default withRouter(Scheduler);
