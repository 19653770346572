import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProjectSelector from 'components_v2/ProjectSelector/ProjectSelector';
import Tabs from 'components_v2/Tabs/Tabs';
import Typography from 'components_v2/Typography/Typography';
import React from 'react';
import { mobileViewBreakpoint } from '../../../../App.helper';

const ProjectsView = React.memo(
  ({ tabs, selectedTab, handleTabChange, setProjectSelectorLoading, loading, selectedDate, data }) => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

    return (
      <div style={
        isMobileView
          ? { paddingBottom: 64 }
          : {}
      }>
        <ProjectSelector
          preventDefaultLoadingBar
          setIsLoading={setProjectSelectorLoading}
          render={(projectName) => (
            <Typography customVariant={(isMobileView && 'h4Bold') || 'h2Bold'}>
              {projectName}
            </Typography>
          )}
        />

        <Tabs
          scrollable
          noBg
          noPadding
          conditionalTabsLoading={loading}
          dependencies={[selectedDate, selectedTab, JSON.stringify(data)]}
          tabs={tabs}
          selectedTab={selectedTab}
          onChangeTab={handleTabChange}
        />
      </div>
    );
  }
);

export default ProjectsView;
