import React from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
// import { uploadFromURL } from "utils/upload";

/*
gql`
  query GetAllDrivers {
    users(where: { role: 4 }) {
      id
      email
      firstName
      lastName
      phone
      confirmed
      username
      role {
        id
        name
      }
    }
  }
`
*/

class Default extends React.Component {
  render() {
    const { query, variables, skip, notifyOnNetworkStatusChange } = this.props;

    return (
      <Query
        notifyOnNetworkStatusChange={notifyOnNetworkStatusChange}
        query={query}
        fetchPolicy={this.props.fetchPolicy}
        variables={variables}
        pollInterval={this.props.pollInterval}
        skip={skip}
      >
        {({ loading, error, data, refetch, networkStatus }) =>
          this.props.children({ loading, error, data, refetch, networkStatus })
        }
      </Query>
    );
  }
}

export default Default;
