import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import auth from "../utils/auth";

const endpoint =
  process.env.REACT_APP_GQL_ENDPOINT || ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/graphql");

const appCache = new InMemoryCache();

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

const client = new ApolloClient({
  defaultOptions,
  uri: endpoint,
  cache: appCache,
  request: async operation => {
    const token = JSON.parse(localStorage.getItem("jwtToken"));
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    let invalidToken = false;
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => {
        if (message.indexOf("Invalid token") > -1) {
          invalidToken = true;
        }
        return null;
      });
    }

    if (networkError) console.error(`[NETWORK ERROR]: ${networkError}`);
    if (invalidToken) {
      auth.clearAppStorage();
      window.location.reload();
    }
  }
});

export default client;