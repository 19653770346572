import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Loading from "components_v2/Loading/CircularProgress";
import SwipeWrapper from "./SwipeWrapper";
import FieldValue from "./FieldValue";
import Actions from "./Actions";
import ModuleTableActions from "./ModuleTableActions";
import { SlideElement } from "./SlideElement";
import { eventStopPropagation } from "../../App.helper";
import {
  getDisplayName,
  getIsModuleTableAndMobileView,
  getCursor
} from "./helper";
// eslint-disable-next-line complexity
const ListItem = props => {
  const {
    actions,
    actionsOverlay,
    addHighlightClass,
    columns,
    customActions,
    customValueChange,
    detailPanel,
    disableSelection,
    editMode,
    getHamburgerMenuItems,
    getMobileMenuItems,
    hamburgerMenuItems,
    handleValueChange,
    hideActions,
    idSuffix,
    invisibleComponent,
    isAutoClose,
    isCheckboxVisible,
    isMobileView,
    loading,
    loadingRecords = [],
    mainColumnsWidth,
    moduleTable,
    newMode,
    newRow,
    onClick,
    open,
    overlayOpen,
    renderLeftBorder,
    row,
    toggleModal,
    selectedItems,
    setDynamicListState,
    setNewMode,
    setOverlayOpen,
    singleSelection,
    stateValues
  } = props;

  const isLoading = loadingRecords.includes(row.id);

  return (
    <>
      {typeof invisibleComponent === "function" && invisibleComponent(row)}
      <div
        className={`dynamic-list-item ${
          addHighlightClass() ? "highlight-dynamic-list-item" : ""
        }`}
        style={{
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxSizing: "border-box",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
          borderLeft: renderLeftBorder
            ? renderLeftBorder(row)
            : "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: 6,
          margin: moduleTable ? "16px 0" : "8px 0",
          backgroundColor: "#fff",
          opacity: loading ? 0.5 : 1,
          position: "relative",
          cursor:
            actions?.rowClick || actions?.checkbox
              ? "pointer"
              : detailPanel
              ? "pointer"
              : "default"
        }}
        onClick={toggleModal || onClick}
      >
        {isLoading && (
          <Loading
            fullPage
            backgroundPosition="absolute"
            backgroundWidth="100%"
            backgroundHeight="100%"
            size={60}
          />
        )}
        <SwipeWrapper
          isMobileView={isMobileView}
          mobileMenuItems={getMobileMenuItems && getMobileMenuItems(row)}
          row={row}
          editMode={editMode}
          isDetailedPanelOpen={open}
          isAutoClose={isAutoClose}
        >
          <div
            onClick={() => {
              if (detailPanel && detailPanel(row)) {
                setDynamicListState({ open: !open });
              } else {
                if (actions && actions.rowClick) {
                  typeof toggleModal === "function"
                    ? toggleModal()
                    : actions.rowClick(row);
                }
                if (
                  actions?.checkbox?.onClick &&
                  !actions.checkbox.disabled(row, selectedItems)
                )
                  actions.checkbox.onClick(row);
              }
            }}
            style={{
              padding: "17px 16px",
              display: "flex",
              position: "relative",
              flexDirection: isMobileView ? "column" : ""
            }}
          >
            <Grid item xs={mainColumnsWidth}>
              <Grid
                container
                spacing={0}
                style={{
                  alignItems: moduleTable ? "flex-start" : "center",
                  display: getIsModuleTableAndMobileView({
                    moduleTable,
                    isMobileView
                  })
                    ? "block"
                    : "flex",
                  height: "100%",
                  justifyContent: "space-around"
                }}
              >
                {columns.map((column, index) => (
                  <Box
                    key={index}
                    item
                    component={Grid}
                    md={column.width}
                    sm={3}
                    xs={3}
                    lg={column.width}
                    display={getDisplayName({ moduleTable, column })}
                    style={{
                      flex: column.isFullWidth ? isMobileView : "1",
                      maxWidth: column.isFullWidth ? isMobileView : "100%"
                    }}
                  >
                    {column.noTypographyWrapper ? (
                      <div
                        style={{
                          paddingRight: getIsModuleTableAndMobileView({
                            moduleTable,
                            isMobileView
                          })
                            ? "0"
                            : 8
                        }}
                      >
                        <FieldValue
                          row={row}
                          column={column}
                          editMode={editMode}
                          stateValues={stateValues}
                          moduleTable={moduleTable}
                          handleValueChange={handleValueChange}
                          customValueChange={customValueChange}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "rgba(0, 0, 0, 0.9)",
                          fontSize: 14,
                          lineHeight: "134.3%",
                          paddingRight: getIsModuleTableAndMobileView({
                            moduleTable,
                            isMobileView
                          })
                            ? "0"
                            : 12,
                          wordBreak: "break-word"
                        }}
                      >
                        <FieldValue
                          row={row}
                          column={column}
                          editMode={editMode}
                          stateValues={stateValues}
                          moduleTable={moduleTable}
                          handleValueChange={handleValueChange}
                          customValueChange={customValueChange}
                        />
                      </div>
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>

            <Grid
              onClick={e => {
                if (!moduleTable) {
                  eventStopPropagation({ event: e });
                }
                if (actions && actions.rowClick) {
                  typeof toggleModal === "function"
                    ? toggleModal()
                    : actions.rowClick(row);
                }

                // actions.edit.onClick(row);
              }}
              item
              align="right"
              style={{
                position: moduleTable && "absolute",
                top: moduleTable && "28%",
                right: moduleTable && "0",
                display:
                  (((!hideActions && !isMobileView) ||
                    editMode ||
                    isCheckboxVisible) &&
                    "flex") ||
                  "none",
                marginRight:
                  ((!hideActions && !isMobileView) ||
                    editMode ||
                    isCheckboxVisible) &&
                  ((moduleTable && ((singleSelection && "0") || "0")) || "0"),
                alignItems: "center",
                marginLeft: isMobileView ? 0 : "auto",
                justifyContent: isMobileView ? "space-between" : "",
                cursor: getCursor({ actions })
              }}
            >
              <SlideElement
                actionsOverlay={actionsOverlay}
                row={row}
                setOverlayOpen={setOverlayOpen}
                overlayOpen={overlayOpen}
              />

              <Actions
                actions={actions}
                customActions={customActions}
                detailPanel={detailPanel}
                disableSelection={disableSelection}
                editMode={editMode}
                getHamburgerMenuItems={getHamburgerMenuItems}
                hamburgerMenuItems={hamburgerMenuItems}
                idSuffix={idSuffix}
                loading={loading}
                moduleTable={moduleTable}
                newMode={newMode}
                newRow={newRow}
                open={open}
                row={row}
                selectedItems={selectedItems}
                setDynamicListState={setDynamicListState}
                setNewMode={setNewMode}
                setOverlayOpen={setOverlayOpen}
                singleSelection={singleSelection}
                values={stateValues}
              />
              {moduleTable && (
                <div
                  style={{
                    borderLeft: "1px solid #eee",
                    width: "fit-content"
                  }}
                >
                  <div style={{ paddingLeft: 8, marginRight: -8 }}>
                    <ModuleTableActions />
                  </div>
                </div>
              )}
            </Grid>
          </div>

          {detailPanel && (
            <Collapse in={open}>
              <div style={{ backgroundColor: "#f9f9f9", cursor: "default" }}>
                {open && detailPanel(row)}
              </div>
            </Collapse>
          )}
        </SwipeWrapper>
      </div>
    </>
  );
};

export default ListItem;
