import gql from "graphql-tag";

export default gql`
  query GetAllVehiclesByEventOrSupplier($event: ID, $supplier: ID, $requiredVehicleClass: ID) {
    vehicles(where: { _or: [{ supplier: $supplier}, {event: $event }], vehicleClass: { id_eq: $requiredVehicleClass } }) {
      id
      make
      model
      year
      plateNumber
      color
      vehicleNumber
      maxPassengers
      type
      enabled
      notes
      plateNumber
      status
      vehicleClass {
        id
        name
        maxPassengers
      }
      driverschedules {
        id
        vehicle {
          id
          vehicleNumber
          make
          model
          year
          maxPassengers
          plateNumber
        }
        driver {
          fullName
          firstName
          lastName
          phone
          email
          identifier
        }
        passengerschedule {
          id
          start
          stop
          type
          vehicleClass
          flightNumber
          numberOfVehicles
          bookingId
          type
          origin
          driverschedules {
            id
            vehicle {
              id
              vehicleNumber
              make
              model
              year
              maxPassengers
              plateNumber
            }
            driver {
              fullName
              firstName
              lastName
              phone
              email
              identifier
            }
          }
          requiredVehicleClass {
            id
            name
            maxPassengers
          }
          passenger {
            id
            fullName
            firstName
            lastName
            email
            phone
            event {
              id
            }
          }
          from {
            id
            name
            address
            lat
            long
          }
          to {
            id
            name
            address
            lat
            long
          }
        }
      }
      assignedDrivers {
        id
        notes
        start
        stop
        wholeEvent
        vehicle {
          id
        }
        driver {
          id
          identifier
          fullName
          firstName
          lastName
          phone
          company
          driveratings {
            id
            rating
          }
        }
      }
    }
  }
`;
