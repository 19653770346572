import React from "react";

import ActionPaddingWrapper from "./ActionPaddingWrapper";
import Menu from 'components_v2/Menu/Menu';
import { setFocusOnElement } from '../../utils/helpers';
import { eventStopPropagation } from "../../App.helper";


const DropdownAction = (props) => {
  const {
    hamburgerMenuItems,
    getHamburgerMenuItems,
    row,
    moduleTable,
  } = props;

  function itemsValidation(items, propName) {
    if (!Array.isArray(items)) throw Error(`${propName} prop resulted in a value of ${items}. 'getHamburgerMenuItems' prop must be a function that returns an array OR 'hamburgerMenuItems' prop must be an array!`);
  }

  function getItems(hamburgerMenuItems, getHamburgerMenuItems, row) {
    let items = null;

    if (hamburgerMenuItems) {
      itemsValidation(hamburgerMenuItems, 'hamburgerMenuItems');
      items = hamburgerMenuItems;
    }
    else if (typeof getHamburgerMenuItems === 'function') {
      itemsValidation(getHamburgerMenuItems(row), 'getHamburgerMenuItems');
      items = getHamburgerMenuItems(row);
    }

    return items;
  }

  const items = getItems(hamburgerMenuItems, getHamburgerMenuItems, row);

  if (!items) return null;

  return (
    <ActionPaddingWrapper moduleTable={moduleTable}>
      <Menu
        items={items}
        row={row}
        onMenuClick={(event) => {
          eventStopPropagation({ event })
          const clickedElement = event.target.tagName;

          let focusTarget = null;

          if (clickedElement === 'path') {
            focusTarget =
              event.target.parentNode.parentNode.parentNode.parentNode
                .parentNode.parentNode.parentNode;
          } else if (clickedElement === 'svg') {
            focusTarget =
              event.target.parentNode.parentNode.parentNode.parentNode
                .parentNode.parentNode;
          } else if (clickedElement === 'span') {
            focusTarget =
              event.target.parentNode.parentNode.parentNode.parentNode
                .parentNode.parentNode;
          } else if (clickedElement === 'BUTTON') {
            focusTarget =
              event.target.parentNode.parentNode.parentNode.parentNode;
          }

          setFocusOnElement(true, focusTarget, 'relative');
        }}
        onMenuClose={() => setFocusOnElement(false)}
      />
    </ActionPaddingWrapper>
  );
};

export default DropdownAction;