import * as React from "react";
import {
  BaseControl
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Goal, UserAdd } from "../../Icons/Icons";
import "./LocationMarker.css";
import RouteDuration from "../RouteDuration";



export default class CustomMarker extends BaseControl {
  _render() {
    const {
      icon,
      name,
      type,
      long: longitude,
      lat: latitude,
      index,
      count,
      duration,
      asDirected,
      onClick,
      address,
    } = this.props;

    const Icon = icon;
    const [x, y] = this._context.viewport.project([longitude, latitude]);

    const markerStyle = {
      position: "absolute",
      left: x,
      top: y,
      transform: 'translate(-50%, -50%)'
    };

    return Icon ? <div className="icon-wrapper invisible" 
        onMouseOver={() => onClick({ longitude, latitude, name, type, address })}
        onMouseOut={() => onClick(null)}
        ref={this._containerRef}
        style={markerStyle}
        onClick={() => onClick({ longitude, latitude, name, address })}><Icon /></div>
      : <div
        onMouseOver={() => onClick({ longitude, latitude, name, address })}
        onMouseOut={() => onClick(null)}
        ref={this._containerRef}
        style={markerStyle}
        onClick={() => onClick({ longitude, latitude, name, address })}
      >
        {index === count - 1 && index !== 0 && !asDirected && (
          <div className="icon-wrapper">
            <Goal />
            {!asDirected && <RouteDuration duration={duration} />}
          </div>
        )}
        {index !== 0 && index !== count - 1 && (
          <div className="icon-wrapper">{index}</div>
        )}
        {index === 0 && (
          <div className="icon-wrapper">
            <UserAdd />
          </div>
        )}
      </div>
  }
}

/*
export default class CustomMarker extends BaseControl {
  _render() {
    const { longitude, latitude, setActivePopover, id } = this.props;

    const [x, y] = this._context.viewport.project([longitude, latitude]);

    const markerStyle = {
      position: "absolute",
      left: x,
      top: y
    };

    if (longitude && latitude) {
      return (
        <div
          ref={this._containerRef}
          style={markerStyle}
          //onClick={() => setActivePopover(id)}
        >
          <img
            style={{
              "-webkit-user-drag": "none",
              "-khtml-user-drag": "none",
              "-moz-user-drag": "none",
              "-o-user-drag": "none",
              "user-drag": "none"
            }}
            src={"/icons/vehicles/SUV.png"}
          />
        </div>
      );
    }
    return;
  }
}
*/
