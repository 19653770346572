import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { AlertFill } from '../../Icons/Icons';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  spacer: {
    display: 'none',
    marginTop: 40,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'block',
    },
  },
  errorsList: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    border: 'none',
    borderLeft: '4px solid #C50202',
    padding: '16px',
    color: 'black',

    '& .MuiAlert-message': {
      padding: 0,
    },

    '& p': {
      marginTop: 0,
      marginBottom: '12px',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '13px',
        fill: '#C50202',
        size: 20,
        flexShrink: 0,
      },
    },

    '& .MuiAlert-message div': {
      marginTop: '8px',
      fontSize: '14px',
    },

    '& .MuiAlert-icon': {
      fontSize: '24px',
    },
  },
  errorHighlight: {
    fontWeight: 'bold',
  },
}));

const TotalErrors = ({ errors = {}, touched = {}, withSpacer }) => {
  const classes = useStyles();

  const touchedKeys = touched && _.keys(touched);

  const displayKeys = touchedKeys.filter((touchedKey) => errors[touchedKey]);

  return (
    (displayKeys && displayKeys.length && (
      <>
        {withSpacer ? <div className={classes.spacer} /> : null}
        <div className={classes.root}>
          <Alert
            variant="outlined"
            severity="error"
            icon={false}
            className={classes.errorsList}
          >
            <p>
              <AlertFill />{' '}
              <span>
                There are{' '}
                <span className={classes.errorHighlight}>
                  {displayKeys.length}
                </span>{' '}
                items that require your attention, amend this to submit the form
              </span>
            </p>
            {_.map(displayKeys, (displayKey, index) => (
              <div className={classes.errorHighlight} key={index}>
                {displayKey}
              </div>
            ))}
          </Alert>
        </div>{' '}
      </>
    )) || <></>
  );
};

export default TotalErrors;
