import React, { useState, useContext, createContext } from 'react';
 
const RouteContext = createContext(null);
 
const useRouteData = () => {
  const [routeData, setRouteData] = useContext(RouteContext);

  const handleRouteData = (value) => {
    setRouteData(value);
  };

  return { routeData, onChange: handleRouteData };
};

const RouteDataProvider = ({ children }) => {
  const [routeData, setRouteData] = useState({
    currentPath: null,
    prevPath: null,
  });

  return (
    <RouteContext.Provider value={[routeData, setRouteData]} >
      {children}
    </RouteContext.Provider>
  )
}
 
export { useRouteData, RouteDataProvider };