import React from 'react';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from 'components_v2/Button/Button';
import SearchInput from 'components_v2/Inputs/SearchInput/SearchInput';
import Accordion from 'components_v2/Accordion/Accordion';
import Typography from 'components_v2/Typography/Typography';
import { faqs } from './helper';
import _ from "lodash"

const Help = ({ history }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography customVariant="h1Bold">Help</Typography>
      <section className={classes.sections}>
        <Typography customVariant="h2Bold">Get Started</Typography>
        <Typography customVariant="bodyLarge" style={{ marginTop: '1em' }}>
          Need some more help from the onboarding tutorial?
        </Typography>
        <ul className={classes.unorderedList}>
          <li className={classes.listedItems}>
            <a style={{ textDecoration: 'none' }} href="#">
              <Typography customVariant="body" className={classes.hyperlinks}>
                Viewing all current projects
              </Typography>
            </a>
          </li>
          <li className={classes.listedItems}>
            <a style={{ textDecoration: 'none' }} href="#">
              <Typography customVariant="body" className={classes.hyperlinks}>
                Viewing all bookings
              </Typography>
            </a>
          </li>
          <li className={classes.listedItems}>
            <a style={{ textDecoration: 'none' }} href="#">
              <Typography customVariant="body" className={classes.hyperlinks}>
                Inspecting an alert
              </Typography>
            </a>
          </li>
          <li className={classes.listedItems}>
            <a style={{ textDecoration: 'none' }} href="#">
              <Typography customVariant="body" className={classes.hyperlinks}>
                Tracking rides on map
              </Typography>
            </a>
          </li>
          <li className={classes.listedItems}>
            <a style={{ textDecoration: 'none' }} href="#">
              <Typography customVariant="body" className={classes.hyperlinks}>
                Get suggested actions from the system
              </Typography>
            </a>
          </li>
        </ul>
        <Button onClick={() => history.push('/help/dashboard')}>See full tutorial</Button>
      </section>
      <section className={classes.sections}>
        <Typography customVariant="h2Bold">RDE FAQ</Typography>
        <Typography customVariant="bodyLarge" style={{ margin: '1em 0' }}>
          Here are some frequently asked questions
        </Typography>
        <GlobalStyle />
        <SearchInput placeholder="Search FAQ's" />
        {
          _.map((faqs), (faq) => {
            return <Accordion summary={faq.Q}>
              <Typography customVariant="body">
                {faq.A}
              </Typography>
            </Accordion>
          })
        }
      </section>
    </div>
  );
};

const useStyles = makeStyles({
  sections: {
    margin: '2.5em 0',
  },
  unorderedList: {
    listStyleType: 'none',
    padding: '0',
    margin: '1.5em 0',
  },
  listedItems: {
    margin: '1em 0',
  },
  hyperlinks: {
    fontWeight: '700',
    color: 'rgba(64, 1, 197, 1)',
    padding: '0',
  },
});

const GlobalStyle = withStyles({
  '@global': {
    '.makeStyles-searchInput-78': {
      margin: '.5em 0 1.5em',
    },
  },
})(() => null);

export default withMainLayout('Help', {}, Help);
