import React from 'react';

import currencyOptions from 'utils/currencies';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';

const CurrencySelect = (props) => {
  const { name, label, placeholder, value, onChange, noMargin, error } = props;

  return (
    <SelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={currencyOptions}
      noMargin={noMargin}
      isError={error}
      errorMessage={error}
    />
  );
};

export default CurrencySelect;
