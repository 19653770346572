import React, { useState } from "react";
import Typography from "components_v2/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SelectionModal from "components_v2/Modal/SelectionModal/SelectionModal";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})
const DriverAddBooking = ({ data, onClickConfirm }) => {
  const [modalData, setModalData] = useState(null);
  const classes = useStyles();

  return (
    <>
      <SelectionModal
        data={modalData}
        onClickCancel={() => {
          setModalData(null);
        }}
        onClickConfirm={onClickConfirm}
      />
      <div
        className={classes.item}
        onClick={() => {
          setModalData(data)
        }}>
        <Typography
          customVariant="bodySmallRegular"
        >
          Add Booking
      </Typography>
      </div>
    </>
  )
}
export default DriverAddBooking;