import _ from 'lodash';
import { deepCopy } from '../../utils/helpers';
import moment from "moment";

export const parseRealSearchData = (realSearchData) => {
  if (!realSearchData || realSearchData.length === 0) return [];

  const modifiedRealSearchData = deepCopy(realSearchData);

  const parsedRealSearchData = [];

  _.map(
    _.slice(
      _.concat(
        modifiedRealSearchData.passengerscheduleBookingIdContains,
      ),
      0,
      3
    ),
    (passengerschedule) => {
      parsedRealSearchData.push({
        title: passengerschedule?.bookingId + ' ' + moment
          .utc(passengerschedule?.start)
          .format('DD/MM/YYYY [at] HH:mm'),
        subtitle: passengerschedule?.passenger?.firstName + ' ' + passengerschedule?.passenger?.lastName,
        type: 'Bookings',
        bookingId: passengerschedule?.bookingId,
        projectId: passengerschedule?.event?.id
      });
    }
  );

  _.map(
    _.slice(
      _.concat(
        modifiedRealSearchData.locationNameContains,
        modifiedRealSearchData.locationIdentifierContains
      ),
      0,
      3
    ),
    (location) => {
      parsedRealSearchData.push({
        title: location?.name,
        subtitle: location?.address,
        type: 'Locations',
        projectId: location?.event?.id
      });
    }
  );

  _.map(
    _.slice(
      _.concat(
        modifiedRealSearchData.passengerFirstNameContains,
        modifiedRealSearchData.passengerLastNameContains,
        modifiedRealSearchData.passengerIdentifierContains,
      ),
      0,
      3
    ),
    (passenger) => {
      parsedRealSearchData.push({
        title: `${passenger?.firstName} ${passenger?.lastName}`,
        subtitle: passenger?.email,
        type: 'Passengers',
        projectId: passenger?.event?.id
      });
    }
  );

  _.map(
    _.slice(
      _.concat(
        modifiedRealSearchData.driverFirstNameContains,
        modifiedRealSearchData.driverLastNameContains,
        modifiedRealSearchData.driverIdentifierContains
      ),
      0,
      3
    ),
    (driver) => {
      parsedRealSearchData.push({
        title: `${driver?.firstName} ${driver?.lastName}`,
        subtitle: driver?.email,
        type: 'Drivers',
        id: driver?.id,
        projectId: driver?.event?.id
      });
    }
  );

  return parsedRealSearchData;
};
