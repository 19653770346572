import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    '& .MuiTab-textColorInherit': {
      opacity: 1,
      color: '#000000',
    },
  },
  tabsRoot: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  tabsIndicator: {
    backgroundColor: '#4001C5',
    height: 4,
    borderRadius: 4,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    padding: 0,
    marginRight: 24,
    fontSize: 16,
    lineHeight: '134.3%',

    '&:hover': {
      color: '#4001c5',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#000',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#000',
    },
  },
  tabSelected: {},
}));
