import gql from 'graphql-tag';

export default gql`
  mutation CreatePassengerSchedule($data: PassengerscheduleInput) {
    createPassengerschedule(input: { data: $data }) {
      passengerschedule {
        id
        bookingId
        isReocurring
        reocurringDates
        createdBy {
          email
        }
      }
    }
  }
`;
