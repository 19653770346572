import gql from 'graphql-tag';

export default gql`
  mutation UpdateEvent($id: InputID, $data: editEventInput) {
    updateEvent(input: { where: $id, data: $data }) {
      event {
        id
      }
    }
  }
`;
