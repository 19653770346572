import React from "react";
// import Modal from '@material-ui/core/Modal';
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { WarningWhite } from "../../Icons/Icons";

const DeleteRideStatusConfirmationModal = ({
  data,
  onClickCancel,
  onClickConfirm,
}) => {

  return (
    <Modal
      size="sm"
      pageView={data?.confirmationType === "delete"}
    >
      <SuccessCard
        icon={
          <WarningWhite
            style={{
              color: "#FFF",
              fill: "white",
              width: 40,
              height: 40,
              filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
            }}
          />
        }
        mainAction={
          (data?.isNotDeleteAllowed ? undefined : {
            text: "Confirm",
            onClick: () => onClickConfirm(data.recordId),
          })
        }
        secondaryAction={{
          text: "Cancel",
          onClick: onClickCancel,
        }}
        headerText={
          (data?.isNotDeleteAllowed ? data?.deleteNotAllowedReason :
            data?.headerText || `Are you sure you want to delete "${data?.recordName}" status?`)
        }
        description={
          (data?.isNotDeleteAllowed ? undefined :
            data?.description || "This cannot be undone")
        }
      />
    </Modal>
  );
};

export default DeleteRideStatusConfirmationModal;
