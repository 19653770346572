import stringSimilarity from 'string-similarity';

export const sortUsersByIdentifierSimilarity = (users, selectedVehicle) => {
  const usersWithSimilarityField = users.map((user) => ({
    ...user,
    similarity: stringSimilarity.compareTwoStrings(
      selectedVehicle.vehicleNumber,
      user.identifier
    ),
  }));

  return usersWithSimilarityField.sort((a, b) =>
    a.similarity < b.similarity ? 1 : -1
  );
};

export const formatUserFullName = (user) => {
  if (!user) return undefined;

  let name;

  if (user?.firstName) name = `${user?.firstName}`;
  if (user?.lastName && name) name += ` ${user?.lastName}`;
  else if (user?.lastName && !name) name = `${user?.lastName}`;

  return name;
};
