import moment from "moment";
import * as Yup from "yup";
import { stringifyJSON } from "utils/json";
import {
  getUploadDocuments,
  formatChangedFormValues,
  formatValues,
  addUpdatedOrCreatedBy
} from "utils/formHelpers";
import { getStatusValue } from "./BookingHelper";

export const isHistory = ({ data }) => {
  const currentUTCTime = moment().utc();
  const duration = moment.duration(currentUTCTime.diff(data.start));
  const hours = duration.asHours();

  return getStatusValue(data) === "complete" || hours >= 24;
};

export const generateRidesListNew = passengerschedules => {
  const allRides = [];

  if (passengerschedules) {
    passengerschedules.map(schedule => {
      allRides.push({
        ...schedule.passengerschedule,
        ...schedule,
        passengerScheduleId: schedule.passengerschedule.id,
        passengerScheduleAlerts: [...schedule.alerts],
        passengerScheduleCancelled: schedule.passengerschedule.cancelled,
        driverScheduleCancelled: schedule.cancelled
      });
    });
  }

  return allRides;
};

export const validationSchema = (isPublic, isUpdate) =>
  Yup.object().shape({
    department: isPublic
      ? Yup.object().nullable()
      : Yup.object()
          .nullable()
          .required("Required"),
    module: isPublic
      ? Yup.object().nullable()
      : Yup.object()
          .nullable()
          .required("Required"),
    numberOfVehicles: Yup.number()
      .required("Required")
      .min(1, "Not enough vehicles!")
      .test(
        "test-enough vehicles for assigned drivers",
        "Not enough vehicles for assigned drivers.",
        function(value) {
          const assignedDriversJSON = this.resolve(Yup.ref("additionalData"));
          const assignedDrivers = assignedDriversJSON
            ? JSON.parse(assignedDriversJSON)
            : [];

          return parseInt(value) >= assignedDrivers.length;
        }
      ),
    vehicleClass: Yup.string()
      .nullable()
      .required("Required"),
    passengersPerVehicle: Yup.number(),
    numPassengers: Yup.number()
      .required("Required")
      .min(1, "Not enough passengers!")
      .test(
        "test-enough vehicles for numPassengers",
        "Too many passengers selected for the requested number of vehicles.",
        function(value) {
          if (isUpdate) return true;

          const numberOfVehicles = this.resolve(Yup.ref("numberOfVehicles"));
          const passengersPerVehicle = this.resolve(
            Yup.ref("passengersPerVehicle")
          );

          const passengerLimit = numberOfVehicles * passengersPerVehicle;
          return value <= passengerLimit;
        }
      ),
    mainPassenger: Yup.array()
      .required("Required")
      .min(1, "Select at least one passenger"),
    arrivalDate: Yup.string()
      .nullable()
      .when("isReocurring", {
        is: false,
        then: Yup.string()
          .nullable()
          .required("Required")
      }),
    arrivalTime: Yup.string()
      .required("Required")
      .nullable(),
    from: Yup.string()
      .required("Required")
      .nullable(),
    to: Yup.string()
      .required("Required")
      .nullable(),
    serviceDuration: Yup.string().required("Required"),
    reocurringDates: Yup.array()
      .nullable()
      .when("isReocurring", {
        is: true,
        then: Yup.array()
          .min(1, "Required")
          .nullable()
          .required("Required")
      })
  });

export const formatSubmissionValues = (
  values,
  initialValues,
  projectId,
  isUpdate = false
) => {
  const formatConfig = {
    supplier: vals => vals?.supplier?.id || null,
    arrivalDate: {
      name: "start",
      format: vals => {
        if (vals.reocurringDates.length > 1) return null;
        const arrivalDate = isUpdate
          ? vals.arrivalDate
          : vals.reocurringDates[0];
        const startDate = moment(arrivalDate).format("YYYY-MM-DD");
        const startTime = moment.utc(vals.arrivalTime).format("HH:mm");

        return `${startDate}T${startTime}:00Z`;
      }
    },
    arrivalTime: {
      name: "start",
      format: vals => {
        if (vals.reocurringDates.length > 1) return null;
        const arrivalDate = isUpdate
          ? vals.arrivalDate
          : vals.reocurringDates[0];
        const startDate = moment(arrivalDate).format("YYYY-MM-DD");
        const startTime = moment.utc(vals.arrivalTime).format("HH:mm");

        return `${startDate}T${startTime}:00Z`;
      }
    },
    origin: null,
    department: "department.value",
    vehicleClass: [
      { name: "vehicleClass", format: "vehicleClass.name" },
      { name: "requiredVehicleClass", format: "vehicleClass.id" }
    ],
    mainPassenger: {
      name: "passenger",
      format: vals => vals?.mainPassenger[0]?.id || vals.mainPassenger[0]
    },
    additionalPassengers: {
      name: "additionalPassengers",
      format: vals =>
        vals.additionalPassengers.map(passenger => passenger?.id || passenger)
    },
    from: vals => vals.from.id || vals.from.id,
    to: vals => vals.to.id || vals.to.id,
    event: null,
    luggageCount: vals => vals?.luggageCount?.toString() || "0",
    products: vals => stringifyJSON(vals.products, "[]"),
    notes: null,
    drivers: {
      name: "additionalData",
      format: vals => stringifyJSON(vals.drivers, "[]")
    },
    productNotes: null,
    numberOfVehicles: vals => vals?.numberOfVehicles?.toString() || "0",
    numPassengers: vals => vals?.numPassengers?.toString() || "0",
    discount: { name: "rate", format: "discount" },
    documents: {
      forceSaveOnUpdate: true,
      name: "savedDocuments",
      format: vals => {
        const uploadedDocuments = getUploadDocuments(vals);
        const documents = [...vals.documents, ...uploadedDocuments];
        return documents.map(doc => doc.id);
      }
    },
    serviceDuration: {
      name: "transferservice",
      format: "serviceDuration.value"
    },
    stops: {
      name: "savedStops",
      format: vals =>
        vals?.stops
          ?.filter(
            stop =>
              stop.long &&
              stop.lat &&
              stop.name &&
              stop.address &&
              stop.type &&
              stop.id
          )
          ?.map(stop => stop.id)
    }
  };

  if (
    values?.from?.type?.toLowerCase() === "airport" ||
    values?.to?.type?.toLowerCase() === "airport"
  ) {
    formatConfig.terminal = null;
    formatConfig.flightNumber = null;
  } else {
    formatConfig.terminal = () => "";
    formatConfig.flightNumber = () => "";
  }

  let formatted;

  if (isUpdate)
    formatted = formatChangedFormValues(values, initialValues, formatConfig);
  else {
    formatConfig.isReocurring = null;
    formatConfig.bookingSource = null;
    formatConfig.reocurringDates = vals => {
      const dates = vals.reocurringDates.map(date => {
        const startDate = moment.utc(date).format("YYYY-MM-DD");
        const startTime = moment.utc(vals.arrivalTime).format("HH:mm");
        return `${startDate}T${startTime}:00Z`;
      });
      return stringifyJSON(dates, "[]");
    };
    formatted = formatValues(values, formatConfig);
  }

  const withUserId = addUpdatedOrCreatedBy(formatted, isUpdate, true);
  return withUserId;
};

export const formatIncomingData = (
  data,
  durationOptions,
  isReturnTrip,
  isUpdate,
  isDuplicate
) => {
  const arrivalDate = moment.utc(data.start).format("YYYY-MM-DD");
  const arrivalTime = isReturnTrip
    ? null
    : `${arrivalDate} ${moment.utc(data.start).format("HH:mm:ss")}`;

  const drivers = data?.driverschedules
    .filter(ds => ds?.driver?.id && ds?.vehicle?.id)
    .map(ds => ({ driver: ds.driver.id, vehicle: ds.vehicle.id }));

  const from = data.from || null;
  const to = data.to || null;

  const serviceDuration = data.transferservice
    ? { name: data.transferservice.name, value: data.transferservice.id }
    : durationOptions[0];

  const reocurringDates = isDuplicate ? [arrivalDate] : [];

  return {
    serviceDuration: serviceDuration,
    arrivalDate: !isUpdate ? null : arrivalDate,
    arrivalTime,
    assignDrivers: !!drivers.length,
    productIds: data.products.map(product => product.id),
    products: data.products,
    stops: isReturnTrip
      ? []
      : data.savedStops.map((stop, index) => ({
          ...stop,
          order: index + 1
        })),

    flightNumber: data.flightNumber,
    terminal: data.terminal,
    passengersPerVehicle: data.requiredVehicleClass?.maxPassengers || 0,
    department: data.department && {
      ...data.department,
      value: data.department.id
    },
    module: data?.department?.module
      ? { name: data.department.module.name, value: data.department.module.id }
      : null,
    notes: data.notes,
    productNotes: data.productNotes,
    id: data.id,
    from: isReturnTrip ? to : from,
    to: isReturnTrip ? from : to,
    numPassengers: parseInt(data.numPassengers),
    numberOfVehicles: parseInt(data.numberOfVehicles),
    discount: data.rate || 0,
    luggageCount: parseInt(data.luggageCount) || 0,
    mainPassenger: data.passenger ? [data.passenger] : [],
    additionalPassengers: data.additionalPassengers || [],
    vehicleClass: data.requiredVehicleClass,
    documents: data.savedDocuments,
    drivers,
    supplier: data.supplier,
    isReocurring: !isUpdate,
    reocurringDates
  };
};

const formatStoredLocation = storedLocation => {
  const newStop = {
    ...storedLocation,
    id: storedLocation.id,
    value: storedLocation.name
  };
  delete newStop.id;
  return newStop;
};

export const mergeReturnValues = previousFormState => {
  const {
    values,
    returnField,
    returnValues,
    returnFieldMetadata
  } = previousFormState;
  const newValues = { ...values };

  if (!returnField || !returnValues) return newValues;

  const returnValue = returnValues[returnField];

  if (returnField === "from" || returnField === "to") {
    const newLocation = formatStoredLocation(returnValue);

    newValues[returnField] = newLocation;
  } else if (returnField === "stops") {
    const { order } = returnFieldMetadata;
    const newStops = newValues[returnField].map(stop => {
      if (order === stop.order) return formatStoredLocation(returnValue);
      else return stop;
    });
    newValues[returnField] = newStops;
  } else if (returnField === "mainPassenger") {
    newValues[returnField] = [returnValue[0]];
  } else if (returnField === "additionalPassengers") {
    newValues[returnField] = [...newValues[returnField], ...returnValue];
  } else if (returnField === "supplier") newValues[returnField] = returnValue;

  return newValues;
};
