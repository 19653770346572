import { makeStyles } from '@material-ui/core/styles';

export const useCustomStyles = makeStyles(() => ({
  toastWrapper: {
    width: '350px',
    minHeight: '191px',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    boxShadow: '0px 6px 10px rgba(66, 75, 90, 0.2)',
    borderRadius: '6px',
  },
  toastHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: 0,

    '& .MuiCardHeader-avatar': {
      marginRight: '12px',

      '& .MuiAvatar-colorDefault': {
        color: 'inherit',
        background: 'inherit',
        width: 'auto',
        height: 'auto',

        '& > svg': {
          width: '32px',
          height: '32px',
        },
      },
    },

    '& .MuiCardHeader-title': {
      fontSize: '16px',
      lineHeight: '134.3%',
      color: '#000000',
      fontWeight: 'normal',
    },
  },
  toastBody: {
    paddingTop: '8px',
    color: 'rgba(0, 0, 0, 0.9)',
    paddingBottom: '16px',
  },
  toastFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: 'rgba(0, 0, 0, 0.02)',
    padding: '16px',

    '& button': {
      width: 'auto',
      padding: '0',
      letterSpacing: '0.3px',
      fontWeight: 'bold',
      minWidth: 0,
      textTransform: 'none',
      fontSize: '14px',

      '&:hover': {
        background: 'transparent',
        textDecoration: 'underline',
      },
    },
  },
  toastOKButton: {
    color: '#4001C5',
  },
  toastCancelButton: {
    marginRight: '16px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  toasterErrorType: {
    '& .MuiCardHeader-avatar': {
      '& .MuiAvatar-colorDefault': {
        '& > svg > path': {
          fill: '#C50202',
        },
      },
    },

    '& .MuiCardHeader-title': {
      color: '#C50202',
    },
  },
}));
