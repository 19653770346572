import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  cibWrapper: {
    display: 'inline-block',

    '& .MuiIconButton-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      padding: 0,
      width: '32px',
      height: '32px',

      '&:hover:enabled': {
        '& svg > path': {
          fill: '#4001C5',
        },
        '& svg > rect': {
          fill: '#4001C5',
        },
      },

      '&:disabled': {
        '& svg > path': {
          fill: 'rgba(0, 0, 0, 0.3)',
        },
        '& svg > rect': {
          fill: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
}));
const CIB = ({ icon, onClick, isDisabled, style }) => {
  const classes = useStyles();
  const Icon = icon;

  return (
    <div className={classes.cibWrapper} style={style}>
      <IconButton component="button" disabled={isDisabled} onClick={onClick}>
        <Icon />
      </IconButton>
    </div>
  );
};

export default CIB;
