import { productIconSelectionOptions } from "utils/iconHelpers";
import { editActionButtonSelector } from "../../../../App.helper";

export const getHamburgerMenuItems = history => [
  {
    label: "Edit",
    onClick: row => history.push(`/project/${row.id}/edit`)
  }
];

export const getMobileMenuItems = ({ row }) => {
  return {
    right: [
      {
        text: " ",
        onClick: () => {
          const clickedButton = document.querySelector(
            "#" + editActionButtonSelector + row.id
          );
          if (clickedButton) clickedButton.click();
        },
        className: "mobile-actions-edit"
      }
    ]
  };
};

export const getColumns = () => [
  {
    field: "icon",
    type: "icon",
    headerName: "Icon",
    options: productIconSelectionOptions,
    width: 2,
    label: "Choose Icon"
  },

  {
    field: "name",
    headerName: "Name",
    width: 2
  },

  {
    field: "price",
    headerName: "Price",
    width: 2,
    type: "number"
  },

  {
    field: "description",
    headerName: "Description",
    width: 2,
    hideMobile: true
  },

  {
    field: "clientPricing",
    headerName: "Client Price",
    width: 2,
    type: "number"
  },

  {
    field: "supplierPricing",
    headerName: "Supplier Price",
    width: 2,
    type: "number"
  }
];
