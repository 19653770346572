import React from 'react';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import ScrollContainer from 'react-indiana-drag-scroll';
import { getCategoryList } from '../../Reporting/ReportingHelper';
import './CounterGraph.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileViewBreakpoint } from '../../../App.helper';
import { getIsDataPresent } from './CounterGraph.helper';

const useStyles = makeStyles((theme) => ({
  counterRecords: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'flex',
    },
  },
  counterRecord: {
    display: 'flex',
    alignItems: 'center',
    margin: '17px 31px',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      alignItems: 'start',
      margin: '24px',
      minWidth: '80px',
      position: 'relative',
    },
  },

  counterValue: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#000000',
    marginRight: '15px',
    minWidth: '24px',
    textAlign: 'right',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      lineHeight: 'unset',
      textAlign: 'unset',
      minWidth: '0',
    },
  },
  counterName: { fontSize: '14px', lineHeight: '134.3%', color: '#000000' },
  counterValueHighlight: { color: '#C50202' },
  footerLayer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '54px',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    paddingRight: '24px',
  },
  noDataBlock: {
    padding: 16,
    minHeight: 214,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      minHeight: '0',
    },
  }
}));

const CounterGraph = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  return (
    <>
      {getIsDataPresent(data) ? <ScrollContainer horizontal>
        <div
          className={classNames(classes.counterRecords, {
            counterRecordsMobile: isMobileView,
          })}
        >
          {_.map(_.keys(data), (key, index) => (
            <div key={index} className={classes.counterRecord}>
              <span
                className={classNames(classes.counterValue, {
                  [classes.counterValueHighlight]: data[key].length,
                })}
              >
                {data[key].length}
              </span>
              <span className={classes.counterName}>{getCategoryList(key)}</span>
            </div>
          ))}
        </div>
      </ScrollContainer> : <div className={classes.noDataBlock}>No data yet available. Please check later.</div>
      }
    </>
  );
};

export default CounterGraph;
