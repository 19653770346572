import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';

import GetAllPublicDrivers from 'gql/Drivers/GetAllPublicDrivers';
import withMainLayout from 'components_v2/Layout/withMainLayout';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DataSelector from '../../../Inputs/DataSelector/DataSelector';
// import ClientDetails from './ClientDetails/ClientDetails';
import { mobileViewBreakpoint } from '../../../../App.helper';
import DynamicDriverList from "components_v2/List/Variations/DriversList";


const Drivers = () => {
  const { data ,loading } = useQuery(
    GetAllPublicDrivers,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    }
  );


  const { users: drivers } = data || [];
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div style={isMobileView ? { paddingBottom: 64 } : {}}>
      <DataSelector
        isMenuBlocked
        variant={(isMobileView && 'h4Bold') || 'h2Bold'}
        value="all"
        urlPrefix="/client/"
        options={drivers || []}
        labelField="companyName"
        valueField="id:"
        loading={loading}
        allDataObject={{
          companyName: 'Public Drivers',
          id: 'all',
          url: '/clients',
        }}
      />
      <DynamicDriverList publicOnly />
    </div>
  );
};
export default withMainLayout('Drivers', {}, withRouter(Drivers));
