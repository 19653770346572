import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";

import DynamicList from "components_v2/List/DynamicList";
import GetAll from "gql/Clients/GetAll";
import Delete from "gql/Clients/Delete";
import withMainLayout from "components_v2/Layout/withMainLayout";
import {
  getHamburgerMenuItems,
  getColumns,
  actions,
  customActions,
  getMobileMenuItems
} from "components_v2/Views/Internal/Clients/ListOptions";
import FAB from "components_v2/Buttons/FAB/FAB";
import { FloatingActionNewClient } from "components_v2/Icons/Icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import DataSelector from "../../../Inputs/DataSelector/DataSelector";
import ClientDetails from "./ClientDetails/ClientDetails";
import { mobileViewBreakpoint } from "../../../../App.helper";
import { NetworkStatus } from "@apollo/client";
import DeleteConfirmationModal from "../../../Modal/DeleteConfirmationModal/DeleteConfirmationModal";
const Clients = ({ history }) => {
  const [listConfig, setListConfig] = useState({
    sort: "id:desc",
    pageSize: 10,
    startIndex: 0,
    filterKey: "",
    count: 0
  });
  const { data, loading, refetch, networkStatus } = useQuery(GetAll, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { ...listConfig, sort: listConfig.sort || "id:desc" }
  });

  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const [
    deleteConfirmationModalData,
    setDeleteConfirmationModalData
  ] = useState(null);

  const [deleteClient, { loading: deleteMutationLoading }] = useMutation(
    Delete
  );

  const deleteClientRecord = async () => {
    const recordToDelete = deleteConfirmationModalData.recordId;

    setDeleteConfirmationModalData(null);

    await deleteClient({
      variables: {
        id: recordToDelete
      }
    });

    await refetch();
  };
  const currentData = data?.clientsConnection?.values || [];
  const count = data?.clientsConnection?.aggregate?.count || 0;
  const handleListConfigChange = listConfig => {
    setListConfig(listConfig);
  };

  return (
    <div style={isMobileView ? { paddingBottom: 64 } : {}}>
      <DeleteConfirmationModal
        deleteConfirmationModalData={deleteConfirmationModalData}
        onClickCancel={() => {
          setDeleteConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteClientRecord();
        }}
      />
      <FAB
        label="Add client"
        icon={FloatingActionNewClient}
        onClick={() => {
          history.push("/client/new");
        }}
      />
      <DataSelector
        isMenuBlocked
        variant={(isMobileView && "h4Bold") || "h2Bold"}
        value="all"
        urlPrefix="/client/"
        options={currentData || []}
        labelField="companyName"
        valueField="id:"
        loading={loading}
        allDataObject={{
          companyName: "All Clients",
          id: "all",
          url: "/clients"
        }}
      />
      <DynamicList
        listConfig={{ ...listConfig, count }}
        onListConfigChange={handleListConfigChange}
        isMobileView={isMobileView}
        showHeader
        noResultsText="No clients"
        enableFreezeHeader
        data={currentData || []}
        loading={
          loading ||
          deleteMutationLoading ||
          networkStatus === NetworkStatus.refetch
        }
        numRows={currentData?.length || 0}
        hamburgerMenuItems={[
          ...getHamburgerMenuItems({ history }),
          {
            label: "Delete",
            onClick: row => {
              setDeleteConfirmationModalData({
                recordName: row.companyName,
                recordId: row.id,
                confirmationType: "delete",
                isNotDeleteAllowed: row.events.length > 0,
                deleteNotAllowedReason:
                  "This client cannot be deleted because it is being used in the events."
              });
            }
          }
        ]}
        getMobileMenuItems={row =>
          getMobileMenuItems({ history, setDeleteConfirmationModalData, row })
        }
        columns={getColumns(isMobileView)}
        actions={actions}
        customActions={customActions}
        detailPanel={data => <ClientDetails client={data} />}
      />
    </div>
  );
};

export default withMainLayout("Clients", {}, withRouter(Clients));
