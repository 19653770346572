import React from 'react';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';

import { formatTimeScaleDate } from '../helpers';
import useStyles from './WeekViewTimeScaleLabel.style';

const WeekViewTimeScaleLabel = ({ ...restProps }) => {
  const classes = useStyles();
  const labelClass = restProps.time
    ? classes.timeScaleLabel
    : classes.emptyTimeScaleLabel;

  return (
    <WeekView.TimeScaleLabel
      className={labelClass}
      {...restProps}
      formatDate={formatTimeScaleDate}
    />
  );
};

export default WeekViewTimeScaleLabel;
