import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { NetworkStatus } from "@apollo/client";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { ArrowsChevronRight } from "../Icons/Icons";
import auth from "../../utils/auth";
import AccountDetails from "./AccountDetails/AccountDetails";
import Notifications from "./Notifications/Notifications";
import Projects from "./Projects/Projects";
import GetOneGQL from "../../gql/Users/GetOne";
import Loading from "components_v2/Loading/LinearProgress";
import Button from "../Button/Button";
import { mobileViewBreakpoint } from "../../App.helper";
import DeleteUserEvent from "gql/UserEvents/Delete";
import UpdateEvent from "gql/Events/Update";

const useStyles = makeStyles(theme => ({
  cardItem: {
    paddingTop: "20px",
    paddingBottom: "20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0px 8px 28px transparent",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&:lastChild": {
      border: "none"
    },
    "&:hover": {
      borderBottom: "1px solid #ffffff",
      boxShadow: "0px 8px 28px rgba(0, 0, 0, 0.2)",
      borderRadius: "6px"
    }
  },
  cardOtherItems: {
    height: "114px"
  },
  inline: {
    display: "inline"
  },
  image: {
    width: "72px",
    height: "72px",
    marginRight: "16px"
  },
  rightContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1
  },
  title: {
    marginTop: "10px",
    marginBottom: "8px",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "134.3%",
    color: "#000000"
  },
  content: {
    marginTop: "14px",
    marginBottom: "8px",
    fontSize: "14px",
    lineHeight: "134.3%",
    letterSpacing: "-0.2px",
    color: "rgba(0, 0, 0, 0.8)"
  },
  supplementInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  from: {
    fontWeight: "bold"
  },
  time: {
    fontSize: "14px",
    lineHeight: "134.3%",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "14px",
    marginBottom: "8px"
  },
  account: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between"
  },
  footer: {
    padding: "20px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      boxShadow: "0px -10px 17px rgba(0, 0, 0, 0.1)"
    }
  },
  basicInfo: {
    flex: 1
  },
  sidebarWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      "& .MuiDrawer-paper": {
        width: "100%"
      }
    }
  }
}));

const UPDATE_USER = gql`
  mutation UpdateUser($id: InputID!, $data: editUserInput) {
    updateUser(input: { where: $id, data: $data }) {
      user {
        id
      }
    }
  }
`;

const Account = ({ onCloseClick, history }) => {
  const { id } = auth.get("userInfo");
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    type: null
  });
  const anchor = "right";

  const [accountDetailsSave, { loading: mutationLoading }] = useMutation(
    UPDATE_USER,
    {
      variables: { id: { id: id } }
    }
  );
  const [updateUserEvent] = useMutation(UpdateEvent);
  const [deleteUserEvent] = useMutation(DeleteUserEvent);

  const {
    data: queryData,
    loading: queryLoading,
    refetch,
    networkStatus
  } = useQuery(GetOneGQL, {
    variables: { id: id },
    notifyOnNetworkStatusChange: true
  });

  const userData = queryData && queryData.user;

  const handleLogoutClick = () => {
    auth.clearAppStorage();
    history.push("/login");
  };

  const toggleDrawer = (type, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open, type });
  };
  const onArchiveProject = async eventId => {
    await updateUserEvent({
      variables: {
        id: { id: eventId },
        data: { status: "Archived" }
      },
      notifyOnNetworkStatusChange: true
    });

    await refetch();
  };
  const onLeaveProject = async eventId => {
    await deleteUserEvent({
      variables: {
        id: { id: eventId }
      }
    });

    await refetch();
  };

  const renderAccountShortDetails = () => (
    <ListItem alignItems="flex-start" className={classes.cardItem}>
      <ListItemAvatar>
        {(!queryLoading && (
          <Avatar
            alt="Remy Sharp"
            className={classes.image}
            src={userData && userData.profileImage}
          />
        )) || (
          <Skeleton
            variant="circle"
            width={72}
            height={72}
            style={{ marginRight: "16px" }}
          />
        )}
      </ListItemAvatar>
      <div className={classes.rightContent}>
        <div className={classes.basicInfo}>
          <div className={classes.title}>
            {!queryLoading &&
              userData &&
              `${userData.firstName} ${userData.lastName}`}
            {queryLoading && <Skeleton variant="text" height={16} />}
          </div>
          {userData && userData.role.name && (
            <div className={classes.content}>
              <strong className={classes.from}>Role: </strong>
              {userData.role.name}
            </div>
          )}
          {queryLoading && (
            <div className={classes.content}>
              <Skeleton variant="text" height={14} />
            </div>
          )}
          {userData && userData.supervisor && (
            <div className={classes.content}>
              <strong className={classes.from}>Reports to: </strong>
              {userData.supervisor}
            </div>
          )}
          {queryLoading && (
            <div className={classes.content}>
              <Skeleton variant="text" height={14} />
            </div>
          )}
        </div>
      </div>
    </ListItem>
  );

  const renderAccountDetailsLink = () => (
    <ListItem
      alignItems="flex-start"
      className={`${classes.cardItem} ${classes.cardOtherItems}`}
      onClick={toggleDrawer("account-details", true)}
    >
      <div className={classes.rightContent}>
        <div className={classes.basicInfo}>
          <div className={classes.title}>Account Details</div>
          <div className={classes.content}>
            Change password, email, contact number
          </div>
        </div>
        <div className={classes.supplementInfo}>
          <ArrowsChevronRight fontSize="small" />
        </div>
      </div>
    </ListItem>
  );

  const renderProjectDetailsLink = () => (
    <ListItem
      alignItems="flex-start"
      className={`${classes.cardItem} ${classes.cardOtherItems}`}
      onClick={toggleDrawer("projects", true)}
    >
      <div className={classes.rightContent}>
        <div className={classes.basicInfo}>
          <div className={classes.title}>Projects</div>
          <div className={classes.content}>
            View your projects, leave or request access to projects
          </div>
        </div>
        <div className={classes.supplementInfo}>
          <ArrowsChevronRight fontSize="small" />
        </div>
      </div>
    </ListItem>
  );

  const renderNotificationDetailsLink = () => (
    <ListItem
      alignItems="flex-start"
      className={`${classes.cardItem} ${classes.cardOtherItems}`}
      onClick={toggleDrawer("notifications", true)}
    >
      <div className={classes.rightContent}>
        <div className={classes.basicInfo}>
          <div className={classes.title}>Notifications</div>
          <div className={classes.content}>
            Manage your notification settings
          </div>
        </div>
        <div className={classes.supplementInfo}>
          <ArrowsChevronRight fontSize="small" />
        </div>
      </div>
    </ListItem>
  );

  return (
    <>
      <div className={classes.account}>
        <div>
          {renderAccountShortDetails()}
          {renderAccountDetailsLink()}
          {renderProjectDetailsLink()}
          {renderNotificationDetailsLink()}
        </div>
        <footer className={classes.footer}>
          <Button variant="simple" onClick={handleLogoutClick}>
            Logout
          </Button>
        </footer>
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
          className={classes.sidebarWrapper}
        >
          {state.type === "account-details" && (
            <AccountDetails
              onBackClick={toggleDrawer(anchor, false)}
              onCloseClick={onCloseClick}
              accountDetailsData={userData}
              onAccountDetailsSave={accountDetailsSave}
              refetchAccountDetals={refetch}
              isLoading={
                mutationLoading ||
                queryLoading ||
                networkStatus === NetworkStatus.refetch
              }
            />
          )}
          {state.type === "notifications" && (
            <Notifications
              onBackClick={toggleDrawer(anchor, false)}
              onCloseClick={onCloseClick}
            />
          )}
          {state.type === "projects" && (
            <Projects
              onLeaveProject={onLeaveProject}
              onArchiveProject={onArchiveProject}
              onBackClick={toggleDrawer(anchor, false)}
              onCloseClick={onCloseClick}
            />
          )}
        </SwipeableDrawer>
      </div>
      {queryLoading && <Loading />}
    </>
  );
};

export default withRouter(Account);
