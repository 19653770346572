import gql from "graphql-tag";

export default gql`
  query GetPassengerScheduleCount($id: ID, $start: String, $end: String) {
    passengerScheduleCount(where: { id: $id, start: $start, end: $end }) {
      totalRides
      completeRides
      ongoingRides
      progressPercentage
      unresolvedAlerts
      userEventIds
    }
  }
`;
