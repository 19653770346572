import React from "react";
import DynamicList from "components_v2/List/DynamicList";
import { columns } from "./ListOptions";
import { getLeftBorderColor } from "./AlertsHelper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AlertsComments from "./AlertsComments";
import { mobileViewBreakpoint } from "../../App.helper";
import { getMobileMenuItems } from "./ListOptions";
const AlertsComponent = ({
  data,
  setFilterKey,
  loading,
  onToggleStatus,
  handleListConfigChange,
  numRows,
  listConfig
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  return (
    <>
      <DynamicList
        filterActions={[
          {
            type: "text",
            onChange: val => {
              setFilterKey(val);
            },
            label: "Search"
          }
        ]}
        isMobileView={isMobileView}
        showHeader
        loading={loading}
        data={data}
        enableFreezeHeader
        headerFreezeLeftContent="Alerts"
        noResultsText="No alerts"
        title="Alerts"
        numRows={data.length}
        columns={columns}
        groupDateField="created_at"
        isAutoClose={true}
        getMobileMenuItems={row =>
          getMobileMenuItems({
            onToggleStatus,
            row
          })
        }
        actions={{
          primary: {
            onClick: row => {
              onToggleStatus(
                row,
                (row.status === "resolved" && "unresolved") || "resolved"
              );
            },
            hidden: row => row.status === "resolved",
            disabled: () => loading === true,
            label: row =>
              (row.status === "resolved" && "Mark as unresolved") ||
              "Mark as resolved"
          }
        }}
        detailPanel={row => {
          return <AlertsComments data={row} />;
        }}
        renderLeftBorder={row => `4px solid ${getLeftBorderColor(row)}`}
        onListConfigChange={handleListConfigChange}
        numRows={numRows}
        listConfig={listConfig}
      />
    </>
  );
};
export default AlertsComponent;
