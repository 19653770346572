import React, { useState } from "react";
import Typography from "components_v2/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CancelConfirmationModal from "../Modal/CancelConfirmationModal/CancelConfirmationModal";
import UpdatePassengerSchedule from "../../gql/PassengerSchedules/Update";
import { useMutation } from "@apollo/react-hooks";
import UpdateDriverSchedule from "gql/DriverSchedules/Update";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})
const CancelBooking = ({ data, id }) => {
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const [
    updateDriverSchedule,
  ] = useMutation(UpdateDriverSchedule);

  const updateCancelStatus = () => {

  }
  const [
    updatePassengerSchedule,
  ] = useMutation(UpdatePassengerSchedule);

  const updateBookingCancelledStatus = async (cancelled, allRides) => {
    const { bookingId, driverScheduleId } = confirmationModalData;

    setConfirmationModalData(null);

    let updated;

    if (allRides) {
      const res = await updatePassengerSchedule({
        variables: {
          id: { id: bookingId },
          data: {
            cancelled,
          },
        },
      });
      updated = res?.data?.updatePassengerschedule?.passengerschedule;
    } else {
      const res = await updateDriverSchedule({
        variables: {
          id: { id: driverScheduleId },
          data: {
            cancelled,
          },
        },
      });
      updated = res?.data?.updateDriverschedule?.driverschedule;
    }

    updateCancelStatus(updated, allRides);
  };
  const classes = useStyles();



  return (
    <>
      <CancelConfirmationModal
        data={confirmationModalData}
        onClickCancel={() => {
          setConfirmationModalData(null);
        }}
        onClickConfirmAll={(updatedCancelledStatus) => {
          updateBookingCancelledStatus(updatedCancelledStatus, true);
        }}
        onClickConfirmOne={(updatedCancelledStatus) => {
          updateBookingCancelledStatus(updatedCancelledStatus, false);
        }}
      />
      <div
        className={classes.item}
        id={id}
        onClick={() => {
          setConfirmationModalData({
            recordName: data.bookingId,
            bookingId: data.passengerScheduleId,
            driverScheduleId: data.id,
            driverScheduleCancelled: data.cancelled,
            passengerScheduleCancelled: data.passengerScheduleCancelled,
            confirmationType:
              data?.cancelled || data?.passengerScheduleCancelled
                ? "re-enable"
                : "cancel",
            numberOfVehicles: !parseInt(data.numberOfVehicles)
              ? 0
              : parseInt(data.numberOfVehicles)
          });
        }}>
        <Typography
          customVariant="bodySmallRegular"
        >
          Cancel
      </Typography>
      </div>
    </>
  )
}
export default CancelBooking