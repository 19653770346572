import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowsChevronUp, ArrowsChevronDown } from '../../Icons/Icons';
import classNames from 'classnames';
import _ from 'lodash';
import { MenuItem } from '@material-ui/core';
import Menu from '../../Menu/Menu';
import { mobileViewBreakpoint } from "../../../App.helper";
import {getSortingOptions} from "./helper"
const useStyles = makeStyles((theme) => ({
  sortByWrapper: {
    cursor: 'pointer',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "100%",
    
    },
  },
  sortedByRenderer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 8px 8px 13px  ',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '6px',
    justifyContent: 'space-between',
    marginLeft: '2px',
    marginBottom: '8px',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginLeft: '0px',
    },

    '&:hover': {
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    },
  },
  sortedByTitle: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: 'rgba(0, 0, 0, 0.5)',
    margin: '5px 0px',
  },
  sortedByLabel: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: '#000000',
    margin: '5px',

    '& .highlight': {
      color: '#C50202',
    },
  },
  sortMenuRecords: {
    width: '188px',
    padding: 0,
    borderRadius: 0,

    '& .MuiList-root': {
      padding: 0,
    },
  },
  sortMenuRecord: {
    width: '188px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '134.3%',
    color: '#000000',

    '&:hover': {
      backgroundColor: 'background: rgba(0, 0, 0, 0.05)',
      fontWeight: 'bold',
    },

    '& .highlight': {
      color: '#C50202',
    },

    '& > span': {
      fontWeight: 'bold',
    },
  },
}));

const SortBy = ({ listConfig, value, onChange, name, columns, sortActions }) => {
  const classes = useStyles();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
 
  const handleSortedByClick = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const renderMenuItems = () =>
    _.map(columns, (item) => (
      item?.isSortable !== false  &&
      <MenuItem
        className={classes.sortMenuRecord}
        onClick={
          // TODO: NEED TO REVISIT
          () => {
            setIsMenuOpened(false);
            setTimeout(() => {
              onChange({
                persist: () => { },
                target: {
                  type: 'change',
                  name,
                  value: item,
                },
              });
            })
          }
        }
      >

        { item.headerName}
        <span
          className={classNames({
            highlight: item.isHighlighted === true,
          })}
        >
          {item.other}
        </span>
      </MenuItem>
    ));

  return (
    <div className={classes.sortByWrapper}>
      <Menu
        isMenuOpened={isMenuOpened}
        className={classes.sortMenuRecords}
        items={renderMenuItems}
        render={(handleClick) => (
          <div
            onClick={(event) => {
              handleClick(event);
              handleSortedByClick();
            }}
            className={classes.sortedByRenderer}
          >
            
            <div>
              <span className={classes.sortedByTitle}>Sorted By:</span>
              <span className={classes.sortedByLabel}>
                {getSortingOptions({sortActions, listConfig})}
                <span
                  className={classNames({
                    highlight: value?.isHighlighted === true,
                  })}
                >
                  {value?.other}
                </span>
              </span>
            </div>
            {(isMenuOpened && <ArrowsChevronUp />) || <ArrowsChevronDown />}
          </div>
        )}
        onMenuClose={() => setIsMenuOpened(false)}
      />
    </div>
  );
};

export default SortBy;