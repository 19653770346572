import gql from "graphql-tag";

export default gql`
  query GetAllVehiclesByEvent($id: ID, $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String) {
    vehiclesConnection(where: { 
      event: $id, 
      _or: [
        { vehicleNumber_contains: $filterKey }
        { make_contains: $filterKey }
        { model_contains: $filterKey }
        { plateNumber_contains: $filterKey }
        { year_contains: $filterKey }
        { color_contains: $filterKey }
        { vehicleClass: { name_contains: $filterKey } }
        { supplier: {name_contains: $filterKey}}
        { maxPassengers_contains: $filterKey }
      ] 
    }
    limit: $pageSize
    start: $startIndex
    sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        supplier {
          id
          name
        }
        make
        model
        year
        plateNumber
        color
        vehicleNumber
        maxPassengers
        type
        enabled
        notes
        plateNumber
        status
        vehicleClass {
          id
          name
          maxPassengers
        }
        driverschedules {
          id
          vehicle {
            id
            vehicleNumber
            make
            model
            year
            maxPassengers
            plateNumber
          }
          driver {
            firstName
            lastName
            phone
            email
            identifier
          }
          passengerschedule {
            id
            start
            stop
            type
            vehicleClass
            flightNumber
            numberOfVehicles
            bookingId
            type
            origin
            driverschedules {
              id
              vehicle {
                id
                vehicleNumber
                make
                model
                year
                maxPassengers
                plateNumber
              }
              driver {
                firstName
                lastName
                phone
                email
                identifier
              }
            }
            requiredVehicleClass {
              id
              name
              maxPassengers
            }
            passenger {
              id
              firstName
              lastName
              email
              phone
              event {
                id
              }
            }
            from {
              id
              name
              address
              lat
              long
            }
            to {
              id
              name
              address
              lat
              long
            }
          }
        }
        assignedDrivers {
          id
          notes
          start
          stop
          wholeEvent
          vehicle {
            id
          }
          driver {
            id
            identifier
            firstName
            lastName
            phone
            company
            driveratings {
              id
              rating
            }
          }
        }
      }
    }
  }
`;
