const filterArchived = (data, hideArchived) =>
  hideArchived ? data.filter((event) => event.status !== 'Archived') : data;

const filterSearch = (data, searchTerm) =>
  data.filter((event) =>
    event.name ? event.name.toLowerCase().includes(searchTerm) : false
  );

export const filterData = (data, hideArchived, searchTerm) => {
  const archiveFiltered = filterArchived(data, hideArchived);
  const searchFiltered = filterSearch(archiveFiltered, searchTerm);

  return searchFiltered;
};
