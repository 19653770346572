import gql from "graphql-tag";

export default gql`
  {
    locationsConnection {
      groupBy {
        driverId {
          key
          connection {
            values {
              lat
              long
              h
            }
          }
        }
      }
    }
  }
`;
