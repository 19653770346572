import React from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import Loading from "components/Loading/LinearProgress";
import Button from "components_v2/Button/Button";
import PasswordInput from "components_v2/Inputs/PasswordInput/PasswordInput";
import Typography from "components_v2/Typography/Typography";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import { Visible } from "components_v2/Icons/Icons";
import Auth from "components_v2/Auth/Auth";
import auth from "utils/auth";
import InputError from "components_v2/Inputs/InputError/InputError";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AuthLayout from "./AuthLayout";
import FormFooter from "./FormFooter";
import { mobileViewBreakpoint } from "../../../../App.helper";

const useStyles = makeStyles(theme => ({
  loginFormWrapper: {
    padding: "0 88px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0 16px"
    },
    display: "flex",
    flexDirection: "column"
  },
  forgetButtonWrapper: {
    fontWeight: "bold",
    margin: "16px 0 0 auto",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "16px 0 0 0",
      letterSpacing: "0.3px",
      fontSize: "12px"
    },
    textDecoration: "none",
    color: "#4001C5"
  },

  footerFormWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      boxShadow: "0px -10px 17px rgba(0, 0, 0, 0.1)",
      background: "#fff",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,

      "& > footer": {
        [theme.breakpoints.down(mobileViewBreakpoint)]: {
          background: "#fff"
        }
      }
    }
  }
}));
const Login = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const onSignup = async (token, user, events, streamToken) => {
    // executes when a user successfully logs in
    if (token && user) {
      auth.setToken(token, true); // true = remember me
      auth.setUserInfo(user, true);

      localStorage.setItem("streamToken", streamToken);

      /*
      if (
        user &&
        user.userEvents &&
        user.userEvents.length == 1 &&
        user.userEvents[0]
      ) {
        localStorage.setItem("selectedEvent", user.userEvents[0].event);
        history.push("/");
      } */

      history.push("/");
    }
  };

  const handleQuickLogin = ({ setFieldValue, submitForm }) => {
    setFieldValue('identifier', "test@motusone1234.com")
    setFieldValue('password', "password")
    setTimeout(() => {
      submitForm()
    })

  }

  const renderForm = ({
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    submitForm,
    error,
    loading
  }) => (
    <Form
      style={
        isMobileView
          ? { height: "100%", display: "flex", flexDirection: "column" }
          : {}
      }
    >
      <div style={isMobileView ? { flex: "1" } : {}}>
        <div className={classes.loginFormWrapper}>
          <TextInput
            name="identifier"
            label="Email"
            placeholder="Please enter your email"
            value={values.identifier}
            onChange={handleChange}
            error={touched.identifier && errors.identifier}
          />
          <div style={{ height: 8 }} />
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Please enter your password"
            icon={Visible}
            value={values.password}
            onChange={handleChange}
            error={touched.password && errors.password}
          />

          <Typography
            customVariant="ctaBold"
            component="a"
            href="/forgot-password"
            className={classes.forgetButtonWrapper}
          >
            Forgot Password
          </Typography>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingTop: 10
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <InputError error={error} />
          </div>
        </div>
        <div style={{ marginBottom: 40 }} />
        {loading && <Loading />}
      </div>
      <div className={classes.footerFormWrapper}>
        <FormFooter
          mainAction={
            <>
              <Button
                disabled={loading}
                onClick={submitForm}
                variant="contained"
                type="Submit"
              >
                Log In
            </Button>
              {process.env.NODE_ENV === 'development' && <Button
                disabled={loading}
                onClick={() => handleQuickLogin({ setFieldValue, submitForm })}
                variant="contained"
                type="Button"
              >
                Quick Log In
          </Button>}
            </>
          }
        />
      </div>
    </Form>
  );

  const validate = values => {
    const errors = {};

    if (!values.identifier) errors.identifier = "Required";
    else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.identifier)
    )
      errors.identifier = "Invalid email address";

    if (!values.password) errors.password = "Required";

    return errors;
  };

  const handleSubmit = handleSubmitNoEvent => async (
    values,
    { setSubmitting }
  ) => {
    await handleSubmitNoEvent(values);
    setSubmitting(false);
  };

  return (
    <AuthLayout
      headerText="Transportation Management"
      description="Powered by MOTUS | ONE"
    >
      <div style={isMobileView ? { height: "100%" } : {}}>
        <Auth onSignup={onSignup}>
          {({ handleSubmitNoEvent, loading, error }) => (
            <>
              <Formik
                initialValues={{ identifier: "", password: "" }}
                validate={validate}
                onSubmit={handleSubmit(handleSubmitNoEvent)}
              >
                {props => renderForm({ ...props, error, loading })}
              </Formik>
            </>
          )}
        </Auth>
      </div>
    </AuthLayout>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default Login;
