import { HelpOutline } from "@material-ui/icons";
import ListModal from "components_v2/ListModal/ListModal";
import Tooltip from "components_v2/Tooltip/Tooltip";
import SelectActionButton from "components_v2/SelectActionButton/SelectActionButton";
import StarRating from "components_v2/StarRating/StarRating";
import Typography from "components_v2/Typography/Typography";
import GetAllByEventOrSupplier from "gql/Vehicles/GetAllByEventOrSupplier";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { createLocationWithState } from "utils/routerHelpers";
import { filterVehiclesWithAvailableDriver } from "utils/vehicleHelpers";
import CheckboxInput from "components_v2/Inputs/CheckboxInput/CheckboxInput";
import { mockGetAllByEvent } from "./AssignDriver.mock";
import withFormState from "containers/Wrappers/withFormState";

const defaultInfo = {
  driver: {
    fullName: "",
    firstName: "",
    lastName: "",
    identifier: "",
    phone: "",
    company: "We Drive",
    ranking: null
  }
};

const selectionColumns = [
  {
    field: "driverInformation",
    headerName: "Name",
    width: 3,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { firstName, lastName, ranking } = driverInformation.driver;

      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography customVariant="bodySmallRegular">{`${firstName} ${lastName}`}</Typography>
          {(ranking && <StarRating direction="right" rating={ranking} />) ||
            "/"}
        </div>
      );
    }
  },
  {
    field: "driverInformation",
    headerName: "Driver ID",
    width: 3,
    titleWithText: true,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { identifier } = driverInformation.driver;

      return identifier;
    }
  },
  {
    field: "driverInformation",
    headerName: "Company",
    width: 3,
    titleWithText: true,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { company } = driverInformation.driver;

      return company || defaultInfo.driver.company;
    }
  },
  {
    field: "driverInformation",
    headerName: "Car type",
    width: 3,
    hideMobile: true,
    titleWithText: true,
    render: data => {
      const { make, model } = data;

      return `${make} ${model || ""}`;
    }
  },
  {
    field: "plateNumber",
    headerName: "License plate",
    width: 3,
    hideMobile: true,
    titleWithText: true
  }
];

const displayColumns = [
  {
    field: "driverInformation",
    headerName: "Name",
    width: 2,
    titleWithText: true,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { firstName, lastName } = driverInformation.driver;

      return `${firstName}${lastName ? " " + lastName : ""}`;
    }
  },
  {
    field: "driverInformation",
    headerName: "Driver ID",
    width: 1,
    titleWithText: true,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { identifier } = driverInformation.driver;

      return identifier;
    }
  },
  {
    field: "ranking",
    headerName: "Ranking",
    width: 1,
    titleWithText: true,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { ranking } = driverInformation.driver;

      return (
        (ranking && <StarRating direction="right" rating={ranking} />) || "/"
      );
    }
  },
  {
    field: "vehicleNumber",
    headerName: "Vehicle",
    width: 2,
    titleWithText: true,
    hideMobile: true,
    render: data => {
      const { make, model, vehicleNumber } = data;

      return `${make || ""} ${model || ""}${
        vehicleNumber ? ` - ${vehicleNumber}` : ``
      }`;
    }
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true,
    hideMobile: true,
    render: data => {
      const driverInformation = data.driverInformation || defaultInfo;
      const { phone } = driverInformation.driver;

      return phone;
    }
  },
  {
    field: "schedule",
    headerName: "Working hours",
    width: 3,
    titleWithText: true,
    hideMobile: true,
    render: () =>
      // "9am - 1pm, 2pm - 6pm"
      "Not specified"
  }
];

const AssignDriver = ({
  projectId,
  supplierId,
  passengerScheduleStart,
  passengerScheduleType,
  requiredVehicleClass,
  onDone,
  history,
  location,
  values,
  noResults,
  isHelpTutorial,
  singleSelection,
  customButton,
  loadDataOnRender = false,
  customHandleDelete,
  pageView,
  customHandleSelection,
  customSelectionValue,
  incomingItems,
  selectionDisabled,
  maxDrivers,
  setFormState
}) => {
  const [showGlobalDrivers, setShowGlobalDrivers] = useState(false);

  const handleNewDriverClick = () => {
    const { pathname, state } = createLocationWithState(
      `/project/${projectId}/driver/new`,
      location,
      values,
      null,
      true
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } });
  };

  return (
    <>
      <ListModal
        enableRadioUnselect
        title="Select drivers"
        noResults={noResults}
        query={GetAllByEventOrSupplier}
        customHandleDelete={customHandleDelete}
        pageView={pageView}
        customHandleSelection={customHandleSelection}
        customSelectionValue={customSelectionValue}
        dataSelector={data => {
          const filteredVehicles = filterVehiclesWithAvailableDriver(
            data.vehicles,
            passengerScheduleStart,
            passengerScheduleType
          );

          if (!isHelpTutorial)
            return filteredVehicles.filter(
              vehicle =>
                vehicle.assignedDrivers && vehicle.assignedDrivers.length > 0
            );
        }}
        queryOptions={{
          variables: {
            event: showGlobalDrivers ? undefined : projectId,
            supplier: showGlobalDrivers ? undefined : supplierId,
            requiredVehicleClass: showGlobalDrivers
              ? undefined
              : requiredVehicleClass?.id
          },
          fetchPolicy: "no-cache",
          skip: isHelpTutorial
        }}
        singleSelection={singleSelection}
        selectionDisabled={selectionDisabled}
        searchPlaceholder="Search for a driver"
        ignoredSearchFields={["driverschedules", "assignedDrivers"]}
        afterSearch={
          <div style={{ marginBottom: 20 }}>
            <CheckboxInput
              name="hideArchived"
              label="Show all drivers"
              value={showGlobalDrivers}
              onChange={() => {
                setShowGlobalDrivers(showGlobalDrivers => !showGlobalDrivers);
              }}
            />
          </div>
        }
        getAfterSearch={selectedItems =>
          maxDrivers && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{ marginTop: 15 }}
                customVariant="bodySmallRegular"
              >
                {`${selectedItems?.length || 0}/${maxDrivers} Drivers`}
              </Typography>
              <Tooltip
                nocontainer
                title="The maximum number of drivers is determined by the number of vehicles."
              >
                <HelpOutline
                  style={{
                    width: 18,
                    height: "auto",
                    marginLeft: 5,
                    marginTop: 13
                  }}
                />
              </Tooltip>
            </div>
          )
        }
        mainAction={{
          label: "Done",
          onClick: onDone
        }}
        secondaryAction={{
          label: "Create a new driver",
          onClick: handleNewDriverClick
        }}
        incomingItems={incomingItems || values.drivers.map(obj => obj.vehicle)}
        columns={displayColumns}
        renderSelectionColumns={selectionColumns}
        isHelpTutorial={isHelpTutorial}
        helpTutorialMockData={mockGetAllByEvent}
        loadDataOnRender={loadDataOnRender}
        onModalClick={toggleModal =>
          customButton ? (
            customButton(toggleModal)
          ) : (
            <SelectActionButton onClick={toggleModal}>
              Select Drivers
            </SelectActionButton>
          )
        }
      />
    </>
  );
};

export default withFormState(withRouter(AssignDriver));
