import { useQuery } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import Bookings from "components_v2/Bookings/Booking.Container.jsx";
import Highlight from "components_v2/Highlight/Highlight.Container";
import Timezone from "components_v2/Inputs/Timezone/Timezone";
import withMainLayout from "components_v2/Layout/withMainLayout";
import CurrentProjectsScrollerContainer from "components_v2/ProjectSelector/CurrentProjectsScrollerContainer";
import Typography from "components_v2/Typography/Typography";
import withUserRole from "components_v2/Wrappers/withUserRole";

import PassengerScheduleCount from "gql/PassengerSchedules/PassengerScheduleCount";

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import auth from "utils/auth";
import { mobileViewBreakpoint } from "../../../../App.helper";
import ProjectSelector from "../../../ProjectSelector/ProjectSelector";
import { useDate } from "components_v2/Hooks/use-date.hook";
import { getCountData } from "utils/countHelpers";
import "./HomeDashboard.css";

const styles = () => ({});

const userEventIds = auth.getUserEventIds();

const PersistentDrawerLeft = (props) => {
  const { isOps, isHelpTutorial } = props;
  const theme = useTheme();
  const [localCountData, setLocalCountData] = useState({});

  const { selectedDate, setSelectedDate, dayStart, dayEnd } = useDate();


  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const handleDateChange = ({ target }) => {
    const { value } = target;

    setSelectedDate(value);
  };

  let { data: countData, loading: countDataLoading, refetch: refetchCountData } = useQuery(
    PassengerScheduleCount,
    { 
      variables: { 
        start: dayStart,
        end: dayEnd,
      },
      skip: isHelpTutorial,
    }
  );

  const countDataJSON = JSON.stringify(countData);

  useEffect(() => {
    setLocalCountData(countData);
  }, [countDataJSON])

  const handleUpdateBookings = async () => {
    await refetchCountData();
  };

  const { onGoingRidesCount, progressPercentage = 0.00, completedRidesCount } = getCountData(localCountData);
  
  return (
    <>
      {/*  (
        <FAB
          label="Add project"
          icon={FloatingActionNewProject}
          onClick={() => {
            history.push("/project/new");
          }}
        />
        ) */}
      <Box
        mb={4}
        style={{ minHeight: "298px", marginTop: isMobileView ? 0 : "30px" }}
      >
        <Grid container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={
              isMobileView
                ? {
                  flexDirection: "column-reverse",
                  marginTop: "32px",
                  alignItems: "flex-start",
                }
                : { marginBottom: "2px" }
            }
          >
            <Grid
              item
              lg={6}
              style={
                isMobileView ? { marginBottom: "12px", marginTop: "20px" } : {}
              }
            >
              <Typography
                customVariant={isMobileView ? "h4Bold" : "h2Bold"}
              >
                {countDataLoading ? <Skeleton width={200} height={40} /> : <>Hi, {auth.get("userInfo").firstName}!</>}

              </Typography>
            </Grid>
            <Grid
              container
              style={
                isMobileView
                  ? { marginBottom: "-16px", justifyContent: "flex-end" }
                  : { justifyContent: "flex-end" }
              }
            >
              <Timezone
                onChange={handleDateChange}
                style={{ marginBottom: 0 }}
                viewType="day"
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={(isMobileView && { flexDirection: "column" }) || {}}
          >
            <Grid item>
              <Highlight
                dayStart={dayStart}
                dayEnd={dayEnd}
                isHelpTutorial={isHelpTutorial}
                passengerSchedulesLoading={countDataLoading}
                progress={progressPercentage}
                ongoing={onGoingRidesCount}
                completed={completedRidesCount}
              />

              {/* <Highlight
                loading={countDataLoading}
                history={history}
                progress={
                  (localCountData?.passengerScheduleCount?.completeRides /
                    localCountData?.passengerScheduleCount?.totalRides) *
                  100
                }
                alerts={localCountData?.passengerScheduleCount?.unresolvedAlerts || countDataLoading}
                ongoing={
                  localCountData ? localCountData?.passengerScheduleCount?.totalRides -
                    localCountData?.passengerScheduleCount?.completeRides : countDataLoading
                }
                completed={ getCompletedData({localCountData ,countDataLoading}) }
              /> */}
            </Grid>
            <Grid
              item
              style={{
                width: !isMobileView && "calc(100% - 430px)",
                overflow: !isMobileView && "hidden",
              }}
            >
              <Box

                ml={2}
                style={
                  isMobileView
                    ? { marginTop: "16px", marginBottom: "24px" }
                    : {}
                }
              >
                {(isMobileView && (
                  <div className="hd-project-selector-wrapper">
                    <ProjectSelector
                      isHelpTutorial={isHelpTutorial}
                      defaultTitle={isMobileView && "Current Projects"}
                      menuOffset={{ top: 40, left: -10 }}
                      showProjectsCount
                      showSeeAllProjects
                      render={(projectName) => (
                        <Typography
                          customVariant={isMobileView ? "h4Bold":"h2Bold"}
                        >
                          {projectName}
                        </Typography>
                      )}
                    />
                  </div>
                )) || (
                    <CurrentProjectsScrollerContainer
                      isHelpTutorial={isHelpTutorial}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            {/* TODO(PPavlov): Place here filters */}
          </Grid>
        </Grid>
      </Box>
      <div
        style={
          isMobileView
            ? {
              marginTop: -57,
              borderTop: "1px solid rgba(0, 0, 0, 0.1)",
              width: "100%",
              left: 0,
              right: 0,
              position: "absolute",
              padding: "20px",
              paddingTop: "22px",
              paddingBottom: 64,
            }
            : { marginTop: -48 }
        }
      >
        <Bookings
          withMapView
          type="Upcoming"
          isTimezoneVisible
          isMapStatic={isOps}
          isHelpTutorial={isHelpTutorial}
          startDate={selectedDate}
          onUpdateBookings={handleUpdateBookings}
          userEventIds={userEventIds}
        />
      </div>
    </>
  );
};

export default withMainLayout(
  "Dashboard",
  {},
  withRouter(withStyles(styles)(withUserRole("vehicles", PersistentDrawerLeft)))
);
