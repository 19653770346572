import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
// import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Users/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddUserComponent from './AddUser.Component';

const AddUserContainer = ({ match }) => {
  const { userId, step } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return <AddUserComponent data={data?.user} refetchUser={refetch} stepParam={parseInt(step)} />;
};

// AddUserContainer.propTypes = {
//   match: PropTypes.shape({
//     params: PropTypes.shape({ userId: PropTypes.string }),
//   }),
// };

export default withMainLayout('AddSupplier', {}, withRouter(AddUserContainer));
