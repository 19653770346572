  import React from 'react';
import PropTypes from 'prop-types';
import ViewVehicleContainer from 'components_v2/Views/Internal/ViewVehicle/ViewVehicle.Container';
import Tabs from 'components_v2/Tabs/Tabs';
import Typography from 'components_v2/Typography/Typography';
import ServiceTrackingContainer from '../../../ServiceTracking/ServiceTracking.Container';
import { Edit } from '../../../Icons/Icons';

const MainContainer = ({isInlineView, data, selectedTab, onChangeTab, vehicleId }) => (
  <>
    {!isInlineView &&<div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '275px',
      }}
    >
      <Typography customVariant="h2Bold">{`${data.make} ${data.model}`}</Typography>
      <a
        onClick={() => onChangeTab('edit')}
        style={{ cursor: 'pointer', fill: '#ccc', marginLeft: 8 }}
      >
        <Edit />
      </a>
    </div>}
    <Tabs
      scrollable
      noBg
      noPadding
      selectedTab={selectedTab}
      onChangeTab={onChangeTab}
      tabs={[
        {
          label: 'Basic Information',
          slug: 'information',
          component: (
            <ViewVehicleContainer vehicleId={vehicleId} />
            // <div style={{ marginTop: 24 }}>{`${data.make} ${data.model}`}</div>
          ),
        },
        {
          label: 'Bookings',
          slug: 'bookings',
          component: <div style={{ marginTop: 24 }} />,
        },
        {
          label: 'Tracking',
          slug: 'tracking',
          component: (
            <div style={{ marginTop: 24 }}>
              <ServiceTrackingContainer id={vehicleId} />
            </div>
          ),
        },
      ]}
    />
  </>
);
MainContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedTab: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired,
};
export default MainContainer;
