import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../../../App.helper";

export const useStyles = makeStyles(({ breakpoints }) => ({
  vehicleDetailsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: 24,
    gridColumnGap: "40px",
    gridRowGap: "8px",
    [breakpoints.down(mobileViewBreakpoint)]: {
      display: "block",
      padding: 0,
    },
  },

  plateNumber: {
    gridColumn: 1,
    gridRow: 2,
    paddingBottom: "8px",
    fontWeight: "bold",
  },
  makeRow: {
    gridColumn: 1,
    gridRow: 3,
    paddingBottom: "8px",
    fontWeight: "bold",
  },
  addPhotoRow: {
    gridColumn: 3,
    gridRow: "2 / span 3",
    display: "flex",
    flexDirection: "column",
  },
  Model: {
    gridColumn: 1,
    gridRow: 4,
    paddingBottom: "8px",
    fontWeight: "bold",
  },
  Class: {
    gridColumn: 2,
    gridRow: 4,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  Color: {
    gridColumn: 1,
    gridRow: 6,
    paddingBottom: "48px",
    fontWeight: "bold",
  },
  maxPassengers: {
    gridColumn: 1,
    gridRow: 5,
    paddingBottom: "8px",
    fontWeight: "bold",
  },

  maxLuggage: {
    gridColumn: 2,
    gridRow: 5,
    paddingBottom: "8px",
    fontWeight: "bold",
  },
}));
