import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import Accordion from "components_v2/Accordion/Accordion";
import Button from "components_v2/Button/Button";
import Typography from "components_v2/Typography/Typography";
import UsersList from "components_v2/List/Variations/UsersList/UsersList.Container";
import SettingsContainer from "./../../../Views/Internal/Settings/Settings.Container";
import ClientDetails from "../../Internal/Clients/ClientDetails/ClientDetails";
import withUserRole from "components_v2/Wrappers/withUserRole";
import { checkUserPermission } from "utils/permissionHelpers";
import { mobileViewBreakpoint } from "../../../../App.helper";
import Skeleton from "@material-ui/lab/Skeleton";
import DynamicListSkeleton from "../../../../components_v2/List/DynamicListSkeleton";



const ProjectDetails = ({
  projectId,
  history,
  isHelpTutorial,
  allPermissions,
  projectData
}) => {
  const classes = useStyles();
  const NextSteps = FloatNum;

  


  return (
    <div>

      {projectData?.status === "Draft" ? (
        <div className={classes.styledBox}>
          <Typography customVariant="h4Bold" style={{ marginBottom: "1em" }}>
            Here are some next steps...
          </Typography>
          <div className={classes.stepsWrapper}>
            <div className={classes.stepsList}>
              <span
                style={{
                  display: "flex",
                  opacity: checkUserPermission(allPermissions, "administration")
                    .canEdit
                    ? 1
                    : 0.4
                }}
              >
                <NextSteps
                  num="1"
                  label="Customize"
                  to={
                    checkUserPermission(allPermissions, "administration")
                      .canEdit
                      ? `/project/${projectId}/edit`
                      : `/project/${projectId}/details`
                  }
                />
              </span>

              <NextSteps
                num="2"
                label="Add Locations"
                to={`/project/${projectId}/location/new`}
              />
              <NextSteps
                num="3"
                label="Add Passengers"
                to={`/project/${projectId}/passenger/new`}
              />
              <NextSteps
                num="4"
                label="Add Bookings"
                to={`/project/${projectId}/booking/new`}
              />
            </div>
            <div className={classes.boxButtonContainer}>
              {checkUserPermission(allPermissions, "administration")
                .canEdit && (
                  <TeamButton className={classes.buttons}>
                    <Link
                      className={classes.assignToTeamMembersBtn}
                      to="/user/new"
                    >
                      Add staff
                    </Link>
                  </TeamButton>
                )}
              <Button
                className={classes.buttons}
                onClick={() =>
                  history.push(`/project/${projectId}/passenger/new`)
                }
              >
                Add passengers
              </Button>
            </div>
          </div>
        </div>
      ) : (<div><Skeleton variant="rect" width={"100%"} height={120} style={{ borderRadius: 6, marginBottom: "32px" }} /></div>)}
      {projectData ? (<section className={classes.styledSection}>
        <Typography customVariant="h5Bold" style={{ marginBottom: 16 }}>
          Project Set Up
        </Typography>

        {checkUserPermission(allPermissions, "administration").canEdit && (
          <Accordion summary="Project Information">
            <Button
              variant="simple"
              onClick={() => history.push(`/project/${projectId}/edit`)}
            >
              {" "}
              Edit Project Information{" "}
            </Button>
          </Accordion>
        )}
        <Accordion summary="Client Information">
          <ClientDetails
            client={(projectData && projectData.projectClient) || {}}
            isEventsNotVisible={true}
          />
        </Accordion>

        {checkUserPermission(allPermissions, "administration").canEdit && (
          <Accordion summary="Internal Project Team">
            <UsersList
              projectId={projectId}
              isHelpTutorial={isHelpTutorial}
            />
          </Accordion>
        )}
      </section>) : (<div style={{ marginBottom: "40px" }}><Skeleton variant="rect" width={"125px"} height={28} style={{ margin: "16px 0", borderRadius: 6, }} /><Skeleton variant="rect" width={"100%"} height={120} style={{ borderRadius: 6 }} /></div>)}

      {projectData ? (<section className={classes.styledSection}>
        <Typography customVariant="h5Bold" style={{ marginBottom: 16 }}>
          Settings
        </Typography>

        <SettingsContainer
          projectId={projectId}
          isHelpTutorial={isHelpTutorial}
        ></SettingsContainer>
      </section>) : (<DynamicListSkeleton />)}

    </div>
  );
};

const FloatNum = ({ num, label, to }) => {
  const classes = useStyles();
  return (
    <div className={classes.stepContainer}>
      <div className={classes.floatNum}>{num}</div>
      <Link to={to} className={classes.nextStepLink}>
        {label}
      </Link>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  styledBox: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    padding: "1.25em 0 1.25em 2em",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "20px"
    }
  },
  stepsWrapper: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down(1140)]: {
      display: "flex",
      flexDirection: "column"
    }
  },
  stepsList: {
    display: "flex",
    [theme.breakpoints.down(1140)]: {
      marginBottom: "20px"
    },
    [theme.breakpoints.down(760)]: {
      display: "flex",
      flexDirection: "column"
    }
  },
  assignToTeamMembersBtn: {
    color: "inherit",
    textDecoration: "none"
  },
  nextStepLink: {
    fontSize: "14px",
    lineHeight: "134.3%",
    letterSpacing: "-0.2px",
    textDecoration: "none",
    color: "rgba(0, 0, 0, 1)"
  },
  floatNum: {
    display: "inline",
    bottom: "3px",
    textAlign: "center",
    height: "28px",
    width: "28px",
    border: "1px dashed rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    padding: ".3em .9em",
    marginRight: "12px",
    backgroundColor: "#fbfbfb",
    fontSize: ".7em"
  },
  stepContainer: {
    marginRight: "2em",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(760)]: {
      margin: "10px 0"
    }
  },
  boxButtonContainer: {
    position: "relative",
    fontSize: "1rem",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: 0
    }
  },
  buttons: {
    padding: ".5em 1em",
    margin: "0 1em 0 0"
  },
  styledSection: {
    margin: "40px 0"
  }
}));

const TeamButton = withStyles({
  root: {
    backgroundColor: "#fbfbfb",
    border: "1px dashed rgb(64, 1, 197, 1)",
    "&:hover > $span": {
      color: "#fbfbfb"
    }
  },
  label: {
    color: "rgb(64, 1, 197, 1)"
  }
})(Button);

export default withRouter(withUserRole("administration", ProjectDetails));
