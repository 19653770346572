/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';

import { Formik, Form } from 'formik';
import BarGraph from 'components_v2/Charts/BarGraph/BarGraph';
import PieGraph from 'components_v2/Charts/PieGraph/PieGraph';
import StatusGraph from 'components_v2/Charts/StatusGraph/StatusGraph';
import SearchInput from 'components_v2/Inputs/SearchInput/SearchInput';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import TextAreaInput from 'components_v2/Inputs/TextAreaInput/TextAreaInput';
import CheckboxInput from 'components_v2/Inputs/CheckboxInput/CheckboxInput';
import RadioGroupInput from 'components_v2/Inputs/RadioGroupInput/RadioGroupInput';
import PasswordInput from 'components_v2/Inputs/PasswordInput/PasswordInput';
import PhoneInput from 'components_v2/Inputs/PhoneInput/PhoneInput';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import TimepickerInput from 'components_v2/Inputs/TimepickerInput/TimepickerInput';
import DatepickerInput from 'components_v2/Inputs/DatepickerInput/DatepickerInput';
import DatepickerButton from 'components_v2/Inputs/DatepickerButton/DatepickerButton';
import Timezone from 'components_v2/Inputs/Timezone/Timezone';
import ToggleSwitchButton from 'components_v2/Inputs/ToggleSwitchButton/ToggleSwitchButton';
import ToggleIconButton from 'components_v2/Inputs/ToggleIconButton/ToggleIconButton';
import ToggleTextSwitchButton from 'components_v2/Inputs/ToggleTextSwitchButton/ToggleTextSwitchButton';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';
import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import ContentTag from 'components_v2/Inputs/ContentTag/ContentTag';
import * as Yup from 'yup';
import Filters from '../Inputs/Filters/Filters';
import SortBy from '../Inputs/SortBy/SortBy';
import { barData, barConfig } from './bar_data';
import { pieData } from './pie_data';
import { statusGraphData } from './status_graph_data';
import { top100Films } from './movies';
import { top100Radios } from './radios';
import { defaultLocationOptionsData } from './default_location_options_data';
import { toggleIconBasedOptions, toggleTextBasedOptions } from './views';
import { sortByData } from './sortby_data';
import AddRemove from '../Inputs/AddRemove/AddRemove';
import { Visible } from '../Icons/Icons';
import LocationSearchInput from '../Inputs/LocationSearchInput/LocationSearchInput';
import IconSelector from '../Inputs/IconSelector/IconSelector';
import { iconSelectorOptionsData } from './icon_selector_options_data';
import CurrencySelect from '../Inputs/CurrencySelect/CurrencySelect';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#eaeaea',
      padding: '10px',
    },
    form: {
      background: '#ffffff',
    },
  })
);

// eslint-disable-next-line no-useless-escape
const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationSchema = Yup.object().shape({
  projectName: Yup.string().required('Required').min(2, 'Too Short!'),
  firstName: Yup.string().required('Required').min(2, 'Too Short!'),
  lastName: Yup.string().required('Required').min(2, 'Too Short!'),
  email: Yup.string()
    .required('Required')
    .test('Validation Test', 'Invalid email', async (email) => {
      if (!emailRegExp.test(email)) {
        return false;
      }
      return true;
    }),
});

const SampleForm = () => {
  const classes = useStyles();

  const defaultFormState = {
    currency: null,
    iconSelector: 'Admin',
    iconSelector1: null,
    dataSelector: {
      label: 'Batman',
      value: 'batman',
    },
    googleMaps: null,
    counter1: 20,
    counter2: 20,
    sortedBy: {
      label: 'Major',
      other: '(22)',
      isHighlighted: true,
      value: 'major',
    },
    filtersSelected: {
      module: 'Module',
      vehicleType: 'Vehicle Type',
      alertType: 'Alert Type',
      status: 'Status',
      rideRating: 'Ride Rating',
    },
    searchText: '',
    contentTag: { category: 'Category' },
    timezoneDate: '2018-01-02T08:30:00+04:00',
    timezoneDate1: undefined,
    currentTimezoneDate: '2018-01-02T08:30:00+04:00',
    currentTimezoneDate1: undefined,
    whichTextView: 'static',
    whichView: '1994',
    isToggleSwitch: true,
    isProject: true,
    projectType: '1972',
    projectName: null,
    projectDescription: 'testing description',
    password: null,
    phone: '+971561806907',
    movie: null,
    movies: [],
    ownMovies: [],
    showTime: '2018-01-01T06:00:00.000Z',
    showDate: '2018-01-01T06:00:00.000Z',
    showDateButton: '2018-01-01T06:00:00.000Z',
  };
  // const [formState, setFormState] = useState(defaultFormState)

  return (
    <div className={classes.root} p={3} id="sample-form">
      <h2>Enter the main project information</h2>

      <Formik
        initialValues={defaultFormState}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, errors, handleChange } = props;

          return (
            <Form>
              <Grid container>
                <Grid item xs={6} className={classes.form}>
                  <Grid container justify="space-around" direction="row">
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <CurrencySelect
                        name="currency"
                        label="Currency"
                        placeholder="Please select currency"
                        value={values.currency}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <IconSelector
                        name="iconSelector1"
                        onChange={handleChange}
                        label="Select Icon +"
                        options={iconSelectorOptionsData}
                        value={values.iconSelector1}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <IconSelector
                        name="iconSelector"
                        onChange={handleChange}
                        label="Select Icon +"
                        options={iconSelectorOptionsData}
                        value={values.iconSelector}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <LocationSearchInput
                        name="googleMaps"
                        onChange={handleChange}
                        label="Some location"
                        placeholder="Some placeholder"
                        defaultOptions={defaultLocationOptionsData}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <AddRemove
                        label="Counter 1"
                        name="counter1"
                        value={values.counter1}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <AddRemove
                        label="Counter 2"
                        isDisabled
                        name="counter2"
                        value={values.counter2}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <SortBy
                        name="sortedBy"
                        value={values.sortedBy}
                        list={sortByData}
                        onChange={handleChange}
                      />
                      <br />
                      <br />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Filters
                        filtersSelected={values.filtersSelected}
                        onFilterBtnClick={() => {}}
                        onDelete={() => {}}
                        onClearAll={() => {}}
                      />
                    </Grid>
                    {/* <Grid item lg={10} md={10} sm={10} xs={10}>
                      <CounterGraph data={counterGraphData} />
                    </Grid> */}
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <StatusGraph data={statusGraphData} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <PieGraph data={pieData} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <BarGraph data={barData} config={barConfig} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <FormSection title="Enter the main project information">
                        // eslint-disable-next-line react/jsx-max-depth
                        <SearchInput
                          name="searchText"
                          label="Search Text"
                          placeholder="Search me"
                          value={values.searchText}
                          onChange={handleChange}
                        />
                      </FormSection>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <ContentTag tag={values.contentTag} onDelete={() => {}} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <TotalErrors errors={errors} />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Timezone
                        timezone="Asia/Riyadh"
                        name="timezoneDate"
                        label="Timezone data"
                        value={values.timezoneDate}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Timezone
                        timezone="Asia/Kolkata"
                        name="timezoneDate1"
                        label="Timezone data 1"
                        value={values.timezoneDate1}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Timezone
                        name="currentTimezoneDate"
                        label="Current Timezone data"
                        value={values.currentTimezoneDate}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <Timezone
                        name="currentTimezoneDate1"
                        label="Current Timezone data 1"
                        value={values.currentTimezoneDate1}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <ToggleTextSwitchButton
                        name="whichTextView"
                        label="Which Text view"
                        value={values.whichTextView}
                        onChange={handleChange}
                        options={toggleTextBasedOptions}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <ToggleIconButton
                        name="whichView"
                        label="Which view"
                        value={values.whichView}
                        onChange={handleChange}
                        options={toggleIconBasedOptions}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <ToggleSwitchButton
                        name="isToggleSwitch"
                        label="Is Toggle Switch"
                        value={values.isToggleSwitch}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <DatepickerButton
                        name="showDateButton"
                        label="Show date button"
                        placeholder="Please enter your show date button"
                        value={values.showDateButton}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <RadioGroupInput
                        name="projectType"
                        label="Project type"
                        value={values.projectType}
                        onChange={handleChange}
                        options={top100Radios}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <CheckboxInput
                        name="isProject"
                        label="Is this a project"
                        placeholder="Is this a project"
                        value={values.isProject}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <TextInput
                        name="projectName"
                        label="Project name"
                        placeholder="As per project brief"
                        value={values.projectName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <TextAreaInput
                        name="projectDescription"
                        label="Project description"
                        placeholder="As per project description"
                        value={values.projectDescription}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <PasswordInput
                        name="password"
                        label="Password"
                        placeholder="Please enter your password"
                        value={values.password}
                        icon={Visible}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <PhoneInput
                        name="phone"
                        label="Phone"
                        placeholder="Please enter your phone number"
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <SelectInput
                        name="movie"
                        label="Movie"
                        placeholder="Please enter your movie"
                        value={values.movie}
                        onChange={handleChange}
                        options={top100Films}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <SelectInput
                        name="movies"
                        label="Movies"
                        placeholder="Please enter your movies"
                        value={values.movies}
                        onChange={handleChange}
                        options={top100Films}
                        isMultiple
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <SelectInput
                        name="ownMovies"
                        label="Own Movies"
                        placeholder="Please enter your own movies"
                        value={values.ownMovies}
                        onChange={handleChange}
                        options={top100Films}
                        isMultiple
                        isFreeSoloAllowed
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <TimepickerInput
                        name="showTime"
                        label="Show time"
                        placeholder="Please enter your show time"
                        value={values.showTime}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                      <DatepickerInput
                        name="showDate"
                        label="Show date"
                        placeholder="Please enter your show date"
                        value={values.showDate}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <code>
                    <pre>{JSON.stringify(values, null, 2)}</pre>
                  </code>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SampleForm;
