import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextInput from '../../Inputs/TextInput/TextInput';
import PhoneInput from '../../Inputs/PhoneInput/PhoneInput';
import PasswordInput from '../../Inputs/PasswordInput/PasswordInput';
import Button from '../../Button/Button';
import AccountHeader from '../AccountHeader';
import Loading from 'components_v2/Loading/LinearProgress';
import { mobileViewBreakpoint } from '../../../App.helper';
import { deepCopy } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: '534px',
    background: '#FFFFFF',
    boxShadow: '-16px 0px 25px rgba(0, 0, 0, 0.1)',
    height: '100%',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  body: {
    margin: '40px 24px 0',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: '0',
      marginTop: '40px',
      flex: '1',
      flexWrap: 'wrap',
      overflow: 'auto',
    },

    '& > form': {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    '& > form > .MuiGrid-container > .MuiGrid-item': {
      marginBottom: '16px',

      '&:last-child': {
        textAlign: 'right',

        [theme.breakpoints.down(mobileViewBreakpoint)]: {
          textAlign: 'left',
        },

        '& button': {
          padding: 0,
          minWidth: 0,
        },
      },
    },
  },
  footer: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '20px',
      textAlign: 'right',
      marginTop: 'auto',
      zIndex: 1,
      boxShadow: '0px -10px 17px rgba(0, 0, 0, 0.1)',
      background: '#ffffff',
    },
  },
}));

const validationSchema = Yup.object().shape({
  phone: Yup.string().required('Required').nullable().min(2, 'Too Short!'),
  password: Yup.string()
    .nullable()
    .oneOf([Yup.ref('repeatPassword'), null], 'Passwords must match'),
  repeatPassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const AccountDetails = ({
  onBackClick,
  onCloseClick,
  accountDetailsData,
  onAccountDetailsSave,
  refetchAccountDetals,
  isLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const defaultFormState = {
    firstName: accountDetailsData?.firstName,
    lastName: accountDetailsData?.lastName,
    email: accountDetailsData?.email,
    phone: accountDetailsData?.phone,
    password: '',
    repeatPassword: '',
  };

  return (
    <>
      <div className={classes.sidebar}>
        <AccountHeader
          title="Account Details"
          onBackClick={onBackClick}
          onCloseClick={onCloseClick}
        />

        <div className={classes.body}>
          <Formik
            enableReinitialize
            initialValues={defaultFormState}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
              const localValues = deepCopy(values)
              delete localValues.email;
              delete localValues.repeatPassword;

              if (!localValues.password) delete localValues.password;

              await onAccountDetailsSave({
                variables: {
                  data: localValues,
                },
              });

              await refetchAccountDetals();

              actions.setSubmitting(false);
            }}
          >
            {(props) => {
              const { values, errors, handleChange } = props;
              return (
                <Form>
                  <Grid
                    container
                    style={
                      (isMobileView && {
                        padding: '0px 24px 0px',
                        background: '#ffffff',
                        overflow: 'auto',

                      }) ||
                      {}
                    }
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextInput
                        name="firstName"
                        label="First name"
                        value={values.firstName}
                        error={errors.firstName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextInput
                        name="lastName"
                        label="Last name"
                        value={values.lastName}
                        error={errors.lastName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextInput
                        name="email"
                        label="Email"
                        value={values.email}
                        error={errors.email}
                        onChange={handleChange}
                        isDisabled
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <PhoneInput
                        name="phone"
                        label="Contact Number"
                        error={errors.phone}
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <PasswordInput
                        name="password"
                        label="Password"
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <PasswordInput
                        name="repeatPassword"
                        label="Repeat Password"
                        value={values.repeatPassword}
                        error={errors.repeatPassword}
                        onChange={handleChange}
                      />
                    </Grid>
                    {!isMobileView && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          variant="simple"
                          type="submit"
                          disabled={isLoading}
                        >
                          Save
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  {isMobileView && (
                    <footer className={classes.footer}>
                      <Button
                        variant="simple"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                    </footer>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};

export default AccountDetails;
