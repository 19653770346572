import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  vehicleClassNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  vehicleInfoContainer: {
    display: 'column',
    justifyContent: 'center',
    marginLeft: 16,
  },
}));

export default useStyles;
