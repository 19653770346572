import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import BookingOptions from "./BookingOptions";
import { mobileViewBreakpoint } from '../../App.helper';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    background: "rgba(0, 0, 0, 0.02)",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      boxShadow: '0px -10px 17px rgba(0, 0, 0, 0.1)',
      background: "#fff",
    },

  },
  footerLinkText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "rgba(64, 1, 197, 1)",
    cursor: "pointer",
  },
  footerLink: {
    textDecoration: "none",
  },
  footerMoreButton: {
    padding: "0",
    marginLeft: "16px",
    color: "#000000",

    "&:hover": {
      backgroundColor: "transparent",
    },

    backgroundColor: "transparent",
    border: "0px",
  },
  footerAssignDriverButton: {}
}));

const BookingDetailFooter = ({onSendDriverLinkLoading ,onSendEmailLoading, isTrackRide, onDelete, isExpanded, history, toggleExpanded, isDriverScheduled, data, onOvertimeLoading, onAssignDriverLoading, onDeleteLoading, onCancelLoading }) => {
  const classes = useStyles();

  return <footer className={classes.footer}>
    {isDriverScheduled && <div onClick={toggleExpanded}>
      <Typography className={classes.footerLinkText}>
        {isExpanded ? "View Less" : "View More"}
      </Typography>
    </div>}
    <aside>

      <BookingOptions onSendDriverLinkLoading={onSendDriverLinkLoading} onSendEmailLoading={onSendEmailLoading} onCancelLoading={onCancelLoading} onDeleteLoading={onDeleteLoading} onDelete={onDelete} isTrackRide={isTrackRide} onOvertimeLoading={onOvertimeLoading} onAssignDriverLoading={onAssignDriverLoading} data={data} history={history} />

    </aside>
  </footer>
}

export default BookingDetailFooter;