import React from 'react';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: 1,
  },
  colorPrimary: {
    color: 'rgba(64, 1, 197, 0.6)',
  },
});

const CircularProgress = ({
  fullPage,
  contained,
  backgroundPosition,
  backgroundWidth,
  backgroundHeight,
  style,
  size = 67,
  thickness = 7.8,
  zIndex
}) => {

  const classes = useStyles();
  const backgroundStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: backgroundPosition || 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: backgroundWidth || '100vw',
    height: backgroundHeight || '100vh',
    backgroundColor: 'rgba(255,255,255, 0.8)',
    zIndex: zIndex || 5000,
  };

  let containerStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  };

  const containedStyles = {
    width: '100%',
    height: '100%',
    zIndex: 100,
    left: 0,
    right: 0,
    top: 0,
    background: 'rgba(255, 255, 255, 0.8)',
    position: 'absolute',
  };

  const trackStyles = {
    position: 'absolute',
    boxShadow: '0 0 0 12px rgba(0, 0, 0, 0.03) inset',
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size,
    height: size,
    zIndex: 0,
  };

  if (contained) containerStyles = { ...containerStyles, ...containedStyles };


  if (fullPage)
    return (
      <div style={backgroundStyles}>
        <div style={containerStyles}>
          <MuiCircularProgress
            size={size}
            thickness={thickness}
            classes={{ root: classes.root, colorPrimary: classes.colorPrimary }}
          />
          <div style={trackStyles} />
        </div>
      </div>
    );

  return (
    <div style={{ ...containerStyles, ...style }}>
      <MuiCircularProgress
        size={size}
        thickness={thickness}
        classes={{ root: classes.root, colorPrimary: classes.colorPrimary }}
      />
      <div style={trackStyles} />
    </div>
  );
};

CircularProgress.propTypes = {
  fullPage: PropTypes.bool,
};

export default CircularProgress;
