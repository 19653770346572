import { useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/react-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FAB from "components_v2/Buttons/FAB/FAB";
import { FloatingActionNewVehicle } from "components_v2/Icons/Icons";
import DynamicList from "components_v2/List/DynamicList";
// import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import VehicleViewContainer from "components_v2/Views/Internal/Vehicle/VehicleView.Container";
import SelectionModal from 'components_v2/Modal/SelectionModal/SelectionModal';
import GetVehiclesByEvent from "gql/Vehicles/GetAllByEvent";
import GetVehiclesBySupplier from "gql/Vehicles/GetAllBySupplier";
import { mobileViewBreakpoint } from "../../../App.helper";
import { createLocationWithState } from 'utils/routerHelpers';
import {
  getVehicleHamburgerMenuItems,
  getVehicleMobileMenuItems,
  handleAddBooking
} from "./menuItems";
import { NetworkStatus } from "@apollo/client";
import { getVehicleColumns } from './columns';
import Delete from "../../../gql/Vehicles/Delete";
import DeleteConfirmationModal from "../../Modal/DeleteConfirmationModal/DeleteConfirmationModal";



const MainContainer = ({ title, history, location, match }) => {
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const { projectId, supplierId } = match.params;
  const id = projectId || supplierId;
  const type = projectId ? "project" : "supplier";
  const query = projectId ? GetVehiclesByEvent : GetVehiclesBySupplier;

  const { data: vehiclesData, refetch, loading, networkStatus } = useQuery(
    query,
    {
      variables: { id, ...listConfig, sort: listConfig.sort || 'id:desc' },
      fetchPolicy: "no-cache",
      // skip: isHelpTutorial,
      notifyOnNetworkStatusChange: true,
    }
  );
  const [
    deleteVehicle,
  ] = useMutation(Delete);
  const [
    deleteConfirmationModalData,
    setDeleteConfirmationModalData,
  ] = useState(null);

  const data = vehiclesData?.vehiclesConnection?.values || [];
  const count = vehiclesData?.vehiclesConnection?.aggregate?.count || 0;

  const deleteVehiclesRecord = async () => {
    const recordToDelete = deleteConfirmationModalData.recordId;

    setDeleteConfirmationModalData(null);

    await deleteVehicle({
      variables: {
        id: recordToDelete,
      },
    });

    await refetch();
  };

  const [mobileModalData, setMobileModalData] = useState(null);

  function handleNewDriverClick() {
    const locationWithState = createLocationWithState(
      `/project/${projectId}/driver/new`,
      location,
      {},
      null,
      true
    );

    history.push(locationWithState)
  }

  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }

  return (
    <>
      <SelectionModal
        data={mobileModalData}
        onClickCancel={() => {
          setMobileModalData(null);
        }}
        onClickConfirm={(driverId) => handleAddBooking({ driverId: driverId, vehicleId: mobileModalData?.id, projectId: projectId, history: history })}
      />
      <DeleteConfirmationModal
        deleteConfirmationModalData={deleteConfirmationModalData}
        onClickCancel={() => {
          setDeleteConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteVehiclesRecord();
        }}
      />
      <FAB
        label="Add vehicle"
        icon={FloatingActionNewVehicle}
        onClick={() => {
          history.push(`/${type}/${id}/vehicle/new`);
        }} 
      />
      <DynamicList
        disableFrontEndFilter
        disableFrontEndSort
        listConfig={{...listConfig, count}}
        onListConfigChange={handleListConfigChange}
        isMobileView={isMobileView}
        showHeader
        loading={loading || networkStatus === NetworkStatus.refetch}
        data={data}
        title={title}
        noResultsText="No vehices"
        enableFreezeHeader
        headerFreezeLeftContent="Vehicles"
        threshold="250"
        numRows={count}
        getHamburgerMenuItems={(row) => [
          ...getVehicleHamburgerMenuItems({ history, handleNewClick: handleNewDriverClick, type, id, row }),
          {
            label: "Delete",
            onClick: (row) => {
              setDeleteConfirmationModalData({
                recordName: row.vehicleClass.name,
                isNotDeleteAllowed: row.assignedDrivers.length > 0,
                recordId: row.id,
                confirmationType: "delete",
                deleteNotAllowedReason:
                  "This vehicle cannot be deleted because it is assigned to drivers.",
              });
            },
          },
        ]}
        getMobileMenuItems={(row) =>
          getVehicleMobileMenuItems({
            history,
            handleNewClick: handleNewDriverClick,
            type,
            row,
            id,
            setDeleteConfirmationModalData,
            setMobileModalData,
          })
        }
        detailPanel={(row) => <VehicleViewContainer data={row} />}
        actions={
          {
            // rowClick: row => {
            //   //history.push(`/vehicle/${row.id}`);
            //   history.push(`/vehicle/${row.id}`);
            // }
            /*
        primary: {
          onClick: (row) => {
           
          },
          label: (row) => 'Add Bookings',
        }, */
          }
        }
        columns={getVehicleColumns({ projectId })}
      />
    </>
  );
};
export default withRouter(MainContainer);
