import React from "react";
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { Messages } from "../../Icons/Icons";
import TitleWithText from "components_v2/TitleWithText/TitleWithText";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const SendDriverLinkConfirmationModal = ({ data, onClickCancel, onClickSend }) => {
  const getAddress = (person) => {

    return `${person?.name}, ${person?.address}`
  };
  return <Modal
    size="sm"
    pageView={!!data}
  >
    <SuccessCard
   icon={
        <Messages
          style={{
            color: "#FFFF",
            fill: "white",
            width: 40,
            height: 40,
            filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
          }}
        />
      }
      mainAction={{
        text: "Send",
        onClick: () => {
          onClickSend({
            id: data?.record?.id
          })
        }
      }
      }
      secondaryAction={{
        text: "Cancel",
        onClick: onClickCancel,
      }}
      headerSize="h4Bold"
      headerText={`Send driver link`}
    >


      <div style={{ textAlign: 'start' }}>


        <Grid
          container
          justify="center"
          direction="row"
        >

          <Grid item xs={12} md={12}>
            <TitleWithText

              text=
              {`Reservation: ${data?.record?.bookingId}`}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TitleWithText

              text={`Passenger: ${data?.record?.passenger.fullName}`}
            />
          </Grid>

          <Grid item xs={12} md={12}>

            <TitleWithText
              text={`Pickup Time: ${moment.utc(data?.record?.start).format('YYYY-MM-DD') + " at " + moment.utc(data?.record?.start).format("HH:mm")}
                
                `}

            />

          </Grid>
          <Grid item xs={12} md={12}>
            <TitleWithText
              label=""
              text={`From: ${getAddress(data?.record?.from)}`}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TitleWithText
              label=""
              text={`To: ${getAddress(data?.record?.to)}`}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TitleWithText
              text={`Driver: ${data?.record?.driver.fullName} `}
            />
          </Grid>
        </Grid>
      </div>
    </SuccessCard>
  </Modal>
}


export default SendDriverLinkConfirmationModal;