import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  footerActions: {
    margin: '56px 0 0 0',
    padding: '0 40px',
    background: 'rgba(0, 0, 0, 0.02)',
    height: 96,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: 0,
      height: '80px',
      padding: '0 16px',
    },

    '& button:nth-of-type(2)': {
      marginLeft: 32,
    },
  },
}));

const FormFooter = ({ mainAction, secondaryAction }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footerActions}>
      {secondaryAction}
      {mainAction}
    </footer>
  );
};

export default FormFooter;
