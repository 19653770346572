import gql from "graphql-tag";

export default gql`
  query GetAllUsers(
    $sort: String
    $pageSize: Int
    $startIndex: Int
    $filterKey: String
  ) {
    usersConnection(
      where: {
        role_in: [3]
        _or: [
          { firstName_contains: $filterKey }
          { lastName_contains: $filterKey }
          { phone_contains: $filterKey }
          { company_contains: $filterKey }
          { email_contains: $filterKey }
        ]
      }
      limit: $pageSize
      start: $startIndex
      sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        email
        firstName
        lastName
        fullName
        phone
        confirmed
        blocked
        company
        identifier
        username
        notes
        role {
          id
          name
        }
        userEvents {
          id
          event {
            id
            name
          }
        }
      }
    }
  }
`;
