import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../Inputs/TextInput/TextInput";
import Button from "components_v2/Button/Button";
import auth from "../../utils/auth";
import { useMutation, useQuery } from "@apollo/react-hooks";
import CreateAlertComment from "gql/AlertComments/Create";
import GetAlertCommentsGQL from "gql/AlertComments/GetAllByAlert";
import { eventStopPropagation, mobileViewBreakpoint } from "../../App.helper";
import List from '@material-ui/core/List';
import AlertCard from '../TopTools/Alerts/AlertCard'
import Loading from "components_v2/Loading/LinearProgress";


export const useStyles = makeStyles(({ breakpoints }) => ({
  alertsCommentsWrapper: {
    padding: "24px",
  },
  alerts: {
    background: '#FFFFFF',

    [breakpoints.down(mobileViewBreakpoint)]: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    "& .MuiGrid-container": {
      marginRight: '24px',
    },
    '& .MuiList-padding': {
      padding: 0,
    },
  },
}));

const AlertsComments = ({ data }) => {
  const classes = useStyles();
  const [newComment, setNewComment] = useState("");

  const { data: alertCommentsData, loading: alertCommentsLoading, refetch: refetchComments } = useQuery(
    GetAlertCommentsGQL,
    {
      variables: { id: data.id },
      fetchPolicy: "no-cache",
    }
  );

  const [
    createAlertComment,
    { loading: createLoading },
  ] = useMutation(CreateAlertComment);

  const handleSaveBtnClick = async (event) => {
    eventStopPropagation({ event })
    await createAlertComment({
      variables: {
        data: {
          message: newComment,
          postedBy: auth.get("userInfo").id,
          alert: data.id,
        },
      },
    });

    await refetchComments();

    setNewComment("")
  };

  return (
    <div className={classes.alertsCommentsWrapper}>
      {alertCommentsData?.alertcomments.map((comment) => {

        return (
          <div className={classes.alerts}>
            <List>

              <AlertCard isAlertPage cardDetails={comment} />
              {alertCommentsLoading && <Loading />}
            </List>
          </div>

        );
      })}
      <div style={{ marginTop: '24px', }}>
        <TextInput
          multiline
          onChange={(event) => setNewComment(event.target.value)}
          value={newComment}
          placeholder="Enter the comments "
        />
        <Button type="button" variant="contained" onClick={handleSaveBtnClick}>
          Save
        </Button>
        {createLoading && <Loading />}
      </div>
    </div>
  );
};
export default AlertsComments;
