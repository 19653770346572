import React from 'react';

import ListModal from 'components_v2/ListModal/ListModal';
import { getFileExtFromUrl, formatFileSize } from 'utils/file';
import { formatUserFullName } from 'utils/userHelpers';

const documentColumns = [
  {
    field: 'name',
    headerName: 'Document name',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'url',
    headerName: 'File type',
    width: 3,
    render: (row) => getFileExtFromUrl(row.url),
    titleWithText: true,
  },
  {
    field: 'size',
    headerName: 'File Size',
    width: 3,
    render: (row) => `${formatFileSize(row.size)} KB`,
    titleWithText: true,
  },
  {
    field: 'uploadedBy',
    headerName: 'Uploaded by',
    width: 3,
    render: (row) => formatUserFullName(row.uploadedBy),
    titleWithText: true,
    hideMobile: true,
  },
];

const ViewDocuments = ({ bookingId, incomingItems, data, customButton, rowClick }) => (
  <ListModal
    noResults
    documentPreview
    disableSelection
    disableQuery
    title={`${bookingId} Documents`}
    searchPlaceholder="Search for a document"
    data={data}
    incomingItems={incomingItems}
    columns={documentColumns}
    renderSelectionColumns={documentColumns}
    customButton={customButton}
    onModalClick={(toggleModal) => customButton(toggleModal)}
    rowClick={rowClick}
  />
);

export default ViewDocuments;
