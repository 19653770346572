import _ from 'lodash';
// import Skeleton from "@material-ui/lab/Skeleton";
import React from 'react';
import { Box } from '@material-ui/core';
import ListModal from 'components_v2/ListModal/ListModal';
import { withRouter } from 'react-router-dom';
import GetAllVehiclesBySupplier from 'gql/Vehicles/GetAllBySupplier';
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import AddRemove from 'components_v2/Inputs/AddRemove/AddRemove';
import Typography from 'components_v2/Typography/Typography';
import { getIndexBy } from 'utils/arrayHelpers';
import Vehicle from 'components_v2/Vehicle/Vehicle';
import VehiclePriceInputs from '../VehiclePriceInputs/VehiclePriceInputs';
import useStyles from './SupplierVehicleSelector.style';

const Container = ({
  values,
  setFieldValue,
  error,
  fleetSupplierId,
}) => {
  const classes = useStyles();

  const getValue = (fleetSuppliers, id) => {
    const fleetSupplier = _.find(fleetSuppliers, { id: fleetSupplierId });
    const vehicle = _.find(fleetSupplier.selectedVehicles, { id: id });

    return vehicle ? vehicle.quantity : 0;
  };

  const renderVehicleName = (row) => (
    <Box className={classes.vehicleClassNameContainer}>
      <Vehicle type={row.name} customStyle={{ position: 'relative' }} />
      <Box className={classes.vehicleInfoContainer}>
        <Typography customVariant="bodyLargeRegular">
          {row.vehicleClass ? row.vehicleClass.name : ''}
        </Typography>
        <Typography customVariant="bodySmallRegular">
          {`${row.make} ${row.model}`}
        </Typography>
      </Box>
    </Box>
  );

  const selectionColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 6,
      render: renderVehicleName,
    },
    {
      field: 'quantity',
      headerName: 'quantity',
      width: 6,
      render: (row) => (
        <AddRemove
          small
          noMarginBot
          value={getValue(values.fleetSuppliers, row.id)}
          onChange={({ target }) => {
            const newFleetSuppliers = [...values.fleetSuppliers];

            const fleetSupplierIndex = getIndexBy(
              values.fleetSuppliers,
              'id:',
              fleetSupplierId
            );

            const newFleetSupplier = {
              ...values.fleetSuppliers[fleetSupplierIndex],
            };

            const vehicleIndex = getIndexBy(
              newFleetSupplier.selectedVehicles,
              'id:',
              row.id
            );

            let newVehicle;

            if (!vehicleIndex && vehicleIndex !== 0) {
              newVehicle = {
                ...row,
                quantity: target.value,
                supplierPrices: [],
                clientPrices: [],
              };
              newFleetSuppliers[fleetSupplierIndex].selectedVehicles.push(
                newVehicle
              );
            } else {
              const oldVehicle =
                values.fleetSuppliers[fleetSupplierIndex].selectedVehicles[
                  vehicleIndex
                ] || {};
              newVehicle = { ...oldVehicle, quantity: target.value };
              newFleetSuppliers[fleetSupplierIndex].selectedVehicles.splice(
                vehicleIndex,
                1,
                newVehicle
              );
            }

            setFieldValue('fleetSuppliers', newFleetSuppliers);
          }}
        />
      ),
    },
  ];

  const handlePriceInputChange = (values, data, target, vehicles) => {
    const { name, value } = target;

    const newFleetSuppliers = [...values.fleetSuppliers];
    const newVehicle = { ...data };
    const newPrices = { ...data.supplierPrices };

    newPrices[name] = value;
    newVehicle.supplierPrices = newPrices;

    const fleetSupplierIndex = getIndexBy(
      values.fleetSuppliers,
      'id:',
      fleetSupplierId
    );
    const vehicleIndex = getIndexBy(vehicles, 'id:', data.id);

    newFleetSuppliers[fleetSupplierIndex].selectedVehicles.splice(
      vehicleIndex,
      1,
      newVehicle
    );

    setFieldValue('fleetSuppliers', newFleetSuppliers);
  };

  const getPriceFieldValue = (data, name) => data.supplierPrices[name] || 0;

  const fleetSupplier = _.find(values.fleetSuppliers, { id: fleetSupplierId });
  const vehicles = fleetSupplier ? fleetSupplier.selectedVehicles : [];

  return (
    <ListModal
      disableActions
      title="Select vehicles needed"
      searchPlaceholder="Search for a vehicle"
      // customHandleDelete={isUpdate ? customHandleDelete : null}
      query={GetAllVehiclesBySupplier}
      dataSelector={(data) => data?.vehiclesConnection?.values || []}
      queryOptions={{ fetchPolicy: 'no-cache', variables: { filterKey: "", id: fleetSupplierId } }}
      mainAction={{
        label: 'Done',
        onClick: () => {
        },
      }}
      error={error}
      incomingItems={
        values.fleetSuppliers[fleetSupplierId] &&
        values.fleetSuppliers[fleetSupplierId].selectedVehicles.map(
          (vehicle) => vehicle.id
        )
      }
      columns={selectionColumns}
      customResults={
        <VehiclePriceInputs
          values={values}
          setFieldValue={setFieldValue}
          vehicles={vehicles}
          handleChange={handlePriceInputChange}
          getValue={getPriceFieldValue}
        />
      }
      onModalClick={(toggleModal) => (
        <SelectActionButton onClick={toggleModal}>
          Select vehicles this supplier will provide
        </SelectActionButton>
      )}
    />
  );
};

export default withRouter(Container);
