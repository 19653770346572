import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DynamicList from 'components_v2/List/DynamicList';
import React ,{useState} from 'react';
import { withRouter } from 'react-router-dom';
import { mobileViewBreakpoint } from '../../App.helper';
import { getActions, getColumns, getMobileMenuItems, getHamburgerMenuItems } from './ListOptions';
import {getSortedProjectList} from './helper'
const ProjectList = ({
  history,
  events,
  title,
  onProjectRowClick,
  loading,
  selectedTab,
  onArchiveClick,
  onRestoreClick,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));
 // const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
 // let finalData = getFilteredProjectList({ projectList: events, filterKey });
  let finalData = getSortedProjectList({ projectList: events, sort });

  return (
    <DynamicList
    // filterActions={[
    //   {
    //     type: "text",
    //     onChange: (val) => {
    //       setFilterKey(val)
    //     },
    //     label: "Search",
    //   },
    // ]}
    sortActions={{
      currentSort: sort,
      onChangeSort: setSort
    }}
      isMobileView={isMobileView}
      showHeader
      data={finalData}
      title={title}
      noResultsText={'No projects'}
      enableFreezeHeader
      headerFreezeLeftContent={title}
      numRows={finalData.length}
      hamburgerMenuItems={getHamburgerMenuItems({ history })}
      getMobileMenuItems={(row) =>
        getMobileMenuItems({
          onProjectRowClick,
          history,
          selectedTab,
          onArchiveClick,
          onRestoreClick,
          row,
        })
      }
      columns={getColumns(isMobileView)}
      actions={getActions(
        onProjectRowClick,
        history,
        selectedTab,
        onArchiveClick,
        onRestoreClick
      )}
      loading={loading}
    />
  );
};

export default withRouter(ProjectList);
