import React from 'react';
import { withRouter } from 'react-router-dom';

import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import ListModal from 'components_v2/ListModal/ListModal';
import GetAllParentLocations from 'gql/StoredLocations/GetAllParentLocations';
import { createLocationWithState } from 'utils/routerHelpers';
import withFormState from "containers/Wrappers/withFormState";

const columns = [
  {
    field: 'name',
    headerName: 'Location name',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'type',
    headerName: 'Location type',
    width: 3,
    titleWithText: true,
  },

  {
    field: 'address',
    headerName: 'Address',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'contactPhone',
    headerName: 'Phone number',
    width: 3,
    titleWithText: true,
  },
];

const LocationsSelector = ({
  onDone,
  values,
  setFieldValue,
  history,
  location,
  formStep,
  error,
  setFormState,
}) => {
  const customHandleDelete = (setSelectedItems) => (row) => {
    const newLocationInput = values.locationInput.filter(
      (id) => id !== row.id
    );

    setFieldValue('locationInput', newLocationInput);
    setSelectedItems(newLocationInput);
  };

  const handleNewLocationClick = () => {
    const { pathname, state } = createLocationWithState(
      `/location/new`,
      location,
      values,
      formStep,
      true,
      "locationInput",
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } })
  };

  return (
    <ListModal
      title="Select locations"
      searchPlaceholder="Search for a location"
      query={GetAllParentLocations}
      dataSelector={(data) => data?.storedlocations || []}
      queryOptions={{ fetchPolicy: 'no-cache' }}
      mainAction={{
        label: 'Done',
        onClick: onDone,
      }}
      secondaryAction={{
        label: 'Create a new location',
        onClick: handleNewLocationClick,
      }}
      error={error}
      customHandleDelete={customHandleDelete}
      incomingItems={values.locationInput}
      columns={columns}
      renderSelectionColumns={columns}
      onModalClick={(toggleModal) => (
        <SelectActionButton onClick={toggleModal}>
          Select Locations
        </SelectActionButton>
      )}
    />
  );
};

export default withFormState(withRouter(LocationsSelector));
