import React from 'react';
import Box from '@material-ui/core/Box';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Typography from 'components_v2/Typography/Typography';
import Accordion from 'components_v2/Accordion/Accordion';
import theme from 'theme';
import useStyles from './FormSection.style';

const FormSection = ({ children, title, defaultExpanded, isNoAccordion, height }) => {
  const classes = useStyles();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down('md')
  );
  const typographyVariant =
    isMobileView ? 'bodyLargeRegular' : 'h4Bold';
  return (
    <>
      {(isMobileView && !isNoAccordion && (<Accordion
        defaultExpanded={defaultExpanded}
        noPadding
        summaryComponent={
          title && (
            <Typography customVariant={typographyVariant}>{title}</Typography>
          )
        }
      >
        <Box className={classes.content}>{children}</Box>
      </Accordion>
      )) || (<div className={classes.container}>
        {title && (
          <Typography customVariant={typographyVariant}>{title}</Typography>
        )}
        <Box className={classes.content} style={height && {height: height, overflowY: "auto"}}>{children}</Box>
      </div>)}

    </>
  )
};

export default FormSection;
