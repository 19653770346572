import gql from "graphql-tag";

export default gql`
  mutation CreateVehiclemaintenancetracking(
    $data: VehiclemaintenancetrackingInput
  ) {
    createVehiclemaintenancetracking(input: { data: $data }) {
      vehiclemaintenancetracking {
        id
      }
    }
  }
`;
