import gql from "graphql-tag";

export default gql`
  query GetAllStoredLocation($id: ID!) {
    storedlocations(where: { id: $id }) {
      id
      identifier
      name
      type
      address
      lat
      long
      contactEmail
      contactPhone
      contactFullName
      notes
    }
  }
`;
