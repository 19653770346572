import _ from 'lodash';
import moment from 'moment';
import AlertTag from 'components_v2/AlertTag/AlertTag';
import React from 'react';
import { deepCopy } from '../../utils/helpers';

export const vehicleOptions = [
  { value: 'fueling', name: 'Fueling' },
  { value: 'damage', name: 'Damage' },
  { value: 'cleaning', name: 'Cleaning' },
  { value: 'maintenance', name: 'Maintenance' },
];

export const driverOptions = [
  { value: 'health exam', name: 'Health exam' },
  { value: 'fine', name: 'Fine' },
];

export const getType = (typeValue, trackDriver) => {
  const foundType = _.find((trackDriver && driverOptions) || vehicleOptions, {
    value: typeValue,
  });
  if (foundType) {
    return foundType;
  }
  return typeValue;
};

export const parseData = (data, trackDriver) => {
  if (
    data &&
    ((trackDriver && data.driverongoingtrackings) ||
      data.vehiclemaintenancetrackings)
  ) {
    const currentData =
      (trackDriver && deepCopy(data.driverongoingtrackings)) ||
      deepCopy(data.vehiclemaintenancetrackings);

    const localCurrentData = _.map(currentData, (record) => {
      const localRecord = deepCopy(record);
      localRecord.type = getType(localRecord.type, trackDriver);
      return localRecord
    });

    return _.orderBy(localCurrentData, ['dateOfService', 'id:'], ['desc', 'desc']);
  }
  return [];
};

export const getAlertTag = (row, data) => {
  const foundRow = _.find(data, { type: row.type });

  if (
    foundRow &&
    (foundRow.type?.value === 'cleaning' ||
      foundRow.type?.value === 'maintenance')
  ) {
    const latestDate = moment(foundRow.dateOfService);
    const currentDate = moment();

    const dateDifference = currentDate.diff(latestDate, 'days');

    if (
      foundRow.id === row.id &&
      foundRow.type.value === 'cleaning' &&
      dateDifference > 7
    ) {
      return (
        <b>
          <AlertTag label="Cleaning due" type="high" />
        </b>
      );
    }
    if (
      foundRow.id === row.id &&
      foundRow.type.value === 'maintenance' &&
      dateDifference > 30
    ) {
      return (
        <b>
          <AlertTag label="Maintenance due" type="high" />
        </b>
      );
    }
  }
  return '';
};
