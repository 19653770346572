
import React from 'react';
import DynamicList from 'components_v2/List/DynamicList';
import PropTypes from 'prop-types';
import { getColumns } from './ListOptions';
import { withRouter } from 'react-router-dom';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from '../../../../App.helper';
import { getMobileMenuItems } from "./ListOptions"
const ProductsComponent = ({ data, loading, createProducts, updateProducts, refetch, projectId, setFilterKey, setSort, sort }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));


  return (
    <>
      <DynamicList
        filterActions={[
          {
            type: "text",
            onChange: (val) => {
              setFilterKey(val)
            },
            label: "Search",
          },

      ]}
      sortActions={{
        currentSort: sort,
        onChangeSort: setSort
      }}

        isMobileView={isMobileView}
        showHeader
        loading={loading}
        data={data}
        noResultsText="No products"
        title="Products"
        numRows={data.length}
        columns={getColumns(isMobileView)}
        getMobileMenuItems={(row) => getMobileMenuItems({ row })}
        actions={{
          edit: {
            onSave: async ({ id, values }) => {
              await updateProducts({
                variables: {
                  id: { id },
                  data: { ...values, type: values?.type?.value },
                },
              });
              await refetch();
            },
          },
          create: {
            onSave: async ({ values }) => {
              await createProducts({
                variables: {
                  data: {
                    ...values,
                    type: values?.type?.value,
                    event: projectId,
                  },
                },
              });

              await refetch();
            },
          },
        }}
      />
    </>
  );
}
ProductsComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};
export default withRouter(ProductsComponent);
