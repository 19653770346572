import gql from 'graphql-tag';

export default gql`
  query GetAllStoredLocations($id: ID) {
    storedlocations(where: { event: $id }) {
      id
      identifier
      name
      type
      address
      lat
      long
    }
  }
`;
