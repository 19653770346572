import gql from "graphql-tag";

export default gql`
  query GetVehicleServiceById($id: ID!) {
    vehiclemaintenancetracking(id: $id) {
      id
      description
      dateOfService
      documents
      cost
      vehicletrackingtype {
        id
        name
      }
      vehicle {
        id
      }
    }
  }
`;
