import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import GetAllByVehicle from './Gql/GetAllByVehicle';
import GetAllByDriver from './Gql/GetAllByDriver';
import ServiceTrackingComponent from './ServiceTracking.Component';
import { parseData } from './ServiceTrackingHelper';


const Container = ({ id, trackDriver }) => {
  const { data, loading, refetch } = useQuery(
    trackDriver ? GetAllByDriver : GetAllByVehicle,
    {
      variables: { id },
      fetchPolicy: 'no-cache',
    }
  );

  const parsedData = parseData(data, trackDriver);

  return (
    <ServiceTrackingComponent
      loading={loading}
      trackingId={id}
      refetch={refetch}
      trackDriver={trackDriver}
      data={parsedData}
    // data={
    //   (data &&
    //     (trackDriver
    //       ? data.driverongoingtrackings
    //       : data.vehiclemaintenancetrackings)) ||
    //   []
    // }
    />
  );
};

export default Container;
