export const defaultLocationOptionsData = [
  {
    lat: -25.7851672,
    long: 28.2778263,
    name: 'Motus Nissan Menlyn',
    address: 'Lois Avenue, Menlyn, Pretoria, South Africa',
  },
  {
    lat: -26.1246619,
    long: 28.22988,
    name: 'Motus Ford Kempton Park',
    address: 'Wellington Street, Rhodesfield, Kempton Park, South Africa',
  },

  {
    lat: -26.0806785,
    long: 28.0258495,
    name: 'Motus Toyota Bryanston',
    address: 'Arklow Road, Bryanston, Sandton, South Africa',
  },

  {
    lat: -26.2375933,
    long: 28.16186,
    name: 'Motus Ford Germiston',
    address: 'Dakota Crescent, Airport Park, Germiston, South Africa',
  },
  {
    lat: -33.9168914,
    long: 18.4771166,
    name: 'Motus Ford Paarden Eiland',
    address: 'Paarden Eiland Road, Paarden Eiland, Cape Town, South Africa',
  },
];
