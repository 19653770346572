import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';
import InputLabel from '../InputLabel/InputLabel';

const StyledRadio = withStyles({
  root: {
    color: '#4001C5',
    '&$checked': {
      color: '#4001C5',
    },
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.1)',
    },
  },
  checked: {},
  disabled: {},
})((props) => <Radio color="default" {...props} />);

const RadioGroupInput = ({
  label,
  options,
  name,
  value,
  onChange,
  addOnChangeToRadioButton,
  isDisabled,
}) => {
  return (
    <>
      <InputLabel label={label} />
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
      >
        {options.map((option, index) => (
          <FormControlLabel
            style={{ marginRight: !option.label && '0' }}
            key={index}
            value={option.value}
            control={
              <StyledRadio
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
                disabled={isDisabled}
                onClick={addOnChangeToRadioButton ? onChange : undefined}
              />
            }
            label={option.label}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default RadioGroupInput;
