import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  searchInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "90px",
    },
    "& .MuiInputBase-input": {
      height: "3px",
    },
  },
}));

export const useCustomStyles = makeStyles(() => ({
  searchWrapper: {
    marginBottom: 20,
    "& > .input-wrapper-overrider": {
      marginBottom: "0",
      borderRadius: 20,
      overflow: "hidden",
      background: "#fff",
    },
  },
}));
