import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import Tooltip from 'components_v2/Tooltip/Tooltip';
import ActionPaddingWrapper from "./ActionPaddingWrapper";
import CustomAction from './CustomAction';
import Checkbox from 'components_v2/Inputs/CheckboxInput/CheckboxInput';
import RadioGroup from 'components_v2/Inputs/RadioGroupInput/RadioGroupInput';
import EditAction from "./EditAction";
import DeleteAction from './DeleteAction';
import DropdownAction from './DropdownAction';
import PrimaryAction from './PrimaryAction';
import {
  ArrowsChevronDown,
  ArrowsChevronUp,
} from '../Icons/Icons';
import { eventStopPropagation } from '../../App.helper';

const Actions = (props) => {
  const {
    actions,
    customActions,
    detailPanel,
    disableSelection,
    editMode,
    getHamburgerMenuItems,
    hamburgerMenuItems,
    idSuffix,
    loading,
    moduleTable,
    newMode,
    newRow,
    open,
    row,
    selectedItems,
    setDynamicListState,
    setNewMode,
    setOverlayOpen,
    singleSelection,
    values
  } = props;

  return (
    <>
      <PrimaryAction
        actions={actions}
        row={row}
        moduleTable={moduleTable}
        setOverlayOpen={setOverlayOpen}
      />
      {customActions && (
        <>
          {customActions.map((action, index) => (
            <CustomAction row={row} key={index} action={action} />
          ))}
        </>
      )}

      <EditAction
        actions={actions}
        row={row}
        setNewMode={setNewMode}
        idSuffix={idSuffix}
        editMode={editMode}
        loading={loading}
        values={values}
        setDynamicListState={setDynamicListState}
      />
      <DeleteAction
        actions={actions}
        row={row}
        moduleTable={moduleTable}
        newMode={newMode}
        newRow={newRow}
        setDynamicListState={setDynamicListState}
      />
      <DropdownAction
        hamburgerMenuItems={hamburgerMenuItems}
        getHamburgerMenuItems={getHamburgerMenuItems}
        row={row}
        moduleTable={moduleTable}
      />
      {actions &&
        actions.checkbox &&
        actions.checkbox.onClick &&
        !singleSelection &&
        !disableSelection && (
          <span style={{
            // marginRight: -16
          }}>
            <Checkbox
              name="isProject"
              isDisabled={actions.checkbox.disabled(
                row,
                selectedItems
              )}
              onChange={(chg) => {
                eventStopPropagation({ event: chg })
                actions.checkbox.onClick(row);
              }}
              value={actions.checkbox.value(row)}
            />
          </span>
        )}
      {actions &&
        actions.checkbox &&
        actions.checkbox.onClick &&
        singleSelection &&
        !disableSelection && (
          <span
            style={{
              display: 'block',
              // width: 60,
              // marginRight: -16,
              // position: moduleTable && 'absolute',
              top: moduleTable && '0',
            }}
          >
            <RadioGroup
              noMargin
              addOnChangeToRadioButton
              name="isProject"
              onChange={() => {
                //chg.stopPropagation();
                actions.checkbox.onClick(row);
              }}
              value={actions.checkbox.value(row)}
              options={[{ value: true }]}
            />
          </span>
        )}
      {detailPanel && (
        <ActionPaddingWrapper moduleTable={moduleTable}>
          <Tooltip
            title={(open && 'Collapse') || 'Expand'}
            placement="bottom"
          >
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                if (detailPanel && detailPanel(row)) {
                  setDynamicListState({ open: !open });
                } else if (actions && actions.rowClick) {
                  actions.rowClick(row);
                }
              }}
            >
              {!open && <ArrowsChevronDown fontSize="small" />}
              {open && <ArrowsChevronUp fontSize="small" />}
            </IconButton>
          </Tooltip>
        </ActionPaddingWrapper>
      )}
    </>
  );
};

export default Actions;