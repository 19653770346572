import gql from 'graphql-tag';

export default gql`
  query GetAllGroupedAlertsByEvent($id: ID, $status: String, $start: String, $end: String) {
    alertsConnection(where: { event: $id, status: $status, passengerschedule: { start_gte: $start, start_lte: $end } }) {
      aggregate {
        count
      }
    }
  }
`;
