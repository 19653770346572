import React, { useState } from "react";
import GetAllByEvent from "gql/VehicleClasses/GetAllByEvent";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import Delete from "gql/VehicleClasses/DeleteVehicleClasses";
import { NetworkStatus } from "@apollo/client";
import FleetClassSelectorComponent from "./FleetClassSelector.Component";
// import { mockData } from './VehicleClasses.mock';

const CreateVehicleclass = gql`
  mutation CreateVehicleclass($data: VehicleclassInput) {
    createVehicleclass(input: { data: $data }) {
      vehicleclass {
        id
      }
    }
  }
`;

const UpdateVehicleclass = gql`
  mutation UpdateVehicleclass($id: InputID!, $data: editVehicleclassInput) {
    updateVehicleclass(input: { where: $id, data: $data }) {
      vehicleclass {
        id
      }
    }
  }
`;

const FleetClassSelector = ({
  projectId,
  isHelpTutorial,
  values,
  setFieldValue,
  onDone
}) => {
  const { data, loading, refetch, networkStatus } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: "no-cache",
    skip: isHelpTutorial,
    notifyOnNetworkStatusChange: true
  });

  if (isHelpTutorial) {
    // data = mockData;
  }

  const [createVehicleClass] = useMutation(CreateVehicleclass);

  const [updateVehicleClass] = useMutation(UpdateVehicleclass);

  // if (data && data.vehicleclasses) data.vehicleclasses[0].icon = 'Dispatch';
  const [
    deleteConfirmationModalData,
    setDeleteConfirmationModalData
  ] = useState(null);

  const [deleteVehicleClasses] = useMutation(Delete);

  const deleteVehicleClassesRecord = async () => {
    const recordToDelete = deleteConfirmationModalData.recordId;

    setDeleteConfirmationModalData(null);

    await deleteVehicleClasses({
      variables: {
        id: recordToDelete
      }
    });

    await refetch();
  };

  return (
    <FleetClassSelectorComponent
      data={data?.vehicleclasses}
      loading={loading || networkStatus === NetworkStatus.refetch}
      createVehicleClass={createVehicleClass}
      updateVehicleClass={updateVehicleClass}
      refetch={refetch}
      projectId={projectId}
      deleteConfirmationModalData={deleteConfirmationModalData}
      setDeleteConfirmationModalData={setDeleteConfirmationModalData}
      deleteVehicleClassesRecord={deleteVehicleClassesRecord}
      values={values}
      setFieldValue={setFieldValue}
      onDone={onDone}
    />
  );
};
export default withRouter(FleetClassSelector);
