import { makeStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import React from 'react';

const useStyles = makeStyles({
  root: {
    padding: (props) => (props.noPadding ? 0 : '24px 24px 32px 24px'),
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
  },
});

const AccordionDetails = ({ children, noPadding }) => {
  const classes = useStyles({ noPadding });

  return (
    <MuiAccordionDetails className={classes.root}>
      {children}
    </MuiAccordionDetails>
  );
};

export default AccordionDetails;
