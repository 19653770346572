import React from "react";
import Swipeout from "rc-swipeout";
import _ from "lodash"
import "rc-swipeout/assets/index.css";
import { eventStopPropagation } from "../../App.helper";

const SwipeWrapper = ({ isMobileView, mobileMenuItems, row, editMode, isDetailedPanelOpen, children, isAutoClose }) => {

  return (

    (isMobileView && !editMode && !isDetailedPanelOpen && (
      <Swipeout
        onTouchMove={(event) => eventStopPropagation({ event })}
        left={_.map(mobileMenuItems?.left, (item) => {
          return { ...item, onPress: () => item.onClick(row) };
        })}
        right={_.map(mobileMenuItems?.right, (item) => {

          return { ...item, onPress: () => item.onClick(row) };
        })}
        autoClose={isAutoClose}
      // onOpen={() => {}}
      // onClose={() => {}}
      >
        {children}
      </Swipeout>
    )) || <>{children}</>

  );
};

export default SwipeWrapper;
