import gql from 'graphql-tag';

export default gql`
  mutation CreateAlertcomment($data: AlertcommentInput) {
    createAlertcomment(input: { data: $data }) {
      alertcomment {
        id
      }
    }
  }
`;
