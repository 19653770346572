import React from 'react';

import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import countries from 'utils/countries';

const countryToFlag = (isoCode) =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;

const TimezoneSelect = (props) => {
  const options = countries.map((country) => ({
    value: country.code,
    name: country.label,
    coordinates: country.coordinates,
  }));

  const renderOption = (option) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{countryToFlag(option.value)}</span>
      {` `}
      <div style={{ marginLeft: '8px' }}>
        {option.name} ({option.value})
      </div>
    </div>
  );

  return (
    <SelectInput {...props} renderOption={renderOption} options={options} />
  );
};

export default TimezoneSelect;
