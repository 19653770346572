import gql from "graphql-tag";

const DELETE = gql`
  mutation($id: ID!) {
    deleteRidestatus(input: { where: { id: $id } }) {
      ridestatus {
        id
      }
    }
  }
`;

export default DELETE;
