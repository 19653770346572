import { makeStyles } from '@material-ui/core/styles';

export const useCustomStyles = makeStyles(() => ({
  inputWrapper: {
    marginBottom: '16px',
  },
}));
export const useStyles = makeStyles(() => ({
    
    root: {
      outline: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
      },
      '&$focused > .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #4001C5',
      },
      '&:hover > .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #4001C5',
      },
      '& > input': {
        color: '#3F3B3B',
        height: '3px',
      },
      '& > .MuiInputAdornment-root > .MuiIconButton-root': {
        color: 'black',
      },
      '&$disabled > input': {
        padding: '18.5px 14px',
        color: '#B9B9B9',
        'z-index': 1,
      },
      '&$disabled > .MuiInputAdornment-root > .MuiIconButton-root': {
        color: '#B9B9B9',
        'z-index': 1,
      },
      '&$disabled > .MuiOutlinedInput-notchedOutline': {
        background: '#EAEAEA',
        border: '1px solid #EAEAEA',
      },
      '&$disabled:hover > .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #EAEAEA',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C50202',
      },
    },
    focused: {},
    disabled: {},
  }));