import React from 'react';

import ActionPaddingWrapper from "./ActionPaddingWrapper";
import Button from 'components_v2/Button/Button';
import { eventStopPropagation } from '../../App.helper';



const PrimaryAction = (props) => {
  const {
    actions,
    row,
    moduleTable,
    setOverlayOpen
  } = props;

  let label;
  let disabled = false;
  let hidden = false;

  if (
    actions?.primary?.render &&
    typeof actions?.primary?.render === 'function'
  )
    return (
      <ActionPaddingWrapper moduleTable={moduleTable}>
        {actions.primary.render(row)}
      </ActionPaddingWrapper>
    );

  if (
    actions &&
    actions.primary &&
    actions?.primary?.label &&
    typeof actions.primary.label === 'function'
  ) {
    label = actions.primary.label(row);
  }

  if (
    actions &&
    actions.primary &&
    actions.primary.disabled &&
    typeof actions.primary.disabled === 'function'
  ) {
    disabled = actions.primary.disabled(row);
  }

  if (
    actions &&
    actions.primary &&
    actions.primary.hidden &&
    typeof actions.primary.hidden === 'function'
  ) {
    hidden = actions.primary.hidden(row);
  }

  return (
    <>
      {actions?.primary?.onClick && !hidden && (
        <ActionPaddingWrapper moduleTable={moduleTable}>
          <Button
            onClick={(e) => {
              eventStopPropagation({ event: e })
              actions.primary.onClick(row, setOverlayOpen);
            }}
            disabled={disabled}
            variant="contained"
            color="primary"
          >
            {label}
          </Button>
        </ActionPaddingWrapper>
      )}
    </>
  );
};

export default PrimaryAction;