import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import MomentUtils from '@date-io/moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import clsx from 'clsx';
import { Busy } from '../../Icons/Icons';
import InputLabel from '../InputLabel/InputLabel';
import InputError from '../InputError/InputError';
import { useStyles, useCustomStyles } from './TimepickerInput.styles';
import './TimepickerInput.css';

const TimepickerInput = ({
  name,
  value,
  onChange,
  label,
  placeholder,
  error,
  noMargin,
  labelFunc
}) => {
  const Icon = Busy;
  const variant = 'outlined';
  const customClasses = useCustomStyles();
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [customClasses.inputWrapper]: !noMargin,
      })}
    >
      <InputLabel label={label} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          name={name}
          ampm={false}
          inputVariant={variant}
          variant="inline"
          fullWidth
          value={value?.isValid() ? value : null}
          placeholder={placeholder}
          labelFunc={labelFunc}
          onChange={
            // TODO: NEED TO REVISIT
            (event) =>
              onChange({
                persist: () => {},
                target: {
                  type: 'change',
                  name,
                  value: moment(event),
                },
              })
          }
          PopoverProps={{
            transformOrigin: { vertical: 'top', horizontal: 'center' },
          }}
          InputProps={{
            classes,
            disableUnderline: true,
            endAdornment: Icon && (
              <InputAdornment position="end">
                <Icon />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
      <InputError error={error} />
    </div>
  );
};

export default TimepickerInput;
