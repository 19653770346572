import gql from 'graphql-tag';

export default gql`
  mutation UpdateModule($id: InputID!, $data: editModuleInput) {
    updateModule(input: { where: $id, data: $data }) {
      module {
        id
      }
    }
  }
`;
