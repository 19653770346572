import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Notifications } from "../Icons/Icons";
import classNames from "classnames";
import CustomizedBadges from "../Badge/Badge";
import { useStyles } from "./AlertsTool.styles";

const AlertsTool = ({ notificationsCount, onClick, currentTool }) => {
  const classes = useStyles();

  return (
    <IconButton
      id="top-tools-alert-btn"
      className={classNames(classes.iconButton, {
        active: currentTool === "alerts",
      })}
      disableRipple
      onClick={onClick}
    >
      <CustomizedBadges
        position="top"
        value={notificationsCount}
        children={<Notifications />}
      />
      <span
        className={classNames(classes.badgeButtonText, {
          active: currentTool === "alerts",
        })}
      >
        Alerts
      </span>
    </IconButton>
  );
};

export default AlertsTool;
