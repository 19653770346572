import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Chat,
  Channel,
  ChannelList,
  ChannelHeader,
  MessageList,
  MessageInput,
} from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import auth from '../../utils/auth';
import MessageCard from './MessageCard';
import './Messages.css';
import MessagesSelector from './MessageSelector';
import { mobileViewBreakpoint } from '../../App.helper';

const useStyles = makeStyles(({ breakpoints }) => ({
  messages: {
    background: '#FFFFFF',
    [breakpoints.down(mobileViewBreakpoint)]: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },

    '& .MuiList-padding': {
      padding: 0,
    },

    '& .str-chat': {
      width: '100%',
      [breakpoints.down(mobileViewBreakpoint)]: {
        position: 'relative',
        left: 0,
      },

      '& .str-chat__channel-list-team__main': {
        width: '100%',

        '& .str-chat__channel-list-team__header': {
          display: 'none',
        },
      },
    },
  },
}));

const Messages = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    type: null,
  });
  const anchor = 'right';
  const [currentChannelDetails, setCurrentChannelDetails] = useState(null);

  const chatClient = new StreamChat('rzrazng85922');
  const userToken = localStorage.getItem('streamToken');

  if (!chatClient.userID) {
    chatClient.setUser(
      {
        id: auth.get('userInfo').id,
        status: 'Driving towards Palm Jumeirah',
        name: `${auth.get('userInfo').firstName} ${auth.get('userInfo').lastName
          }`,
        image: `https://getstream.io/random_png/?id=${auth.get('userInfo').id
          }&name=${auth.get('userInfo').firstName} ${auth.get('userInfo').lastName
          }`,
      },
      userToken
    );

    // const channel = chatClient.channel('messaging', 'general', {
    //   name: 'General channel - portal wide',
    // });
    // const state = channel.watch(); // adds existing user to chat room
  }

  const filters = { type: 'messaging' };
  const sort = { last_message_at: -1 };

  const toggleDrawer = (type, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open, type });
  };

  const handleOnChannelClick = (event, channelDetails) => {
    setCurrentChannelDetails(channelDetails);
    toggleDrawer('messages-list', true)(event);
  };

  const renderChannelListPreviewer = (props) => {
    const { channel } = props;

    return (
      <MessageCard
        cardDetails={channel}
        onChannelClick={handleOnChannelClick}
      />
    );
  };

  const handleSelectValues = async (event) => {
    const selectedIds = _.map(event, _.property('id:'));
    const selectedNames = _.map(event, _.property('name'));

    const conversation = chatClient.channel('messaging', {
      name: _.join(
        [
          ...selectedNames,
          `${auth.get('userInfo').firstName} ${auth.get('userInfo').lastName}`,
        ],
        ', '
      ),
      members: [...selectedIds, auth.get('userInfo').id],
    });

    handleOnChannelClick(null, conversation);
  };

  return (
    <div className={classes.messages}>
      <MessagesSelector onSelectValues={handleSelectValues} />

      <Chat client={chatClient} theme="messaging light">
        <ChannelList
          filters={filters}
          sort={sort}
          Preview={renderChannelListPreviewer}
        />
      </Chat>
      {/* <List>
        {messages.map((message) => (
          <MessageCard cardDetails={message} />
        ))}
      </List> */}
      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        {state.type === 'messages-list' && (
          <div className="messages-list">
            <Chat client={chatClient} theme="messaging light">
              <Channel channel={currentChannelDetails}>
                <ChannelHeader />
                <MessageList />
                <MessageInput />
              </Channel>
            </Chat>
          </div>
        )}
      </SwipeableDrawer>
    </div>
  );
};

export default Messages;
