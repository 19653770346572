import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FiltersSelected from "./FiltersSelected/FiltersSelected";
import FilterButton from "./FilterButton/FilterButton";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../App.helper";
import { useDebounce } from "../../Hooks/use-debounce.hook";
const useStyles = makeStyles(() => ({
  verticalDivider: {
    height: "8px"
  }
}));

const Filters = ({
  filtersSelected,
  onDelete,
  onClearAll,
  initialFilterKey,
  filters,
  listConfig,
  onChange,
  isOnChangeAvailable,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const classes = useStyles();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  let debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm && searchTerm && debouncedSearchTerm.filterKey === searchTerm.filterKey) {
      onChange && isOnChangeAvailable ? onChange(debouncedSearchTerm.filterKey) : debouncedSearchTerm.filter.onChange(debouncedSearchTerm.filterKey)
    }
  }, [
    debouncedSearchTerm
  ]);

  useEffect(() => {
    if (initialFilterKey) {
      const initialFilter = { filter: filters[0], filterKey: initialFilterKey };
      setSearchTerm(initialFilter);
      debouncedSearchTerm = initialFilter;
    }
  }, [])


  return (
    <div
      style={{ flexDirection: isMobileView ? "row" : "", display: "flex" }}
    >
      <FilterButton onClick={() => setFiltersOpen(!filtersOpen)} />

      {filtersOpen && (
        <div
          style={{
            marginRight: "8px",
          }}
        >
          {listConfig ? <TextInput
            //isDisabled={column.isDisabled}
            placeholder={'Search'}
            value={searchTerm?.filterKey}
            //type={column.type == "number" ? "number" : undefined}
            noMargin
            onChange={
              event => {
                // filter.onChange(event.target.value);
                setSearchTerm({ filterKey: event.target.value })
              }
              //this.handleValueChange(column, row, event.target.value)
            }
          /> : filters?.map(filter => (
            <TextInput
              //isDisabled={column.isDisabled}
              placeholder={filter.label}
              value={searchTerm?.filterKey}
              //type={column.type == "number" ? "number" : undefined}
              noMargin
              onChange={
                event => {
                  // filter.onChange(event.target.value);
                  setSearchTerm({ filterKey: event.target.value, filter })
                }
                //this.handleValueChange(column, row, event.target.value)
              }
            />
          ))}
        </div>
      )}

      <div className={classes.verticalDivider} />
      {!isMobileView && <div
        style={{
          marginTop: filtersOpen && isMobileView ? "48px" : "0px",
          height: 40
        }}
      >
        <div>
          <FiltersSelected
            searchTerm={searchTerm}
            filtersSelected={filtersSelected}
            onDelete={onDelete}
            onClearAll={() => {
              setSearchTerm({ filterKey: '', filter: filters && filters[0] });
              if (onClearAll) onClearAll();
            }}
          />
        </div>
      </div>}

    </div>
  );
};

export default Filters;
