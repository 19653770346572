import * as React from 'react';
import { useState, useEffect } from 'react';


import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import PolylineOverlay from './PolylineOverlay';

export default function RouteHistory({ stops, onRouteData, setDuration }) {
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const fetchDirections = async () => {
      const coordinates = stops
        .map((point) => `${point[0]},${point[1]}`)
        .join(';');

      // coordinates = coordinates.slice(0, -1);

      const { data } = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=pk.eyJ1IjoiYmJyZXoxIiwiYSI6ImNqdG9xZGhwZjFrc3U0NGs2NjBkZmNybm4ifQ.i3qrq27FR2XlowOR92O3wA&steps=true&geometries=geojson`
      );

      if (data?.routes[0]) {
        onRouteData(data.routes[0]);
        setPoints(data.routes[0].geometry.coordinates);
        setDuration(data.routes[0].duration);
      }
    };

    fetchDirections();
  }, [JSON.stringify(stops)]);

  // if (loading) return <></>;

  // var points = data.locations.map(r => [r.long, r.lat]);

  return <PolylineOverlay points={points} lineWidth={5} />;
}
