export const mockData = {
  events: [
    {
      id: '5f270baecc54999de0a9f579',
      name: 'FIFA',
      start: '2020-06-18',
      end: '2020-07-09',
      inChargeName: '',
      inChargeEmail: '',
      inChargeMobile: '',
      client: '',
      clientPriceFormat: 'Overall',
      clientPriceMargin: 50,
      products: [
        {
          id: '5f9f469fd0f6302e104174f1',
          name: 'Champagne',
          price: 139,
          description: '',
          icon: 'Waterbottle',
          __typename: 'Product',
        },
      ],
      projectClient: {
        id: '5f7cd108b3b6417f602119f7',
        contactName: null,
        companyName: 'Palm Jumeirah',
        location: 'Palm, Dubai, UAE',
        officeType: null,
        address: null,
        phone: '+971509664774',
        email: null,
        lat: null,
        long: null,
        events: [
          {
            id: '5f270baecc54999de0a9f579',
            name: 'FIFA',
            __typename: 'Event',
          },
          {
            id: '5fc952890dd0334c75ab2fac',
            name: 'FIFA',
            __typename: 'Event',
          },
        ],
        __typename: 'Client',
      },
      country: 'United Arab Emirates',
      bookingEmailRecepients: ['blazbreznik+22@gmail.com'],
      logoImageUrl: '',
      locationInput: '["5fb8231bf8879d52b947ecd7"]',
      bookingEmailToPassenger: true,
      bookingEmailToCreator: false,
      fromEmail: 'MOTUS BEE',
      type: 'event',
      bookingPrefix: 'BB.',
      archived: false,
      currency: 'eur',
      chargeForNoShow: false,
      fullChargeTimeframe: 0,
      timeFrameBeforeBooking: 0,
      transferEnabled: true,
      sixHourEnabled: true,
      twelveHourEnabled: true,
      twentyFourHourEnabled: true,
      timezone: 'Asia/Dubai',
      fleet:
        '[{"value":"Sedan","name":"Sedan","id:":"Sedan","quantity":4},{"value":"SUV","name":"SUV","id:":"SUV","quantity":1}]',
      requiredStaff: '[]',
      fleetSuppliers:
        '[{"id:":"5f7e30b0dae257a330eceb29","name":"Awesome Limos","contactName":"Jack Michaelson","phone":"24324 94 34934 9349","email":"jmick@gmail.com","__typename":"Suppliers","selectedVehicles":[{"id:":"5f271bdb7442154fd8a8c13d","make":"Audi v2","model":"Car","vehicleClass":{"id:":"5f271b6e7442154fd8a8c139","name":"Sedan-Basic","__typename":"Vehicleclass"},"__typename":"Vehicle","quantity":1,"supplierPrices":{"supplierTransfer":"100"},"clientPrices":[]},{"id:":"5f2c6a7916f55c9d3ce20640","make":"Audi","model":"Car","vehicleClass":{"id:":"5f271b6e7442154fd8a8c139","name":"Sedan-Basic","__typename":"Vehicleclass"},"__typename":"Vehicle","quantity":1,"supplierPrices":[],"clientPrices":[]}]}]',
      backgroundAlertsEnabled: true,
      lateArrivalTimeframe: 15,
      defaultTransferTime: 2,
      availabilityAfterTransfer: 30,
      modules: [
        {
          id: '5f763aba0cf1e4d13032e436',
          name: 'Media 69',
          description: null,
          departments: [
            {
              id: '5f975edb69eeee18d55e498a',
              name: '24 Hours',
              __typename: 'Department',
            },
          ],
          __typename: 'Module',
        },
        {
          id: '5fb13fa7f9139a00170229b0',
          name: 'Ivan',
          description: null,
          departments: [],
          __typename: 'Module',
        },
      ],
      __typename: 'Event',
    },
  ],
  products: [
    {
      id: '5f9f469fd0f6302e104174f1',
      name: 'Champagne',
      description: '',
      price: 139,
      icon: 'Waterbottle',
      __typename: 'Product',
    },
  ],
};
