import gql from 'graphql-tag';

export default gql`
mutation SendDriverLinkConfirmation(
  $id: ID
) {
  sendDriverLink(id: $id) {
    event {
        id
      }
  }
}
`;