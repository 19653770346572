import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const StyledLinearProgress = withStyles({
  root: {
    borderRadius: "75px",
    backgroundColor: "rgb(0, 0, 0, 0.1)"
  },
  bar: {
    backgroundColor:  "#153C3F"
  }
})(LinearProgress);

export default function ProgressBarExport({percent}){
  return (
    <StyledLinearProgress variant="determinate" value={percent}></StyledLinearProgress>
  );
}