export const mockData = {
  users: [
    {
      id: "5f3d03744c2533001783b490",
      email: "jamesGames123@gmail.com",
      firstName: "James",
      lastName: "White",
      phone: null,
      confirmed: false,
      company: null,
      identifier: null,
      username: "1597834100461",
      notes: null,
      passengerPriority: "normal",
      arrivalFlightNumber: null,
      arrivalDate: null,
      departureFlightNumber: null,
      departureDate: null,
      role: {
        id: 1,
        name: "Passenger",
        __typename: "UsersPermissionsRole"
      },
      __typename: "UsersPermissionsUser"
    },

    {
      id: "5fa33ed9400450c30ee7c2c9",
      email: "russel@gmail.com",
      firstName: "Russel",
      lastName: "Wilson",
      phone: "+971234523424",
      confirmed: false,
      company: null,
      identifier: null,
      username: "inrusswetrust",
      notes: null,
      passengerPriority: "Normal",
      arrivalFlightNumber: null,
      arrivalDate: null,
      departureFlightNumber: null,
      departureDate: null,
      role: {
        id: 1,
        name: "Passenger",
        __typename: "UsersPermissionsRole"
      },
      __typename: "UsersPermissionsUser"
    },
    {
      id: "5fa3f31b42c8884b4ca09bd0",
      email: "jackeeyjack@gogog.co",
      firstName: "Jack",
      lastName: "Reeves",
      phone: null,
      confirmed: false,
      company: null,
      identifier: null,
      username: "jackjackson",
      notes: null,
      passengerPriority: "Normal",
      arrivalFlightNumber: null,
      arrivalDate: null,
      departureFlightNumber: null,
      departureDate: null,
      role: {
        id: 1,
        name: "Passenger",
        __typename: "UsersPermissionsRole"
      },
      __typename: "UsersPermissionsUser"
    }
  ]
};
