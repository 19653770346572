import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Suppliers/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddSupplierComponent from './AddSupplier.Component';

const AddSupplierContainer = ({ match }) => {
  const { supplierId } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: supplierId },
    skip: !supplierId || supplierId.toLowerCase() === 'new',
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return (
    <AddSupplierComponent
      data={data?.supplier}
      refetchSupplier={refetch}
    />
  );
};

AddSupplierContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ supplierId: PropTypes.string }),
  }),
};

export default withMainLayout(
  'AddSupplier',
  {},
  withRouter(AddSupplierContainer)
);
