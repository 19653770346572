import gql from "graphql-tag";

export default gql`
  query GetAllDriversByEvent($id: ID!) {
    users(where: { role: 4, event: $id }) {
      id
      email
      firstName
      lastName
      phone
      confirmed
      company
      identifier
      username
      notes
    }
  }
`;
