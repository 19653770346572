import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import Stepper from 'components_v2/Stepper/Stepper';
import useStyles from './PopoverFormFooter.style';

const PopoverFormFooter = ({
  currentStep,
  disableStepper,
  mainAction,
  numberOfSteps,
  secondaryAction,
}) => {
  const classes = useStyles();

  const saving = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/*
      <Save
        style={{ marginRight: 8, color: '#000', width: 'auto', height: 24 }}
      />
      <Typography customVariant="bodyLargeRegular">Saving...</Typography> */}
    </div>
  );

  const divider = <div className={classes.divider} />;

  const leftLayout = disableStepper ? null : (
    <Box className={classes.leftLayout}>
      <Stepper currentStep={currentStep} numberOfSteps={numberOfSteps} />
      {divider}
      {saving}
    </Box>
  );

  if (!mainAction && !secondaryAction) return null;
  return (
    <Box className={classes.container}>
      {leftLayout}
      <Box
        className={
          secondaryAction && mainAction
            ? classes.rightLayoutTwoActions
            : classes.rightLayoutSingleAction
        }
      >
        {secondaryAction}
        {mainAction}
      </Box>
    </Box>
  );
};

PopoverFormFooter.propTypes = {
  currentStep: PropTypes.number,
  disableStepper: PropTypes.bool,
  mainAction: PropTypes.element,
  numberOfSteps: PropTypes.number,
  secondaryAction: PropTypes.element,
};

export default PopoverFormFooter;
