import React from 'react';
import _ from 'lodash';
import Vehicle from 'components_v2/Vehicle/Vehicle';
import Tooltip from 'components_v2/Tooltip/Tooltip';
import AddRemove from 'components_v2/Inputs/AddRemove/AddRemove';
import { getIndexBy } from 'utils/arrayHelpers';
import { editActionButtonSelector } from '../../../../../App.helper';

const getValue = (fleet, id) => {
  const vehicle = _.find(fleet, { id: id });

  return vehicle ? vehicle.quantity : 1;
};

export const getMobileMenuItems = ({
  row,
  setDeleteConfirmationModalData,
}) => ({
  left: [
    {
      text: ' ',
      onClick: (row) => {
        setDeleteConfirmationModalData({
          recordName: row.name,
          recordId: row.id,
          confirmationType: 'delete',
          isNotDeleteAllowed: row.vehicles.length > 0,
          deleteNotAllowedReason:
            'This vehicle class cannot be deleted because it is being used by the vehicles.',
        });
      },
      className: 'mobile-actions-delete',
    },
  ],
  right: [
    {
      text: ' ',
      onClick: () => {
        const clickedButton = document.querySelector(
          `#${editActionButtonSelector}${row.id}`
        );
        if (clickedButton) clickedButton.click();
      },
      className: 'mobile-actions-edit',
    },
  ],
});

export const getColumns = (isMobileView, setFieldValue, values, isResults) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 2,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 3,
    options: [
      { value: 'Sedan', name: 'Sedan' },
      { value: 'SUV', name: 'SUV' },
      { value: 'Van', name: 'Van' },
      { value: 'Mini-Van', name: 'Mini-Van' },
      { value: 'Mini-Bus', name: 'Mini-Bus' },
      { value: 'Sprinter', name: 'Sprinter' },
      { value: 'Coaster Bus', name: 'Coaster Bus' },
      { value: 'Motor Coach', name: 'Motor Coach' },
      { value: 'Luggage Van', name: 'Luggage Van' },
      { value: 'ADA Van (Handicap)', name: 'ADA Van (Handicap)' },
    ],
    render: (data) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {' '}
        <Tooltip title={data.type}>
          <Vehicle type={data.type} customStyle={{ position: 'relative' }} />{' '}
        </Tooltip>
      </div>
    ),
  },
  {
    field: 'maxPassengers',
    headerName: isMobileView ? 'Max pax' : 'Max passengers',
    width: 2,
    type: 'number',
    render: (data) =>
      data.maxPassengers && (
        <>
          {data.maxPassengers} {isMobileView ? '' : 'passenger(s)'}
        </>
      ),
  },

  {
    field: 'maxLuggage',
    headerName: 'Max luggage',
    width: 2,
    type: 'number',
    hideMobile: true,
    render: (data) => data.maxLuggage && <>{data.maxLuggage} small piece(s)</>,
  },
  ...(isResults
    ? [
        {
          field: 'quantity',
          headerName: 'quantity',
          width: 2,
          render: (row) => (
            <AddRemove
              small
              noMarginBot
              value={getValue(values.fleet, row.id)}
              onChange={({ target }) => {
                const newFleet = [...values.fleet];

                const vehicleIndex = getIndexBy(values.fleet, 'id:', row.id);

                let newVehicle;

                if (!vehicleIndex && vehicleIndex !== 0) {
                  newVehicle = { id: row.id, quantity: target.value };
                  newFleet.push(newVehicle);
                } else {
                  const oldVehicle = values.fleet[vehicleIndex] || {};
                  newVehicle = { ...oldVehicle, quantity: target.value };
                  newFleet.splice(vehicleIndex, 1, newVehicle);
                }

                setFieldValue('fleet', newFleet);
              }}
            />
          ),
        },
      ]
    : []),
];
