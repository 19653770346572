import React, { useState } from "react";
import "react-activity-feed/dist/index.es.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { withChatContext } from "stream-chat-react";
import TopTools from "components_v2/TopTools/TopTools";
import useSound from "use-sound";
import useChat from "hooks/useChat";
import { UserAdd } from "../Icons/Icons";
import Toast from "../Toast/Toast";
import Navigation from "./Navigation/Navigation/Navigation";
import { mobileViewBreakpoint } from "../../App.helper";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%"
  },
  fullWidth: {
    width: "100%",
    height: "100vh",
    padding: "56px 0px 0px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px"
    }
  },
  content: {
    // height: "100vh",
    // overflowX: "hidden",
    flexGrow: 1,
    padding: "0 64px 0 140px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "16px",
      height: "calc(100vh - 62px)"
    }
  },
  contentNoPadding: {
    flexGrow: 1,
    padding: "0 0 0 90px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0 0 0 0"
    }
  },
  topRow: {
    margin: "0 0",
    display: "flex",
    justifyContent: "flex-end"
  },
  topRowNoPadding: {
    margin: "0 0",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 100px 0 90px"
  },
  drawerPaperOpenMobile: {
    right: "0",
    left: "auto",
    width: "280px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      zIndex: 1201
    }
  }
}));

function MainLayout({
  children,
  noPadding,
  enqueueSnackbar,
  closeSnackbar,
  isHelpTutorial
}) {
  const classes = useStyles();
  const [openedTool, setOpenedTool] = useState({
    tool: null,
    isOpen: false
  });

  const [play] = useSound(
    "https://proxy.notificationsounds.com/message-tones/juntos-607/download/file-sounds-1148-juntos.mp3"
  );

  useChat("alertCreate", alertMessage => {
    var alert = alertMessage.alerts[0];

    play();

    enqueueSnackbar(alert.title, {
      content: (key, message) => (
        <Toast
          id={key}
          key={key}
          message={message}
          icon={UserAdd}
          isNoIcon={false}
          description={
            <>
              {alert.description}
              <br /> <br />
              Driver comment:
              <br />
              {alert.comment ? (
                <>
                  <blockquote>{alert.comment}</blockquote>{" "}
                </>
              ) : (
                <blockquote>No comment was provided.</blockquote>
              )}
            </>
          }
          onOKClick={() => {}}
          onCancelClick={() => closeSnackbar(key)}
        />
      )
    });
  });

  const openRightSideBar = tool => {
    setOpenedTool({ tool, isOpen: true });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigation
        openRightSideBar={openRightSideBar}
        isHelpTutorial={isHelpTutorial}
      />

      <main className={noPadding ? classes.contentNoPadding : classes.content}>
        <div className={noPadding ? classes.topRowNoPadding : classes.topRow}>
          <TopTools
            isHelpTutorial={isHelpTutorial}
            messagesCount={0}
            notificationsCount={56}
            openedTool={openedTool}
          />
        </div>
        {children}
      </main>
    </div>
  );
}

export default withChatContext(withSnackbar(MainLayout));
