import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';
import React from 'react';

// WIP

const SkeletonRows = ({ numRows = 1 }) => {
  let rows = [];

  if (numRows < 1) return;

  const row = (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
      <Skeleton
        variant="rect"
        width="14%"
        height={100}
        style={{ borderRadius: 6, margin: '4px' }}
      />
    </Box>
  );

  for (let i = 1; i <= numRows; i += 1) {
    rows = [...rows, row];
  }

  return <>{rows}</>;
};

const SkeletonHeader = () => {
  let columns = [];

  const column = (
    <Box
      style={{
        width: '14%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '4px',
      }}
    >
      <Skeleton variant="text" width={150} height={40} />
    </Box>
  );

  for (let i = 1; i <= 7; i += 1) {
    columns = [...columns, column];
  }

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {columns}
    </Box>
  );
};

const WeekViewSkeleton = () => (
  <>
    <Skeleton
      variant="rect"
      width={200}
      height={28}
      style={{ borderRadius: 6, margin: '16px 0' }}
    />
    <Skeleton
      variant="rect"
      width={50}
      height={28}
      style={{ borderRadius: 6, margin: '16px 0' }}
    />
    <Skeleton
      variant="rect"
      width={200}
      height={28}
      style={{ borderRadius: 6, margin: '16px 0' }}
    />

    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 32,
        paddingTop: 24,
      }}
    >
      <SkeletonHeader />
      <SkeletonRows numRows={7} />
    </Box>
  </>
);

export default WeekViewSkeleton;
