/* eslint-disable react/display-name */
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import GetAllByUser from "gql/Events/GetAllByUser";
import auth from "utils/auth";
import CurrentProjectSelector from "components_v2/ProjectSelector/CurrentProjectsScroller";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import ScrollContainer from "react-indiana-drag-scroll";
import Typography from "components_v2/Typography/Typography";
import { mockData } from "../Views/Internal/HomeDashboard/HomeDashboard.mock";
import UpdateEvent from "gql/Events/Update";
import DeleteUserEvent from "gql/UserEvents/Delete";
import { useMutation } from "@apollo/react-hooks";
import { NetworkStatus } from "@apollo/client";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = () => ({});

const CurrentProjectsScrollerContainer = React.memo(({ isHelpTutorial }) => {
  const { id } = auth.get("userInfo");

  const [updateUserEvent, { loading: updateMutationLoading }] = useMutation(
    UpdateEvent
  );

  const [deleteUserEvent, { loading: deleteMutationLoading }] = useMutation(
    DeleteUserEvent
  );

  const { loading, data, refetch, networkStatus } = useQuery(GetAllByUser, {
    variables: { id: id },
    fetchPolicy: "no-cache",
    skip: isHelpTutorial,
    notifyOnNetworkStatusChange: true
  });

  if (
    loading ||
    (!data && !isHelpTutorial) ||
    updateMutationLoading ||
    networkStatus === NetworkStatus.refetch ||
    deleteMutationLoading
  )
    return (
      <>
        <Skeleton
          variant="text"
          width={200}
          height={50}
          style={{ marginTop: 10, marginBottom: 8 }}
        />
        <div style={{ display: "flex" }}>
          {Array.from(new Array(5)).map((item, index) => {
            return (
              <Skeleton
                key={index}
                variant="rect"
                width={"100%"}
                height={104}
                style={{ marginRight: 16 }}
              />
            );
          })}
        </div>
      </>
    );

  let eventData = [];
  if (isHelpTutorial) {
    eventData =
      mockData?.user?.userEvents.map(userEvent => ({
        ...userEvent.event
      })) || [];
  } else {
    eventData =
      data?.user?.userEvents.map(userEvent => ({
        ...userEvent.event,
        userEventId: userEvent.id
      })) || [];
  }
  eventData = eventData.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  );

  const selectedEvent = localStorage.getItem("selectedEvent");
  const count = eventData.filter(x => x.status !== "Archived").length;

  return (
    <>
      <Box my={2}>
        <Typography customVariant="h4Regular">
          <span>Your projects&nbsp;</span>
          <b>({count})</b>
        </Typography>
      </Box>
      <ScrollContainer
        horizontal
        style={{
          paddingTop: "30px",
          paddingBottom: "30px",
          paddingRight: "30px",

          position: "relative",
          top: "-30px"
        }}
      >
        <CurrentProjectSelector
          eventData={eventData}
          eventId={selectedEvent}
          id="current-projects-carousel"
          onLeaveProject={async eventId => {
            await deleteUserEvent({
              variables: {
                id: { id: eventId }
              }
            });

            await refetch();
          }}
          onArchiveProject={async eventId => {
            await updateUserEvent({
              variables: {
                id: { id: eventId },
                data: { status: "Archived" }
              },
              notifyOnNetworkStatusChange: true
            });

            await refetch();
          }}
        />
      </ScrollContainer>
    </>
  );
});
export default withStyles(styles)(CurrentProjectsScrollerContainer);
