import gql from "graphql-tag";

export default gql`
  query GetAllDrivers($id: ID) {
    users(where: { role: 4, event: $id }) {
      id
      email
      firstName
      lastName
      phone
      confirmed
      company
      identifier
      username
      notes
      passengerPriority
      role {
        id
        name
      }
      assignedVehicles {
        id
        notes
        created_at
        start
        stop
        wholeEvent
        vehicle {
          id
          type
          make
          model
          color
          plateNumber
          vehicleNumber
          vehicleClass {
            id
            name
            type
          }
        }
      }
    }
  }
`;
