import React from "react";
import StaticLayout from "../../Layout/StaticLayout";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

class ErrorPage extends React.Component {
  render() {
    return (
      <StaticLayout>
        <Typography>There seems to be a problem loading this page. Please try again or contact us.</Typography>
        <div style={{ margin: 20 }} />
        <a href="/" style={{ textDecoration: "none" }}>
          <Button variant="contained" color="primary">
            Continue
          </Button>
        </a>
      </StaticLayout>
    );
  }
}
export default ErrorPage;
