import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import Typography from "components_v2/Typography/Typography";
import AdjustOvertimeModal from './AdjustOvertimeModal/AdjustOvertimeModal'
import AdjustOvertimeConfirmation from "../../gql/DriverSchedules/Update";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})
const AdjustOvertime = ({ data, id }) => {

  const classes = useStyles();
  const [adjustOvertimeModalData, setAdjustOvertimeModalData] = useState(null);
  const [
    adjustOvertimeConfirmation,
  ] = useMutation(AdjustOvertimeConfirmation);

  const adjustOvertime = async (data) => {
    setAdjustOvertimeModalData(null);

    await adjustOvertimeConfirmation({
      variables: {
        ...data,
      },
    });
  };

  return (
    <>
      <AdjustOvertimeModal
        data={adjustOvertimeModalData}
        onClickCancel={() => {
          setAdjustOvertimeModalData(null);
        }}
        onClickSend={async (data) => {
          adjustOvertime(data);
        }}
      />
      <div
        className={classes.item}
        id={id}
        onClick={() => {
          setAdjustOvertimeModalData({
            record: data
          })
        }}>
        <Typography
          customVariant="bodySmallRegular"
        >
          Adjust overtime
      </Typography>
      </div>
    </>
  )
}
export default AdjustOvertime;