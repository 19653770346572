import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  vehicleQuantityContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    marginBottom: 40,
  },
  allVehicleQuantitiesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pricesContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  pricesItem: {
    width: '10%',
    minWidth: 70,
    padding: '0 4px',
    '&:not(:last-of-type)': {
      marginRight: '2.8%',
    },
    '& input': {
      padding: '18.5px 8px',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 4,
    },
    '& .input-wrapper-overrider': {
      marginBottom: 0,
    },
    '& .input-wrapper-overrider > div': {
      marginBottom: 0,
    },
  },
  moneyAdornment: {
    width: 16,
    height: 'auto',
    fill: '#3C3C3C',
  },
}));

export default useStyles;
