import React from 'react';
import CIB from '../../Buttons/CIB/CIB';
import { Add, Subtract } from '../../Icons/Icons';
import { NumberInput } from '../NumberInput/NumberInput';
import { useCustomStyles } from './AddRemove.styles';
import InputLabel from '../InputLabel/InputLabel';
import InputError from '../InputError/InputError';

const AddRemove = ({
  value,
  name,
  label,
  onChange,
  placeholder,
  isDisabled,
  error,
  percentage,
  small,
  noMarginBot
}) => {
  const customClasses = useCustomStyles({ percentage, small, noMarginBot });

  const handleChange = (newValue) => {
    onChange({
      persist: () => {},
      target: {
        type: 'change',
        name,
        value: newValue,
      },
    });
  };

  return (
    <div className={customClasses.addRemoveWrapper}>
      <InputLabel label={label} />
      <div className="add-remove-input">
        <CIB
          isDisabled={isDisabled}
          icon={Subtract}
          onClick={() => handleChange(value ? value - 1 : 0)}
        />
        <NumberInput
          name={name}
          placeholder={placeholder}
          value={value}
          isDisabled={isDisabled}
          onChange={(event) => handleChange(Number(event.target.value))}
          max={percentage && 100}
          icon={
            percentage
              ? () => <span className={customClasses.percentageIcon}>%</span>
              : null
          }
          adornmentPosition="end"
        />
        <CIB
          icon={Add}
          isDisabled={isDisabled}
          onClick={() => handleChange(value || value === 0 ? value + 1 : 0)}
        />
      </div>
      <InputError error={error} />
    </div>
  );
};

export default AddRemove;
