import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const DynamicListSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rect"
        width={125}
        height={28}
        style={{ borderRadius: 6, margin: "16px 0" }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={64}
        style={{ borderRadius: 6, margin: "8px 0" }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={64}
        style={{ borderRadius: 6, margin: "8px 0" }}
      />
      <Skeleton
        variant="rect"
        width={125}
        height={28}
        style={{ borderRadius: 6, margin: "16px 0" }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={64}
        style={{ borderRadius: 6, margin: "8px 0" }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={64}
        style={{ borderRadius: 6, margin: "8px 0" }}
      />
    </>
  );
};

export default DynamicListSkeleton;
