import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from "lodash";
import GetAllParentLocationsByProject from 'gql/StoredLocations/GetAllParentLocationsByEvent';
import FAB from 'components_v2/Buttons/FAB/FAB';
import { FloatingActionNewLocation } from 'components_v2/Icons/Icons';
import LocationsComponent from './Locations.Component';
import { mockData } from './Locations.mock';

const LocationsContainer = ({ projectId, history, isHelpTutorial }) => {
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })

  let { data, loading } = useQuery(GetAllParentLocationsByProject, {
    variables: { id: projectId, ...listConfig, sort: listConfig.sort || 'id:desc' },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }

  const filteredData = _.filter(
    data?.storedlocationsConnection?.values,
    _.negate(
      _.matchesProperty('name', '-As directed-')
    )
  )
  const count = data?.storedlocationsConnection?.aggregate?.count || 0;

  return (
    <>
      <FAB
        label="Add location"
        icon={FloatingActionNewLocation}
        onClick={() => {
          history.push(`/project/${projectId}/location/new`);
        }}
      />
      <LocationsComponent
        handleListConfigChange={handleListConfigChange}
        listConfig={listConfig}
        projectId={projectId}
        data={filteredData || []}
        count={count}
        loading={loading}
      />
    </>
  );
};

LocationsContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default withRouter(LocationsContainer);
