import gql from "graphql-tag";

export default gql`
  mutation CreatePassenger($data: UserInput) {
    createUser(input: { data: $data }) {
      user {
        id
        arrivalDate
        arrivalFlightNumber
        company
        confirmed
        departureDate
        departureFlightNumber
        email
        firstName
        fullName
        identifier
        lastName
        notes
        passengerPriority
        phone
        username
      }
    }
  }
`;
