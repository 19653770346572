import React from 'react';

import request from '../../utils/request';

class Default extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  logUser = (values) => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      const requestURL =
        process.env.REACT_APP_AUTH_ENDPOINT ||
        ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/auth/local");

      request(requestURL, {
        method: 'POST',
        body: values,
      })
        .then((response) => {

          const { user } = response;
          this.setState({ loading: false });
          this.props.onSignup(
            response.jwt,
            { ...user },
            response.events,
            response.streamToken
          );
        })
        .catch(() => {
          // TODO handle errors for other views
          this.setState({ loading: false });
          // This is just an example
          // const errors = [{ name: "identifier", errors: [err.response.payload.message] }];
          this.setState({
            didCheckErrors: !this.state.didCheckErrors,
            errors: [],
            error: 'Email or password invalid.', // err.response.payload.message
          });
        });
    }
  };

  handleSubmitNoEvent = (values) => {
    this.logUser(values);
  };

  handleSubmit = (values) => (e) => {
    e.preventDefault();

    this.logUser(values);
  };

  render() {
    const { handleSubmit, handleSubmitNoEvent } = this;
    const { errors, error } = this.state;
    return this.props.children({
      loading: this.state.loading,
      handleSubmit,
      handleSubmitNoEvent,
      errors,
      error,
    });
  }
}

export default Default;
