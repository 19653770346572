import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import React from "react";
import TitleWithText from "components_v2/TitleWithText/TitleWithText";
import { ChevronRight } from "components_v2/Icons/Icons";
import SelectPassengers from "components_v2/Bookings/SelectPassengers/SelectPassengers";
import { withRouter } from "react-router-dom";
import Tooltip from "./Tooltip";

const PassengerListTooltip = withStyles(() => ({
  popper: {
    zIndex: 1300
  },
  tooltip: {
    padding: "0px",
    boxShadow: "none",
    background: "#000000",
    borderRadius: 3,
    fontSize: 12,
    lineHeight: "16px",
    color: "#fff",
    letterSpacing: "0.3px",
    fontWeight: "bold",
    "& p": {
      // padding: '8px',
      // margin: 0,
    },
    "& .passenger-tooltip__footer": {
      background: "rgba(255, 255, 255, 0.2)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px",
      cursor: "pointer"
    },
    "& .passenger-tooltip__footer svg": {
      width: 16,
      height: "auto",
      marginLeft: -4
    },
    "& .passenger-tooltip__footer svg path": { fill: "#fff" }
  }
}))(props => {
  const { projectId } = props.match.params;
  const handleNewPassengerClick = () => {
    const url = `/project/${projectId}/passenger/new`;

    props.history.push(url);
  };

  const {
    open,
    setOpen,
    setDisableHover,
    disableListeners,
    bookingId,
    passengers
  } = props;

  const handleCloseCallback = () => {
    setDisableHover(false);
    setOpen(false);
  };

  return (
    <Tooltip
      disableHoverListener={disableListeners}
      disableFocusListener={disableListeners}
      disableTouchListener={disableListeners}
      open={open}
      onOpen={() => {
        setOpen(true);
        setDisableHover(true);
      }}
      onClose={() => {
        setOpen(false);
        setDisableHover(false);
      }}
      title={
        props.passengers?.length ? (
          <>
            {props.passengers.map((passenger, index) => (
              <>
                <TitleWithText
                  key={index}
                  noStyling
                  white
                  noText
                  additionalTextStyles={{
                    fontWeight: 800,
                    margin:
                      index < props.passengers.length - 1
                        ? "0px 0px 8px 0"
                        : "0px 0px 16px 0",
                    padding: "0 8px"
                  }}
                  additionalLabelStyles={{
                    margin: 0,
                    padding: "8px 8px 4px 8px"
                  }}
                  label={passenger.name}
                  text={passenger.phone}
                />
                {index < props.passengers.length - 1 ? (
                  <Box
                    style={{
                      width: "calc(100% - 16px)",
                      background: "rgba(255, 255, 255, 0.2)",
                      height: 1,
                      marginLeft: 8
                    }}
                  />
                ) : null}
              </>
            ))}
            <SelectPassengers
              singleSelection
              noResults
              disableSelection
              handleCloseCallback={handleCloseCallback}
              data={passengers}
              values={{ passengers: [] }}
              projectId={projectId}
              bookingId={bookingId}
              handleNewPassengerClick={handleNewPassengerClick}
              customButton={toggleModal => (
                <footer
                  className="passenger-tooltip__footer"
                  onClick={() => {
                    setDisableHover(true);
                    toggleModal();
                    setOpen(true);
                  }}
                >
                  <ChevronRight />
                  See passenger information
                </footer>
              )}
            />
          </>
        ) : (
          <TitleWithText
            noStyling
            white
            noText
            additionalTextStyles={{
              fontWeight: 800,
              margin: "0px 0px 16px 0",
              padding: "0 8px"
            }}
            additionalLabelStyles={{
              margin: 0,
              padding: "8px"
            }}
            label="No passengers selected"
          />
        )
      }
      arrow
      interactive
      {...props}
    >
      <span>{props.children}</span>
    </Tooltip>
  );
});

export default withRouter(PassengerListTooltip);
