import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import { Visible, Done } from 'components_v2/Icons/Icons';
import Button from 'components_v2/Button/Button';
import PasswordInput from 'components_v2/Inputs/PasswordInput/PasswordInput';
import request from 'utils/request';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormFooter from './FormFooter';
import AuthLayout from './AuthLayout';
import { mobileViewBreakpoint } from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  doneIcon: {
    width: 53,
    height: 'auto',
    filter: 'drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))',
    '& path': {
      fill: '#FFF',
    },
  },
  footerFormWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      boxShadow: '0px -10px 17px rgba(0, 0, 0, 0.1)',
      background: '#fff',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },

    '& > footer': {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        background: '#fff',
      },
    },
  },
}));

const ResetPassword = ({ match, history }) => {
  const [loading] = useState(false);
  // Set to true to display the success view
  const [passwordChanged, setPasswordChanged] = useState(false);

  const classes = useStyles();

  const resetPassword = async (values) => {
    const requestURL =
      process.env.REACT_APP_NEWPASSWORD_ENDPOINT ||
      ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/auth/reset-password");

    if (!loading) {
      const response = await request(requestURL, {
        method: 'POST',
        body: values,
      });

      setPasswordChanged(true);
      return response;
    }
  };

  const navigateToLogin = () => {
    history.push('/login');
  };
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const renderForm = ({
    values,
    touched,
    errors,
    handleChange,
    isSubmitting,
    submitForm,
  }) => (
    <>
      {passwordChanged && (
        <FormFooter
          mainAction={
            <Button variant="contained" onClick={navigateToLogin}>
              Log In
            </Button>
          }
        />
      )}
      {!passwordChanged && (
        <Form
          style={
            isMobileView
              ? { height: '100%', display: 'flex', flexDirection: 'column' }
              : {}
          }
        >
          <div style={isMobileView ? { flex: '1' } : {}}>
            <div
              style={
                isMobileView ? { padding: '0 16px' } : { padding: '0 88px' }
              }
            >
              <PasswordInput
                name="password"
                label="Password"
                placeholder="Please enter your password"
                icon={Visible}
                value={values.password}
                onChange={handleChange}
                className={classes.passwordInput}
                error={touched.password && errors.password}
              />
              <div style={{ height: 24 }} />
              <PasswordInput
                name="passwordConfirmation"
                label="Confirm Password"
                placeholder="Please enter your password again"
                icon={Visible}
                value={values.passwordConfirmation}
                onChange={handleChange}
                error={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
              />
            </div>
          </div>
          <div style={{ marginBottom: 40 }} />
          {isSubmitting && <Loading />}
          <div className={classes.footerFormWrapper}>
            <FormFooter
              mainAction={
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  Change Password
                </Button>
              }
            />
          </div>
        </Form>
      )}
    </>
  );

  const validate = (values) => {
    const errors = {};
    if (values.password.length === 0) {
      errors.password = 'Please enter your new password';
    }
    if (values.passwordConfirmation.length === 0) {
      errors.passwordConfirmation = 'Please enter your new password again';
    }
    if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = 'Both passwords should match';
    }
    return errors;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    await resetPassword(values);

    setSubmitting(false);
  };

  const code = match.params.id;

  const headerText = passwordChanged ? 'Password Changed!' : 'Change Password';
  const description = passwordChanged
    ? 'Your password has been changed successfully.'
    : 'Enter your new password.';
  const icon = passwordChanged ? <Done className={classes.doneIcon} /> : null;
  return (
    <AuthLayout headerText={headerText} description={description} icon={icon}>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '', code }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(props) => renderForm(props)}
      </Formik>
    </AuthLayout>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(ResetPassword);
