import React ,{useState}from 'react';

import DriverComponent from './Driver.Component.jsx';

const DriverViewContainer = ({data }) => {
  const [selectedTab, setSelectedTab] = useState('information');
  
 const handleTabChange = (value) => {
  
    setSelectedTab(value)
  };
  return (
    <div style={{padding:'8px'}}>
  <DriverComponent
    isInlineView={true}
    driverId={data.id}
    data={data}
    selectedTab={selectedTab}
    onChangeTab={handleTabChange}
  />
    </div>
    );
};

export default DriverViewContainer;
