import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GetAllByEvent from 'gql/Products/GetAllByEvent';
import Update from 'gql/Products/Update';
import Create from 'gql/Products/Create';
import ProductsComponent from './Products.Component';
import { mockData } from './Products.mock';
import {getFilteredProducts ,getSortedProducts} from './helper'
const ProductsContainer = ({ projectId, isHelpTutorial }) => {
  let { data, loading, refetch } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const [
    createProducts,
  ] = useMutation(Create);

  const [
    updateProducts,
  ] = useMutation(Update);
  const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
  let  finalData = getFilteredProducts({ products: data?.products, filterKey });
  finalData = getSortedProducts({ vehicles: finalData, sort })

  return (
    <ProductsComponent
      setSort={setSort}
      sort={sort}
      setFilterKey={setFilterKey}
      data={(finalData) || []}
      loading={loading}
      createProducts={createProducts}
      updateProducts={updateProducts}
      refetch={refetch}
      projectId={projectId}
    />
  );
};

export default ProductsContainer;
