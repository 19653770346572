import gql from "graphql-tag";

export default gql`
  query GetAllVehicleClasses($id: ID) {
    vehicleclasses(where: { event: $id }, sort: "id:desc") {
      id
      name
      transferPricing
      trackTransferPricing
      fourHourPricing
      sixHourPricing
      twelveHourPricing
      twentyFourHourPricing
      affiliatePricing
      overtimePricing
      trackTransferAffiliatePricing
      fourHourAffiliatePricing
      transferAffiliatePricing
      sixHourAffiliatePricing
      twelveHourAffiliatePricing
      twentyFourHourAffiliatePricing
      overtimeAffiliatePricing
      noShowAffiliatePricing
      noShowPricing
      maxPassengers
      maxLuggage
      type
      waitingTimePricing
      waitingTimeAffiliatePricing
      vehicles {
        id
      }
    }
  }
`;
