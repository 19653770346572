import React from 'react';
import { Grid } from '@material-ui/core';

import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import PhoneInput from 'components_v2/Inputs/PhoneInput/PhoneInput';
import Typography from 'components_v2/Typography/Typography';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';

const AddLocationForm = ({
  values,
  touched,
  errors,
  handleChange,
  classes,
}) => (
  <>
    <FormSection defaultExpanded title="Supplier details">
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <TextInput
            name="name"
            label="Company name *"
            placeholder="Enter company name"
            value={values.name}
            onChange={handleChange}
            error={touched.name && errors.name}
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginBottom: 8, color: '#3C3C3C' }}
          >
            Person of contact
          </Typography>
          <TextInput
            name="contactName"
            label="Full name"
            placeholder="Enter full name"
            value={values.contactName}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <TextInput
            name="contactDepartment"
            label="Department"
            placeholder="Enter department"
            value={values.contactDepartment}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextInput
            name="contactPosition"
            label="Postition"
            placeholder="Enter position"
            value={values.contactPosition}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <PhoneInput
            name="phone"
            label="Phone"
            placeholder="Enter phone number"
            value={values.phone}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridContainer} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <TextInput
            name="email"
            label="Email *"
            placeholder="Enter email"
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email}
          />
        </Grid>
      </Grid>
    </FormSection>
    <TotalErrors errors={errors} touched={touched} withSpacer />
  </>
);

export default AddLocationForm;
