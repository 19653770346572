import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Clients/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddClientComponent from './AddClient.Component';

const AddClientContainer = ({ match }) => {
  const { clientId } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: clientId },
    skip: !clientId,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return (
    <AddClientComponent
      data={data?.clients[0]}
      refetchClient={refetch}
    />
  );
};

AddClientContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ clientId: PropTypes.string }),
  }),
};

export default withMainLayout('Add Driver', {}, withRouter(AddClientContainer));
