import { Grid } from "@material-ui/core";
import Accordion from "components_v2/Accordion/Accordion";
import Button from "components_v2/Button/Button";
import Loading from "components_v2/Loading/CircularProgress";
import CheckboxInput from "components_v2/Inputs/CheckboxInput/CheckboxInput";
import { NumberInput } from "components_v2/Inputs/NumberInput/NumberInput";
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import { Form, Formik } from "formik";
import React from "react";
import ModuleSettingsContainer from "../Modules/ModuleSettingsContainer";
import PricingSettingContainer from "../PricingSettings/PricingSettings.container";
import ProductsContainer from "../Products/Products.Container";
import SupplierPricingContainer from "../SupplierPricing/SupplierPricing.Container";
import VehicleClassesContainer from "../VehicleClasses/VehicleClasses.Container";
import TransferServicesContainer from "../TransferServices/TransferServices.Container";
import withUserRole from "components_v2/Wrappers/withUserRole";
import { checkUserPermission } from "utils/permissionHelpers";
import { deepCopy } from "../../../../utils/helpers";

const RenderFormikForm = ({
  values,
  handleChange,
  projectId,
  isHelpTutorial,
  allPermissions,
  permissions,
  mutationLoading
}) => {
  return (
    <Form>
      <Accordion relativeWrapper summary="Booking Settings" size="large">
        {mutationLoading && <Loading contained />}
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <CheckboxInput
              name="bookingEmailToPassenger"
              label="Send confirmation emails to passengers on bookings"
              value={values.bookingEmailToPassenger}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxInput
              name="bookingTextToPassenger"
              label="Send confirmation SMS to passengers on bookings"
              value={values.bookingTextToPassenger}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxInput
              name="bookingEmailToCreator"
              label="Send confirmation emails to booking creators (i.e. account managers)"
              value={values.bookingEmailToCreator}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <SelectInput
              name="bookingEmailRecepients"
              label="Booking notification email recipients"
              placeholder="Booking notification email recipients"
              value={values.bookingEmailRecepients || []}
              onChange={handleChange}
              isMultiple
              isFreeSoloAllowed
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextInput
              name="fromEmail"
              label="Email from"
              placeholder="Email from"
              value={values.fromEmail}
              onChange={handleChange}
              isDisabled={!permissions.canEdit}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextInput
              name="bookingPrefix"
              label="Booking Code Prefix"
              placeholder="Booking Code Prefix"
              value={values.bookingPrefix}
              onChange={handleChange}
              isDisabled={!permissions.canEdit}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <NumberInput
              name="fullChargeTimeframe"
              label="Full charge cancellation timeframe"
              placeholder="Full charge cancellation timeframe"
              value={values.fullChargeTimeframe}
              onChange={handleChange}
              min={0}
              isDisabled={!permissions.canEdit}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <NumberInput
              name="timeFrameBeforeBooking"
              label="Timeframe before booking"
              placeholder="Timeframe before booking"
              value={values.timeFrameBeforeBooking}
              onChange={handleChange}
              min={0}
              isDisabled={!permissions.canEdit}
            />
          </Grid>
        </Grid>

        <Button style={{ marginTop: 16 }} type="submit">
          Save
        </Button>
      </Accordion>

      {checkUserPermission(allPermissions, "administration").canEdit && (
        <>
          <Accordion relativeWrapper summary="Alert Settings" size="large">
            {mutationLoading && <Loading contained />}
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <CheckboxInput
                  name="backgroundAlertsEnabled"
                  label="Enable background alerts"
                  value={values.backgroundAlertsEnabled}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <NumberInput
                  name="lateArrivalTimeframe"
                  label="Late arrival/ride not confirmed timeframe (minutes)"
                  placeholder="Late arrival time frame in minutes"
                  value={values.lateArrivalTimeframe}
                  onChange={handleChange}
                  type="number"
                />
              </Grid>
            </Grid>
            <Button style={{ marginTop: 16 }} type="submit">
              Save
            </Button>
          </Accordion>
          <Accordion summary="Vehicle classes">
            <VehicleClassesContainer
              projectId={projectId}
              isHelpTutorial={isHelpTutorial}
            />
          </Accordion>
          <Accordion summary="Products">
            <ProductsContainer
              projectId={projectId}
              isHelpTutorial={isHelpTutorial}
            />
          </Accordion>
        </>
      )}

      {checkUserPermission(allPermissions, "supplierPricing").canView && (
        <Accordion summary="Supplier Pricing">
          <SupplierPricingContainer
            projectId={projectId}
            isHelpTutorial={isHelpTutorial}
          />
        </Accordion>
      )}

      {checkUserPermission(allPermissions, "routePricing").canView && (
        <Accordion summary="Client Pricing">
          <PricingSettingContainer
            projectId={projectId}
            isHelpTutorial={isHelpTutorial}
          />
        </Accordion>
      )}

      {checkUserPermission(allPermissions, "administration").canEdit && (
        <Accordion summary="Project Modules">
          <ModuleSettingsContainer
            projectId={projectId}
            isHelpTutorial={isHelpTutorial}
          />
        </Accordion>
      )}
      {checkUserPermission(allPermissions, "administration").canEdit && (
        <Accordion summary="Transfer Services">
          <TransferServicesContainer projectId={projectId} />
        </Accordion>
      )}
    </Form>
  );
};

const Settings = ({
  initialData,
  save,
  projectId,
  isHelpTutorial,
  allPermissions,
  permissions,
  mutationLoading
}) => (
  <>
    <Formik
      initialValues={initialData}
      validationSchema={{}}
      enableReinitialize
      onSubmit={(values, actions) => {
        const localValues = deepCopy(values);
        delete localValues.id;
        delete localValues.__typename;
        delete localValues.modules;
        delete localValues.projectClient;
        delete localValues.products;
        delete localValues.transferservices;

        save({
          variables: {
            data: localValues
          }
        });

        actions.setSubmitting(false);
      }}
    >
      {props =>
        RenderFormikForm({
          ...props,
          projectId,
          isHelpTutorial,
          allPermissions,
          permissions,
          mutationLoading
        })
      }
    </Formik>
  </>
);

export default withUserRole("administration", Settings);
