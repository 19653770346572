import React from "react";
import DynamicList from "components_v2/List/DynamicList";
import PropTypes from "prop-types";
import { columns } from "./ListOptions";
import AccessRightsDetails from "./AccessRightsDetails.container";

const AccessRightsComponent = ({ data, loading }) => {
  return (
    <>
      <DynamicList
        showHeader
        loading={loading}
        data={data}
        noResultsText="No system user roles"
        title="System User Roles"
        numRows={data.length}
        columns={columns}
        detailPanel={row => (
          <AccessRightsDetails accessRightsId={row && row.id} />
        )}
      />
    </>
  );
};

AccessRightsComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired
};
export default AccessRightsComponent;
