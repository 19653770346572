export const checkIfNonNullMandatoryFields = arr => {
  const locationsMapped = {};

  const goodRows = {};
  const badRows = {};

  arr.map(row => {
    let isGood = true;

    Object.keys(row).map(property => {
      if (property.includes("*") && row[property] === null) isGood = false;
    });

    if (isGood) {
      goodRows.push(row);
    } else {
      badRows.push(row);
    }

    return row
  });

  return locationsMapped;
};

const convertToDBString = type => {
  if (type === "transfer") return "transferPricing";
  if (type === "6hour") return "sixHourPricing";
  if (type === "12hour") return "twelveHourPricing";
  if (type === "24hour") return "twentyFourHourPricing";
  if (type === "4hour") return "fourHourPricing";
  if (type === "trackTransfer") return "trackTransferPricing";
};

export const calculateCost = row => {
  if (row.requiredVehicleClass) {
    const typeVariableName = convertToDBString(row.type);

    if (typeVariableName) {
      const transferTypeCost = row.requiredVehicleClass[typeVariableName];
      if (row.numberOfVehicles && transferTypeCost) {
        return row.numberOfVehicles * transferTypeCost;
      }

      return transferTypeCost;
    }
    return null;
  }
  return null;
};
