import gql from 'graphql-tag';

export default gql`
  mutation CreateSupplier($data: SupplierInput) {
    createSupplier(input: { data: $data }) {
      supplier {
        id
        contactName
        email
        name
        phone
      }
    }
  }
`;
