export const formatLocationHistory = (data, driverData) => {
  const _locationData = data?.locationsConnection?.groupBy?.driverId;

  const outputData = driverData.users.map((driver) => {
    const locationForDriver =
      _locationData && _locationData.find((data) => data.key === driver.id);

    const locationData = locationForDriver
      ? locationForDriver.connection.values[0]
      : [];

    return {
      ...driver,
      ...locationForDriver,
      ...locationData,
      id: driver.id,
    };
  });

  return outputData;

  /*
  var driverLocations = data.locationsConnection.groupBy.driverId.map(
    driver => {
      var driverInfo = driverData.users.find(x => x.id == driver.key);

      if (driver.connection.values && driver.connection.values[0]) {
        return {
          ...driverInfo,
          ...driver.connection.values[0],
          id: driver.key
        };
      }
    } */
  // );

  // return driverLocations.filter(x => x);
};
