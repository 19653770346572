import { deepCopy } from "../../utils/helpers"
import _ from "lodash"

export function filterByValue(array, value, ignoredSearchFields = []) {
  const localArray = deepCopy(array)

  return localArray.filter(
    (data) => {
      let localData = deepCopy(data)
      if (ignoredSearchFields?.length)
        localData = _.omit(localData, ignoredSearchFields)
      return JSON.stringify(localData).toLowerCase().indexOf(value.toLowerCase()) !== -1
    }
  );
}


export const getMobileMenuItems = ({ row }) => {
  return {
    left: [
      {
        text: " ",
        onClick: () => {
          const clickedButton = document.querySelector("#delete-action-button_" + row.id)
          if (clickedButton) clickedButton.click()
        },
        className: "mobile-actions-delete"
      }
    ]
  }
}

export const getSkip = ({ disableQuery, isHelpTutorial, loadDataOnRender, queryOptions }) => {
  return disableQuery ||
    isHelpTutorial ||
    loadDataOnRender ||
    !!queryOptions?.skip
}
export const getIsContentFilteredData = ({ query, loading, disableQuery, loadDataOnRender }) => {
  return query && !loading && !disableQuery && !loadDataOnRender
}



