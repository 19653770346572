import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import _ from "lodash";
import AlertCard from "./AlertCard";
import { mobileViewBreakpoint } from "../../../App.helper";
import Typography from "components_v2/Typography/Typography";

const useStyles = makeStyles(({ breakpoints }) => ({
  alerts: {
    background: "#FFFFFF",
    [breakpoints.down(mobileViewBreakpoint)]: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto"
    },

    "& .MuiList-padding": {
      padding: 0
    }
  },
  emptyAlertsWrapper: {
    paddingTop: "26px",
    alginItems: "center",
    display: "flex",
    justifyContent: "center"
  }
}));

const Alerts = ({ alertsData }) => {
  const classes = useStyles();
  const alertDetails = _.orderBy(alertsData, ["created_at"], ["desc"]);

  return (
    <div className={classes.alerts}>
      {(alertDetails.length && (
        <List>
          {alertDetails.map((alert, index) => (
            <AlertCard key={index} cardDetails={alert} />
          ))}
        </List>
      )) || (
        <div className={classes.emptyAlertsWrapper}>
          <Typography customVariant="bodySmall">
            You have no unresolved alerts
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Alerts;
