import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from "../../../../App.helper";

export const useStyles = makeStyles(({ breakpoints }) => ({
  driverDetailsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: 24,
    gridColumnGap: "40px",
    gridRowGap: "8px",
    [breakpoints.down(mobileViewBreakpoint)]: {
      display: "block",
      padding: 0,
    },
  },
  supplierRow: {
    gridColumn: 1,
    gridRow: 1,
    paddingBottom: "48px",
  },
  firstNameRow: {
    gridColumn: 1,
    gridRow: 2,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  lastNameRow: {
    gridColumn: 2,
    gridRow: 2,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  addPhotoRow: {
    gridColumn: 3,
    gridRow: "2 / span 3",
    display: "flex",
    flexDirection: "column",
  },
  phoneRow: {
    gridColumn: 1,
    gridRow: 3,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  whatsAppRow: {
    gridColumn: 2,
    gridRow: 3,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  emailRow: {
    gridColumn: 1,
    gridRow: 4,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  licenseNumberRow: {
    gridColumn: 1,
    gridRow: 5,
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  languageRow: {
    gridColumn: 1,
    gridRow: 6,
    fontWeight: "bold",
  },
  addLanguageRow: {
    gridColumn: 1,
    gridRow: 7,
    fontWeight: "bold",
    paddingBottom: "48px",
  },
  hoursPerDayRow: {
    gridColumn: 1,
    gridRow: 8,
    fontWeight: "bold",
    paddingBottom: "16px",
  },
  startShiftRow: {
    gridColumn: 1,
    gridRow: 9,
    fontWeight: "bold",
    paddingBottom: "32px",
  },
  endShiftRow: {
    gridColumn: 2,
    gridRow: 9,
    fontWeight: "bold",
    paddingBottom: "32px",
  },
  daysWorkingRow: {
    gridColumn: 1,
    gridRow: 10,
    fontWeight: "bold",
    paddingBottom: "48px",
  },
  breakLabelRow: {
    gridColumn: 1,
    gridRow: 11,
    fontWeight: "bold",
  },
  startBreakRow: {
    gridColumn: 1,
    gridRow: 12,
    fontWeight: "bold",
    paddingBottom: "24px",
  },
  endBreakRow: {
    gridColumn: 2,
    gridRow: 12,
    fontWeight: "bold",
    paddingBottom: "24px",
  },
  breakTypeRow: {
    gridColumn: 1,
    gridRow: 13,
    fontWeight: "bold",
    paddingBottom: "24px",
  },
  addBreakRow: {
    gridColumn: 1,
    gridRow: 14,
    fontWeight: "bold",
    paddingBottom: "32px",
  },
  notesRow: {
    gridColumn: 1,
    gridRow: 15,
    fontWeight: "bold",
    paddingBottom: "16px",
  },
  linkVehicleRow: {
    gridColumn: 1,
    gridRow: 16,
    fontWeight: "bold",
  },
  successContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
