import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  timeScaleLabel: {
    height: 120,
  },
  emptyTimeScaleLabel: {
    height: 96,
  },
}));
