import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const styles = () => ({
  badge: {
    top: "100%",
    right: "auto",
    paddingLeft: 6,
    color: "#fff",
    left:"-28px",
    width:"60px",
  },
  passengerName:{
    marginBottom: "16px",
  }
});

class App extends Component {
  render() {
    var { passenger, classes, marginTop } = this.props;

    if (passenger) {
      if (passenger.passengerPriority === "VIP") {
        return (
          <Badge
            badgeContent={"VIP"}
            color="secondary"
            classes={{ badge: classes.badge }}
            style={{ marginTop: marginTop ? -4 : undefined }}
          >
            <span className={classes.passengerName}>
              {passenger.firstName} {passenger.lastName}
            </span>
          </Badge>
        );
      } else {
        return (
          <span>
            {passenger.firstName} {passenger.lastName}
          </span>
        );
      }
    } else return null;
  }
}

export default withStyles(styles)(App);
