import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "../InputLabel/InputLabel";
import InputError from "../InputError/InputError";
import { useStyles, useCustomStyles } from "./TextInput.styles";

const TextInput = ({
  label,
  icon,
  placeholder,
  name,
  value,
  onChange,
  isDisabled,
  error,
  rows,
  isErrored,
  multiline,
  className,
  type = "text",
  step,
  min,
  max,
  adornmentPosition = "end",
  noMargin,
  onClick
}) => {
  const variant = "outlined";
  const Icon = icon;
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div
      onClick={(event) => onClick && onClick(event)}
      className={`${customClasses.inputWrapper} input-wrapper-overrider`}
      style={{ marginBottom: noMargin ? 0 : undefined }}
    >
      <InputLabel label={label} />
      <TextField
        className={className}
        fullWidth
        multiline={multiline}
        name={name}
        rows={rows}
        type={type}
        error={!!error || isErrored}
        placeholder={placeholder}
        variant={variant}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        InputProps={{
          classes,
          disableUnderline: true,
          endAdornment: Icon && adornmentPosition === "end" && (
            <InputAdornment position="end">
              <Icon />
            </InputAdornment>
          ),
          startAdornment: Icon && adornmentPosition === "start" && (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ),
          inputProps: {
            step,
            min,
            max
          }
        }}
      />
      <InputError error={error} />
    </div>
  );
};

export default TextInput;
