import React from "react";
import gql from "graphql-tag";

import ListModal from "components_v2/ListModal/ListModal";
import SelectActionButton from "components_v2/SelectActionButton/SelectActionButton";
import EmailDisplay from "../../EmailDisplay/EmailDisplay";
import PhoneDisplay from "../../PhoneDisplay/PhoneDisplay";
const passengerColumns = [
  {
    field: "firstName",
    headerName: "Name",
    width: 2,
    render: row => `${row.firstName} ${row.lastName}`,
    titleWithText: true
  },
  {
    field: "email",
    headerName: "Email",
    width: 4,
    render: row => <EmailDisplay email={row.email}></EmailDisplay>,
    titleWithText: true
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true,
    render: data => <PhoneDisplay phone={data.phone}></PhoneDisplay>
  },
  {
    field: "company",
    headerName: "Company",
    width: 3,
    titleWithText: true,
    hideMobile: true
  }
];

const passengerColumnsTiny = [
  {
    field: "name",
    headerName: "Name",
    width: 3,
    render: row => `${row.firstName} ${row.lastName}`,
    titleWithText: true
  },
  {
    field: "email",
    headerName: "Email",
    width: 3,
    titleWithText: true,
    render: row => <EmailDisplay email={row.email}></EmailDisplay>
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true,
    hideMobile: true,
    render: data => <PhoneDisplay phone={data.phone}></PhoneDisplay>
  },
  {
    field: "company",
    headerName: "Company",
    width: 3,
    titleWithText: true,
    hideMobile: true
  }
];

const SelectPassengers = ({
  values,
  projectId,
  bookingId,
  onDone,
  handleCloseCallback,
  handleNewPassengerClick,
  handleUpdatePassengers,
  error,
  incomingItems,
  singleSelection,
  data,
  noResults,
  disableSelection,
  customButton,
  customHandleDelete,
  readOnlyResult
}) => {
  const getTitle = (singleSelection, disableSelection) => {
    if (disableSelection) return `${bookingId} Passengers`;
    return singleSelection
      ? "Select main passenger"
      : "Select additional passengers";
  };

  return (
    <ListModal
      readOnlyResult={readOnlyResult}
      customHandleDelete={customHandleDelete}
      onCloseCallback={handleCloseCallback}
      disableSelection={disableSelection}
      title={getTitle(singleSelection, disableSelection)}
      handleCloseCallback={handleCloseCallback}
      noResults={noResults}
      searchPlaceholder="Search for a passenger"
      singleSelection={singleSelection}
      values={values}
      disableQuery={!!data}
      data={data}
      query={gql`
        query GetAllPassengers($id: ID) {
          users(where: { role: 1, event: $id }) {
            id
            email
            firstName
            lastName
            fullName
            phone
            confirmed
            company
            identifier
            username
            notes
            passengerPriority
            arrivalFlightNumber
            arrivalDate
            departureFlightNumber
            departureDate
            # hotel
            role {
              id
              name
            }
          }
        }
      `}
      dataSelector={data => data.users}
      queryOptions={{
        variables: { id: projectId },
        fetchPolicy: "no-cache"
      }}
      mainAction={{
        label: "Done",
        onClick: onDone
      }}
      secondaryAction={{
        label: "Create a new passenger",
        onClick: handleNewPassengerClick
      }}
      handleUpdateForm={handleUpdatePassengers}
      error={error}
      incomingItems={incomingItems}
      columns={passengerColumns}
      renderSelectionColumns={passengerColumnsTiny}
      customButton={customButton}
      onModalClick={toggleModal =>
        customButton ? (
          customButton(toggleModal)
        ) : (
          <SelectActionButton onClick={toggleModal}>
            {singleSelection
              ? "Select Main Passenger"
              : "Select Additional Passengers"}
          </SelectActionButton>
        )
      }
    />
  );
};

export default SelectPassengers;
