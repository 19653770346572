import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

const styles = () => ({
  tooltip: {
    boxShadow: 'none',
    background: '#000000',
    borderRadius: 3,
    fontSize: 12,
    lineHeight: "16px",
    color: "#fff",
    letterSpacing: "0.3px",
    fontWeight: "bold",
  },
  arrow: {
    fontSize: 8,
    width: 9,
    '&::before': {
      backgroundColor: '#000',
    },
  },
});

const content = (props) => props.nocontainer ? props.children : <span>
  {props.children}
</span>

const LightTooltip = ((props) => {
  const { nocontainer, children, ...other } = props;
  return (
    props.tooltip?.disabled ? content(props) :
      <Tooltip title={props.title} arrow children={children} {...other}>
        {content({ nocontainer , children })}
      </Tooltip>
  );
})

export default withStyles(styles)(LightTooltip);
