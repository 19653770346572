import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Passengers/GetOne';
import GetOneEvent from 'gql/Events/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddPassengerComponent from './AddPassenger.Component';

const AddPassengerContainer = ({ match }) => {
  const { passengerId, projectId } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: passengerId },
    skip: !passengerId,
    fetchPolicy: 'no-cache',
  });

  const { data: eventData = null, loading: eventLoading } = useQuery(
    GetOneEvent,
    {
      variables: { id: projectId },
      fetchPolicy: 'no-cache',
    }
  );

  if (loading || eventLoading) return <Loading />;

  return (
    <AddPassengerComponent
      data={data?.user}
      projectId={projectId}
      projectData={eventData && eventData.events && eventData.events[0]}
      refetchPassenger={refetch}
    />
  );
};

AddPassengerContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ passengerId: PropTypes.string }),
  }),
};

export default withMainLayout(
  'AddSupplier',
  {},
  withRouter(AddPassengerContainer)
);
