import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";

import GetAllByEvent from "gql/StoredLocations/GetAllByEvent";
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import Loading from "components_v2/Loading/CircularProgress"
import { createLocationWithState } from "utils/routerHelpers";
import withFormState from "containers/Wrappers/withFormState";

const StoredLocationSearch = props => {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    projectId,
    error,
    history,
    location,
    values,
    disableAsDirected,
    setFormState,
    isDisabled,
    returnField,
    returnFieldMetadata = {}
  } = props;

  const { data, loading } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: "no-cache"
  });

  const options =
    !loading && data ?
    data.storedlocations
      .filter(x => (disableAsDirected ? x.name !== "-As directed-" : true))
      .filter(location => !!location.name)
      .map(location => ({
        name: location.name,
        value: location.name,
        lat: location.lat,
        long: location.long,
        address: location.address,
        type: location.type,
        id: location.id
      })) :
      [
        {
          loading: true,
          name: "",
        }
      ]

  const optionsWithAddNewLink = (options && [
    ...options,
    { link: true, name: "Add New" }
  ]) || [{ link: true, name: "Add New" }];

  return (
    <SelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      isDisabled={isDisabled}
      getOptionDisabled={(option) => option.loading}
      value={value || ""}
      onChange={e => {
        if (e?.target?.value?.link) {
          const { pathname, state } = createLocationWithState(
            `/project/${projectId}/location/new`,
            location,
            values,
            null,
            true,
            returnField,
            returnFieldMetadata
          );
          
          setFormState(state);
          history.replace({ pathname, state: { hasState: true } });
        } else onChange(e);
      }}
      options={optionsWithAddNewLink || []}
      isError={!!error}
      errorMessage={error}
      renderOption={option => (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {option.link ? (
            <div
              style={{
                width: "100%",
                height: 1,
                background: "#111",
                position: "relative",
                bottom: 6
              }}
            />
          ) : null}
          <div
            style={{
              color: option.link ? "#4001C5" : undefined,
              fontWeight: option.link ? 700 : undefined,
              width: option.link ? "100%" : undefined
            }}
            value={option.name}
          >
            {option.loading ? <Loading thickness={7.8} size={22} /> : null}
            {option.name}
          </div>
        </div>
      )}
    />
  );
};

export default withFormState(withRouter(StoredLocationSearch));
