import { getAlertTag } from "./AlertsHelper";
export const getMobileMenuItems = ({ row, onToggleStatus }) => ({
  right: [
    {
      text:
        (row.status === "resolved" && "Mark as unresolved") ||
        "Mark as resolved",
      onClick: (row) => {
        onToggleStatus(
          row,
          (row.status === "resolved" && "unresolved") || "resolved"
        );
      },

      className: "mobile-actions-primary-actions",
    },
  ],
});
export const columns = [
  {
    field: "description",
    headerName: "Description",
    width: 6,
    isDisabled: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 3,
    hideMobile: true,
    render: (data) => getAlertTag(data),
  },
  {
    field: "category",
    headerName: "Category",
    width: 3,
    hideMobile: true,
    isDisabled: true,
    isSortable: false,
  },
];
