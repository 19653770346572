import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  timeScaleLabel: {
    height: 72,
    cursor: 'pointer',
  },
  emptyTimeScaleLabel: {
    height: 48,
    cursor: 'pointer',
  },
}));
