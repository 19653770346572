import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _ from "lodash";


import Button from 'components_v2/Button/Button';
import FullScreenPopover from 'components_v2/FullScreenPopover/FullScreenPopover';
import Loading from 'components_v2/Loading/CircularProgress';
import CreateStoredLocation from 'gql/StoredLocations/Create';
import UpdateStoredLocation from 'gql/StoredLocations/Update';
import DeleteStoredLocation from 'gql/StoredLocations/Delete';
import SuccessCard from 'components_v2/Cards/SuccessCard/SuccessCard';
import AddLocationForm from './AddLocationForm';
import useStyles from './AddLocation.style';
import {
  formatIncomingData,
  formatSubmissionValues,
  formatFunctionalAreaValues,
  validationSchema,
} from './helpers';
import { getContinueBodyAction } from "utils/formHelpers";
import withFormState from "containers/Wrappers/withFormState";

const AddLocation = ({
  history,
  location,
  data,
  refetchLocation,
  refetchFunctionalAreas,
  match,
  projectData = {},
  getPreviousFormState,
  setReturnValues
}) => {
  const classes = useStyles();
  const [storedLocationId, setStoredLocationId] = useState(null);
  const { projectId } = match.params;
  const previousFormState = getPreviousFormState(location);

  const initialInputValues = {
    location: null,
    functionalAreas: [],
    event: projectId,
    contactFullName: '',
    contactEmail: '',
    type: null,
    name: '',
    hasFunctionalAreas: false,
  };

  const isUpdate = !!data.parentLocation;
  const initialValues = data.parentLocation
    ? { ...initialInputValues, ...formatIncomingData(data) }
    : initialInputValues;

  const successHeader = `Location ${isUpdate ? 'Updated' : 'Created'}!`;
  const successDescription = `Congratulations, a new location has been ${isUpdate ? 'updated' : 'created'
    }.`;
  const title = isUpdate
    ? 'Update an existing location'
    : 'Create a new location';

  const [
    createStoredLocation,
    { loading: createMutationLoading },
  ] = useMutation(CreateStoredLocation);

  const [
    updateStoredLocation,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateStoredLocation);

  const [
    deleteStoredLocation,
    { loading: deleteMutationLoading },
  ] = useMutation(DeleteStoredLocation);

  const isLoading =
    createMutationLoading || updateMutationLoading || deleteMutationLoading;

  const submitFunctionalAreas = async (values, parentId) => {
    const formattedFunctionalAreas = formatFunctionalAreaValues(
      values,
      initialValues,
      isUpdate,
      parentId
    );



    await Promise.all(
      formattedFunctionalAreas.map(async ([area, id]) => {

        if (id)
          await updateStoredLocation({
            variables: { id: { id }, data: area },
          });
        else await createStoredLocation({ variables: { data: area } });
      })
    );
  };

  const handleSubmit = async (values, actions) => {
    const formattedValues = formatSubmissionValues(
      values,
      initialValues,
      isUpdate
    );

    if (isUpdate) {
      const id = data.parentLocation.id;
      const res = await updateStoredLocation({
        variables: {
          id: { id },
          data: formattedValues,
        },
      });

      if (res?.data?.updateStoredlocation) {
        await submitFunctionalAreas(values, id);
        setStoredLocationId(id);
        await refetchLocation();
        await refetchFunctionalAreas();
      }
    } else {
      const res = await createStoredLocation({
        variables: {
          data: formattedValues,
        },
      });

      if (res?.data?.createStoredlocation) {
        const { storedlocation } = res.data.createStoredlocation;
        const { id } = storedlocation;
        await submitFunctionalAreas(values, id);
        setStoredLocationId(id);
        setReturnValues(storedlocation, previousFormState);
        actions.resetForm(initialValues);
      }
    }

    actions.setSubmitting(false);
  };

  const mainSuccessAction = !previousFormState
    ? {
      text: 'Back To Locations',
      onClick: () => {
        history.push(`/project/${projectId}/locations`);
      },
    }
    : null;
  const secondarySuccessAction = !previousFormState
    ? {
      text: 'Edit Location',
      onClick: () => {
        if (!isUpdate)
          history.push(
            `/project/${projectId}/location/${storedLocationId}/edit`
          );
        setStoredLocationId(null);
      },
    }
    : undefined;

  let mainBodyActions;

  if (!_.isEmpty(previousFormState)) mainBodyActions = [
    getContinueBodyAction(history, previousFormState),
  ];

  else mainBodyActions = [
    {
      text: 'Add Additional Locations',
      onClick: () => {
        if (isUpdate) history.push(`/project/${projectId}/location/new`);
        setStoredLocationId(null);
      },
    },
  ];

  if (storedLocationId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        onCloseCallback={() => {
          history.goBack();
        }}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={mainSuccessAction}
          secondaryAction={secondarySuccessAction}
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={title}
              subtitle={projectData.name}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save location
                </Button>
              }
              onCloseCallback={() => {
                history.goBack();
              }}
              pageView
            >
              <AddLocationForm
                {...props}
                classes={classes}
                deleteStoredLocation={deleteStoredLocation}
                refetchFunctionalAreas={refetchFunctionalAreas}
              />
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddLocation));
