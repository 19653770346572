import React from 'react';

const ModuleTableActions = () => {
  return (
    <>
      {/* <div>{this.EditAction()}</div> */}
      {/* <div>{this.DeleteAction()}</div> */}
    </>
  );
};

export default ModuleTableActions;