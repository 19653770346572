import React from 'react';

import ListModal from 'components_v2/ListModal/ListModal';
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import GetAllSuppliers from 'gql/Suppliers/GetAll';
import EmailDisplay from '../../EmailDisplay/EmailDisplay'
const supplierColumns = [
  {
    field: 'name',
    headerName: 'Supplier',
    width: 2,
    titleWithText: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 4,
    titleWithText: true,
    render: (row) =>(
      <EmailDisplay email={row.email}></EmailDisplay>),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 3,
    titleWithText: true,
  },
];

const supplierColumnsResults = [
  {
    field: 'name',
    headerName: 'Supplier',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 6,
    titleWithText: true,
    render: (row) =>(
      <EmailDisplay email={row.email}></EmailDisplay>),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 3,
    titleWithText: true,
    hideMobile: true,
  },
];

const SelectSupplier = ({
  values,
  onDone,
  handleCloseCallback,
  handleNewClick,
  error,
  incomingItems,
  singleSelection,
  data,
  noResults,
  disableSelection,
  customButton,
  customHandleDelete,
}) => (
  <ListModal
    enableRadioUnselect
    customHandleDelete={customHandleDelete}
    onCloseCallback={handleCloseCallback}
    disableSelection={disableSelection}
    title="Select Supplier"
    handleCloseCallback={handleCloseCallback}
    noResults={noResults}
    searchPlaceholder="Search for a supplier"
    singleSelection={singleSelection}
    values={values}
    disableQuery={!!data}
    data={data}
    query={GetAllSuppliers}
    dataSelector={(data) => data.suppliers}
    queryOptions={{
      fetchPolicy: 'no-cache',
    }}
    mainAction={{
      label: 'Done',
      onClick: onDone,
    }}
    secondaryAction={{
      label: 'Create a new supplier',
      onClick: handleNewClick,
    }}
    error={error}
    incomingItems={incomingItems}
    columns={supplierColumns}
    renderSelectionColumns={supplierColumnsResults}
    customButton={customButton}
    onModalClick={(toggleModal) =>
      customButton ? (
        customButton(toggleModal)
      ) : (
        <SelectActionButton onClick={toggleModal}>
          Select a supplier
        </SelectActionButton>
      )
    }
  />
);

export default SelectSupplier;
