import React from 'react';
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';

import { formatDayScaleDate } from '../helpers';
import useStyles from './DayViewDayScaleCell.style';

const DayViewDayScaleCell = ({ setView, ...restProps }) => {
  const classes = useStyles();

  const dayScaleClass = restProps.today
    ? classes.dayScaleCellToday
    : classes.dayScaleCell;

  return (
    <DayView.DayScaleCell
      {...restProps}
      className={dayScaleClass}
      formatDate={formatDayScaleDate}
      onClick={() => {
        setView('week');
      }}
    />
  );
};

export default DayViewDayScaleCell;
