import React from 'react';
import PropTypes from 'prop-types';

import Step from './Step';

const Stepper = ({ currentStep, numberOfSteps }) => {
  let steps = [];

  for (let i = 0; i < numberOfSteps; i += 1) {
    const stepNumber = i + 1;
    const lastStep = stepNumber === numberOfSteps;
    steps = [
      ...steps,
      <Step
        stepNumber={stepNumber}
        currentStep={currentStep}
        lastStep={lastStep}
      />,
    ];
  }

  return <div style={{ display: 'flex', alignItems: 'center' }}>{steps}</div>;
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
};

export default Stepper;
