import React from 'react';
import GetOne from 'gql/Vehicles/GetOne.js';
import ViewVehicleComponent from 'components_v2/Views/Internal/ViewVehicle/ViewVehicle.Component';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

const ViewVehicleContainer = ({ vehicleId }) => {
  const { data, loading } = useQuery(GetOne, {
    variables: { id: vehicleId },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <ViewVehicleComponent
        data={(data?.vehicles[0]) || {}}
        loading={loading}
      />
    </>
  );
};

export default withRouter(ViewVehicleContainer);
