import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ContainedButton } from "../Button/Partials/Contained";
import { ChevronDown, Clear, Distance } from "../Icons/Icons";

const useStyles = makeStyles(() => ({
  driverActionsWrapper: {
    display: "flex",

    "& > div:first-child": {
      background: "#000000",
      display: "flex",
      flex: 1,
      alignItems: "center",
      padding: "8px 24px"
    }
  },
  distanceWrapper: {
    position: "relative",
    padding: "7px",
    marginRight: "16px",

    "& > div": {
      background: "rgba(255, 255, 255, 0.3)",
      borderRadius: "20px",
      fontWeight: "500",
      fontSize: "8px",
      lineHeight: "9px",
      textAlign: "center",
      letterSpacing: "0.2px",
      color: "#FFFFFF",
      minWidth: "29px",
      minHeight: "13px",
      left: 0,
      top: "22px",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  },
  driverWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    textAlign: "left",

    "& > .title": {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF"
    },
    "& > .driverName": {
      fontSize: "14px",
      lineHeight: "134.3%",
      color: "#FFFFFF"
    }
  },
  assignDriver: {},
  closeWrapper: {
    marginLeft: "21px",
    marginRight: "21px",

    "& > svg": {
      cursor: "pointer",

      "& > path": {
        fill: "#ffffff"
      }
    }
  },
  otherOptionsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "23px",
    paddingRight: "23px",
    backgroundColor: "#333",

    "& > svg": {
      cursor: "pointer",

      "& > path": {
        fill: "#ffffff"
      }
    }
  }
}));

const DriverActions = ({
  distance,
  driver,
  onAssignDriverClick,
  onCloseClick,
  onDropdownClick,
  otherOptions
}) => {
  const classes = useStyles();

  return (
    <div className={classes.driverActionsWrapper}>
      <div>
        {distance && (
          <div className={classes.distanceWrapper}>
            <Distance />
            <div>{distance}</div>
          </div>
        )}
        {driver && (
          <div className={classes.driverWrapper}>
            <div className="title">Suggested Driver</div>
            <div className="driverName">{driver.name}</div>
          </div>
        )}

        <ContainedButton
          className={classes.assignDriver}
          onClick={onAssignDriverClick}
        >
          Assign Driver
        </ContainedButton>

        <div className={classes.closeWrapper} onClick={onCloseClick}>
          <Clear />
        </div>
      </div>
      {otherOptions && (
        <div className={classes.otherOptionsWrapper} onClick={onDropdownClick}>
          <ChevronDown />
        </div>
      )}
    </div>
  );
};

export default DriverActions;
