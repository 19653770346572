import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../App.helper';

export const useStyles = makeStyles(({ typography, breakpoints }) => ({
  divider: {
    height: 24,
    margin: 4,
  },
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: typography.fontSize,
    position: 'relative',
    marginTop: '14px',
  },
  iconButton: {
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSize,
    '&:hover': {
      color: '#4001C5',
      backgroundColor: 'transparent',
      borderRadius: '0px',
      boxShadow: '0 3px #4001C5',
    },
  },
  badgeButtonText: {
    paddingLeft: '20px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  iconButtonText: {
    paddingLeft: '10px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    justifyContent: 'flex-end',
  },
  notifications: {
    marginLeft: '-10px',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  sidebar: {
    width: '534px',
    background: '#FFFFFF',
    boxShadow: '-16px 0px 25px rgba(0, 0, 0, 0.1)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down(mobileViewBreakpoint)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  sidebarWrapper: {
    [breakpoints.down(mobileViewBreakpoint)]: {
      '& .MuiDrawer-paper': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
  },
  header: {
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',

    [breakpoints.down(mobileViewBreakpoint)]: {
      height: 'auto',
    },
  },
  mobileTitleBarWrapper: {
    [breakpoints.down(mobileViewBreakpoint)]: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      width: '100%',
    },
  },
  mobileTitleBar: {
    [breakpoints.down(mobileViewBreakpoint)]: {
      position: 'relative',
      margin: '48px 0 16px 0',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& .MuiBadge-badge': {
        position: 'relative',
        top: '16px',
        left: '4px',
        lineHeight: 'unset',
        width: '30px',
        height: '30px',
        borderRadius: '30px',
        fontSize: '13px',
      },

      '& .MuiIconButton-root': {
        position: 'absolute',
        left: '16px',
        top: '2px',
      },
    },
  },
}));
