import React from 'react';

const CircularIcon = ({ bgColor, icon, bgSize, isShadowed }) => (
  <div
    style={{
      backgroundColor: bgColor,
      boxShadow: isShadowed ? 'rgba(0, 0, 0, 0.3) 1px 8px 12px' : 'none',
      width: bgSize,
      height: bgSize,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {icon}
  </div>
);
export default CircularIcon;
