import gql from "graphql-tag";

export default gql`
  query GetAllPassengerSchedules($id: ID, $start: String, $end: String) {
    passengerschedules(
      where: { event: $id, start_gte: $start, start_lte: $end }
    ) {
      id
      start
      stop
      stops
      vehicleClass
      origin
      created_at
      type
      flightNumber
      terminal
      notes
      numberOfVehicles
      numPassengers
      bookingId
      productNotes
      cancelled
      documents
      supplier {
        id
      }
      savedStops {
        name
        type
        address
        lat
        long
        id
      }
      savedDocuments {
        id
        size
        url
        name
        uploadedBy {
          id
          firstName
          lastName
        }
      }
      event {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
        fullName
        email
        phone
      }
      requiredVehicleClass {
        id
        name
        transferPricing
        trackTransferPricing
        fourHourPricing
        sixHourPricing
        twelveHourPricing
        twentyFourHourPricing
        maxPassengers
      }
      department {
        id
        name
        module {
          id
          name
        }
      }
      transferservice {
        id
        name
      }
      passenger {
        id
        firstName
        lastName
        fullName
        email
        phone
      }
      from {
        id
        name
        address
        lat
        long
      }
      to {
        id
        name
        address
        lat
        long
      }
      driverschedules {
        id
        cancelled
        vehicle {
          id
          vehicleNumber
          make
          model
          year
          plateNumber
          color
        }
        driver {
          id
          firstName
          lastName
          fullName
          identifier
          phone
        }
        ridestatuses {
          id
          status
          created_at
          location
        }
        overtimeHours
      }
    }
  }
`;
