import gql from 'graphql-tag';

export default gql`
  mutation UpdateSupplier($id: InputID, $data: editSupplierInput) {
    updateSupplier(input: { where: $id, data: $data }) {
      supplier {
        id
      }
    }
  }
`;
