import React from 'react';
import RateDriverComponent from 'components_v2/Views/Public/RateDriver/RateDriver.jsx';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GetRideInformationGQL from 'gql/DriverSchedules/GetOne';
import CreateDriverRatingGQL from 'gql/DriverRatings/Create';
import { NetworkStatus } from '@apollo/client';
import _ from 'lodash';
import TrackRide from '../TrackRide/TrackRide.Component';

const onCreate = async (
  driverschedule,
  rating,
  comments,
  addRating,
  refetch
) => {
  await addRating({
    variables: {
      data: {
        comment: comments,
        driver: driverschedule.driver.id,
        driverschedule: driverschedule.id,
        passenger: driverschedule.passengerschedule.passenger.id,
        rating,
      },
    },
  });
  await refetch();
};

const RateDriverViewContainer = ({ match }) => {
  const [
    addRating,
    { data: mutationData, loading: mutationLoading },
  ] = useMutation(CreateDriverRatingGQL);

  const { id } = match.params;
  const {
    data: queryData,
    loading: queryLoading,
    refetch,
    networkStatus,
  } = useQuery(GetRideInformationGQL, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  let latestDriverRating = null;
  let isCompletedRide = false;


  if (queryData) {
    const allDriverRatings = queryData.driverschedule.driveratings;
    latestDriverRating =
      allDriverRatings &&
      allDriverRatings.length > 0 &&
      allDriverRatings[allDriverRatings.length - 1];

    const allRideStatuses = queryData.driverschedule.ridestatuses;
    isCompletedRide = !!_.find(allRideStatuses, { status: 'complete' });
  }

  return (
    <>
      {queryData && (
        <TrackRide
          driverSchedule={queryData && queryData.driverschedule}
          isMapVisible={false}
          isTrackRideDataVisible={false}
        >
          <RateDriverComponent
            driverSchedule={queryData && queryData.driverschedule}
            isCompletedRide={isCompletedRide}
            ratingData={latestDriverRating}
            loading={
              mutationLoading ||
              queryLoading ||
              networkStatus === NetworkStatus.refetch
            }
            ratingPosted={
              !!mutationData &&
              !mutationLoading &&
              networkStatus !== NetworkStatus.refetch
            }
            onRatingChange={(rating, comments) => {
              onCreate(
                queryData.driverschedule,
                rating,
                comments,
                addRating,
                refetch
              );
            }}
          />
        </TrackRide>
      )}
    </>
  );
};

export default withRouter(RateDriverViewContainer);
