import React from "react";
import Slide from '@material-ui/core/Slide';

export const SlideElement = ({ actionsOverlay, row, setOverlayOpen, overlayOpen }) => {
  return <Slide
    direction="left"
    in={overlayOpen}
    mountOnEnter
    unmountOnExit
  >
    <div
      style={{
        position: 'relative',
        zIndex: 99,

        marginTop: -18,
        marginRight: -16,
        marginBottom: -53,
      }}
    >
      {actionsOverlay && (
        <div style={{}}>
          {actionsOverlay(row, setOverlayOpen)}
        </div>
      )}
    </div>
  </Slide>
}