import React from 'react';
import { SnackbarContent } from 'notistack';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from 'components_v2/Typography/Typography';
import { CardHeader } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import { useCustomStyles } from './Toast.styles';
import { Info, ToastInfo } from '../Icons/Icons';

const Toast = React.forwardRef(
  (
    {
      key,
      message,
      type,
      icon,
      description,
      onOKClick,
      onCancelClick,
      isNoIcon,
    },
    ref
  ) => {
    const customClasses = useCustomStyles();
    const Icon = icon;

    const renderHeaderIcon = () => {
      if (!isNoIcon) {
        if (type === 'error') {
          return <Avatar>{(Icon && <Icon />) || <Info />}</Avatar>;
        }
        return <Avatar>{(Icon && <Icon />) || <ToastInfo />}</Avatar>;
      }

      return null;
    };

    return (
      <SnackbarContent ref={ref} className={customClasses.toastWrapper}>
        <Card key={key}>
          <CardHeader
            className={classNames(customClasses.toastHeader, {
              [customClasses.toasterErrorType]: type === 'error',
            })}
            title={message}
            avatar={renderHeaderIcon()}
          />
          <CardContent className={customClasses.toastBody}>
            <Typography customVariant="bodySmallRegular">
              {description}
            </Typography>
          </CardContent>
          <CardActions className={customClasses.toastFooter}>
            <Button
              secondary
              className={customClasses.toastCancelButton}
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            <Button
              secondary
              className={customClasses.toastOKButton}
              onClick={onOKClick}
            >
              OK
            </Button>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

export default Toast;
