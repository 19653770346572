import React from 'react';
import { useStyles } from './TourTooltipDescription.styles';
import './react-joyride.css';

const TourTooltipIntro = ({ content, index, totalNumberOfSteps }) => {
  const classes = useStyles();

  return (
    <div className={classes.tourTooltipDescriptionWrapper}>
      <div className={classes.tooltipTipCounter}>
        Tip <strong>{index}</strong> of {totalNumberOfSteps}
      </div>
      <div className={classes.tooltipDescription}>{content}</div>
    </div>
  );
};

export default TourTooltipIntro;
