import WebMercatorViewport from "viewport-mercator-project";

export const getBoundsForPoints = (points, params = { padding: 200 }) => {
  const applyToArray = (func, array) => func.apply(Math, array);

  // Calculate corner values of bounds
  const pointsLong = points.map(point => point.long);
  const pointsLat = points.map(point => point.lat);
  const cornersLongLat = [
    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
  ];
  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const viewport = new WebMercatorViewport({
    width: 800,
    height: 600
  }).fitBounds(cornersLongLat, params);
  const { longitude, latitude, zoom } = viewport;
  return { longitude, latitude, zoom };
};

export const add3DOverlay = map => {
  map.on("load", function() {
    // Insert the layer beneath any symbol layer.
    const { layers } = map.getStyle();

    let labelLayerId;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
        labelLayerId = layers[i].id;
        break;
      }
    }

    map.addLayer(
      {
        id: "3d-buildings",
        source: "composite",
        "source-layer": "building",
        filter: ["==", "extrude", "true"],
        type: "fill-extrusion",
        minzoom: 14,
        paint: {
          "fill-extrusion-color": "#aaa",

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          "fill-extrusion-height": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.05,
            ["get", "height"]
          ],
          "fill-extrusion-base": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.05,
            ["get", "min_height"]
          ],
          "fill-extrusion-opacity": 0.6
        }
      },
      labelLayerId
    );
  });
};

// https://codesandbox.io/s/proud-water-t6ume

export const addLineLayer = (map, coords) => {
  // remove layer if it already exists
  const mapLayer = map.getLayer("route");
  if (typeof mapLayer !== "undefined") {
    map.removeLayer("route").removeSource("route");
  }

  map.addLayer({
    id: "route",
    type: "line",
    source: {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coords
        }
      }
    },
    layout: {
      "line-join": "round",
      "line-cap": "round"
    },
    paint: {
      "line-color": "#000",
      "line-width": 8
    }
  });
};

export const updatePoint = (pointId, coordinates, heading, map) => {
  map.getSource(pointId).setData({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates
        },
        properties: { rotate: heading || 0 }
      }
    ]
  });
};

export const flyTo = (target, map) => {
  map.flyTo({
    // These options control the ending camera position: centered at
    // the target, at zoom level 9, and north up.
    center: target,
    zoom: 12,
    bearing: 0,

    // These options control the flight curve, making it move
    // slowly and zoom out almost completely before starting
    // to pan.
    speed: 2, // make the flying slow
    curve: 2, // change the speed at which it zooms out

    // This can be any easing function: it takes a number between
    // 0 and 1 and returns another number between 0 and 1.
    easing(t) {
      return t;
    },

    // this animation is considered essential with respect to prefers-reduced-motion
    essential: true
  });
};

export const createPoint = (
  pointId,
  coordinates,
  heading,
  map,
  pointType,
  vehicleInformation = null
) => {
  const mapObject = map;

  let vehicleType = vehicleInformation
    ? vehicleInformation.vehicle.type
    : "human";

  if (!vehicleType) {
    vehicleType = "sedan";
  }

  mapObject.addSource(pointId, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates
          },
          properties: { rotate: heading || 0, type: pointType }
        }
      ]
    }
  });

  mapObject.addLayer({
    id: pointId,
    source: pointId,
    type: "symbol",
    layout: {
      "icon-image": vehicleType,
      "icon-size": 0.15,
      "icon-rotate": ["get", "rotate"],
      "icon-allow-overlap": true
    }
    /*
    paint: {
      "circle-radius": 10,
      "circle-color": "#007cbf"
    } */
  });

  mapObject.on("click", pointId, function(e) {
    mapObject.flyTo({ center: e.features[0].geometry.coordinates });
  });

  mapObject.on("mouseenter", pointId, function() {
    mapObject.getCanvas().style.cursor = "pointer";
  });

  mapObject.on("mouseleave", pointId, function() {
    mapObject.getCanvas().style.cursor = "";
  });
};

export const createLocationMarker = (pointId, coordinates, map) => {
  const mapObject = map;

  mapObject.addSource(pointId, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates
          },
          properties: { rotate: 0, type: "location" }
        }
      ]
    }
  });

  mapObject.addLayer({
    id: pointId,
    source: pointId,
    type: "symbol",
    layout: {
      "icon-image": "location",
      "icon-size": 0.15,
      "icon-rotate": ["get", "rotate"],
      "icon-allow-overlap": true
    }
    /*
    paint: {
      "circle-radius": 10,
      "circle-color": "#007cbf"
    } */
  });

  mapObject.on("click", pointId, function(e) {
    mapObject.flyTo({ center: e.features[0].geometry.coordinates });
  });

  mapObject.on("mouseenter", pointId, function() {
    mapObject.getCanvas().style.cursor = "pointer";
  });

  mapObject.on("mouseleave", pointId, function() {
    mapObject.getCanvas().style.cursor = "";
  });
};
