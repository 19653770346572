import gql from "graphql-tag";

export default gql`
  query GetDriverScheduleByDriverId($id: ID!) {
    driverScheduleCurrentUpcoming(where: { driver: $id }) {
      driverschedules {
        id
        ridestatuses {
          id
          status
          created_at
          location
        }
        passengerschedule {
          id
          start
          stop
          stops
          savedStops {
            name
            type
            address
            lat
            long
            id
          }
          vehicleClass
          origin
          created_at
          type
          flightNumber
          terminal
          numPassengers
          numberOfVehicles
          bookingId
          billed
          notes
          cancelled
          documents
          transferservice {
            id
            name
          }
          savedDocuments {
            id
            size
            url
            name
            uploadedBy {
              id
              firstName
              lastName
            }
          }
          alerts {
            id
            type
            description
            status
          }
          event {
            id
            name
          }
          requiredVehicleClass {
            id
            name
            transferPricing
            trackTransferPricing
            fourHourPricing
            sixHourPricing
            twelveHourPricing
            twentyFourHourPricing
            maxPassengers
          }
          department {
            id
            name
            module {
              id
              name
            }
          }
          createdBy {
            id
            firstName
            lastName
            email
            phone
          }
          passenger {
            id
            firstName
            lastName
            email
            phone
            passengerPriority
            notes
          }
          additionalPassengers {
            id
            firstName
            lastName
            fullName
            email
            phone
            passengerPriority
            notes
          }
          from {
            id
            name
            address
            lat
            long
          }
          to {
            id
            name
            address
            lat
            long
          }
        }
      }
    }
  }
`;
