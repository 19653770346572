import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useStyles } from "./Tabs.styles";
import { mobileViewBreakpoint } from "../../App.helper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./Tabs.css";
import _ from "lodash";

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

function TabContainer(props) {
  return (
    <div style={{ padding: props.noPadding ? 0 : 8 * 3 }}>{props.children}</div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const SimpleTabs = ({
  tabs,
  noBg,
  noPadding,
  selectedTab,
  onChangeTab,
  scrollable,
  conditionalTabsLoading,
  dependencies = [],
}) => {

  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const [value, setValue] = useState(selectedTab || tabs[0].slug);
  const [currentTabs, setCurrentTabs] = useState(tabs);

  useEffect(() => setValue(selectedTab || tabs[0].slug), [selectedTab]);

  useEffect(() => {

    if (conditionalTabsLoading) return;

    const loadedTabs = tabs.map(tab => {
      if(tab.isConditional && tab.condition) return {
        label: tab.label,
        slug: tab.slug,
        component: tab.conditionalComponent,
      };

      if (tab.isConditional && !tab.condition) return null;

      return tab;
    }).filter(tab => tab !== null);

    setCurrentTabs(loadedTabs);


  }, [conditionalTabsLoading, ...dependencies])

  const handleChange = (event, value) => {
    if (onChangeTab) {
      onChangeTab(value);
    } else setValue(value);
  };

  return (
    <div>
      <div className={classes.root}>
        <Paper
          className={classes.root}
          style={{ boxShadow: noBg ? "none" : "" }}
        >
          <Tabs
            {...(scrollable
              ? { variant: "scrollable", scrollButtons: "on" }
              : null)}
            value={value}
            onChange={handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator
            }}
          >
            
            {_.filter(currentTabs, tab => {
              if(isMobileView) return tab.slug !== 'dispatch'
              return true
            }).map(tab => (

              <Tab
                key={tab.slug}
                value={tab.slug}
                disableRipple
                label={tab.label}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                {...(scrollable ? a11yProps(tab.slug) : null)}
              />
              ))}
          </Tabs>

          {currentTabs
            .filter(x => value === x.slug)
            .map((tab, index) => (
              <>
                {scrollable ? (
                  <TabPanel value={value} hidden={value !== tab.slug} index={index}>
                    <div>{tab.component}</div>
                  </TabPanel>
                ) : (
                  value === tab.slug && (
                    <TabContainer
                      hidden={value !== tab.slug}
                      {...{ noPadding }}
                    >
                      {tab.component}
                    </TabContainer>
                  )
                )}
              </>
            ))}
        </Paper>
      </div>
    </div>
  );
};

export default SimpleTabs;
