import {editActionButtonSelector,deleteActionButtonSelector}from '../../../../App.helper'

export const getMobileMenuItems = ({row}) => {
    return {
      left: [
        {
          text: " ",
          onClick: () => {
            const clickedButton = document.querySelector("#"+deleteActionButtonSelector + row.id)
          if(clickedButton) clickedButton.click()
          },
          className: "mobile-actions-delete"
        }
      ],
      right: [
        {
          text: " ",
          onClick: () => {
            const clickedButton = document.querySelector('#'+editActionButtonSelector + row.id)
          if(clickedButton) clickedButton.click()
          },
          className: "mobile-actions-edit"
        }
      ]
    }
  }
  