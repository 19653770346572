import React from "react";
import Typography from "components_v2/Typography/Typography";
import _ from "lodash";
const EmailDisplay = ({ email }) => {
    const getEmailToDisplay = () => {
        const isNoEmail = _.includes(email, '@noemail');
        if (isNoEmail) {
            return 'Not Specified';
        }
        else {
            return email;
        }
    }
    return (
        <Typography customVariant="bodySmallRegular">{getEmailToDisplay()}</Typography>
    )
}
export default EmailDisplay;