import React from 'react';

import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import ListModal from 'components_v2/ListModal/ListModal';
import GetAllUsers from 'gql/Users/GetAll';
import { withRouter } from 'react-router-dom';
import EmailDisplay from '../../../../EmailDisplay/EmailDisplay'
const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 3,
    titleWithText: true,
    render: (data) => `${data.firstName} ${data.lastName}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 3,
    titleWithText: true,
    render: (row) =>(
      <EmailDisplay email={row.email}></EmailDisplay>),
  },
  {
    field: 'phone',
    headerName: 'Phone number',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 3,
    titleWithText: true,
    render: (data) => (data.role ? data.role.name : ''),
  },
];

const TeamMemberSelector = ({ onDone, handleNewTeamMemberClick, incomingItems, customHandleDelete, index }) => (


  <ListModal
    title="Select team members"
    searchPlaceholder="Search for a team member"
    query={GetAllUsers}
    dataSelector={(data) => data?.usersConnection?.values || []}
    queryOptions={{ fetchPolicy: 'no-cache', variables: { filterKey: "" } }}
    mainAction={{
      label: 'Done',
      onClick: onDone,
    }}
    secondaryAction={{
      label: 'Create a new team member',
      onClick: handleNewTeamMemberClick(index),
    }}
    customHandleDelete={customHandleDelete}
    incomingItems={incomingItems}
    columns={columns}
    renderSelectionColumns={columns}
    onModalClick={(toggleModal) => (
      <SelectActionButton onClick={toggleModal}>
        Select Team Members
      </SelectActionButton>
    )}
  />
);
export default withRouter(TeamMemberSelector);
