import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const DynamicListSkeletonSidebar = () => {
  return (
    <div style={{marginTop: "16px"}}>
      <div style={{display: "flex", justifyContent: "space-between", padding:15}}>
      <Skeleton
        variant="circle"
        width={30}
        height={30}
      />
       <Skeleton
        variant="rect"
        width={"40%"}
        height={30}
        style={{ borderRadius: 6 }}
      />
      <Skeleton
        variant="circle"
        width={30}
        height={30}
      />
      </div>
      <Skeleton
        variant="rect"
        width={"90%"}
        height={44}
        style={{ borderRadius: 6, margin: "16px auto" }}
      />
      <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
      <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
     
      <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
      <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
       <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
     
      <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
      <Skeleton
        variant="rect"
        width={"90%"}
        height={54}
        style={{ borderRadius: 6, margin: "8px auto" }}
      />
    </div>
  );
};

export default DynamicListSkeletonSidebar;
