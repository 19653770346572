import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  container: ({ mainAction, secondaryAction, disableLayout }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: !mainAction && !secondaryAction ? 0 : 104,
    boxSizing: 'border-box',
    flexGrow: disableLayout ? 1 : 'initial',
    width: '100%',
    position: 'relative',
    padding: '0 88px 44px 88px',

    top: disableLayout ? -96 : 'initial',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: 66,
      padding: 0,
      justifyContent: 'unset',
      overflow: 'auto',
    },
  }),
  layout: ({ pageView }) => ({
    background: '#FFF',
    width: pageView ? 1280 : 874,
    maxWidth: '100%',
    padding: 40,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      background: 'transparent',
      padding: 0,
    },
  }),
}));

export default useStyles;
