import React from "react";
import AlertTag from "components_v2/AlertTag/AlertTag";
import _ from "lodash";
import moment from "moment";
import PassengerNameDisplay from "components_v2/DisplayName/DisplayName";
import { getTimezoneOffset } from "../Inputs/Timezone/TimezoneHelper";
import { convertRideStatusToLabel } from "../../utils/passengerScheduleHelpers";

export const smsFooter = "\n\n- Team MOTUS | ONE RDE \n+1 (512) 787-1876";

export const allSteps = [
  {
    value: "scheduled",
    label: "Scheduled",
    color: "#7c7c7c",
    sms: true
  },
  {
    value: "driverConfirmed",
    label: "Driver Confirmed",
    color: "#37ff85"
  },
  {
    value: "enroute",
    label: "Enroute To Pickup",
    color: "#37ff85",
    sms: true
  },
  {
    value: "arrivedToPickup",
    label: "Arrived To Pickup",
    color: "#007400",
    sms: true
  },
  {
    value: "passengerInVehicle",
    label: "Passenger in Vehicle",
    color: "#0099ff"
  },
  {
    value: "arrivedToDestination",
    label: "Arrived to Destination",
    color: "#4001c5"
  },
  {
    value: "complete",
    label: "Complete",
    color: "#4001c5",
    sms: true
  }
];

export const getMarkers = values => {
  let markers = [];
  let stops = [];
  if (values?.savedStops) {
    stops = values.savedStops;
    if (typeof values.savedStops === "string")
      stops = JSON.parse(values.savedStops);
  } else if (values?.stops) {
    stops = values.stops;
    if (typeof values.stops === "string") stops = JSON.parse(values.stops);
  }

  if (values?.from) markers = [...markers, values.from];
  const stopsWithCoords = stops.filter(stop => stop.lat && stop.long);
  markers = [...markers, ...stopsWithCoords];
  if (values?.to && !values.to.name.includes("-As directed-"))
    markers = [...markers, values.to];

  return markers;
};

// Change status to category @bbrez
export const getAlertTag = alerts => {
  const filteredAlerts = alerts.filter(x => x.status === "unresolved");

  const latestAlert = filteredAlerts
    ? filteredAlerts[filteredAlerts?.length - 1]
    : null;

  return (
    (latestAlert && (
      <b className="alert-tag">
        <AlertTag
          label={latestAlert?.status}
          type={latestAlert?.type}
          description={latestAlert?.description}
        />
        {filteredAlerts?.length > 1 && (
          <>
            <small>+{filteredAlerts?.length - 1} more</small>
          </>
        )}
      </b>
    )) ||
    null
  );
};

export const getLeftBorderColor = row => {
  let alerts = [];

  if (row.cancelled) alerts = row.alerts;
  if (row.passengerScheduleCancelled || row.cancelled)
    alerts = row.passengerScheduleAlerts;
  const latestAlert = alerts[alerts.length - 1];

  if (latestAlert) {
    switch (latestAlert.type) {
      case "high":
        return "red";
      case "medium":
        return "blue";
      case "low":
        return "green";
      default:
        return null;
    }
  }
  return null;
};

export const getParsedSMSText = ({ step, data }) => {
  let smsText = "";
  switch (step.value) {
    case "scheduled":
      smsText =
        "Your ride from " +
        data?.from?.name +
        " is scheduled for " +
        moment.utc(data?.start).format("YYYY-MM-DD") +
        " at " +
        moment.utc(data?.start).format("HH:mm") +
        ". Reservation #" +
        data?.bookingId;
      break;

    case "enroute":
      smsText =
        "Your driver is on the way. \n\n" +
        "Your driver " +
        data?.driver?.firstName +
        " is driving a " +
        (data?.vehicle?.color ? data?.vehicle?.color + " " : "") +
        data?.vehicle?.make +
        ", licence plate " +
        data?.vehicle?.plateNumber +
        ".";
      break;

    case "arrivedToPickup":
      smsText = "Your Driver has arrived to " + data?.from?.name + ".";
      break;

    case "complete":
      smsText =
        "Thank you for riding with MOTUS | ONE. Please rate your driver. \n" +
        "https://app.motusone.com/leavefeedback/" +
        data?.id +
        "\n" +
        "We appreciate your feedback!";
      break;
    default:
      return null;
  }

  return smsText + smsFooter;
};

export const getCompletedSteps = ({ data }) => {
  return (
    (data?.ridestatuses &&
      data?.ridestatuses?.map(x => ({
        status: x.status,
        label: convertRideStatusToLabel(x.status),
        created_at: x.created_at
      }))) ||
    []
  );
};

export const getSentSMSs = ({ data }) => {
  const completedSteps = getCompletedSteps({ data });

  return _.filter(
    _.slice(allSteps, 0, getActiveStepIndex(completedSteps)),
    "sms"
  );
};
let activeStepIndex;
export const getActiveStepIndex = completedSteps => {
  activeStepIndex = 1;
  while (activeStepIndex < allSteps.length) {
    const foundStepFromData = _.find(
      completedSteps,
      step => step.status === allSteps[activeStepIndex].value
    );
    if (!foundStepFromData) {
      break;
    }
    activeStepIndex++;
  }

  return activeStepIndex;
};

export const getStatusValue = data => {
  if (data?.passengerScheduleCancelled || data.cancelled) return "cancelled";

  const bookingStatus = data.ridestatuses;
  if (bookingStatus && !bookingStatus[0]) {
    if (data?.driver) return "driverAssigned";

    return "scheduled";
  }

  const activeStepIndex = getActiveStepIndex(bookingStatus);

  return allSteps[activeStepIndex - 1].value;
};

export const getStatusLabelWithTime = ({ status, completedSteps, data }) => {
  const lastStatus = _.findLast(completedSteps, step => {
    return step.status === status.value;
  });

  if (status.value === "scheduled" && data)
    return `${moment(data.created_at).format("HH:MM")} - ${status.label}`;
  if (lastStatus)
    return `${moment(lastStatus.created_at).format("HH:MM")} - ${status.label}`;

  return status?.label;
};

export const getStatusLabel = data => {
  if (data?.passengerScheduleCancelled || data.cancelled) return "CANCELLED";

  const bookingStatus = data.ridestatuses;
  if (bookingStatus && !bookingStatus[0]) {
    if (data?.driver) return "Driver assigned";

    return "Scheduled";
  }

  const activeStepIndex = getActiveStepIndex(bookingStatus);

  return allSteps[activeStepIndex - 1].label;
};

export const getStatusColor = data => {
  if (data?.passengerScheduleCancelled || data.cancelled)
    return "rgb(197, 2, 2)";

  const bookingStatus = data.ridestatuses;
  if (!bookingStatus[0]) {
    if (data.driver) return "#ff00ff";
    return "#7c7c7c";
  }

  const activeStepIndex = getActiveStepIndex(bookingStatus);

  return allSteps[activeStepIndex - 1].color;
};
export const getIsCancelled = data => {
  return data?.cancelled || data?.passengerschedule?.cancelled;
};
export const getColumns = (projectId, isTimezoneVisible, isMobileView) => [
  {
    field: "start",
    headerName: "Pickup time",
    type: "time",
    width: (isMobileView && 4) || 1,
    fixedMaxWidth: 140,
    render: data => {
      return (
        <span>
          {moment.utc(data.start).format("HH:mm")}
          {isTimezoneVisible && (
            <>
              <br />
              UTC{" "}
              {getTimezoneOffset(data?.start, data?.event?.timezone || "UTC")}
            </>
          )}
        </span>
      );
    }
  },

  ...(projectId
    ? []
    : [
        {
          field: "event.name",
          headerName: "Project",
          width: 1,
          render: data => <b>{data.event && data.event.name}</b>
        }
      ]),
  {
    field: "status",
    headerName: "Status",
    width: (isMobileView && 6) || 1,
    render: data => <b>{getStatusLabel(data)}</b>
  },

  /*
  {
    field: "bookingId",
    headerName: "Booking ID",
    width: 2,
    render: data => <b>{data.bookingId}</b>,
    hideMobile: true
  },*/

  {
    field: "passenger.firstName",
    headerName: "Booking",
    width: (isMobileView && 6) || 1,

    render: data => (
      <>
        <b>#{data.bookingId}</b> <br />{" "}
        <small>
          <PassengerNameDisplay passenger={data?.passenger} />{" "}
          {data.numPassengers > 1 && <>(+{data.numPassengers - 1} pax)</>}
        </small>
      </>
    )
  },
  {
    field: "start.name",
    headerName: "Pick up Location",
    width: (isMobileView && 6) || 3,
    hideMobile: true,
    render: data => (
      <>
        <b>{data?.from?.name}</b>
        <br />
        <small>{data?.from?.address}</small>
      </>
    )
  },
  {
    // Need something different for field, can't sort 3 levels deep
    field: "driverschedules.driver.firstName",
    headerName: "Driver Name",
    width: (isMobileView && 6) || 1,
    hideMobile: true,
    render: data => {
      return (
        (data.driver && (
          <>
            <b>{`${data.driver.firstName} ${data.driver.lastName}`}</b> <br />{" "}
            <small>{data.driver.phone}</small>
          </>
        )) ||
        "Not Assigned"
      );
    }
  },
  // {
  //   field: "driverNumber",
  //   headerName: "Driver Number",
  //   width: (isMobileView && 6) || 1,
  //   hideMobile: true,
  //   render: data => data?.driver?.phone
  // },
  {
    field: "department.name",
    headerName: "Department",
    width: (isMobileView && 6) || 1,
    hideMobile: true,
    render: data => {
      return data?.department?.name;
    }
  },
  {
    field: "alert",
    headerName: "Alert",
    width: 2,
    hideMobile: true,
    noTypographyWrapper: true,
    render: data => {
      return getAlertTag(data.alerts);
    }
  }
];

export const getLabel = ({ projectId, localData }) => {
  return !projectId
    ? "Event"
    : localData?.department?.module?.name
    ? "Module"
    : "Event";
};
export const getText = ({ projectId, localData }) => {
  return !projectId
    ? localData?.event?.name
    : localData?.department?.module?.name || localData?.event?.name;
};

const additionalPassengersFormatted = localData => {
  return localData?.additionalPassengers
    ? localData?.additionalPassengers?.map(passenger => ({
        name: passenger?.fullName,
        phone: passenger?.phone,
        firstName: passenger?.firstName,
        lastName: passenger?.lastName,
        email: passenger?.email
      }))
    : [];
};
export const passengerListFormatted = ({ localData }) => {
  return [
    {
      name: localData?.passenger?.fullName,
      phone: localData?.passenger?.phone,
      firstName: localData?.passenger?.firstName,
      lastName: localData?.passenger?.lastName,
      email: localData?.passenger?.email
    },
    ...additionalPassengersFormatted(localData)
  ];
};
export const getIsMarkerMapVisible = ({
  isDispatchPage,
  isSchedulePage,
  isTrackRide
}) => {
  return !isDispatchPage && !isSchedulePage && !isTrackRide;
};
export const getIsBookingDetailsFooterVisible = ({
  isDispatchPage,
  isSchedulePage,
  isTrackRide
}) => {
  return isDispatchPage || isSchedulePage || isTrackRide;
};
export const getIsMainPassengerError = ({ touched, errors }) => {
  return touched.mainPassenger && errors.mainPassenger;
};
export const getIsModuleError = ({ touched, errors }) => {
  return touched.module && errors.module;
};
export const getIsAdditionalPassengersError = ({ touched, errors }) => {
  return touched.additionalPassengers && errors.additionalPassengers;
};
export const getIsDispatchOrScheduleOrTrackRidePage = ({
  isDispatchPage,
  isSchedulePage,
  isTrackRide
}) => {
  return isDispatchPage || isSchedulePage || isTrackRide;
};

export const getData = ({ isPublic, currentUser }) => {
  return isPublic ? [currentUser] : undefined;
};
export const getValueType = ({ values }) => {
  return values?.from?.type?.toLowerCase() === "airport";
};
export const getOpenTab = ({ openTab, currentTab }) => {
  return openTab === currentTab;
};
export const getIsMapView = ({ withMapView, isMapStatic }) => {
  return withMapView && !isMapStatic;
};
export const getIsDepartments = ({ selectedModule }) => {
  return selectedModule?.departments;
};
export const getTitleIsUpdate = ({ isUpdate }) => {
  return isUpdate ? "Assign driver" : "Assign drivers";
};
export const isGetError = ({ touched, errors }) => {
  return touched.arrivalDate && errors.arrivalDate;
};
export const getVehiclesDetails = ({ vehicles }) => {
  return vehicles[0].vehicleId || vehicles[0]?.driverInformation?.vehicle?.id;
};
export const getDriverDetails = ({ vehicles }) => {
  return vehicles[0]?.driverInformation?.driver?.id || null;
};
export const getNoResultsText = ({ type }) => {
  return (type === "History" && "No bookings") || "No upcoming bookings";
};
export const getIsLoading = ({
  isLoading,
  loading,
  overTimeLoading,
  assignDriverLoading,
  lazyLoading,
  isDeleteLoading,
  isCancelLoading,
  bookingUpdatesLoading,
  sendEmailLoading,
  sendDriverLinkLoading
}) => {
  return (
    isLoading ||
    loading ||
    overTimeLoading ||
    assignDriverLoading ||
    lazyLoading ||
    isDeleteLoading ||
    isCancelLoading ||
    bookingUpdatesLoading ||
    sendEmailLoading ||
    sendDriverLinkLoading
  );
};

export const getNumberOfVehicles = ({ data }) => {
  return data.numberOfVehicles ? data.numberOfVehicles : "1";
};
export const isNumberOfVehicleError = ({ touched, errors }) => {
  return touched.numberOfVehicles && errors.numberOfVehicles;
};
export const getIncomingItems = ({ values }) => {
  return values?.supplier?.id ? [values?.supplier?.id] : [];
};
export const getIsDepartmentError = ({ touched, errors }) => {
  return touched.department && !!errors.department;
};
export const getRideStatuesConfirmed = ({ x }) => {
  return (
    x?.ridestatuses?.length > 0 &&
    x?.ridestatuses[0]?.status === "driverConfirmed"
  );
};
