import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import {Clear} from '../../Icons/Icons';

const useStyles = makeStyles(() => ({
  contentTag: {
    background: 'rgba(64, 1, 197, 0.05)',
    border: '1px solid rgba(0, 0, 0, 0.02)',

    '& .MuiChip-label': {
      fontWeight: '900',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.3px',
      textTransform: 'uppercase',
      color: '#000000',
    },

    '& .MuiSvgIcon-root': {
      fill: 'rgba(0, 0, 0, 0.6)',
      width: '12px',
      height: '12px',
    },
    '&:hover, &:focus, &:active': {
      background: 'rgba(64, 1, 197, 0.05)',
      border: '1px solid rgba(64, 1, 197, 0.6)',
    },
  },
}));

export default function ContentTag({ tag, onDelete }) {
  const classes = useStyles();

  return (
    <Chip
      label={_.values(tag)[0]}
      onDelete={() => onDelete(tag)}
      className={classes.contentTag}
      deleteIcon={<Clear />}
    />
  );
}
