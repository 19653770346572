import React from 'react';
import PropTypes from 'prop-types';

import DynamicList from 'components_v2/List/DynamicList';
import { withRouter } from 'react-router-dom';
import MarkerMap from 'components_v2/Map/MarkerMap';
import { getHamburgerMenuItems, getColumns, actions, getMobileMenuItems } from './ListOptions';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from '../../App.helper';

const LocationsComponent = ({ history, data, loading, projectId, listConfig, count, handleListConfigChange }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  return (
    <>
      <DynamicList
        listConfig={{ ...listConfig, count }}
        onListConfigChange={handleListConfigChange}
        isMobileView={isMobileView}
        showHeader
        loading={loading}
        data={data}
        title="Locations"
        noResultsText="No location records"
        numRows={count}
        enableFreezeHeader
        headerFreezeLeftContent="Locations"
        hamburgerMenuItems={getHamburgerMenuItems({ history, projectId })}
        getMobileMenuItems={(row) =>
          getMobileMenuItems({ history, projectId, row })
        }
        columns={getColumns(isMobileView)}
        actions={actions}
        detailPanel={(row) => {
          const markers =
            (row.children &&
              row.children.map(({ lat, long, name, address }) => ({
                lat,
                long,
                name,
                address,
              }))) ||
            [];

          const { lat, long, name, address } = row;
          markers.push({ lat, long, name, address });

          return (
            <div style={{ height: 500 }}>
              <MarkerMap displayPopups markers={markers} />
            </div>
          );
        }}
      />
    </>
  );
}
LocationsComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRouter(LocationsComponent);
