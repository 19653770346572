import React from 'react';
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';

import useStyles from './DayViewTimeScaleLabel.style';
import { formatTimeScaleDate } from '../helpers';

const DayViewTimeScaleLabel = ({ ...restProps }) => {
  const classes = useStyles();
  const labelClass = restProps.time
    ? classes.timeScaleLabel
    : classes.emptyTimeScaleLabel;

  return (
    <DayView.TimeScaleLabel
      className={labelClass}
      {...restProps}
      formatDate={formatTimeScaleDate}
    />
  );
};

export default DayViewTimeScaleLabel;
