import React, { useEffect } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "components_v2/Typography/Typography";
import AssignDriver from "components_v2/Bookings/AssignDriver/AssignDriver";
import { MoreHorizontal } from "../Icons/Icons";
import Divider from "../Divider/Divider";
import { eventStopPropagation } from "../../App.helper";

const useStyles = makeStyles({
  menuPaper: {
    transform: "translateY(-4px) !important",
    borderRadius: 6,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderTopRightRadius: 0,

    boxShadow: " 0px 8px 28px rgba(0, 0, 0, 0.2)"
  },
  menuItem: {
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
  iconButtonRoot: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: 6,
    padding: "4.5px 6.5px",
    backgroundColor: "#fff"
  },
  iconRoot: {
    color: "#111"
  },
  dividerWrapper: {
    opacity: 0.1,
    margin: "8px 0"
  }
});

export default function SimpleMenu({
  render,
  items = [],
  row,
  onMenuClose,
  onMenuClick,
  className,
  isMenuOpened,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    if (onMenuClick) onMenuClick(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
   
    setAnchorEl(null);
    if (onMenuClose) onMenuClose();
  };

  useEffect(() => {
    if (typeof items === "function") {
      if (!isMenuOpened) {
        handleClose();
      }
    }
  }, [isMenuOpened]);

  const classes = useStyles();

  const anchorOrigin = {
    vertical: "bottom",
    horizontal: typeof items === "object" && "right"
  };

  const transformOrigin = {
    vertical: "top",
    horizontal: typeof items === "object" && "right"
  };

  const menuButton = render ? (
    render(handleClick)
  ) : (
    <IconButton
      className={classes.iconButtonRoot}
      onClick={e => {
        eventStopPropagation({ event: e })
        handleClick(e);
      }}
    >
      <MoreHorizontal className={classes.iconRoot} fontSize="small" />
    </IconButton>
  );

  const values = {
    drivers: [
      { vehicle: row?.vehicle?.id || null, driver: row?.driver?.id || null }
    ]
  };

  return (
    <>
      <>{menuButton}</>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={(e) =>{eventStopPropagation({ event: e }); handleClose()}}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={{ paper: className || classes.menuPaper }}
      >
        {typeof items === "function" && items()}
        {typeof items === "object" &&
          items.map((item, index) => {
            if (item.isDivider) {
              return (
                <div key={index} className={classes.dividerWrapper}>
                  <Divider />
                </div>
              );
            }
            if (item.isAssignDriver) {
              return (
                <AssignDriver
                  // isHelpTutorial={isHelpTutorial}
                  singleSelection
                  noResults
                  loadDataOnRender={false}
                  projectId={item.projectId}
                  passengerScheduleStart={item.start}
                  passengerScheduleType={item.type}
                  values={values}
                  customButton={toggleModal => (
                    <MenuItem
                      key={item.label}
                      onClick={e => {
                        eventStopPropagation({ event: e })
                        toggleModal();
                        handleClose();
                      }}
                      classes={{ root: classes.menuItem }}
                    >
                      <Typography customVariant="bodySmallRegular">
                        {item.label}
                      </Typography>
                    </MenuItem>
                  )}
                  onDone={async vehicles => {
                    item.onDone(vehicles, row);
                  }}
                />
              );
            }
            if (item.render) {
              return <div key={index} onClick={() => {
                handleClose();
              }}>{item.render()}</div>
            }
            return (
              <MenuItem
                key={item.label}
                onClick={e => {
                  eventStopPropagation({ event: e })
                  item.onClick(row);
                  handleClose();
                }}
                classes={{ root: classes.menuItem }}
              >
                <Typography customVariant="bodySmallRegular">
                  {item.label}
                </Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
}
