import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles(() => ({
  badge: {
    right: 4,
    top: 13,
    border: 0,
    padding: '0 4px',
    background: '#C50202',
    fontSize: 8,
    lineHeight: '9px',
    color: '#fff',
  },
}))(Badge);

const StyledBadgeDefault = withStyles(() => ({
  badge: {
    border: 0,
    padding: '0 4px',
    background: '#C50202',
    fontSize: 8,
    lineHeight: '9px',
    color: '#fff'
  },
}))(Badge);

export default function CustomizedBadges({
  value,
  position,
  children,
  isZeroShown,
  style
}) {
  if (position === 'top') {
    return (
      <StyledBadge badgeContent={value} showZero={isZeroShown} style={style}>
        {children}
      </StyledBadge>
    );
  }

  return (
    <StyledBadgeDefault badgeContent={value} showZero={isZeroShown} style={style}>
      {children}
    </StyledBadgeDefault>
  );
}
