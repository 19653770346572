import gql from 'graphql-tag';

export default gql`
  query GetAllEventsFilterUsers($id: ID!) {
    events {
      id
      name
      start
      end
      inChargeName
      inChargeEmail
      inChargeMobile
      client
      archived
      userevents(where: { user: { id: $id } }) {
        id
        role
        user {
          id
          email
          isSuperAdmin
          role {
            id
            name
          }
        }
        accessRight {
          id
          name
        }
      }
    }
  }
`;
