import gql from "graphql-tag";

export default gql`
  query GetDriver($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      confirmed
      company
      identifier
      username
      notes
      passengerPriority
      profileImage
      profileImageThumbnail
      arrivalFlightNumber
      arrivalDate
      departureFlightNumber
      departureDate
      hotel {
        id
      }
      role {
        id
      }
      assignedVehicles {
        vehicle {
          id
          make
          model
          year
          vehicleNumber
          type
        }
      }
    }
  }
`;
