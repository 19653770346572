import gql from "graphql-tag";

export default gql`
  query GetAllTransferServicesByEvent($id: ID) {
    transferservices(where: { event: $id }, sort: "duration:asc") {
      id
      name
      duration
      enabled
      event {
        id
        name
      }
    }
  }
`;
