import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../App.helper";

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    "& > button": {
      position: "relative",
      bottom: "40px",
      padding: 0,
    },
  },
  timeline: {
    padding: 0,
    marginBottom: 0,
    "& .MuiTimelineItem-content": {
      paddingRight: 0,
      top: "-38px",
      position: "relative",
    },
    "& > li::before": {
      padding: 0,
      content: "none",
    },
    "& .MuiTimelineConnector-root": {
      width: 0,
      flexGrow: 1,
      backgroundColor: "transparent",
      borderLeft: "1px dashed #3C3C3C",
    },
    "& .MuiTimelineDot-root": {
      backgroundColor: "#3C3C3C",
      borderWidth: "3px",
      position: "relative",
      left: " 0.5px",
    },
  },
  newLocationContent: {
    marginLeft: "0px",
    padding: "0px",
  },
  stopInputContainer: {
    position: "relative",
    "&:hover > button": {
      opacity: 1,
      transform: "translateY(0)",
    },
    "& > button": {
      position: "absolute",
      bottom: -30,
      right: 0,
      opacity: 0,
      transform: "translateY(5px)",
      transition: "all 100ms ease-in-out",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },

  stopsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "0px!important",
    "& li": {
      width: "49%",

      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        width: "100%",
        marginBottom: "12px",
      },
      "&:before": {
        content: "none",
      },
    },
  },
}));

export default useStyles;
