import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Typography from "components_v2/Typography/Typography";
import React from "react";
import { withRouter } from "react-router-dom";
import StickyHeader from "components_v2/StickyHeader/StickyHeader";
import clsx from "clsx";
import classNames from "classnames";

const BasicListHeader = withRouter(
  withStyles({
    root: {
      color: "rgba(255, 255, 255, 0.75)",
      paddingLeft: 10
    },
    listItemText: {
      fontSize: 10,
      color: "rgba(255, 255, 255, 0.45)",
      paddingLeft: 0,
      textTransform: "uppercase"
    },
    listItemTextRoot: { paddingLeft: "15px !important" },
    header: {
      background: "rgba(0, 0, 0, 0.02)",
      borderRadius: 5,
      marginTop: 8,
      marginBottom: 16,
      position: "sticky"
    },
    headerScroll: {
      background: "#F7F7F8",
      position: "sticky",
      width: "100%",
      top: 60,
      zIndex: "99!important",
      left: 0
    },
    stickyHeaderTop: {
      position: "sticky",
      top: 0,
      padding: "20px",
      opacity: 0,
      visibility: "hidden",
      transition: "all ease 0.2s",
      zIndex: 99
    },
    stickyHeaderScroll: {
      opacity: 1,
      visibility: "visible"
    },
    headerItem: {
      padding: "0px 0px 0px 2px",
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "16px",
      letterSpacing: "0.3px",
      wordBreak: "break-word",
      maxWidth: "100%",
      flex: "1 1 0%"
    },
    sortableColumn: {
      display: "flex",
      alignItems: "center",

      "& svg": {
        width: 16,
        height: 16,
        display: "none"
      }
    },
    currentSortedColumn: {
      color: "#4001C5",

      "& svg": {
        display: "block"
      }
    }
  })(props => {
    const {
      classes,
      columns,
      hideActions = false,
      mainColumnsWidth,
      isMobileView = false,
      enableFreezeHeader = false,
      headerFreezeLeftContent,
      headerFreezeRightContent,
      scrollTrigger,
      sortActions
    } = props;

    return (
      <>
        {enableFreezeHeader && scrollTrigger && (
          <StickyHeader
            leftContent={headerFreezeLeftContent}
            rightContent={headerFreezeRightContent}
            publicUrl
            sticky
            containerClass={
              scrollTrigger
                ? clsx(classes.stickyHeaderScroll, classes.stickyHeaderTop)
                : classes.stickyHeaderTop
            }
          />
        )}

        <div
          className={
            enableFreezeHeader && scrollTrigger
              ? classes.headerScroll
              : classes.header
          }
          style={{
            padding:
              hideActions || isMobileView
                ? "20px 12px"
                : enableFreezeHeader && scrollTrigger
                ? "12px 14px"
                : "12px 14px",
            top: !headerFreezeLeftContent && 0,
            background: "#f4f5f9",
            zIndex: "9"
          }} // dirty fix for proper header padding
        >
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={mainColumnsWidth}>
              <Grid container spacing={0} style={{ alignItems: "center" }}>
                {columns.map((column, index) => {
                  return (
                    <Box
                      key={index}
                      item
                      component={Grid}
                      md={column.width}
                      sm={3}
                      xs={3}
                      lg={column.width}
                      display={{
                        md: column.hideMobile ? "none" : "block",
                        sm: column.hideMobile ? "none" : "block",
                        xs: column.hideMobile ? "none" : "block",
                        lg: "block"
                      }}
                      className={classes.headerItem}
                      style={{
                        flex: (column.isFullWidth || isMobileView) && "1",
                        maxWidth: (column.isFullWidth || isMobileView) && "100%"
                      }}
                    >
                      <div
                        className={classNames({
                          [classes.sortableColumn]:
                            sortActions && column.isSortable !== false,
                          [classes.currentSortedColumn]:
                            column?.field === sortActions?.currentSort?.field &&
                            column.isSortable !== false
                        })}
                      >
                        {column.headerName}
                      </div>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>

            {!hideActions && !isMobileView && (
              <Grid item align="right" style={{ flexGrow: 1 }}>
                <Typography
                  customVariant="ctaBold"
                  style={{ justifyContent: "flex-end" }}
                >
                  Quick
                  <br />
                  actions
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </>
    );
  })
);

export default BasicListHeader;
