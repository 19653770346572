import { useMutation } from "@apollo/react-hooks";
import Button from "components_v2/Button/Button";
import SuccessCard from "components_v2/Cards/SuccessCard/SuccessCard";
import FullScreenPopover from "components_v2/FullScreenPopover/FullScreenPopover";
import Loading from "components_v2/Loading/CircularProgress";
import { Form, Formik } from "formik";
import CreateUser from "gql/Users/CreateUser";
import UpdateUser from "gql/Users/Update";
import React, { useState } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import auth from "utils/auth";
import useStyles from "./AddDriver.style";
import AddDriverForm from "./AddDriverForm";
import { getContinueBodyAction } from "utils/formHelpers";
import withFormState from "containers/Wrappers/withFormState";

import {
  formatIncomingData,
  formatSubmissionValues,
  validationSchema,
  mergeReturnValues
} from "./helpers";

const userInfo = auth.getUserInfo();

const getPreviousFormStateValues = previousFormState =>
  previousFormState?.values ? mergeReturnValues(previousFormState) : {};

const AddDriver = ({
  history,
  location,
  match,
  data,
  refetchUser,
  isPublicDriver,
  getPreviousFormState,
  setReturnValues
}) => {
  const classes = useStyles();
  const [userId, setUserId] = useState(null);
  const previousFormState = getPreviousFormState(location);
  const previousFormStateValues = getPreviousFormStateValues(previousFormState);

  const { projectId, supplierId } = match.params;
  const driverType = projectId ? "event" : "supplier";

  const initialInputValues = {
    autoGenPassword: "MOTUS!123",
    customPassword: "",
    generatePassword: true,
    willSendPassword: true,
    requirePasswordChange: false,
    updatePassword: false,
    sendPasswordEmail: userInfo?.email,
    role: 4,
    employmentType: "contractor",
    documents: [],
    firstName: "",
    lastName: "",
    supplier: supplierId || "",
    event: projectId || "",
    email: "",
    gender: "male",
    driverType,
    shift: {
      allowedHoursPerDay: null,
      shiftStart: null,
      shiftEnd: null,
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false
    },
    driverVehicles: [],
    breaks: [{ breakType: null, startBreak: null, endBreak: null }],
    isPublicDriver
  };

  const isUpdate = !!data;
  const initialValues = isUpdate
    ? {
        ...initialInputValues,
        ...formatIncomingData(data),
        ...previousFormStateValues
      }
    : { ...initialInputValues, projectId, ...previousFormStateValues };

  const successHeader = `Driver ${isUpdate ? "Updated" : "Created"}!`;
  const successDescription = `Congratulations, a driver has been ${
    isUpdate ? "updated" : "created"
  }.`;
  const title = isUpdate ? "Update an existing driver" : "Create a new driver";

  const [createUser, { loading: createMutationLoading }] = useMutation(
    CreateUser
  );

  const [updateUser, { loading: updateMutationLoading }] = useMutation(
    UpdateUser
  );

  const isLoading = createMutationLoading || updateMutationLoading;

  const handleSubmit = async (values, actions) => {
    const formattedValues = formatSubmissionValues(
      values,
      initialValues,
      isUpdate
    );

    if (isUpdate) {
      const id = data.id;
      const res = await updateUser({
        variables: {
          id: { id },
          data: formattedValues
        }
      });

      if (res?.data?.updateUser) {
        setUserId(res.data.updateUser.user.id);
        await refetchUser();
      }
    } else {
      const res = await createUser({
        variables: {
          data: formattedValues
        }
      });

      if (res?.data?.createUser) {
        const driver = res.data.createUser.user;
        setUserId(driver.id);
        setReturnValues(driver, previousFormState);
        actions.resetForm(initialValues);
      }
    }

    actions.setSubmitting(false);
  };

  const baseNavigationUrl =
    projectId || supplierId
      ? `${projectId ? "project" : "supplier"}/${projectId || supplierId}`
      : "public";

  let mainBodyActions;

  if (!_.isEmpty(previousFormState))
    mainBodyActions = [getContinueBodyAction(history, previousFormState)];
  else
    mainBodyActions = [
      {
        text: "Add Additional Drivers",
        onClick: () => {
          if (isUpdate) {
            const url = `/${baseNavigationUrl}/driver/new`;
            history.push(url);
          }
          setUserId(null);
        }
      }
    ];

  if (userId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={
            previousFormState
              ? null
              : {
                  text: "Setup Drivers",
                  onClick: () => {
                    const url = isPublicDriver
                      ? "/drivers"
                      : `/${baseNavigationUrl}/drivers`;
                    history.push(url);
                  }
                }
          }
          secondaryAction={
            previousFormState
              ? null
              : {
                  text: "Edit Driver",
                  onClick: () => {
                    if (!isUpdate) {
                      const url = `/${baseNavigationUrl}/driver/${userId}/edit`;
                      history.push(url);
                    }
                    setUserId(null);
                  }
                }
          }
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(isUpdate)}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {props => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={title}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save driver
                </Button>
              }
              pageView
            >
              <AddDriverForm
                {...props}
                classes={classes}
                isUpdate={isUpdate}
                projectId={projectId}
                supplierId={supplierId}
              />
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddDriver));
