import gql from 'graphql-tag';

export default gql`
  mutation CreateUserEvent($data: UsereventInput) {
    createUserevent(input: { data: $data }) {
      userevent {
        id
      }
    }
  }
`;
