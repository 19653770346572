import React, { useState } from "react";
import GetAllByEvent from "gql/ShuttleSchedule/GetAllByEvent";
import DeleteGQL from "gql/ShuttleSchedule/Delete";
import { useQuery, useMutation } from "@apollo/react-hooks";
import DynamicList from "components_v2/List/DynamicList";
import moment from "moment";
import { Calendar } from "components_v2/Icons/Icons";
import { CreateShuttleschedule, UpdateShuttleschedule } from "./gql";
import ScheduleDetails from "./ScheduleDetails";
import { mockData } from "./ScheduleContainer.mock";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  mobileViewBreakpoint,
  editActionButtonSelector,
  deleteActionButtonSelector
} from "../../../../App.helper";
import { getSortedShuttleShedules, getFilteredShuttleShedules } from "./helper";

const ScheduleContainer = ({ projectId, isHelpTutorial }) => {
  const eventId = projectId;
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const [listConfig, setListConfig] = useState({
    sort: "id:desc",
    pageSize: 10,
    startIndex: 0,
    filterKey: "",
    count: 0
  });

  let { data, loading, refetch } = useQuery(GetAllByEvent, {
    variables: {
      id: eventId,
      ...listConfig,
      sort: listConfig.sort || "id:desc"
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    skip: isHelpTutorial
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const shuttlesData = data?.shuttleschedulesConnection?.values || [];
  const count = data?.shuttleschedulesConnection?.aggregate?.count || 0;
  const handleListConfigChange = listConfig => {
    setListConfig(listConfig);
  };

  const shuttleSchedules = data ? data.shuttleschedules || [] : [];
  const storedLocations = data ? data.storedlocations || [] : [];
  const storedVehicles = data ? data.vehicles || [] : [];

  const [deleteSchedule] = useMutation(DeleteGQL);

  const [updateSchedule] = useMutation(UpdateShuttleschedule);

  const [createSchedule] = useMutation(CreateShuttleschedule);
  const [filterKey, setFilterKey] = useState("");
  const [sort, setSort] = useState(null);
  let finalData = getFilteredShuttleShedules({
    shuttleSchedules: shuttleSchedules,
    filterKey
  });
  finalData = getSortedShuttleShedules({ shuttleSchedules: finalData, sort });

  return (
    <DynamicList
      isMobileView={isMobileView}
      filterActions={[
        {
          type: "text",
          onChange: val => {
            setFilterKey(val);
          },
          label: "Search"
        }
      ]}
      sortActions={{
        currentSort: sort,
        onChangeSort: setSort
      }}
      title="Shuttle Schedule"
      showHeader
      noResultsText="No schedules"
      data={shuttlesData}
      enableFreezeHeader
      headerFreezeLeftContent="Shuttles"
      loading={loading && !finalData.length}
      numRows={count}
      listConfig={{ ...listConfig, count }}
      onListConfigChange={handleListConfigChange}
      getMobileMenuItems={row => {
        return {
          left: [
            {
              text: " ",
              onClick: () => {
                const clickedButton = document.querySelector(
                  "#" + deleteActionButtonSelector + row.id
                );
                if (clickedButton) clickedButton.click();
              },
              className: "mobile-actions-delete"
            }
          ],
          right: [
            {
              text: " ",
              onClick: () => {
                const clickedButton = document.querySelector(
                  "#" + editActionButtonSelector + row.id
                );
                if (clickedButton) clickedButton.click();
              },
              className: "mobile-actions-edit"
            }
          ]
        };
      }}
      columns={[
        {
          headerName: "Name",
          field: "name",
          width: 4
        },
        {
          field: "startDate",
          headerName: "Starts",
          type: "date",
          width: 4,
          render: ({ startDate }) =>
            moment.utc(startDate).format("dddd, MMMM D, YYYY")
        },
        {
          field: "endDate",
          headerName: "Ends",
          type: "date",
          width: 4,
          render: ({ endDate }) =>
            moment.utc(endDate).format("dddd, MMMM D, YYYY")
        }
      ]}
      customActions={[
        {
          onClick: async shuttleSchedule => {
            await createSchedule({
              variables: {
                data: {
                  name: shuttleSchedule.name,
                  startDate: moment
                    .utc(shuttleSchedule.startDate)
                    .format("YYYY-MM-DD"),
                  endDate: moment
                    .utc(shuttleSchedule.endDate)
                    .format("YYYY-MM-DD"),
                  event: eventId
                }
              }
            });

            await refetch();
          },
          label: "Duplicate",
          icon: Calendar
        }
      ]}
      actions={{
        edit: {
          onSave: async ({ id, values: { name, startDate, endDate } }) => {
            await updateSchedule({
              variables: {
                id: { id },
                data: {
                  name,
                  startDate: moment.utc(startDate).format("YYYY-MM-DD"),
                  endDate: moment.utc(endDate).format("YYYY-MM-DD")
                }
              }
            });

            await refetch();
          }
        },
        delete: {
          onClick: async ({ id }) => {
            await deleteSchedule({
              variables: {
                id
              }
            });

            await refetch();
          }
        },
        create: {
          onSave: async ({ values: { startDate, name, endDate, type } }) => {
            await createSchedule({
              variables: {
                data: {
                  name,
                  startDate: moment.utc(startDate).format("YYYY-MM-DD"),
                  endDate: moment.utc(endDate).format("YYYY-MM-DD"),
                  type: type?.value,
                  event: eventId
                }
              }
            });

            await refetch();
          }
        }
      }}
      detailPanel={({ shuttlescheduleitems, vehicles, name, id }) => (
        <ScheduleDetails
          name={name}
          vehicleItems={vehicles}
          vehicles={storedVehicles}
          shuttleScheduleItems={shuttlescheduleitems}
          locations={storedLocations}
          refetch={refetch}
          shuttleScheduleId={id}
        />
      )}
    />
  );
};

export default ScheduleContainer;
