import React from 'react';
import { InsertDriveFile } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Button from 'components_v2/Button/Button';
import Card from 'components_v2/Cards/Card/Card';

const EmptyStateCtaCard = ({
  headerText,
  description,
  icon,
  mainAction = {},
  noIcon,
  size = 'small',
}) => {
  const iconComponent = icon || (
    <InsertDriveFile
      style={{
        color: '#000',
        width: 40,
        height: 'auto',
      }}
    />
  );

  const iconElement = noIcon ? null : iconComponent;
  return (
    <Card
      size={size}
      headerText={headerText}
      description={description}
      icon={iconElement}
      iconBackground="light"
      mainAction={
        mainAction.component || (
          <Button onClick={mainAction.onClick} variant="contained">
            {mainAction.text}
          </Button>
        )
      }
    />
  );
};

const actionShape = {
  text: PropTypes.string.isRequried,
  onClick: PropTypes.func.isRequired,
};

const customeActionShape = {
  component: PropTypes.element.isRequired,
};

const actionPropTypes = [
  PropTypes.shape(actionShape),
  PropTypes.shape(customeActionShape),
];

EmptyStateCtaCard.propTypes = {
  headerText: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
  mainAction: PropTypes.oneOf(actionPropTypes),
  noIcon: PropTypes.bool,
  size: PropTypes.string,
};

export default EmptyStateCtaCard;
