import React from 'react';
import moment from 'moment';

import DatepickerInput from 'components_v2/Inputs/DatepickerInput/DatepickerInput';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import TimepickerInput from 'components_v2/Inputs/TimepickerInput/TimepickerInput';
import IconSelector from '../Inputs/IconSelector/IconSelector';
import Checkbox from 'components_v2/Inputs/CheckboxInput/CheckboxInput';
import { eventStopPropagation } from '../../App.helper';

const EditableField = (props) => {
  const { column, row, values, handleValueChange, customValueChange } = props;

  if (column.type === 'icon') {
    return (
      <IconSelector
        onChange={(event) => {
          handleValueChange(column, row, event.target.value);
        }}
        label={column.label || 'Select Icon +'}
        options={column.options}
        value={values[column.field]}
      />
    );
  }

  if (column.type === 'checkbox')
    return (
      <Checkbox
        onChange={(event) => {
          handleValueChange(column, row, event.target.value);
        }}
        value={values[column.field]}
        label={column.label}
      />
    );

  if (column.options || column.customSelect) {
    // const value =
    //   values[column.field].defaultValue || values[column.field];

    let value = null;
    if (values[column.field]) {
      value = {
        name: values[column.field].defaultValue || values[column.field],
        value: values[column.field].defaultValue || values[column.field],
      };
    }

    if (column.options) return (
      <SelectInput
        placeholder={`Enter ${column.headerName}`}
        value={value}
        noMargin
        onChange={(event) =>
          handleValueChange(column, row, event.target.value)
        }
        options={column.options}
      />
    );

    else return (
      <column.customSelect 
        placeholder={`Enter ${column.headerName}`}
        value={column.getValue ? column.getValue(values, column) : value}
        noMargin
        onChange={(event) =>
          {
            return column.handleChange ? customValueChange(column, row, event.target.value, column.handleChange) : handleValueChange(column, row, event.target.value);
          }
        }
      />
    );
  }

  if (column.type === 'date') {
    return (
      <DatepickerInput
        onClick={(e) => {
          eventStopPropagation({ event: e })
        }}
        noMargin
        value={values[column.field]}
        onChange={(event) =>
          handleValueChange(column, row, event.target.value)
        }
      />
    );
  }

  if (column.type === 'time') {
    return (
      <TimepickerInput
        noMargin
        value={
          values[column.field]
            ? moment(values[column.field], 'H:mm').toDate()
            : new Date()
        }
        onChange={(event) =>
          handleValueChange(
            column,
            row,
            moment(event.target.value).format('H:mm')
          )
        }
      />
    );
  }

  return (
    <TextInput
      onClick={(e) => {
        eventStopPropagation({ event: e })
      }}
      isDisabled={column.isDisabled}
      placeholder={`Enter ${column.headerName}`}
      value={values[column.field]}
      type={column.type === 'number' ? 'number' : undefined}
      noMargin
      onChange={(event) =>
        handleValueChange(column, row, event.target.value)
      }
    />
  );
};

export default EditableField;