import React from 'react';
import { Grid } from '@material-ui/core';

import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import { NumberInput } from 'components_v2/Inputs/NumberInput/NumberInput';
import DatepickerInput from 'components_v2/Inputs/DatepickerInput/DatepickerInput';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import VehicleTrackingTypeSelect from 'components_v2/Inputs/VehicleTrackingTypeSelect/VehicleTrackingTypeSelect';
import DriverTrackingTypeSelect from 'components_v2/Inputs/DriverTrackingTypeSelect/DriverTrackingTypeSelect';
import DocumentUpload from 'components_v2/Upload/UploadDocument';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';

const AddTrackingForm = ({ 
  values,
  setFieldValue,
  touched,
  errors,
  handleChange,
  isDriverTracking
}) => {


  const handleSelectChange = (e) => {
    setFieldValue('type', e.target.value);
  };

  const trackingTypeSelect = isDriverTracking
    ? <DriverTrackingTypeSelect
        label="Type"
        placeholder="Select tracking type"
        value={values.type}
        onChange={handleSelectChange}
        error={touched.type && errors.type}
      />
    : <VehicleTrackingTypeSelect
        label="Type *"
        placeholder="Select tracking type"
        value={values.type}
        onChange={handleSelectChange}
        error={touched.type && errors.type}
      />;

  return (
  <>
    <FormSection defaultExpanded title="Tracking details">
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          {trackingTypeSelect}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <DatepickerInput
            onClick={(e) => {
              e.stopPropagation();
            }}
            label="Date of service *"
            placeholder="Select a date"
            value={values.dateOfService}
            onChange={event => {
              setFieldValue('dateOfService', event.target.value);
            }}
            error={touched.dateOfService && errors.dateOfService}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <NumberInput
            label="Cost"
            placeholder="Enter the cost"
            value={values.cost}
            onChange={handleChange}
            name="cost"
            min={0}
            step="0.01"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <TextInput
            name="description"
            label="Description"
            placeholder="Enter a description"
            value={values.description}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </FormSection>
    <FormSection defaultExpanded title="Upload any supporting documents">
      <DocumentUpload
        documents={values?.documents}
        onDeleteCallback={(document) => {
          if (document.identifier) setFieldValue(document.identifier, null);
          else {
            const newDocuments = values.documents.filter(
              (loadedDocument) => loadedDocument.id !== document.id
            );
            setFieldValue('documents', newDocuments);
          }
        }}
        onComplete={async (document) => {
          setFieldValue(document.identifier, document);
        }}
      />
    </FormSection>
    <TotalErrors errors={errors} touched={touched} withSpacer />
  </>
  )
}

export default AddTrackingForm;
