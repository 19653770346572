import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  detailPanelContainer: {
    padding: 16,
    // background: '#fff',
  },
});

export default useStyles;
