import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";

import Typography from "components_v2/Typography/Typography";
import AddRemove from "components_v2/Inputs/AddRemove/AddRemove";
import ModuleSelector from "components_v2/Inputs/Custom/ModuleSelector";
import DatepickerInput from "components_v2/Inputs/DatepickerInput/DatepickerInput";
import FormSection from "components_v2/Inputs/FormSection/FormSection";
import { NumberInput } from "components_v2/Inputs/NumberInput/NumberInput";
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import TextAreaInput from "components_v2/Inputs/TextAreaInput/TextAreaInput";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import TimepickerInput from "components_v2/Inputs/TimepickerInput/TimepickerInput";
import TotalErrors from "components_v2/Inputs/TotalErrors/TotalErrors";
import VehicleClassPicker from "components_v2/Inputs/VehicleClassPicker/VehicleClassPicker";
import MarkerMap from "components_v2/Map/MarkerMap";
import StoredLocationTimeline from "components_v2/StoredLocationTimeline/StoredLocationTimeline";
import DocumentUpload from "components_v2/Upload/UploadDocument";
import { createLocationWithState } from "utils/routerHelpers";
import MultiDatePicker from "components_v2/Inputs/MultiDatePicker/MultiDatePicker";
import AssignDriver from "./AssignDriver/AssignDriver";
import { getMarkers } from "./BookingHelper";
import SelectPassengers from "./SelectPassengers/SelectPassengers";
import ProductSelector from "components_v2/Views/Internal/AddProject/ProductSelector/ProductSelector.Container";
import SelectSupplier from "./SelectSupplier/SelectSupplier";
import PhoneDisplay from "../PhoneDisplay/PhoneDisplay";
import {
  productSelectionColumns,
  productSelectedColumns
} from "./AddBooking.Config";
import EmailDisplay from "../EmailDisplay/EmailDisplay";
import {
  getIsMainPassengerError,
  isGetError,
  getIncomingItems,
  getIsDepartmentError,
  isNumberOfVehicleError,
  getIsDepartments,
  getTitleIsUpdate,
  getIsAdditionalPassengersError,
  getValueType,
  getData,
  getIsModuleError
} from "./BookingHelper";
import withFormState from "containers/Wrappers/withFormState";

const passengerColumns = [
  {
    field: "fullName",
    headerName: "Name",
    width: 2,
    titleWithText: true
  },
  {
    field: "email",
    headerName: "Email",
    width: 4,
    titleWithText: true,
    render: row => <EmailDisplay email={row.email}></EmailDisplay>
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true,
    render: data => <PhoneDisplay phone={data.phone}></PhoneDisplay>
  }
];

const passengerColumnsTiny = [
  {
    field: "fullName",
    headerName: "Name",
    width: 3,
    titleWithText: true
  },
  {
    field: "email",
    headerName: "Email",
    width: 6,
    titleWithText: true,
    render: row => <EmailDisplay email={row.email}></EmailDisplay>
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 3,
    titleWithText: true,
    hideMobile: true,
    render: data => <PhoneDisplay phone={data.phone}></PhoneDisplay>
  }
];

// eslint-disable-next-line complexity
const AddBookingForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  projectId,
  classes,
  isUpdate,
  history,
  location,
  isPublic,
  currentUser,
  noModuleOrDepartmentSection,
  noSupplierSection,
  noDocumentSection,
  serviceDurationOptions,
  setFormState
}) => {
  const markers = getMarkers(values);

  const handleProductSelectorDone = products => {
    setFieldValue("products", products);
  };

  const customHandleProductDelete = setSelectedItems => row => {
    const newProducts = values.products.filter(
      product => product.id !== row.id
    );

    setSelectedItems(newProducts.map(product => product.id));
    setFieldValue("products", newProducts);
  };

  const handleUpdatePassengers = newValues => {
    setFieldValue("passengers", newValues);
  };

  const handleNewPassengerClick = (isAdditionalPassengers) => () => {
    const { pathname, state } = createLocationWithState(
      `/project/${projectId}/passenger/new`,
      location,
      values,
      null,
      true,
      isAdditionalPassengers ? "additionalPassengers" : "mainPassenger"
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } });
  };

  const handleNewSupplierClick = () => {
    const { pathname, state } = createLocationWithState(
      `/supplier/new`,
      location,
      values,
      null,
      true,
      "supplier"
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } });
  };

  const customHandleSupplierDelete = setSelectedItems => () => {
    setFieldValue("supplier", null);
    setSelectedItems([]);
  };

  const customHandleMainPassengerDelete = setSelectedItems => () => {
    setFieldValue("mainPassenger", []);
    setSelectedItems([]);
  };

  const customHandleAdditionalPassengersDelete = setSelectedItems => row => {
    const newPassengers = values.additionalPassengers.filter(
      passenger => passenger.id !== row.id
    );
    const newPassengerIds = newPassengers.map(passenger => passenger.id);

    setFieldValue("additionalPassengers", newPassengers);
    setSelectedItems(newPassengerIds);
  };

  const setPreselectedVehicleClassValue = value => {
    setFieldValue("vehicleClass", value);
  };

  const handleDateChange = ({ target }) => {
    const { value, name } = target;
    setFieldValue(name, moment.utc(value).format("YYYY-MM-DD HH:mm:ss"));
  };

  const [selectedModule, setSelectedModule] = useState(null);

  const getDepartmentOptions = (selectedModule, values) => {
    const mapValues = department => ({
      name: department.name,
      value: department.id
    });

    if (getIsDepartments({ selectedModule }))
      return selectedModule.departments.map(mapValues);
    if (values?.department?.module)
      return values.department.module.departments.map(mapValues);
    return [];
  };

  const handleModuleChange = ({ target }) => {
    const { value } = target;
    setSelectedModule(value);
    setFieldValue("department", null);
    setFieldValue("module", value);
  };

  const getModuleFromDepartment = values => {
    const name = values?.department?.module?.name;
    const value = values?.department?.module?.id;

    return { name, value };
  };

  useEffect(() => {
    setFieldValue(
      "passengersPerVehicle",
      values.vehicleClass?.maxPassengers || 0
    );
  }, [values.vehicleClass?.maxPassengers]);

  return (
    <>
      <FormSection defaultExpanded title="Enter the booking information">
        <Grid className={classes.gridContainer} container spacing={3}>
          {!noModuleOrDepartmentSection && (
            <>
              <Grid item md={6} xs={12}>
                <ModuleSelector
                  name="module"
                  label="Module *"
                  placeholder="Select module"
                  value={
                    selectedModule ||
                    values.module ||
                    getModuleFromDepartment(values)
                  }
                  onChange={handleModuleChange}
                  projectId={projectId}
                  isError={getIsModuleError({ touched, errors })}
                  errorMessage={getIsModuleError({ touched, errors })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectInput
                  name="department"
                  label="Department *"
                  placeholder="Select department"
                  value={values.department}
                  onChange={handleChange}
                  options={getDepartmentOptions(selectedModule, values)}
                  isDisabled={
                    !selectedModule && !getModuleFromDepartment(values)
                  }
                  isError={getIsDepartmentError({ touched, errors })}
                  errorMessage={touched.department && errors.department}
                />
              </Grid>
            </>
          )}
          <Grid item md={6} xs={12}>
            {isUpdate && (
              <DatepickerInput
                name="arrivalDate"
                label="Pickup date *"
                placeholder="Select arrival date"
                value={moment.utc(values.arrivalDate)}
                onChange={handleDateChange}
                error={isGetError({ touched, errors })}
              />
            )}
            {!isUpdate && (
              <>
                <Typography
                  customVariant="bodySmallRegular"
                  style={{ marginBottom: 8, color: "#3C3C3C" }}
                >
                  Pickup date(s) *
                </Typography>
                <MultiDatePicker
                  selected={values.reocurringDates}
                  placeholder="Select pickup date(s)"
                  onDone={dates => {
                    // sort dates
                    dates.sort((a, b) => a - b);

                    // convert to UTC
                    const utcDates = dates.map(date => moment(date));

                    setFieldValue("reocurringDates", utcDates);
                  }}
                  error={touched.reocurringDates && errors.reocurringDates}
                />
              </>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <TimepickerInput
              name="arrivalTime"
              label="Pickup time *"
              placeholder="Select arrival time"
              value={moment.utc(values.arrivalTime)}
              onChange={handleDateChange}
              error={touched.arrivalTime && errors.arrivalTime}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <StoredLocationTimeline
              noStops={isPublic}
              values={values}
              handleChange={handleChange}
              projectId={projectId}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </Grid>
          <Grid md={12} sm={12} xs={12} className={classes.mapRow}>
            <MarkerMap showRoute markers={markers} />
          </Grid>
          {getValueType({ values }) && (
            <>
              <Grid item xs={12} className={classes.flightInfoTitle}>
                <Typography
                  customVariant="bodySmallRegular"
                  style={{ color: "#3C3C3C" }}
                >
                  Flight information
                </Typography>
              </Grid>

              <Grid item md={2} sm={4} xs={12}>
                <TextInput
                  name="flightNumber"
                  label="Flight number"
                  placeholder="Enter flight number"
                  value={values.flightNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={2} sm={4} xs={12}>
                <TextInput
                  name="terminal"
                  label="Terminal"
                  placeholder="Enter terminal"
                  value={values.terminal}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={8} sm={4} xs={0} className={classes.gridSpacer} />
            </>
          )}
        </Grid>
      </FormSection>

      <Grid container className={classes.gridContainer} spacing={3}>
        <Grid
          item
          className={classes.gridContainerPassenger}
          md={6}
          sm={6}
          xs={12}
        >
          <FormSection
            title="Select the passengers for this booking"
            height="507px"
          >
            <Grid item md={4} sm={6} xs={12} className={classes.passengerCount}>
              <NumberInput
                label="Number of passengers *"
                value={values.numPassengers}
                onChange={handleChange}
                name="numPassengers"
                min={0}
                error={touched.numPassengers && errors.numPassengers}
              />
            </Grid>
            <Grid item className={classes.vehicleInputItem} xs={12}>
              <Typography
                customVariant="bodySmallRegular"
                style={{ marginBottom: 8 }}
              >
                Main Passenger
              </Typography>
              <SelectPassengers
                singleSelection
                readOnlyResult={isPublic}
                values={values}
                customHandleDelete={customHandleMainPassengerDelete}
                error={getIsMainPassengerError({ touched, errors })}
                projectId={projectId}
                onDone={items => {
                  setFieldValue("mainPassenger", items);
                }}
                data={getData({ isPublic, currentUser })}
                columns={passengerColumns}
                renderSelectionColumns={passengerColumnsTiny}
                handleNewPassengerClick={handleNewPassengerClick(false)}
                handleUpdatePassengers={handleUpdatePassengers}
                incomingItems={values.mainPassenger.map(x => x?.id)}
              />
            </Grid>

            {!isPublic && (
              <Grid item className={classes.vehicleInputItem} xs={12}>
                <Typography
                  customVariant="bodySmallRegular"
                  style={{ marginBottom: 8 }}
                >
                  Additional Passengers
                </Typography>
                <SelectPassengers
                  values={values}
                  customHandleDelete={customHandleAdditionalPassengersDelete}
                  error={getIsAdditionalPassengersError({ touched, errors })}
                  projectId={projectId}
                  onDone={items => {
                    setFieldValue("additionalPassengers", items);
                  }}
                  columns={passengerColumns}
                  renderSelectionColumns={passengerColumnsTiny}
                  handleNewPassengerClick={handleNewPassengerClick(true)}
                  handleUpdatePassengers={handleUpdatePassengers}
                  incomingItems={values.additionalPassengers.map(x => x?.id)}
                />
              </Grid>
            )}
          </FormSection>
        </Grid>
        <Grid
          item
          className={classes.gridContainerDetails}
          md={6}
          sm={6}
          xs={12}
        >
          <FormSection title="Enter booking details">
            {!isUpdate && (
              <>
                <Grid item md={12} sm={12} xs={12}>
                  <NumberInput
                    name="numberOfVehicles"
                    label="Number of vehicles *"
                    value={values.numberOfVehicles}
                    onChange={handleChange}
                    min={0}
                    error={isNumberOfVehicleError({ touched, errors })}
                  />
                </Grid>
              </>
            )}

            <Grid
              Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.vehicleInputItem}
            >
              <VehicleClassPicker
                name="vehicleClass"
                label="Vehicle class *"
                placeholder="Select a vehicle type"
                value={values.vehicleClass}
                onChange={handleChange}
                projectId={projectId}
                error={touched.vehicleClass && errors.vehicleClass}
                setPreselectedValue={setPreselectedVehicleClassValue}
              />
            </Grid>

            <Grid
              Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.vehicleInputItem}
            >
              <SelectInput
                name="serviceDuration"
                label="Service duration *"
                placeholder="Select service duration"
                value={values.serviceDuration}
                onChange={handleChange}
                options={serviceDurationOptions}
                isError={touched.serviceDuration && !!errors.serviceDuration}
                errorMessage={touched.serviceDuration && errors.serviceDuration}
              />
            </Grid>

            <Grid
              Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.vehicleInputItem}
            >
              <TextAreaInput
                label="Notes"
                name="notes"
                value={values.notes}
                onChange={handleChange}
                placeholder="Enter notes"
              />
            </Grid>
          </FormSection>
        </Grid>
      </Grid>

      <Grid container className={classes.gridContainer} spacing={3}>
        {!noSupplierSection && (
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            className={classes.paddingTopBottomZero}
          >
            <FormSection
              title="Select a supplier for this booking"
              style={{ maxHeight: "400px" }}
            >
              <Grid item xs={12}>
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                  <SelectSupplier
                    singleSelection
                    values={values}
                    customHandleDelete={customHandleSupplierDelete}
                    error={touched.supplier && errors.supplier}
                    projectId={projectId}
                    onDone={suppliers => {
                      const [supplier = null] = suppliers;
                      setFieldValue("supplier", supplier);
                    }}
                    handleNewClick={handleNewSupplierClick}
                    incomingItems={getIncomingItems({ values })}
                  />
                </div>
              </Grid>
            </FormSection>
          </Grid>
        )}
        <Grid
          item
          className={classes.gridContainerDetails}
          md={6}
          sm={6}
          xs={12}
        >
          <FormSection title={getTitleIsUpdate({ isUpdate })}>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <AssignDriver
                singleSelection={isUpdate}
                loadDataOnRender
                maxDrivers={values?.numberOfVehicles}
                projectId={projectId}
                supplierId={values?.supplier?.id}
                passengerScheduleStart={values.start}
                passengerScheduleType={values.type}
                requiredVehicleClass={values?.vehicleClass}
                selectionDisabled={(row, selectedItems) => {
                  const foundItems = _.find(selectedItems, {
                    driver: row?.driverInformation?.driver?.id,
                    vehicle: row?.driverInformation?.vehicle?.id
                  });
                  if (foundItems || isUpdate) return false;
                  if (selectedItems.length === values.numberOfVehicles)
                    return true;
                  return false;
                }}
                setFieldValue={setFieldValue}
                values={values}
                incomingItems={values.drivers}
                customHandleSelection={(
                  selectedItems,
                  setSelectedItems
                ) => row => {
                  let newItems = [...selectedItems];
                  const selectedDriver = _.find(selectedItems, {
                    driver: row?.driverInformation?.driver?.id,
                    vehicle: row?.driverInformation?.vehicle?.id
                  });

                  if (selectedDriver) {
                    newItems = newItems.filter(x => {
                      return (
                        x.driver !== selectedDriver.driver ||
                        x.vehicle !== selectedDriver.vehicle
                      );
                    });
                  } else if (isUpdate) {
                    newItems = [
                      {
                        driver: row?.driverInformation?.driver?.id,
                        vehicle: row?.driverInformation?.vehicle?.id
                      }
                    ];
                  } else
                    newItems.push({
                      driver: row?.driverInformation?.driver?.id,
                      vehicle: row?.driverInformation?.vehicle?.id
                    });

                  setSelectedItems(newItems);
                }}
                customSelectionValue={(selectedItems, row) => {
                  const selectedDriver = _.find(selectedItems, {
                    driver: row?.driverInformation?.driver?.id,
                    vehicle: row?.driverInformation?.vehicle?.id
                  });

                  if (selectedDriver) return true;
                  return false;
                }}
                customHandleDelete={setSelectedItems => row => {
                  const newDrivers = values.drivers.filter(
                    x =>
                      x.driver !== row?.driverInformation?.driver?.id ||
                      x.vehicle !== row?.driverInformation?.vehicle?.id
                  );

                  setSelectedItems(newDrivers);
                  setFieldValue("drivers", newDrivers);
                }}
                onDone={drivers => {
                  const driversCleaned = drivers.map(x => ({
                    driver: x.driverInformation.driver.id,
                    vehicle: x.driverInformation.vehicle.id
                  }));

                  setFieldValue("drivers", driversCleaned);
                }}
              />
            </div>
          </FormSection>
        </Grid>
      </Grid>

      <FormSection title="Enter any additional information">
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item xs={12} className={classes.gridItem}>
            <ProductSelector
              disableEdit
              values={values}
              setFieldValue={setFieldValue}
              onDone={handleProductSelectorDone}
              customHandleDelete={customHandleProductDelete}
              projectId={projectId}
              selectionColumns={productSelectionColumns}
              selectedColumns={productSelectedColumns}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12} className={classes.gridItem}>
            <AddRemove
              small
              label="Amount of luggage"
              name="luggageCount"
              value={values.luggageCount}
              onChange={handleChange}
            />
          </Grid>

          {!isPublic && (
            <Grid Grid item md={3} sm={3} xs={12} className={classes.gridItem}>
              <AddRemove
                percentage
                label="Discount (optional)"
                name="discount"
                value={values.discount}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
            <TextAreaInput
              label="Product notes"
              name="productNotes"
              value={values.productNotes}
              onChange={handleChange}
              placeholder="Enter product notes            "
            />
          </Grid>
          <Grid item md={8} sm={6} xs={0} className={classes.gridSpacer} />
        </Grid>
      </FormSection>

      {!noDocumentSection && (
        <FormSection title="Upload any supporting documents">
          <DocumentUpload
            moduleId={(values.module && values.module.value) || null}
            documents={values.documents}
            onDeleteCallback={document => {
              if (document.identifier) setFieldValue(document.identifier, null);
              else {
                const newDocuments = values.documents.filter(
                  loadedDocument => loadedDocument.id !== document.id
                );
                setFieldValue("documents", newDocuments);
              }
            }}
            onComplete={async document => {
              setFieldValue(document.identifier, document);
            }}
          />
        </FormSection>
      )}
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default withFormState(AddBookingForm);
