/* eslint-disable complexity */
import React from "react";
import moment from "moment";
import _ from "lodash";
import { getIsKey } from "../helper";
import PhoneDisplay from "../../PhoneDisplay/PhoneDisplay";
import StarRating from "components_v2/StarRating/StarRating";
import {
  getMultipleDriversData,
  getMultiplVehiclesData
} from "../../../utils/listData";
import Typography from "components_v2/Typography/Typography";

function formatRatings(data) {
  let ranking = _.meanBy(data.driveratings, "rating");
  ranking = (!_.isNaN(ranking) && _.round(ranking, 1)) || null;

  return (ranking && <StarRating direction="right" rating={ranking} />) || "/";
}

function formatAverageRating({ averageDriverRating }) {
  return (
    (averageDriverRating && (
      <StarRating direction="right" rating={averageDriverRating} />
    )) ||
    "/"
  );
}

// AddBooking -- SelectionModal Columns
export const driverSelectColumns = [
  {
    field: "firstName",
    headerName: "Name",
    width: 3,
    render: row => `${row.firstName} ${row.lastName}`
  },
  {
    field: "identifier",
    headerName: "Driver ID",
    width: 3
  },
  {
    field: "ranking",
    headerName: "Ranking",
    width: 2,
    render: formatRatings
  },
  {
    field: "phone",
    headerName: "Contact Phone",
    width: 3
  }
];

export const vehicleSelectColumns = [
  {
    field: "make",
    headerName: "Make",
    width: 3
  },
  {
    field: "model",
    headerName: "Model",
    width: 3
  },
  {
    field: "plateNumber",
    headerName: "Plate number",
    width: 3
  },
  {
    field: "color",
    headerName: "Color",
    width: 3
  }
];

// VehicleList.jsx columns
export const getVehicleColumns = ({ projectId }) => [
  {
    field: "vehicleNumber",
    headerName: "Vehicle ID",
    width: 1
  },
  {
    field: "make",
    headerName: "Make",
    width: 1,
    hideMobile: true
  },
  {
    field: "model",
    headerName: "Model",
    width: 1
  },
  {
    field: "plateNumber",
    headerName: "Plate Number",
    width: 1,
    hideMobile: true
  },
  {
    field: "year",
    headerName: "Year",
    width: projectId ? 1 : 2,
    hideMobile: true
  },
  {
    field: "color",
    headerName: "Color",
    width: 2,
    hideMobile: true
  },
  {
    field: "vehicleClass.name",
    headerName: "Class",
    width: 2,
    render: row => <>{row?.vehicleClass?.name}</>
  },
  ...(projectId
    ? [
        {
          field: "supplier.name",
          headerName: "Supplier",
          width: 2,
          render: row => <>{row?.supplier?.name}</>,
          hideMobile: true
        }
      ]
    : []),
  {
    field: "maxPassengers",
    headerName: "Passenger Count",
    width: projectId ? 1 : 2,
    hideMobile: true
  },
  {
    field: "assignedDrivers",
    headerName: "Driver",
    width: 2,
    hideMobile: true,
    render: row => {
      const driverNames = row.assignedDrivers?.map(assignedDriver => (
        <span>{assignedDriver.driver.firstName}</span>
      ));
      const [firstDriver, otherDriversCount] = getMultipleDriversData(
        row.assignedDrivers
      );
      return otherDriversCount ? (
        <>
          <Typography customVariant="bodySmallRegular">
            {firstDriver}
          </Typography>
          <div className="user-list">
            {`+ ${otherDriversCount} more`}
            <div className="user-projects">{driverNames}</div>
          </div>
        </>
      ) : (
        <Typography customVariant="bodySmallRegular">{firstDriver}</Typography>
      );
    }
  }
];

// DriverList.jsx columns
export const driverColumns = [
  {
    field: "firstName",
    headerName: "Name",
    width: 2,
    render: row => `${row.firstName} ${row.lastName}`
  },
  {
    field: "identifier",
    headerName: "Driver ID",
    width: 2,
    isSortable: false
  },
  {
    field: "averageDriverRating",
    headerName: "Ranking",
    width: 2,
    render: formatAverageRating
  },
  {
    field: "phone",
    headerName: "Contact Phone",
    width: 2,
    hideMobile: true,
    render: data => <PhoneDisplay phone={data.phone}></PhoneDisplay>,
    isSortable: false
  },
  {
    field: "shift",
    headerName: "Working Hours",
    width: 2,
    hideMobile: true,
    render: data => {
      let formattedShift = "";

      if (!data.shift) return "N/A";

      const shift = data?.shift;

      _.forEach(shift, (value, key) => {
        if (key === "sunday" && value) formattedShift += "Sun";
        else if (getIsKey({ key, keyValue: "monday", value })) {
          if (formattedShift.length) formattedShift += ", Mon";
          else formattedShift = "Mon";
        } else if (getIsKey({ key, keyValue: "tuesday", value })) {
          if (formattedShift.length) formattedShift += ", Tue";
          else formattedShift = "Tue";
        } else if (key === "wednesday" && value) {
          if (formattedShift.length) formattedShift += ", Wed";
          else formattedShift = "Wed";
        } else if (key === "thursday" && value) {
          if (formattedShift.length) formattedShift += ", Thu";
          else formattedShift = "Thu";
        } else if (key === "friday" && value) {
          if (formattedShift.length) formattedShift += ", Fri";
          else formattedShift = "Fri";
        } else if (key === "saturday" && value) {
          if (formattedShift.length) formattedShift += ", Sat";
          else formattedShift = "Sat";
        }
      });

      if (shift?.shiftStart && shift?.shiftEnd)
        formattedShift = formattedShift += `  |  ${moment(
          shift.shiftStart
        ).format(`H:mm`)} - ${moment(shift.shiftEnd).format(`H:mm`)}`;

      if (!formattedShift) return "N/A";

      return formattedShift;
    }
  },
  {
    field: "assignedVehicles",
    headerName: "Vehicles",
    width: 2,
    hideMobile: true,
    render: data => {
      const vehicleNames = data.assignedVehicles?.map(assignedVehicle => (
        <span>
          {assignedVehicle.vehicle.make} {assignedVehicle.vehicle.model}{" "}
          {assignedVehicle.vehicle.plateNumber}
        </span>
      ));
      const [firstVehicle, otherVehiclesCount] = getMultiplVehiclesData(
        data.assignedVehicles
      );
      return otherVehiclesCount ? (
        <>
          <Typography customVariant="bodySmallRegular">
            {firstVehicle}
          </Typography>
          <div className="user-list">
            {`+ ${otherVehiclesCount} more`}
            <div className="user-projects">{vehicleNames}</div>
          </div>
        </>
      ) : (
        <Typography customVariant="bodySmallRegular">{firstVehicle}</Typography>
      );
    },
    isSortable: false
  }
];
