import React from 'react';
import { Card as MuiCard, CardActions, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from 'components_v2/Typography/Typography';
import CircularIcon from '../../CircularIcon/CircularIcon';
import {
  extraSmallMobileBreakpoint,
  mobileViewBreakpoint,
} from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  cardRoot: (props) => ({
    background: '#FFF',

    width: props.size === 'small' ? 513 : 697,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: '100vw',
      border: 'none',
      background:
        'linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)',
      boxShadow: 'none',
    },
  }),
  cardContentRoot: (props) => ({
    padding: props.hasFooter ? '64px 56px 0 56px' : '64px 56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '&:last-child': {
      padding: props.hasFooter ? '64px 56px 0 56px' : '64px 56px',
    },
  }),

  bodyActionsRoot: {
    marginTop: 16,
  },
  bodyActionsSpacing: (props) => ({
    width: 550,
    maxWidth: '100%',
    display: props.bodyActionCount === 2 ? 'flex' : 'grid',
    gridTemplateColumns:
      props.bodyActionCount === 1
        ? 'auto'
        : 'repeat(auto-fill, minmax(160px, 1fr))',
    gridAutoRows: 'auto',
    rowGap: '24px',
    columnGap: '24px',
    justifyItems: 'center',
    justifyContent: 'center',
    '& > :not(:first-child)': {
      margin: 0,
    },
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      flexWrap: props.bodyActionCount === 2 ? 'wrap' : undefined,
    },
  }),
  footerActionsRoot: (props) => ({
    margin: '56px 0 0 0',
    padding: '32px 40px',
    background: 'rgba(0, 0, 0, 0.02)',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button:first-of-type': {
      marginRight: props.hasBothActions ? 32 : 0,
    },
    '& button:nth-of-type(2)': {
      marginRight: props.hasThreeActions ? 32 : 0,
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      background: '#fff',
      justifyContent: 'space-between',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '32px 16px',
      boxShadow: "0px -10px 17px rgba(0, 0, 0, 0.1)",
      '& button:first-of-type': {
        marginRight: 0,
        marginLeft: props.hasBothActions ? 0 : 'auto',
      },
    },
  }),
}));

const Card = ({
  children,
  size = 'small',
  mainAction = false,
  secondaryAction = false,
  otherMainAction = false,
  icon,
  iconBackground = 'light',
  confetti,
  headerText,
  description,
  secondaryBodyAction,
  mainBodyActions,
  headerSize
}) => {
  const hasFooter = mainAction || secondaryAction;
  const hasBothActions =
    (mainAction && secondaryAction) || (otherMainAction && secondaryAction);
  const hasThreeActions = mainAction && secondaryAction && otherMainAction;
  const classes = useStyles({
    size,
    hasFooter,
    hasBothActions,
    hasThreeActions,
    bodyActionCount: (mainBodyActions || []).length,
  });

  const iconBackgroundColor =
    iconBackground === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.8)';

  return (
    <MuiCard classes={{ root: classes.cardRoot }}>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <div style={{ marginBottom: '32px' }}>
          <CircularIcon
            isShadowed={confetti}
            bgColor={iconBackgroundColor}
            icon={icon}
            bgSize={73}
          />
        </div>
        {headerText ? (
          <Typography style={{ marginBottom: 16 }} customVariant={headerSize || "h3Bold"}>
            {headerText}
          </Typography>
        ) : null}
        {description ? (
          <Typography
            style={{ marginBottom: 16 }}
            customVariant="bodySmallRegular"
          >
            {description}
          </Typography>
        ) : null}
        {secondaryBodyAction}
        {mainBodyActions && mainBodyActions.length ? (
          <CardActions
            classes={{
              root: classes.bodyActionsRoot,
              spacing: classes.bodyActionsSpacing,
            }}
          >
            {mainBodyActions}
          </CardActions>
        ) : null}
        {children}
      </CardContent>

      {mainAction || otherMainAction || secondaryAction ? (
        <CardActions
          disableSpacing
          classes={{ root: classes.footerActionsRoot }}
        >
          {secondaryAction} {otherMainAction} {mainAction}
        </CardActions>
      ) : null}
    </MuiCard>
  );
};

Card.propTypes = {
  mainAction: PropTypes.element,
  secondaryAction: PropTypes.element,
  icon: PropTypes.element,
  headerText: PropTypes.string,
  description: PropTypes.string,
  secondaryBodyAction: PropTypes.element,
  mainBodyActions: PropTypes.arrayOf(PropTypes.element),
  iconBackground: PropTypes.oneOf(['dark', 'light']),
  confetti: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
};

export default Card;
