import gql from 'graphql-tag';

export default gql`
  query GetAllByEvent(
    $id: ID
    $name: String
    $passengerRole: String
    $driverRole: String
  ) {
    passengerscheduleBookingIdContains: passengerschedules(
      where: { event: $id, bookingId_contains: $name }
      limit: 3
    ) {
      id
      bookingId
      passenger {
        firstName
        lastName
      }
      start
      event {
        id
      }
    }

    locationNameContains: storedlocations(
      where: { event: $id, name_contains: $name }
      limit: 3
    ) {
      id
      identifier
      name
      type
      address
      lat
      long
      event {
        id
      }
    }
    locationIdentifierContains: storedlocations(
      where: { event: $id, identifier_contains: $name }
      limit: 3
    ) {
      id
      identifier
      name
      type
      address
      lat
      long
      event {
        id
      }
    }

    passengerFirstNameContains: users(
      where: { firstName_contains: $name, event: $id, role: $passengerRole }
      limit: 3
    ) {
      id
      firstName
      lastName
      email
      identifier
      phone
      event {
        id
      }
    }
    passengerLastNameContains: users(
      where: { lastName_contains: $name, event: $id, role: $passengerRole }
      limit: 3
    ) {
      id
      firstName
      lastName
      email
      identifier
      phone
      event {
        id
      }
    }
    passengerIdentifierContains: users(
      where: { identifier_contains: $name, event: $id, role: $passengerRole }
      limit: 3
    ) {
      id
      firstName
      lastName
      email
      identifier
      phone
      event {
        id
      }
    }

    driverFirstNameContains: users(
      where: { firstName_contains: $name, event: $id, role: $driverRole }
      limit: 3
    ) {
      id
      firstName
      lastName
      email
      identifier
      phone
      event {
        id
      }
    }
    driverLastNameContains: users(
      where: { lastName_contains: $name, event: $id, role: $driverRole }
      limit: 3
    ) {
      id
      firstName
      lastName
      email
      identifier
      phone
      event {
        id
      }
    }
    driverIdentifierContains: users(
      where: { identifier_contains: $name, event: $id, role: $driverRole }
      limit: 3
    ) {
      id
      firstName
      lastName
      email
      identifier
      phone
      event {
        id
      }
    }
  }
`;
