import React, { useState } from "react";
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { NewVehicle } from "../../Icons/Icons";
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import ListModal from "components_v2/ListModal/ListModal";


const SelectVehicleForDriverModal = ({
  data,
  onClickCancel,
  onClickConfirm,
}) => {
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);

  return (
    <Modal
      size="sm"
      pageView={data?.items}
    >
      <SuccessCard
        icon={
          <NewVehicle
            style={{
              color: "#FFF",
              fill: "white",
              width: 40,
              height: 40,
              filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
            }}
          />
        }
        mainAction={{
            text: "Continue",
            onClick: id ? () => onClickConfirm(id) : () => setError(data.errorMessage),
          }}
        secondaryAction={{
          text: "Cancel",
          onClick: () => {
            setId(null);
            setError(null);
            onClickCancel();
          },
        }}
        headerText={data?.headerText}
      > 
        <div
          style={{
            width: "100%"
          }}
        >
          <ListModal
            showHeader
            disableQuery
            singleSelection
            title={data?.title}
            searchPlaceholder={data?.searchPlaceholder}
            onCloseCallback={() => {
              setError(null);
            }}
            onCancelCallback={() => {
              setId(null);
            }}
            mainAction={{
              label: 'Select',
              onClick: (data) => {
                const vehicle = data[0];
                setId(vehicle.id);
                setError(null);
              },
            }}
            secondaryAction={{
              label: data?.secondaryActionLabel,
              onClick: data?.secondaryActionHandler,
            }}
            data={data?.items}
            incomingItems={[]}
            columns={data?.columns}
            renderSelectionColumns={data?.columns}
            error={error}
            onModalClick={(toggleModal) => (
              <SelectActionButton onClick={toggleModal}>
                {data?.actionButtonText}
              </SelectActionButton>
            )}
          />
        </div>
        
      </SuccessCard>
      
        
    </Modal>
  );
};

export default SelectVehicleForDriverModal;
