import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import withMainLayout from 'components_v2/Layout/withMainLayout';
import GetEventById from 'gql/Events/GetOne';
import GetStoredLocationsByLocationIds from 'gql/StoredLocations/GetStoredLocationsByLocationIds';
import DispatchComponent from './Dispatch.Component';

const DispatchContainer = ({ match }) => {
  const { projectId } = match.params;

  const { data = null, loading } = useQuery(GetEventById, {
    variables: { id: projectId },
    skip: !projectId,
    fetchPolicy: 'no-cache',
  });


  const locationIds = data?.events[0]?.locationInput || [];

  const { data: locationsData, loading: locationsLoading } = useQuery(GetStoredLocationsByLocationIds, {
    variables: { ids: locationIds },
    skip: !locationIds.length,
    fetchPolicy: 'no-cache',
  });
  
  return (
    <DispatchComponent
      data={data?.events[0] || null}
      locationsData={locationsData?.storedlocationsConnection?.values}
      loading={loading || locationsLoading}
      projectId={projectId}
    />
  );
};

export default withMainLayout(
  'Dispatch',
  { noPadding: true },
  withRouter(DispatchContainer)
);
