import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import TitleWithText from "components_v2/TitleWithText/TitleWithText";
import PassengerTooltip from "components_v2/Tooltip/PassengerTooltip";
import PassengerNameDisplay from "components_v2/DisplayName/DisplayName";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import MarkerMap from "components_v2/Map/MarkerMap";
import _ from "lodash";
import Timeline from "components_v2/Timeline/Timeline";
import Loading from "components_v2/Loading/CircularProgress";
import {
  getCompletedSteps,
  getIsLoading,
  getMarkers,
  getStatusLabel,
  getLabel,
  getIsMarkerMapVisible,
  getIsBookingDetailsFooterVisible,
  getText,
  passengerListFormatted,
  getIsDispatchOrScheduleOrTrackRidePage
} from "./BookingHelper";
import { mobileViewBreakpoint } from "../../App.helper";
import DriverDetails from "./DriverDetails";
import useChat from "hooks/useChat";
import { useLazyQuery } from "@apollo/react-hooks";
import GetAllUpcomingByProject from "./Gql/GetAllUpcomingByProject";
import { generateRidesListNew } from "./helpers";
import BookingDetailFooter from "./BookingDetail.Footer.Component";
import PuDo from "./PuDo";
import { Header } from "./BookingDetail/Header";

const useStyles = makeStyles(theme => ({
  root: {
    background: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      "#ffffff",
    width: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      560,
    display: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      "flex",
    flexDirection: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      "column",
    height: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      "100%",
    border: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: props =>
      (props.isDispatchPage || props.isSchedulePage || props.isTrackRide) &&
      "6px",
    position: "relative",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: props =>
        (props.isDispatchPage && "calc(100% - 120px)") ||
        ((props.isSchedulePage || props.isTrackRide) && "100%"),
      width: props =>
        (props.isDispatchPage || props.isTrackRide || props.isSchedulePage) &&
        "100%"
    },

    "& .MuiGrid-container": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        display: "block"
      }
    }
  },
  mapWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: "300px"
    }
  },
  timeline: {
    padding: "0 18px 18px 18px"
  },
  passengers: {
    color: "#4001C5"
  },
  headerButton: {
    borderRadius: "90px",
    border: "1px solid rgba(0, 0, 0, 0.2)"
  },
  headerButtonText: {
    color: "rgba(197, 2, 2, 1)",
    fontSize: "10px",
    fontWeight: "900"
  },
  details: {
    padding: "0 20px",
    flex: 1,
    overflowY: "auto"
  }
}));

export default function BookingDetail({
  updatingBookingRecords,
  data,
  isHelpTutorial,
  refetchPassengerSchedules,
  projectId,
  isDispatchPage,
  loading,
  onClose,
  expanded,
  history,
  isSchedulePage,
  handleClose,
  isDriverScheduled = true,
  isTrackRide,
  onDelete,
  bookingUpdatesLoading
}) {
  const [localData, setLocalData] = useState({});

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const onAssignDriverLoading = assignDriverLoading => {
    setAssignDriverLoading(assignDriverLoading);
  };

  const onOvertimeLoading = overtimeLoading => {
    setOverTimeLoading(overtimeLoading);
  };
  const onDeleteLoading = isloading => {
    setIsDeleteLoading(isloading);
  };

  const [assignDriverLoading, setAssignDriverLoading] = useState(false);
  const [overTimeLoading, setOverTimeLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const classes = useStyles({ isDispatchPage, isSchedulePage, isTrackRide });
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendDriverLinkLoading, setSendDriverLinkLoading] = useState(false);
  const [disableListeners, setDisableListeners] = useState(true);
  const [open, setOpen] = useState(false);
  const [disableHover, setDisableHover] = useState(false);
  const onSendEmailLoading = loading => {
    setSendEmailLoading(loading);
  };

  const onSendDriverLinkLoading = loading => {
    setSendDriverLinkLoading(loading);
  };

  const onCancelLoading = isloading => {
    setIsCancelLoading(isloading);
  };

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const completedSteps = getCompletedSteps({ data: localData });

  const markers = getMarkers(localData);

  const getIsCurrentRecordUpdating = () => {
    if (localData?.id) return _.includes(updatingBookingRecords, localData?.id);
    return false;
  };
  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // lazy query for updating schedules
  const [
    getNewDriverSchedules,
    { loading: lazyLoading, data: lazyData }
  ] = useLazyQuery(GetAllUpcomingByProject, { fetchPolicy: "no-cache" });

  useChat("bookingUpdates", async ({ driverSchedules }) => {
    if (isDispatchPage) {
      await getNewDriverSchedules({
        variables: {
          driverScheduleIds: driverSchedules,
          limit: driverSchedules.length
        }
      });
    }
  });

  useEffect(() => {
    if (!_.isEmpty(lazyData)) {
      const lazyLoadedPassengerSchedules = generateRidesListNew(
        lazyData?.allBookings?.bookings
      );
      setLocalData(lazyLoadedPassengerSchedules[0]);
    }
  }, [lazyData]);

  return (
    <div className={classes.root}>
      {(getIsCurrentRecordUpdating() ||
        getIsLoading({
          isLoading,
          loading,
          overTimeLoading,
          assignDriverLoading,
          lazyLoading,
          isDeleteLoading,
          isCancelLoading,
          bookingUpdatesLoading,
          sendEmailLoading,
          sendDriverLinkLoading
        })) && (
        <Loading
          fullPage
          backgroundPosition="absolute"
          backgroundWidth="100%"
          backgroundHeight="100%"
        />
      )}
      <Header
        isDispatchPage={isDispatchPage}
        isSchedulePage={isSchedulePage}
        isTrackRide={isTrackRide}
        onClose={onClose}
        handleClose={handleClose}
      />
      <Grid container style={{ flex: 1, overflow: "auto" }}>
        <Grid
          item
          md={12}
          lg={
            getIsDispatchOrScheduleOrTrackRidePage({
              isDispatchPage,
              isSchedulePage,
              isTrackRide
            })
              ? 12
              : 8
          }
        >
          {isDriverScheduled && (
            <Collapse
              timeout={600}
              in={
                getIsDispatchOrScheduleOrTrackRidePage({
                  isDispatchPage,
                  isSchedulePage,
                  isTrackRide
                })
                  ? isExpanded
                  : true
              }
              style={{ width: "100%" }}
            >
              {getIsDispatchOrScheduleOrTrackRidePage({
                isDispatchPage,
                isSchedulePage,
                isTrackRide
              }) && (
                <>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    justify="space-between"
                    direction="row"
                    style={{ padding: "24px 24px 0 24px" }}
                  >
                    <Grid item xs={12} md={3}>
                      <TitleWithText
                        label={getLabel({ projectId, localData })}
                        text={getText({ projectId, localData })}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TitleWithText
                        label="Status"
                        text={getStatusLabel(localData)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TitleWithText
                        label="Booking ID"
                        text={localData?.bookingId}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Collapse>
          )}

          <Grid container style={{ padding: 24 }}>
            {isDriverScheduled && (
              <Grid item xs={12} md={12}>
                <PuDo data={localData} />
                <Divider variant="middle" style={{ margin: "24px 0" }} />
              </Grid>
            )}

            <Collapse
              timeout={600}
              in={
                isDispatchPage || isSchedulePage || isTrackRide
                  ? isExpanded
                  : true
              }
              style={{ width: "100%" }}
            >
              {isDriverScheduled && (
                <Grid
                  container
                  xs={12}
                  md={12}
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} lg={3}>
                    <TitleWithText
                      label="Passenger"
                      text={
                        <PassengerNameDisplay
                          passenger={localData?.passenger}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    onMouseEnter={() => {
                      setOpen(true);
                    }}
                    onMouseLeave={() => {
                      if (!disableHover) setOpen(false);
                    }}
                    item
                    xs={12}
                    lg={3}
                  >
                    <PassengerTooltip
                      passengers={passengerListFormatted({ localData })}
                      bookingId={localData?.bookingId}
                      style={{
                        display: "inline-flex",
                        flexDirection: "column"
                      }}
                      placement="bottom-start"
                      disableListeners={disableListeners}
                      setDisableListeners={setDisableListeners}
                      open={open}
                      setOpen={setOpen}
                      setDisableHover={setDisableHover}
                    >
                      <TitleWithText
                        label="Pax"
                        text={
                          <strong className={classes.passengers}>
                            {localData?.numPassengers}
                          </strong>
                        }
                      />
                    </PassengerTooltip>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <TitleWithText
                      label="Contact (passenger)"
                      text={localData?.passenger?.phone}
                    />
                  </Grid>

                  <Grid item xs={0} lg={3} />
                </Grid>
              )}
              {isDriverScheduled && (
                <Grid
                  container
                  xs={12}
                  md={12}
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={3}>
                    <TitleWithText
                      label="Service Type"
                      text={localData?.transferservice?.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TitleWithText
                      label="Vehicle Class"
                      text={localData?.vehicleClass}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TitleWithText
                      label="Flight Number"
                      text={localData?.flightNumber}
                    />
                  </Grid>
                  <Grid item xs={0} md={3} />
                </Grid>
              )}
              <DriverDetails
                data={localData}
                isMenuOpened={isMenuOpened}
                setIsMenuOpened={setIsMenuOpened}
              />
            </Collapse>
            <Grid item xs={10} md={10}>
              <TitleWithText label="Additional" text={localData?.notes} />
            </Grid>

            {isDriverScheduled && (
              <Grid
                container
                xs={12}
                md={12}
                justify="flex-end"
                direction="row"
              >
                <Grid item xs={6}>
                  <TitleWithText
                    label="Pickup Time"
                    text={moment
                      .utc(localData?.start)
                      .format("MMMM D, YYYY [at] HH:mm")}
                  />
                </Grid>

                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {/* REMOVE FOR FIFA DEMO<TitleWithText
                  label="Estimated arrival time"
                  text={moment().utc().format('HH:mm')}
                />  */}
                </Grid>
              </Grid>
            )}
          </Grid>

          {isDriverScheduled && (
            <Grid>
              <Timeline
                isBooking
                className={classes.timeline}
                completedSteps={completedSteps}
                data={localData}
                isHelpTutorial={isHelpTutorial}
                refetchPassengerSchedules={refetchPassengerSchedules}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </Grid>
          )}
        </Grid>
        {getIsMarkerMapVisible({
          isDispatchPage,
          isSchedulePage,
          isTrackRide
        }) && (
          <Grid className={classes.mapWrapper} item md={12} lg={4}>
            <MarkerMap
              mapHeight={400}
              showRoute
              noPadding
              asDirected={localData?.to?.name?.includes("-As directed-")}
              markers={markers}
              noTracking
            />
            {/* noTracking */}
          </Grid>
        )}
      </Grid>
      {getIsBookingDetailsFooterVisible({
        isDispatchPage,
        isSchedulePage,
        isTrackRide
      }) && (
        <BookingDetailFooter
          onAssignDriverLoading={onAssignDriverLoading}
          onOvertimeLoading={onOvertimeLoading}
          isExpanded={isExpanded}
          history={history}
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          isDriverScheduled={isDriverScheduled}
          data={localData}
          isTrackRide={isTrackRide}
          onDelete={onDelete}
          onSendDriverLinkLoading={onSendDriverLinkLoading}
          onDeleteLoading={onDeleteLoading}
          onCancelLoading={onCancelLoading}
          onSendEmailLoading={onSendEmailLoading}
          onSendDriverLinkLoading={onSendDriverLinkLoading}
        />
      )}
    </div>
  );
}
