// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export const mapItemsToTableSelect = (arr) => {
  let locationsMapped = {};

  arr.map((location) => {
    locationsMapped = {
      [location.id]: location.name,
      ...locationsMapped,
    };

    return location
  });

  return locationsMapped;
};

export const calculateAirDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  var lat_1 = toRad(lat1);
  var lat_2 = toRad(lat2);

  const a =
    (Math.sin(dLat / 2) * Math.sin(dLat / 2)) +
    (Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat_1) * Math.cos(lat_2));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return isNaN(d) ? 999999 : d;
};

export const vehicleTypeToString = (vehicleTypeValue) => {
  const lookup = {
    sedan: 'Sedan',
    suv: 'SUV',
    van: 'Van',
    minibus: 'Mini Bus',
    bus: 'Bus',
  };

  return lookup[vehicleTypeValue];
};

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const setBlurryBackground = (isSet) => {
  const blurElement = document.querySelector('.blurry-bg');

  blurElement.style.display = isSet ? 'block' : 'none';
};

let currentFocusedOnElement = null;

export const setFocusOnElement = (
  isSet,
  element = currentFocusedOnElement,
  position = 'relative'
) => {
  const el = element;

  setBlurryBackground(isSet);
  if (isSet) {
    currentFocusedOnElement = el;
    el.style.position = position;
    el.style.zIndex = '11';
  } else if (currentFocusedOnElement) {
    currentFocusedOnElement.style.position = 'revert';
    currentFocusedOnElement.style.zIndex = 'revert';
  }
};
