import gql from "graphql-tag";

const DELETE = gql`
  mutation($id: ID!) {
    deleteStoredlocation(input: { where: { id: $id } }) {
      storedlocation {
        id
      }
    }
  }
`;

export default DELETE;
