import React from 'react';

import { stringifyJSON, parseJSON } from 'utils/json';

const FORM_STATE = "FORM_STATE";

const withFormState = (WrappedComponent) => {

  const setFormState = (state, isArray, index) => {
    let currentFormState;

    // If incoming state is not an array, it will be added to the array of form state objects already stored
    // Otherwise it will replace the current form state array
    if (!isArray){
    currentFormState = getFormState({ returnArray: true }) || [];

    if (currentFormState && typeof currentFormState === "string") currentFormState = parseJSON(currentFormState);
    if (index !== undefined) currentFormState.splice(index, 1, state);
    else currentFormState = [...currentFormState, state];
  } else currentFormState = state;

    const jsonState = stringifyJSON(currentFormState, undefined);
    
    localStorage.setItem(FORM_STATE, jsonState);
  };

  const getFormState = ({ index, returnArray }) => {
    const jsonState = localStorage.getItem(FORM_STATE);

    let formState;

    if (jsonState && typeof jsonState === "string") 
      formState = parseJSON(jsonState, undefined);
    
    // If index is included as an argument, return specific form state object
    if (index && formState) return formState[index];
    // if no index either return the most recent form state object or if returnArray is true return the whole form state array.
    else if (!index && formState) return returnArray ? formState : formState[formState.length - 1];
    return formState;
  };

  const getPreviousFormState = (location, index) => {
    const previousFormState =  location?.state?.hasState ? { ...getFormState({ index }) } : {};
    return previousFormState;
  }

  const removeFormStateItem = (removeIndex) => {
    const formState = getFormState({ returnArray: true });

    let newFormState;

    if (removeIndex) newFormState = formState.filter((state, index) => index !== removeIndex);
    else newFormState = formState.filter((state, index) => index !== formState.length - 1);
    setFormState(newFormState, true);
  };

  const setReturnValues = (value, previousFormState ) => {
    const newPreviousFormState = { ...previousFormState };
    newPreviousFormState.returnValues = { [previousFormState.returnField]: value };
    setFormState(newPreviousFormState, false, -1);
  };

  return (props) => (
    <WrappedComponent
      {...props}
      setFormState={setFormState}
      getFormState={getFormState}
      getPreviousFormState={getPreviousFormState}
      removeFormStateItem={removeFormStateItem}
      setReturnValues={setReturnValues}
    />
  );
};

export default withFormState;
