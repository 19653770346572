import React from 'react';
import { Grid } from '@material-ui/core';

import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import PhoneInput from 'components_v2/Inputs/PhoneInput/PhoneInput';
import LocationSearchInput from 'components_v2/Inputs/LocationSearchInput/LocationSearchInput';
import Typography from 'components_v2/Typography/Typography';
import DocumentUpload from 'components_v2/Upload/UploadDocument';
import MarkerMap from 'components_v2/Map/MarkerMap';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';

const AddClientForm = ({
  values,
  touched,
  errors,
  handleChange,
  classes,

}) => {
  const lat = values?.location?.lat;
  const long = values?.location?.long;
  const name = values?.location?.name;
  const address = values?.location?.address;
  let markers = [];
  if (lat && long) markers = [{ lat, long, name, address }];

  return (
    <>
      <FormSection defaultExpanded title="Enter the company details">
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="companyName"
              label="Company name *"
              placeholder="Enter company name"
              value={values.companyName}
              onChange={handleChange}
              error={touched.companyName && errors.companyName}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="officeType"
              label="Office type"
              placeholder="Enter office type i.e. Head office"
              value={values.officeType}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <LocationSearchInput
              name="location"
              onChange={handleChange}
              label="Location *"
              placeholder="Search a location"
              defaultOptions={values.location ? [values.location] : []}
              defaultValue={values.location || null}
              error={touched.location && errors.location}
            />
          </Grid>
          <Grid item xs={12} className={classes.mapRow}>
            <MarkerMap markers={markers} />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Enter client contact information">
        <Typography
          customVariant="bodySmallRegular"
          style={{ marginBottom: 24, color: "#3C3C3C" }}
        >
          Contact details
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="contactName"
              label="Full name *"
              placeholder="Enter full name"
              value={values.contactName}
              onChange={handleChange}
              error={touched.contactName && errors.contactName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="contactDepartment"
              label="Department"
              placeholder="Enter department"
              value={values.contactDepartment}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="contactPosition"
              label="Postition"
              placeholder="Enter position"
              value={values.contactPosition}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="email"
              label="Email *"
              placeholder="Enter email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <PhoneInput
              name="phone"
              label="Phone"
              placeholder="Enter phone number"
              value={values.phone}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Upload any client documents">
        <DocumentUpload
          onComplete={async () => {
            // await createDocument({
            //   variables: {
            //     data: values,
            //   },
            // });
          }}
        />
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default AddClientForm;
