import gql from 'graphql-tag';

export default gql`
  mutation UpdateClient($id: InputID, $data: editClientInput) {
    updateClient(input: { where: $id, data: $data }) {
      client {
        id
      }
    }
  }
`;
