import React, { useEffect } from "react";
import { Box, Button as MuiButton, Grid } from "@material-ui/core";

import FormSection from "components_v2/Inputs/FormSection/FormSection";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import LocationSearchInput from "components_v2/Inputs/LocationSearchInput/LocationSearchInput";
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import PhoneInput from "components_v2/Inputs/PhoneInput/PhoneInput";
import TextAreaInput from "components_v2/Inputs/TextAreaInput/TextAreaInput";
import Button from "components_v2/Button/Button";
import { Add, MenuPointer, Delete } from "components_v2/Icons/Icons";
import Typography from "components_v2/Typography/Typography";
import MarkerMap from "components_v2/Map/MarkerMap";
import Accordion from "components_v2/Accordion/Accordion";
import TotalErrors from "components_v2/Inputs/TotalErrors/TotalErrors";
import { locationTypes, functionalAreaTypes } from "./helpers";
import { eventStopPropagation } from "../../../../App.helper";

const AddLocationForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  classes,
  deleteStoredLocation,
  refetchFunctionalAreas,
}) => {

  const lat = values?.location?.lat;
  const long = values?.location?.long;
  const name = values?.location?.name;
  const address = values?.location?.address;
  let markers = [];
  if (lat && long) markers = [{ lat, long, name, address }];

  useEffect(() => {
    let hasFunctionalAreas = false;

    if (values.functionalAreas.length) hasFunctionalAreas = true;

    setFieldValue("hasFunctionalAreas", hasFunctionalAreas);
  }, [values.functionalAreas, setFieldValue]);

  const handleFunctionalAreaChange = index => e => {
    const { name, value } = e.target;

    const newFunctionalAreas = values.functionalAreas.map((fa, i) => {
      if (i === index) return { ...fa, [name]: value };
      return fa;
    });
    setFieldValue("functionalAreas", newFunctionalAreas);
  };

  const handleDeleteClick = index => async e => {
    eventStopPropagation({ event: e });

    const id = values.functionalAreas[index].id;

    if (id) {
      await deleteStoredLocation({ variables: { id } });
      await refetchFunctionalAreas();
    } else {
      const newFunctionalAreas = values.functionalAreas.filter(
        (el, i) => index !== i
      );

      setFieldValue("functionalAreas", newFunctionalAreas);
    }
  };

  const functionalAreaForms = values.functionalAreas.map(
    ({ name, location, type }, index) => {
      const handleMapClick = async event => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = {
          lat: event?.lngLat?.[1],
          lng: event?.lngLat?.[0]
        };
        geocoder
          .geocode({ location: latlng })
          .then(response => {
            if (response.results[0]) {
              handleFunctionalAreaChange(index)({
                persist: () => {},
                target: {
                  type: "change",
                  name: "location",
                  value: {
                    lat: response.results[0].geometry.location.lat(),
                    long: response.results[0].geometry.location.lng(),
                    name: response.results[0].formatted_address,
                    address: response.results[0].formatted_address
                  }
                }
              });
            } else {
              window.alert("No results found");
            }
          })
          .catch(e => window.alert("Geocoder failed due to: " + e));
      };

      return (
        <Box className={classes.functionalAreasFormContainer}>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginBottom: "8px" }}
          >
            Functional area {index + 1}
          </Typography>
          <Box className={classes.functionalAreasGrid}>
            <Grid container spacing={3}>
              <Grid item md={4} sm={6} xs={12}>
                <LocationSearchInput
                  name="location"
                  onChange={handleFunctionalAreaChange(index)}
                  label="Location"
                  placeholder="Search a location"
                  defaultOptions={location ? [location] : []}
                  defaultValue={location || null}
                  error={
                    touched.functionalAreas &&
                    touched.functionalAreas[index]?.location &&
                    errors.functionalAreas &&
                    errors.functionalAreas[index]?.location
                  }
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <SelectInput
                  name="type"
                  label="Type"
                  placeholder="Select a location type"
                  value={type}
                  onChange={handleFunctionalAreaChange(index)}
                  options={functionalAreaTypes}
                  errorMessage={
                    touched.functionalAreas &&
                    touched.functionalAreas[index]?.type &&
                    errors.functionalAreas &&
                    errors.functionalAreas[index]?.type
                  }
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="Enter location name"
                  value={name}
                  onChange={handleFunctionalAreaChange(index)}
                  error={
                    touched.functionalAreas &&
                    touched.functionalAreas[index]?.name &&
                    errors.functionalAreas &&
                    errors.functionalAreas[index]?.name
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid className={classes.functionalAreaMapRow} item xs={12}>
                <Accordion
                  contentHeight={300}
                  noPadding
                  defaultExpanded
                  summaryComponent={
                    <Box className={classes.accordionSummaryContainer}>
                      <Box className={classes.accordionSummaryLeft}>
                        <MenuPointer />
                        <Typography
                          style={{
                            marginLeft: "8px",
                            position: "relative",
                            top: "2px"
                          }}
                          customVariant="bodySmallRegular"
                        >
                          {location && `${location.name}, ${location.address}`}
                        </Typography>
                      </Box>
                      <Box className={classes.accordionSummaryRight}>
                        <MuiButton
                          onClick={handleDeleteClick(index)}
                          className={classes.accordionDeleteButton}
                        >
                          <Delete />
                        </MuiButton>
                      </Box>
                    </Box>
                  }
                >
                  <MarkerMap
                    onMapClick={handleMapClick}
                    initialFocusRegion={{
                      lat: values?.location?.lat,
                      long: values?.location?.long
                    }}
                    noPadding
                    draggableMarkers
                    markers={location ? [location] : []}
                    markerType="glMarker"
                    onDragEnd={event => {
                      const [long, lat] = event.lngLat;

                      const newFunctionalAreaData = {
                        ...values.functionalAreas[index],
                        location: {
                          ...values.functionalAreas[index].location,
                          lat,
                          long
                        }
                      };
                      const newFunctionalAreas = [...values.functionalAreas];
                      newFunctionalAreas.splice(
                        index,
                        1,
                        newFunctionalAreaData
                      );
                      setFieldValue("functionalAreas", newFunctionalAreas);
                    }}
                  />
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }
  );

  const handleAddFunctionalArea = () => {
    setFieldValue("functionalAreas", [
      ...values.functionalAreas,
      { location: null, type: null, name: "" }
    ]);
  };

  const addFunctionalAreasButton = !functionalAreaForms.length ? (
    <Button
      variant="contained"
      secondary
      startIcon={<Add />}
      onClick={handleAddFunctionalArea}
    >
      Add functional area
    </Button>
  ) : null;

  const AddAnotherFunctionalAreaButton = functionalAreaForms.length ? (
    <Button
      variant="contained"
      secondary
      startIcon={<Add />}
      onClick={handleAddFunctionalArea}
      className={classes.addAnotherAreaButton}
    >
      Add another functional area
    </Button>
  ) : null;

  const handleMapClick = async event => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: event?.lngLat?.[1],
      lng: event?.lngLat?.[0]
    };
    geocoder
      .geocode({ location: latlng })
      .then(response => {
        if (response.results[0]) {
          handleChange({
            persist: () => {},
            target: {
              type: "change",
              name: "location",
              value: {
                lat: response.results[0].geometry.location.lat(),
                long: response.results[0].geometry.location.lng(),
                name: response.results[0].formatted_address,
                address: response.results[0].formatted_address
              }
            }
          });
        } else {
          window.alert("No results found");
        }
      })
      .catch(e => window.alert("Geocoder failed due to: " + e));
  };

  return (
    <>
      <FormSection defaultExpanded title="Enter the location details">
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <LocationSearchInput
              name="location"
              onChange={handleChange}
              label="Location *"
              placeholder="Search a location"
              defaultOptions={values.location ? [values.location] : []}
              defaultValue={values.location || null}
              error={touched.location && errors.location}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <SelectInput
              name="type"
              label="Type *"
              placeholder="Select a location type"
              value={values.type}
              onChange={handleChange}
              options={locationTypes}
              isError={touched.type && errors.type}
              errorMessage={touched.type && errors.type}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="name"
              label="Name *"
              placeholder="Enter location name"
              value={values.name}
              onChange={handleChange}
              error={touched.name && errors.name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.mapRow}>
            <MarkerMap
              onMapClick={handleMapClick}
              initialFocusRegion={{
                lat: values?.location?.lat,
                long: values?.location?.long
              }}
              draggableMarkers
              markerType="glMarker"
              onDragEnd={event => {
                const [long, lat] = event.lngLat;

                const newLocationData = {
                  ...values.location,
                  lat,
                  long
                };

                setFieldValue("location", newLocationData);
              }}
              markers={markers}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Enter the contact information of this location">
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="contactFullName"
              label="Full name"
              placeholder="Enter full name"
              value={values.contactFullName}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="contactEmail"
              label="Email"
              placeholder="Enter email"
              value={values.contactEmail}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <PhoneInput
              name="contactPhone"
              label="Phone"
              placeholder="Enter phone number"
              value={values.contactPhone}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Enter the functional areas">
        <Box className={classes.functionalAreasContainer}>
          {addFunctionalAreasButton}
          {functionalAreaForms}
          <Box className={classes.addAnotherAreaButtonMobileContainer}>
            {AddAnotherFunctionalAreaButton}
          </Box>
        </Box>
      </FormSection>
      <Box className={classes.addAnotherAreaButtonDesktopContainer}>
        {AddAnotherFunctionalAreaButton}
      </Box>
      <FormSection title="Add notes for this location">
        <Box className={classes.notesContainer}>
          <TextAreaInput
            name="notes"
            label="Notes"
            placeholder="Enter location notes"
            value={values.notes}
            onChange={handleChange}
          />
        </Box>
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default AddLocationForm;
