import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components_v2/Typography/Typography';

const Step = ({ stepNumber, currentStep, lastStep }) => {
  const current = stepNumber <= currentStep;
  const currentStepLine = stepNumber < currentStep && currentStep !== 1;
  const textColor = current ? '#4001C5' : 'rgba(0, 0, 0, 0.3)';
  const dynamicCircleStyles = {
    background: current ? '#4001C5' : 'transparent',
    borderColor: current ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
  };
  const stepLineBackground = currentStepLine ? '#4001C5' : 'rgba(0, 0, 0, 0.1)';

  const stepLine = lastStep ? null : (
    <div
      style={{
        height: 4,
        width: 60,
        borderRadius: 4,
        background: stepLineBackground,
        margin: '0 4px',
      }}
    />
  );

  return (
    <>
      <Typography color={textColor} customVariant="labelBold">
        STEP {` `}
        {stepNumber}
      </Typography>
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: '50%',
          border: '2px solid',
          marginLeft: 8,
          ...dynamicCircleStyles,
        }}
      />
      {stepLine}
    </>
  );
};

Step.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  lastStep: PropTypes.bool.isRequired,
};

export default Step;
