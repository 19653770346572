export const parseJSON = (json, fallback) => {
  let parsed = null;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    throw Error(`JSON parse error ==> ${e}, for value ${json}`);
  }

  if (fallback) return parsed || fallback;
  return parsed;
};
export const stringifyJSON = (value, fallback) => {
  let stringified = null;
  try {
    stringified = JSON.stringify(value);
  } catch (e) {
    throw Error(`JSON stringify error ==> ${e}, for value ${value}`);
  }
  if (fallback) return stringified || fallback;
  return stringified;
};
