import React from 'react';
import PropTypes from 'prop-types';

import {
  ContainedButton,
  ContainedButtonSecondary,
} from './Partials/Contained';
import { SimpleButton, SimpleButtonSecondary } from './Partials/Simple';

function Button(props) {
  const { variant = 'contained', secondary, ...other } = props;

  if (variant === 'simple' && !secondary)
    return <SimpleButton {...other}>{props.children}</SimpleButton>;
  if (variant === 'simple' && secondary)
    return (
      <SimpleButtonSecondary {...other}>{props.children}</SimpleButtonSecondary>
    );
  if (variant === 'contained' && secondary)
    return (
      <ContainedButtonSecondary {...other}>
        {props.children}
      </ContainedButtonSecondary>
    );
  return <ContainedButton {...other}>{props.children}</ContainedButton>;
}

Button.propTypes = {
  variant: PropTypes.string,
  secondary: PropTypes.bool,
};

export default Button;
