import request from './request';

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const checkIfEmailValid = async (email) => {
  if (!emailRegExp.test(email)) {
    return false;
  }
  return true;
};

export async function checkIfUpdatedEmailExists(email, uneditedEmail) {
  // Check is necessary so that the route to check if email exists is not spammed by invalid email addresses
  if (!emailRegExp.test(email)) {
    return false;
  }

  if (email !== uneditedEmail) {
    const requestURL = process.env.REACT_APP_API_ENDPOINT
      ? `${process.env.REACT_APP_API_ENDPOINT}/helpers/checkemail`
      : ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/helpers/checkemail");

    const response = await request(requestURL, {
      method: 'POST',
      body: {
        email,
      },
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
      },
    });

    return response.available;
  }

  return true;
}

export const checkIfEmailExists = async (email) => {
  // Check is necessary so that the route to check if email exists is not spammed by invalid email addresses
  if (!emailRegExp.test(email)) {
    return false;
  }

  const requestURL = process.env.REACT_APP_API_ENDPOINT
    ? `${process.env.REACT_APP_API_ENDPOINT}/helpers/checkemail`
    : ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/helpers/checkemail");

  const response = await request(requestURL, {
    method: 'POST',
    body: {
      email,
    },
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
    },
  });

  return response.available;
};
