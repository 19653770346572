import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from 'components_v2/Typography/Typography';
import Logo from 'static/logo_blue_alt.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowsChevronLeft } from '../../Icons/Icons';
import CIB from '../../Buttons/CIB/CIB';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    background: '#FFF',
    position: 'relative',
    width: 697,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
    border: '1px solid rgba(0, 0, 0, 0.05)',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      // height: 'calc(100vh - 80px)',
      overflow: 'auto',
      paddingBottom: '80px',
      flex: 1
    },
  },
  cardContentRoot: {
    padding: '40px 88px 0 88px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 40,
    '&:last-child': {
      padding: 0,
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingTop: '72px',
    },
  },
}));

const AuthCard = ({
  headerText,
  description,
  children,
  icon,
  hasBackButton,
  history,
  emailSent,
}) => {
  const classes = useStyles();

  const iconContainer = icon ? (
    <div>
      <div
        style={{
          background: 'rgba(0, 0, 0, 0.8)',
          boxShadow: 'rgba(0, 0, 0, 0.3) 1px 8px 12px',
          width: 73,
          height: 73,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 32,
        }}
      >
        {icon}
      </div>
    </div>
  ) : null;

  const goBack = () => {
    history.goBack();
  };

  const backButton = (
    <CIB
      style={{ position: 'absolute', top: 24, left: 24 }}
      onClick={goBack}
      icon={ArrowsChevronLeft}
    />
  );
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  return (
    <MuiCard classes={{ root: classes.cardRoot }}>
      {hasBackButton ? backButton : null}
      <CardContent
        classes={{ root: classes.cardContentRoot }}
        style={isMobileView && emailSent ? { flex: 1 } : {}}
      >
        <Link to="/">
          <img style={{ marginBottom: 48 }} src={Logo} alt="RDE logo" />
        </Link>
        {iconContainer}
        {headerText
          ? (!isMobileView && (
              <Typography style={{ marginBottom: 16 }} customVariant="h3Bold">
                {headerText}
              </Typography>
            )) || (
              <Typography style={{ marginBottom: 16 }} customVariant="h4Bold">
                {headerText}
              </Typography>
            )
          : null}
        {description ? (
          <Typography
            style={{ maxWidth: 391 }}
            customVariant="bodySmallRegular"
          >
            {description}
          </Typography>
        ) : null}
      </CardContent>
      <div style={isMobileView && !emailSent ? { flex: 1 } : {}}>
        {children}
      </div>
    </MuiCard>
  );
};

AuthCard.propTypes = {
  children: PropTypes.element,
  icon: PropTypes.element,
  headerText: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  description: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
};

export default withRouter(AuthCard);
