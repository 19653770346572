import gql from "graphql-tag";

export default gql`
  query GetAllDrivers {
    users {
      id
      email
      firstName
      lastName
      phone
      confirmed
      company
      identifier
      username
      notes
      passengerPriority
      role {
        id
        name
      }
      assignedVehicles {
        notes
        created_at
        start
        stop
        wholeEvent
        vehicle {
          id
          type
          vehicleNumber
        }
      }
    }
  }
`;
