/* eslint-disable react/jsx-max-depth */
import { Typography, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "components_v2/Menu/Menu";
import NameDisplay from "components_v2/NameDisplay/NameDisplay";
import Timeline from "components_v2/Timeline/Timeline";
import TitleWithText from "components_v2/TitleWithText/TitleWithText";
import PassengerTooltip from "components_v2/Tooltip/PassengerTooltip";
import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Clear, MoreHorizontal } from "../Icons/Icons";
import { mockGetAllUpcomingByProject } from "./Booking.mock";
import { mobileViewBreakpoint } from "../../App.helper";
import NotAssigned from "../NotAssigned/NotAssigned";
import Loading from "components_v2/Loading/CircularProgress";
import PuDo from "./PuDo";
import {
  getStatusLabel,
} from "./BookingHelper";
const styles = theme => ({
  root: {
    width: "430px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "auto",
      overflowY: "auto"
    },
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      justifyContent: "flex-end"
    }
  },
  headerButton: {
    borderRadius: "90px",
    border: "1px solid rgba(0, 0, 0, 0.2)"
  },
  headerButtonText: {
    color: "rgba(197, 2, 2, 1)",
    fontSize: "10px",
    fontWeight: "900"
  },
  gridItem: {
    paddingRight: 8,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "50%"
    }
  },
  closeIconContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    display: "inline-flex",
    borderRadius: "50%",
    padding: "5px",
    color: "#000000"
  },
  closeIcon: {
    fontSize: "20px"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    background: "rgba(0, 0, 0, 0.02)",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      background: "#fff",
      boxShadow: "0px -10px 17px rgba(0, 0, 0, 0.1)"
    }
  },
  footerLinkText: {
    fontSize: "12px",
    fontWeight: "700",
    color: "rgba(64, 1, 197, 1)",
    cursor: "pointer"
  },
  footerLink: {
    textDecoration: "none"
  },
  footerMoreButton: {
    padding: "0",
    marginLeft: "16px",
    color: "#000000",

    "&:hover": {
      backgroundColor: "transparent"
    },

    backgroundColor: "transparent",
    border: "0px"
  },
  footerAssignDriverButton: {},
  details: {
    padding: "0 20px",
    flex: 1,
    overflowY: "auto"
  },
  detailsInfoText: {
    color: "rgba(0, 0, 0, 0.9)",
    fontSize: "14px"
  },
  moreMenu: {
    marginTop: "10px"
  }
});

// eslint-disable-next-line complexity
const BookingDetailCompact = ({
  classes,
  expanded,
  onClose,
  handleClose, // Injected by the modal component wrapper if inside a modal
  history,
  isHelpTutorial,
  data,
  isDriverScheduled = true,
  loading

}) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  let booking = { ...data };

  if (isHelpTutorial) {
    booking = mockGetAllUpcomingByProject.passengerschedules[0];
  }

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const driverschedule =
    booking && booking.driverschedules && booking.driverschedules.length
      ? booking.driverschedules[0]
      : {
          driver: <NotAssigned />,
          ridestatuses: [],
          vehicle: { make: "N/A", model: "", vehicleNumber: "" }
        };

  const passenger = (booking && booking.passenger) || {
    firstName: "N/A",
    lastName: "",
    phone: "N/A"
  };

  const ridestatuses = driverschedule.ridestatuses.map(data => ({
    label: data.status,
    status: data.status
  }));

  const createdBy = (booking && booking.createdBy) || {
    firstName: "N/A",
    lastName: ""
  };

  const make = driverschedule?.vehicle?.make || "";
  const model = driverschedule?.vehicle?.model || "";
  const vehicleNumber = driverschedule?.vehicle?.vehicleNumber || "";
  const vehicle =
    !make && !model && !vehicle ? "" : `${make} ${model}, ${vehicleNumber}`;

  return (
    <section className={classes.root}>
     { loading && 
     <Loading
          fullPage
          backgroundPosition="absolute"
          backgroundWidth="100%"
          backgroundHeight="100%"
        />}
      <header className={classes.header}>
        {/* <AlertTag type="critical" label="Accident" /> */}
        <IconButton
          disableRipple
          className={classes.closeIconContainer}
          onClick={handleClose || onClose}
        >
          <Clear className={classes.closeIcon} />
        </IconButton>
      </header>
      <Grid container className={classes.details}>
        <Collapse timeout={600} in={isExpanded} style={{ width: "100%" }}>
          <Grid container>
            <Grid className={classes.gridItem} item lg={3} md={6}>
              <TitleWithText
                label="Pick up time"
                text={moment.utc(data.startDate).format("HH:mm")}
              />
            </Grid>
            <Grid className={classes.gridItem} item lg={3} md={6}>
              <TitleWithText
                label="Module"
                text={booking.department ? booking.department.name : "N/A"}
              />
            </Grid>
            <Grid className={classes.gridItem} item lg={3} md={6}>
            <TitleWithText
              label="Status"
              text={getStatusLabel(data)}
            />
            </Grid>
            <Grid className={classes.gridItem} item lg={3} md={6}>
              <TitleWithText
                label="Booking ID"
                text={booking.bookingId || "N/A"}
              />
            </Grid>
          </Grid>

          <Divider />
        </Collapse>
        <Box
          display="flex"
          {...(isExpanded ? { my: 2 } : { mt: 3, mb: 1 })}
          style={{ width: "100%" }}
        >
          {isDriverScheduled && (
              <Grid item xs={12} md={12}>
                <PuDo data={data} />
              </Grid>
            )}
        </Box>
        <Collapse timeout={600} in={isExpanded} style={{ width: "100%" }}>
          <Divider />
          <Box
            display="flex"
            mt={1}
            flexDirection="column"
            style={{ width: "100%" }}
          >
            <Grid container>
              <Grid className={classes.gridItem} item md={6} lg={4}>
                <TitleWithText
                  label="Passenger"
                  text={<NameDisplay fullName={`${passenger.firstName} ${passenger.lastName}`} />}
                />
              </Grid>
              <Grid className={classes.gridItem} item lg={4} md={6}>
                <PassengerTooltip
                  passengers={[
                    { name: "Natalie Lancaster T.", phone: "+971 58 000 0000" },
                    { name: "Mohammed Bieber", phone: "+971 58 000 0000" },
                    { name: "Miguel De Unamuno", phone: "+971 58 000 0000" }
                  ]}
                  style={{ display: "inline-flex", flexDirection: "column" }}
                  placement="bottom-start"
                >
                  {/* <TitleWithText
                    label="Pax"
                    text={booking.numPassengers || 0}
                  /> */}
                </PassengerTooltip>
              </Grid>
              <Grid className={classes.gridItem} item md={6} lg={4}>
                <TitleWithText label="Contact" text={passenger.phone} />
              </Grid>

              <Grid className={classes.gridItem} item md={6} lg={4}>
                <TitleWithText
                  label="Ride booked by"
                  text={<NameDisplay fullName={`${createdBy?.firstName} ${createdBy?.lastName}`} />}
                />
              </Grid>
              <Grid className={classes.gridItem} item md={6} lg={4}>
                <TitleWithText
                  label="Driver"
                  text={
                    <NameDisplay
                      fullName={
                        isHelpTutorial
                          ? driverschedule.driverMock.fullName
                          : `${data?.driver?.firstName} ${data?.driver?.lastName}`
                      }
                    />
                  }
                />
              </Grid>
              <Grid className={classes.gridItem} item md={4} lg={4}>
                <TitleWithText label="Vehicle" text={data?.vehicleClass} />
              </Grid>
            </Grid>
            <Box display="flex" mt={3} style={{ width: "100%" }}>
              <Grid container md={12}>
                <TitleWithText
                  label="Additional"
                  text={data.notes}
                />
              </Grid>
            </Box>
          </Box>
        </Collapse>
        <Box display="flex" my={1} style={{ width: "100%" }}>
          <Grid container md={12} justify="flex-start" direction="row">
            <Grid item lg={12}>
              <TitleWithText
                label="Departure time"
                text={moment(Date.now()).format("HH:mm")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" mt={1} mb={4} style={{ width: "100%" }}>
          <Timeline
            className={classes.timeline}
            completedSteps={ridestatuses}
            isHelpTutorial={isHelpTutorial}
          />
        </Box>
      </Grid>
      <footer className={classes.footer}>
        <div onClick={toggleExpanded}>
          <Typography className={classes.footerLinkText}>
            {isExpanded ? "View Less" : "View More"}
          </Typography>
        </div>
        <aside>
          {/*! driverschedule.driver &&
          <ContainedButton className={classes.footerAssignDriverButton}>
            Assign Driver
          </ContainedButton>
          */}
          <Menu
            className={classes.moreMenu}
            render={handleClick => (
              <IconButton
                onClick={handleClick}
                disableRipple
                className={classes.footerMoreButton}
              >
                <MoreHorizontal />
              </IconButton>
            )}
            items={[
              {
                label: "Edit",
                onClick: () => {
                  // createLocationState and navigate to edit booking form
                  const route = `/project/${booking.event.id}/booking/${booking.id}/edit`;
                  history.push(route);
                }
              },
              { label: "Duplicate", onClick: () => {} },
              { label: "Share", onClick: () => {} },
              { label: "Copy Link", onClick: () => {} }
            ]}
          />
        </aside>
      </footer>
    </section>
  );
};

export default withStyles(styles)(withRouter(BookingDetailCompact));
