import gql from 'graphql-tag';

export default gql`
  query GetAllVehiclesBySupplier($id: ID, $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String) {
    vehiclesConnection(where: { 
      supplier: $id, 
      _or: [
        { vehicleNumber_contains: $filterKey }
        { make_contains: $filterKey }
        { model_contains: $filterKey }
        { plateNumber_contains: $filterKey }
        { year_contains: $filterKey }
        { color_contains: $filterKey }
        { vehicleClass: { name_contains: $filterKey } }
        { maxPassengers_contains: $filterKey }
      ] 
    }
    limit: $pageSize
    start: $startIndex
    sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        make
        model
        plateNumber
        year
        color
        maxPassengers
        vehicleNumber
        vehicleClass {
          id
          name
        }
        assignedDrivers {
          id
        }
      }
    }
  }
`;
