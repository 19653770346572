import * as Yup from 'yup';

import {
  getUploadDocuments,
  formatChangedFormValues,
  formatValues,
} from 'utils/formHelpers';
import { stringifyJSON } from 'utils/json';

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required').min(2, 'Too short!'),
  email: Yup.string().required('Required').email('Invalid email'),
  contactName: Yup.string().required('Required').min(2, 'Too short!'),
  location: Yup.object().nullable().required('Required'),
});

export const formatSubmissionValues = (values, initialValues, isUpdate) => {
  const formatConfig = {
    location: [
      { name: 'lat', format: 'location.lat' },
      { name: 'long', format: 'location.long' },
      {
        name: 'address',
        format: (vals) => `${vals.location.name}, ${vals.location.address}`,
      },
      { name: 'location', format: 'location.name' },
    ],
    companyName: null,
    officeType: null,
    phone: null,
    email: null,
    contactName: null,
    contactDepartment: null,
    contactPosition: null,
    documents: (vals) => {
      const uploadedDocuments = getUploadDocuments(values);
      const documents = [...(vals.documents || []), ...uploadedDocuments];
      return stringifyJSON(documents, '[]');
    },
  };

  if (isUpdate)
    return formatChangedFormValues(values, initialValues, formatConfig);
  return formatValues(values, formatConfig);
};

export const formatIncomingData = (data) => {
  const address = data?.address?.split(`${data.location}, `)[1];

  const location = {
    address,
    lat: data.lat,
    long: data.long,
    name: data.location,
  };

  return {
    location,
    companyName: data.companyName,
    officeType: data.officeType,
    phone: data.phone,
    email: data.email,
    contactName: data.contactName,
    contactDepartment: data.contactDepartment,
    contactPosition: data.contactPosition,
  };
};
