import React from 'react';
import { StarBorder } from '@material-ui/icons';

import Typography from 'components_v2/Typography/Typography';
import { getMultipleEventsData } from 'utils/listData';

import '../../../Bookings/mobile-actions.css';
import PhoneDisplay from '../../../PhoneDisplay/PhoneDisplay'
export const getMobileMenuItems = ({ history, setDeleteConfirmationModalData, row }) => ({
  right: [
    {
      text: " ",
      onClick: () => history.push(`/client/${row.id}/edit`),
      className: "mobile-actions-edit",
    },
  ],
  left: [
    {
      text: " ",
      onClick: row => {
        setDeleteConfirmationModalData({
          recordName: row.companyName,
          recordId: row.id,
          confirmationType: "delete",
          isNotDeleteAllowed: row.events.length > 0,
          deleteNotAllowedReason:
            "This client cannot be deleted because it is being used in the events.",
        });
      },
      className: "mobile-actions-delete"
    }
  ]
});

export const getHamburgerMenuItems = ({ history }) => [
  {
    label: 'Edit',
    onClick: (row) => history.push(`/client/${row.id}/edit`),
  },
];
export const getColumns = () => [
  {
    field: 'companyName',
    headerName: 'Company name',
    width: 3,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">
        {data.companyName}
      </Typography>
    ),
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 2,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">{data.location}</Typography>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone number',
    width: 2,
    render: (data) => (
      <PhoneDisplay phone={data.phone}></PhoneDisplay>

    ),
  },
  {
    field: 'events',
    headerName: 'Events',
    width: 2,
    isSortable: false,
    hideMobile: true,
    render: (data) => {
      const [firstEvent, otherEventsCount] = getMultipleEventsData(
        data.events,
        (events) => events[0].name
      );

      return otherEventsCount ? (
        <>
          <Typography customVariant="bodySmallRegular">{firstEvent}</Typography>
          <Typography customVariant="bodySmallRegular">
            {`+ ${otherEventsCount} more`}
          </Typography>
        </>
      ) : (
        <Typography customVariant="bodySmallRegular">{firstEvent}</Typography>
      );
    },
  },


];

export const customActions = [
  {
    onClick: () => {

      return true;
    },
    label: 'Favorite',
    icon: StarBorder,
    successString: 'Client added to your favorites',
  },
];

export const actions = {
  rowClick: () => {

  },
};
