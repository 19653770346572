import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOneGQL from 'gql/Users/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import DriverComponent from './Driver.Component.jsx';

const DriverContainer = ({ match, history }) => {
  const { driverId, selectedTab } = match.params;

  const handleTabChange = (value) => {
    history.push(`/driver/${driverId}/${value}`);
  };

  const { data, loading } = useQuery(GetOneGQL, {
    variables: { id: driverId },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return (
    <DriverComponent
      driverId={driverId}
      data={data.user}
      selectedTab={selectedTab}
      onChangeTab={handleTabChange}
    />
  );
};

DriverContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withMainLayout('Driver', {}, withRouter(DriverContainer));
