import { makeStyles } from '@material-ui/core/styles';

export const useCustomStyles = makeStyles(() => ({
  inputWrapper: {
    marginBottom: '16px',
  },
}));

export const useStyles = makeStyles(() => ({
  root: {
    outline: 'none',

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '3px',
    },
    '&$focused > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #4001C5',
    },
    '&:hover > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #4001C5',
    },
    '& > textarea': {
      color: '#3F3B3B',
    },
    '&$disabled > textarea': {
      color: '#B9B9B9',
      'z-index': 1,
    },
    '&$disabled > .MuiOutlinedInput-notchedOutline': {
      background: 'rgba(0, 0, 0, 0.05)',
      border: '1px solid rgba(0, 0, 0, 0.05)',
    },
    '&$disabled:hover > .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.05)',
    },
  },
  focused: {},
  disabled: {},
}));
