import gql from 'graphql-tag';

export default gql`
  query GetGroupedByProject($id: ID!) {
    driveratingsConnection(where: { event: $id }) {
      aggregate {
        count
        avg {
          rating
        }
      }
      groupBy {
        rating {
          key
          connection {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
