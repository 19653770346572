export const checkUserPermission = (allPermissions, page) => {
  var permission = allPermissions.find(p => p.page.value === page);

  if (permission) {
    return permission.permissions;
  }

  return {
    canViewOwn: false,
    canView: false,
    canEdit: false,
    canCreate: false,
    canDelete: false
  };
};
