import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import CircularProgress from 'components_v2/Loading/CircularProgress';
import Tooltip from 'components_v2/Tooltip/Tooltip';
import { Clear, Edit, Save } from '../Icons/Icons';
import { eventStopPropagation } from '../../App.helper';



const EditAction = (props) => {
  const {
    actions,
    row,
    setNewMode,
    idSuffix,
    editMode,
    loading,
    values,
    setDynamicListState
  } = props;

  let rowId = `edit-action-button_${row.id}`;
  if (idSuffix) rowId += idSuffix;

  return (
    <>
      {(actions?.edit?.onSave || actions?.create?.onSave || actions?.edit?.onClick) && (
        <>
          {
            <>
              {loading && (
                <CircularProgress
                  size={32}
                  style={{ padding: '0 16px', verticalAlign: 'middle' }}
                />
              )}
            </>
          }

          {!editMode && !loading && (actions?.edit?.onSave || actions?.edit?.onClick) && (
            <Tooltip title="Edit" placement="bottom">
              <IconButton
                id={rowId}
                onClick={(e) => {
                  eventStopPropagation({ event: e });
                  if (actions?.edit?.onClick) actions.edit.onClick({ id: values.id });
                  else setDynamicListState({ editMode: !editMode });
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {editMode && !loading && (
            <>
              <Tooltip title="Clear" placement="bottom">
                <IconButton
                  onClick={async (e) => {
                    eventStopPropagation({ event: e })

                    setDynamicListState({ editMode: !editMode });
                    setNewMode(false);
                    // actions.edit.onClick(row);
                  }}
                >
                  <Clear fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Save" placement="bottom">
                <IconButton
                  onClick={async (e) => {
                    eventStopPropagation({ event: e })

                    setDynamicListState({ loading: true });

                    const submissionValues = values;
                    const id = submissionValues.id;
                    delete submissionValues.__typename;
                    delete submissionValues.id;

                    // if new item
                    if (!id) {
                      await actions.create.onSave({
                        values: submissionValues,
                      });
                      setNewMode(false);
                    } else {
                      await actions.edit.onSave({
                        id,
                        values: submissionValues,
                      });
                    }

                    setDynamicListState({
                      editMode: !editMode,
                      loading: false,
                    });
                    // actions.edit.onClick(row);
                  }}
                >
                  <Save fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditAction;