import React from "react";
import logo from "../../static/MOTUS_COLOR_LOGO.png";
import "./Reports.css";

const PageTemplate = props =>
  <React.Fragment>
    <div
      style={{
        position: "absolute",
        top: ".5em",
        left: "3em"
      }}
      className="report-font"
    >
      <p>{props.title}</p>
    </div>

    <img alt="" style={{ position: "absolute", top: "2em", right: "3em" }} src={logo} height={25} />

    <hr
      style={{
        position: "absolute",
        border: "0",
        borderTop: "1px solid #eee",
        top: "5em",
        left: "0",
        right: "0"
      }}
    />

    <div
      style={{
        position: "absolute",
        bottom: "2.5em",
        right: "4.5em",
        display: "block"
      }}
      className="report-font"
    >
      {(props.pageNum && props.totalPages) && `Page ${props.pageNum} of ${props.totalPages}`}
    </div>
  </React.Fragment>


export default PageTemplate;
