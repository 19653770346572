import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Vehicles/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import VehicleComponent from './Vehicle.Component.jsx';

const VehicleContainer = ({ match, history }) => {
  const { vehicleId, selectedTab } = match.params;

  const handleTabChange = (value) => {
    history.push(`/vehicle/${vehicleId}/${value}`);
  };

  const { data, loading } = useQuery(GetOne, {
    variables: { id: vehicleId },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return (
    <VehicleComponent
      vehicleId={vehicleId}
      data={data?.vehicles[0]}
      selectedTab={selectedTab}
      onChangeTab={handleTabChange}
    />
  );
};

VehicleContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withMainLayout('Vehicle', {}, withRouter(VehicleContainer));
