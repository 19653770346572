import Typography from 'components_v2/Typography/Typography';
import React from 'react';
import {
  vehicleOptions,
  driverOptions,
  getAlertTag,
} from './ServiceTrackingHelper';
import { editActionButtonSelector } from '../../App.helper'
export const getMobileMenuItems = ({ row }) => {
  return {
    right: [
      {
        text: " ",
        onClick: () => {
          const clickedButton = document.querySelector(
            "#" + editActionButtonSelector + row.id
          );
          if (clickedButton) clickedButton.click()
        },
        className: "mobile-actions-edit"
      }
    ]
  }
}
export const getColumns = (trackDriver, data) => {
  const columnsList = [



    {
      field: 'type',
      headerName: 'Type',
      width: 2,
      options: (trackDriver && driverOptions) || vehicleOptions,
      render: (data) => {
        const trackingType = trackDriver ? data?.drivertrackingtype?.name : data?.vehicletrackingtype?.name;
        // using the or conditional for backwards compatibility
        const type = trackingType || data?.type?.name;
        return (
          <Typography customVariant="bodySmallRegular">{`${type}`}</Typography>
        );
      },
    },
    {
      field: 'dateOfService',
      headerName: 'Date of service',
      width: 2,
      type: 'date',
    },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 2,
      type: 'number',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 2,
      hideMobile: true,
    },
    {
      field: 'numberOfDocuments',
      headerName: 'Documents',
      width: 2,
      options: (trackDriver && driverOptions) || vehicleOptions,
      hideMobile: true,
      render: (data) => (
        <Typography customVariant="bodySmallRegular">{`${data.documents ? JSON.parse(data.documents).length : 0
          }`}</Typography>
      ),
    },


  ];

  if (!trackDriver)
    columnsList.push({
      field: 'alerts',
      headerName: 'Alerts',
      width: 2,
      isEditDisabled: true,
      hideMobile: true,
      render: (row) => getAlertTag(row, data),
    });

  return columnsList;
};
