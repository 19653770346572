import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ContentTag from '../../ContentTag/ContentTag';
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  filtersSelectedWrapper: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 16px',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '6px',
  },
  title: {
    padding: '8px 0',
    fontSize: '14px',
    lineHeight: '134.3%',
    color: '#000000',
  },
  highlightedTitle: {
    color: '#4001C5',
    fontWeight: 'bold'
  },
  clearAll: {
    padding: '8px 0',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: 'rgba(0, 0, 0, 0.8)',
    cursor: 'pointer',
    fontWeight: 'bold',

    '&:hover': {
      color: '#4001C5',
    },
  },
  chipsWrapper: {
    margin: '4px 4px',

    '& .MuiChip-root': {
      margin: '4px 4px',
    },
  },
}));

const FiltersSelected = ({ searchTerm, filtersSelected, onDelete, onClearAll }) => {
  const classes = useStyles();

  const renderFilterChips = () => (
    <div className={classes.chipsWrapper}>
      {_.keys(filtersSelected).map((key, index) => (
        <ContentTag
          key={index}
          tag={{ [key]: filtersSelected[key] }}
          onDelete={onDelete}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.filtersSelectedWrapper}>
      <span className={classNames(classes.title, {
        [classes.highlightedTitle]: searchTerm?.filterKey
      })}>Filters Selected</span>
      {renderFilterChips()}
      <span className={classes.clearAll} onClick={onClearAll}>
        Clear All
      </span>
    </div>
  );
};

export default FiltersSelected;
