import gql from "graphql-tag";

export default gql`
  query GetEvent($id: ID!) {
    events(where: { id: $id }) {
      id
      name
      start
      end
      inChargeName
      inChargeEmail
      inChargeMobile
      client
      clientPriceFormat
      clientPriceMargin
      status
      transferservices {
        id
        name
        enabled
      }
      products {
        id
        name
        price
        description
        icon
      }
      projectClient {
        id
        contactName
        companyName
        location
        officeType
        address
        phone
        email
        lat
        long
        events {
          id
          name
        }
      }
      country
      viewport
      bookingEmailRecepients
      logoImageUrl
      locationInput
      bookingEmailToPassenger
      bookingTextToPassenger
      bookingEmailToCreator
      fromEmail
      type
      bookingPrefix
      archived
      currency
      chargeForNoShow
      fullChargeTimeframe
      timeFrameBeforeBooking
      transferEnabled
      trackTransferEnabled
      fourHourEnabled
      sixHourEnabled
      twelveHourEnabled
      twentyFourHourEnabled
      timezone
      fleet
      requiredStaff
      fleetSuppliers
      backgroundAlertsEnabled
      lateArrivalTimeframe
      defaultTransferTime
      availabilityAfterTransfer
      modules {
        id
        name
        description
        usereventmodules {
          id
          user {
            id
          }
        }
        departments {
          id
          name
        }
      }
    }
    products(where: { event: $id }) {
      id
      name
      description
      price
      icon
    }
  }
`;
