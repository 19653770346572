import gql from 'graphql-tag';

export default gql`
  mutation UpdatePassengerSchedule(
    $id: InputID!
    $data: editPassengerscheduleInput
  ) {
    updatePassengerschedule(input: { where: $id, data: $data }) {
      passengerschedule {
        id
        bookingId
        cancelled
        alerts {
          id
          type
          description
          status
        }
      }
    }
  }
`;
