import React from "react";
import DynamicList from "components_v2/List/DynamicList";
import PropTypes from "prop-types";
import { getColumns } from "./ListOptions";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withUserRole from "components_v2/Wrappers/withUserRole";
import { mobileViewBreakpoint, editActionButtonSelector } from "../../../../App.helper";
import _ from "lodash";


const PricingSettingComponent = ({
  data,
  loading,
  // createVehicleClass,
  updateVehicleClass,
  refetch,
  permissions,
  setSort,
  setFilterKey,
  sort
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  return (
    <>
      <DynamicList
        filterActions={[
          {
            type: "text",
            onChange: (val) => {
              setFilterKey(val)
            },
            label: "Search",
          },
        ]}
        sortActions={{
          currentSort: sort,
          onChangeSort: setSort
        }}
        idSuffix={'_client_pricing'}
        isMobileView={isMobileView}
        showHeader
        noResultsText="No pricing settings"
        loading={loading}
        data={data}
        title="Client Pricing"
        numRows={data.length}
        columns={getColumns(isMobileView)}
        getMobileMenuItems={(row) => {
          return {
            right: [
              ...(permissions.canEdit
                ? [
                  {
                    text: " ",
                    onClick: () => {
                      const clickedButton = document.querySelector(
                        "#" + editActionButtonSelector + row.id + '_client_pricing'
                      );
                      if (clickedButton) clickedButton.click();
                    },
                    className: "mobile-actions-edit",
                  },
                ]
                : []),
            ],
          };
        }}
        actions={
          permissions.canEdit
            ? {
              edit: {
                onSave: async ({ id, values }) => {
                  await updateVehicleClass({
                    variables: {
                      id: { id },
                      data: {
                        ...values,
                        type: values?.type?.value,
                        vehicles: values?.vehicles?.length ? _.map(values.vehicles, 'id:') : []
                      },
                    },
                  });

                  await refetch();
                },
              },
              // create: {
              //   onSave: async ({ values }) => {
              //     await createVehicleClass({
              //       variables: {
              //         data: {
              //           ...values,
              //           type: values.type && values.type.value,
              //           event: projectId,
              //         },
              //       },
              //     });

              //     await refetch();
              //   },
              // },
            }
            : undefined
        }
      />
    </>
  );
};

export default withUserRole("routePricing", PricingSettingComponent);

PricingSettingComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired
};
