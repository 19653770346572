import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import GetAllDocumentsByModule from 'gql/Documents/GetAllByModule';
import GetAllModulesByProject from 'gql/Modules/GetAllByProject';
import DocumentComponent from './Documents.Component';
import ModuleComponent from './Modules';
import { getFilteredDocuments, getSortedDocuments } from './helper';

const MainContainer = ({ projectId, isHelpTutorial }) => {
  const [moduleId, setModuleId] = useState(null);
  const [moduleName, setModuleName] = useState(null);


  if (moduleId) {
    return (
      <DocumentsContainer
        moduleId={moduleId}
        moduleName={moduleName}
        setModuleId={setModuleId}
      />
    );
  }
  return (
    <ModulesContainer
      projectId={projectId}
      setModuleId={setModuleId}
      setModuleName={setModuleName}
      isHelpTutorial={isHelpTutorial}
    />
  );
};

const DocumentsContainer = ({ moduleId, moduleName, setModuleId }) => {
  const [filterKey, setFilterKey] = useState('');
  const [sort, setSort] = useState(null);
  // if projectId is set then show documents otherwise show modules
  const { data, loading, refetch } = useQuery(GetAllDocumentsByModule, {
    variables: { id: moduleId },
    fetchPolicy: 'no-cache',
  });

  let finalData = getFilteredDocuments({ documents: data?.documents, filterKey });
  finalData = getSortedDocuments({ documents: finalData, sort })

  return (
    <DocumentComponent
      sort={sort}
      setSort={setSort}
      setFilterKey={setFilterKey}
      loading={loading}
      data={(finalData) || []}
      moduleName={moduleName}
      moduleId={moduleId}
      refetchDocuments={refetch}
      setModuleId={setModuleId}
    />
  );
};

const ModulesContainer = ({
  projectId,
  setModuleId,
  setModuleName,
  isHelpTutorial,
}) => {
  const [sort, setSort] = useState(null);

  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })

  let { data, loading, refetch} = useQuery(GetAllModulesByProject, {
    variables: {id: projectId, ...listConfig, sort: listConfig.sort || 'id:desc'},
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  const currentData = data?.modulesConnection?.values  || [];
  const count = data?.modulesConnection?.aggregate?.count|| 0;

  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }

  return (
    <ModuleComponent
      sort={sort}
      setSort={setSort}
      loading={loading}
      data={currentData}
      setModuleId={setModuleId}
      setModuleName={setModuleName}
      refetchModules={refetch}
      handleListConfigChange={handleListConfigChange}
      listConfig={{...listConfig, count}}
      numRows={count}
    />
  );
};

export default MainContainer;
