import React from "react";
import { withRouter } from "react-router";
import { Box } from "@material-ui/core";
import FormSection from "components_v2/Inputs/FormSection/FormSection";
import Typography from "components_v2/Typography/Typography";
import InputLabel from "components_v2/Inputs/InputLabel/InputLabel.jsx";
import Loading from "components_v2/Loading/CircularProgress";
import { useStyles } from "./ViewDriver.style";
import Img from "../../../Img/Img";

const ViewDriverComponent = ({ data, loading }) => {
  const classes = useStyles();
  return (
    <Box position="relative">
      {loading && (
        <Loading
          fullPage
          backgroundWidth="100%"
          backgroundHeight="100%"
          backgroundPosition="absolute"
          zIndex={1199}
        />
      )}
      <FormSection isNoAccordion>
        <Box className={classes.driverDetailsContainer}>
          <Box className={classes.firstNameRow}>
            <InputLabel label="First name" />
            <Typography customVariant="bodySmallRegular">
              {data.firstName || "---"}
            </Typography>
          </Box>
          <Box className={classes.lastNameRow}>
            <InputLabel label="Last name" />
            <Typography customVariant="bodySmallRegular">
              {data.lastName || "---"}
            </Typography>
          </Box>
          <Box className={classes.phoneRow}>
            <InputLabel label="Phone" />
            <Typography customVariant="bodySmallRegular">
              {data.phone || "---"}
            </Typography>
          </Box>
          <Box className={classes.whatsAppRow}>
            <InputLabel label="WhatsApp" />
            <Typography customVariant="bodySmallRegular">
              {data.whatsapp || "---"}
            </Typography>
          </Box>
          <Box className={classes.emailRow}>
            <InputLabel label="Email" />
            <Typography customVariant="bodySmallRegular">
              {data.email || "---"}
            </Typography>
          </Box>
          <Box className={classes.nationalityRow}>
            <InputLabel label="Nationality" />
            <Typography customVariant="bodySmallRegular">
              {data.nationality || "---"}
            </Typography>
          </Box>
          <Box className={classes.licenseNumberRow}>
            <InputLabel label="License number" />
            <Typography customVariant="bodySmallRegular">
              {data.licenseNumber || "---"}
            </Typography>
          </Box>
          <Box className={classes.passportNumberRow}>
            <InputLabel label="Passport number" />
            <Typography customVariant="bodySmallRegular">
              {data.passportNumber || "---"}
            </Typography>
          </Box>
          <Box className={classes.visaNumberRow}>
            <InputLabel label="Visa number" />
            <Typography customVariant="bodySmallRegular">
              {data.visaNumber || "---"}
            </Typography>
          </Box>

          <Box className={classes.addPhotoRow}>
            <Img
              style={{ maxWidth: "50%", maxHeight: "100%", objectFit: "cover" }}
              src={data.profileImage}
              alt={data.firstName}
            />
          </Box>
        </Box>
      </FormSection>
    </Box>
  );
};

export default withRouter(ViewDriverComponent);
