import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";

const DynamicReportSkeleton = () => {
  return (
    <Grid container spacing={3}>
          <Grid item sm={12} md={12} xs={12} lg={6}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12} xs={12} lg={6}>
              <Skeleton
                variant="rect"
                width={"100%"}
                height={40}
                style={{ margin: "16px 0" }}
              />
              <Skeleton
                variant="rect"
                width={"100%"}
                height={180}
              />
              </Grid>
              <Grid item sm={12} md={12} xs={12} lg={6}>
              <Skeleton
                variant="rect"
                width={"100%"}
                height={40}
                style={{ margin: "16px 0" }}
              />
              <Skeleton
                variant="rect"
                width={"100%"}
                height={180}
              />
              </Grid>

              <Grid item sm={12} md={12} xs={12} lg={12}>
                <Skeleton
                  variant="rect"
                  width={"100%"}
                  height={180}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} xs={12} lg={6}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={40}
            style={{ margin: "16px 0" }}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={380}
          />
          </Grid>
          
      <Grid item sm={12} md={12} xs={12} lg={12}>
        <Skeleton
          variant="rect"
          width={"25%"}
          height={28}
          style={{ margin: "16px 0" }}
        />
        <Skeleton
          variant="rect"
          width={"100%"}
          height={28}
          style={{ margin: "16px 0" }}
        />
        <Skeleton
          variant="rect"
          width={"100%"}
          height={64}
          style={{ margin: "8px 0" }}
        />
      </Grid>
    </Grid>
  );
};

export default DynamicReportSkeleton;
