import gql from 'graphql-tag';

export default gql`
  query GetAllSuppliers {
    suppliers {
      id
      name
      contactName
      phone
      email
    }
  }
`;
