import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/FirstPage";
import ChevronRightIcon from "@material-ui/icons/FirstPage";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import withWidth from "@material-ui/core/withWidth";

import Avatar from "@material-ui/core/Avatar";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import CarIcon from "@material-ui/icons/DirectionsCarOutlined";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import GroupIcon from "@material-ui/icons/Group";
import compose from "recompose/compose";
import AddIcon from "@material-ui/icons/AddBox";
import LocationIcon from "@material-ui/icons/LocationCity";

import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import UserManagementIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import PaymentIcon from "@material-ui/icons/PaymentOutlined";

import LateArrivalsIcon from "@material-ui/icons/ReportProblemOutlined";
import TBDIcon from "@material-ui/icons/BugReportOutlined";

import AttendanceIcon from "@material-ui/icons/GolfCourseOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import CalendarIcon from "@material-ui/icons/CalendarTodayOutlined";
import DriverVehicleMappingIcon from "@material-ui/icons/TransferWithinAStationOutlined";

import PersonIcon from "@material-ui/icons/PersonOutlined";

import PassengersIcon from "@material-ui/icons/PeopleOutlined";
import EventTimelineIcon from "@material-ui/icons/EventNoteOutlined";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Message";
import auth from "../../utils/auth";
import { withRouter, Link } from "react-router-dom";
import Loading from "../../components/Loading/LinearProgress";
import EventPicker from "../../components_v2/Layout/EventPicker";
import Chat from "../../components/views/Chat/Chat";
import SimpleQuery from "../../containers/Query/SimpleQuery";
import GetAllByEventGQL from "../../gql/Drivers/GetAllByEventNoRole";
import { createChatNamePrefix, createChatNameBetweenUsers } from "../../utils/chatHelpers";
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Collapse from "@material-ui/core/Collapse";

import GetOneGQL from "../../gql/Events/GetOne";
import Paper from "@material-ui/core/Paper";

export default ({ children }) => {
  return (
    <div style={{ width: "100%", maxWidth: 920, margin: "0 auto", marginTop: 0 }}>
      <div style={{ padding: 20 }}>
        <div style={{ height: 100, padding: 50 }}>
          <img
            src="https://motusone.com/wp-content/uploads/2019/10/MOTUS_COLOR_LOGO-2.png"
            style={{ width: "200px" }}
          ></img>
        </div>

        <div style={{ margin: "0px auto" }}>
          <div style={{ padding: 50 }}>{children}</div>
        </div>
      </div>
    </div>
  );
};
