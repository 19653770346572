import gql from 'graphql-tag';

export default gql`
  query GetSupplier($id: ID!) {
    supplier(id: $id) {
      id
      name
      notes
      email
      contactName
      contactPosition
      contactDepartment
      phone
      address
    }
  }
`;
