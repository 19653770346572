import React from 'react';
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

// import Loading from 'components_v2/Loading/LinearProgress';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddTrackingComponent from "./AddTracking.Component";
import {
  GetVehicleTrackingById,
  GetDriverTrackingById,
} from './Gql';

const AddTrackingContainer = ({ match }) => {
  const { trackingId, driverId, vehicleId } = match.params;

  const query = driverId ? GetDriverTrackingById : GetVehicleTrackingById;

  const { data, loading, refetch } = useQuery(query, {
    variables: { id: trackingId },
    skip: !trackingId || (!driverId && !vehicleId),
    fetchPolicy: 'no-cache',
  });

  return (
    <AddTrackingComponent
      data={data?.driverongoingtracking || data?.vehiclemaintenancetracking}
      loading={loading}
      refetch={refetch}
      trackingId={trackingId}
      driverId={driverId}
      vehicleId={vehicleId}
    />
  )
}

export default withMainLayout('Add Tracking', {}, withRouter(AddTrackingContainer));
