import React, { useState } from "react";
import { Calendar } from "components_v2/Icons/Icons";
import DynamicList from "components_v2/List/DynamicList";
import DeleteItemGQL from "gql/ShuttleScheduleItems/Delete";
import { useMutation } from "@apollo/react-hooks";
import { CreateShuttlesScheduleItem, UpdateShuttlesScheduleItem } from "./gql";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  mobileViewBreakpoint,
  editActionButtonSelector,
  deleteActionButtonSelector
} from "../../../../App.helper";
import { deepCopy } from "../../../../utils/helpers";
import { getSortedShuttleShedules } from "./helper";
const ShuttleScheduleItems = ({
  shuttleScheduleItems,
  locationsObject,
  shuttleScheduleId,
  refetch
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const [deleteScheduleItem] = useMutation(DeleteItemGQL);

  const [updateScheduleItem] = useMutation(UpdateShuttlesScheduleItem);

  const [createScheduleItem] = useMutation(CreateShuttlesScheduleItem);

  const localShuttleScheduleItems = deepCopy(shuttleScheduleItems || []);

  const locationOptions = Object.keys(locationsObject).map(l => ({
    value: l,
    name: locationsObject[l]?.name ?? ""
  }));

  const shuttleScheduleItemsTransformed = localShuttleScheduleItems.map(
    shuttleSchedule => ({
      ...shuttleSchedule,
      location: {
        ...shuttleSchedule.location,
        defaultValue: {
          value: shuttleSchedule.location.id,
          name: locationsObject[shuttleSchedule.location.id].name
        }
      }
    })
  );
  // const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
  // let finalData = getFilteredShuttleShedules({ shuttleSchedules: shuttleScheduleItemsTransformed, filterKey });
  let finalData = getSortedShuttleShedules({
    shuttleSchedules: shuttleScheduleItemsTransformed,
    sort
  });

  return (
    <DynamicList
      // filterActions={[
      //   {
      //     type: "text",
      //     onChange: (val) => {
      //       setFilterKey(val)
      //     },
      //     label: "Search",
      //   },
      // ]}
      sortActions={{
        currentSort: sort,
        onChangeSort: setSort
      }}
      isMobileView={isMobileView}
      title="Shuttle Schedule Items"
      showHeader
      noResultsText="No shuttle schedule items"
      data={finalData}
      numRows={finalData.length}
      getMobileMenuItems={row => {
        return {
          left: [
            {
              text: " ",
              onClick: () => {
                const clickedButton = document.querySelector(
                  "#" + deleteActionButtonSelector + row.id
                );
                if (clickedButton) clickedButton.click();
              },
              className: "mobile-actions-delete"
            }
          ],
          right: [
            {
              text: " ",
              onClick: () => {
                const clickedButton = document.querySelector(
                  "#" + editActionButtonSelector + row.id
                );
                if (clickedButton) clickedButton.click();
              },
              className: "mobile-actions-edit"
            }
          ]
        };
      }}
      columns={[
        {
          field: "location",
          headerName: "Location",
          width: 4,
          options: locationOptions,
          isSortable: false,
          render: ({ location: { id } }) => {
            return locationsObject[id]?.name;
          }
        },
        {
          field: "time",
          headerName: "Time",
          type: "time",
          width: 4,
          render: ({ time }) => time
        }
      ]}
      customActions={[
        {
          onClick: async ({ name, location, time }) => {
            await createScheduleItem({
              variables: {
                data: {
                  location: location.id,
                  name: name,
                  shuttleschedule: shuttleScheduleId,
                  time: time
                }
              }
            });

            await refetch();
          },
          label: "Duplicate",
          icon: Calendar
        }
      ]}
      actions={{
        edit: {
          onSave: async ({
            id,
            values: {
              location: { value },
              name,
              time
            }
          }) => {
            await updateScheduleItem({
              variables: {
                id: { id },
                data: {
                  location: value,
                  name: name,
                  shuttleschedule: shuttleScheduleId,
                  time: time
                }
              }
            });

            await refetch();
          }
        },
        delete: {
          onClick: async ({ id }) => {
            await deleteScheduleItem({
              variables: {
                id: id
              }
            });

            await refetch();
          }
        },
        create: {
          onSave: async ({ values }) => {
            await createScheduleItem({
              variables: {
                data: {
                  location: values.location.value,
                  type: values?.type?.value,
                  shuttleschedule: shuttleScheduleId
                }
              }
            });

            await refetch();
          }
        }
      }}
    ></DynamicList>
  );
};

export default ShuttleScheduleItems;
