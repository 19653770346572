import gql from "graphql-tag";

export default gql`
  mutation CreateDocument($data: DocumentInput) {
    createDocument(input: { data: $data }) {
      document {
        id
      }
    }
  }
`;
