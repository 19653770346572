import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 66,
    background: '#FFF',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.05)',
    padding: '0 16px 0 24px',
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    flexShrink: 0,
    boxSizing: 'border-box',
  },
  rightLayoutTwoActions: {
    marginLeft: 'auto',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginLeft: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  },
  rightLayoutSingleAction: {
    marginLeft: 'auto',
  },
  leftLayout: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'none',
    },
  },
  divider: {
    width: 1,
    height: 24,
    background: 'rgba(0, 0, 0, 0.1)',
    margin: '0 24px',
  },
}));

export default useStyles;
