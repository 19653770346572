import gql from 'graphql-tag';

export default gql`
  mutation UpdateTransferservice($id: InputID, $data: editTransferserviceInput) {
    updateTransferservice(input: { where: $id, data: $data }) {
      transferservice {
        id
      }
    }
  }
`;