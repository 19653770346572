import "./mobile-actions.css";
import SendEmail from "./SendEmail";
import React from "react";
import AdjustOvertime from "./AdjustOvertime";
import DeleteBooking from "./DeleteBooking";
import ViewDocumentsOption from "./ViewDocuments/ViewDocumentsOption";
import CancelBooking from "./CancelBooking";
import AssignDriverOption from "./AssignDriver/AssignDriverOption";
import CopySMS from "./CopySMS";
import SendDriverLink from "./SendDriverLink";
export const getMobileMenuItems = ({
  history,

  row,
  type,
}) => ({
  right: [
    {
      text: "Duplicate",
      onClick: () =>
        history.push(
          `/project/${row.event.id}/booking/${row.passengerScheduleId}/duplicate`
        ),
      className: "mobile-actions-duplicate",
    },
    {
      text: "Copy link ",
      onClick: () => {
        const { origin } = window.location;
        const { bookingId } = row;
        const projectId = row.event.id;
        navigator.clipboard.writeText(
          `${origin}/project/${projectId}/bookings/${bookingId}`
        );
      },
      className: "mobile-actions-copy-link",
    },
    {
      text: "Open in new tab ",
      onClick: () => {
        const { origin } = window.location;
        const { bookingId } = row;
        const projectId = row.event.id;
        window.open(
          `${origin}/project/${projectId}/bookings/${bookingId}`,
          "_blank"
        );
      },
      className: "mobile-actions-open-in-new-tab",
    },
    {
      text: " Adjust overtime",
      className: "mobile-actions-overtime",
      onClick: () => {
        const clickedButton = document.querySelector(
          `#mobile-actions-overtime-${row.id}`
        );
        if (clickedButton) clickedButton.click();
      },
    },
    {
      text: "Add documents",
      onClick: () =>
        history.push(
          `/project/${row.event.id}/booking/${row.passengerScheduleId}/edit`
        ),
      className: "mobile-actions-add-documents",
    },
    {
      text: "View documents",
      className: "mobile-actions-view-documents",
      onClick: () => {
        const clickedButton = document.querySelector(
          `#view-booking-documents-action-button-${row.id}`
        );
        if (clickedButton) clickedButton.click();
      },
    },
    {
      text: "Copy SMS",
      className: "mobile-actions-copy-sms",
      onClick: () => {
        const clickedButton = document.querySelector(
          `#copy-sms-button-${row.id}`
        );
        if (clickedButton) clickedButton.click();
      },
    },


    ...(type === "History"
      ? [
        {
          text: "Ride Playback",
          onClick: () => {
            // Callback logic here
          },
          className: "mobile-actions-primary-actions",
        },
      ]
      : []),

    {
      text: " ",
      onClick: () =>
        history.push(
          `/project/${row.event.id}/booking/${row.passengerScheduleId}/edit`
        ),
      className: "mobile-actions-edit",
    },
  ],
  left: [
    {
      text: " ",
      onClick: () => {
        const clickedButton = document.querySelector(
          `#mobile-actions-delete-${row.id}`
        );
        if (clickedButton) clickedButton.click();
      },
      className: "mobile-actions-delete",
    },
    ...(type !== "History" && !row.passengerScheduleCancelled && !row.cancelled & row.ridestatuses.length !== 6
      ? [
        {
          text: "Cancel",
          onClick: () => {
            const clickedButton = document.querySelector(
              `#mobile-actions-cancel-${row.id}`
            );
            if (clickedButton) clickedButton.click();
          },
          className: "mobile-actions-cancel",
        },
      ]
      : []),
    ...(type !== "History" && !row.driver
      ? [
        {
          text: "Assign Driver",
          onClick: () => {
            const clickedButton = document.querySelector(
              `#assign-driver-action-button-${row.id}`
            );
            if (clickedButton) clickedButton.click();
          },
          className: "mobile-actions-primary-actions",
        },
      ]
      : []),
    ...(type !== "History" && row.driver && row.ridestatuses.length !== 6
      ? [
        {
          text: "Re-assign Driver",
          onClick: () => {
            const clickedButton = document.querySelector(
              `#assign-driver-action-button-${row.id}`
            );
            if (clickedButton) clickedButton.click();
          },
          className: "mobile-actions-primary-actions",
        },
      ]
      : []),
    {
      text: "Send email confirmation",
      className: "mobile-actions-resend-email",
      onClick: () => {
        const clickedButton = document.querySelector(
          `#send-email-button-${row.id}`
        );
        if (clickedButton) clickedButton.click();
      },
    },
    ...(row.driver
      ? [
        {
          text: "Send driver link",
          className: "mobile-send-driver-link",
          onClick: () => {
            const clickedButton = document.querySelector(
              `#send-driver-link-button-${row.id}`
            );
            if (clickedButton) clickedButton.click();
          },
        },]
      : []),
  ],
});

export const getHamburgerMenuItems = ({
  history,
  currentRow,
  type,
  onDelete,
  onDeleteLoading,
  onSendEmailLoading,
  setUpdatingBookings
}) => [
    {
      label: "Edit",
      onClick: (row) =>
        history.push(
          `/project/${row.event.id}/booking/${row.passengerScheduleId}/edit`
        ),
    },
    {
      label: "Duplicate",
      onClick: (row) => {
        history.push(
          `/project/${row.event.id}/booking/${row.passengerScheduleId}/duplicate`
        );
      },
    },
    // {
    //   isDivider: true
    // },
    // {
    //   label: "Share",
    //   onClick: row => {}
    // },
    {
      label: "Share",
      onClick: (row) => history.push(`/trackride/${row.id}`),
    },
    {
      label: "Copy link",
      onClick: (row) => {
        const { origin } = window.location;
        const { bookingId } = row;
        const projectId = row.event.id;
        navigator.clipboard.writeText(
          `${origin}/project/${projectId}/bookings/${bookingId}`
        );
      },
    },
    {
      label: "Open in new tab",
      onClick: (row) => {
        const { origin } = window.location;
        const { bookingId } = row;
        const projectId = row.event.id;
        window.open(
          `${origin}/project/${projectId}/bookings/${bookingId}`,
          "_blank"
        );
      },
    },
    {
      isDivider: true,
    },
    {
      render: () => {
        return (
          <SendEmail
            onSendEmailLoading={onSendEmailLoading}
            data={currentRow}
            id={`send-email-button-${currentRow.id}`}
          />
        );
      },
    },
    ...(currentRow.driver
      ? [
        {

          render: () => {
            return (
              <SendDriverLink
                data={currentRow}
                id={`send-driver-link-button-${currentRow.id}`}
              />
            );
          },
        },]
      : []),
    {
      render: () => {
        return (
          <CopySMS id={`copy-sms-button-${currentRow.id}`} data={currentRow} />
        );
      },
    },
    // {
    //   label: "Send email confirmation",
    //   onClick: row => {
    //     setResendEmailConfirmationModalData({
    //       record: row
    //     });
    //   }
    // },
    // {
    //   label: "Adjust overtime",
    //   onClick: row => {
    //     setAdjustOvertimeModalData({
    //       record: row
    //     });
    //   }
    // },
    {
      render: () => {
        return (
          <AdjustOvertime
            id={`mobile-actions-overtime-${currentRow.id}`}
            data={currentRow}
          ></AdjustOvertime>
        );
      },
    },
    {
      isDivider: true,
    },
    ...(type !== "History" && currentRow.driver &&  currentRow?.ridestatuses.length !== 6
      ? [
        {
          render: () => {
            return (
              <AssignDriverOption
                data={currentRow}
                setUpdatingBookings={setUpdatingBookings}
              />
            );
          },
        },
      ]
      : []),
    {
      label: "Add documents",
      onClick: (row) =>
        history.push(
          `/project/${row.event.id}/booking/${row.passengerScheduleId}/edit`
        ),
    },
    // {
    //   label: "View documents",
    //   onClick: row => {
    //     const clickedButton = document.querySelector(
    //       `#view-booking-documents-action-button-${row.id}`
    //     );
    //     if (clickedButton) clickedButton.click();
    //   }
    // },
    {
      render: () => {
        return <ViewDocumentsOption data={currentRow} />;
      },
    },
    {
      isDivider: true,
    },

    ...(type !== "History" &&
      !currentRow.passengerScheduleCancelled &&
      !currentRow.cancelled &&
      currentRow?.ridestatuses.length !== 6
      ? [
        {
          render: () => {
            return (
              <CancelBooking
                id={`mobile-actions-cancel-${currentRow.id}`}
                data={currentRow}
              ></CancelBooking>
            );
          },
        },
        // {
        //   label: "Cancel",
        //   onClick: row => {
        //     setConfirmationModalData({
        //       recordName: row.bookingId,
        //       bookingId: row.passengerScheduleId,
        //       driverScheduleId: row.id,
        //       driverScheduleCancelled: row.cancelled,
        //       passengerScheduleCancelled: row.passengerScheduleCancelled,
        //       confirmationType:
        //         currentRow?.cancelled || currentRow?.passengerScheduleCancelled
        //           ? "re-enable"
        //           : "cancel",
        //       numberOfVehicles: !parseInt(row.numberOfVehicles)
        //         ? 0
        //         : parseInt(row.numberOfVehicles)
        //     });
        //   },
        //   className: "mobile-actions-cancel"
        // }
      ]
      : []),
    {
      render: () => {
        return (
          <DeleteBooking
            onDelete={onDelete}
            onDeleteLoading={onDeleteLoading}
            id={`mobile-actions-delete-${currentRow.id}`}
            data={currentRow}
          />
        );
      },
    },

    // {
    //   label: "Delete",
    //   onClick: row => {
    //     setConfirmationModalData({
    //       recordName: row.bookingId,
    //       recordId: row.passengerScheduleId,
    //       confirmationType: "delete"
    //     });
    //   }
    // }
    /* {
          label: "Delete",
          onClick: row => {}
        } */
  ];
