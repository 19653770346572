import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const PublicisTextWithTopLabel = withStyles({})(
  class extends React.Component {
    render() {
      const {
        label,
        text,
        white,
        noText = false,
        noStyling,
        additionalTextStyles = {},
        additionalLabelStyles = {},
      } = this.props;

      if (!text && !noText) {
        return <></>;
      }

      return (
        <>
          <Typography
            style={{
              fontSize: noStyling ? undefined : '12px',
              lineHeight: noStyling ? undefined : '16px',
              letterSpacing: '0.3px',
              color: white ? '#fff' : 'rgba(0, 0, 0, 0.5)',
              margin: '8px 0px',
              ...additionalLabelStyles
            }}
          >
            {label}
          </Typography>

          <Typography
            style={{
              fontSize: noStyling ? undefined : '14px',
              lineHeight: noStyling ? undefined : '19px',
              color: white ? '#fff' : 'rgba(0, 0, 0, 0.9)',
              order: '1',
              margin: '8px 0px',
              ...additionalTextStyles,
            }}
          >
            {text}
          </Typography>
        </>
      );
    }
  }
);

export default PublicisTextWithTopLabel;
