import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  toggleRow: {
    alignSelf: 'center',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: -12,
    },
  },
  photoRow: {
    '& > div': {
      height: 300,
    },
  },
  marginBot: {
    marginBottom: 12,
  },
  marginBotLarge: {
    marginBottom: 32,
  },
  alignFlexEnd: {
    alignSelf: 'flex-end',
  },
  addAnotherDriverButtonContainer: {
    margin: '40px 0',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  genderContainer:{
    "& .MuiFormGroup-root":{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    }
  }
}));

export default useStyles;
