import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UsersList from 'components_v2/List/Variations/UsersList/UsersList.Component';
import FAB from 'components_v2/Buttons/FAB/FAB';
import { FloatingActionNewPassenger } from 'components_v2/Icons/Icons';
import { mobileViewBreakpoint } from '../../../../App.helper';

const MainContainer = ({listConfig, history,handleListConfigChange, count ,data, loading }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  return (
    <div style={isMobileView ? { paddingBottom: 64 } : {}}>
      <FAB
        label="Add user"
        icon={FloatingActionNewPassenger}
        onClick={() => {
          history.push('/user/new');
        }}
      />
      <UsersList listConfig={listConfig}  handleListConfigChange={handleListConfigChange} count={count}data={data} loading={loading} />
    </div>
  )
};

MainContainer.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withRouter(MainContainer);
