import React from 'react';

import StickyHeader from 'components_v2/StickyHeader/StickyHeader';
import Typography from 'components_v2/Typography/Typography';
import { ToastInfo, DownloadTo, Clear } from '../Icons/Icons';
import useStyles from './DocumentPreview.style';

const getFileNameFromUrl = (url) => {
  const indexOfLastSlash = url.lastIndexOf('/');
  const fileName = url.substring(indexOfLastSlash + 1);

  return fileName;
};

const DocumentPreview = ({ src, fileSize, handleClose }) => {
  const fileName = getFileNameFromUrl(src);
  const classes = useStyles();

  const purple = '#4001C5';
  const black = '#000';

  const rightContent = (
    <div className={classes.rightContent}>
      <DownloadTo
        style={{ color: purple, height: 11.6, width: 'auto', marginRight: 6 }}
      />
      <a style={{ textDecoration: 'none' }} href={src} download={fileName}>
        <Typography customVariant="ctaBold" color={purple}>
          Download file &nbsp;
        </Typography>
      </a>
      <Typography customVariant="bodySmallRegular">{`${fileSize} KB`}</Typography>
      <button
        type="button"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <Clear
          style={{
            color: black,
            height: 14,
            width: 'auto',
            pointerEvents: 'none',
          }}
        />
      </button>
    </div>
  );

  const leftContent = (
    <div className={classes.leftContent}>
      <ToastInfo
        style={{ color: black, width: 19, height: 'auto', marginRight: 12 }}
      />

      <Typography customVariant="bodySmallRegular">{fileName}</Typography>
    </div>
  );

  return (
    <div style={{ height: '100vh' }}>
      <StickyHeader
        columnMobileView
        leftContent={leftContent}
        rightContent={rightContent}
        sticky
      />
      <iframe
        style={{
          width: '100%',
          height: 'calc(100vh - 56px)',
          margin: '0 auto',
        }}
        src={src}
      />
    </div>
  );
};

export default DocumentPreview;
