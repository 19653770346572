import { makeStyles } from "@material-ui/core/styles";
import {
  mobileViewBreakpoint,
  extraSmallMobileBreakpoint,
} from "../../App.helper";

const useStyles = makeStyles((theme) => ({
  publicDescription: {
    display: "block",
  },
  publicDescriptionEmail: {
    display: "block",
    margin: "24px auto",
    maxWidth: 300,
    textAlign: "center",
  },
  publicDescriptionPhone: {
    display: "block",
    margin: "12px 0 0 0",
  },
  publicDescriptionLink: {},
  passengerEmail: {
    fontWeight: 800,
  },
  bookingInfoContainer: {
    padding: "24px",
  },
  upperBookingInfo: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  upperBookingLeft: {
    flexGrow: 1,
    display: "grid",
    rowGap: "8px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "none",
    },
  },
  upperBookingRight: {
    width: "66.6%",
    marginLeft: "40px",
    display: "grid",
    rowGap: "8px",
    columnGap: "40px",
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "none",
    },
  },
  gridSpacer: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "none",
    },
  },
  lowerBookingInfo: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: "40px",
    rowGap: "8px",
  },
  vehicleCountRow: {
    gridColumn: 1,
    gridRow: 1,
    "& .MuiTextField-root": {
      width: "64px",
    },
    "& input": {
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  vehicleTypeRow: {
    gridColumn: 1,
    gridRow: 2,
  },
  serviceDurationRow: {
    gridColumn: 1,
    gridRow: 3,
  },
  notesRow: {
    gridColumn: 1,
    gridRow: 4,
  },
  discountRow: {
    gridColumn: 1,
    gridRow: 5,
  },
  divider: {
    marginTop: "40px",
    marginBottom: "30px",
    height: "1px",
    backgroundColor: "#3C3C3C",
    width: "100%",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: 48,
    },
  },
  timeRow: {
    gridColumn: 1,
    gridRow: 1,
  },
  timelineContainer: {
    left: 0,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      position: "relative",
      left: -22,
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      position: "relative",
      left: 0,
    },
  },
  mapRow: {
    height: "300px",
    "& > div": {
      paddingLeft: 12,
      width: "calc(100% - 12px) !important",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        paddingLeft: 0,
        width: "100% !important",
      },
    },
  },
  dateRow: {
    gridColumn: 1,
    gridRow: 1,
  },
  routePlanningRow: {
    gridColumn: 1,
    gridRow: 2,
  },
  flightInfo: {
    display: "flex",
    width: "360px",
    marginRight: "40px",
  },
  flightNumberInput: {
    width: "75%",
    marginRight: "16px",
    flexShrink: 0,
  },
  terminalInput: {
    flexGrow: 1,
  },
  luggageCount: {
    marginTop: "40px",
  },

  flightInfoTitle: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: 24,
    },
  },

  gridItem: {
    marginTop: "16px",
  },

  vehicleInputItem: {
    marginTop: "28px",
  },
  passengerCount: {
    "& .MuiTextField-root": {
      width: "72px",
    },
  },
  checkboxContainer: {
    marginTop: "64px",
    marginBottom: "40px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "40px",

      padding: "0 32px",
    },
  },
  selectPassengers: {
    height: "100%",
  },
  gridContainerPassenger: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "12px 12px 0px 12px!important",
    },
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      padding: "12px 12px 0px 12px!important",
    },
  },
  gridContainerDetails: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "12px 12px 0px 12px!important",
    },
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      padding: "0px 12px 12px 12px!important",
    },
  },
  paddingTopBottomZero: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "12px 12px 12px 12px!important",
    },
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      padding: "0px 12px 0px 12px!important",
    },
  },
}));

export default useStyles;
