import { useQuery } from '@apollo/react-hooks';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import GetAllModulesByEvent from 'gql/Modules/GetAllByProject';
import React from 'react';

const ModulesContainer = (props) => {
  const { data, loading } = useQuery(GetAllModulesByEvent, {
    variables: { id: props.projectId, fetchPolicy: 'no-cache' },
  });

  if (loading) {
    return <div>loading..</div>;
  }

  const options = data.modules.map((mod) => ({
    name: mod.name,
    value: mod.id,
    departments: mod.departments,
  }));

  return <SelectInput {...props} options={options} />;
};

export default ModulesContainer;
