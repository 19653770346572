import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';

import StickyHeader from 'components_v2/StickyHeader/StickyHeader';
import Typography from 'components_v2/Typography/Typography';
import { UserBig } from 'components_v2/Icons/Icons';
import LogoBlue from 'static/logo-blue.png';

const useStyles = makeStyles({
  userIcon: {
    height: 13.3,
    marginRight: 3,
    '& path': {
      fill: '#4001C5',
    },
  },
});

const PublicLayout = ({ children, noAuthLinks, noPadding, fullWidth }) => {
  const classes = useStyles();

  const leftContent = (
    <Link to="/">
      <img src={LogoBlue} alt="RDE logo" />
    </Link>
  );

  const rightContent = (
    <Link
      style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
      to="/login"
    >
      <UserBig className={classes.userIcon} />
      <Typography
        customVariant="ctaBold"
        style={{ color: '#4001C5' }}
        component="span"
      >
        Login
      </Typography>
    </Link>
  );

  return (
    <div>
      <CssBaseline />
      <StickyHeader
        leftContent={leftContent}
        rightContent={noAuthLinks ? null : rightContent}
        sticky
        publicUrl
      />
      <div
        style={{
          padding: fullWidth ? '0px 0px' : (noPadding ? '24px 24px' : '56px 88px'),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'calc(100vh - 93px)',
          background:
            'linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)',
        }}
      >
        {children}
      </div>
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.element.isRequired,
  noAuthLinks: PropTypes.bool,
};

export default PublicLayout;
