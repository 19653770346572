import React,{useState} from 'react';
import GetAllByEvent from 'gql/VehicleClasses/GetAllByEvent';
import { withRouter } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {getSortedSupplierPricing,getFilteredSupplierPricing } from './helper'
import UpdateVehicleclass from 'gql/VehicleClasses/UpdateVehicleclass';
import SupplierPricingComponent from './SupplierPricing.Component';
import { mockData } from '../VehicleClasses/VehicleClasses.mock';

const SupplierPricingContainer = ({ projectId, isHelpTutorial }) => {
  let { data, loading, refetch } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });
  const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
  let finalData = getFilteredSupplierPricing({ supplierPricing: data?.vehicleclasses, filterKey });
  finalData = getSortedSupplierPricing({ supplierPricing: finalData, sort })

  if (isHelpTutorial) {
    data = mockData;
  }

  // const [
  //   createVehicleClass,
  //   { data: createMutationData, loading: createMutationLoading },
  // ] = useMutation(CreateVehicleclass);

  const [
    updateVehicleClass,
  ] = useMutation(UpdateVehicleclass);

  return (
    <SupplierPricingComponent
      data={(finalData) || []}
      loading={loading}
      setFilterKey={setFilterKey}
      setSort={setSort}
      // createVehicleClass={createVehicleClass}
      updateVehicleClass={updateVehicleClass}
      refetch={refetch}
      projectId={projectId}
    />
  );
};
export default withRouter(SupplierPricingContainer);
