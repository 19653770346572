import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paper: {
    border: (props) =>
      props.noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: (props) =>
      props.noBorder ? 'none' : '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
  },
  headerCell: {
    padding: '24px 16px',
  },
  lastRowCellRoot: {
    border: 'none',
  },
  firstCellRoot: {
    borderBottom: 'none',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 16,
      bottom: -1,
      height: 1,
      width: 'calc(100% - 16px)',
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  midCellRoot: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  lastCellRoot: {
    borderBottom: 'none',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 16,
      bottom: -1,
      height: 1,
      width: 'calc(100% - 16px)',
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 16px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: 0,
      height: 1,
      width: 'calc(100% - 32px)',
      background: 'rgba(0, 0, 0, 0.1)',
      transform: 'translateX(-50%)',
    },
  },
});

export default (props) => useStyles(props);
