/* eslint-disable */
import React from 'react';
import { Close } from '@material-ui/icons';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import { withRouter } from 'react-router-dom';
import Button from 'components_v2/Button/Button';
import AlertTag from 'components_v2/AlertTag/AlertTag';
import TitleWithText from 'components_v2/TitleWithText/TitleWithText';
import Tabs from 'components_v2/Tabs/Tabs';
import SampleForm from 'components_v2/SampleForm/SampleForm';
import Iconography from 'components_v2/Iconography/Iconography';
import DocumentUpload from 'components_v2/Upload/UploadDocument';
import Tooltip from 'components_v2/Tooltip/Tooltip';
import PassengerTooltip from 'components_v2/Tooltip/PassengerTooltip';
import Menu from 'components_v2/Menu/Menu';
import Map from 'components_v2/Map/Map';
import LocationMarkerMap from 'components_v2/Map/MarkerMap';
import Modal from 'components_v2/Modal/Modal';
import DynamicList from 'components_v2/List/DynamicList';
import StickyHeader from 'components_v2/StickyHeader/StickyHeader';
import Accordion from 'components_v2/Accordion/Accordion';
import DocumentPreview from 'components_v2/DocumentPreview/DocumentPreview';
import Highlight from 'components_v2/Highlight/Highlight';
import FullScreenPopover from 'components_v2/FullScreenPopover/FullScreenPopover';
import {
  AccountCircle,
  UserAdd,
  AccountSquare,
  NewProject,
} from 'components_v2/Icons/Icons';
import Stepper from 'components_v2/Stepper/Stepper';
import {
  SuccessCard,
  EmptyStateCtaCard,
  EmptyStateCard,
} from 'components_v2/Cards';
import Table from 'components_v2/Table/Table';

import BookingDetailCompactComponent from '../../components_v2/Bookings/BookingDetail.Compact.Component';
import DriverActions from '../../components_v2/DriverActions/DriverActions';
import FAB from '../../components_v2/Buttons/FAB/FAB';
import CIB from '../../components_v2/Buttons/CIB/CIB';
import { Clear } from '../../components_v2/Icons/Icons';
import { tourConfig } from './Playground.tour';
import TourGuide from '../../components_v2/TourGuide/TourGuide';

class PersistentDrawerLeft extends React.Component {
  state = {
    run: false,
    steps: tourConfig,
  };

  componentDidMount() {
    this.setState({ run: true });
  }

  render() {
    const { steps, run } = this.state;

    return (
      <div>
        <TourGuide steps={steps} run={run} />
        <BookingDetailCompactComponent />
        <StickyHeader
          leftContent={<div>32434</div>}
          rightContent={<div>fhifdfhi ifhif ih hi</div>}
          publicUrl
          sticky
        />
        <Accordion summary="Click this here" size="large">
          this is a new body thing yes sir
        </Accordion>{' '}
        <Accordion summary="Click this here" size="large">
          this is a new body thing yes sir
        </Accordion>{' '}
        <Accordion summary="Click this here" size="large">
          this is a new body thing yes sir
        </Accordion>
        <Highlight progress={80} alerts={14} ongoing={12} completed={11} />
        <AccountCircle />
        <AccountSquare />
        <br />
        <br />
        <div style={{ height: '500px' }}>
          <LocationMarkerMap
            markers={[
              { lat: 35.6558, long: -119.2027 },
              { lat: 35.7333, long: -119.3666 },
              { lat: 35.8466, long: -119.448 },
            ]}
            showRoute
          />
        </div>
        <br />
        <br />
        <h2>Modal Based Components</h2>
        <Modal
          onModalClick={(toggleModal) => (
            <>
              <Tooltip title="Assign a new driver">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleModal()}
                >
                  Open Modal
                </Button>
              </Tooltip>
            </>
          )}
        >
          this is the inner content of the modal
        </Modal>
        <br />
        <Modal
          size="full"
          onModalClick={(toggleModal) => (
            <>
              <Tooltip title="Preview Document">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleModal()}
                >
                  Preview Document
                </Button>
              </Tooltip>
            </>
          )}
        >
          <DocumentPreview
            fileSize="500"
            src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
          />
        </Modal>
        <br />
        <FullScreenPopover
          title="Test Popover"
          numberOfSteps={3}
          currentStep={1}
          mainAction={<Button variant="contained">Main Action</Button>}
          secondaryAction={<Button variant="simple">Secondary Action</Button>}
          onModalClick={(toggleModal) => (
            <>
              <Tooltip title="Test popover">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => toggleModal()}
                >
                  Full Screen Popover
                </Button>
              </Tooltip>
            </>
          )}
        >
          TEST
        </FullScreenPopover>
        <br />
        <br />
        <h2>Stepper</h2>
        <Stepper currentStep={3} numberOfSteps={5} />
        <br />
        <br />
        <h2>Cards</h2>
        <SuccessCard
          mainAction={{
            text: 'See Project',
            onClick: () => {},
          }}
          secondaryAction={{
            text: 'Project Settings',
            onClick: () => {},
          }}
          headerText="Project Created!"
          description="Congratulations, your event has been created."
          mainBodyActions={[
            {
              text: 'Add Passengers',
              onClick: () => {},
            },
            {
              text: 'Add Bookings',
              onClick: () => {},
            },
            {
              text: 'Add Locations',
              onClick: () => {},
            },
          ]}
          secondaryBodyAction={{
            text: 'Invite other members to collaborate with you.',
            onClick: () => {},
          }}
        />
        <br />
        <br />
        <EmptyStateCard
          headerText="No results found"
          description="Try different keywords or remove search filters."
        />
        <br />
        <br />
        <EmptyStateCtaCard
          headerText="No preview available"
          description="We're sorry we can't preview this file type in your web browser. To view this content please download and open in your device."
          mainAction={{
            text: 'Download file',
            onClick: () => {},
          }}
        />
        <br />
        <br />
        <DynamicList
          data={[
            {
              id: 204244,
              locationName: 'DXB Airport',
              tag: 'Main Entrance Gate 322 b',
              address:
                'Dubai International Airport, Dubai, United Arab Emirates',
              phoneNumber: '+971 4 224 5555',
              locationId: 123456,
            },
          ]}
          moduleTable
          hamburgerMenuItems={[
            {
              label: 'This is #1',
              onClick: (row) => console.log(row),
            },
            {
              label: 'This is #2',
              onClick: (row) => console.log('lol'),
            },
          ]}
          columns={[
            {
              field: 'locationName',
              headerName: 'Pickup time',
              type: 'time',
              width: 2,
              render: (data) => (
                <TitleWithText label="Location Name" text={data.locationName} />
              ),
            },
            {
              field: 'bookingId',
              headerName: 'Project',
              width: 3,
              render: (data) => <b>{data.tag}</b>,
            },

            {
              field: 'bookingId',
              headerName: 'Project',
              width: 2,
              render: (data) => <b>{data.tag}</b>,
            },
            {
              field: 'bookingId',
              headerName: 'Project',
              width: 2,
              render: (data) => <b>{data.tag}</b>,
            },
            {
              field: 'bookingId',
              headerName: 'Project',
              width: 12,
              render: (data) => (
                <TitleWithText label="Address" text={data.address} />
              ),
            },
          ]}
          actions={{
            edit: {
              onSave: (row) => {},
            },
            delete: {
              onClick: (row) => {},
            },
          }}
        />
        {/* 
                    <button
                      onClick={() =>
                        context.sendAlert(
                          id,
                          "Some warning message",
                          undefined,
                          "warning",
                          "medium"
                        )
                      }
                    >
                      Send Warning Message
                    </button>
                    <button
                      onClick={() =>
                        context.sendAlert(
                          id,
                          "John has just booked a new transfer to Palm Jumeirah. [TEST]",
                          undefined,
                          "info",
                          "low"
                        )
                      }
                    >
                      Send Info
                    </button>
                    <button
                      onClick={() =>
                        context.sendAlert(
                          id,
                          "Driver Dana Buchawiecki has missed his pickup time. Was expected at 13:05. [TEST]",
                          undefined,
                          "error",
                          "critical"
                        )
                      }
                    >
                      Send Alert
                    </button> */}
        <div>
          {/**
          <ProjectSelector
            render={projectName => (
              <Typography variant="h1">{projectName}</Typography>
            )}
          /> */}

          <Map />

          <br />
          <br />
          <Accordion noPadding summary="Final chargeable prices">
            <Table
              noBorder
              columns={[
                {
                  name: 'Per Item (AED)',
                  render: () => {},
                  width: '20%',
                },
                {
                  align: 'center',
                  field: 'transfer',
                  name: 'Transfer',
                  width: '15%',
                },
                {
                  align: 'center',
                  field: 'six',
                  name: '6 Hr.',
                },
                {
                  align: 'center',
                  field: 'twelve',
                  name: '12 Hr.',
                  width: '12.5%',
                },
                {
                  align: 'center',
                  field: 'twentyFour',
                  name: '24 Hr.',
                  width: '12.5%',
                },
                {
                  align: 'center',
                  field: 'noShow',
                  name: 'No Show',
                  width: '12.5%',
                },
                {
                  align: 'center',
                  field: 'pricePerKph',
                  name: 'Price per KM/H',
                  width: '12.5%',
                },
              ]}
              data={[
                {
                  extra: 100,
                  noShow: 100,
                  perItem: '1x Sedan',
                  pricePerKph: 100,
                  six: 100,
                  transfer: '',
                  twelve: 100,
                  twentyFour: 100,
                },
                {
                  extra: 100,
                  noShow: 100,
                  perItem: '1x Van',
                  pricePerKph: 100,
                  six: 100,
                  transfer: '',
                  twelve: 100,
                  twentyFour: 100,
                },
                {
                  extra: 100,
                  noShow: 100,
                  perItem: '1x Wagon',
                  pricePerKph: 100,
                  six: 100,
                  transfer: '',
                  twelve: 100,
                  twentyFour: 100,
                },
              ]}
              mainAction={<Button variant="contained">Done</Button>}
              secondaryAction={<Button variant="simple">View PDF</Button>}
            />
          </Accordion>
          <br />
          <br />

          <Tabs
            noBg
            noPadding
            tabs={[
              {
                label: 'Trips',
                slug: 'trips',
                component: <div style={{ marginTop: 25 }}>lol</div>,
              },
              {
                label: 'History',
                slug: 'history',
                component: <div style={{ marginTop: 25 }}>sup</div>,
              },
            ]}
          />

          {/* 
                      <ServiceTracking
                        id={"5f35a47f3825eee594a1df20"}
                        trackDriver
                      /> */}

          {/* 
                      <h2>Cleaning services log</h2>

                      <ServiceTracking id={"5f35a47f3825eee594a1df20"} />

                      {/
                      <ServiceTracking
                        id={"5f35a47f3825eee594a1df20"}
                        type={"maintenance"}
                      />}

                      <Typography variant="h5">8 September</Typography>
                      <FlipMove>
                        <PublicisListItem />
                        <PublicisListItem />
                        <PublicisListItem />
                        <PublicisListItem />
                      </FlipMove>
                    */}
        </div>
        <h2>Buttons</h2>
        <br />
        <br />
        <p>CircularIconButton</p>
        <CIB
          icon={Clear}
          onClick={() => {}}
        />
        <CIB
          icon={UserAdd}
          onClick={() => {}}
        />
        <br />
        <br />
        <p>CTA Contained</p>
        <Button startIcon={<UserAdd />} secondary>
          Simple Button
        </Button>
        <br />
        <br />
        <p>CTA Simple</p>
        <Button startIcon={<Close />} variant="simple">
          Simple Button
        </Button>
        <br />
        <br />
        <DocumentUpload
          onComplete={(data) => {}}
        />
        <br />
        <br />
        <br />
        Custom Menu (Button passed in as render props): <br />
        <Menu
          items={[
            { label: 'This is #1', onClick: () => console.log('lol') },
            { label: 'This is #2', onClick: () => console.log('lol') },
          ]}
          render={(handleClick) => (
            <span onClick={handleClick}>click me - menu example</span>
          )}
        />
        <br />
        <br />
        Default menu (no render props): <br />
        <Menu
          items={[
            { label: 'This is #1', onClick: () => console.log('lol') },
            { label: 'This is #2', onClick: () => console.log('lol') },
          ]}
        />
        <br />
        <br />
        <PassengerTooltip
          passengers={[
            { name: 'Jack Jackson', phone: '+23 243 424 23' },
            { name: 'Jack Jackson', phone: '+23 243 424 23' },
            { name: 'Jack Jackson', phone: '+23 243 424 23' },
          ]}
        >
          55 pax (tooltip preview)
        </PassengerTooltip>
        <br />
        Disabled: <br />
        <Button variant="contained" disabled color="primary">
          Assign Driver
        </Button>
        <br />
        <br />
        <AlertTag severity="critical" label="Accident" />
        <br />
        <a href="tel:123-456-7890">CLICK TO CALL</a>
        <a href="sms:22542356436;body=lolo">CLICK TO TEXT</a>
        {/* <CustomForm></CustomForm> */}
        <CIB
          icon={Clear}
          onClick={() => {}}
          isDisabled
        />
        <CIB
          icon={Clear}
          onClick={() => {}}
        />
        <FAB
          icon={NewProject}
          label="New Project"
          onClick={() => {}}
        />
        <DriverActions
          distance="22km"
          driver={{
            name: 'Mohammed B.',
          }}
          otherOptions={[]}
          onAssignDriverClick={() => {
          }}
          onCloseClick={() => {
          }}
          onDropdownClick={() => {
          }}
        />
        <br />
        <DriverActions
          distance="22km"
          driver={{
            name: 'Mohammed B.',
          }}
        />
        <br />
        <DriverActions
          driver={{
            name: 'Mohammed B.',
          }}
        />
        <Iconography />
        <SampleForm />
        {/* <Timeline
          completedSteps={[
            {
              status: 'scheduled',
              label: 'Scheduled',
              isError: false,
            },
            {
              status: 'enroute',
              label: 'Enroute To Pickup',
              isError: false,
            },
            {
              status: 'arrivedToPickup',
              label: 'Arrived To Pickup',
              isError: true,
            },
            {
              status: 'passengerInVehicle',
              label: 'Passenger in Vehicle',
              isError: false,
            },
          ]}
        /> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default withMainLayout('Chat', {}, withRouter(PersistentDrawerLeft));
