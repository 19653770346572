import * as React from "react";
import CircularIcon from "../CircularIcon/CircularIcon";

import {
  Suv,
  Disabled,
  VIP,
  Disabledvip,
  Alert,
  Busy,
  NewDriver1,
  Vacant1,
  Bus,
  Minibus,
  Sedan,
  Van,
  DistanceFull
} from "../Icons/Icons";

const Vehicle = ({
  className,
  onClick,
  ref,
  type,
  category,
  rotate,
  status,
  isBusy,
  distance,
  customStyle,
  label
}) => {
  const setCategoryIcon = (category, type) => {
    if (type === "ADA Van (Handicap)") return Disabled;

    switch (category) {
      case "vip":
        return VIP;
      case "disabled":
        return Disabled;
      case "disabled-vip":
        return Disabledvip;
      default:
        return null;
    }
  };
  const setVehicleClassIcon = vehicleType => {
    switch (vehicleType) {
      case "SUV":
        return Suv;

      case "Mini-Van":
      case "Van":
      case "ADA Van (Handicap)":
      case "Luggage Van":
        return Van;

      case "Mini-Bus":
      case "Sprinter":
        return Minibus;

      case "Bus":
      case "Coaster Bus":
      case "Motor Coach":
        return Bus;

      default:
        return Sedan;
    }
  };

  const setStatusIcon = status => {
    switch (status) {
      case "busy":
        return (
          <CircularIcon
            isShadowed={false}
            bgColor="#C50202"
            icon={
              <Busy
                style={{
                  fill: "#fff"
                }}
              />
            }
            bgSize={32}
          />
        );
      case "new-driver":
        return (
          <CircularIcon
            isShadowed={false}
            bgColor="black"
            icon={
              <NewDriver1
                style={{
                  fill: "#fff"
                }}
              />
            }
            bgSize={32}
          />
        );

      case "vacant":
        return (
          <CircularIcon
            isShadowed={false}
            bgColor="black"
            icon={
              <Vacant1
                style={{
                  fill: "#fff"
                }}
              />
            }
            bgSize={32}
          />
        );
      case "alert":
        return (
          <CircularIcon
            isShadowed={false}
            bgColor="#CC4A01"
            icon={
              <Alert
                style={{
                  fill: "#fff"
                }}
              />
            }
            bgSize={32}
          />
        );

      default:
        return null;
    }
  };
  const CategoryIcon = setCategoryIcon(category, type);
  const renderedStatusIcon = setStatusIcon(status);
  const VehicleIcon = setVehicleClassIcon(type);

  return (
    <div
      className={className}
      onClick={onClick}
      ref={ref}
      style={{
        ...customStyle,
        "-webkit-user-drag": "none",
        "-khtml-user-drag": "none",
        "-moz-user-drag": "none",
        "-o-user-drag": "none",
        "user-drag": "none",
        width: "auto",
        height: "auto",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {VehicleIcon && (
        <VehicleIcon
          style={{
            position: "relative",
            opacity: isBusy && "0.5"
          }}
        />
      )}
      {CategoryIcon && (
        <div
          style={{
            position: "absolute",
            width: "auto",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: `rotate(-${rotate}deg)`,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <CategoryIcon />
        </div>
      )}
      {renderedStatusIcon && (
        <div
          style={{
            position: "absolute",
            width: "auto",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: `rotate(-${rotate}deg)`,
            left: "-9px",
            top: "-9px"
          }}
        >
          {renderedStatusIcon}
        </div>
      )}

      {label && (
        <div
          style={{
            position: "absolute",
            width: "auto",
            height: "auto",
            display: "flex",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "9px",
              textAlign: "center",
              letterSpacing: "0.2px",
              color: "#000",
              position: "absolute",
              marginLeft: -10
            }}
          >
            {label}
          </span>
        </div>
      )}

      {distance && (
        <div
          style={{
            position: "absolute",
            width: "auto",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: `translate(0%, -50%) rotate(-${rotate}deg)`
          }}
        >
          <DistanceFull />
          <span
            style={{
              fontWeight: "500",
              fontSize: "8px",
              textAlign: "center",
              letterSpacing: "0.2px",
              color: "#FFFFFF",
              position: "absolute",
              bottom: "11px"
            }}
          >
            {distance}
          </span>
        </div>
      )}
    </div>
  );
};
export default Vehicle;
