export const mockData = {
  storedlocations: [
    {
      id: '5f271ba97442154fd8a8c13b',
      identifier: 'RDH0010',
      name: 'Dubai Airport Terminal 2 Departure',
      type: 'Event venue',
      address: 'Dubai - United Arab Emirates',
      lat: 25.2628902,
      long: 55.3532168,
      contactFullName: 'Simon Jackson',
      contactPhone: null,
      children: [],
      __typename: 'Storedlocation',
    },

    {
      id: '5fb8231bf8879d52b947ecd7',
      identifier: 'Tac-123',
      name: 'Tacoma Dome',
      type: 'Event venue',
      address: 'Tacoma Dome, East D Street, Tacoma, WA, USA',
      lat: 47.2362554,
      long: -122.4273209,
      contactFullName: 'Jennie Osmond',
      contactPhone: null,
      children: [
        {
          id: '5fb8231cf8879d52b947ecd8',
          name: 'Tacoma Mall',
          address: 'Tacoma Mall, South Steele Street, Tacoma, WA, USA',
          lat: 47.2161117,
          long: -122.4681294,
          __typename: 'Storedlocation',
        },
        {
          id: '5fb8231cf8879d52b947ecd9',
          name: 'SeaTac Airport',
          address:
            'Seattle-Tacoma International Airport (SEA), International Boulevard, Seattle, WA, USA',
          lat: 47.4502499,
          long: -122.3088165,
          __typename: 'Storedlocation',
        },
      ],
      __typename: 'Storedlocation',
    },
  ],
};
