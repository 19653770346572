import React from "react";
import Bookings from "components_v2/Bookings/Booking.Container.jsx";
import withMainLayout from 'components_v2/Layout/withMainLayout';
import { withRouter } from 'react-router-dom';

const History = () => {
  return <Bookings type="History"  />;
};


export default withMainLayout(
  'History',
  {},
  withRouter(History)
);
