import gql from "graphql-tag";

export default gql`
  query GetDriver($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      whatsapp
      confirmed
      company
      identifier
      username
      notes
      pushToken
      passengerPriority
      supervisor
      hireDate
      employmentType
      profileImage
      profileImageThumbnail
      documents
      nationality
      gender
      licenseNumber
      passportNumber
      visaNumber
      assignedVehicles {
        id
        wholeEvent
        start
        stop
        created_at
        vehicle {
          id
          make
          model
          year
          vehicleNumber
          type
        }
      }
    }
  }
`;
