import React from 'react';
import { AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  mobileViewBreakpoint,
  extraSmallMobileBreakpoint,
} from '../../App.helper';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: (props) => (props.publicUrl ? 24 : '16px 112px'),
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: (props) => (props.publicUrl ? 24 : '16px 20px'),
    },
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      flexDirection: (props) => (props.columnMobileView ? 'column' : 'row'),
    },
  },
  contentContainer: {
    width: (props) => (props.columnMobileView ? '100%' : undefined),
  },
}));

const StickyHeader = ({
  leftContent,
  rightContent,
  sticky,
  publicUrl,
  containerClass,
  columnMobileView,
}) => {
  const position = sticky ? 'sticky' : 'absolute';
  const classes = useStyles({ publicUrl, columnMobileView });
  return (
    
    <AppBar className={clsx(classes.root, containerClass)} position={position}>
      {leftContent && <><div className={classes.contentContainer}>{leftContent}</div>
      <div className={classes.contentContainer}>{rightContent}</div></>}
    </AppBar>
  );
};

StickyHeader.propTypes = {
  leftContent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rightContent: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  sticky: PropTypes.bool,
  publicUrl: PropTypes.bool,
};

export default StickyHeader;
