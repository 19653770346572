/* eslint-disable complexity */
import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import currencyOptions from "utils/currencies";
import Button from "components_v2/Button/Button";
import FullScreenPopover from "components_v2/FullScreenPopover/FullScreenPopover";
import CreateEvent from "gql/Events/CreateCompleteEvent";
import UpdateEvent from "gql/Events/UpdateCompleteEvent";
import { addUpdatedOrCreatedBy } from "utils/formHelpers";
import Loading from "components_v2/Loading/CircularProgress";
import SuccessCard from "components_v2/Cards/SuccessCard/SuccessCard";
import {
  redirectOutOfBoundStep,
  getPathWithoutStep
} from "utils/routerHelpers";
import _ from "lodash";
import {
  formatSubmissionValues,
  formatIncomingData,
  getValidationSchema,
  mergeReturnValues
} from "./helpers";
import InfoForm from "./InfoForm";
import SuppliersForm from "./SuppliersForm";
import PricesForm from "./PricesForm";
import useStyles from "./AddProject.style";
import DEFAULT_TRANSFER_SERVICES from "utils/transferServices";
import { useRouteData } from "context/route-data.context";
import withFormState from "containers/Wrappers/withFormState";

const AddProject = ({
  history,
  data,
  refetchProject,
  createProduct,
  updateProduct,
  projectId,
  eventProducts,
  location,
  stepParam,
  getPreviousFormState
}) => {
  const totalSteps = 3;

  const pathRouteList = location.pathname.split("/");
  const isNew = pathRouteList.includes("new");

  if (stepParam)
    redirectOutOfBoundStep(stepParam, location, history, totalSteps);

  const classes = useStyles();
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [nextView, setNextView] = useState(false);
  const [step, setStep] = useState(stepParam || 1);
  const [savedProjectId, setSavedProjectId] = useState(null);
  const [subtitle, setSubtitle] = useState("");
  const formRef = useRef(null);
  const previousFormState = getPreviousFormState(location);
  const previousFormStateValues = previousFormState?.values
    ? mergeReturnValues(previousFormState)
    : {};

  const isUpdate = data && projectId;
  const initialInputValues = {
    name: "",
    type: "event",
    country: { name: "United Arab Emirates" },
    timezone: "Asia/Dubai",
    start: null,
    end: null,
    modules: [],
    client: [],
    locationInput: [],
    currency: _.find(currencyOptions, { code: "aed" }),
    fleet: [],
    requiredStaff: [],
    fleetSuppliers: [],
    productOptions: [],
    products: [],
    clientPriceMargin: 10,
    clientPriceFormat: "Overall",
    isProjectCreated: false,
    createdProjectId: "",
    viewport: [],
    status: { name: "Draft" },
    hasModules: false,
    transferservices: DEFAULT_TRANSFER_SERVICES
  };
  const validationSchema = getValidationSchema(step, isUpdate);
  const initialValues = isUpdate
    ? {
        ...initialInputValues,
        ...formatIncomingData(data, eventProducts),
        ...previousFormStateValues
      }
    : { ...initialInputValues, ...previousFormStateValues };

  const successHeader = `Project ${isUpdate ? "Updated" : "Created"}!`;
  const successDescription = `Congratulations, your project has been ${
    isUpdate ? "updated" : "created"
  }.`;

  const [createProject, { loading: createMutationLoading }] = useMutation(
    CreateEvent
  );

  const [updateProject, { loading: updateMutationLoading }] = useMutation(
    UpdateEvent
  );

  const isLoading = createMutationLoading || updateMutationLoading;

  const navigateNext = () => {
    const pathWithoutStep = getPathWithoutStep(step, location);
    window.history.replaceState(null, "", `${pathWithoutStep}/${step + 1}`);

    setStep(prevState => prevState + 1);
  };

  const handleSubmit = async (values, actions) => {
    const formattedValues = formatSubmissionValues(
      values,
      initialValues,
      isUpdate,
      step
    );

    const valuesWithUserId = addUpdatedOrCreatedBy(
      formattedValues,
      isUpdate || savedProjectId
    );

    const formikBag = formRef.current.getFormikBag();

    const id = savedProjectId || data?.id || formikBag.values.createdProjectId;

    if (step === 1) {
      if (isUpdate || savedProjectId || formikBag.values.isProjectCreated) {
        if (valuesWithUserId.hasModules === undefined)
          valuesWithUserId.hasModules = false;
        const res = await updateProject({
          variables: {
            id: { id },
            input: {
              event: { ...valuesWithUserId },
              modules: values.modules.map(mod => ({
                id: mod.id,
                name: mod.name,
                description: mod.description,
                departments: mod.departments.map(dep => ({
                  id: dep.id,
                  name: dep.name
                })),
                teamMembers: mod.teamMembers,
                usereventmodules: mod.usereventmodules.map(x => ({
                  id: x.id,
                  user: x.user.id
                }))
              }))
            }
          }
        });

        if (res?.data?.updateCompleteEvent) {
          const updateEventId = res.data.updateCompleteEvent.id;

          setSavedProjectId(updateEventId);
          if (!nextView) setDisplaySuccess(true);
          else navigateNext();
          if (isUpdate) await refetchProject();
        }
      } else {
        const res = await createProject({
          variables: {
            input: {
              event: valuesWithUserId,
              modules: values.modules.map(mod => ({
                id: mod.id,
                name: mod.name,
                description: mod.description,
                departments: mod.departments.map(dep => ({
                  id: dep.id,
                  name: dep.name
                })),
                teamMembers: mod.teamMembers
              })),
              transferservices: values.transferservices.map(
                ({ name, duration }) => ({
                  name,
                  duration:
                    typeof duration === "string" ? parseInt(duration) : duration
                })
              )
            }
          }
        });

        if (res?.data?.createCompleteEvent) {
          const createEventId = res.data.createCompleteEvent.id;
          setSavedProjectId(createEventId);
          formRef.current.setFieldValue("isProjectCreated", true);
          formRef.current.setFieldValue("createdProjectId", createEventId);
          if (!nextView) {
            setDisplaySuccess(true);
            actions.resetForm(initialValues);
          } else history.push(`/project/${createEventId}/new/2`);
        }
      }
    }

    if (step === 2 || step === 3) {
      const res = await updateProject({
        variables: {
          id: { id },
          input: {
            event: valuesWithUserId
          }
        }
      });

      if (res?.data?.updateCompleteEvent) {
        const updateEventId = res.data.updateCompleteEvent.id;
        setSavedProjectId(updateEventId);
        if (!nextView) setDisplaySuccess(true);
        else navigateNext();
        if (isUpdate) await refetchProject();
      }
    }

    if (step === 3) return;

    actions.setSubmitting(false);
  };

  const getForm = (step, props, classes) => {
    if (step === 1)
      return (
        <InfoForm
          {...props}
          classes={classes}
          setSubtitle={setSubtitle}
          formStep={step}
          projectId={projectId}
          isUpdate={isUpdate}
        />
      );
    if (step === 2)
      return SuppliersForm({
        ...props,
        classes,
        refetchProject,
        createProduct,
        updateProduct,
        projectId,
        formStep: step
      });
    return PricesForm({ ...props, classes, formStep: step, projectId });
  };

  const handleNextClick = () => {
    setNextView(true);
    formRef.current.submitForm();
  };

  const handleFinishedClick = () => {
    setNextView(false);
    formRef.current.submitForm();
  };

  const getMainAction = step => {
    if (step === 1)
      return (
        <Button type="button" variant="contained" onClick={handleNextClick}>
          Set up suppliers
        </Button>
      );

    if (step === 2)
      return (
        <Button type="button" variant="contained" onClick={handleNextClick}>
          Set up prices
        </Button>
      );

    if (step === 3)
      return (
        <Button type="button" variant="contained" onClick={handleFinishedClick}>
          Finalize
        </Button>
      );
    return null;
  };

  const getTitle = (step, isUpdate) => {
    if (step === 1)
      return isUpdate && !isNew
        ? "Update an existing project"
        : "Create a new project";
    if (step === 2)
      return isUpdate && !isNew
        ? "Update project suppliers"
        : "Add project suppliers";
    if (step === 3)
      return isUpdate && !isNew ? "Update prices" : "Set up prices";
    return "Something went wrong";
  };

  const routeData = useRouteData();

  const closeFSPCallback = () => {
    history.replace(routeData.prevPath);
  };

  if (displaySuccess)
    return (
      <FullScreenPopover
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        // onCloseCallback={closeFSPCallback}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={{
            text: "Go to Project",
            onClick: () => {
              history.push(`/project/${savedProjectId}/details`);
            }
          }}
          secondaryAction={{
            text: "Customize Project",
            onClick: () => {
              history.push(`/project/${savedProjectId}/edit`);
              setDisplaySuccess(false);
            }
          }}
          mainBodyActions={[
            {
              text: "Add Passengers",
              onClick: () => {
                history.push(`/project/${savedProjectId}/passenger/new`);
              }
            },
            {
              text: "Add Bookings",
              onClick: () => {
                history.push(`/project/${savedProjectId}/booking/new`);
              }
            },
            {
              text: "Add Locations",
              onClick: () => {
                history.push(`/project/${savedProjectId}/location/new`);
              }
            }
          ]}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
        ref={formRef}
      >
        {props => (
          <Form>
            <FullScreenPopover
              title={getTitle(step, isUpdate)}
              numberOfSteps={totalSteps}
              currentStep={step}
              subtitle={step > 1 ? subtitle || data?.name : null}
              setCurrentStep={setStep}
              mainAction={getMainAction(step)}
              onCloseCallback={closeFSPCallback}
              secondaryAction={
                <Button
                  type="button"
                  variant="simple"
                  onClick={() => {
                    setNextView(false);
                    formRef.current.submitForm();
                  }}
                >
                  Save and close
                </Button>
              }
              pageView
            >
              {getForm(step, props, classes)}
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddProject));
