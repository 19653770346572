import React from 'react';
import { withRouter } from 'react-router-dom';

import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import ListModal from 'components_v2/ListModal/ListModal';
import GetAllClients from 'gql/Clients/GetAllClients';
import { createLocationWithState } from 'utils/routerHelpers'
import withFormState from "containers/Wrappers/withFormState";


const clientColumns = [
  {
    field: 'companyName',
    headerName: 'Name',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 3,
    titleWithText: true,
  },
];

const ClientSelector = ({ onDone, values, setFieldValue, history, location, error, formStep, setFormState }) => {
  const handleModalClick = values.client.length
    ? null
    : (toggleModal) => (
        <SelectActionButton onClick={toggleModal}>
          Select Client
        </SelectActionButton>
      );

  const customHandleDelete = (setSelectedItems) => () => {
    setFieldValue('client', []);
    setSelectedItems([]);
  };

  const handleNewClientClick = () => {
    const { pathname, state } = createLocationWithState(
      `/client/new`,
      location,
      values,
      formStep,
      false,
      "client"
    );
    
    setFormState(state);
    history.replace({ pathname, state: { hasState: true } })
  };

  return (
    <ListModal
      title="Select client"
      searchPlaceholder="Search for a client"
      query={GetAllClients}
      dataSelector={(data) => data?.clients || []}
      queryOptions={{ fetchPolicy: 'no-cache' }}
      mainAction={{
        label: 'Done',
        onClick: onDone,
      }}
      secondaryAction={{
        label: 'Create a new client',
        onClick: handleNewClientClick,
      }}
      singleSelection
      error={error}
      customHandleDelete={customHandleDelete}
      incomingItems={values.client}
      columns={clientColumns}
      renderSelectionColumns={clientColumns}
      onModalClick={handleModalClick}
    />
  );
};

export default withFormState(withRouter(ClientSelector));
