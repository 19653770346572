import React from 'react';
import moment from 'moment';
import { Box, TableCell } from '@material-ui/core';

import Typography from 'components_v2/Typography/Typography';
import useStyles from './MonthViewTimeTableCell.style';

const MonthViewTimeTableCell = ({ startDate }) => {
  const classes = useStyles();

  const currentDateUTC = moment.utc(Date.now()).format('MMMM-DD');
  const startDateFormatted = moment(startDate).format('MMMM-DD');

  const [startMonth, startDay] = currentDateUTC.split('-');
  const [currentMonth, currentDay] = startDateFormatted.split('-');

  const date =
    currentDateUTC === startDateFormatted ? (
      <Box className={classes.todayDateContainer}>
        <Box>{startDay}</Box>
      </Box>
    ) : (
      <Typography
        style={{
          color: startMonth === currentMonth ? undefined : 'rgba(0, 0, 0, 0.3)',
        }}
        customVariant="bodySmallRegular"
      >
        {currentDay}
      </Typography>
    );

  return (
    <TableCell tabIndex={0} className={classes.cellBase}>
      {date}
    </TableCell>
  );
};

export default MonthViewTimeTableCell;
