import React from 'react';
import DynamicList from 'components_v2/List/DynamicList';
import PropTypes from 'prop-types';
import { getColumns, getMobileMenuItems } from './ListOptions';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from '../../../../App.helper';
import _ from "lodash"

const SupplierPricingComponent = ({
  data,
  loading,
  // createVehicleClass,
  updateVehicleClass,
  refetch,
  setFilterKey,
  setSort,
  sort

}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  return (
    <>
      <DynamicList
        idSuffix={'_supplier_pricing'}
        isMobileView={isMobileView}
        showHeader
        loading={loading}
        data={data}
        filterActions={[
          {
            type: "text",
            onChange: (val) => {
              setFilterKey(val)
            },
            label: "Search",
          },
        ]}
        sortActions={{
          currentSort: sort,
          onChangeSort: setSort
        }}
        title="Supplier Pricing"
        noResultsText="No supplier pricing"
        numRows={data.length}
        columns={getColumns(isMobileView)}
        getMobileMenuItems={(row) => getMobileMenuItems({ row })}
        actions={{
          edit: {
            onSave: async ({ id, values }) => {
              await updateVehicleClass({
                variables: {
                  id: { id },
                  data: {
                    ...values,
                    type: values?.type?.value,
                    vehicles: values?.vehicles?.length ? _.map(values.vehicles, 'id:') : []
                  },
                },
              });

              await refetch();
            },
          },
          // create: {
          //   onSave: async ({ values }) => {
          //     await createVehicleClass({
          //       variables: {
          //         data: {
          //           ...values,
          //           type: values.type && values.type.value,
          //           event: projectId,
          //         },
          //       },
          //     });

          //     await refetch();
          //   },
          // },
        }}
      />
    </>
  );
}
export default SupplierPricingComponent;
SupplierPricingComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};
