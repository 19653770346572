import gql from "graphql-tag";

export default gql`
  query GetAllProducts($id: ID) {
    products(where: { event: $id }) {
      id
      name
      description
      price
      icon
      clientPricing
      supplierPricing
    }
  }
`;
