import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckboxInput from "components_v2/Inputs/CheckboxInput/CheckboxInput";
import { Formik, Form } from "formik";
import { useCustomStyle } from "./AccessRightsDetails.styles";
import Button from "../../components_v2/Button/Button";

const AccessRightsDetailsComponent = ({ data, save }) => {
  const customStyle = useCustomStyle();

  const properties = [
    { value: "canViewOwn" },
    { value: "canView" },
    { value: "canEdit" },
    { value: "canCreate" },
    { value: "canDelete" }
  ];
  const pageNames = [
    { value: "dashboard", name: "Dashboard" },
    { value: "vehicles", name: "Vehicles" },
    { value: "drivers", name: "Drivers" },
    { value: "locations", name: "Locations" },
    { value: "passengers", name: "Passengers" },
    { value: "bookings", name: "Bookings" },
    { value: "eventTimeline", name: "Event Timeline" },
    { value: "routePricing", name: "Client Pricing" },
    { value: "supplierPricing", name: "Supplier Pricing" },
    { value: "attendanceMapping", name: "Attendance Mapping" },
    { value: "passengerScheduleBuilder", name: "Passenger Schedule Builder" },
    { value: "driverVehicleMapping", name: "Driver Vehicle Mapping" },
    { value: "driverScheduleBuilder", name: "Driver Schedule Builder" },
    { value: "shuttleSchedule", name: "Shuttle Schedule" },
    { value: "eventSchedule", name: "Vehicles" },
    { value: "dispatching", name: "Dispatching" },
    { value: "alerts", name: "Alerts" },
    { value: "administration", name: "Administration" },
    { value: "currentEvent", name: "Event a user is in" },
    { value: "reports", name: "Reports" }
  ];
  const [currentData, setCurrentData] = useState({});
  const renderData = dat => dat;
  useEffect(() => {
    setCurrentData(renderData(data));
  }, [data]);

  const deepCopy = obj => JSON.parse(JSON.stringify(obj));
  const { permissions } = currentData;
  const handleChangeEvent = (
    eventName,
    eventPageName,
    eventValue,
    eventRole,
    eventId,
    idx
  ) => {
    const modifyCurrentData = deepCopy(currentData);
    modifyCurrentData.permissions[idx].permissions[eventName] = eventValue;
    setCurrentData(renderData(modifyCurrentData));
  };

  return (
    <div className={customStyle.accessRightsWrapper}>
      <Formik
        initialValues={{ permissions }}
        validationSchema={{}}
        enableReinitialize
        onSubmit={async (currentData, actions) => {
          const data = currentData;

          delete data.id;

          await save({
            variables: {
              data: data
            }
          });

          actions.setSubmitting(false);
        }}
      >
        {props => {
          const { values } = props;
          return (
            <Form>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Page</TableCell>
                      {properties.map(prop => (
                        <TableCell align="center">{prop.value}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(values.permissions &&
                      values.permissions.map((permission, index) => {
                        let pageName = pageNames.find(
                          pN => pN.value === permission.page.value
                        );
                        if (pageName) {
                          pageName = pageName.name;
                        } else {
                          pageName = permission.page.value;
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {pageName}
                            </TableCell>

                            <TableCell align="center">
                              <CheckboxInput
                                name="canViewOwn"
                                label
                                onChange={event => {
                                  handleChangeEvent(
                                    "canViewOwn",
                                    permission.page.value,
                                    event.target.checked,
                                    currentData.name,
                                    currentData.id,
                                    index
                                  );
                                }}
                                value={permission.permissions.canViewOwn}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <CheckboxInput
                                name="canView"
                                label
                                onChange={event => {
                                  handleChangeEvent(
                                    "canView",
                                    permission.page.value,
                                    event.target.checked,
                                    currentData.name,
                                    currentData.id,
                                    index
                                  );
                                }}
                                value={permission.permissions.canView}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <CheckboxInput
                                label
                                name="canEdit"
                                onChange={event => {
                                  handleChangeEvent(
                                    "canEdit",
                                    permission.page.value,
                                    event.target.checked,
                                    currentData.name,
                                    currentData.id,
                                    index
                                  );
                                }}
                                value={permission.permissions.canEdit}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <CheckboxInput
                                label
                                name="canCreate"
                                onChange={event => {
                                  handleChangeEvent(
                                    "canCreate",
                                    permission.page.value,
                                    event.target.checked,
                                    currentData.name,
                                    currentData.id,
                                    index
                                  );
                                }}
                                value={permission.permissions.canCreate}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <CheckboxInput
                                label
                                name="canDelete"
                                onChange={event => {
                                  handleChangeEvent(
                                    "canDelete",
                                    permission.page.value,
                                    event.target.checked,
                                    currentData.name,
                                    currentData.id,
                                    index
                                  );
                                }}
                                value={permission.permissions.canDelete}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })) ||
                      []}
                  </TableBody>
                </Table>
                <div className={customStyle.saveWrapper}>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </div>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default AccessRightsDetailsComponent;
