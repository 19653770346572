import gql from "graphql-tag";

export default gql`
  query GetVehicle($id: ID!) {
    vehicles(where: { id: $id }) {
      id
      make
      model
      year
      additionalData
      vehicleNumber
      maxPassengers
      maxLuggage
      imageUrl
      interiorImageUrl
      class
      type
      enabled
      notes
      color
      plateNumber
      status
      documents
      assignedDrivers {
        id
        driver {
          id
          firstName
          lastName
        }
        vehicle {
          id
        }
        wholeEvent
      }
      vehicleClass {
        id
        name
      }
      supplier {
        id
        name
      }
    }
  }
`;
