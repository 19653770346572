import gql from "graphql-tag";

export default gql`
  query GetAllStoredLocations($id: ID, $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String) {
    storedlocationsConnection(
      where: { 
        event: $id, 
        parent_null: true
        _or: [
          { name_contains: $filterKey }
          { type_contains: $filterKey }
          { address_contains: $filterKey }
          { contactPhone_contains: $filterKey }
        ]
      }
      limit: $pageSize
      start: $startIndex
      sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        identifier
        name
        type
        address
        lat
        long
        contactFullName
        contactPhone
        children {
          id
          name
          address
          lat
          long
        }
      }
    }
  }
`;
