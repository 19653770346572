import React from "react";

import ListModal from "components_v2/ListModal/ListModal";
import SelectActionButton from "components_v2/SelectActionButton/SelectActionButton";

const selectionColumns = [
  {
    field: "vehicleClass",
    headerName: "Class",
    width: 2,
    render: row => row?.vehicleClass?.name,
    titleWithText: true
  },
  {
    field: "color",
    headerName: "Color",
    width: 3,
    titleWithText: true
  },
  {
    field: "plateNumber",
    headerName: "Plate",
    width: 3,
    titleWithText: true
  },
  {
    field: "make",
    headerName: "Make",
    width: 3,
    titleWithText: true
  },
  {
    field: "model",
    headerName: "Model",
    width: 3,
    titleWithText: true
  }
];

const resultColumns = [
  {
    field: "vehicleClass",
    headerName: "Class",
    width: 2,
    render: row => row?.vehicleClass?.name,
    titleWithText: true
  },
  {
    field: "color",
    headerName: "Color",
    width: 3,
    titleWithText: true
  },
  {
    field: "plateNumber",
    headerName: "Plate",
    width: 3,
    titleWithText: true
  },
  {
    field: "make",
    headerName: "Make",
    width: 3,
    titleWithText: true
  },
  {
    field: "model",
    headerName: "Model",
    width: 3,
    titleWithText: true
  }
];

const SelectSupplier = ({
  values,
  onDone,
  handleCloseCallback,
  handleNewClick,
  error,
  incomingItems,
  singleSelection,
  data,
  noResults,
  disableSelection,
  customButton,
  customHandleDelete,
  queryOptions,
  query
}) => (
  <ListModal
    enableRadioUnselect
    customHandleDelete={customHandleDelete}
    onCloseCallback={handleCloseCallback}
    disableSelection={disableSelection}
    title="Select Vehicles"
    handleCloseCallback={handleCloseCallback}
    noResults={noResults}
    searchPlaceholder="Search for a vehicle"
    singleSelection={singleSelection}
    values={values}
    disableQuery={!!data}
    data={data}
    query={query}
    dataSelector={data => data?.vehiclesConnection?.values || []}
    queryOptions={queryOptions}
    mainAction={{
      label: "Done",
      onClick: onDone
    }}
    secondaryAction={{
      label: "Create a new vehicle",
      onClick: handleNewClick
    }}
    error={error}
    incomingItems={incomingItems}
    columns={selectionColumns}
    renderSelectionColumns={resultColumns}
    customButton={customButton}
    onModalClick={toggleModal =>
      customButton ? (
        customButton(toggleModal)
      ) : (
        <SelectActionButton onClick={toggleModal}>
          Select vehicles
        </SelectActionButton>
      )
    }
  />
);

export default SelectSupplier;
