import gql from 'graphql-tag';

export default gql`
  query GetAllClients {
    clients{
      id
      contactName
      companyName
      location
      officeType
      address
      phone
      email
      lat
      long
      events {
        id
        name
        type
      }
    }
  }
`;
