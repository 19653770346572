import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "components_v2/Typography/Typography";
import Map from "components_v2/Map/Map";
import ReactResizeDetector from "react-resize-detector";
import { makeStyles } from "@material-ui/core/styles";
import {mobileViewBreakpoint} from '../../../../App.helper';

 const useStyles = makeStyles((theme) => ({
    trackingDetailsContainer:{
      marginTop: 56,
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginTop: 20,
      },
    },
    trackRideMain:{
      width:"100%",
      height: "100vh",
      overflow:'hidden',
      marginTop: 48,
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginTop: 0,
      },
    }
 }))


const TrackRide = ({
  driverSchedule,
  children,
  isMapVisible = true,
  isTrackRideDataVisible = true,
  history,
  bookingUpdatesLoading ,
}) => {
  const classes = useStyles();
  const {
    passengerschedule: {
      bookingId,
      from: { name: fromName },
      to: { name: toName },
      passenger: {fullName },
    },
  } = driverSchedule;

  const detailsDiv = {
    display: "flex",
  };

  const typogBaseStyle = {
    lineHeight: "20px",
  };
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  return (
    <div className={classes.trackRideMain}>
      <div style={{ marginRight: "auto", marginBottom: 56 }}>
        {isTrackRideDataVisible && (
          <div style={{marginLeft:'26px',marginTop:isMobileView? '26px':''}}>
            <Typography
              customVariant="bodyLargeBold"
              style={typogBaseStyle}
            >{`Tracking ride #${bookingId}`}</Typography>
            <div style={detailsDiv}>
              <Typography
                customVariant="bodySmallRegular"
                style={{ ...typogBaseStyle, color: "#666" }}
              >
                Journey: &nbsp;
              </Typography>
              <Typography
                customVariant="bodySmallRegular"
                style={typogBaseStyle}
              >{`${fromName} - ${toName}`}</Typography>
            </div>
            <div style={detailsDiv}>
              <Typography
                customVariant="bodySmallRegular"
                style={{ ...typogBaseStyle, color: "#666" }}
              >
                Passenger: &nbsp;
              </Typography>
              <Typography
                customVariant="bodySmallRegular"
                style={typogBaseStyle}
              >{`${fullName}`}</Typography>
            </div>
          </div>
        )}
        <div>
          <Typography customVariant="bodyLargeBold" style={typogBaseStyle}>
            {children}
          </Typography>
        </div>
        {isMapVisible && (
          <div className={classes.trackingDetailsContainer}>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) => (
                <Map bookingUpdatesLoading ={bookingUpdatesLoading } history={history}isTrackRide width={width} trackDriverScheduleData={driverSchedule} height={height} />
              )}
            </ReactResizeDetector>
          </div>
        )}
      </div>
    </div>
  );
};

TrackRide.propTypes = {
  driverSchedule: PropTypes.shape({
    passengerschedule: PropTypes.shape({
      bookingId: PropTypes.string.isRequired,
      from: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      to: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      passenger: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TrackRide;
