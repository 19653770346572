import gql from 'graphql-tag';

export default gql`
  mutation CreateModule($data: ModuleInput) {
    createModule(input: { data: $data }) {
      module {
        id
      }
    }
  }
`;
