import { Button, Menu, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import {
  Book,
  Diamond,
  Fastfood,
  Flower,
  Gift,
  Light,
  Simcard,
  Waterbottle,
} from '../../Icons/Icons';
import { useCustomStyles } from './IconSelector.styles';

const IconSelector = ({
  label,
  options,
  name,
  value,
  onChange,
  readOnly,
  disabled,
}) => {
  const customClasses = useCustomStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnMenuClick = (option) => {
    onChange({
      persist: () => {},
      target: {
        type: 'change',
        name,
        value: option && option.value,
      },
    });
  };

  const renderIcon = (option) => {
    let iconValue = null;

    if (typeof option === 'string') {
      iconValue = option;
    }
    if (option === null) iconValue = null;
    else if (typeof option === 'object') {
      iconValue = option.value;
    }

    switch (iconValue) {
      case 'Book':
        return <Book />;

      // case 'Champagne':
      //   return <Champagne />;

      case 'Diamond':
        return <Diamond />;

      case 'Fastfood':
        return <Fastfood />;

      case 'Flower':
        return <Flower />;

      case 'Gift':
        return <Gift />;

      case 'Light':
        return <Light />;

      case 'Simcard':
        return <Simcard />;

      case 'Waterbottle':
        return <Waterbottle />;
      default:
        return iconValue;
    }
  };

  return (
    <div className={customClasses.iconSelectorWrapper}>
      {(readOnly && ((value && renderIcon(value)) || <></>)) || (
        <Button
          disabled={disabled}
          className={classNames(customClasses.iconSelectorButton, {
            [customClasses.valueSelected]: value,
            [customClasses.disabled]: disabled && !renderIcon(value),
          })}
          onClick={handleClick}
        >
          {(value && renderIcon(value)) || label}
        </Button>
      )}
      <Menu
        className={customClasses.sortMenuRecords}
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '200px',
            width: '260px !important',
          },
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_.map(options, (option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleOnMenuClick(option);
              handleClose();
            }}
            value={option.value}
          >
            {renderIcon(option)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default IconSelector;
