/* eslint-disable complexity */
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";

import Button from "components_v2/Button/Button";
import SuccessCard from "components_v2/Cards/SuccessCard/SuccessCard";
import FullScreenPopover from "components_v2/FullScreenPopover/FullScreenPopover";
import Loading from "components_v2/Loading/CircularProgress";
import Typography from "components_v2/Typography/Typography";
import AddBookingForm from "./AddBookingForm";
import { Form, Formik } from "formik";
import CreatePassengerSchedule from "gql/PassengerSchedules/Create";
import CreatePassengerScheduleMulti from "gql/PassengerSchedules/CreateMultiple";
import UpdatePassengerSchedule from "gql/PassengerSchedules/Update";
import auth from "utils/auth";
import useStyles from "./AddBooking.style";
import withFormState from "containers/Wrappers/withFormState";
import {
  formatIncomingData,
  formatSubmissionValues,
  validationSchema,
  mergeReturnValues
} from "./helpers";
import ResendEmailConfirmationModal from "./ResendEmailConfirmationModal/ResendEmailConfirmationModal";



const AddBookingComponent = ({
  projectId,
  history,
  location,
  match,
  availableProducts,
  bookingData = null,
  refetchPassengerSchedule,
  projectInfo,
  isPublic,
  isDuplicate,
  isReturnTrip,
  resendEmailConfirmation,
  getPreviousFormState,
  ...otherProps
}) => {
  const classes = useStyles();
  const previousFormState = getPreviousFormState(location);
  const previousFormStateValues = previousFormState?.values ? mergeReturnValues(previousFormState) : {};

  const [passengerScheduleId, setPassengerScheduleId] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [passengerEmail, setPassengerEmail] = useState("");
  const [reocurringDates, setReocurringDates] = useState([]);
  const [isReocurring, setIsReocurring] = useState(false);
  const [numberOfBookings, setNumberOfBookings] = useState(1);

  const serviceDurationOptions = projectInfo.events[0].transferservices
    .filter(service => service.enabled)
    .map(service => ({ name: service.name, value: service.id }));

  let currentUser;

  if (isPublic) {
    const { id, phone, email, firstName, lastName } = auth.getUserInfo();

    currentUser = {
      id,
      phone,
      email,
      fullName: `${firstName} ${lastName}`,
      firstName,
      lastName
    };
  }

  const isUpdate = !!bookingData && !isDuplicate && !isReturnTrip;
  const initialInputValues = {
    event: projectId,
    supplier: undefined,
    origin: "Booking",
    products: [],
    discount: 0,
    numberOfVehicles: 1,
    luggageCount: 0,
    numPassengers: 1,
    passengersPerVehicle: 0,
    stops: [{ order: 1 }, { order: 2 }],
    documents: [],
    drivers: [],
    module: null,
    department: null,
    mainPassenger: isPublic ? [{ id: currentUser.id }] : [],
    additionalPassengers: [],
    from: null,
    to: null,
    vehicleClass: null,
    serviceDuration:
      serviceDurationOptions && serviceDurationOptions.length
        ? serviceDurationOptions[0]
        : null,
    arrivalDate: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    arrivalTime: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    isReocurring: true,
    reocurringDates: [],
    bookingSource: isPublic ? "public" : "internal"
  };
  const [
    resendEmailConfirmationModalData,
    setResendEmailConfirmationModalData
  ] = useState(null);

  const resendEmail = async data => {
    setResendEmailConfirmationModalData(null);

    await resendEmailConfirmation({
      variables: {
        ...data
      }
    });
  };
  const initialValues = bookingData
    ? {
        ...initialInputValues,
        ...formatIncomingData(
          bookingData,
          serviceDurationOptions,
          isReturnTrip,
          isUpdate,
          isDuplicate
        ),
        ...previousFormStateValues
      }
    : { ...initialInputValues, ...previousFormStateValues };

  const [createPassengerSchedule, { loading: createLoading }] = useMutation(
    CreatePassengerSchedule
  );

  const [
    createPassengerScheduleMulti,
    { loading: createMultiLoading }
  ] = useMutation(CreatePassengerScheduleMulti);

  const [updatePassengerSchedule, { loading: updateLoading }] = useMutation(
    UpdatePassengerSchedule
  );

  const getSuccessHeader = (
    isPublic,
    isUpdate,
    isDuplicate,
    isReturnTrip,
    isReocurring,
    numberOfBookings
  ) => {
    if (isPublic) return "Booking Request Created";
    if (isUpdate) return "Booking Updated";
    if (isDuplicate) return "Booking Duplicated";
    if (isReturnTrip) return "Return Trip Created";
    if (isReocurring || numberOfBookings > 1) return "Bookings created";
    return "Booking Created";
  };

  const getSuccessDescription = (isUpdate, isReocurring, numberOfBookings) => {
    if (isUpdate)
      return `Congratulations, booking ${bookingId} has been updated.`;
    if (isReocurring && reocurringDates?.length > 1)
      return `Congratulations, booking ${bookingId} and ${reocurringDates?.length -
        1} reocurring booking(s) have been created.`;
    if (numberOfBookings > 1)
      return `Congratulations, booking ${bookingId} and ${numberOfBookings -
        1} booking(s) have been created.`;
    return `Congratulations, booking ${bookingId} has been created.`;
  };

  const publicSuccessDescription = (
    <>
      <span className={classes.publicDescription}>
        {`Contratulations, booking ${bookingId} has been created.`}
      </span>
      <span className={classes.publicDescriptionEmail}>
        You will receive a confirmation email at:{" "}
        <span className={classes.passengerEmail}>{passengerEmail}</span>
      </span>
      <Typography style={{ margin: "0 0 8px 0" }} customVariant="h5Bold">
        In a hurry?
      </Typography>
      <a href="#" className={classes.publicDescriptionLink}>
        Chat with us
      </a>
      <span className={classes.publicDescriptionPhone}>
        Call us at: 999 9776 5433
      </span>
    </>
  );

  const getTitle = (isUpdate, isDuplicate, isReturnTrip) => {
    if (isUpdate) return "Update an existing booking";
    if (isDuplicate) return "Duplicate an existing booking";
    if (isReturnTrip) return "Create a return trip";
    return "Create a new booking";
  };

  const handleSubmit = async (values, actions) => {
    const formatted = formatSubmissionValues(
      values,
      initialValues,
      projectId,
      isUpdate,
      isPublic
    );

    if (isUpdate) {
      const id = bookingData.id;
      const res = await updatePassengerSchedule({
        variables: {
          id: { id },
          data: formatted
        }
      });
      if (res && res.data && res.data.updatePassengerschedule) {
        setPassengerScheduleId(
          res.data.updatePassengerschedule.passengerschedule.id
        );
        setBookingId(
          res?.data?.updatePassengerschedule?.passengerschedule.bookingId
        );
        await refetchPassengerSchedule();
      }
    } else if (values.reocurringDates.length > 1) {
      const passengerschedules = values.reocurringDates.map(date => {
        const startDate = moment(date).format("YYYY-MM-DD");
        const startTime = moment(values.arrivalTime).format("HH:mm");

        return {
          ...formatted,
          start: `${startDate}T${startTime}:00Z`
        };
      });

      const res = await createPassengerScheduleMulti({
        variables: {
          input: passengerschedules
        }
      });

      const createdPassengerschedules = res?.data?.createPassengerschedules;
      setPassengerScheduleId(createdPassengerschedules[0].id);
      setBookingId(createdPassengerschedules[0].bookingId);
      setIsReocurring(true);
      setReocurringDates(
        JSON.parse(createdPassengerschedules[0].reocurringDates)
      );
      setPassengerEmail(createdPassengerschedules[0].createdBy.email);
      actions.resetForm(initialValues);
    } else {
      const res = await createPassengerSchedule({
        variables: {
          data: formatted
        }
      });

      const createdPassengerschedule =
        res?.data?.createPassengerschedule?.passengerschedule;

      setNumberOfBookings(parseInt(formatted.numberOfVehicles));
      setPassengerScheduleId(createdPassengerschedule?.id);
      setBookingId(createdPassengerschedule?.bookingId);
      setPassengerEmail(createdPassengerschedule?.createdBy?.email);
      actions.resetForm(initialValues);
    }

    actions.setSubmitting(false);
  };

  const handlePopoverClose = () => {
    history.push(`/project/${projectId}/bookings`);
  };

  const returnTripAction =
    !isUpdate && !isReturnTrip
      ? [
          {
            text: "Create Return Trip",
            onClick: () => {
              history.push(
                `/project/${projectId}/booking/${passengerScheduleId}/return`
              );
            }
          }
        ]
      : [];

  const duplicateAction = isDuplicate
    ? [
        {
          text: "Duplicate Booking",
          onClick: () => {
            history.push(
              `/project/${projectId}/booking/${passengerScheduleId}/duplicate`
            );
          }
        }
      ]
    : [];

  if (passengerScheduleId)
    return (
      <FullScreenPopover
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        hideHeaderButtons={isPublic}
        onCloseCallback={handlePopoverClose}
        previousFormState={previousFormState}
        pageView
      >
        <ResendEmailConfirmationModal
          data={resendEmailConfirmationModalData}
          onClickCancel={() => {
            setResendEmailConfirmationModalData(null);
          }}
          onClickSend={async data => {
            resendEmail(data);
          }}
        />
        <SuccessCard
          headerText={getSuccessHeader(
            isPublic,
            isUpdate,
            isDuplicate,
            isReturnTrip,
            isReocurring,
            numberOfBookings
          )}
          description={
            isPublic
              ? publicSuccessDescription
              : getSuccessDescription(isUpdate, isReocurring, numberOfBookings)
          }
          mainAction={
            !isPublic
              ? {
                  text: `See booking${numberOfBookings > 1 ? "s" : ""}`,
                  onClick: () => {
                    const isBookingGroupId =
                      (bookingId.match(/-/g) || []).length > 1;

                    let bookingGroupId;

                    if (isBookingGroupId) {
                      bookingGroupId = bookingId.slice(
                        0,
                        bookingId.lastIndexOf("-")
                      );
                    }

                    history.push(
                      `/project/${projectId}/bookings/${bookingGroupId ||
                        bookingId}`
                    );
                  }
                }
              : null
          }
          secondaryAction={
            !isPublic
              ? {
                  text: "Edit Booking",
                  onClick: () => {
                    if (!isUpdate)
                      history.push(
                        `/project/${projectId}/booking/${passengerScheduleId}/edit`
                      );
                    setPassengerScheduleId(null);
                    setNumberOfBookings(1);
                  }
                }
              : null
          }
          mainBodyActions={[
            {
              text: "Add Bookings",
              onClick: () => {
                if (isUpdate) history.push(`/project/${projectId}/booking/new`);
                setPassengerScheduleId(null);
              }
            },

            ...(isUpdate
              ? [
                  {
                    text: "Send Email Confirmation",
                    onClick: () => {
                      if (isUpdate)
                        setResendEmailConfirmationModalData({
                          record: bookingData
                        });
                    }
                  }
                ]
              : []),

            ...returnTripAction,
            ...duplicateAction
          ]}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {(createLoading || createMultiLoading || updateLoading) && (
        <Loading fullPage />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(isPublic, isUpdate)}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form>
            <FullScreenPopover
              hideHeaderButtons={isPublic}
              previousFormState={previousFormState}
              title={getTitle(isUpdate, isDuplicate, isReturnTrip)}
              subtitle={projectInfo.events[0].name}
              numberOfSteps={0}
              currentStep={1}
              onCloseCallback={handlePopoverClose}
              mainAction={
                <Button type="submit" variant="contained">
                  Save
                </Button>
              }
              secondaryAction={<Button variant="simple">Save and close</Button>}
              pageView
            >
              <AddBookingForm
                {...props}
                {...otherProps}
                classes={classes}
                projectId={match.params.projectId}
                availableProducts={availableProducts}
                isUpdate={isUpdate}
                isReturnTrip={isReturnTrip}
                isDuplicate={isDuplicate}
                bookingData={bookingData}
                history={history}
                location={location}
                passengerScheduleId={passengerScheduleId}
                projectInfo={projectInfo}
                isPublic={isPublic}
                currentUser={currentUser}
                serviceDurationOptions={serviceDurationOptions}
              />
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddBookingComponent));
