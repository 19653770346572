import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid #000',
  },
}));

export default function Divider() {
  const classes = useStyles();
  return <div className={classes.root} />;
}
