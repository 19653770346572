import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import InputLabel from '../InputLabel/InputLabel';
import InputError from '../InputError/InputError';
import {useStyles,useCustomStyles} from './PhoneInput.styles'
const PhoneInput = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  isDisabled,
  error,
}) => {
  const variant = 'outlined';
  const classes = useStyles();
  const customClasses = useCustomStyles();

  return (
    <div  className={customClasses.inputWrapper}>
      <InputLabel label={label} />
      <MuiPhoneNumber
        fullWidth
        name={name}
        error={!!error}
        placeholder={placeholder}
        variant={variant}
        value={value}
        InputProps={{ classes }}
        onChange={
          // TODO: NEED TO REVISIT
          (event) =>
            onChange({
              persist: () => {},
              target: {
                type: 'change',
                name,
                value: event,
              },
            })
        }
        disabled={isDisabled}
        defaultCountry="ae"
        disableAreaCodes
      />
      <InputError error={error} />
    </div>
  );
};

export default PhoneInput;
