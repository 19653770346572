import { useQuery } from "@apollo/react-hooks";

import React from "react";
import { withRouter } from "react-router-dom";
import GetOneGQL from "gql/Suppliers/GetOne";
import withMainLayout from "components_v2/Layout/withMainLayout";
import GetAllGQL from "gql/Suppliers/GetAllByEvent";
import SupplierComponent from "./Supplier.Component.jsx";
import withUserRole from "components_v2/Wrappers/withUserRole";

/*
const UPDATE_GQL = gql`
  mutation UpdateEvent($id: InputID!, $data: editEventInput) {
    updateEvent(input: { where: $id, data: $data }) {
      event {
        id:
      }
    }
  }
`; */

const SuppliersContainer = ({ match, history }) => {
  const { supplierId, selectedTab } = match.params;

  const handleTabChange = value => {
    history.push(`/supplier/${supplierId}/${value}`);
  };
  /*
  const [save, { data: mutationData, loading: mutationLoading }] = useMutation(
    UPDATE_GQL,
    { variables: { id: { id: projectId } } }
  ); */

  //var

  const { data, loading } = useQuery(GetOneGQL, {
    variables: { id: supplierId },
    fetchPolicy: "no-cache"
  });

  const { data: dataAll, loading: loadingAll } = useQuery(GetAllGQL, {
    variables: {},
    fetchPolicy: "no-cache"
  });

  return (
    <>
      <SupplierComponent
        data={(data?.supplier) || {}}
        dataAll={(dataAll?.suppliers) || []}
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
        loading={loading || loadingAll}
      />
    </>
  );
};

export default withMainLayout(
  "Dashboard",
  {},
  withUserRole("vehicles", withRouter(SuppliersContainer))
);

/*
const Container = ({ id, type, trackDriver }) => {
  const { data, loading } = useQuery(
    trackDriver ? GetAllByDriver : GetAllByVehicle,
    {
      variables: { id, type }
    }
  );

  if (loading) {
    return <div>loading..</div>;
  }

  return (
    <ServiceTrackingComponent
      loading={loading}
      data={
        data &&
        (trackDriver
          ? data.driverongoingtrackings
          : data.vehiclemaintenancetrackings)
      }
    />
  );
};

export default Container;
*/
