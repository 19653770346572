import React from 'react';
import { Grid } from '@material-ui/core';
import PasswordInput from 'components_v2/Inputs/PasswordInput/PasswordInput';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import CheckboxInput from 'components_v2/Inputs/CheckboxInput/CheckboxInput';
import { Visible } from 'components_v2/Icons/Icons';

const GeneratePassword = ({
  handleChange,
  gridContainerClass,
  values,
  touched = {},
  errors = {},
}) => (
  <>
    <Grid className={gridContainerClass} container spacing={3}>
      <Grid item md={4} sm={6} xs={12}>
        <CheckboxInput
          label="Automatically generate a password"
          name="generatePassword"
          value={values.generatePassword}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
    {!values.generatePassword && (
      <Grid className={gridContainerClass} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <PasswordInput
            disableAutocomplete
            name="customPassword"
            label="Password *"
            placeholder="Please enter a password"
            icon={Visible}
            value={values.customPassword}
            onChange={handleChange}
            error={touched?.customPassword && errors?.customPassword}
          />
        </Grid>
      </Grid>
    )}
    <Grid className={gridContainerClass} container spacing={3}>
      <Grid item md={4} sm={6} xs={12}>
        <CheckboxInput
          label="Require this user to change their password when they first login"
          name="requirePasswordChange"
          value={values.requirePasswordChange}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
    <Grid className={gridContainerClass} container spacing={3}>
      <Grid item md={4} sm={6} xs={12}>
        <CheckboxInput
          label="Send password in email upon completion"
          name="willSendPassword"
          value={values.willSendPassword}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
    {values.willSendPassword && (
      <Grid className={gridContainerClass} container spacing={3}>
        <Grid item md={4} sm={6} xs={12}>
          <TextInput
            name="sendPasswordEmail"
            label="Send password to this email *"
            placeholder="Enter email *"
            value={values.sendPasswordEmail}
            onChange={handleChange}
            error={touched.sendPasswordEmail && errors.sendPasswordEmail}
          />
        </Grid>
      </Grid>
    )}
  </>
);

export default GeneratePassword;
