import gql from 'graphql-tag';

export default gql`
  mutation UpdateStoredlocation($id: InputID, $data: editStoredlocationInput) {
    updateStoredlocation(input: { where: $id, data: $data }) {
      storedlocation {
        id
      }
    }
  }
`;
