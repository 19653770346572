import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import ListModal from 'components_v2/ListModal/ListModal';
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';

const ProductSelector = ({
  onDone,
  values,
  error,
  refetch,
  products,
  disableEdit,
  customHandleDelete,
  createProduct,
  updateProduct,
  projectId,
  selectedColumns,
  selectionColumns,
}) => {

  const incomingItems = _.map(values.products, (product) => product.id);

  const sortedProducts = _.orderBy(products, ['id:'], ['desc']);

  return (
    <ListModal
      showHeader
      disableQuery
      title="Select products"
      searchPlaceholder="Search for a product"
      mainAction={{
        label: 'Done',
        onClick: onDone,
      }}
      error={error}
      data={sortedProducts}
      customHandleDelete={customHandleDelete}
      incomingItems={incomingItems}
      columns={selectionColumns}
      renderSelectionColumns={selectedColumns}
      customListActions={{
        edit:  disableEdit ? undefined : {
          onSave: async ({ id, values }) => {
            await updateProduct({
              variables: {
                id: { id },
                data: { ...values, type: values.type && values.type.value },
              },
            });
            await refetch();
          },
        },
        create: {
          onSave: async ({ values }) => {
            await createProduct({
              variables: {
                data: {
                  ...values,
                  price: null,
                  type: values.type && values.type.value,
                  event: projectId,
                },
              },
            });
            await refetch();
          },
        },
      }}
      onModalClick={(toggleModal) => (
        <SelectActionButton onClick={toggleModal}>
          Select Products
        </SelectActionButton>
      )}
    />
  );
};

export default withRouter(ProductSelector);
