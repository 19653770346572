import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import useChat from "hooks/useChat";
import HighlightComponent from "./Highlight";
import GetAllGroupedByEvent from "gql/Alerts/GetAllGroupedByEvent";
import { getLocalAlertsGroupedData,getIsNewAlerts, getIsLoading } from "components_v2/Views/Internal/Dashboard/DashboardHelper";
import {
  mockAlertsGroupedData,
} from "components_v2/Views/Internal/Dashboard/Dashboard.mock";

const HighlightContainer = (props) => {

  const { 
    projectId,
    history,
    match,
    isHelpTutorial,
    passengerSchedulesDataLoading,
    progress,
    ongoing,
    completed,
    dayStart,
    dayEnd,
  } = props;

  const [localAlertsGroupedData, setLocalAlertsGroupedData] = useState({});

  let { data: alertsGroupedData, loading: alertsGroupedDataLoading } = useQuery(
    GetAllGroupedByEvent,
    {
      variables: { id: projectId, status: "unresolved", start: dayStart, end: dayEnd },
      fetchPolicy: "no-cache",
      skip: isHelpTutorial
    }
  );

  if (isHelpTutorial) {
    alertsGroupedData = mockAlertsGroupedData;
  }

  useEffect(() => {
    setLocalAlertsGroupedData(alertsGroupedData);
  }, [alertsGroupedData]);

  const [
    getNewAlerts,
    { data: lazyAlertsGroupedData, loading: lazyAlertsGroupedDataLoading }
  ] = useLazyQuery(GetAllGroupedByEvent, {
    variables: { id: projectId, status: "unresolved" },
    fetchPolicy: "no-cache",
    skip: isHelpTutorial
  });

  useChat(
    "alertUpdates",
    async ({ alerts }) => {
      if (getIsNewAlerts({alerts ,match})) {
        await getNewAlerts();
      }
 }
  );
  useChat(
    "alertCreate",
    async () => {
      await getNewAlerts();
    }
  );
  useEffect(() => {
    setLocalAlertsGroupedData(lazyAlertsGroupedData);
  }, [lazyAlertsGroupedData]);

  return (
    <HighlightComponent
      alertsLoading={alertsGroupedDataLoading && lazyAlertsGroupedDataLoading}
      loading={getIsLoading({ alertsGroupedDataLoading, passengerSchedulesDataLoading })}
      progress={progress}
      projectId={projectId}
      history={history}
      alerts={getLocalAlertsGroupedData({ localAlertsGroupedData })}
      ongoing={ongoing}
      completed={completed}
    />
  )
}

export default withRouter(HighlightContainer);
