import React from 'react';

import { AuthCard } from 'components_v2/Cards';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileViewBreakpoint } from '../../../../App.helper';

const AuthLayout = (props) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const { children, ...other } = props;

  return (
    <div
      style={{
        minHeight: '100vh',
        background:
          'linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)',
        // display: isMobileView ? 'block' : 'flex',
        display: 'flex',
        alignItems: isMobileView ? 'stretch' : 'center',
        justifyContent: 'center',
      }}
    >
      <AuthCard {...other}>{children}</AuthCard>
    </div>
  );
};

export default AuthLayout;
