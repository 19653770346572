import { makeStyles } from '@material-ui/core/styles';
import { extraSmallMobileBreakpoint } from '../../App.helper';

const DIV = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const useStyles = makeStyles((theme) => ({
  leftContent: {
    ...DIV,
    justifyContent: 'flex-start',
  },
  rightContent: {
    ...DIV,
    justifyContent: 'flex-end',
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      justifyContent: 'flex-start',
    },
  },
  closeButton: {
    background: 'rgba(0, 0, 0, 0.05)',
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    marginLeft: 40,
    cursor: 'pointer',
    [theme.breakpoints.down(extraSmallMobileBreakpoint)]: {
      position: 'relative',
      bottom: 9,
      marginLeft: 'auto',
    },
  },
}));

export default useStyles;
