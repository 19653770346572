export const mockData = {
  storedlocations: [
    {
      id: '5f271ba97442154fd8a8c13b',
      identifier: 'RDH0010',
      name: 'Dubai Airport Terminal 2 Departure',
      type: 'Event venue',
      address: 'Dubai - United Arab Emirates',
      lat: 25.2628902,
      long: 55.3532168,
      __typename: 'Storedlocation',
    },

    {
      id: '5fb11e937fee2b9d34c2693b',
      identifier: null,
      name: 'International Airport',
      type: 'Event venue',
      address:
        'Dubai International Airport (DXB), Dubai - United Arab Emirates',
      lat: 25.2531745,
      long: 55.3656728,
      __typename: 'Storedlocation',
    },
  ],
  shuttleschedules: [
    {
      id: '5f29cebee25630e12cdb3fc4',
      name: 'Number 69',
      startDate: '2020-08-31',
      endDate: '2020-08-01',
      vehicles: [
        { id: '5f271bdb7442154fd8a8c13d', __typename: 'Vehicle' },
        { id: '5f2c6a7916f55c9d3ce20640', __typename: 'Vehicle' },
        { id: '5f31808a7a21a82615a96f8b', __typename: 'Vehicle' },
        { id: '5f31808f7a21a82615a96f8c', __typename: 'Vehicle' },
        { id: '5f3180947a21a82615a96f8d', __typename: 'Vehicle' },
        { id: '5f33b493689bc1372d5e6f65', __typename: 'Vehicle' },
        { id: '5f33b7a0689bc1372d5e6f69', __typename: 'Vehicle' },
        { id: '5f33b829689bc1372d5e6f6d', __typename: 'Vehicle' },
      ],
      shuttlescheduleitems: [
        {
          id: '5fadbf492069e70017724efb',
          when: null,
          name: '',
          time: '',
          location: {
            id: '5f271ba97442154fd8a8c13b',
            __typename: 'Storedlocation',
          },
          __typename: 'Shuttlescheduleitem',
        },
      ],
      __typename: 'Shuttleschedule',
    },
  ],
  vehicles: [
    {
      id: '5f271bdb7442154fd8a8c13d',
      make: 'Audi v2',
      model: 'Car',
      year: '2019',
      vehicleNumber: 'B0020',
      maxPassengers: 7,
      type: 'sedan',
      enabled: true,
      notes: ');)',
      plateNumber: 'BF-2430',
      status: 'allocated',
      authorizedDrivers: [],
      assignedDrivers: [
        {
          id: '5f29f60970652c577c7d8eb4',
          notes: '',
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: '5f289d298d19e3785c525f70',
            identifier: 'B0010',
            __typename: 'UsersPermissionsUser',
          },
          __typename: 'Assignedvehicle',
        },
      ],
      __typename: 'Vehicle',
    },
  ],
};
