import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 40,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '3px',
    marginTop: 16,
    marginBottom: 56,

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: 24,
      border: 'none',
      margin: 0,
    },
  },
}));

export default useStyles;
