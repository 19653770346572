import React from "react";
import { Add } from "components_v2/Icons/Icons";
import DynamicList from "components_v2/List/DynamicList";
import TablePagination from "@material-ui/core/TablePagination";
import { UpdateShuttleschedule } from "./gql";
import { useMutation } from "@apollo/react-hooks";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../App.helper";
import { deepCopy } from "../../../../utils/helpers";

const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const DEFAULT_PAGE_SIZE = 5;

const VehicleAssignmentItems = ({
  shuttleScheduleId,
  vehicleItems,
  vehiclesObject,
  refetch
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGE_SIZE);
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const [updateSchedule] = useMutation(UpdateShuttleschedule);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_PAGE_SIZE));
    setPage(0);
  };

  const sortFunction = (x, y) =>
    vehicleItems.some(v => v.id === x.id) -
    vehicleItems.some(v => v.id === y.id);

  const localVehicleItems = deepCopy(vehicleItems || []);

  return (
    <>
      <DynamicList
        isMobileView={isMobileView}
        showHeader
        noResultsText="No vehicle assignment items"
        data={paginate(
          Object.keys(vehiclesObject || {}).sort(sortFunction),
          DEFAULT_PAGE_SIZE,
          page + 1
        )}
        numRows={localVehicleItems.length}
        columns={[
          {
            headerName: "Identifier",
            field: "identifier",
            width: 4,
            render: id => vehiclesObject[id].vehicleNumber
          },
          {
            headerName: "Type",
            field: "type",
            width: 4,
            render: id => vehiclesObject[id].type
          },
          {
            headerName: "Assigned",
            field: "type",
            width: 4,
            render: id =>
              localVehicleItems.some(v => v.id === id) ? (
                <strong>Assigned</strong>
              ) : (
                <span>Available</span>
              )
          }
        ]}
        customActions={[
          {
            onClick: async id => {
              await updateSchedule({
                variables: {
                  id: { id: shuttleScheduleId },
                  data: {
                    vehicles: [...localVehicleItems.map(v => v.id), id]
                  }
                }
              });

              refetch();
            },
            icon: Add,
            successString: "Successfully assigned vehicle"
          }
        ]}
      ></DynamicList>
      <TablePagination
        component="div"
        count={Object.keys(vehiclesObject).length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default VehicleAssignmentItems;
