import gql from 'graphql-tag';

export default gql`
  mutation CreateDepartment($data: DepartmentInput) {
    createDepartment(input: { data: $data }) {
      department {
        id
      }
    }
  }
`;
