
import React from 'react';
import { withRouter } from 'react-router-dom';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import FavoritesComponent from './Favorites.component';

const FavoritesContainer = ({ match, history }) => {
  const { selectedTab } = match.params;
  const handleTabChange = (value) => {
    history.push(`/favorites/${value}`);
  };
  return (
    <>
      <FavoritesComponent
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
      />
    </>
  );
};

export default withMainLayout('Dashboard', {}, withRouter(FavoritesContainer));
