import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import _ from 'lodash';

import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import TextAreaInput from 'components_v2/Inputs/TextAreaInput/TextAreaInput';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import PhoneInput from 'components_v2/Inputs/PhoneInput/PhoneInput';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';
import DynamicList from 'components_v2/List/DynamicList';
import Typography from 'components_v2/Typography/Typography';
import Button from 'components_v2/Button/Button';
import { Add } from 'components_v2/Icons/Icons';
import {
  priorityOptions,
  formatPassengerValidations,
  getErrorMessage,
} from './helpers';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from '../../../../App.helper';
import UploadImage from 'components_v2/Upload/UploadImage/UploadImage';
import EmailDisplay from '../../../EmailDisplay/EmailDisplay';

const AddUserForm = ({
  values,
  touched,
  errors: submitErrors,
  setFieldValue,
  setTouched,
  validateForm,
  classes,
  isUpdate,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const errors = _.isEmpty(submitErrors) ? validationErrors : submitErrors;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  useEffect(() => {
    setValidationErrors({});
  }, [JSON.stringify(values)]);

  const handleAddPassenger = async () => {
    const validationErrors = await validateForm();
    setTouched(validationErrors);

    if (_.isEmpty(validationErrors)) {
      setFieldValue('passengers', [
        ...values.passengers,
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          passengerPriority: { name: 'Normal' },
          notes: '',
          profileImage: ''
        },
      ]);
    } else setValidationErrors(validationErrors);
  };

  const formattedTouched = formatPassengerValidations(touched);
  const formattedErrors = formatPassengerValidations(errors);

  const renderPassengerForm = (index, isLast) => {
    const handleFormChange = ({ target }) => {
      const { name, value } = target;

      const newPassengers = values.passengers;
      const newPassenger = { ...values.passengers[index], [name]: value };
      newPassengers.splice(index, 1, newPassenger);

      setFieldValue('passengers', newPassengers);
    };

    const handleDelete = () => {
      const newPassengers = values.passengers.filter(
        (passenger, passengerIndex) => passengerIndex !== index
      );

      setFieldValue('passengers', newPassengers);
    };

    const deleteButton =
      isLast && index !== 0 ? (
        <Grid container spacing={3}>
          <Grid md={6} xs={12} item>
            <Button variant="simple" onClick={handleDelete}>
              Delete passenger
            </Button>
          </Grid>
        </Grid>
      ) : null;

    return (
      <Box className={!isLast ? classes.detailPanelContainer : null}>
        {isLast && (
          <Typography
            style={{ marginBottom: 16 }}
            customVariant="bodyLargeRegular"
          >
            {`${index + 1}. Passenger details`}
          </Typography>
        )}
        <Grid container spacing={3}>
          <Grid md={4} sm={6} xs={12} item>
            <TextInput
              name="firstName"
              label="First name *"
              placeholder="Enter first name"
              value={values.passengers[index].firstName}
              onChange={handleFormChange}
              error={getErrorMessage(errors, touched, index, 'firstName')}
            />
          </Grid>
          <Grid md={4} sm={6} xs={12} item>
            <TextInput
              name="lastName"
              label="Last name *"
              placeholder="Enter last name"
              value={values.passengers[index].lastName}
              onChange={handleFormChange}
              error={getErrorMessage(errors, touched, index, 'lastName')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid md={4} sm={6} xs={12} item>
            <TextInput
              name="email"
              label="Email"
              placeholder="Enter email"
              value={values.passengers[index].email}
              onChange={handleFormChange}
              error={getErrorMessage(errors, touched, index, 'email')}
            />
          </Grid>
          <Grid md={4} sm={6} xs={12} item>
            <PhoneInput
              name="phone"
              label="Phone"
              placeholder="Enter phone number"
              value={values.passengers[index].phone}
              onChange={handleFormChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid md={4} sm={6} xs={12} item>
            <SelectInput
              name="passengerPriority"
              label="Priority *"
              placeholder="Select priority"
              value={values.passengers[index].passengerPriority}
              onChange={handleFormChange}
              options={priorityOptions}
              isError={touched.passengerPriority && errors.passengerPriority}
              errorMessage={getErrorMessage(
                errors,
                touched,
                index,
                'passengerPriority'
              )}
            />
          </Grid>
          <Grid md={4} sm={6} xs={12} item>
            <TextInput
              name="company"
              label="Company"
              placeholder="Enter company name"
              value={values.passengers[index].company}
              onChange={handleFormChange}
              error={getErrorMessage(errors, touched, index, 'company')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid md={6} xs={12} item>
            <TextAreaInput
              name="notes"
              label="Notes"
              rows={6}
              placeholder="Enter notes"
              value={values.passengers[index].notes}
              onChange={handleFormChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid className={classes.photoRow} md={6} xs={12} item>
            <Typography
              style={{ marginBottom: 8, color: '#3C3C3C' }}
              customVariant="bodySmallRegular"
            >
              Upload photo of passengers
            </Typography>
            <UploadImage
              placeholder="Add photo of passenger"
              defaultPreviewImage={values.passengers[index].profileImage}
              onComplete={(data) => {
                // setFieldValue('profileImage', data.url);
                handleFormChange({
                  persist: () => { },
                  target: {
                    type: 'change',
                    name: 'profileImage',
                    value: data.url,
                  },
                });
              }}
            />
          </Grid>
        </Grid>
        {deleteButton}
      </Box>
    );
  };

  const passengerForms = values.passengers.map((passenger, index) => {
    if (index === values.passengers.length - 1)
      return (
        <Box key={index} className={classes.passengerFormContainer}>
          {renderPassengerForm(index, true)}
        </Box>
      );

    return (
      <Box className={classes.passengerFormContainer}>
        <Typography
          style={{ marginBottom: 16 }}
          customVariant="bodyLargeRegular"
        >
          {`${index + 1}. Passenger details`}
        </Typography>
        <DynamicList
          isMobileView={isMobileView}
          data={[passenger]}
          title={undefined}
          noResultsText="No passengers"
          columns={[
            {
              field: "name",
              headerName: "Name",
              width: 4,
              titleWithText: true,
              render: (data) => (
                <Typography customVariant="bodySmallRegular">{`${data.firstName} ${data.lastName}`}</Typography>
              ),
            },
            {
              field: "email",
              headerName: "Email",
              titleWithText: true,
              render: data => (
                <EmailDisplay email={data.email}></EmailDisplay>),
              width: 4,
            },
            {
              field: "passengerPriority",
              headerName: "Priority",
              titleWithText: true,
              width: 4,
              render: (data) => (
                <Typography customVariant="bodySmallRegular">
                  {data?.passengerPriority?.name}
                </Typography>
              ),
            },
          ]}
          actions={{
            rowClick: () => {

            },
            delete: {
              onClick: () => {
                const newPassengers = values.passengers.filter(
                  (passenger, passengerIndex) => index !== passengerIndex
                );
                setFieldValue("passengers", newPassengers);
              },
            },
          }}
          detailPanel={() => renderPassengerForm(index)}
        />
      </Box>
    );
  });

  return (
    <>
      <FormSection defaultExpanded title="Enter passenger details">
        {passengerForms}
      </FormSection>
      {!isUpdate ? (
        <>
          <Button
            variant="contained"
            secondary
            startIcon={<Add />}
            onClick={handleAddPassenger}
          >
            Add another passenger
          </Button>

          <Box className={classes.addPassengerButtonSpacer} />
        </>
      ) : null}

      <TotalErrors
        errors={formattedErrors}
        touched={formattedTouched}
        withSpacer
      />
    </>
  );
};

export default AddUserForm;
