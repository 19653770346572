import {
  Sedan,
  Suv,
  Van,
  Minibus,
  Bus,
  Dispatch,
  Admin,
  Operations,
} from 'components_v2/Icons/Icons';

export const getVehicleClassIcon = (type) => {
  if (type === 'Sedan') return Sedan;
  if (type === 'SUV') return Suv;
  if (type === 'Mini-Van') return Van;
  if (type === 'Van') return Van;
  if (type === 'ADA Van (Handicap)') return Van;
  if (type === 'Luggage Van') return Van;
  if (type === 'Mini-Bus') return Minibus;
  if (type === 'Sprinter') return Minibus;
  if (type === 'Coaster Bus') return Bus;
  if (type === 'Motor Coach') return Bus;
  if (type === 'Bus') return Bus;
  return Sedan;
};

export const getStaffIcon = (type) => {
  if (type === 'Admin') return Admin;
  if (type === 'Dispatch') return Dispatch;
  if (type === 'Operations') return Operations;
  return Admin;
};

export const productIconSelectionOptions = [
  {
    value: 'Book',
  },
  {
    value: 'Diamond',
  },
  {
    value: 'Fastfood',
  },
  {
    value: 'Flower',
  },
  {
    value: 'Gift',
  },
  {
    value: 'Light',
  },
  {
    value: 'Simcard',
  },
  {
    value: 'Waterbottle',
  },
];

