import React from 'react';
// import Modal from '@material-ui/core/Modal';
import Modal from 'components_v2/Modal/Modal';
import SuccessCard from '../../Cards/SuccessCard/SuccessCard';
import { WarningWhite } from '../../Icons/Icons';
import {capitalize} from 'lodash';

const CancelConfirmationModal = ({
  data = {},
  onClickCancel,
  onClickConfirmAll,
  onClickConfirmOne
}) => {
  const getHeaderText = (data) => {
    if (data?.numberOfVehicles < 2)
      return `This will ${data?.confirmationType} ${data?.recordName} and its scheduled ride.`;
    return `There are ${data?.numberOfVehicles} rides associated with ${data?.recordName}. Would you like to ${data?.confirmationType} all rides or just this one?`;
  };

  const headerText = getHeaderText(data);

  const isMultipleRides = data?.numberOfVehicles > 1;

  return (
    <Modal
      size="sm"
      pageView={
        data?.confirmationType === 'cancel' ||
        data?.confirmationType === 're-enable'
      }
    >
      <SuccessCard
        icon={
          <WarningWhite
            style={{
              color: '#FFF',
              fill: 'white',
              width: 40,
              height: 40,
              filter: 'drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))',
            }}
          />
        }
        otherMainAction={{
          text: isMultipleRides ? `${capitalize(data.confirmationType)} All` : 'Confirm',
          onClick: () => onClickConfirmAll(!data.passengerScheduleCancelled),
        }}
        mainAction={
          isMultipleRides
            ? {
                text: 'Just This Ride',
                onClick: () => onClickConfirmOne(!data.driverScheduleCancelled),
              }
            : undefined
        }
        secondaryAction={{
          text: 'Cancel',
          onClick: onClickCancel,
        }}
        headerText={headerText}
        description={
          data?.confirmationType === 'cancel'
            ? 'Bookings can only be re-enabled by MOTUS | ONE Staff.'
            : ''
        }
      />
    </Modal>
  );
};

export default CancelConfirmationModal;
