export const mockData = {
  modules: [
    {
      id: '5f763aba0cf1e4d13032e436',
      name: 'Media 69',
      description: null,
      departments: [
        {
          id: '5f975edb69eeee18d55e498a',
          name: '24 Hours',
          __typename: 'Department',
        },
      ],
      documents: [
        { name: 'Doc1.pdf', __typename: 'Documents' },
        { name: 'oglas.pdf', __typename: 'Documents' },
        { name: 'oglas.pdf', __typename: 'Documents' },
        { name: '774694_large69028.jpg', __typename: 'Documents' },
      ],
      __typename: 'Module',
    },
    {
      id: '5fb13fa7f9139a00170229b0',
      name: 'Ivan',
      description: null,
      departments: [],
      documents: [],
      __typename: 'Module',
    },
  ],
};
