import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../App.helper';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    padding: '40px 40px 56px 72px',
    boxSizing: 'border-box',
    display: 'block',
    zIndex: 2,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      zIndex: "unset",
      padding: '0 16px',
      height: 90,
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      marginTop: '22px',
      '& .MuiIconButton-root': {
        background: 'transparent',
      },
      '& > .MuiTypography-root': {
        display: 'none',
      },
    },
  },
  mobileHeaderTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .MuiTypography-root': {
      display: 'none',
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        display: 'block',
      },
    },
  },
  headerSteps: {
    display: 'none',
    background: '#fff',
    alignItems: 'center',
    height: 48,
    width: '100%',
    padding: 16,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'flex',
    },
  },
  container: {
    background:
      'linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    flexShrink: 0,
    overflowY: 'scroll',
    zIndex: 1,

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      alignItems: 'unset',
      height: '100%',
      flexShrink: "unset",
      overflowY: 'unset',
      zIndex: "unset",
    },
  },
  headerButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
    width: '100%',
    '& > .MuiTypography-root': {
      display: 'none',
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: 0,
      '& > .MuiTypography-root': {
        display: 'block',
        textAlign: 'center',
      },
    },
  },
  backButtonContainer: {
    display: 'flex',
    '& button': {
      marginRight: 32,
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      '& button': {
        marginRight: 0,
      },
      '& .MuiTypography-root': {
        display: 'none',
      },
    },
  },
  backIcon: { height: 14, width: 'auto', position: 'relative', left: 3 },
  closeIcon: { height: 20, width: 'auto' },
}));

export default useStyles;
