import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  helperText: {
    fontSize: '14px',
    lineHeight: '134.3%',
    color: 'rgba(0, 0, 0, 0.5)',
    marginTop: '7px',
  },
});

const HelperText = ({ text }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="caption"
      display="block"
      gutterBottom
      className={classes.helperText}
    >
      {text}
    </Typography>
  );
};

export default HelperText;
