import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import InputLabel from "../InputLabel/InputLabel";
import { useStyles, useCustomStyles } from "../SelectInput/SelectInput.styles";
import { Pointer } from "../../Icons/Icons";
import InputError from "../InputError/InputError";

const autocompleteService = { current: null, geocode: null };

const LocationSearchInput = ({
  name,
  onChange,
  label,
  error,
  placeholder,
  isDisabled,
  defaultOptions,
  defaultValue
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const loaded = React.useRef(false);
  const variant = "outlined";

  // Update the internal value if the defaultValue prop changes
  React.useEffect(() => {
    setValue(defaultValue);
  }, [JSON.stringify(defaultValue)]);

  if (typeof window !== "undefined" && !loaded.current) {
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const handleChange = newValue => {
    if (newValue?.name || newValue === null) {
      onChange({
        persist: () => {},
        target: {
          type: "change",
          name,
          value: newValue
        }
      });
    } else if (newValue?.place_id) {
      autocompleteService.geocode.geocode(
        { placeId: newValue.place_id },
        (results, status) => {
          if (status === "OK") {
            onChange({
              persist: () => {},
              target: {
                type: "change",
                name,
                value: {
                  lat: results[0].geometry.location.lat(),
                  long: results[0].geometry.location.lng(),
                  name: newValue.structured_formatting.main_text,
                  address: newValue.structured_formatting.secondary_text
                }
              }
            });
          }
        }
      );
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      autocompleteService.geocode = new window.google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : defaultOptions);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <div className={customClasses.inputWrapper}>
      <InputLabel label={label} />
      <Autocomplete
        forcePopupIcon={false}
        open={isDropdownOpened}
        // openOnFocus={true}
        // onOpen={() => {

        // }}
        name={name}
        id="google-map-demo"
        getOptionLabel={option =>
          option.description || `${option.name}, ${option.address}`
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        fullWidth
        InputProps={{
          classes,
          disableUnderline: true
        }}
        variant={variant}
        disabled={isDisabled}
        onChange={(event, newValue) => {
          setIsDropdownOpened(false);
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          handleChange(newValue);
        }}
        // onInputChange={(event, newInputValue) => {
        //   setIsDropdownOpened(!!newInputValue);
        //   setInputValue(newInputValue);
        // }}
        renderInput={params => (
          <TextField
            {...params}
            variant={variant}
            classes={classes}
            disabled={isDisabled}
            placeholder={placeholder}
            onBlur={() => {
              setIsDropdownOpened(false);
            }}
            // onFocus={(event) => {

            //   if (event?.target?.value) {
            //     setIsDropdownOpened(!!inputValue);
            //     // setInputValue(event.target.value);
            //   } else {
            //     setIsDropdownOpened(false);
            //     // setInputValue(false);
            //   }
            // }}
            onChange={event => {
              if (event?.target?.value) {
                setIsDropdownOpened(!!event.target.value);
                setInputValue(event.target.value);
              } else {
                setIsDropdownOpened(false);
                setInputValue(false);
              }
            }}
          />
        )}
        renderOption={option => {
          if (inputValue === "" || option.name) {
            return (
              <Grid container alignItems="center">
                <Grid item style={{ paddingRight: "16px" }}>
                  <Pointer className={classes.icon} />
                </Grid>
                <Grid item xs>
                  <span style={{ fontWeight: 400 }}>{option.name}</span>

                  <Typography variant="body2" color="textSecondary">
                    {option.address}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches && matches.length
              ? matches.map(match => [
                  match.offset,
                  match.offset + match.length
                ])
              : []
          );

          return (
            <Grid container alignItems="center">
              <Grid item style={{ paddingRight: "16px" }}>
                <Pointer />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <InputError error={error} />
    </div>
  );
};

export default LocationSearchInput;
