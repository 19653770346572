import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import { useMutation } from "@apollo/react-hooks";
import DynamicList from "components_v2/List/DynamicList";
import Typography from "components_v2/Typography/Typography";
import AccessRightsPicker from "components_v2/Inputs/AccessRightsPicker/AccessRightsPicker";
import Loading from "components_v2/Loading/CircularProgress";
import CreateUserEvent from "gql/UserEvents/Create";
import UpdateUserEvent from "gql/UserEvents/Update";
import DeleteUserEvent from "gql/UserEvents/Delete";
import CheckboxInput from "components_v2/Inputs/CheckboxInput/CheckboxInput";
import SearchInput from "components_v2/Inputs/SearchInput/SearchInput";
import { filterData } from "./helpers";
import useStyles from "./UserProjectList.style";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../App.helper";

const UsersListComponent = ({ data, loading, refetchEvents, userId }) => {
  const [hideArchived, setHideArchived] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const classes = useStyles();

  const [
    createUserEvent,
    { loading: createMutationLoading },
  ] = useMutation(CreateUserEvent);

  const [
    updateUserEvent,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateUserEvent);

  const [
    deleteUserEvent,
    { loading: deleteMutationLoading },
  ] = useMutation(DeleteUserEvent);

  const filteredData = filterData(data, hideArchived, searchTerm);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {(createMutationLoading ||
        updateMutationLoading ||
        deleteMutationLoading ||
        loading) && <Loading fullPage />}
      <DynamicList
        isMobileView={isMobileView}
        showHeader
        data={filteredData}
        title="Projects"
        noResultsText="No projects"
        numRows={filteredData.length}
        filterActions={
          <Box className={classes.filterInputContainer}>
            <CheckboxInput
              name="hideArchived"
              label="Hide archived projects"
              value={hideArchived}
              onChange={() => {
                setHideArchived((prevState) => !prevState);
              }}
            />
            <Box className={classes.searchInputContainer}>
              <SearchInput
                name="search"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search projects"
              />
            </Box>
          </Box>
        }
        getMobileMenuItems={(row) => {
          return {
            left: [
              ...(row.userevents.length ? [
                {
                  text: " ",
                  onClick: async () => {
                    await deleteUserEvent({
                      variables: {
                        id: { id: row.userevents[0].id },
                      },
                    });
                    await refetchEvents();
                  },

                  className: "mobile-actions-delete",
                },
              ] : []),
            ],

            right: [
              ...(!row.userevents.length ? [
                {
                  text: "Add User",
                  onClick: async (row) => {
                    await createUserEvent({
                      variables: {
                        data: {
                          user: userId,
                          event: row.id,
                          role: "client",
                        },
                      },
                    });
                    await refetchEvents();
                  },
                  className: "mobile-actions-primary-actions",
                },
              ] : []),
            ],
          };
        }}
        actions={{
          primary: {
            onClick: async (row) => {
              await createUserEvent({
                variables: {
                  data: {
                    user: userId,
                    event: row.id,
                    role: "client",
                  },
                },
              });
              await refetchEvents();
            },
            hidden: (row) => !!row.userevents.length,
            label: () => "Add User",
          },
          delete: {
            onClick: async (row) => {
              await deleteUserEvent({
                variables: {
                  id: { id: row.userevents[0].id },
                },
              });
              await refetchEvents();
            },
            hidden: (row) => !row.userevents.length,
          },
        }}
        columns={[
          {
            field: "name",
            headerName: "Name",
            width: 3,
            render: (data) => (
              <Typography customVariant="bodySmallRegular">
                {data.name}
              </Typography>
            ),
          },
          {
            field: "role",
            headerName: "Role",
            width: 4,
            render: (data) => {
              const userevent =
                (data.userevents.length && data.userevents[0]) || {};

              const accessRight = userevent.accessRight || {
                name: "",
                id: "",
              };
              return data.userevents.length ? (
                <AccessRightsPicker
                  noMargin
                  placeholder="Select a role"
                  value={{ name: accessRight.name, id: accessRight.id }}
                  onChange={async (e) => {
                    if (e.target.value) {
                      await updateUserEvent({
                        variables: {
                          id: { id: userevent.id },
                          data: { accessRight: e.target.value.id },
                        },
                      });
                      await refetchEvents();
                    }
                  }}
                />
              ) : null;
            },
          },
        ]}
      />
    </>
  );
};

UsersListComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UsersListComponent;
