import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  staffNameContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 32,
      height: 'auto'
    },
  },
}));

export default useStyles;
