import React from "react";
import Grid from "@material-ui/core/Grid";
import TitleWithText from "components_v2/TitleWithText/TitleWithText";
import NameDisplay from "components_v2/NameDisplay/NameDisplay";
import Menu from "components_v2/Menu/Menu";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { Call, Messages } from "../Icons/Icons";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  sortMenuRecords: {
    marginTop: "10px",
    width: "auto",
    padding: 0,
    borderRadius: 0,

    "& .MuiList-root": {
      padding: 0
    }
  },
  sortMenuRecord: {
    width: "100px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    lineHeight: "134.3%",
    color: "#4001C5",

    "& a": {
      textDecoration: "none"
    },

    "& svg": {
      fill: "#4001C5"
    },

    "&:hover": {
      backgroundColor: "background: rgba(0, 0, 0, 0.05)",
      fontWeight: "bold"
    },

    "& .highlight": {
      color: "#C50202"
    },

    "& > span": {
      fontWeight: "bold"
    }
  }
}));

const DriverDetails = ({
  data,
  isMenuOpened,
  setIsMenuOpened,
  isDispatchPage
}) => {
  const classes = useStyles({ isDispatchPage });

  const handleSortedByClick = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const renderDriverMenuItems = data =>
    _.map(
      [
        {
          icon: Call,
          label: "Call",
          link:
            data.passenger &&
            data.passenger.phone &&
            `tel:${data.passenger.phone}`
        },
        {
          icon: Messages,
          label: "SMS",
          link:
            data.passenger &&
            data.passenger.phone &&
            `sms:${data.passenger.phone}`
        }
      ],
      (item, index) => {
        const Icon = item.icon;
        return (
          <MenuItem key={index} className={classes.sortMenuRecord}>
            <a
              href={item.link}
              className={classes.sortMenuRecord}
              onClick={
                // TODO: NEED TO REVISIT
                () => {
                  setIsMenuOpened(false);
                }
              }
            >
              <Icon />
              {item.label}
            </a>
          </MenuItem>
        );
      }
    );

  return (
    <Grid container xs={12} md={12} justify="space-between" direction="row">
      <Grid item xs={12} md={3}>
        <TitleWithText
          label="Driver"
          text={
            <>
              {!data?.driver && "Not Assigned"}
              {data?.driver && (
                <Menu
                  isMenuOpened={isMenuOpened}
                  onMenuClose={() => setIsMenuOpened(false)}
                  className={classes.sortMenuRecords}
                  render={handleClick => (
                    <NameDisplay
                      onClick={event => {
                        handleClick(event);
                        handleSortedByClick();
                      }}
                      fullName={
                        data?.driver?.firstName +
                          " " +
                          data?.driver?.lastName || ""
                      }
                    />
                  )}
                  items={() => renderDriverMenuItems(data)}
                />
              )}
            </>
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TitleWithText
          label="Vehicle"
          text={
            data?.vehicle && (
              <>
                {data?.vehicle?.vehicleNumber || ""} -{" "}
                {data?.vehicle?.make || ""} {data?.vehicle?.model || ""}
              </>
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TitleWithText
          label="Contact (driver)"
          text={data?.driver?.phone || ""}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        {data?.createdBy && (
          <TitleWithText
            label="Ride Booked By"
            text={
              data?.createdBy && (
                <NameDisplay
                  fullName={`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
                />
              )
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DriverDetails;
