import { useQuery } from '@apollo/react-hooks';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import GetAll from 'gql/Suppliers/GetAll';
import find from 'lodash/find';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { createLocationWithState } from 'utils/routerHelpers';
import Loading from "components_v2/Loading/CircularProgress"
import withFormState from "containers/Wrappers/withFormState";


const SupplierSelect = (props) => {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    noMargin,
    error,
    values,
    history,
    location,
    setFormState,
    returnField
  } = props;

  const { data, loading } = useQuery(GetAll, {
    fetchPolicy: 'no-cache',
  });

  const options =
    !loading && data ?
    data.suppliers.map((supplier) => ({
      name: supplier.name,
      id: supplier.id,
    })) : [
      {
        loading: true,
        name: "",
      }
    ]

  const matchedValue = find(options, { id: value }) || null;

  const optionsWithAddNewLink = (options && [
    ...options,
    { link: true, name: 'Add New' },
  ]) || [{ link: true, name: 'Add New' }];

  const handleChange = (e) => {
    if (e?.target?.value?.link) {
      const { pathname, state } = createLocationWithState(
        `/supplier/new`,
        location,
        values,
        null,
        true,
        returnField
      );
      
      setFormState(state);
      history.push({ pathname, state: { hasState: true } });
    } else onChange(e);
  };

  const renderOption = (option) => (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {option.link ? (
        <div
          style={{
            width: '100%',
            height: 1,
            background: '#111',
            position: 'relative',
            bottom: 6,
          }}
        />
      ) : null}
      <div
        style={{
          color: option.link ? '#4001C5' : undefined,
          fontWeight: option.link ? 700 : undefined,
          width: option.link ? '100%' : undefined,
        }}
        value={option.name}
      >
        {option.loading ? <Loading thickness={7.8} size={22} /> : null}
        {option.name}
      </div>
    </div>
  );

  return (
    <SelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      getOptionDisabled={(option) => option.loading}
      value={matchedValue}
      onChange={handleChange}
      options={optionsWithAddNewLink || []}
      noMargin={noMargin}
      renderOption={renderOption}
      isError={error}
      errorMessage={error}
    />
  );
};

export default withFormState(withRouter(SupplierSelect));
