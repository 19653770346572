import gql from 'graphql-tag';

export default gql`
  query GetAllModules($id: ID) {
    modules(where: { event: $id }) {
      id
      name
      description
      departments {
        id
        name
      }
      documents {
        name
      }
      documentsCount
    }
  }
`;
