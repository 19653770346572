import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ArrowsChevronLeft, Clear } from '../Icons/Icons';
import CIB from '../Buttons/CIB/CIB';
import { mobileViewBreakpoint } from '../../App.helper';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '79px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '15px',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '48px 16px 16px',
      height: 'auto',
    },
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#000000',
  },
}));

const AccountHeader = ({ title, onBackClick, onCloseClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <CIB onClick={onBackClick} icon={ArrowsChevronLeft} />
      <span className={classes.headerTitle}>{title}</span>
      <CIB onClick={onCloseClick} icon={Clear} />
    </div>
  );
};

export default AccountHeader;
