import { useQuery } from '@apollo/react-hooks';
import InputError from 'components_v2/Inputs/InputError/InputError';
import Modal from 'components_v2/Modal/Modal';
import GetAll from 'gql/Vehicles/GetAll';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { filterByValue } from './helpers';
import ListModalContent from './ListModalContent';
import ListModalResults from './ListModalResults';

export default function ListModal(props) {
  const {
    onModalClick,
    data,
    query = GetAll, // Added because useQuery has to receive a query, the hook can not be used conditionally
    queryOptions = { skip: true }, // Skips query if not provided, see above
    dataSelector,
    renderSelectionColumns,
    incomingItems = [],
    customHandleDelete,
    handleUpdateForm,
    disableQuery, // Added because query needs a default value, this is part of the work around for hooks not being called conditionally
    disableActions,
    customResults,
    customListActions,
    error,
    noResults = false,
    loadDataOnRender = true,
    isHelpTutorial = false,
    helpTutorialMockData,
    pageView,
    onOpenCallback,
    onCloseCallback,
    onCancelCallback,
    readOnlyResult,
    customSelectionValue,
    ignoredSearchFields
  } = props;

  const [listData, setListData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState(
    incomingItems && incomingItems.length ? [...incomingItems] : []
  );

  const handleDelete =
    customHandleDelete && customHandleDelete(setSelectedItems);

  const { data: queryData, loading } = useQuery(
    query,
    {
      ...queryOptions,
      skip:
        disableQuery ||
        isHelpTutorial ||
        !loadDataOnRender ||
        !!queryOptions?.skip,
    },
    {
      fetchPolicy: 'no-cache',
    }
  );

  let filteredData = [];
  if (data && disableQuery) filteredData = data; // if data was passed in through data object
  if (query && !loading && !disableQuery && loadDataOnRender)
    filteredData = dataSelector ? dataSelector(queryData) : queryData; // if a query is provided

  if (searchValue && searchValue.length > 0) {
    filteredData = filterByValue(filteredData, searchValue, ignoredSearchFields);
  }

  useEffect(() => {
    if (isHelpTutorial) setListData(helpTutorialMockData);
    else if (loadDataOnRender) setListData(filteredData);
  }, [JSON.stringify(filteredData)]);

  const defaultFilterData = (x) => selectedItems.includes(x.id);
  const customFilterData = (x) => customSelectionValue(selectedItems, x);

  // Used JSON.stringify in dep array because incomingItems is an array
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  
  useEffect(() => {
    setSelectedItems(incomingItems && incomingItems.length ? [...incomingItems] : [])
  }, [JSON.stringify(incomingItems)]);

  const onCloseCallbackComplete = () => {
    setSelectedItems(incomingItems && incomingItems.length ? [...incomingItems] : []);
    if (onCloseCallback) onCloseCallback();
  };

  return (
    <Modal
      size="medium"
      pageView={pageView}
      onOpenCallback={onOpenCallback}
      onCloseCallback={onCloseCallbackComplete}
      onCancelCallback={onCancelCallback}
      onModalClick={(toggleModal) => (
        <>
          {renderSelectionColumns && !customResults && !noResults && (
            <ListModalResults
              log={props.log ? true : false}
              columns={renderSelectionColumns}
              data={
                listData.filter(customSelectionValue ? customFilterData : defaultFilterData)
              }
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              customHandleDelete={handleDelete}
              handleUpdateForm={handleUpdateForm}
              detailPanel={props.detailPanelComponent}
              customListActions={customListActions}
              loading={loading}
              readOnlyResult={readOnlyResult}
            />
          )}
          {!renderSelectionColumns && customResults}
          {!readOnlyResult && onModalClick && onModalClick(toggleModal)}
          {<InputError error={error} />}
        </>
      )}
      disableOutline
    >
      <ListModalContent
        {...props}
        loading={loading}
        filteredData={listData}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        disableActions={disableActions}
        setListData={setListData}
      />
    </Modal>
  );
}

ListModal.propTypes = {
  onModalClick: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  secondaryAction: PropTypes.object,
  mainAction: PropTypes.object,
};
