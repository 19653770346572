import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import auth from "../../utils/auth";
import { withRouter } from "react-router-dom";

const queryGQL = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id: email
      isSuperAdmin
      role {
        id
      }
      userEvents {
        id
        event {
          id
          name
        }
        role
        accessRight {
          id
          name
          permissions
        }
      }
    }
  }
`;

function isIterable(obj) {
  // checks for null and undefined
  if (obj === null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

const RolesWrapper = withRouter(props => {
  const id = auth.get("userInfo") ? auth.get("userInfo").id : null;
  let permissions = defaultPermissions;

  const { data } = useQuery(queryGQL, {
    variables: { id },
    skip: !id
  });

  if (!id) {
    var permission = permissions.find(p => p.page.value === props.pageName);

    return (
      <props.WrappedComponent
        permissions={permission.permissions}
        allPermissions={permissions}
        {...props}
      />
    );
  }

  const eventId = props.match.params.projectId;

  let accessRight = null;

  if (data?.user?.userEvents && eventId) {
    const _userEvent = data.user.userEvents.find(
      x => x.event && x.event.id === eventId
    );

    if (_userEvent) {
      accessRight = _userEvent.accessRight;
    }
  }

  if (accessRight) {
    permissions = accessRight.permissions
      ? accessRight.permissions
      : [...defaultPermissions];
  }

  if (
    accessRight &&
    accessRight.permissions &&
    isIterable(accessRight.permissions) &&
    !permissions
  ) {
    // set permissions from database
    permissions = defaultPermissions.map(permission => {
      const currentPage = permission.page.value;
      const savedPermission = accessRight.permissions.find(
        p => p.page.value === currentPage
      );

      if (savedPermission) {
        return savedPermission;
      }

      return permission;
    });
  }

  if (data && data.user && data.user.isSuperAdmin) {
    permissions = defaultPermissions.map(permission => ({
      ...permission,
      permissions: {
        canViewOwn: true,
        canView: true,
        canEdit: true,
        canCreate: true,
        canDelete: true
      }
    }));
  }

  /*      {...permission, permissions: {      canViewOwn: true,
        canView: true,
        canEdit: true,
        canCreate: true,
        canDelete: true}
      } */

  var permissionObj = permissions.find(p => p.page.value === props.pageName);
  return (
    <props.WrappedComponent
      permissions={permissionObj.permissions}
      allPermissions={permissions}
      userInfo={(data && data.user) || {}}
      {...props}
    />
  );
});

var defaultPermissions = [
  {
    page: { value: "dashboard" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "vehicles" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "drivers" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "locations" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "passengers" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "eventTimeline" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "bookings" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "routePricing" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },

  {
    page: { value: "supplierPricing" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },

  {
    page: { value: "attendanceMapping" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "passengerScheduleBuilder" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "driverVehicleMapping" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "driverScheduleBuilder" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "shuttleSchedule" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "eventSchedule" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "dispatching" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "alerts" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "administration" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "currentEvent" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  },
  {
    page: { value: "reports" },
    permissions: {
      canViewOwn: null,
      canView: null,
      canEdit: null,
      canCreate: null,
      canDelete: null
    }
  }
];

export default function withUserRole(pageName, WrappedComponent) {
  return props => (
    <RolesWrapper
      WrappedComponent={WrappedComponent}
      {...props}
      pageName={pageName}
    />
  );
}
