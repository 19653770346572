import _ from "lodash";
import moment from "moment";
import currencyOptions from "utils/currencies";
import * as Yup from "yup";
import { stringifyJSON } from "utils/json";
import { productIconSelectionOptions } from "utils/iconHelpers";

import { formatChangedFormValues, formatValues } from "utils/formHelpers";

export const getValidationSchema = (step, isUpdate) => {
  const stepOneValidations = {
    name: Yup.string()
      .required("Required")
      .min(2, "Too short!"),
    start: Yup.string()
      .nullable()
      .required("Required"),
    end: Yup.string()
      .nullable()
      .required("Required")
      .test(
        "test-end date comes after start date",
        "End must come after start date.",
        function(value) {
          const start = this.resolve(Yup.ref("start"));
          if (!start) return true;
          return moment(start).isBefore(value);
        }
      ),
    country: Yup.object()
      .nullable()
      .required("Required"),
    timezone: Yup.string()
      .nullable()
      .required("Required"),
    currency: Yup.string().required("Required"),
    client: Yup.array().min(1, "Required"),
    modules: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .min(2, "Too short!")
          .required("Required")
          .nullable()
      })
    ),
    status: isUpdate
      ? Yup.object()
          .nullable()
          .required("Required")
      : Yup.object().nullable()
  };
  const otherStepsValidations = {
    ...stepOneValidations,
    fleet: Yup.array().min(1, "Required"),
    fleetSuppliers: Yup.array().min(1, "Required")
  };

  if (step === 1) return Yup.object().shape(stepOneValidations);
  if (step === 2 || step === 3)
    return Yup.object().shape(otherStepsValidations);
  return {};
};

export const getInitialValues = step => {
  if (step === 1)
    return {
      name: "",
      type: "event",
      country: { name: "United Arab Emirates" },
      timezone: "Asia/Dubai",
      start: null,
      end: null,
      modules: [],
      client: [],
      locationInput: [],
      currency: _.find(currencyOptions, { code: "aed" })
    };
  if (step === 2)
    return {
      fleet: [],
      requiredStaff: []
    };
  return {};
};

export const projectTypeOptions = [
  { label: "Event", value: "event" },
  { label: "Corporate", value: "corporate" }
];

export const formatSubmissionValues = (
  values,
  initialValues,
  isUpdate,
  step
) => {
  const step1FormatConfig = {
    name: null,
    logoImageUrl: null,
    type: null,
    currency: "currency.code",
    country: "country.name",
    timezone: null,
    locationInput: vals => stringifyJSON(vals.locationInput, "[]"),
    start: vals => moment(vals.start).format("YYYY-MM-DD"),
    end: vals => moment(vals.end).format("YYYY-MM-DD"),
    client: { name: "projectClient", format: vals => vals.client[0] },
    modules: { name: "hasModules", format: vals => !!vals.modules?.length },
    viewport: vals => stringifyJSON(vals.viewport, "[]")
  };

  if (step === 1 && !isUpdate) return formatValues(values, step1FormatConfig);
  if (step === 1 && isUpdate) {
    const step1UpdateFormatConfig = {
      ...step1FormatConfig,
      status: vals => vals.status.name,
      transferservices: {
        name: "transferservices",
        format: vals => vals.transferservices.map(service => service.id)
      }
    };
    return formatChangedFormValues(
      values,
      initialValues,
      step1UpdateFormatConfig
    );
  }

  const step2FormatConfig = {
    fleet: vals => JSON.stringify(vals.fleet, "[]"),
    fleetSuppliers: vals => JSON.stringify(vals.fleetSuppliers, "[]"),
    requiredStaff: vals => JSON.stringify(vals.requiredStaff, "[]"),
    products: vals => vals.products.map(product => product.id)
  };

  if (step === 2 && !isUpdate) return formatValues(values, step2FormatConfig);
  if (step === 2 && isUpdate)
    return formatChangedFormValues(values, initialValues, step2FormatConfig);

  const step3FormatConfig = {
    ...step2FormatConfig,
    clientPriceFormat: null,
    clientPriceMargin: null
  };

  if (step === 3 && !isUpdate) return formatValues(values, step3FormatConfig);
  if (step === 3 && isUpdate)
    return formatChangedFormValues(values, initialValues, step3FormatConfig);
};

export const formatIncomingData = (data, eventProducts) => ({
  name: data.name,
  type: data.type,
  start: moment(data.start).format("YYYY-MM-DD"),
  end: moment(data.end).format("YYYY-MM-DD"),
  logoImageUrl: data.logoImageUrl,
  currency: _.find(currencyOptions, { code: _.lowerCase(data.currency) }),
  country: { name: data.country },
  timezone: data.timezone,
  modules: data.modules.map(mod => ({
    ...mod,
    teamMembers: mod.usereventmodules.map(
      usereventmodule => usereventmodule.user.id
    )
  })),
  client: data.projectClient ? [data.projectClient.id] : [],
  locationInput: data.locationInput || [],
  fleet: data.fleet || [],
  requiredStaff: data.requiredStaff || [],
  fleetSuppliers: data.fleetSuppliers || [],
  products: data.products,
  productOptions: eventProducts,
  clientPriceFormat: data.clientPriceFormat || "Overall",
  clientPriceMargin: data.clientPriceMargin || 10,
  viewport: data.viewport || [],
  status: { name: data.status },
  transferservices: data.transferservices || []
});

export const handleSupplierSelectorDone = (values, setFieldValue) => items => {
  const valuesWithVehicles = items.map(supplier => ({
    ...supplier,
    selectedVehicles: []
  }));

  const suppliersRemoved = values.fleetSuppliers.filter(supplier => {
    const supplierMatch = _.find(valuesWithVehicles, { id: supplier.id });

    return supplierMatch;
  });

  const suppliersAdded = [...suppliersRemoved];

  valuesWithVehicles.forEach(supplier => {
    const supplierMatch = _.find(suppliersRemoved, { id: supplier.id });
    if (!supplierMatch) suppliersAdded.push(supplier);
  });

  setFieldValue("fleetSuppliers", suppliersAdded);
};

export const productSelectionColumns = [
  {
    field: "icon",
    headerName: "Icon",
    width: 3,
    type: "icon",
    options: productIconSelectionOptions,
    label: "Choose Icon"
  },
  {
    field: "name",
    headerName: "Product name",
    width: 3
  }
];

export const productSelectedColumns = [
  {
    field: "icon",
    headerName: "Icon",
    width: 1,
    type: "icon",
    options: productIconSelectionOptions,
    label: "Choose Icon"
  },
  {
    field: "name",
    headerName: "Product name",
    width: 3
  }
];

export const mergeReturnValues = previousFormState => {
  const {
    values,
    returnField,
    returnValues,
    returnFieldMetadata
  } = previousFormState;
  const newValues = { ...values };

  if (!returnField || !returnValues) return newValues;

  const returnValue = returnValues[returnField];

  if (returnField === "locationInput" || returnField === "fleetSuppliers")
    newValues[returnField] = [
      ...newValues[returnField],
      returnValue?.id || returnValue
    ];
  else if (returnField === "client") newValues[returnField] = [returnValue];
  else if (returnField === "modules") {
    const teamMembers =
      newValues[returnField][returnFieldMetadata.index].teamMembers;
    newValues[returnField][returnFieldMetadata.index].teamMembers = [
      ...teamMembers,
      returnValue
    ];
  }

  return newValues;
};
