import React from "react";
import { Grid } from "@material-ui/core";

import MarkerMap from "components_v2/Map/MarkerMap";
import Typography from "components_v2/Typography/Typography";
import DynamicList from "components_v2/List/DynamicList";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../../App.helper";

export const columns = [
  {
    field: "name",
    headerName: "Event name",
    width: 6,
    titleWithText: true,
  },
  {
    field: "type",
    headerName: "Type",
    width: 6,
    titleWithText: true,
  },
];

const ClientDetails = ({ client, isEventsNotVisible }) => {
  const {
    contactName,
    phone,
    email,
    lat,
    long,
    events,
    address,
    officeType,
    location
  } = client;

  let markers = [];

  if (lat && long) markers = [{ lat, long, name: location, address }];
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));


  return (
    <Grid style={{ padding: "24px 16px", paddingBottom: isEventsNotVisible && 0}} container>
      <Grid xs={12} sm={12}>
        <Typography customVariant="bodySmallBold" style={{ marginBottom: 8 }}>
          Contact name:
        </Typography>
        <Typography customVariant="bodySmallRegular">{contactName}</Typography>
      </Grid>
      <Grid style={{ marginTop: 24 }}xs={12}  sm={12}>
        <Typography customVariant="bodySmallBold" style={{ marginBottom: 8 }}>
          Phone:
        </Typography>
        <Typography customVariant="bodySmallRegular">{phone}</Typography>
      </Grid>
      <Grid style={{ marginTop: 24 }}xs={12}  sm={12}>
        <Typography customVariant="bodySmallBold" style={{ marginBottom: 8 }}>
          Email:
        </Typography>
        <Typography customVariant="bodySmallRegular">{email}</Typography>
      </Grid>
      <Grid style={{ marginTop: 24 }}xs={12}  sm={12} md={4}>
        <Typography customVariant="bodySmallBold" style={{ marginBottom: 8 }}>
          Address:
        </Typography>
        <Typography customVariant="bodySmallRegular">{address}</Typography>
      </Grid>
      <Grid style={{ marginTop: 24 }}xs={12}  sm={12} md={4}>
        <Typography customVariant="bodySmallBold" style={{ marginBottom: 8 }}>
          Office type:
        </Typography>
        <Typography customVariant="bodySmallRegular">{officeType}</Typography>
      </Grid>
      <Grid xs={4} />
      <Grid style={{ marginTop: 24 }} xs={12}  sm={12}>
        <div style={{ height: 300 }}>
          <MarkerMap markers={markers} />
        </div>
      </Grid>
      {!isEventsNotVisible && (
        <Grid style={{ marginTop: 40 }} sm={12}>
          <Typography customVariant="bodySmallBold" style={{ marginBottom: 8 }}>
            Events:
          </Typography>
          <DynamicList data={events} loading={false} columns={columns} isMobileView={isMobileView} />
        </Grid>
      )}
    </Grid>
  );
};

export default ClientDetails;
