import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../../App.helper";

export const useCustomStyles = makeStyles((theme) => ({
  paper: {
    padding: 0,

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: "100%",
    },

    "& ul": {
      padding: "0 !important",
    },
    "& li": {
      fontSize: "16px",
      lineHeight: "134.3%",
    },
  },
  list: {
    height: "200px",
    width: "260px !important",
  },
  dataSelectorWrapper: {
    display: "inline-block",
    marginBottom: "16px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: "0px",
    },
    "& h2": {
      fontSize: "40px",
      "& > span > span": {
        fontWeight: "bold",
      },
    },
  },
  menuItemlabel: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  previewLabel: {
    fontSize: "40px",
    textTransform: "none",
    fontWeight: "bold",
    lineHeight: "34px",
    letterSpacing: "-0.2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: "24px",
    },

    "& > span > span": {
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "48px",
      letterSpacing: "-0.4px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        fontSize: "24px",
      },
    },

    "& div": { padding: "0 15px", transition: "all 0.3s" },
    "& svg": { fill: "#ccc", transition: "all 0.3s", marginTop: 8 },
  },
  menuItems: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.3px",
    paddingTop: 16,
    paddingBottom: 16,
    color: "#4001C5",
    background: "rgba(0, 0, 0, 0.02)",
  },
  expanded: {
    "& svg": {
      fill: "rgba(64, 1, 197, 1)",
      transform: "rotate(-180deg)",
    },
  },
}));
