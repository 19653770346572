import * as Yup from "yup";

import {
  checkIfEmailExists,
  checkIfEmailValid,
  checkIfUpdatedEmailExists
} from "utils/validationHelpers";
import {
  getUploadDocuments,
  formatChangedFormValues,
  formatValues,
  addPasswordChangeValues
} from "utils/formHelpers";
import { stringifyJSON } from "utils/json";
import moment from "moment";
import { deepCopy } from "../../../../utils/helpers";
import asyncDebouncer from "utils/asyncDebouncer";

const getCustomPasswordValidation = isUpdate => {
  if (!isUpdate)
    return Yup.string().when("generatePassword", {
      is: false,
      then: Yup.string()
        .min(6, "Must be at least 6 characters")
        .required("Required"),
      otherwise: Yup.string()
    });
  return Yup.string().when(["generatePassword", "updatePassword"], {
    is: (generatePassword, updatePassword) =>
      !generatePassword && updatePassword,
    then: Yup.string()
      .min(6, "Must be at least 6 characters")
      .required("Required"),
    otherwise: Yup.string()
  });
};

const getSendPasswordEmailValidation = isUpdate => {
  if (!isUpdate)
    return Yup.string().when("willSendPassword", {
      is: true,
      then: Yup.string()
        .email("Email is invalid")
        .required("Required"),
      otherwise: Yup.string()
    });
  return Yup.string().when(["willSendPassword", "updatePassword"], {
    is: (willSendPassword, updatePassword) =>
      willSendPassword && updatePassword,
    then: Yup.string()
      .email("Email is invalid")
      .required("Required"),
    otherwise: Yup.string()
  });
};

export const validationSchema = isUpdate =>
  Yup.object().shape({
    supplier: Yup.string()
      .nullable()
      .required("Required"),
    firstName: Yup.string()
      .min(2, "Too short!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too short!")
      .required("Required"),
    email: isUpdate
      ? Yup.string()
          .required("Required")
          .test("Check Valid Email", "Email is invalid", checkIfEmailValid)
          .when("uneditedEmail", (uneditedEmail, schema) =>
            schema.test(
              "Email Already Exists",
              "This email is already in use",
              asyncDebouncer(
                value => checkIfUpdatedEmailExists(value, uneditedEmail),
                400
              )
            )
          )
      : Yup.string()
          .required("Required")
          .test("Check Valid Email", "Email is invalid", checkIfEmailValid)
          .test(
            "Email Already Exists",
            "This email is already in use",
            asyncDebouncer(value => checkIfEmailExists(value), 400)
          ),
    customPassword: getCustomPasswordValidation(isUpdate),
    sendPasswordEmail: getSendPasswordEmailValidation(isUpdate)
  });

export const formatSubmissionValues = (values, initialValues, isUpdate) => {
  const uploadedDocuments = getUploadDocuments(values);

  const formatConfig = {
    firstName: null,
    lastName: null,
    phone: null,
    whatsapp: null,
    identifier: null,
    profileImage: null,
    nationality: null,
    gender: null,
    licenseNumber: null,
    passportNumber: null,
    visaNumber: null,
    supplier: null,
    confirmed: null,
    autoCreateAndLinkVehicle: null,
    driverType: null,
    isPublicDriver: null,
    notes: null,
    shift: vals => stringifyJSON(vals.shift),
    breaks: vals => stringifyJSON(vals.breaks),
    driverVehicles: vals => {
      return vals.driverVehicles.map(vehicle => vehicle.id) || "[]";
    },
    email: vals => vals.email || "noemail@noemail.com",
    documents: vals => {
      const documents = [...(vals.documents || []), ...uploadedDocuments];
      return stringifyJSON(documents, "[]");
    },
    languages: vals => {
      const languages = vals.languages || [];
      return stringifyJSON(languages);
    }
  };

  // Update with no password change
  if (isUpdate && !values.updatePassword)
    return formatChangedFormValues(values, initialValues, formatConfig);

  // If update and password changed
  if (isUpdate && values.updatePassword) {
    const changed = formatChangedFormValues(
      values,
      initialValues,
      formatConfig
    );
    return addPasswordChangeValues(changed, values);
  }

  // Create driver format config additions
  formatConfig.employmentType = null;
  formatConfig.role = null;
  if (values.event) formatConfig.event = vals => vals.projectId;

  const formatted = formatValues(values, formatConfig);
  formatted.username = Date.now().toString();

  console.log(formatted);
  console.log(values);

  return addPasswordChangeValues(formatted, values);
};

export const formatIncomingData = data => {
  let documents;
  let languages;
  const defaultShift = {
    allowedHoursPerDay: null,
    shiftStart: null,
    shiftEnd: null,
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false
  };

  const defaultBreaks = [
    {
      breakType: null,
      startBreak: null,
      endBreak: null
    }
  ];

  const breaks = (data.breaks || defaultBreaks).map(driverBreak => {
    const localDriverBreak = deepCopy(driverBreak);
    if (localDriverBreak.startBreak)
      localDriverBreak.startBreak = moment.utc(localDriverBreak.startBreak);
    if (localDriverBreak.endBreak)
      localDriverBreak.endBreak = moment.utc(localDriverBreak.endBreak);

    return localDriverBreak;
  });
  const shift = data.shift || defaultShift;
  shift.shiftStart = shift.shiftStart && moment.utc(shift.shiftStart);
  shift.shiftEnd = shift.shiftEnd && moment.utc(shift.shiftEnd);

  try {
    documents = JSON.parse(data.documents) || [];
    languages = JSON.parse(data.languages) || [];
  } catch (e) {
    // Empty block
  }

  return {
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    whatsapp: data.whatsapp,
    email: data.email,
    uneditedEmail: data.email,
    profileImage: data.profileImage,
    licenseNumber: data.licenseNumber,
    nationality: data.nationality,
    gender: data.gender,
    passportNumber: data.passportNumber,
    visaNumber: data.visaNumber,
    identifier: data.identifier,
    notes: data.notes,
    documents,
    languages,
    breaks,
    shift,
    supplier: data?.supplier?.id,
    confirmed: data.confirmed,
    driverVehicles: data.assignedVehicles?.length
      ? data.assignedVehicles.filter(x => x.vehicle).map(x => x.vehicle)
      : [],
    isPublicDriver: data.isPublicDriver
  };
};

export const mergeReturnValues = previousFormState => {
  const { values, returnField, returnValues } = previousFormState;
  const newValues = { ...values };

  if (!returnField || !returnValues) return newValues;

  const returnValue = returnValues[returnField];

  if (returnField === "supplier") newValues[returnField] = returnValue?.id;
  else if (returnField === "driverVehicles") {
    newValues[returnField] = [...newValues[returnField], returnValue];
  }

  return newValues;
};
