import gql from "graphql-tag";

export default gql`
  query GetAllPassengerSchedules(
    $event: ID
    $module: ID
    $passengerSchedules: [ID]
    $bookingId: String
    $userEvents: [ID]
    $pageSize: Int
    $start: String
    $end: String
    $startIndex: Int
    $sort: String
    $filterKey: String
    $passengers: [ID]
  ) {
    passengerschedulesConnection(
      where: {
        event: $event
        event_in: $userEvents
        start_null: false
        start_gte: $start
        start_lte: $end
        bookingId_contains: $bookingId
        department: { module: $module }
        passenger_in: $passengers
        id_in: $passengerSchedules
        _or: [{ bookingId_contains: $filterKey }]
      }
      sort: $sort
      start: $startIndex
      limit: $pageSize
    ) {
      aggregate {
        count
      }
      values {
        id
        start
        stop
        stops
        vehicleClass
        origin
        created_at
        type
        flightNumber
        terminal
        notes
        numberOfVehicles
        numPassengers
        bookingId
        productNotes
        cancelled
        documents
        supplier {
          id
        }
        alerts {
          id
          type
          description
          status
        }
        savedStops {
          name
          type
          address
          lat
          long
          id
        }
        savedDocuments {
          id
          size
          url
          name
          uploadedBy {
            id
            firstName
            lastName
          }
        }
        event {
          id
          name
          timezone
        }
        createdBy {
          id
          firstName
          lastName
          fullName
          email
          phone
        }
        requiredVehicleClass {
          id
          name
          transferPricing
          trackTransferPricing
          fourHourPricing
          sixHourPricing
          twelveHourPricing
          twentyFourHourPricing
          maxPassengers
        }
        department {
          id
          name
          module {
            id
            name
          }
        }
        transferservice {
          id
          name
        }
        passenger {
          id
          firstName
          lastName
          fullName
          email
          phone
        }
        additionalPassengers {
          id
          firstName
          lastName
          fullName
          email
          phone
        }
        from {
          id
          name
          address
          lat
          long
        }
        to {
          id
          name
          address
          lat
          long
        }
        driverschedules {
          id
          cancelled
          ridestatuses {
            id
            status
            location
            source
            numPassengers
          }
          vehicle {
            id
            vehicleNumber
            make
            model
            year
            plateNumber
            color
          }
          driver {
            id
            firstName
            lastName
            fullName
            identifier
            phone
          }
          ridestatuses {
            id
            status
            created_at
            location
          }
          overtimeHours
        }
      }
    }
  }
`;
