import React from 'react';
// import GetOne from "gql/Vehicles/GetOne.js";
import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import { withRouter } from 'react-router';
import { Box } from '@material-ui/core';
import InputLabel from 'components_v2/Inputs/InputLabel/InputLabel.jsx';
// import { useQuery } from "@apollo/react-hooks";
import Typography from 'components_v2/Typography/Typography';
import Loading from 'components_v2/Loading/CircularProgress';
import { useStyles } from './ViewVehicle.styles';
import Img from '../../../Img/Img';

const ViewVehicleComponent = ({ data, loading }) => {
  const classes = useStyles();

  return (
    <Box position="relative">
      {loading && (
        <Loading
          fullPage
          backgroundWidth="100%"
          backgroundHeight="100%"
          backgroundPosition="absolute"
          zIndex={1199}
        />
      )}

      <FormSection isNoAccordion>
        <Box className={classes.vehicleDetailsContainer}>
          <Box className={classes.plateNumber}>
            <InputLabel className={classes.plateNumber} label="Plate number" />
            <Typography customVariant="bodySmallRegular">
              {data.plateNumber || "---"}
            </Typography>
          </Box>
          <Box className={classes.makeRow}>
            <InputLabel label="Make" />
            <Typography customVariant="bodySmallRegular">
              {data.make || "---"}
            </Typography>
          </Box>
          <Box className={classes.Model}>
            <InputLabel label="Model" />
            <Typography customVariant="bodySmallRegular">
              {data.model || "---"}
            </Typography>
          </Box>
          <Box className={classes.Class}>
            <InputLabel label="Class" />
            <Typography customVariant="bodySmallRegular">
              {data.class || "---"}
            </Typography>
          </Box>

          <Box className={classes.maxPassengers}>
            <InputLabel label="Passenger Capacity" />
            <Typography customVariant="bodySmallRegular">
              {data.maxPassengers || "---"}
            </Typography>
          </Box>
          <Box className={classes.maxLuggage}>
            <InputLabel label="Luggage Capacity" />
            <Typography customVariant="bodySmallRegular">
              {data.maxLuggage || "---"}
            </Typography>
          </Box>
          <Box className={classes.Color}>
            <InputLabel label="Color" />
            <Typography customVariant="bodySmallRegular">
              {data.color || "---"}
            </Typography>
          </Box>
          <Box className={classes.addPhotoRow}>
            <Img  style={{ maxWidth: "50%", maxHeight: "100%", objectFit: "cover" }}  src={data.imageUrl} alt={data.model}/>
           
          </Box>
        </Box>
      </FormSection>
    </Box>
  );
};

export default withRouter(ViewVehicleComponent);
