import _ from "lodash";
import React from "react";

import FAB from "components_v2/Buttons/FAB/FAB";
import {
  FloatingActionNewBooking,
  FloatingActionNewPassenger
} from "components_v2/Icons/Icons";

export const getRideCounts = passengerSchedules => {
  const rideCounts = {
    completedRidesCount: 0,
    onGoingRidesCount: 0
  };
  const driverschedules = _.flattenDeep(
    _.map(passengerSchedules, _.property("driverschedules"))
  );

  if (driverschedules) {
    const completedDriversSchedules = _.flattenDeep(
      _.filter(driverschedules, schedule =>
        _.find(schedule.ridestatuses, { status: "complete" })
      )
    );

    rideCounts.completedRidesCount =
      (completedDriversSchedules?.length) || 0;
    rideCounts.onGoingRidesCount =
      driverschedules.length - rideCounts.completedRidesCount;
  }

  return rideCounts;
};

export const getFAB = (projectId, selectedTab, history) => {
  const tab = selectedTab?.toLowerCase();
  if (tab === "passengers")
    return (
      <FAB
        label="Add Passenger"
        icon={FloatingActionNewPassenger}
        onClick={() => {
          history.push(`/project/${projectId}/passenger/new`);
        }}
      />
    );
  return (
    <FAB
      label="Add Booking"
      icon={FloatingActionNewBooking}
      onClick={() => {
        history.push(`/project/${projectId}/booking/new`);
      }}
    />
  );
};

export const getGridStyles=({isMobileView})=>{
  return  isMobileView
  ? {
    flexDirection: "column-reverse",
    marginTop: "32px",
    alignItems: "flex-start",
    marginBottom: "12px"
  }
  : { marginTop: "30px", marginBottom: "8px" }
}

export const getCustomVariantFontSize=({isMobileView})=>{

return (isMobileView && "h4Bold") || "h2Bold"} 

export const getIsLoading=({alertsGroupedDataLoading ,passengerSchedulesDataLoading})=>{
  return  alertsGroupedDataLoading || passengerSchedulesDataLoading
}
export const getIsLoadingTabs=({passengerSchedulesDataLoading})=>{
  return  passengerSchedulesDataLoading
}
export const getIsScheduleTab=({selectedTab})=>{
  return selectedTab === "schedule"
}
export const getViewType=({schedulerView ,datePickerView})=>{
  return schedulerView || datePickerView || "day"
}

export const getSavedView=({location})=>{
  return location?.state?.values?.view || undefined
  
}
export const getEventTimeZone=({eventData})=>{
return   eventData?.events && eventData.events[0]?.timezone
}
export const getProjectDetailsData=({eventData})=>{
  return  eventData?.events &&eventData?.events[0]
}
export const getIsNewAlerts=({alerts ,match})=>{
  return alerts[0]?.result?.event?.id === match.params.projectId
}
export const getLocalAlertsGroupedData=({localAlertsGroupedData})=>{
  return  localAlertsGroupedData ?
  localAlertsGroupedData?.alertsConnection?.aggregate?.count:
0
}
export const getSelectedDateValue=({selectedDate})=>{
  return selectedDate || undefined
}

