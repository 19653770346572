import gql from 'graphql-tag';

export default gql`
  mutation CreateClient($data: ClientInput) {
    createClient(input: { data: $data }) {
      client {
        id
      }
    }
  }
`;
