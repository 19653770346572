import gql from "graphql-tag";

export default gql`
  query GetAllVehicleServicesById($id: ID!) {
    vehiclemaintenancetrackings(where: { vehicle: $id }) {
      id
      description
      dateOfService
      documents
      cost
      type
      vehicletrackingtype {
        name
      }
      vehicle {
        id
      }
    }
  }
`;
