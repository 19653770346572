import gql from 'graphql-tag';

export default gql`
  mutation CreateStoredlocation($data: StoredlocationInput) {
    createStoredlocation(input: { data: $data }) {
      storedlocation {
        id
        address
        name
        type
        lat
        long
      }
    }
  }
`;
