import React, { useState } from "react";
import {
  Chat,
  Channel,
  ChannelList,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread
} from "stream-chat-react";

import { StreamChat } from "stream-chat";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import IconButton from "@material-ui/core/IconButton";
import auth from "../../utils/auth";

import "stream-chat-react/dist/css/index.css";
import "./Chat.css";

const chatClient = new StreamChat("rzrazng85922");
const userToken = localStorage.getItem("streamToken");

const NewChatWrapper = () => (
  <div className="str-chat__header-livestream">
    <div className="str-chat__header-livestream-left">
      <p className="str-chat__header-livestream-left--title">lol</p>
      olo
      <p className="str-chat__header-livestream-left--members">members</p>
    </div>
  </div>
);

class MyChannelPreview extends React.Component {
  // modify this component to match the PS messages and unread list
  render() {
    const { setActiveChannel, channel } = this.props;
    const unreadCount = channel.countUnread();

    return (
      <div className="channel_preview">
        <a href="#" onClick={e => setActiveChannel(channel, e)}>
          {channel.data.name}
        </a>

        <span>Unread messages: {unreadCount}</span>
      </div>
    );
  }
}

const App = () => {
  const [newChatState, setNewChatState] = useState(false);

  if (!chatClient.userID) {
    chatClient.setUser(
      {
        id: auth.get("userInfo").id,
        status: "Driving towards Palm Jumeirah",
        name: `${auth.get("userInfo").firstName} ${
          auth.get("userInfo").lastName
        }`,
        image: `https://getstream.io/random_png/?id=${
          auth.get("userInfo").id
        }&name=${auth.get("userInfo").firstName} ${
          auth.get("userInfo").lastName
        }`
      },
      userToken
    );

   

    const channel = chatClient.channel("messaging", "general", {
      name: "General channel - portal wide"
    });
    channel.watch(); // adds existing user to chat room
  }

  const filters = { type: "messaging" };
  const sort = { last_message_at: -1 };

  return (
    <>
      <Chat client={chatClient} theme="messaging light">
        <Fab
          style={{ position: "fixed", bottom: 20, left: 110, zIndex: 9999 }}
          color="primary"
          onClick={async () => {
            const conversation = chatClient.channel("messaging", {
              members: ["5f397f042541ff4c2c823c89", auth.get("userInfo").id]
            });

            await conversation.watch();
          }}
        >
          <AddIcon />
        </Fab>

        <ChannelList
          filters={filters}
          sort={sort}
          HeaderComponent={<div>lol</div>}
          Preview={MyChannelPreview}
        />
        <Channel>
          <Window>
            {newChatState ? <NewChatWrapper /> : <ChannelHeader />}
            <MessageList />
            <MessageInput />
            <Thread />
          </Window>
        </Channel>
      </Chat>

      <div style={{ position: "fixed", left: 110, top: 20 }}>
        <IconButton
          aria-label="Delete"
          disabled={newChatState}
          onClick={() => {
            setNewChatState(true);
          }}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </div>
    </>
  );
};

export default App;
