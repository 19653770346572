import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ActionButton = withStyles({
  root: {
    borderRadius: "3px",
    width: "100%",
    border: "1px dashed #4001C5",
    padding: "16px",
    boxShadow: "none",
    "&:hover": {
      background: "rgba(64, 1, 197, 0.05)",
      border: "1px solid #4001C5",
      boxShadow: "none"
    }
  },
  label: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "start",
    textAlign: "start",
    justifyContent: "space-between",
    letterSpacing: "0.3px",
    color: "#4001C5",
    textTransform: "none"
  },
  disabled: {
    border: "none",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    "& $label": {
      color: "rgba(0, 0, 0, 0.3)"
    }
  }
})(Button);

const Add = makeStyles({
  root: {
    fontSize: "1px",
    fontWeight: "400",
    padding: "8px, 12px"
  }
});

//SelectActionButton is a customized button from Material UI
/**
 * Change style of button by adding specialized props
 * @param disabled - Type in 'simple' prop to disable component.
 * @param startIcon - Takes in a component, icon, etc. as icon to place at beginning of button. The default value for simple button is a forwerd arrow.
 */

export default function SelectActionButton(props) {
  const classes = Add();

  const ActBtn = (
    <ActionButton
      endIcon={<span class={classes.root}>+</span>}
      style={{ display: "block" }}
      {...props}
    >
      {props.children}
    </ActionButton>
  );

  return ActBtn;
}
