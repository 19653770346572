import _ from 'lodash';

export const getUnresolvedAlerts = (eventData) => {
  if(eventData) {
const allAlerts = _.flatMap(eventData, 'alerts');
  return (allAlerts && _.filter(allAlerts, { status: 'unresolved' })) || [];
}
};
export const getCompletedData=({localCountData ,loadingCount})=>{
  return localCountData ? localCountData?.passengerScheduleCount?.completeRides : loadingCount

}
