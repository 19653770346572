import gql from "graphql-tag";

export default gql`
  query GetAllStoredLocations {
    storedlocations(
      where: {
        parent_null: true
        name_ne: "-As directed-"
        isDuplicate_ne: true
      }
      sort: "{ id:desc"
    ) {
      id
      identifier
      name
      type
      address
      contactPhone
      lat
      long
    }
  }
`;
