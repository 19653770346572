import gql from 'graphql-tag';

export default gql`
  mutation UpdateDepartment($id: InputID!, $data: editDepartmentInput) {
    updateDepartment(input: { where: $id, data: $data }) {
      department {
        id
      }
    }
  }
`;
