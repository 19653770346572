export const barData = [
  { name: "8 Nov", rt: 4000, rb: 2400 },
  { name: "9 Nov", rt: 3000, rb: 1398 },
  { name: "10 Nov", rt: 2000, rb: 9800 },
  { name: "11 Nov", rt: 2780, rb: 3908 },
];

export const barConfig = {
  xAxis: "name",
  bars: [
    {
      dataKey: "rt",
      fill: "#CC4A01",
      legend: "Rides Taken",
    },
    {
      dataKey: "rb",
      fill: "rgba(204, 74, 1, 0.4)",
      legend: "Rides Booked",
    },
  ],
};

export const transferTypebarConfig = {
  xAxis: "name",
  bars: [
    {
      dataKey: "rt",
      fill: "#6AB657",
      legend: "Transfer type",
    },
  ],
};

export const transferVehiclebarConfig = {
  xAxis: "name",
  bars: [
    {
      dataKey: "rt",
      fill: "#6AB657",
      legend: "Vehicle type",
    },
  ],
};

export const transferModulebarConfig = {
  xAxis: "name",
  bars: [
    {
      dataKey: "rt",
      fill: "#6AB657",
      legend: "Module type",
    },
  ],
};
