import AlertTag from "components_v2/AlertTag/AlertTag";
import React from "react";
import _ from "lodash";
import { deepCopy } from "../../utils/helpers";

export const getLeftBorderColor = row => {
  if (row.type) {
    switch (row.type) {
      case "high":
        return "red";
      case "medium":
        return "blue";
      case "low":
        return "green";
      default:
        return null;
    }
  }
  return null;
};

// Change status to category @bbrez
export const getAlertTag = row =>
  (row && (
    <b>
      <AlertTag
        label={row.status}
        type={row.type}
        labelColor={row.status === "resolved" && "#2B7479"}
      />
    </b>
  )) ||
  null;

export const parseAlertsData = data => {
  let currentData = (data && deepCopy(data?.alerts)) || [];

  currentData = _.orderBy(currentData, ["status", "id:"], ["desc", "desc"]);

  return currentData;
};
