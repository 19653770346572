/* eslint-disable react/display-name */
import moment from 'moment';
import React from 'react';
import StarRating from 'components_v2/StarRating/StarRating';
import Typography from '../Typography/Typography';

export const getHamburgerMenuItems = (history) => [
  {
    label: 'Edit',
    onClick: (row) => history.push(`/project/${row.id}/edit`),
  },
  {
    label: 'Duplicate',
    onClick: () => { },
  },
  {
    label: 'Share',
    onClick: () => { },
  },
  {
    label: 'Copy Link',
    onClick: () => { },
  },
];

export const actions = {
  rowClick: () => { },

  primary: {
    onClick: () => { },
    label: 'View Playback',
  },
};

export const getColumns = (projectId = null, driverId = null, isMobileView) => {
  let dynamicColumn = {};

  if (projectId)
    dynamicColumn = {
      field: 'driver.fullName',
      headerName: 'Driver',
      width: 3,
  render: (data) =>
        (data.driver && (
          <Typography customVariant="bodySmall">
            {data.driver.fullName} 
          </Typography>
        )) || <></>,
    };
  else if (driverId)
    dynamicColumn = {
      field: 'name',
      headerName: 'Project name',
      width: 3,
      hideMobile: true,
      render: (data) =>
        (data.event && (
          <Typography customVariant="bodySmall">{data.event.name}</Typography>
        )) || <></>,
    };

  return [
    ...(isMobileView
      ? [
        {
          field: 'start',
          headerName: 'Date',
          type: 'time',

          render: (data) =>
            (data.event && (
              <Typography customVariant="bodySmallRegular">
                {moment(data.event.start).format('DD/MM/YYYY')}
              </Typography>
            )) || <></>,
        },
        {
          field: 'passenger.fullName',
          headerName: 'Passenger',
          width: 3,
          render: (data) =>data.passenger.fullName
          
        },
        {
          field: 'rating',
          headerName: 'Driver',

          render: (data) =>
            (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',

                  flexDirection: 'row',
                }}
              >
                <div style={{ marginRight: '8px' }}>
                  {data.rating && (
                    <StarRating direction="left" rating={data.rating} />
                  )}
                </div>

                {data.driver && (
                  <Typography customVariant="bodySmallRegular">
                    {data.driver.fullName}
                  </Typography>
                )}
              </div>
            ) || <></>,
        },
      ]
      : [
        {
          field: 'start',
          headerName: 'Ride date',
          type: 'time',
          width: 2,

          render: (data) =>
            (data.event && (
              <Typography customVariant="bodySmallRegular">
                {moment(data.event.start).format('DD/MM/YYYY')}
              </Typography>
            )) || <></>,
        },
        {
          field: 'passenger.fullName',
          headerName: 'Passenger',
          width: 3,
          render: (data) =>data.passenger.fullName
          
        },
        {
          field: 'rating',
          headerName: 'Rating',
          width: 2,
          render: (data) =>
            (data.rating && (
              <StarRating direction="left" rating={data.rating} />
            )) || <></>,
        },
        dynamicColumn,
      ]),
  ];
};

