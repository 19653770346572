import * as React from 'react';
import { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { UserAdd } from '../../Icons/Icons';
import './LocationMarker.css';

const CustomMarker = ({ long, lat, draggable, onDragEnd }) => (
  <Marker
    draggable={draggable}
    onDragEnd={onDragEnd}
    longitude={long}
    latitude={lat}
  >
    <div className="icon-wrapper">
      <UserAdd />
    </div>
  </Marker>
);
export default CustomMarker;
