import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';


import GetAllUsers from 'gql/Users/GetAll';
import './Messages.css';
import SelectInput from '../Inputs/SelectInput/SelectInput';
import Button from '../Button/Button';
import { ArrowsChevronRight } from '../Icons/Icons';
import { deepCopy } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  messageSelectorWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',

    '& > div:first-child': {
      flex: 1,
      marginRight: '8px',
    },

    '& > button svg': {
      fill: '#ffffff',
    },
  },
}));

const MessagesSelector = ({ onSelectValues }) => {
  const classes = useStyles();
  const [currentChatRoom, setCurrentChatRoom] = useState([]);

  const { data: usersData } = useQuery(GetAllUsers, {
    variables: {},
    fetchPolicy: 'no-cache',
  });

  let localUsersData = {}


  if (usersData) {
    localUsersData = _.map(
      usersData.users,
      (record) => {
        const localRecord = deepCopy(record);
        localRecord.name = `${localRecord.firstName} ${localRecord.lastName}`
        return localRecord;
      }
    );
  }

  const handleUserChange = async (event) => {
    setCurrentChatRoom(event.target.value);
  };

  return (
    <div className={classes.messageSelectorWrapper}>
      <SelectInput
        isMultiple
        noMargin
        name="currentChatRoom"
        placeholder="Please select a chat room"
        value={currentChatRoom}
        onChange={handleUserChange}
        options={(localUsersData && localUsersData.users) || []}
      />
      <Button
        variant="contained"
        disabled={currentChatRoom.length === 0}
        onClick={() => onSelectValues(currentChatRoom)}
      >
        <ArrowsChevronRight />
      </Button>
    </div>
  );
};

export default MessagesSelector;
