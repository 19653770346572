import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import ProgressBar from "./components/ProgressBar";
import CardAction from "./components/CardAction";
import { StatusAlert } from "../Icons/Icons";
import { mobileViewBreakpoint } from "../../App.helper";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "components_v2/Typography/Typography";
import Box from "@material-ui/core/Box";
import Loading from 'components_v2/Loading/CircularProgress';
import CircularProgress from "../../components_v2/Loading/CircularProgress";

// Highlight takes props of percentage of completion (integer), amount of alerts, ongoing and completed tasks
/**
 * Progressbar and buttons component.
 * @param {int} percent - The integer value of percent the progressbar has full range: 0-100.
 * @param {int} alerts - The integer value of alerts the user has.
 * @param {int} ongoing - The integer value of ongoings the user has.
 * @param {int} completed - The integer value of complets the user has.
 */

const Highlight = ({
  projectId,
  history,
  progress,
  alerts,
  ongoing,
  completed,
  loading,
  alertsLoading
}) => {
  const classes = styles();
  const seperator = <span className={classes.seperator}>|</span>;
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const alertsClick = () => {
    if (projectId) {
      history.push(`/project/${projectId}/alerts`);
    } else {
      document.querySelector("#top-tools-alert-btn").click();
    }
  };
  const ongoingClick = () => {
    if (projectId) {
      history.push(`/project/${projectId}`);
    }
  };
  const completedClick = () => {
    if (projectId) {
      history.push(`/project/${projectId}/history`);
    } else {
      history.push(`/history`);
    }
  };

  return (
    (loading && (
      <div>
        {!isMobileView && (
          <Skeleton
            variant="text"
            width={400}
            height={50}
            style={{ marginTop: 10, marginBottom: 8 }}
          />
        )}
        <div style={{ display: "flex" }}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={isMobileView ? 50 : 104}
          />
        </div>
      </div>
    )) || (
      <>
        {(!isMobileView && (

          <>

            <Box my={2}>
              <Typography customVariant="h4Regular">Ride status</Typography>
            </Box>

            <Card className={classes.root}>
              <CardContent className={classes.contentSpacing}>
                <ProgressBar percent={progress} />
              </CardContent>
              <CardActions>
                <Button onClick={() => alertsClick()}>
                  {alerts === 0 ? <CardAction amount="0" type="alerts" /> : <CardAction amount={alerts === loading ? <CircularProgress size={20} /> : alerts} type="alerts" />}

                </Button>
                {seperator}
                <Button onClick={() => ongoingClick()}>
                  {ongoing === 0 ? <CardAction amount="0" type="ongoing" /> : <CardAction amount={ongoing === loading ? <CircularProgress size={20} /> : ongoing} type="ongoing" />}
                </Button>
                {seperator}
                <Button onClick={() => completedClick()}>
                  {completed === 0 ? <CardAction amount="0" type="completed" /> : <CardAction amount={completed === loading ? <CircularProgress size={20} /> : completed} type="completed" />}
                </Button>
              </CardActions>
            </Card>
          </>
        )) || (
            <Card className={classes.mobileViewWrapper}>
              <CardContent>
                <Grid container wrap="nowrap">
                  <Grid item xs zeroMinWidth>
                    <div className={classes.ridesCompleted}>
                      Rides completed
                      <div className={classes.rating}>
                        ({completed}/{ongoing + completed})
                      </div>{" "}
                    </div>
                    <ProgressBar percent={progress} />
                  </Grid>
                  <Grid item className={classes.contentTwoWrapper}>
                    <StatusAlert />
                  </Grid>
                  <Grid item className={classes.completedWrapper}>
                    {alertsLoading ? <Loading size={24} /> : alerts}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
      </>
    )
  );
};

const styles = makeStyles({
  mobileViewWrapper: {
    width: "100%",
    height: "63px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
  },
  ridesCompleted: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "134.3%",
    color: "#000000",
    paddingBottom: "8px",
    display: "flex",
  },
  root: {
    padding: "10px 0px",
    width: "430px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  rating: {
    fontWeight: "bold",
    paddingLeft: "2px",
  },
  contentTwoWrapper: {
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "14px",
  },

  completedWrapper: {
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "134.3%",
    paddingLeft: "8px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    letterSpacing: "-0.5px",

    color: "#000000",
  },
  seperator: {
    display: "inline-block",
    margin: "0 0 .25em",
    transform: "scale(0.8)",
    lineHeight: "1",
    color: "rgba(0, 0, 0, 0.1)",
  },
  contentSpacing: {
    paddingBottom: ".50em",
  },
});

export default Highlight;
