import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cardItem: {
    cursor: 'pointer',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',

    boxShadow: '0px 8px 28px transparent',
    '&:lastChild': {
      border: 'none',
    },
    '&:hover': {
      borderBottom: '1px solid #ffffff',
      boxShadow: '0px 8px 28px rgba(0, 0, 0, 0.2)',
      borderRadius: '6px',
    },
  },
  alertPageCardItem: {
    cursor: 'pointer',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',

    boxShadow: '0px 8px 28px transparent',
    '&:lastChild': {
      border: 'none',
    },
  },
  inline: {
    display: 'inline',
  },
  image: {
    width: '72px',
    height: '72px',
    marginRight: '16px',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  content: {
    marginTop: '16px',
    marginBottom: '14px',
    fontSize: '14px',
    lineHeight: '134.3%',
    weight: 400,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  basicInfo: {
    paddingRight: '16px',
  },
  location: {
    fontSize: '14px',
    lineHeight: '134.3%',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  supplementInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  from: {
    fontWeight: 'bold',
    fontSize: '16px',
    paddingBottom: '8px',
    lineHeight: '134.3%',
  },
  time: {
    fontSize: '14px',
    lineHeight: '134.3%',

    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '16px',
    marginBottom: '8px',
  },
  iconTypeAlert: {
    fill: '#C50202',
  },
  iconTypeDelay: {
    fill: '#C50202',
  },
}));
