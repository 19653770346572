import React from 'react';
import Typography from "components_v2/Typography/Typography";

export default function FullNameDisplay({ firstName, lastName }) {
  if (!firstName && !lastName) return null;

  const fullName = `${firstName} ${lastName}`
  return (
    <Typography customVariant="bodySmallRegular">{fullName}</Typography>
  );
}
