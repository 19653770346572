import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CheckboxInput from '../../Inputs/CheckboxInput/CheckboxInput';
import Button from '../../Button/Button';
import AccountHeader from '../AccountHeader';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: '534px',
    background: '#FFFFFF',
    boxShadow: '-16px 0px 25px rgba(0, 0, 0, 0.1)',
    height: '100%',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  body: {
    margin: '0 24px 0',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: '0',
      flex: '1',
      flexWrap: 'wrap',
      overflow: 'auto',
    },

    '& > form': {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    '& > form > .MuiGrid-container > .MuiGrid-item': {
      '&:last-child': {
        textAlign: 'right',

        [theme.breakpoints.down(mobileViewBreakpoint)]: {
          textAlign: 'left',
        },

        '& button': {
          padding: 0,
          minWidth: 0,
        },
      },
    },
  },
  subTitle: {
    marginTop: '40px',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#000000',
  },
  notificationLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: '#000000',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    minHeight: '78px',

    '&.MuiGrid-item': { marginBottom: '0' },
    '& .MuiGrid-container': {
      alignItems: 'center',
    },
  },
  submitButton: {
    marginTop: '32px',
  },
  notificationType: {
    textAlign: 'center',
    marginBottom: '0',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  checkbox: {
    textAlign: 'center',

    '& > .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  footer: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '20px',
      textAlign: 'right',
      zIndex: 1,
      marginTop: 'auto',
      boxShadow: '0px -10px 17px rgba(0, 0, 0, 0.1)',
      background: '#ffffff',
    },
  },
}));

const Notifications = ({ onBackClick, onCloseClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const defaultFormState = {
    accidentEmail: true,
    accidentSMS: false,
    delayedFlightEmail: true,
    delayedFlightSMS: true,
    delayedDriverEmail: true,
    delayedDriverSMS: true,
    changeRequestsEmail: false,
    changeRequestsSMS: true,
    newProjectEmail: true,
    newProjectSMS: false,
    newBookingEmail: false,
    newBookingSMS: true,
    requestAccessEmail: true,
    requestAccessSMS: true,
  };

  return (
    <div className={classes.sidebar}>
      <AccountHeader
        title="Notifications"
        onBackClick={onBackClick}
        onCloseClick={onCloseClick}
      />

      <div className={classes.body}>
        <Formik
          initialValues={defaultFormState}
          onSubmit={() => { }}
        >
          {(props) => {
            const { values, handleChange } = props;
            return (
              <Form>
                <Grid
                  container
                  style={
                    (isMobileView && {
                      padding: '0px 24px 0px',
                      background: '#ffffff',
                      overflow: 'auto',

                    }) ||
                    {}
                  }
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.subTitle}
                  >
                    Booking Alerts
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8} />
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <div className={classes.notificationType}>Email</div>
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <div className={classes.notificationType}>SMS</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Accident
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="accidentEmail"
                          value={values.accidentEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="accidentSMS"
                          value={values.accidentSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Delayed Flight
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="delayedFlightEmail"
                          value={values.delayedFlightEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="delayedFlightSMS"
                          value={values.delayedFlightSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Delayed Driver
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="delayedDriverEmail"
                          value={values.delayedDriverEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="delayedDriverSMS"
                          value={values.delayedDriverSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Change Requests
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="changeRequestsEmail"
                          value={values.changeRequestEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="changeRequestsSMS"
                          value={values.changeRequestsSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.subTitle}
                  >
                    Activity
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8} />
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <div className={classes.notificationType}>Email</div>
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <div className={classes.notificationType}>SMS</div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Someone creates a new project
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="newProjectEmail"
                          value={values.newProjectEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="newProjectSMS"
                          value={values.newProjectSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Someone creates a new booking
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="newBookingEmail"
                          value={values.newBookingEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="newBookingSMS"
                          value={values.newBookingSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.notificationLabel}
                  >
                    <Grid container>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        Someone requests access to my project
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="requestAccessEmail"
                          value={values.requestAccessEmail}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        className={classes.checkbox}
                      >
                        <CheckboxInput
                          name="requestAccessSMS"
                          value={values.requestAccessSMS}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {!isMobileView && (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.submitButton}
                    >
                      <Button variant="simple" type="submit">
                        Save
                      </Button>
                    </Grid>
                  )}
                </Grid>
                {isMobileView && (
                  <footer className={classes.footer}>
                    <Button variant="simple" type="submit">
                      Save
                    </Button>
                  </footer>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Notifications;
