export const mockAlertsGroupedData = {
  alertsConnection: {
    aggregate: { count: 1, __typename: 'AlertsAggregator' },
    __typename: 'AlertsConnection',
  },
};

export const mockPassengerSchedulesData = {
  passengerschedules: [
    {
      id: '5f29c23b678370f78808adbc',
      start: '2020-08-05T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f2d299d16f55c9d3ce20642',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c299678370f78808adbd',
      start: '2020-08-04T01:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c394678370f78808adbe',
      start: '2020-08-04T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c4ad0693e9ac508e9368',
      start: '2020-08-04T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c5c280d8bf1149844569',
      start: '2020-08-04T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c6297b1dcffab432958e',
      start: '2020-08-04T08:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f2d2a0f16f55c9d3ce20643',
      start: '2020-08-07T12:16:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f2d2a2116f55c9d3ce20644',
          ridestatuses: [
            {
              id: '5f3ae80f0574f89ed01a3053',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae80f0574f89ed01a3054',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae80f0574f89ed01a3055',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae8120574f89ed01a3056',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae8120574f89ed01a3057',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae8130574f89ed01a3058',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d549cb602c73c11aa57',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d569cb602c73c11aa58',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d579cb602c73c11aa59',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d599cb602c73c11aa5a',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d6d9cb602c73c11aa5b',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d6f9cb602c73c11aa5c',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d709cb602c73c11aa5d',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d719cb602c73c11aa5e',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d729cb602c73c11aa5f',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f2dd487d9045982b0e8bb32',
      start: '2020-08-07T00:23:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f2dd49bd9045982b0e8bb33',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f343f9a99a4c119f075949a',
      start: '2020-08-12T21:11:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f35b5683825eee594a1df2d',
      start: '2020-08-13T23:48:00.000Z',
      stop: null,
      numberOfVehicles: '8',
      driverschedules: [
        {
          id: '5f3d5c264c2533001783b4a1',
          ridestatuses: [
            {
              id: '5fa9f52cd19cb05218398ac6',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fa9f5d7d19cb05218398ac7',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fa9f5dbd19cb05218398ac8',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fa9f5ecd19cb05218398ac9',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fa9f5f1d19cb05218398aca',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
        {
          id: '5f3d5c294c2533001783b4a2',
          ridestatuses: [
            {
              id: '5f3d5ca54c2533001783b4b2',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3d5ca54c2533001783b4b3',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3d5ca64c2533001783b4b4',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3d5ca84c2533001783b4b5',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3d5ca94c2533001783b4b6',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3d5ca94c2533001783b4b7',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f6927fec812aff65494cc5b',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f6927ffc812aff65494cc5c',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692800c812aff65494cc5d',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692801c812aff65494cc5e',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692812c812aff65494cc5f',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692812c812aff65494cc60',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692813c812aff65494cc61',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692814c812aff65494cc62',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692815c812aff65494cc63',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f3d03744c2533001783b491',
      start: '2020-08-19T17:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f3d5c4b4c2533001783b4a3',
          ridestatuses: [
            {
              id: '5f3d5c8b4c2533001783b4a6',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69275692708b11497e3a0a',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69275792708b11497e3a0b',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69275892708b11497e3a0c',
              status: 'noShow',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69275a92708b11497e3a0e',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f3d5a7e4c2533001783b499',
      start: '2020-08-19T21:10:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f3d5c5f4c2533001783b4a4',
          ridestatuses: [
            {
              id: '5f3d5c904c2533001783b4a7',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3d5c904c2533001783b4a8',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274392708b11497e3a00',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274492708b11497e3a01',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274592708b11497e3a02',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274692708b11497e3a03',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274692708b11497e3a04',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274792708b11497e3a05',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274792708b11497e3a06',
              status: 'complete',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274892708b11497e3a07',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274992708b11497e3a08',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f69274a92708b11497e3a09',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f46b99a01c7565a84baeb0d',
      start: '2020-08-26T21:35:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f46b9eb90242c35c80b991c',
      start: '2020-08-26T21:36:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f46bb33e00cc47588a6b8a8',
      start: '2020-08-26T21:39:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f48edc490ca8ec1a4d3b802',
      start: '2020-08-28T17:30:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f48ee3b90ca8ec1a4d3b803',
      start: '2020-08-28T16:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f877dbec340b80017eb3272',
      start: '2020-10-14T06:37:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f88cccfd1ff2d00170d3a93',
      start: '2020-10-30T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f88cd86d1ff2d00170d3a97',
      start: '2020-08-03T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fa9f4ef7f2ba80017dfd6a2',
      start: '2020-11-10T17:03:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fa9f6b07f2ba80017dfd6a3',
          ridestatuses: [
            {
              id: '5fab2ff94bdef85c64ef3a16',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fab2ffe4bdef85c64ef3a17',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fab30064bdef85c64ef3a18',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fab300a4bdef85c64ef3a19',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fab309a871e56ce08b23ab6',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac38a559c9628d52d02ddd',
      start: '2020-11-11T19:16:51.000Z',
      stop: null,
      numberOfVehicles: '3',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac38a659c9628d52d02dde',
      start: '2020-11-20T18:16:00.000Z',
      stop: null,
      numberOfVehicles: '3',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac78c57f99e48e42dec243',
      start: '2020-11-13T07:00:29.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac78c77f99e48e42dec244',
      start: '2020-11-13T07:00:29.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac834a7f99e48e42dec245',
      start: '2020-11-12T00:35:21.000Z',
      stop: null,
      numberOfVehicles: '4',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac834b7f99e48e42dec246',
      start: '2020-11-12T00:35:21.000Z',
      stop: null,
      numberOfVehicles: '4',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac850f7f99e48e42dec247',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac85117f99e48e42dec248',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac86097f99e48e42dec249',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac862f7f99e48e42dec24a',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac86607f99e48e42dec24b',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac86767f99e48e42dec24c',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac86847f99e48e42dec24d',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac87177f99e48e42dec24e',
      start: '2020-11-05T21:04:07.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac8bdb7f99e48e42dec24f',
      start: '2020-11-12T01:11:54.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac8bdd7f99e48e42dec250',
      start: '2020-11-12T01:11:54.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac92467f99e48e42dec251',
      start: '2020-11-12T01:11:54.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac93b87f99e48e42dec252',
      start: '2020-11-05T23:15:43.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac93ba7f99e48e42dec253',
      start: '2020-11-05T23:15:43.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fac93f47f99e48e42dec254',
      start: '2020-11-05T23:15:43.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5faca0c463a33e5600a5aff5',
      start: '2020-11-12T19:00:00.000Z',
      stop: null,
      numberOfVehicles: '3',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5faca0c663a33e5600a5aff6',
      start: '2020-11-12T19:00:00.000Z',
      stop: null,
      numberOfVehicles: '3',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5faca41463a33e5600a5aff7',
      start: '2020-11-12T21:00:25.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5faca41663a33e5600a5aff8',
      start: '2020-11-12T21:00:25.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5faca50363a33e5600a5aff9',
      start: '2020-11-12T02:59:14.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5faca50563a33e5600a5affa',
      start: '2020-11-12T02:59:14.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fad588eab25f7bba82ae79b',
      start: '2020-11-12T15:45:16.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fad588fab25f7bba82ae79c',
      start: '2020-11-12T15:45:16.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb066f472585195a0ba6bdc',
      start: '2020-11-14T23:23:31.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb070ca6347f78020001f82',
      start: '2020-11-15T00:05:29.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb0711d7d0d668664730319',
      start: '2020-11-15T00:06:52.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb0717f72e5cb95f424ee14',
      start: '2020-11-15T00:08:30.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb0719672e5cb95f424ee15',
      start: '2020-11-15T00:08:52.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb072f78cef9b841070c4fe',
      start: '2020-11-15T00:14:46.000Z',
      stop: null,
      numberOfVehicles: '3',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb07362337dff63a47e4313',
      start: '2020-11-15T00:16:33.000Z',
      stop: null,
      numberOfVehicles: '3',
      driverschedules: [
        {
          id: '5fb07363337dff63a47e4315',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
        {
          id: '5fb07363337dff63a47e4316',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
        {
          id: '5fb07363337dff63a47e4314',
          ridestatuses: [
            {
              id: '5fb087ba337dff63a47e4319',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb087c6337dff63a47e431b',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb087ca337dff63a47e431c',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb087ce337dff63a47e431d',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb087d2337dff63a47e431e',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb0887a337dff63a47e431f',
      start: '2020-11-15T01:46:33.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb0887b337dff63a47e4320',
          ridestatuses: [
            {
              id: '5fb08aa9337dff63a47e433d',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08aac337dff63a47e433e',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08aaf337dff63a47e433f',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08ab3337dff63a47e4340',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08ab6337dff63a47e4341',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb08d28974cce3d04ca20f7',
      start: '2020-11-15T02:06:31.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb08d29974cce3d04ca20f8',
          ridestatuses: [
            {
              id: '5fb08d80619934268c1b12c1',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08d9e619934268c1b12c4',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08da2619934268c1b12c5',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08da5619934268c1b12c6',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb08da9619934268c1b12c7',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb08db6619934268c1b12c8',
      start: '2020-11-15T02:08:53.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb08db7619934268c1b12c9',
          ridestatuses: [
            {
              id: '5fb112f37fee2b9d34c26910',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb112f97fee2b9d34c26913',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb112fd7fee2b9d34c26914',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb113007fee2b9d34c26915',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb113047fee2b9d34c26916',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb08e1a8834b08608014191',
      start: '2020-11-15T02:10:32.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb08e1a8834b08608014192',
          ridestatuses: [
            {
              id: '5fb11c2a7fee2b9d34c2692f',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb11cef7fee2b9d34c26931',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb11d057fee2b9d34c26932',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb11d0b7fee2b9d34c26933',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb11d107fee2b9d34c26934',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb168e7270f43c7aca9a630',
      start: '2020-11-15T17:44:05.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb168e7270f43c7aca9a631',
          ridestatuses: [
            {
              id: '5fb198aaffbca9859078c23e',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb198aeffbca9859078c23f',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb198b3ffbca9859078c240',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb198b8ffbca9859078c241',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19936ffbca9859078c242',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb169705731e4c5b0d46f60',
      start: '2020-11-15T17:46:22.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb169705731e4c5b0d46f61',
          ridestatuses: [
            {
              id: '5fb19ade7736b1af84a5475e',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19ae47736b1af84a5475f',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19af57736b1af84a54760',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19afa7736b1af84a54761',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19aff7736b1af84a54762',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb16abf7f27a78d48ffa023',
      start: '2020-11-15T21:00:52.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb16abf7f27a78d48ffa024',
          ridestatuses: [
            {
              id: '5fb19b0a7736b1af84a54763',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19b0e7736b1af84a54764',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19b4df335b743b4152c31',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19b51f335b743b4152c32',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5fb19b6df335b743b4152c33',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb16dd57eae2e36ccda7f5d',
      start: '2020-11-15T21:00:17.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb16dd57eae2e36ccda7f5e',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb16e69a32af0c70c076ced',
      start: '2020-11-15T21:00:17.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb16e69a32af0c70c076cee',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb16f57a32af0c70c076cef',
      start: '2020-11-15T22:00:51.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb16f57a32af0c70c076cf0',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb18d79df0a8643d215183b',
      start: '2020-11-15T20:20:08.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb18f84df0a8643d2151844',
      start: '2020-11-15T20:28:50.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb18ffbdf0a8643d2151849',
      start: '2020-11-15T20:30:50.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb1911fdf0a8643d215184e',
      start: '2020-11-15T20:35:42.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb19258df0a8643d2151853',
      start: '2020-11-15T20:40:55.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb19291a32af0c70c076cf1',
      start: '2020-11-15T20:41:52.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb19292a32af0c70c076cf2',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb19cb15f13c35a67a4c673',
      start: '2020-11-15T21:25:04.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb30886caf9500017db7329',
      start: '2020-11-16T23:17:26.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5fb30886caf9500017db732b',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
        {
          id: '5fb30886caf9500017db732a',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb7fc1bf8879d52b947ecd4',
      start: '2020-11-20T09:25:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fb801f0f8879d52b947ecd5',
      start: '2020-11-20T17:45:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fc0057da4e9afb9e1acfaa5',
      start: '2020-11-04T19:06:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fc01127a4e9afb9e1acfaa6',
      start: '2020-11-03T21:32:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fc153c72b7739e42882b204',
      start: '2020-11-05T06:50:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fc1541d2b7739e42882b205',
      start: '2020-11-03T18:05:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fc154592b7739e42882b206',
      start: '2020-11-04T18:05:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fceb298bb90f74678e65dc2',
      start: '2020-12-09T22:53:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fd25401888ee65e5e674ca0',
      start: '2020-12-02T22:15:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5fecd686096dca56717d1a0e',
      start: '2020-12-30T19:34:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
  ],
};

export const mockEventData = {
  events: [
    {
      id: '5f270baecc54999de0a9f579',
      name: 'FIFA',
      start: '2020-06-18',
      end: '2020-07-09',
      inChargeName: '',
      inChargeEmail: '',
      inChargeMobile: '',
      client: '',
      clientPriceFormat: 'Overall',
      clientPriceMargin: 50,
      products: [
        {
          id: '5f9f469fd0f6302e104174f1',
          name: 'Champagne',
          price: 139,
          description: '',
          icon: 'Waterbottle',
          __typename: 'Product',
        },
      ],
      projectClient: {
        id: '5f7cd108b3b6417f602119f7',
        contactName: null,
        companyName: 'Palm Jumeirah',
        location: 'Palm, Dubai, UAE',
        officeType: null,
        address: null,
        phone: '+11 44324  9686 787',
        email: null,
        lat: null,
        long: null,
        events: [
          {
            id: '5f270baecc54999de0a9f579',
            name: 'FIFA',
            __typename: 'Event',
          },
          {
            id: '5fc94015c657823bba8bf477',
            name: 'Test Project 2',
            __typename: 'Event',
          },
          {
            id: '5fc94287c657823bba8bf49f',
            name: 'Test 55',
            __typename: 'Event',
          },
          {
            id: '5fc94367c657823bba8bf4a9',
            name: 'Test 6dd',
            __typename: 'Event',
          },
          {
            id: '5fc94f95c657823bba8bf4e5',
            name: 'Test 13',
            __typename: 'Event',
          },
          {
            id: '5fc952890dd0334c75ab2fac',
            name: 'Test Project 4',
            __typename: 'Event',
          },
          {
            id: '5fc95ad90dd0334c75ab2fb7',
            name: 'Superbowl',
            __typename: 'Event',
          },
          {
            id: '5fcfc11aa341bcfe908e5fef',
            name: 'Add Product On Save',
            __typename: 'Event',
          },
          {
            id: '5fcfc14da341bcfe908e5ff1',
            name: 'Test Product OnCreate',
            __typename: 'Event',
          },
          {
            id: '5fcfc250749483fef21d0254',
            name: 'Test Project On Create',
            __typename: 'Event',
          },
          {
            id: '5fd3c490888ee65e5e674cb0',
            name: 'Winter Olympics',
            __typename: 'Event',
          },
          {
            id: '5fd3c4cd888ee65e5e674cbc',
            name: 'Winter Olympics',
            __typename: 'Event',
          },
          {
            id: '5fd3c584888ee65e5e674cc8',
            name: 'Test Event',
            __typename: 'Event',
          },
          {
            id: '5fd3c644888ee65e5e674cd3',
            name: 'Test Event',
            __typename: 'Event',
          },
          {
            id: '5fd3ce3b888ee65e5e674cde',
            name: 'test project 3',
            __typename: 'Event',
          },
          {
            id: '5fd3cf10888ee65e5e674ce9',
            name: 'Summer Olympics',
            __typename: 'Event',
          },
          {
            id: '5fd3d261888ee65e5e674cf4',
            name: 'Testing 10',
            __typename: 'Event',
          },
          {
            id: '5fdcb81fa599ae14dc2fdb68',
            name: 'test',
            __typename: 'Event',
          },
          {
            id: '5fdd108d4204aa1e98a61862',
            name: 'Test URL Project',
            __typename: 'Event',
          },
          {
            id: '5fe7afb7fa368efa683df858',
            name: 'Scheduler Test 1',
            __typename: 'Event',
          },
        ],
        __typename: 'Client',
      },
      country: 'United Arab Emirates',
      bookingEmailRecepients: ['blazbreznik+22@gmail.com'],
      logoImageUrl: '',
      locationInput: '["5fb8231bf8879d52b947ecd7"]',
      bookingEmailToPassenger: true,
      bookingEmailToCreator: false,
      fromEmail: 'MOTUS BEE',
      type: 'event',
      bookingPrefix: 'BB.',
      archived: false,
      currency: 'eur',
      chargeForNoShow: false,
      fullChargeTimeframe: 0,
      timeFrameBeforeBooking: 0,
      transferEnabled: true,
      sixHourEnabled: true,
      twelveHourEnabled: true,
      twentyFourHourEnabled: true,
      timezone: 'Asia/Dubai',
      fleet:
        '[{"value":"Sedan","name":"Sedan","id:":"Sedan","quantity":4},{"value":"SUV","name":"SUV","id:":"SUV","quantity":1}]',
      requiredStaff: '[]',
      fleetSuppliers:
        '[{"id:":"5f7e30b0dae257a330eceb29","name":"Awesome Limos","contactName":"Jack Michaelson","phone":"24324 94 34934 9349","email":"jmick@gmail.com","__typename":"Suppliers","selectedVehicles":[{"id:":"5f271bdb7442154fd8a8c13d","make":"Audi v2","model":"Car","vehicleClass":{"id:":"5f271b6e7442154fd8a8c139","name":"Sedan-Basic","__typename":"Vehicleclass"},"__typename":"Vehicle","quantity":1,"supplierPrices":{"supplierTransfer":"100"},"clientPrices":[]},{"id:":"5f2c6a7916f55c9d3ce20640","make":"Audi","model":"Car","vehicleClass":{"id:":"5f271b6e7442154fd8a8c139","name":"Sedan-Basic","__typename":"Vehicleclass"},"__typename":"Vehicle","quantity":1,"supplierPrices":[],"clientPrices":[]}]}]',
      backgroundAlertsEnabled: true,
      lateArrivalTimeframe: 15,
      defaultTransferTime: 2,
      availabilityAfterTransfer: 30,
      modules: [
        {
          id: '5f763aba0cf1e4d13032e436',
          name: 'Media 69',
          description: 'Description',
          departments: [
            {
              id: '5f975edb69eeee18d55e498a',
              name: '24 Hours',
              __typename: 'Department',
            },
          ],
          __typename: 'Module',
        },
        {
          id: '5fb13fa7f9139a00170229b0',
          name: 'Ivan',
          description: 'some awesome description',
          departments: [],
          __typename: 'Module',
        },
      ],
      __typename: 'Event',
    },
  ],
  products: [
    {
      id: '5f9f469fd0f6302e104174f1',
      name: 'Champagne',
      description: '',
      price: 139,
      icon: 'Waterbottle',
      __typename: 'Product',
    },
  ],
};
