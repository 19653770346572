import gql from 'graphql-tag';

export default gql`
  mutation UpdateVehicleclass($id: InputID!, $data: editVehicleclassInput) {
    updateVehicleclass(input: { where: $id, data: $data }) {
      vehicleclass {
        id
      }
    }
  }
`;
