import gql from 'graphql-tag';

export default gql`
  mutation UpdateProduct($id: InputID!, $data: editProductInput) {
    updateProduct(input: { where: $id, data: $data }) {
      product {
        id
      }
    }
  }
`;
