export const createLocationWithState = (
  to,
  location,
  values,
  formStep,
  exactUrl,
  returnField,
  returnFieldMetadata,
) => {
  const fromPathArray = location.pathname.split('/');
 
  // The filter removes any form step numbers from the path
  const baseFromPath = fromPathArray
    .filter((path, index) => path !== '' &&  fromPathArray[index - 1] !== 'step')
    .join('/');


  const fromPath =
    formStep && formStep !== 1 ? `${baseFromPath}/${formStep}` : baseFromPath;

  const toPath = baseFromPath
    .split('/')
    .filter((path) => path !== '' && path !== 'edit')
    .join('/');

  const result = {
    pathname: exactUrl ? to : `/${toPath}${to}`,
    state: { values, fromPath: `/${fromPath}` },
  };
  if (returnField) result.state.returnField = returnField;
  if (returnFieldMetadata) result.state.returnFieldMetadata = returnFieldMetadata;

  return result;
};

export const getPathWithoutStep = (step) => {
  const splitPathname = window.location.pathname.split('/');
  const paramIndex = splitPathname.indexOf(step.toString());
  if (paramIndex === -1) return window.location.pathname;
  const pathWithoutStep = splitPathname.slice(0, paramIndex).join('/');

  return pathWithoutStep;
};

export const redirectOutOfBoundStep = (
  stepParam,
  location,
  history,
  totalSteps
) => {
  const pathWithoutStep = getPathWithoutStep(stepParam, location);

  if (stepParam > totalSteps || stepParam < 1) history.push(pathWithoutStep);
};
