import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Users/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddDriverComponent from './AddDriver.Component';

const AddDriverContainer = ({ match, isPublicDriver = false }) => {
  const { userId } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return (
    <AddDriverComponent
      data={data?.user}
      refetchUser={refetch}
      isPublicDriver={isPublicDriver}
    />
  );
};

AddDriverContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ userId: PropTypes.string }),
  }),
};

export default withMainLayout('Add Driver', {}, withRouter(AddDriverContainer));
