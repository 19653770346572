import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputLabel from '../InputLabel/InputLabel';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '23.5px',
    '& .MuiToggleButton-root': {
      border: 'none',
      borderRadius: '23.5px',
      color: 'rgba(0, 0, 0, 0.6)',
      height: '32px',
      fontSize: '12px',
      textTransform: 'capitalize',

      '&.Mui-selected': {
        backgroundColor: '#4001C5',
        color: 'white',
      },
    },
  },
  focused: {},
  disabled: {},
}));

const ToggleTextSwitchButton = ({
  name,
  value,
  options,
  onChange,
  label,
}) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel label={label} />
      <ToggleButtonGroup
        classes={classes}
        value={value}
        exclusive
        onChange={
          // TODO: NEED TO REVISIT
          (event, value) =>
            onChange({
              persist: () => { },
              target: {
                type: 'change',
                name,
                value,
              },
            })
        }
      >
        {options.map((option, index) => {
          return (
            <ToggleButton key={index} value={option.value}>
              {option.title}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
};

export default ToggleTextSwitchButton;
