import React from "react";
import AddBooking from "./AddBooking";
import { driverSelectColumns, vehicleSelectColumns } from "./columns";
import "../../Bookings/mobile-actions.css";

function formatSelectDriverModalData({ row, handleNewClick, columns, projectId }) {
  return { 
    headerText: `Select a driver for ${row?.year} ${row?.make} ${row?.model}`,
    title: "Select a driver",
    searchPlaceholder: "Search drivers",
    actionButtonText: "Select a driver",
    secondaryActionLabel: 'Create a new driver',
    secondaryActionHandler: handleNewClick,
    errorMessage: "Driver selection required!",
    id: row.id,
    items: row?.assignedDrivers?.map(x => x.driver),
    projectId: projectId,
    columns: columns,
  };
}

function formatSelectVehicleModalData({ row, handleNewClick, columns, projectId }) {
  return { 
    headerText: `Select a vehicle for ${row?.firstName} ${row?.lastName}`,
    title: "Select a vehicle",
    searchPlaceholder: "Search vehicles",
    actionButtonText: "Select a vehicle",
    secondaryActionLabel: 'Create a new vehicle',
    secondaryActionHandler: handleNewClick,
    errorMessage: "Vehicle selection required!",
    id: row.id,
    items: row?.assignedVehicles?.map(x => x.vehicle),
    projectId: projectId,
    columns: columns,
  };
}

export function handleAddBooking({ driverId, vehicleId, projectId, history }) {
  history.push(`/project/${projectId}/booking/new`, { values: { drivers: [{ driver: driverId, vehicle: vehicleId }]} });
}

// VehicleList menu items
export const getVehicleHamburgerMenuItems = ({ history, handleNewClick, type, id, row }) => {
  let items = [
    {
      label: "Edit",
      onClick: row => history.push(`/${type}/${id}/vehicle/${row.id}/edit`)
    },
  ];

  if (type === "project" && id) items = [
    ...items, 
    {
      render: () => { 
        return (
          <AddBooking 
            data={formatSelectDriverModalData({ row: row, handleNewClick: handleNewClick, projectId: id, columns: driverSelectColumns })}
            onClickConfirm={
              (driverId) => handleAddBooking({ driverId: driverId, vehicleId: row.id, projectId: id, history: history })
            }
          />
          )
        }
      }
    ];

  return items;
  
};

export const getVehicleMobileMenuItems = ({
  history,
  handleNewClick,
  type,
  row,
  id,
  setDeleteConfirmationModalData,
  setMobileModalData
}) => {

  const items = {
    left: [
      {
        text: " ",
        onClick: row => {
          setDeleteConfirmationModalData({
            recordName: row.vehicleClass.name,
            recordId: row.id,
            confirmationType: "delete",
            isNotDeleteAllowed: row.assignedDrivers.length > 0,
            deleteNotAllowedReason: "This vehicle cannot be deleted because it is assigned to drivers."
          });
        },
        className: "mobile-actions-delete"
      }
    ],
    right: [
      {
        text: " ",
        onClick: () => history.push(`/${type}/${id}/vehicle/${row.id}/edit`),
        className: "mobile-actions-edit"
      }
    ]
  };

  if (type === "project" && id) items.right = [
    ...items.right,
    {
      text: "Add Booking",
      onClick: (row) => {
        const data = formatSelectDriverModalData({ row: row, handleNewClick: handleNewClick, projectId: id, columns: driverSelectColumns });

        setMobileModalData(data); 
      },
      className: "mobile-actions-primary-actions"
    },
  ];

  return items;
};

// DriverList menu items
export const getDriversHamburgerMenuItems = ({ history, handleNewClick, type, id, publicOnly, row }) => {

  let items = [
    {
      label: "Edit",
      onClick: row =>  history.push(publicOnly ? `/public/driver/${row.id}/edit` :`/${type}/${id}/driver/${row.id}/edit`)
    },
  ];

  if (type === "project" && !publicOnly && id) items = [
  ...items, 
  {
    render: () => { 
      return (
      <AddBooking 
        data={formatSelectVehicleModalData({ row: row, handleNewClick: handleNewClick, projectId: id, columns: vehicleSelectColumns })}
        onClickConfirm={
          (vehicleId) => handleAddBooking({ driverId: row.id, vehicleId: vehicleId, projectId: id, history: history })
        }
      />
        )
      }
    },
]
  
  return items;
};

export const getDriverMobileMenuItems = ({history, handleNewClick, type, row, id, publicOnly, setMobileModalData}) => {
  const items = {
    right: [
      {
        text: " ",
        onClick: () => history.push(publicOnly ? `/public/driver/${row.id}/edit` : `/${type}/${id}/driver/${row.id}/edit`),
        className: "mobile-actions-edit"
      }
    ]
  };

  if (type === "project" && !publicOnly && id) items.right = [
    ...items.right,
    {
      text: "Add Booking",
      onClick: (row) => {
        const data = formatSelectVehicleModalData({ row: row, handleNewClick: handleNewClick, projectId: id, columns: vehicleSelectColumns });
        
        setMobileModalData(data);

        return items;
      },
      className: "mobile-actions-primary-actions"
    },
  ]

  return items;
};
