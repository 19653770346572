import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  mapRow: {
    height: 500,
    paddingTop: 16,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: 300,
    },
  },
  notesContainer: {
    '& > div': {
      height: 200,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .input-wrapper-overrider': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiFormControl-root': {
      flexGrow: 1,
    },
    '& .MuiInputBase-root': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',

      '& textarea': {
        flexGrow: 1,
      },
    },
  },
  functionalAreasContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      alignItems: 'center',
    },
  },
  functionalAreasFormContainer: {
    width: '100%',
    '&:not(:first-of-type)': {
      marginTop: '24px',
    },
  },
  functionalAreasGrid: {
    padding: 0,
    width: '100%',
  },
  functionalAreaMapRow: {
    marginTop: 16,
  },
  accordionSummaryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  accordionSummaryLeft: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: 20,
      width: 'auto',
      flexShrink: 0,
    },
  },
  accordionSummaryRight: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      '& svg': {
        position: 'relative',
        left: 30,
      },
    },
  },
  accordionDeleteButton: {
    minWidth: 32,
    '& svg': {
      width: 18,
      height: 'auto',
    },
  },
  addAnotherAreaButton: {
    marginBottom: 56,
    marginTop: -32,
    [theme.breakpoints.down(mobileViewBreakpoint)]: { marginTop: 40, marginBottom: 0 },
  },
  addAnotherAreaButtonDesktopContainer: {
    display: 'block',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'none',
    },
  },
  addAnotherAreaButtonMobileContainer: {
    display: 'none',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: 'block',
    },
  },
}));

export default useStyles;
