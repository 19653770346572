import React, { useEffect, useState } from "react";
import useStyles from "./WeekViewTimeTableCell.style";
import Modal from "components_v2/Modal/Modal";
import { Box } from "@material-ui/core";
import useChat from "hooks/useChat";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";
import GetAllUpcomingByProject from "../../Bookings/Gql/GetAllUpcomingByProject";
import { generateRidesListNew } from "../../Bookings/helpers";
import BookingDetail from "../../Bookings/BookingDetail.Component";
import PassengerNameDisplay from "components_v2/DisplayName/DisplayName";
import classNames from "classnames";

const WeekViewAppointment = ({ appointment, history, startDate, onDelete }) => {
  const classes = useStyles();
  const [localData, setLocalData] = useState({});

  const getParsedAppointment = localAppointment => {
    return {
      ...localAppointment,
      passengerScheduleId: localAppointment?.id,
      driver: localAppointment?.driverschedules[0]?.driver,
      ...localAppointment?.driverschedules[0],
      ridestatuses: localAppointment?.driverschedules[0]?.ridestatuses,
      passengerScheduleCancelled: localAppointment?.cancelled,
      driverScheduleCancelled: localAppointment?.driverschedules[0]?.cancelled
    };
  };

  useEffect(() => {
    setLocalData(getParsedAppointment(appointment));
  }, [appointment]);

  // lazy query for updating schedules
  const [
    getNewDriverSchedules,
    { loading: bookingUpdatesLoading, data: lazyData }
  ] = useLazyQuery(GetAllUpcomingByProject, { fetchPolicy: "no-cache" });

  useChat("bookingUpdates", async ({ driverSchedules }) => {
    if (
      driverSchedules &&
      driverSchedules[0] === getParsedAppointment(appointment).id
    ) {
      await getNewDriverSchedules({
        variables: {
          driverScheduleIds: driverSchedules,
          limit: driverSchedules.length
        }
      });
    }
  });

  useEffect(() => {
    if (!_.isEmpty(lazyData)) {
      const lazyLoadedPassengerSchedules = generateRidesListNew(
        lazyData?.allBookings?.bookings
      );
      setLocalData(lazyLoadedPassengerSchedules[0]);
    }
  }, [lazyData]);
  const addHighlightClass = () => {
    return !!bookingUpdatesLoading;
  };

  return (
    <Modal
      fullContainerWidth
      onModalClick={toggleModal => (
        <Box
          onClick={() => {
            toggleModal();
          }}
          className={classNames(classes.appointment, {
            "highlight-dynamic-list-item": addHighlightClass()
          })}
        >
          <>
            <b>#{localData.bookingId}</b> <br />
            &nbsp;
            <small>
              <PassengerNameDisplay passenger={localData?.passenger} />{" "}
              {localData.numPassengers > 1 && (
                <>(+{localData.numPassengers - 1} pax)</>
              )}
            </small>
          </>
        </Box>
      )}
    >
      <BookingDetail
        bookingUpdatesLoading={bookingUpdatesLoading}
        isSchedulePage
        history={history}
        data={localData}
        startDate={startDate}
        projectId={localData.id}
        onDelete={onDelete}
      />
    </Modal>
  );
};
export default WeekViewAppointment;
