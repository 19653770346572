import React from 'react';
import Tabs from 'components_v2/Tabs/Tabs';
import Typography from 'components_v2/Typography/Typography';

const MainContainer = ({ selectedTab, onChangeTab }) => (
  <>
    <Typography customVariant="h2Bold" component="span">
      Favorites
    </Typography>
    <Tabs
      scrollable
      noBg
      noPadding
      selectedTab={selectedTab}
      onChangeTab={onChangeTab}
      tabs={[
        {
          label: 'Clients',
          slug: 'clients',
          component: <div style={{ marginTop: 24 }}>clients</div>,
        },
        {
          label: 'Suppliers',
          slug: 'suppliers',
          component: <div style={{ marginTop: 24 }}>suppliers</div>,
        },
        {
          label: 'Vehicles',
          slug: 'vehicles',
          component: <div style={{ marginTop: 24 }}>vehicles</div>,
        },
        {
          label: 'Drivers',
          slug: 'drivers',
          component: <div style={{ marginTop: 24 }}>drivers</div>,
        },
      ]}
    />
  </>
);
export default MainContainer;
