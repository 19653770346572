import { useQuery } from '@apollo/react-hooks';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import Typography from 'components_v2/Typography/Typography';
import GetAllByEvent from 'gql/VehicleClasses/GetAllByEvent';
import React, { useEffect } from 'react';

const StoredVehicleClassPicker = (props) => {
  const {
    name,
    label,
    placeholder,
    value = null,
    onChange,
    projectId,
    error,
    preselect = true,
    setPreselectedValue,
  } = props;

  const { data, loading } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
  });

  const options =
    !loading &&
    data &&
    data.vehicleclasses.map((vehicle) => ({
      name: vehicle.name || '',
      value: vehicle.name || '',
      maxLuggage: vehicle.maxLuggage,
      maxPassengers: vehicle.maxPassengers,
      id: vehicle.id,
    }));

  const renderOption = ({ name: vehicleName, maxLuggage, maxPassengers }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography customVariant="bodySmallRegular" style={{ color: '#3C3C3C' }}>
        {vehicleName}
      </Typography>
      <Typography
        customVariant="bodySmallRegular"
        style={{ fontSize: 12, color: '#3C3C3C', opacity: 0.7 }}
      >
        {maxLuggage
          ? `Max ${maxLuggage} pieces of luggage`
          : `Luggage capacity is missing`}
      </Typography>
      <Typography
        customVariant="bodySmallRegular"
        style={{ fontSize: 12, color: '#3C3C3C', opacity: 0.7 }}
      >
        {maxPassengers
          ? `Max ${maxPassengers} passengers`
          : `Passenger capacity is missing`}
      </Typography>
    </div>
  );

  const classes = data?.vehicleclasses;
  const hasClasses = classes?.length;

  const getPreselectedValue = () => {
    if (value === null && hasClasses) return classes[0];
    if (value === null && !hasClasses) return null;
    if (value) return value;
  };

  const inputValue =
    preselect && value === null ? getPreselectedValue() : value;

  useEffect(() => {
    if (
      value === null &&
      preselect &&
      setPreselectedValue &&
      typeof setPreselectedValue === 'function'
    ) {
      setPreselectedValue(inputValue);
    }
  }, [hasClasses]);

  return (
    <SelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options || []}
      renderOption={renderOption}
      isError={!!error}
      errorMessage={error}
    />
  );
};

export default StoredVehicleClassPicker;
