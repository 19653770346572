import _ from "lodash";

export const getNextSortOrder = ({ event, sortActions, listConfig }) => {
  if(listConfig) {
    const sortArray = _.split(listConfig?.sort, ':')
    if (event?.target?.value?.field === sortArray[0]) {
      if (sortArray[1] === 'asc') return 'desc'
      else if (sortArray[1] === 'desc') return null
      return 'asc'
    }
  } else {
    if (event?.target?.value?.field === sortActions?.currentSort?.field) {
      if (sortActions?.currentSort?.order === 'asc') return 'desc'
      else if (sortActions?.currentSort?.order === 'desc') return null
      return 'asc'
    }
  }
  
  return 'asc';
}


export const getCurrentSortOrder = ({ column, sortActions }) => {
  if (column?.field === sortActions?.currentSort?.field) {
    return sortActions?.currentSort?.order
  }
  return null
}
export const getSortedData = ({ data, sort }) => {
  if (sort?.field && sort?.order) {
    return _.orderBy(data, [item => {
      const value = _.property(sort?.field)(item);
      if (typeof value === 'number' || Array.isArray(value) )
        return (_.property(sort?.field)(item))
      return (_.property(sort?.field)(item))?.toLowerCase()
    }], [sort?.order])
  }
  return data
}

export const getFilteredData = ({ data, filterKey }) => {
  if (filterKey) {
    return _.filter((data), (item) => {
      return JSON.stringify(item).toLowerCase().indexOf(filterKey.toLowerCase()) !== -1
    })
  }
  return data
}
export const getDisplayName=({moduleTable ,column})=>{
  return {md: !moduleTable && column.hideMobile
                          ? 'none'
                          : 'block',
                        sm: !moduleTable && column.hideMobile
                          ? 'none'
                          : 'block',
                        xs: !moduleTable && column.hideMobile
                          ? 'none'
                          : 'block',
                        lg: 'block',
}}
export const getIsModuleTableAndMobileView=({moduleTable ,isMobileView})=>{return moduleTable && isMobileView}
export const getCursor=({actions})=>{return  actions && (actions.rowClick || actions.checkbox)
  ? 'pointer'
  : 'default'}
 export const  getIsColumnsType=({column ,type})=>{
return column.type && column.type === type
 } 
 export const getIsKey =({key ,keyValue ,value})=>{
  return key === keyValue && value
}


  