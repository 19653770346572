import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Loading from "components/Loading/LinearProgress";
import { Done } from "components_v2/Icons/Icons";
import Button from "components_v2/Button/Button";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import request from "utils/request";
import AuthLayout from "./AuthLayout";
import FormFooter from "./FormFooter";
import { mobileViewBreakpoint } from "../../../../App.helper";

const useStyles = makeStyles(theme => ({
  doneIcon: {
    width: 53,
    height: "auto",
    filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
    "& path": {
      fill: "#FFF"
    }
  },
  loginFormWrapper: {
    padding: "0 88px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0 16px"
    }
  },
  footerFormWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      boxShadow: "0px -10px 17px rgba(0, 0, 0, 0.1)",
      background: "#fff",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0
    },

    "& > footer": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        background: "#fff"
      }
    }
  }
}));

const ForgotPassword = () => {
  const [loading] = useState(false);
  // Set to true to see the success view
  const [emailSent, setEmailSent] = useState(false);
  // Email state should be initialized as an empty string
  // Using this default value to test the success view
  const [email, setEmail] = useState("DEMO_EMAIL@TEST.COM");

  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const forgotPassword = async values => {
    const requestURL =
      process.env.REACT_APP_RESETPASSWORD_ENDPOINT ||
      ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/auth/forgot-password");

    if (!loading) {
      await request(requestURL, {
        method: "POST",
        body: values
      });
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const renderForm = ({
    values,
    touched,
    errors,
    handleChange,
    isSubmitting,
    submitForm,
  }) => (
    <>
      {!emailSent && (
        <Form
          style={
            isMobileView
              ? { height: "100%", display: "flex", flexDirection: "column" }
              : {}
          }
        >
          <div style={isMobileView ? { flex: "1" } : {}}>
            <div className={classes.loginFormWrapper}>
              <TextInput
                name="email"
                label="Email"
                placeholder="Please enter your email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && errors.email}
              />
            </div>

            <div style={{ marginBottom: 40 }} />

            {isSubmitting && <Loading />}
          </div>
          <div className={classes.footerFormWrapper}>
            <FormFooter
              mainAction={
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  Reset Password
                </Button>
              }
            />
          </div>
        </Form>
      )}
    </>
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    await forgotPassword(values);

    setEmailSent(true);
    setEmail(values.email);
    setSubmitting(false);
  };

  const headerText = emailSent ? "Email sent!" : "Reset Password";
  const description = emailSent ? (
    <>
      We have sent an email to{" "}
      <Typography
        customVariant="bodySmallBold"
        component="span"
        style={{ color: "#4001C5", fontWeight: 700 }}
      >
        {email}
      </Typography>{" "}
      with a link to change your password.
    </>
  ) : (
    "Enter your email address"
  );

  const icon = emailSent ? <Done className={classes.doneIcon} /> : null;

  return (
    <AuthLayout
      headerText={headerText}
      description={description}
      hasBackButton={!emailSent}
      icon={icon}
      emailSent={emailSent}
    >
      <div style={isMobileView ? { height: "100%" } : {}}>
        <Formik
          initialValues={{ email: "" }}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {props => renderForm(props)}
        </Formik>
      </div>
    </AuthLayout>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default withRouter(ForgotPassword);
