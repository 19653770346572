import gql from "graphql-tag";
import React from "react";
import withMainLayout from "../../../components_v2/Layout/withMainLayout";
import TooltipTwo from "../../../components_v2/Tooltip/Tooltip";
import Chat from "../../../components_v2/Chat/Chat";
import SimpleQuery from "../../../containers/Query/SimpleQuery";
import { getAvailableVehicleForDriver } from "../../../utils/vehicleHelpers";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";

class PersistentDrawerLeft extends React.Component {
  state = {
    selectedScheduleItem: null
  };

  render() {
    const { classes } = this.props;
    var id = localStorage.getItem("selectedEvent");
    var PubNubContext = this.props.pubNubContext;

    return <Chat />;
  }
}

export default withMainLayout(
  "Chat",
  { noPadding: true },
  withRouter(PersistentDrawerLeft)
);
