import { makeStyles } from '@material-ui/core/styles';

import { appointmentBaseStyles } from '../helpers';

export default makeStyles(() => ({
  appointment: {
    ...appointmentBaseStyles,
    cursor: 'pointer',
  },
}));
