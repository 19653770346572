import gql from "graphql-tag";

export default gql`
  query GetAllVehicles {
    vehicles {
      id
      make
      model
      year
      vehicleNumber
      maxPassengers
      type
      enabled
      notes
      status
      authorizedDrivers {
        id
        email
        username
      }
      assignedDrivers {
        id
      }
    }
  }
`;
