import React from 'react';
import PropTypes from 'prop-types';

import Card from 'components_v2/Cards/Card/Card';
import { SearchIcon } from '../../Icons/Icons';

const EmptyStateCard = (props) => {
  const { headerText, description, icon } = props;

  return (
    <Card
      size="small"
      headerText={headerText}
      description={description}
      icon={
        icon || (
          <SearchIcon
            style={{
              color: '#000',
              width: 51,
              height: 'auto',
            }}
          />
        )
      }
      iconBackground="light"
    />
  );
};

EmptyStateCard.propTypes = {
  headerText: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.element,
};

export default EmptyStateCard;
