import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';

import withMainLayout from 'components_v2/Layout/withMainLayout';
import GetAllParentLocations from 'gql/StoredLocations/GetAllParentLocations';
import FAB from 'components_v2/Buttons/FAB/FAB';
import { FloatingActionNewLocation } from 'components_v2/Icons/Icons';
import LocationsComponent from './Locations.Component';

const LocationsContainer = ({ history }) => {
  const { data, loading } = useQuery(GetAllParentLocations, {
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <FAB
        label="Add location"
        icon={FloatingActionNewLocation}
        onClick={() => {
          history.push('/location/new');
        }}
      />
      <LocationsComponent
        data={(data?.storedlocations) || []}
        loading={loading}
      />
    </>
  );
};

export default withMainLayout('Locations', {}, withRouter(LocationsContainer));
