import gql from "graphql-tag";

export default gql`
  query GetAllEvents {
    events {
      id
      name
      start
      end
      type
      inChargeName
      inChargeEmail
      inChargeMobile
      client
      archived
      country
      status
    }
  }
`;
