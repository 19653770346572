import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import React from 'react';
import auth from '../utils/auth';

const endpoint =
  process.env.REACT_APP_GQL_ENDPOINT || ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/graphql");
const appCache = new InMemoryCache();
const client = new ApolloClient({
  uri: endpoint,
  cache: appCache,
  request: async (operation) => {
    const token = JSON.parse(localStorage.getItem('jwtToken'));
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
  onError: ({ graphQLErrors, networkError, response, operation }) => {
    let invalidToken = false;
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(`[GQL ERROR]: ${message}`);
        if (message.indexOf('Invalid token') > -1) {
          invalidToken = true;
        }
        return null;
      });
    }

    if (networkError) console.error(`[NETWORK ERROR]: ${networkError}`);
    if (invalidToken) {
      auth.clearAppStorage();
      window.location.reload();
    }
  },
});

class Default extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
    );
  }
}

export default Default;
