import React from "react";
import { Box, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import FormSection from "components_v2/Inputs/FormSection/FormSection";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import TextAreaInput from "components_v2/Inputs/TextAreaInput/TextAreaInput";
import CheckboxInput from "components_v2/Inputs/CheckboxInput/CheckboxInput";
import TimepickerInput from "components_v2/Inputs/TimepickerInput/TimepickerInput";
import SupplierSelect from "components_v2/Inputs/SupplierSelect/SupplierSelect";
import RadioGroupInput from 'components_v2/Inputs/RadioGroupInput/RadioGroupInput';
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import { NumberInput } from "components_v2/Inputs/NumberInput/NumberInput";
import LanguageSelect from "components_v2/Inputs/LanguageSelect/LanguageSelect";
import PhoneInput from "components_v2/Inputs/PhoneInput/PhoneInput";
import Button from "components_v2/Button/Button";
import { Add } from "components_v2/Icons/Icons";
import Typography from "components_v2/Typography/Typography";
import DocumentUpload from "components_v2/Upload/UploadDocument";
import UploadImage from "components_v2/Upload/UploadImage/UploadImage";
import TotalErrors from "components_v2/Inputs/TotalErrors/TotalErrors";
import ToggleSwitchButton from "components_v2/Inputs/ToggleSwitchButton/ToggleSwitchButton";
import GeneratePassword from "components_v2/Inputs/GeneratePassword/GeneratePassword";
import SelectVehicles from "components_v2/ListModal/variants/SelectVehicles/SelectVehicles";
import GetAllVehiclesByEvent from "gql/Vehicles/GetAllByEvent";
import GetAllVehiclesBySupplier from "gql/Vehicles/GetAllBySupplier";
import { createLocationWithState } from "utils/routerHelpers";
import withFormState from "containers/Wrappers/withFormState";

const AddDriverForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  classes,
  isUpdate,
  projectId,
  supplierId,
  location,
  history,
  setFormState
}) => {
  const handleSupplierChange = ({ target }) => {
    const { name, value } = target;
    setFieldValue(name, value?.id);
  };

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" }
  ];

  const query = projectId ? GetAllVehiclesByEvent : GetAllVehiclesBySupplier;
  const queryOptions = {
    variables: {
      id: projectId || supplierId,
      filterKey: ""
    },
    fetchPolicy: "no-cache"
  };

  const customHandleDelete = setSelectedItems => row => {
    const newVehicles = values.driverVehicles.filter(
      vehicle => vehicle.id !== row.id
    );
    const newVehicleIds = newVehicles.map(vehicle => vehicle.id);

    setFieldValue("driverVehicles", newVehicles);
    setSelectedItems(newVehicleIds);
  };

  const handleNewVehicleClick = () => {
    const getVehicleFormUrl = (projectId, supplierId) => {
      const endRoute = "vehicle/new";
      if (projectId) return `/project/${projectId}/${endRoute}`;
      else if (supplierId) return `/supplier/${supplierId}/${endRoute}`;
      return `/${endRoute}`;
    };

    const url = getVehicleFormUrl(projectId, supplierId);

    const { pathname, state } = createLocationWithState(
      url,
      location,
      values,
      null,
      true,
      "driverVehicles"
    );

    setFormState(state);
    history.push({ pathname, state: { hasState: true } });
  };

  const renderBreaks = () =>
    _.map(values.breaks, function(driverBreak, index) {
      const handleBreakInputChange = e => {
        const newBreak = { ...driverBreak, [e.target.name]: e.target.value };
        const newBreaks = [...values.breaks];
        newBreaks[index] = newBreak;

        setFieldValue("breaks", newBreaks);
      };

      const handleDeleteBreak = () => {
        const newBreaks = values.breaks.filter(
          (driverBreak, driverBreakIndex) => index !== driverBreakIndex
        );
        setFieldValue("breaks", newBreaks);
      };

      return (
        <>
          <Grid className={classes.marginBot} container spacing={3}>
            <Grid item xs={12}>
              <Typography customVariant="bodySmallRegular">
                <strong>{`Break ${index + 1}`}</strong>
              </Typography>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TimepickerInput
                name="startBreak"
                label="Break start time"
                placeholder="Break start time"
                value={driverBreak.startBreak}
                onChange={handleBreakInputChange}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TimepickerInput
                name="endBreak"
                label="Break end time"
                placeholder="Break finish time"
                value={driverBreak.endBreak}
                onChange={handleBreakInputChange}
              />
            </Grid>
          </Grid>
          <Grid className={classes.marginBot} container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <SelectInput
                name="breakType"
                label="Break type"
                placeholder="Select break type"
                value={driverBreak.breakType}
                onChange={handleBreakInputChange}
                options={[
                  { name: "Prayer", value: "prayer" },
                  { name: "Lunch", value: "lunch" },
                  { name: "Other", value: "other" }
                ]}
              />
            </Grid>
            {index !== 0 && (
              <Grid item xs={12}>
                <Button
                  style={{ padding: 0 }}
                  variant="simple"
                  onClick={handleDeleteBreak}
                >
                  Delete break
                </Button>
              </Grid>
            )}
            {values.breaks.length > 1 && (
              <Grid item xs={12}>
                <Box
                  style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "#666",
                    margin: "16px 0"
                  }}
                />
              </Grid>
            )}
          </Grid>
        </>
      );
    });

  const handleAddBreak = () => {
    const newBreaks = [
      ...values.breaks,
      { type: null, startBreak: null, endBreak: null }
    ];
    setFieldValue("breaks", newBreaks);
  };

  return (
    <>
      <FormSection defaultExpanded title="Enter the driver details">
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <SupplierSelect
              value={values.supplier}
              label="Supplier *"
              placeholder="Select supplier"
              name="supplier"
              onChange={handleSupplierChange}
              error={touched.supplier && errors.supplier}
              values={values}
              returnField="supplier"
            />
          </Grid>
          <Grid className={classes.toggleRow} item md={4} sm={6} xs={12}>
            <ToggleSwitchButton
              name="confirmed"
              label="Enabled"
              value={values.confirmed}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={12} xs={12}>
            <TextInput
              name="identifier"
              label="Identifier"
              placeholder="Enter driver identifier"
              value={values.identifier}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12} className={classes.genderContainer}>
            <RadioGroupInput
              name="gender"
              label="Gender"
              value={values.gender}
              onChange={handleChange}
              options={genderOptions}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="firstName"
              label="First name *"
              placeholder="Enter first name"
              value={values.firstName}
              onChange={handleChange}
              error={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="lastName"
              label="Last name *"
              placeholder="Enter last name"
              value={values.lastName}
              onChange={handleChange}
              error={touched.lastName && errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <PhoneInput
              name="phone"
              label="Phone number"
              placeholder="Please enter your phone number"
              value={values.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <PhoneInput
              name="whatsapp"
              label="WhatsApp"
              placeholder="Please enter your WhatsApp number"
              value={values.whatsapp}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="email"
              label="Email *"
              placeholder="Enter email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
            />
          </Grid>
        </Grid>

        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="nationality"
              label="Nationality"
              placeholder="Enter nationality"
              value={values.nationality}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="licenseNumber"
              label="License number"
              placeholder="Enter license number"
              value={values.licenseNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="passportNumber"
              label="Passport number"
              placeholder="Enter passport number"
              value={values.passportNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="visaNumber"
              label="Visa number"
              placeholder="Enter visa number"
              value={values.visaNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid className={classes.marginBotLarge} container spacing={3}>
          <Grid className={classes.photoRow} item md={4} sm={6} xs={12}>
            <Typography
              style={{ marginBottom: 8, color: "#3C3C3C" }}
              customVariant="bodySmallRegular"
            >
              Upload photo of driver
            </Typography>
            <UploadImage
              placeholder="Add photo of driver"
              defaultPreviewImage={values.profileImage}
              onComplete={data => {
                setFieldValue("profileImage", data.url);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              style={{ marginBottom: 8, color: "#3C3C3C" }}
              customVariant="bodySmallRegular"
            >
              Languages spoken
            </Typography>
            <LanguageSelect
              name="languages"
              label="Language"
              placeholder="Please select languages"
              value={values.languages}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ marginBottom: 8, color: "#3C3C3C" }}
              customVariant="bodySmallRegular"
            >
              Will this driver be public? (Enabling this will also automatically
              make all of their assigned vehicles public.)
            </Typography>
            <CheckboxInput
              name="isPublicDriver"
              label="Make driver available in other projects"
              value={values.isPublicDriver}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Assigned vehicles">
        <Grid container spacing={3}>
          {!isUpdate && (
            <Grid item md={4} sm={6} xs={12}>
              <CheckboxInput
                label="Auto create and link"
                name="autoCreateAndLinkVehicle"
                value={values.autoCreateAndLinkVehicle}
                onChange={() => {
                  setFieldValue(
                    "autoCreateAndLinkVehicle",
                    !values.autoCreateAndLinkVehicle
                  );
                }}
              />
            </Grid>
          )}
          {!values?.autoCreateAndLinkVehicle && (
            <Grid item xs={12}>
              <SelectVehicles
                values={values}
                customHandleDelete={customHandleDelete}
                error={touched.driverVehicles && errors.driverVehicles}
                projectId={projectId}
                onDone={vehicles => {
                  setFieldValue("driverVehicles", vehicles);
                }}
                query={query}
                queryOptions={queryOptions}
                handleNewClick={handleNewVehicleClick}
                incomingItems={
                  values.driverVehicles?.map(vehicle => vehicle.id) || []
                }
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
      <FormSection title="Configure driver password">
        {isUpdate && (
          <>
            <ToggleSwitchButton
              name="updatePassword"
              label="Reset password"
              value={values.updatePassword}
              onChange={handleChange}
            />
            {values.updatePassword && <Box style={{ marginBottom: 32 }} />}
          </>
        )}
        {!isUpdate || (isUpdate && values.updatePassword) ? (
          <GeneratePassword
            title="Configure driver password"
            handleChange={handleChange}
            values={values}
            gridContainerClass={classes.marginBot}
            touched={touched}
            errors={errors}
          />
        ) : null}
      </FormSection>

      <FormSection title="Enter driver work schedule">
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <NumberInput
              name="shift.allowedHoursPerDay"
              label="Allowed hours per day"
              placeholder="Allowed hours per day"
              value={values.shift.allowedHoursPerDay}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TimepickerInput
              name="shift.shiftStart"
              label="Shift start time"
              placeholder="Start shift"
              value={values.shift.shiftStart}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TimepickerInput
              name="shift.shiftEnd"
              label="Shift end time"
              placeholder="Finish shift"
              value={values.shift.shiftEnd}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              style={{ marginBottom: 8, color: "#3C3C3C" }}
              customVariant="bodySmallRegular"
            >
              Days working
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <CheckboxInput
                label="Sunday"
                value={values.shift.sunday}
                name="shift.sunday"
                onChange={handleChange}
              />
              <CheckboxInput
                label="Monday"
                value={values.shift.monday}
                name="shift.monday"
                onChange={handleChange}
              />
              <CheckboxInput
                label="Tuesday"
                value={values.shift.tuesday}
                name="shift.tuesday"
                onChange={handleChange}
              />
              <CheckboxInput
                label="Wednesday"
                value={values.shift.wednesday}
                name="shift.wednesday"
                onChange={handleChange}
              />
              <CheckboxInput
                label="Thursday"
                value={values.shift.thursday}
                name="shift.thursday"
                onChange={handleChange}
              />
              <CheckboxInput
                label="Friday"
                value={values.shift.friday}
                name="shift.friday"
                onChange={handleChange}
              />
              <CheckboxInput
                label="Saturday"
                value={values.shift.saturday}
                name="shift.saturday"
                onChange={handleChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          className={classes.marginBot}
          alignItems="flex-end"
          container
          spacing={3}
        >
          <Grid item md={4} sm={6} xs={12}>
            <Typography
              style={{ marginBottom: 0, color: "#3C3C3C" }}
              customVariant="bodySmallRegular"
            >
              Break times
            </Typography>
          </Grid>
        </Grid>
        {renderBreaks()}
        <Grid className={classes.marginBot} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <Button
              onClick={handleAddBreak}
              startIcon={<Add />}
              variant="contained"
              secondary
            >
              Add another break time
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextAreaInput
              name="notes"
              label="Notes"
              placeholder="Notes"
              value={values.notes}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection title="Upload any supporting documents">
        <DocumentUpload
          documents={values.documents}
          onDeleteCallback={document => {
            if (document.identifier) setFieldValue(document.identifier, null);
            else {
              const newDocuments = values.documents.filter(
                loadedDocument => loadedDocument.id !== document.id
              );
              setFieldValue("documents", newDocuments);
            }
          }}
          onComplete={async document => {
            setFieldValue(document.identifier, document);
          }}
        />
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default withFormState(withRouter(AddDriverForm));
