import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import DynamicList from 'components_v2/List/DynamicList';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileViewBreakpoint } from '../../App.helper';
import { getMobileMenuItems } from './helpers';


const ListModalResults = (props) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const {
    data,
    selectedItems,
    setSelectedItems,
    customHandleDelete,
    handleUpdateForm,
    readOnlyResult,
  } = props;

  const defaultHandleDelete = (row) => {
    const newItems = selectedItems.filter((item) => item !== row.id);
    if (handleUpdateForm && typeof handleUpdateForm === 'function')
      handleUpdateForm(newItems);
    setSelectedItems(newItems);

  };

  return (
    <DynamicList
      {...props}
      isMobileView={isMobileView}
      moduleTable
      hideEmptyString
      detailPanel={props.detailPanel}
      actions={{
        delete: readOnlyResult
          ? undefined
          : {
            onClick: customHandleDelete || defaultHandleDelete,
          },
      }}
      data={data}
      title={undefined}
      getMobileMenuItems={(row) => getMobileMenuItems({ row })}
    />
  );
};

export default ListModalResults;
