import gql from "graphql-tag";

export default gql`
  query GetAllLocationUpdates($id: ID) {
    locations(sort: "created_at:desc", limit: 100, where: { driver: $id }) {
      id
      lat
      long
    }
  }
`;
