import gql from "graphql-tag";

const CREATE_GQL = gql`
  mutation Create($data: DriveratingInput) {
    createDriverating(input: { data: $data }) {
      driverating {
        id
      }
    }
  }
`;

export default CREATE_GQL;
