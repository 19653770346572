import React, { useState, useEffect } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import Typography from 'components_v2/Typography/Typography';
import { formatTimeScaleDate } from '../helpers';
import useStyles from './TimeIndicator.style';

const TimeIndicator = ({ startDate, endDate, eventTimeZone }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [top, setTop] = useState(0);

  useEffect(() => {
    const currentTop = (parseInt(moment(currentDate).format('m')) / 60) * 100;

    setTop(currentTop);
  }, [currentDate]);

  const classes = useStyles({ top });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const startDateTime = moment(startDate).format('YYYY-MM-DD:HH:mm:ss');
  const endDateTime = moment(endDate).format('YYYY-MM-DD:HH:mm:ss');
  const currentDateTime =
    eventTimeZone &&
    momentTimezone(currentDate)
      .tz(eventTimeZone || undefined)
      .format('YYYY-MM-DD:HH:mm:ss');

  if (currentDateTime >= startDateTime && currentDateTime < endDateTime) {
    return (
      <div className={classes.timeIndicatorContainer}>
        <div className={classes.timeIndicatorLine}>
          <div className={classes.timeIndicatorTime}>
            <Typography
              style={{ color: '#FCFCFD' }}
              customVariant="bodySmallBold"
            >
              {formatTimeScaleDate(moment(currentDateTime))}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default TimeIndicator;
