import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../../App.helper";
import { Clear } from "../../Icons/Icons";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      justifyContent: "flex-end"
    },
  },
  closeIconContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    display: "inline-flex",
    borderRadius: "50%",
    padding: "6px",
    color: "#000000",
    marginLeft: "10px",
  },
}));

export const Header = ({ isDispatchPage, isSchedulePage, isTrackRide, onClose, handleClose }) => {
  const classes = useStyles({ isDispatchPage, isSchedulePage, isTrackRide });

  return (isDispatchPage || isSchedulePage || isTrackRide) ? (
    <header className={classes.header}>
      <IconButton
        disableRipple
        className={classes.closeIconContainer}
        onClick={onClose || handleClose}
      >
        <Clear className={classes.closeIcon} />
      </IconButton>
    </header>
  ) : <></>
}
