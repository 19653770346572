import gql from "graphql-tag";

export default gql`
  query GetAllEventsByCurrentUser($id: ID!) {
    user(id: $id) {
      id
      userEvents {
        id
        event {
          id
          name
          start
          end
          inChargeName
          inChargeEmail
          inChargeMobile
          client
          archived
          status
          alerts {
            id
            status
            description
            created_at
            event {
              id
              name
            }
          }
        }
        role
        user {
          id
          isSuperAdmin
        }
        accessRight {
          id
          name
        }
      }
    }
  }
`;
