import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  dayScaleCell: {
    cursor: 'pointer',
    textAlign: 'center !important',
    '& p': {
      display: 'none',
    },
  },
  dayScaleCellToday: {
    cursor: 'pointer',
    textAlign: 'center !important',
    '& p': {
      display: 'none',
    },
    '&>div>div': {
      fontWeight: 'bold !important',
      fontSize: '14px',
      lineHeight: '134.3%',
      letterSpacing: '0.3px',
    },
  },
}));
