import gql from "graphql-tag";

export default gql`
  query GetAllDriverServicesById($id: ID!) {
    driverongoingtrackings(where: { driver: $id }) {
      id
      description
      dateOfService
      documents
      type
      drivertrackingtype {
        name
      }
      cost
      driver {
        id
      }
    }
  }
`;
