import React from "react";
import { withRouter } from "react-router-dom";
import ProjectFolder from "components_v2/ProjectFolder/ProjectFolder";

const CurrentProjectSelector = ({
  eventId,
  eventData = [],
  id,
  onLeaveProject,
  onArchiveProject
}) => (
  <div style={{ display: "flex" }} id={id}>
    {eventData.map(
      (event, index) =>
        (event.id === eventId || event.status !== "Archived") && (
          <div key={index} style={{ marginRight: 16 }}>
            <ProjectFolder
              avatar={null}
              id={event.id}
              name={event.name}
              userEventId={event.userEventId}
              alerts={event.alerts}
              onLeaveProject={onLeaveProject}
              onArchiveProject={onArchiveProject}
            />
          </div>
        )
    )}
  </div>
);

export default withRouter(CurrentProjectSelector);
