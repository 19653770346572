import gql from "graphql-tag";

export default gql`
  query GetAccessRight($id: ID!) {
    accessrights(where: { id: $id }) {
      id
      name
      permissions
    }
  }
`;
