import gql from 'graphql-tag';

export default gql`
  mutation CreateTransferservice($data: TransferserviceInput) {
    createTransferservice(input: { data: $data }) {
      transferservice {
        id
      }
    }
  }
`;