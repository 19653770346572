import gql from 'graphql-tag';

export default gql`
  mutation CreateVehicle($data: VehicleInput) {
    createVehicle(input: { data: $data }) {
      vehicle {
        id
      }
    }
  }
`;
