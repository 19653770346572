export const mapObject = (object, fn) => {
  const mapped = {};

  const keys = Object.keys(object);

  for (const key of keys) {
    const value = object[key];
    mapped[key] = fn(key, value);
  }

  return mapped;
};