import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 12,
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  photoRow: {
    '& > div': {
      height: 300,
    },
  },
}));

export default useStyles;
