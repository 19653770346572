/* eslint-disable react/display-name */
import { withStyles, useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import DynamicList from "components_v2/List/DynamicList";
import BookingDetail from "components_v2/Bookings/BookingDetail.Component";
import ToggleIconButton from "components_v2/Inputs/ToggleIconButton/ToggleIconButton";
import Map from "components_v2/Map/Map";
import moment from "moment";
import DriverActions from "components_v2/DriverActions/DriverActions";
import ReactResizeDetector from "react-resize-detector";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { calculateCost } from "utils/csvHelpers";
import _ from "lodash";
import { getHamburgerMenuItems, getMobileMenuItems } from "./BookingConfig";
import { MenuPointer, List } from "../Icons/Icons";
import ViewDocuments from "./ViewDocuments/ViewDocuments";
import AssignDriverOption from "./AssignDriver/AssignDriverOption";

import {
  getStatusColor,
  getVehiclesDetails,
  getDriverDetails,
  getNoResultsText,
  getNumberOfVehicles,
  getColumns,
  getOpenTab,
  getIsMapView,
  getRideStatuesConfirmed
} from "./BookingHelper";
import { mobileViewBreakpoint } from "../../App.helper";

export const toggleIconBasedOptions = [
  { title: "Map view", value: "map", icon: MenuPointer },
  { title: "List view", value: "list", icon: List }
];

const BookingComponent = ({
  updatingBookingRecords,
  history,
  data,
  loading,
  type,
  withMapView,
  isMapStatic,
  totalRows,
  projectId,
  isTimezoneVisible,
  isHelpTutorial,
  refetchPassengerSchedules,
  updateDriverSchedule,
  initialLoading,
  onDelete,
  onOvertimeLoading,
  onDeleteLoading,
  onCancelLoading,
  onAssignDriverLoading,
  onSendEmailLoading,
  onSendDriverLinkLoading,
  listConfig,
  setListConfig
}) => {
  const [openTab, setOpenTab] = useState("list");
  const [updatingBookings, setUpdatingBookings] = useState([]);


  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const onReassignDriverDone = async (vehicles, row) => {
    let vehicle = null;
    let driver = null;

    if (vehicles?.length) {
      driver = getDriverDetails({ vehicles });
      vehicle = getVehiclesDetails({ vehicles });
    }
    // Update driver schedule
    await updateDriverSchedule({
      variables: {
        id: { id: row.id },
        data: {
          driver,
          vehicle
        }
      }
    });
    // Refetch bookings
    // await refetchPassengerSchedules();
  };

  const handleListConfigChange = listConfig => {
    setListConfig(listConfig);
  };

  const finalData = data.map(x => {
    // add driver confirmed if it doesn't exist

    if (getRideStatuesConfirmed({ x })) {
      return x;
    } else if (x?.ridestatuses?.length === 0) {
      return x;
    }

    var newRideStatuses = [
      { status: "driverConfirmed" },
      ...(x?.ridestatuses || [])
    ];

    const uniqueRideStatuses = Array.from(
      new Set(newRideStatuses.map(a => a.status))
    ).map(status => {
      return newRideStatuses.find(a => a.status === status);
    });

    var newObject = {
      ...x,
      ridestatuses: uniqueRideStatuses
    };

    return newObject;
  });

  return (
    <>
      {/* <ViewDocuments  /> */}
      <Grid
        id="booking-component"
        container
        style={{ flexDirection: isMapStatic ? "row" : "column" }}
      >
        <Grid
          item
          style={
            (isMapStatic && {
              width: !isMobileView ? "calc(100% - calc(100% - 430px))" : "100%"
            }) ||
            {}
          }
        >
          {openTab === 'map' ? (
            <>
              {withMapView && !isMapStatic && (
                <div style={{ marginBottom: 16 }}>
                  <ToggleIconButton
                    value={openTab}
                    onChange={event => {
                      setOpenTab(event.target.value);
                    }}
                    options={toggleIconBasedOptions}
                  />
                </div>
              )}
              <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                  <Map
                    isDispatchPage
                    fullScreen
                    width={width}
                    height={height}
                    isHelpTutorial={isHelpTutorial}
                    projectId={projectId}
                  />
                )}
              </ReactResizeDetector>
            </>
          ) : (
            getOpenTab({ openTab, currentTab: "map" })
          )}
        </Grid>
        <Grid
          item
          style={
            (isMapStatic && {
              width: !isMobileView ? "calc(100% - 446px)" : "100%",
              paddingLeft: !isMobileView && "16px",
              paddingTop: isMobileView && "16px"
            }) ||
            {}
          }
        >
          {getOpenTab({ openTab, currentTab: "list" }) && (
            <DynamicList
              onListConfigChange={handleListConfigChange}
              listConfig={{ ...listConfig, count: totalRows }}
              highlightRecords={updatingBookingRecords}
              loadingRecords={updatingBookings}
              noResultsText={getNoResultsText({ type })}
              isMobileView={isMobileView}
              showHeader
              data={finalData}
              loading={loading}
              enableFreezeHeader
              headerFreezeLeftContent="Bookings"
              title={type}
              numRows={totalRows}
              filterActions={[
                {
                  type: "text",
                  onChange: () => {
                    // Placeholder until we can move filtering to BE on all lists
                  },
                  label: "Search"
                }
              ]}
              beforeFilters={
                <>
                  {" "}
                  {getIsMapView({ withMapView, isMapStatic }) && (
                    <div
                      style={{
                        marginBottom: isMobileView ? "0px" : 16,
                        marginRight: isMobileView ? 0 : "8px"
                      }}
                    >
                      <ToggleIconButton
                        value={openTab}
                        onChange={event => {
                          setOpenTab(event.target.value);
                        }}
                        options={toggleIconBasedOptions}
                      />
                    </div>
                  )}
                </>
              }
              initialLoading={initialLoading}
              exportDataFormatter={data => {
                const getBookingId = data => {
                  let { bookingId } = data;

                  if (data.cancelled) {
                    bookingId = `${bookingId} CANCELED`;
                  }

                  return bookingId;
                };

                const getVehicleClass = data => {
                  const vehicleClass = data?.requiredVehicleClass?.name;

                  const num = getNumberOfVehicles({ data });

                  return `${vehicleClass} (${num})`;
                };

                const getStops = data => {
                  let stops = "";
                  if (data?.savedStops) {
                    stops = data.savedStops;
                    // if (typeof data.stops === "string")
                    //   stops = JSON.parse(data.stops);

                    return _.join(
                      _.map(
                        stops,
                        (stop, key) => `Stop ${key + 1}: ${stop.address}`
                      ),
                      "\n"
                    );
                  }
                  return stops;
                };

                const getDriverLanguages = data => {
                  const { driver } = data;
                  if (!driver) return "";

                  const { languages } = driver;
                  if (!languages?.length) return "";

                  const languagesList = JSON.parse(languages);
                  return languagesList.map(language => language?.name);
                };

                const getProducts = data => {
                  const { products } = data;
                  if (!products) return "";

                  const productList = JSON.parse(products);

                  if (!Array.isArray(productList)) return "";

                  const getProductPrice = price => {
                    if (!price) return "";
                    return ` - $${price}`;
                  };
                  return productList.map(
                    product => product?.name + getProductPrice(product?.price)
                  );
                };

                const getField = (field, fallback = "") => {
                  return data[field] || fallback;
                };

                return {
                  bookingId: getBookingId(data),
                  projectName: data?.event?.name,
                  start: moment
                    .utc(data?.start)
                    .format("MMMM D, YYYY [at] HH:mm"),
                  from: data?.from?.name,
                  to: data?.to?.name,
                  pickupDay: moment.utc(data?.start).format("YYYY-MM-DD"),
                  pickupTime: moment.utc(data?.start).format("HH:mm"),
                  passengerName: `${data?.passenger?.fullName}`,
                  passengerPhone: data?.passenger?.phone,
                  passengerEmail: data?.passenger?.email,
                  pickupAddress: data?.from?.address,
                  dropoffAddress: data?.to?.address,
                  stops: getStops(data),
                  vehicleClass: getVehicleClass(data),
                  type: data.transferservice?.name,
                  department: data?.department?.name || "",
                  notes: data.notes,
                  bookedBy: `${data?.createdBy?.fullName}`,
                  bookedByPhone: data?.createdBy?.phone || "",
                  cost: calculateCost(data),
                  numPassengers: data.numPassengers,
                  module: data?.department?.module?.name || "",
                  flightNumber: getField("flightNumber", ""),
                  terminal: getField("terminal", ""),
                  driverName: `${data?.driver?.fullName}`,
                  driverPhone: data?.driver?.phone || "",
                  driverWhatsapp: data?.driver?.whatsapp || "",
                  driverEmail: data?.driver?.email || "",
                  driverIdentifier: data?.driver?.identifier,
                  driverLicenseNumber: data?.driver?.licenseNumber || "",
                  driverNationality: data?.driver?.nationality || "",
                  driverPassportNumber: data?.driver?.passportNumber || "",
                  driverVisaNumber: data?.driver?.visaNumber || "",
                  driverLanguages: getDriverLanguages(data),
                  vehicleMake: data?.vehicle?.make || "",
                  vehicleModel: data?.vehicle?.model || "",
                  vehicleYear: data?.vehicle?.year || "",
                  vehicleColor: data?.vehicle?.color || "",
                  vehicleNumber: data?.vehicle?.vehicleNumber || "",
                  vehiclePlateNumber: data?.vehicle?.plateNumber || "",
                  productNotes: getField("productNotes", ""),
                  luggageCount: getField("luggageCount", ""),
                  products: getProducts(data),
                  discount: `${data?.rate}%`
                };
              }}
              exportHeaders={[
                { label: "Booking ID", key: "bookingId" },
                { label: "Project Name", key: "projectName" },
                { label: "Pickup Date", key: "start" },
                { label: "Pickup Day", key: "pickupDay" },
                { label: "Pickup Time", key: "pickupTime" },
                { label: "Pickup Location", key: "from" },
                { label: "Dropoff Location", key: "to" },
                { label: "Main Passenger", key: "passengerName" },
                { label: "Main Passenger Phone", key: "passengerPhone" },
                { label: "Main Passenger Email", key: "passengerEmail" },
                { label: "Total Passengers", key: "numPassengers" },
                { label: "Pickup address", key: "pickupAddress" },
                { label: "Dropoff address", key: "dropoffAddress" },
                { label: "Stops", key: "stops" },
                { label: "Flight Number", key: "flightNumber" },
                { label: "Terminal", key: "terminal" },
                { label: "Vehicle class", key: "vehicleClass" },
                { label: "Transfer Type", key: "type" },
                { label: "Department", key: "department" },
                { label: "Module", key: "module" },
                { label: "Notes", key: "notes" },
                { label: "Booked by (Name)", key: "bookedBy" },
                { label: "Booked by (Phone)", key: "bookedByPhone" },
                { label: "Cost", key: "cost" },
                { label: "Driver", key: "driverName" },
                { label: "Driver Phone", key: "driverPhone" },
                { label: "Driver Whatsapp", key: "driverWhatsapp" },
                { label: "Driver Email", key: "driverEmail" },
                { label: "Driver Identifier", key: "driverIdentifier" },
                { label: "Driver License Number", key: "driverLicenseNumber" },
                { label: "Driver Nationality", key: "driverNationality" },
                {
                  label: "Driver Passport Number",
                  key: "driverPassportNumber"
                },
                { label: "Driver Visa Number", key: "driverVisaNumber" },
                { label: "Driver Languages", key: "driverLanguages" },
                { label: "Vehicle Make", key: "vehicleMake" },
                { label: "Vehicle Model", key: "vehicleModel" },
                { label: "Vehicle Year", key: "vehicleYear" },
                { label: "Vehicle Color", key: "vehicleColor" },
                { label: "Vehicle Number", key: "vehicleNumber" },
                { label: "Vehicle Plate Number", key: "vehiclePlateNumber" },
                { label: "Luggage Count", key: "luggageCount" },
                { label: "Product Notes", key: "productNotes" },
                { label: "Products", key: "products" },
                { label: "Discount", key: "discount" }
              ]}
              groupDateField="start"
              renderLeftBorder={row => `6px solid ${getStatusColor(row)}`}
              // hamburgerMenuItems={getHamburgerMenuItems(
              //   history,
              //   setConfirmationModalData
              // )}
              getHamburgerMenuItems={row =>
                getHamburgerMenuItems({
                  history,
                  onOvertimeLoading,
                  currentRow: row,
                  type,
                  onDelete,
                  onDeleteLoading,
                  onReassignDriverDone,
                  onCancelLoading,
                  onAssignDriverLoading,
                  onSendEmailLoading,
                  onSendDriverLinkLoading,
                  setUpdatingBookings
                })
              }
              getMobileMenuItems={row =>
                getMobileMenuItems({
                  history,
                  row,
                  type
                })
              }
              columns={getColumns(projectId, isTimezoneVisible, isMobileView)}
              actions={{
                primary:
                  type === "History"
                    ? {
                        onClick: () => {
                          // Callback logic here
                        },
                        label: () => "Ride Playback"
                      }
                    : {
                        render: row => {
                          if (!row.driver)
                            return (
                              <AssignDriverOption
                                isActionButton
                                data={row}
                                onAssignDriverLoading={onAssignDriverLoading}
                                setUpdatingBookings={setUpdatingBookings}
                              />
                            );

                          return null;
                        }
                      }
              }}
              actionsOverlay={(row, setOverlayOpen) => (
                <DriverActions
                  distance="22km"
                  driver={{
                    name: "Mohammed B."
                  }}
                  otherOptions={[]}
                  onAssignDriverClick={() => {}}
                  onCloseClick={() => {
                    setOverlayOpen(false);
                  }}
                />
              )}
              invisibleComponent={row => (
                <>
                  <ViewDocuments
                    bookingId={row.bookingId}
                    incomingItems={row.savedDocuments}
                    data={row.savedDocuments}
                    customButton={toggleModal => (
                      <div
                        style={{ visibility: "invisible", zIndex: -1 }}
                        id={`view-booking-documents-action-button-${row.id}`}
                        onClick={() => toggleModal()}
                      />
                    )}
                  />
                </>
              )}
              detailPanel={row => (
                <BookingDetail
                  updatingBookingRecords={updatingBookingRecords}
                  data={row}
                  isHelpTutorial={isHelpTutorial}
                  refetchPassengerSchedules={refetchPassengerSchedules}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const BasicListItem = withRouter(
  withStyles({
    root: {
      color: "rgba(255, 255, 255, 0.75)",
      paddingLeft: 10
    },
    listItemText: {
      fontSize: 10,
      color: "rgba(255, 255, 255, 0.45)",
      paddingLeft: 0,
      textTransform: "uppercase"
    },
    listItemTextRoot: { paddingLeft: "15px !important" }
  })(BookingComponent)
);

export default BasicListItem;
