import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import {
  labelBaseStyles,
  baseIconRootStyles,
  basePseudoStyles,
  disabledSimplePrimaryStyles,
  disabledSimpleSecondaryStyles,
  labelSimpleStyles,
} from './style';

export const SimpleButton = withStyles({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    '& .MuiButton-startIcon': {
      position: 'relative',
      transition: 'all 150ms ease-in-out',
      marginRight: 10,
    },
    '& .MuiButton-startIcon svg path': {
      fill: '#4001C5',
      transition: 'fill 150ms ease-in-out',
    },
    '& .MuiButton-startIcon::after': {
      ...basePseudoStyles,
    },
    '& .MuiButton-startIcon svg': {
      ...baseIconRootStyles,
    },
    '&:hover': { background: 'transparent' },
    '&:hover  .MuiButton-startIcon::after': {
      opacity: 1,
    },
    '&:hover  .MuiButton-startIcon svg path': {
      fill: '#fff',
    },
  },
  focusVisible: {
    background: 'transparent',
    '& .MuiButton-startIcon svg': {
      position: 'relative',
    },
    '& .MuiButton-startIcon svg path': {
      fill: '#FFF',
    },
    '& .MuiButton-startIcon::after': {
      opacity: 1,
    },
  },
  label: {
    ...labelBaseStyles,
    ...labelSimpleStyles,
    color: '#4001C5',
  },
  disabled: {
    ...disabledSimplePrimaryStyles,
  },
})(MuiButton);

export const SimpleButtonSecondary = withStyles({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    '& .MuiButton-startIcon': {
      position: 'relative',
      transition: 'all 150ms ease-in-out',
      marginRight: 13,
    },
    '& .MuiButton-startIcon svg path': {
      fill: '#FFF',
      transition: 'fill 150ms ease-in-out',
    },
    '& .MuiButton-startIcon::after': {
      ...basePseudoStyles,
    },
    '& .MuiButton-startIcon svg': {
      ...baseIconRootStyles,
    },
    '&:hover': { background: 'transparent' },
    '&:hover  .MuiButton-startIcon': {
      position: 'relative',
    },
    '&:hover .MuiButton-startIcon': {
      marginRight: 18,
    },
    '&:hover  .MuiButton-startIcon svg path': {
      fill: '#FFF',
    },
    '&:hover .MuiButton-startIcon::after': {
      opacity: 1,
    },
  },

  label: {
    ...labelBaseStyles,
    ...labelSimpleStyles,
    color: '#FFF',
  },
  focusVisible: {
    '& .MuiButton-startIcon::after': {
      opacity: 1,
    },
  },
  disabled: {
    ...disabledSimpleSecondaryStyles,
  },
})(MuiButton);
