import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import React from "react";

import GetOneGQL from "gql/Events/GetOne";
import SettingsComponent from "./Settings.Component.jsx";
import { mockData } from "./Settings.mock.js";

const UPDATE_GQL = gql`
  mutation UpdateEvent($id: InputID!, $data: editEventInput) {
    updateEvent(input: { where: $id, data: $data }) {
      event {
        id
      }
    }
  }
`;

const SettingsContainer = ({ projectId, isHelpTutorial }) => {
  const [save, { loading }] = useMutation(UPDATE_GQL, {
    variables: { id: { id: projectId } }
  });

  let { data } = useQuery(GetOneGQL, {
    variables: { id: projectId },
    fetchPolicy: "no-cache",
    skip: isHelpTutorial
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const initialData = data?.events[0] || {};

  return (
    <SettingsComponent
      initialData={initialData}
      projectId={projectId}
      save={save}
      isHelpTutorial={isHelpTutorial}
      mutationLoading={loading}
    />
  );
};

export default SettingsContainer;
