import React, { useState } from "react";
import GetAllByProject from "gql/Modules/GetAllByProject";
import Create from "gql/Modules/Create";
import Delete from "gql/Modules/Delete";
import Update from "gql/Modules/Update";
import { useQuery, useMutation } from "@apollo/react-hooks";
import DynamicList from "components_v2/List/DynamicList";
import { useTheme } from "@material-ui/core/styles";
import { MenuFolder } from "components_v2/Icons/Icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mockData } from "./ModuleSettingsContainer.mock";
import { getMobileMenuItems } from "./listOptions";
import { mobileViewBreakpoint } from "../../../../App.helper";
import DepartmentSettings from "../Departments/DepartmentSettings";
import { getFilteredModules, getSortedModules } from "./helper";

const ModuleSettingsContainer = ({ projectId, isHelpTutorial }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  let { data, loading, refetch } = useQuery(GetAllByProject, {
    variables: { id: projectId },
    skip: isHelpTutorial
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  const modules = data ? data.modules || [] : [];

  const [deleteModule] = useMutation(Delete);

  const [updateModule] = useMutation(Update);

  const [createModule] = useMutation(Create);

  const [filterKey, setFilterKey] = useState("");
  const [sort, setSort] = useState(null);

  let finalData = getFilteredModules({ modules, filterKey });
  finalData = getSortedModules({ modules: finalData, sort });

  return (
    <DynamicList
      filterActions={[
        {
          type: "text",
          onChange: val => {
            setFilterKey(val);
          },
          label: "Search"
        }
      ]}
      sortActions={{
        currentSort: sort,
        onChangeSort: setSort
      }}
      isMobileView={isMobileView}
      title="Modules"
      noResultsText="No modules"
      showHeader
      data={finalData}
      loading={loading}
      numRows={finalData.length}
      getMobileMenuItems={row => getMobileMenuItems({ row })}
      detailPanel={row => (
        <DepartmentSettings
          departments={row?.departments || []}
          projectId={projectId}
          moduleId={row.id}
          refetchModules={refetch}
        />
      )}
      columns={[
        {
          field: "name",
          headerName: "Module Name",
          width: 3,
          render: ({ name }) => (
            <section
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <div style={{ marginRight: "20px" }}>
                <MenuFolder />
              </div>
              <div>{name}</div>
            </section>
          )
        },

        { headerName: "Description", field: "description", width: 3 }
      ]}
      actions={{
        edit: {
          onSave: async ({ id, values: { name, description } }) => {
            await updateModule({
              variables: {
                id: { id },
                data: {
                  name,
                  description,
                  event: projectId
                }
              }
            });
            await refetch();
          }
        },
        delete: {
          onClick: async ({ id }) => {
            await deleteModule({
              variables: {
                id: { id: id }
              }
            });

            await refetch();
          }
        },
        create: {
          onSave: async ({ values: { name, description } }) => {
            await createModule({
              variables: {
                data: {
                  name,
                  description,
                  event: projectId
                }
              }
            });

            await refetch();
          }
        }
      }}
    />
  );
};

export default ModuleSettingsContainer;
