import { useQuery } from '@apollo/react-hooks';
import React ,{useState}from 'react';

import GetAllByEvent from 'gql/Users/GetAll';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import UsersComponent from './Users.Component.jsx';

const UsersContainer = () => {
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })
  const { data, loading } = useQuery(GetAllByEvent, {
    variables: {...listConfig, sort: listConfig.sort || 'id:desc'},
    fetchPolicy: 'no-cache',
  });
 
  const currentData = data?.usersConnection?.values  || [];
  const count = data?.usersConnection?.aggregate?.count|| 0;
  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }
  return <UsersComponent 
  count={count}
  listConfig={listConfig}
  handleListConfigChange={handleListConfigChange}
  data={currentData  || []} loading={loading} />;
};

export default withMainLayout('Users', {}, UsersContainer);
