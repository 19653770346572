import React from 'react';
import { useQuery } from "@apollo/react-hooks";

import GetEventGQL from "gql/Events/GetOne";
import { getProjectDetailsData } from "components_v2/Views/Internal/Dashboard/DashboardHelper";
import {
  mockEventData,
} from "components_v2/Views/Internal/Dashboard/Dashboard.mock";
import ProjectDetails from "./ProjectDetails";

const ProjectDetailContainer = (props) => {
  const { projectId, isHelpTutorial } = props;

  let { data: eventData } = useQuery(GetEventGQL, {
    variables: { id: projectId },
    fetchPolicy: "no-cache",
    skip: isHelpTutorial
  });

  if (isHelpTutorial) {
    eventData = mockEventData;
  }

  const projectData = getProjectDetailsData({eventData});

  return (
    <ProjectDetails
      projectData={projectData}
      {...props}
    />
  )
}

export default ProjectDetailContainer;
