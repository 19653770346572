/* eslint-disable react/display-name */
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import DynamicListItem from "components_v2/List/DynamicListItem";
import Typography from "components_v2/Typography/Typography";
import _ from "lodash";

const BasicListBody = withRouter(
  withStyles({
    root: {
      color: "rgba(255, 255, 255, 0.75)",
      paddingLeft: 10
    }
  })(
    class extends React.Component {
      renderChildren = props => (
        <div style={{ position: 'relative' }}>
          {props.newMode && !props.groupDateField && (
            <>
              <DynamicListItem {...props} row={{}} editMode newRow />
            </>
          )}

          {!_.isEmpty(props.data) &&
            props.data.map((row, index) => (
              <DynamicListItem key={index} {...props} row={row} />
            ))}
        </div>
      );

      render() {
        const { props } = this;
        const {
          data,
          groupDateField,
          newMode,
        } = this.props;

        if (groupDateField) {
          // if data is grouped
          return (
            <>
              {newMode && (
                <div>
                  {props.newMode && (
                    <DynamicListItem {...this.props} row={{}} editMode />
                  )}
                </div>
              )}
              {Object.entries(data).map((item, index) => (
                <React.Fragment key={index}>
                  <div style={{ margin: "16px 0" }}>
                    <Typography customVariant="h5Bold">{item[0]}</Typography>
                  </div>

                  {item &&
                    item[1] &&
                    this.renderChildren({ ...props, data: item[1] })}
                </React.Fragment>
              ))}
            </>
          );
        }

        return this.renderChildren(props);
      }
    }
  )
);

export default BasicListBody;
