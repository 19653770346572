import moment from 'moment';
import React from 'react';
import copy from 'copy-to-clipboard';
import Typography from 'components_v2/Typography/Typography';
import {
  getProjectAbsoluteUrl,
  getProjectRelativeUrl,
} from './ProjectList.helper';

const getLabel = (row) =>
  row?.status === 'Draft' ? 'Complete Info ' : 'View Project';

const tabButtonlabel = (selectedTab, row) => {
  switch (selectedTab) {
    case 'current':
      return getLabel(row);
    case 'history':
      return 'Archive';
    case 'archived':
      return 'Restore as Draft';
    default:
      return getLabel(row);
  }
};

const getNavigation = (row, history) =>
  row?.status === 'Draft'
    ? history.push(`/project/${row.id}/edit`)
    : history.push(`/project/${row.id}`);

const tabButtonNavigation = (
  row,
  history,
  selectedTab,
  onArchiveClick,
  onRestoreClick
) => {
  switch (selectedTab) {
    case 'upcoming':
      return getNavigation(row, history);
    case 'current':
      return getNavigation(row, history);
    case 'history':
      onArchiveClick(row, 'Archived');
      break;
    case 'archived':
      onRestoreClick(row, 'Draft');
      break;

    default:
      return '';
  }
};

export const getMobileMenuItems = ({
  history,
  selectedTab,
  onArchiveClick,
  onRestoreClick,
  row }
) => ({
  right: [
    {
      text: 'Duplicate',
      onClick: () => { },
      className: 'mobile-actions-duplicate',
    },
    {
      text: 'Copy Link',
      onClick: () => {
        copy(getProjectAbsoluteUrl(row.id));
      },
      className: 'mobile-actions-copy-link',
    },
    {
      text: tabButtonlabel(selectedTab, row),
      onClick: () =>
        tabButtonNavigation(
          row,
          history,
          selectedTab,
          onArchiveClick,
          onRestoreClick
        ),

      className: 'mobile-actions-primary-actions',
    },
    {
      text: ' ',
      onClick: () => history.push(`/project/${row.id}/edit`),

      className: 'mobile-actions-edit',
    },
  ],
});
export const getHamburgerMenuItems = ({ history }) => [
  {
    label: 'Edit',
    onClick: (row) => history.push(`/project/${row.id}/edit`),
  },
  {
    label: 'Duplicate',
    onClick: () => { },
  },
  {
    label: 'Share',
    onClick: (row) => history.push(`/trackride/${row.id}`),
  },
  {
    label: 'Copy Link',
    onClick: (row) => {
      copy(getProjectAbsoluteUrl(row.id));
    },
  },
];

export const getColumns = () => [
  {
    field: 'start',
    headerName: 'Starting date',
    type: 'time',
    width: 2,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">
        {moment(data.start).format('DD/MM/YYYY')}
      </Typography>
    ),
  },

  {
    field: 'name',
    headerName: 'Project name',
    width: 3,
    render: (data) => (
      <Typography customVariant="bodySmallBold">{data.name}</Typography>
    ),
  },
  {
    field: 'country',
    headerName: 'Location',
    width: 2,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 3,
    hideMobile: true,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">{data.status}</Typography>
    ),
  },
];

export const getActions = (
  onProjectRowClick,
  history,
  selectedTab,
  onArchiveClick,
  onRestoreClick
) => ({
  rowClick: (row) => {
    onProjectRowClick(getProjectRelativeUrl(row.id));
  },

  primary: {
    onClick: (row) =>
      tabButtonNavigation(
        row,
        history,
        selectedTab,
        onArchiveClick,
        onRestoreClick
      ),

    label: (row) => tabButtonlabel(selectedTab, row),
  },
});
