import React from 'react';
import {
  withStyles,
} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Switch from '@material-ui/core/Switch';
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';

const StyledSwitch = withStyles({
  switchBase: {
    color: '#4001C5',
    '& + $track': {
      backgroundColor: 'white',
      boxShadow:
        '0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)',
      border: 'none',
      opacity: 1,
    },
    '& .MuiIconButton-label > .MuiSwitch-thumb': {
      backgroundColor: '#4001C5',
      opacity: 1,
    },
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: '#4001C5',
      opacity: 1,
    },
    '&$checked .MuiIconButton-label > .MuiSwitch-thumb': {
      backgroundColor: 'white',
      opacity: 1,
    },
    '&$disabled + $track': {
      backgroundColor: 'rgba(0, 0, 0, 0.2);',
      opacity: 1,
    },
    '&$disabled .MuiIconButton-label > .MuiSwitch-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      opacity: 1,
    },
  },
  checked: {},
  track: {},
  disabled: {},
})(Switch);

const ToggleSwitchButton = ({
  name,
  value,
  onChange,
  label,
  isDisabled,
}) => {
  const lovelyStyles = useLovelySwitchStyles();

  return (
    <>
      <FormControlLabel
        control={
          <StyledSwitch
            disabled={isDisabled}
            classes={lovelyStyles}
            checked={value}
            onChange={
              // TODO: NEED TO REVISIT
              (event) =>
                onChange({
                  persist: () => { },
                  target: {
                    type: 'change',
                    name,
                    value: event.target.checked,
                  },
                })
            }
          />
        }
        label={label}
      />
    </>
  );
};

export default ToggleSwitchButton;
