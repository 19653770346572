import gql from "graphql-tag";

export default gql`
  mutation updateDriverschedule($id: InputID, $data: editDriverscheduleInput) {
    updateDriverschedule(input: { where: $id, data: $data }) {
      driverschedule {
        id
        cancelled
        alerts {
          id
          type
          description
          status
        }
        vehicle {
          id
          vehicleNumber
          make
          model
          year
          plateNumber
        }
        driver {
          id
          firstName
          lastName
          identifier
          phone
        }
        ridestatuses {
          id
          status
          created_at
          location
        }
        unconfirmedAlertSent
        overtimeHours
        waitingTime
      }
    }
  }
`;
