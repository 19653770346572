import React from 'react';
import find from 'lodash/find';

import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import timezones from 'utils/timezones';

const TimezoneSelect = (props) => {
  const { value } = props;

  const options = timezones.map((timezone) => ({
    name: timezone.formatted,
    formatted: timezone.formatted,
    value: timezone.name,
  }));

  const valueObject = find(options, { value });

  return <SelectInput {...props} value={valueObject} options={options} />;
};

export default TimezoneSelect;
