import gql from "graphql-tag";

export default gql`
  query GetAllDrivers(
    $id: ID
    $sort: String
    $pageSize: Int
    $startIndex: Int
    $filterKey: String
  ) {
    usersConnection(
      where: {
        role: 1
        event: $id
        _or: [
          { firstName_contains: $filterKey }
          { lastName_contains: $filterKey }
          { phone_contains: $filterKey }
          { company_contains: $filterKey }
          { passengerPriority_contains: $filterKey }
        ]
      }
      limit: $pageSize
      start: $startIndex
      sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        email
        firstName
        lastName
        fullName
        phone
        confirmed
        company
        identifier
        username
        notes
        passengerPriority
        arrivalFlightNumber
        arrivalDate
        departureFlightNumber
        departureDate
        # hotel
        role {
          id
          name
        }
      }
    }
  }
`;
