import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import GetOneGQL from 'gql/DriverSchedules/GetOne';
import TrackRide from './TrackRide.Component';
import useChat from "hooks/useChat";
import GetAllUpcomingByProject from '../../../Bookings/Gql/GetAllUpcomingByProject';
import _ from "lodash";
import { generateRidesListNew } from '../../../Bookings/helpers';
import withPublicLayout from '../../../Layout/PublicLayout/withPublicLayout';

const TrackRideContainer = ({ match, history }) => {
  const { rideId } = match.params;
  const [localData, setLocalData] = useState({});

  const getParsedData = (data) => {
    return {
      ...data.driverschedule,
      passengerScheduleId: data.driverschedule.id,
      driver: data.driverschedule.driver,
      ridestatuses: data.driverschedule.ridestatuses,
      passengerScheduleCancelled: data.driverschedule.passengerschedule.cancelled,
      driverScheduleCancelled: data.driverschedule.cancelled
    }
  }


  const { data } = useQuery(GetOneGQL, {
    variables: { id: rideId },
    fetchPolicy: 'no-cache',
  });

  // lazy query for updating schedules
  const [
    getNewDriverSchedules,
    { loading: bookingUpdatesLoading , data: lazyData }
  ] = useLazyQuery(GetAllUpcomingByProject, { fetchPolicy: "no-cache" });

  useChat(
    "bookingUpdates",
    async ({ driverSchedules }) => {
      await getNewDriverSchedules({
        variables: {
          driverScheduleIds: driverSchedules,
          limit: driverSchedules.length
        }
      });
    }
  );

  useEffect(() => {
    if (data) setLocalData(getParsedData(data));
  }, [data])


  useEffect(() => {
    if (!_.isEmpty(lazyData)) {
      const lazyLoadedPassengerSchedules = generateRidesListNew(lazyData?.allBookings?.bookings)
      setLocalData(lazyLoadedPassengerSchedules[0])
    }
  }, [lazyData])

  return !_.isEmpty(localData) && <TrackRide bookingUpdatesLoading ={bookingUpdatesLoading } history={history} driverSchedule={localData} />;
};

TrackRideContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      rideId: PropTypes.string.isRequired,
    }),
  }),
};

export default withPublicLayout({ noAuthLinks: true, fullWidth: true, noPadding: true }, withRouter(TrackRideContainer));