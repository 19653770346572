export const mockGetAllByEvent = {
  events: [
    {
      id: "5f270baecc54999de0a9f579",
      name: "Vehicle Assignment",
      start: "2020-06-18",
      end: "2020-07-09",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f2711db7442154fd8a8c137",
      name: "Blaz's Event",
      start: "2020-08-12",
      end: "2020-08-20",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f2b0ee05a54d26dac43e83b",
      name: "New Event #2",
      start: "2020-08-05",
      end: "2020-08-19",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f2b1076d25205904c254b24",
      name: "EVENT #3",
      start: "2020-08-05",
      end: "2020-08-26",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f2b10c7cb1ab2bfec15bc82",
      name: "#4",
      start: "2020-08-05",
      end: "2020-08-20",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f2b11efcb1ab2bfec15bc89",
      name: "#5",
      start: "2020-08-05",
      end: "2020-08-19",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f3182e1c5c5fd1cb45844dc",
      name: "Test #5",
      start: "2020-08-10",
      end: "2020-08-24",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f3185d764951b20b0fb4dd5",
      name: "#6",
      start: "2020-08-10",
      end: "2020-08-24",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f35af613825eee594a1df24",
      name: "TEST #10",
      start: "2020-08-19",
      end: "2020-08-27",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f4e1005483e1f0017fde980",
      name: "Natalie Schwalb",
      start: "2020-09-19",
      end: "2020-09-30",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f4f5516ab4f8e0017302057",
      name: "Natalie Schwalb",
      start: "2020-09-11",
      end: "2020-09-30",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f5df3ceaccf370017bfc77f",
      name: "ghet",
      start: "2020-09-18",
      end: "2020-09-30",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f5df418accf370017bfc787",
      name: " fdbfdas",
      start: "2020-09-19",
      end: "2020-09-30",
      inChargeName: "cea",
      inChargeEmail: "name@name.com",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f6238ec973f8d3c9480cd2d",
      name: "",
      start: null,
      end: null,
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f688a8b7d1c4400175e9257",
      name: "NBA Kigali Sight Inspection",
      start: "2020-09-21",
      end: "2020-09-02",
      inChargeName: "Kim Gibbens",
      inChargeEmail: "Kimg@motusone.com",
      inChargeMobile: "+27607895119",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f688a8c7d1c4400175e9258",
      name: "NBA Kigali Sight Inspection",
      start: "2020-09-21",
      end: "2020-09-02",
      inChargeName: "Kim Gibbens",
      inChargeEmail: "Kimg@motusone.com",
      inChargeMobile: "+27607895119",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f6b0ad91ac96c0017c33b64",
      name: "vgrw",
      start: "2020-09-26",
      end: "2020-09-29",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f78f25be57eac0017f8a613",
      name: "This is a project that is in progress",
      start: "2020-10-01",
      end: "2020-11-19",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f78f276e57eac0017f8a61b",
      name: "This project is upcoming soon",
      start: "2020-10-07",
      end: "2020-10-30",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f7ef9b6e9aa7a0017a2ef97",
      name: "Benns Pharmacy & Cosmetic Centre",
      start: "2020-11-14",
      end: "2020-10-14",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f7ef9b6e9aa7a0017a2ef98",
      name: "Benns Pharmacy & Cosmetic Centre",
      start: "2020-11-14",
      end: "2020-10-14",
      inChargeName: "Kim Gibbens",
      inChargeEmail: "kimg@motusone.com",
      inChargeMobile: "+27607895119",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f82b3e988f776001710c7c6",
      name: "The Saudi Invertment Bank Event",
      start: "2020-10-07",
      end: "2020-10-15",
      inChargeName: "ADNAN KHALDI ",
      inChargeEmail: "adnank@motusone.com",
      inChargeMobile: "+962786466218",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f82b3e988f776001710c7c5",
      name: "The Saudi Invertment Bank Event",
      start: "2020-10-07",
      end: "2020-10-15",
      inChargeName: "ADNAN KHALDI ",
      inChargeEmail: "adnank@motusone.com",
      inChargeMobile: "+962786466218",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f84770109e0750017f3b1c3",
      name: "The Saudi Invertment Bank Event 2",
      start: "2020-10-07",
      end: "2020-10-12",
      inChargeName: "Adnan",
      inChargeEmail: "Adnank@motusone.com",
      inChargeMobile: "+966548590590",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f84770209e0750017f3b1c4",
      name: "The Saudi Invertment Bank Event 2",
      start: "2020-10-07",
      end: "2020-10-12",
      inChargeName: "Adnan",
      inChargeEmail: "Adnank@motusone.com",
      inChargeMobile: "+966548590590",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f991b1a7e246100173c7d88",
      name: " SC Johnson",
      start: "2020-10-28",
      end: "2020-11-06",
      inChargeName: "Kim Gibbens",
      inChargeEmail: "kimg@motusone.com",
      inChargeMobile: "+27607895119",
      client: "",
      __typename: "Event",
    },
    {
      id: "5f991b1a7e246100173c7d89",
      name: " SC Johnson",
      start: "2020-10-28",
      end: "2020-11-06",
      inChargeName: "Kim Gibbens",
      inChargeEmail: "kimg@motusone.com",
      inChargeMobile: "+27607895119",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa313487de03db3e5d1afb0",
      name: "Test Create",
      start: "2020-11-03",
      end: "2020-11-11",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa3157b7de03db3e5d1afb8",
      name: "test create",
      start: "2020-11-03",
      end: "2020-11-17",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa3164d7de03db3e5d1afc0",
      name: "test",
      start: "2020-11-03",
      end: "2020-11-09",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa317537de03db3e5d1afc7",
      name: "",
      start: null,
      end: null,
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa317b27de03db3e5d1afce",
      name: "",
      start: null,
      end: null,
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa317c87de03db3e5d1afd5",
      name: "",
      start: null,
      end: null,
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa317d17de03db3e5d1afdc",
      name: "",
      start: null,
      end: null,
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa318497de03db3e5d1afea",
      name: "test",
      start: "2020-11-03",
      end: "2020-11-09",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa318ab4e9299b6d173cc9c",
      name: "Anthony J Pettigrew",
      start: "2020-11-03",
      end: "2020-11-09",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa31a1b22ae2bb856af4893",
      name: "test event",
      start: "2020-11-03",
      end: "2020-11-18",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa326fa22ae2bb856af489f",
      name: "test event",
      start: "2020-11-11",
      end: "2020-11-16",
      inChargeName: "",
      inChargeEmail: "",
      inChargeMobile: "",
      client: "",
      __typename: "Event",
    },
    {
      id: "5fa5d9d1c4e0cbdce838d558",
      name: "Blaz's test project",
      start: "2020-11-06",
      end: "2020-11-28",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fac86445cf4c92d5098f5ff",
      name: "Test project v2",
      start: "2020-11-01",
      end: "2020-12-31",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fac8ff2c7ce8a9504d04202",
      name: "Some other test",
      start: "2020-11-12",
      end: "2020-11-12",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fac90e4724471f4704bdcac",
      name: "Project #24242",
      start: "2020-11-12",
      end: "2020-11-12",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fad8846ab25f7bba82ae79d",
      name: "Module Test Project",
      start: "2020-11-03",
      end: "2020-11-03",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fad9172ab25f7bba82ae7ad",
      name: "Test Project For Modules",
      start: "2020-11-10",
      end: "2020-11-10",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fad9f57306a8f4510373025",
      name: "FIFA TEST",
      start: "2020-11-01",
      end: "2020-11-12",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fada0a9306a8f4510373035",
      name: "FIFA Final #1",
      start: "2020-11-01",
      end: "2020-11-12",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fada884ab25f7bba82ae7b7",
      name: "Test client project",
      start: "2020-11-10",
      end: "2020-11-10",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: "5fa07c19720e0b7439559929",
      __typename: "Event",
    },
    {
      id: "5fadac98ab25f7bba82ae7c0",
      name: "Birthday Bash",
      start: "2020-11-11",
      end: "2020-11-11",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: "5fa080f2826273302826d6ae",
      __typename: "Event",
    },
    {
      id: "5fadb20fab25f7bba82ae7c9",
      name: "Fyre Festival",
      start: "2020-11-11",
      end: "2020-11-11",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: "5fa080f2826273302826d6ae",
      __typename: "Event",
    },
    {
      id: "5fadb2a4ab25f7bba82ae7d2",
      name: "Fyre Festival 2.0",
      start: "2020-11-10",
      end: "2020-11-10",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: "5fa07c19720e0b7439559929",
      __typename: "Event",
    },
    {
      id: "5faf2f03da37527dda7a7c78",
      name: "NBA Finals",
      start: "2020-11-11",
      end: "2020-11-17",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: "NBA",
      __typename: "Event",
    },
    {
      id: "5fb119cd7fee2b9d34c26924",
      name: "Dev Complete",
      start: "2020-11-15",
      end: "2020-11-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb122937fee2b9d34c2693d",
      name: "Motus ONE #2",
      start: "2020-11-15",
      end: "2020-11-25",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb26abe81dd10e5a842f5a6",
      name: "iiohihih",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb26d9b81dd10e5a842f5b1",
      name: "Motus ONE",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb26f9281dd10e5a842f5ba",
      name: "FDFohjhho",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb27263483b787e90507fe5",
      name: "TEST EVENT #1000",
      start: "2020-11-19",
      end: "2020-11-25",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb275f45f35882c98795565",
      name: "FIFA 202020",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb284cc38db13c6bc5014e5",
      name: "Latest by Blaz",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb286fc35c0c8aa14086ba8",
      name: "Motus ONE v100",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb29b9135c0c8aa14086bb0",
      name: "New FIFA Event 2021",
      start: "2020-11-19",
      end: "2020-11-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb29be235c0c8aa14086bb8",
      name: "zffuzfuzfuzuzf",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb29c2535c0c8aa14086bbe",
      name: "Motus ONE #100000",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb29d41a1800b4500264da0",
      name: "Latest #1090",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2aea7a1800b4500264db4",
      name: "Fifa JAPAN",
      start: "2020-11-04",
      end: "2020-11-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2d3099c394e8249a19b80",
      name: "Department Project",
      start: "2020-11-14",
      end: "2020-11-14",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2d6729c394e8249a19b8d",
      name: "Test Department Project",
      start: "2020-11-14",
      end: "2020-11-14",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2dbc69c394e8249a19b9a",
      name: "Test Department Project 2",
      start: "2020-11-14",
      end: "2020-11-14",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2e41e9c394e8249a19ba8",
      name: "Test Success Modal",
      start: "2020-11-15",
      end: "2020-11-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2e49b9c394e8249a19bb2",
      name: "Succcess Modal Test",
      start: "2020-11-15",
      end: "2020-11-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb2f2c9a1800b4500264dc4",
      name: "Blaz's project",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6c5d9c205e9e7e2729f35",
      name: "test project bug",
      start: "2020-11-16",
      end: "2020-11-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6c6e1c205e9e7e2729f45",
      name: "test project",
      start: "2020-11-18",
      end: "2020-11-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6c824c205e9e7e2729f53",
      name: "test project",
      start: "2020-11-18",
      end: "2020-11-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6c8aac205e9e7e2729f63",
      name: null,
      start: "2020-11-18",
      end: "2020-11-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6e91a1f71fd2d61cacd8c",
      name: "Test",
      start: "2020-11-04",
      end: "2020-12-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6e9511f71fd2d61cacd97",
      name: "test",
      start: "2020-11-05",
      end: "2020-11-24",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fb6eafe1f71fd2d61cacda1",
      name: "test",
      start: "2020-11-04",
      end: "2020-11-19",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fbed67df45d9d8136672140",
      name: "PAX 2021",
      start: "2021-08-25",
      end: "2021-08-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fbee8d3f45d9d813667214a",
      name: "DMB at the Gorge",
      start: "2020-11-26",
      end: "2020-11-27",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc93d53c657823bba8bf463",
      name: "MultiStepSubmission",
      start: "2020-12-03",
      end: "2020-12-08",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc93f9cc657823bba8bf46d",
      name: "Super Mega Project",
      start: "2020-12-16",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94015c657823bba8bf477",
      name: "Test Project 2",
      start: "2020-12-03",
      end: "2020-12-08",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94161c657823bba8bf481",
      name: "Test Project 3",
      start: "2020-12-04",
      end: "2020-12-23",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc941ddc657823bba8bf48b",
      name: "Test Project 4",
      start: "2020-12-10",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94233c657823bba8bf495",
      name: "Test Project 5",
      start: "2020-12-01",
      end: "2020-12-07",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94287c657823bba8bf49f",
      name: "Test 55",
      start: "2020-12-07",
      end: "2020-12-23",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94367c657823bba8bf4a9",
      name: "Test 6dd",
      start: "2020-11-30",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94409c657823bba8bf4b3",
      name: "Test 8",
      start: "2020-11-30",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc944aec657823bba8bf4bd",
      name: "Test 8",
      start: "2020-12-10",
      end: "2020-12-08",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94562c657823bba8bf4c7",
      name: "test 10",
      start: "2020-12-01",
      end: "2020-12-08",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94718c657823bba8bf4d1",
      name: "test 11",
      start: "2020-12-10",
      end: "2020-12-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94bcbc657823bba8bf4db",
      name: "Test 100",
      start: "2020-11-12",
      end: "2020-11-11",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc94f95c657823bba8bf4e5",
      name: "Test 13",
      start: "2020-12-01",
      end: "2020-11-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc951dd0dd0334c75ab2fa1",
      name: "New Project 1",
      start: "2020-12-03",
      end: "2020-12-01",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc952890dd0334c75ab2fac",
      name: "Test Project 4",
      start: "2020-12-03",
      end: "2020-12-09",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc95ad90dd0334c75ab2fb7",
      name: "Superbowl",
      start: "2020-12-11",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc95b8d0dd0334c75ab2fc2",
      name: "World Series Ping Pong",
      start: "2020-11-11",
      end: "2020-12-01",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc95c760dd0334c75ab2fcd",
      name: "F1 Championships",
      start: "2020-12-16",
      end: "2020-12-17",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc95d3b0dd0334c75ab2fd8",
      name: "Rocket League Championships",
      start: "2020-12-01",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fc968740dd0334c75ab2fe3",
      name: "League of Legends Championships",
      start: "2020-12-01",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fcfc11aa341bcfe908e5fef",
      name: "Add Product On Save",
      start: "2020-12-02",
      end: "2020-12-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fcfc14da341bcfe908e5ff1",
      name: "Test Product OnCreate",
      start: "2020-12-02",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fcfc250749483fef21d0254",
      name: "Test Project On Create",
      start: "2020-12-01",
      end: "2020-12-17",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd115f2c429f40988adbdc8",
      name: "Blaz's latest test",
      start: "2020-12-10",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd255f5888ee65e5e674ca1",
      name: "No Key Locations",
      start: "2020-12-01",
      end: "2020-12-01",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3c490888ee65e5e674cb0",
      name: "Winter Olympics",
      start: "2020-12-16",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3c4cd888ee65e5e674cbc",
      name: "Winter Olympics",
      start: "2020-12-16",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3c584888ee65e5e674cc8",
      name: "Test Event",
      start: "2020-12-01",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3c644888ee65e5e674cd3",
      name: "Test Event",
      start: "2020-12-01",
      end: "2020-12-15",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3ce3b888ee65e5e674cde",
      name: "test project 3",
      start: "2020-12-01",
      end: "2020-12-23",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3cf10888ee65e5e674ce9",
      name: "Summer Olympics",
      start: "2020-12-02",
      end: "2020-12-22",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fd3d261888ee65e5e674cf4",
      name: "Testing 10",
      start: "2020-11-25",
      end: "2020-12-16",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb1f7d2dd56839a444e8f8",
      name: "aaa",
      start: "2020-12-19",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb20ca2dd56839a444e903",
      name: "abc",
      start: "2020-12-17",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb23f02dd56839a444e90e",
      name: "cde",
      start: "2020-12-17",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb2cc72dd56839a444e919",
      name: "qwe",
      start: "2020-12-17",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb418a0ddb317110070fad",
      name: "New test #00",
      start: "2020-12-16",
      end: "2020-12-23",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb555fd2535a51609e12e3",
      name: "asd",
      start: "2020-12-17",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdb556bd2535a51609e12ed",
      name: "asd",
      start: "2020-12-17",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcb478a599ae14dc2fdb5d",
      name: "test",
      start: "2020-12-23",
      end: "2020-12-17",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcb81fa599ae14dc2fdb68",
      name: "test",
      start: "2020-12-11",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcbc6da599ae14dc2fdb74",
      name: "tes",
      start: "2020-12-12",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcbdffa599ae14dc2fdb7f",
      name: "test",
      start: "2020-12-18",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcbe08a599ae14dc2fdb85",
      name: "test",
      start: "2020-12-18",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcbe7da599ae14dc2fdb95",
      name: "test",
      start: "2020-12-18",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdcf83aa599ae14dc2fdba0",
      name: "test",
      start: "2020-12-11",
      end: "2020-12-18",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdd0b424204aa1e98a61836",
      name: "Test URL",
      start: "2020-12-02",
      end: "2020-12-24",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdd0bbe4204aa1e98a61841",
      name: "Test URL Project",
      start: "2020-12-01",
      end: "2020-12-24",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdd0c294204aa1e98a6184c",
      name: "test",
      start: "2020-12-01",
      end: "2020-12-14",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdd0c8e4204aa1e98a61857",
      name: "test",
      start: "2020-12-02",
      end: "2020-12-30",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdd108d4204aa1e98a61862",
      name: "Test URL Project",
      start: "2020-11-30",
      end: "2020-12-28",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fdd11aa4204aa1e98a6186d",
      name: "Test Url Demo",
      start: "2020-11-30",
      end: "2020-12-22",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5fe7afb7fa368efa683df858",
      name: "Scheduler Test 1",
      start: "2020-11-28",
      end: "2020-12-05",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5ff974790655431e40f96c1a",
      name: "test",
      start: "2021-01-20",
      end: "2021-01-10",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "5ffded8b3c2f3b1fe4e81131",
      name: "TestCreatedBy",
      start: "2021-01-03",
      end: "2021-01-27",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "6007239f5260d87ef8db3904",
      name: "Teest Water Bottle",
      start: "2021-01-12",
      end: "2021-01-20",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
    {
      id: "6007245c5260d87ef8db390f",
      name: "Test Water Bottle 2",
      start: "2021-01-29",
      end: "2021-02-24",
      inChargeName: null,
      inChargeEmail: null,
      inChargeMobile: null,
      client: null,
      __typename: "Event",
    },
  ],
  vehicles: [
    {
      id: "5f271bdb7442154fd8a8c13d",
      make: "Audi v2",
      model: "Car",
      year: "2019",
      vehicleNumber: "B0020",
      maxPassengers: 7,
      type: "sedan",
      enabled: true,
      notes: ");)",
      plateNumber: "BF-2430",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [
        {
          id: "5f3d5c264c2533001783b4a1",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f35b5683825eee594a1df2d",
            start: "2020-08-13T23:48:00.000Z",
            stop: null,
            type: "transfer",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "8",
            bookingId: "BB.-Z6ROODNVJ",
            origin: "booking",
            driverschedules: [
              {
                id: "5f3d5c264c2533001783b4a1",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f3d5c294c2533001783b4a2",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fa9f6b07f2ba80017dfd6a3",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fa9f4ef7f2ba80017dfd6a2",
            start: "2020-11-10T17:03:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-KD7CB2DRT",
            origin: "booking",
            driverschedules: [
              {
                id: "5fa9f6b07f2ba80017dfd6a3",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5fa9f4be7f2ba80017dfd6a0",
              name: "Cesta k BB",
              address: "Zgornji Duplek 145 K, Spodnji Duplek, Slovenia",
              lat: 46.5241866,
              long: 15.7302582,
              __typename: "Storedlocation",
            },
            to: {
              id: "5fa9f4cd7f2ba80017dfd6a1",
              name: "Maribor",
              address: "Europark, Pobreška cesta, Maribor, Slovenia",
              lat: 46.5535917,
              long: 15.6530349,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb072f88cef9b841070c4ff",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: null,
          __typename: "Driverschedule",
        },
        {
          id: "5fb07363337dff63a47e4314",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb07362337dff63a47e4313",
            start: "2020-11-15T00:16:33.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "3",
            bookingId: "BB.-2R6WAAEOZ",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb07363337dff63a47e4315",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb07363337dff63a47e4316",
                vehicle: {
                  id: "5f31808a7a21a82615a96f8b",
                  vehicleNumber: "B0021",
                  make: "Audi",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 4,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Simon",
                  lastName: "Desni",
                  phone: null,
                  email: "uporabnikd+1@gmail.com",
                  identifier: "SD01",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb07363337dff63a47e4314",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb0887b337dff63a47e4320",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb0887a337dff63a47e431f",
            start: "2020-11-15T01:46:33.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: "BB-3420",
            numberOfVehicles: "1",
            bookingId: "BB.-XAAZV06DJ",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb0887b337dff63a47e4320",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f3d59154c2533001783b498",
              firstName: "Dana",
              lastName: "Buchawiecki",
              email: "Danab@motusone.com",
              phone: "+971509664754",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f3d02d84c2533001783b48d",
              name:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
              address:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
              lat: 25.2531745,
              long: 55.3656728,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb08d29974cce3d04ca20f8",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb08d28974cce3d04ca20f7",
            start: "2020-11-15T02:06:31.000Z",
            stop: null,
            type: "12 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.--PCIXTMTT",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb08d29974cce3d04ca20f8",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f3d59154c2533001783b498",
              firstName: "Dana",
              lastName: "Buchawiecki",
              email: "Danab@motusone.com",
              phone: "+971509664754",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f3d02d84c2533001783b48d",
              name:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
              address:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
              lat: 25.2531745,
              long: 55.3656728,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb08db7619934268c1b12c9",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb08db6619934268c1b12c8",
            start: "2020-11-15T02:08:53.000Z",
            stop: null,
            type: "12 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-JXAW5HBS2",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb08db7619934268c1b12c9",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f3d59154c2533001783b498",
              firstName: "Dana",
              lastName: "Buchawiecki",
              email: "Danab@motusone.com",
              phone: "+971509664754",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f3d02d84c2533001783b48d",
              name:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
              address:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
              lat: 25.2531745,
              long: 55.3656728,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb08e1a8834b08608014192",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb08e1a8834b08608014191",
            start: "2020-11-15T02:10:32.000Z",
            stop: null,
            type: "12 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-F-FQZZ_WQ",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb08e1a8834b08608014192",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f3d59154c2533001783b498",
              firstName: "Dana",
              lastName: "Buchawiecki",
              email: "Danab@motusone.com",
              phone: "+971509664754",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f3d02d84c2533001783b48d",
              name:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
              address:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
              lat: 25.2531745,
              long: 55.3656728,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb168e7270f43c7aca9a631",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb168e7270f43c7aca9a630",
            start: "2020-11-15T17:44:05.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-O-XCIV1ZPJ",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb168e7270f43c7aca9a631",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb169705731e4c5b0d46f61",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb169705731e4c5b0d46f60",
            start: "2020-11-15T17:46:22.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-YO9M5XCMO",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb169705731e4c5b0d46f61",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb16abf7f27a78d48ffa024",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb16abf7f27a78d48ffa023",
            start: "2020-11-15T21:00:52.000Z",
            stop: null,
            type: "12 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-5K5RDLTM3",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb16abf7f27a78d48ffa024",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb16dd57eae2e36ccda7f5e",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb16dd57eae2e36ccda7f5d",
            start: "2020-11-15T21:00:17.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-FQUDIHKVT",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb16dd57eae2e36ccda7f5e",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb16e69a32af0c70c076cee",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb16e69a32af0c70c076ced",
            start: "2020-11-15T21:00:17.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-K2OBYXBIR",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb16e69a32af0c70c076cee",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb16f57a32af0c70c076cf0",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb16f57a32af0c70c076cef",
            start: "2020-11-15T22:00:51.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.--52_295GD",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb16f57a32af0c70c076cf0",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb19292a32af0c70c076cf2",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb19291a32af0c70c076cf1",
            start: "2020-11-15T20:41:52.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-I8AFK-ADG",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb19292a32af0c70c076cf2",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb30886caf9500017db732a",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb30886caf9500017db7329",
            start: "2020-11-16T23:17:26.000Z",
            stop: null,
            type: "12 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-E2U1PU8P9",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb30886caf9500017db732b",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb30886caf9500017db732a",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5ffb63cba719785a08b68a3d",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: null,
          __typename: "Driverschedule",
        },
        {
          id: "5ffb73e2a719785a08b68a3f",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: null,
          __typename: "Driverschedule",
        },
        {
          id: "5ffb743fa719785a08b68a41",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: null,
          __typename: "Driverschedule",
        },
        {
          id: "5ffb750fa719785a08b68a43",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5ffb750fa719785a08b68a42",
            start: "2021-01-12T22:00:00.000Z",
            stop: null,
            type: "Transfer",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-VVIAIJZE_",
            origin: "Booking",
            driverschedules: [
              {
                id: "5ffb750fa719785a08b68a43",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "60087cb58f9f5d9434f3c80e",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "60087cb48f9f5d9434f3c80d",
            start: "2021-01-22T17:55:00.000Z",
            stop: null,
            type: "Transfer",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-JPWSUXJWQ",
            origin: "Booking",
            driverschedules: [
              {
                id: "60087cb58f9f5d9434f3c80e",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5fa9f4cd7f2ba80017dfd6a1",
              name: "Maribor",
              address: "Europark, Pobreška cesta, Maribor, Slovenia",
              lat: 46.5535917,
              long: 15.6530349,
              __typename: "Storedlocation",
            },
            to: {
              id: "5fa9f4be7f2ba80017dfd6a0",
              name: "Cesta k BB",
              address: "Zgornji Duplek 145 K, Spodnji Duplek, Slovenia",
              lat: 46.5241866,
              long: 15.7302582,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "6008c22bf3b0479d18f79729",
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            maxPassengers: 7,
            plateNumber: "BF-2430",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            email: "blazbreznik+90@gmail.com",
            identifier: "B0010",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "6008c22af3b0479d18f79728",
            start: "2021-01-29T22:51:00.000Z",
            stop: null,
            type: "Transfer",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-66B9KYOYU",
            origin: "Booking",
            driverschedules: [
              {
                id: "6008c22bf3b0479d18f79729",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5fa9f4be7f2ba80017dfd6a0",
              name: "Cesta k BB",
              address: "Zgornji Duplek 145 K, Spodnji Duplek, Slovenia",
              lat: 46.5241866,
              long: 15.7302582,
              __typename: "Storedlocation",
            },
            to: {
              id: "5fa9f4cd7f2ba80017dfd6a1",
              name: "Maribor",
              address: "Europark, Pobreška cesta, Maribor, Slovenia",
              lat: 46.5535917,
              long: 15.6530349,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
      ],
      assignedDrivers: [
        {
          id: "5f29f60970652c577c7d8eb4",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f289d298d19e3785c525f70",
            identifier: "B0010",
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            company: null,
            driveratings: [
              {
                id: "5fa9afc59eb72928745d7ec7",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac573c8e5c3001bcfc7d9c",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac593d8e5c3001bcfc7d9d",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac5aa08e5c3001bcfc7d9e",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5b308e5c3001bcfc7d9f",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5b488e5c3001bcfc7da0",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5b598e5c3001bcfc7da1",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5b658e5c3001bcfc7da2",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5be58e5c3001bcfc7da3",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5c328e5c3001bcfc7da4",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5c4c8e5c3001bcfc7da5",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5c818e5c3001bcfc7da6",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac5c878e5c3001bcfc7da7",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac5dd38e5c3001bcfc7da8",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5dd88e5c3001bcfc7da9",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac5e828e5c3001bcfc7daa",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5e928e5c3001bcfc7dab",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac5f008e5c3001bcfc7dac",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5f708e5c3001bcfc7dad",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5f9f8e5c3001bcfc7dae",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac5fe58e5c3001bcfc7daf",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5feb8e5c3001bcfc7db0",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac60008e5c3001bcfc7db1",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac60068e5c3001bcfc7db2",
                rating: 1,
                __typename: "Driverating",
              },
            ],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f2c6a7916f55c9d3ce20640",
      make: "Audi",
      model: "Car",
      year: "",
      vehicleNumber: "B003",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: "",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [
        {
          id: "5f2d299d16f55c9d3ce20642",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f29c23b678370f78808adbc",
            start: "2020-08-05T00:00:00.000Z",
            stop: null,
            type: "transfer",
            vehicleClass: "",
            flightNumber: "",
            numberOfVehicles: "1",
            bookingId: "F18HEGLMW",
            origin: "booking",
            driverschedules: [
              {
                id: "5f2d299d16f55c9d3ce20642",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f28a5cb7950d03fd1ef5d86",
              name: "Some Hotel - South Korea :O",
              address: "Some Hotel, Jinjam-dong, Daejeon, South Korea",
              lat: 36.312748,
              long: 127.31992,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f2d2a2116f55c9d3ce20644",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f2d2a0f16f55c9d3ce20643",
            start: "2020-08-07T12:16:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: "",
            flightNumber: "XX-2429",
            numberOfVehicles: "1",
            bookingId: "BB.-MIRPGUI1X",
            origin: "booking",
            driverschedules: [
              {
                id: "5f2d2a2116f55c9d3ce20644",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f2dd49bd9045982b0e8bb33",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f2dd487d9045982b0e8bb32",
            start: "2020-08-07T00:23:00.000Z",
            stop: null,
            type: "transfer",
            vehicleClass: "",
            flightNumber: "",
            numberOfVehicles: "1",
            bookingId: "BB.-1WY0-V7MX",
            origin: "booking",
            driverschedules: [
              {
                id: "5f2dd49bd9045982b0e8bb33",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f28a5cb7950d03fd1ef5d86",
              name: "Some Hotel - South Korea :O",
              address: "Some Hotel, Jinjam-dong, Daejeon, South Korea",
              lat: 36.312748,
              long: 127.31992,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f3d5c294c2533001783b4a2",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f35b5683825eee594a1df2d",
            start: "2020-08-13T23:48:00.000Z",
            stop: null,
            type: "transfer",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "8",
            bookingId: "BB.-Z6ROODNVJ",
            origin: "booking",
            driverschedules: [
              {
                id: "5f3d5c264c2533001783b4a1",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f3d5c294c2533001783b4a2",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb072f88cef9b841070c500",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: null,
          __typename: "Driverschedule",
        },
        {
          id: "5fb07363337dff63a47e4315",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb07362337dff63a47e4313",
            start: "2020-11-15T00:16:33.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "3",
            bookingId: "BB.-2R6WAAEOZ",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb07363337dff63a47e4315",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb07363337dff63a47e4316",
                vehicle: {
                  id: "5f31808a7a21a82615a96f8b",
                  vehicleNumber: "B0021",
                  make: "Audi",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 4,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Simon",
                  lastName: "Desni",
                  phone: null,
                  email: "uporabnikd+1@gmail.com",
                  identifier: "SD01",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb07363337dff63a47e4314",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5fb30886caf9500017db732b",
          vehicle: {
            id: "5f2c6a7916f55c9d3ce20640",
            vehicleNumber: "B003",
            make: "Audi",
            model: "Car",
            year: "",
            maxPassengers: 3,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            email: "blazbreznik+driver2@gmail.com",
            identifier: "D001B",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb30886caf9500017db7329",
            start: "2020-11-16T23:17:26.000Z",
            stop: null,
            type: "12 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-E2U1PU8P9",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb30886caf9500017db732b",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb30886caf9500017db732a",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
      ],
      assignedDrivers: [
        {
          id: "5f2c6a8216f55c9d3ce20641",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f2c668d16f55c9d3ce2063f",
            identifier: "D001B",
            firstName: "Some",
            lastName: "Guy",
            phone: "+38631682640",
            company: null,
            driveratings: [
              {
                id: "5f6a4c5aca91dfd8889e95b9",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5f878987562cf7a8888acef4",
                rating: 1,
                __typename: "Driverating",
              },
              {
                id: "5f9b656cc881dbe0ccb7d644",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5f9b661ec881dbe0ccb7d645",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fa024bcf1a1220e1cf0fc51",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fa02590f1a1220e1cf0fc52",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fa02635f1a1220e1cf0fc53",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fa02646f1a1220e1cf0fc54",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fa0264ff1a1220e1cf0fc55",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fa0265df1a1220e1cf0fc56",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fa02671f1a1220e1cf0fc57",
                rating: null,
                __typename: "Driverating",
              },
              {
                id: "5fa0267af1a1220e1cf0fc58",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fa094ce80f6c56e602cb62c",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fa0956f80f6c56e602cb62d",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fa0957380f6c56e602cb62e",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac0c2b8e5c3001bcfc7d97",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac42c28e5c3001bcfc7d98",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac45a08e5c3001bcfc7d99",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac48b58e5c3001bcfc7d9a",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac49258e5c3001bcfc7d9b",
                rating: 1,
                __typename: "Driverating",
              },
            ],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f31808a7a21a82615a96f8b",
      make: "Audi",
      model: "Car",
      year: "2019",
      vehicleNumber: "B0021",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: ";)",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [
        {
          id: "5fb072f88cef9b841070c501",
          vehicle: {
            id: "5f31808a7a21a82615a96f8b",
            vehicleNumber: "B0021",
            make: "Audi",
            model: "Car",
            year: "2019",
            maxPassengers: 4,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Simon",
            lastName: "Desni",
            phone: null,
            email: "uporabnikd+1@gmail.com",
            identifier: "SD01",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: null,
          __typename: "Driverschedule",
        },
        {
          id: "5fb07363337dff63a47e4316",
          vehicle: {
            id: "5f31808a7a21a82615a96f8b",
            vehicleNumber: "B0021",
            make: "Audi",
            model: "Car",
            year: "2019",
            maxPassengers: 4,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Simon",
            lastName: "Desni",
            phone: null,
            email: "uporabnikd+1@gmail.com",
            identifier: "SD01",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5fb07362337dff63a47e4313",
            start: "2020-11-15T00:16:33.000Z",
            stop: null,
            type: "6 Hour",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "3",
            bookingId: "BB.-2R6WAAEOZ",
            origin: "Booking",
            driverschedules: [
              {
                id: "5fb07363337dff63a47e4315",
                vehicle: {
                  id: "5f2c6a7916f55c9d3ce20640",
                  vehicleNumber: "B003",
                  make: "Audi",
                  model: "Car",
                  year: "",
                  maxPassengers: 3,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Some",
                  lastName: "Guy",
                  phone: "+38631682640",
                  email: "blazbreznik+driver2@gmail.com",
                  identifier: "D001B",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb07363337dff63a47e4316",
                vehicle: {
                  id: "5f31808a7a21a82615a96f8b",
                  vehicleNumber: "B0021",
                  make: "Audi",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 4,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Simon",
                  lastName: "Desni",
                  phone: null,
                  email: "uporabnikd+1@gmail.com",
                  identifier: "SD01",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5fb07363337dff63a47e4314",
                vehicle: {
                  id: "5f271bdb7442154fd8a8c13d",
                  vehicleNumber: "B0020",
                  make: "Audi v2",
                  model: "Car",
                  year: "2019",
                  maxPassengers: 7,
                  plateNumber: "BF-2430",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Blaž",
                  lastName: "Breznik",
                  phone: "+38631682640",
                  email: "blazbreznik+90@gmail.com",
                  identifier: "B0010",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a10f7950d03fd1ef5d84",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+8755786587@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271ba97442154fd8a8c13b",
              name: "Dubai Airport Terminal 2 Departure",
              address: "Dubai - United Arab Emirates",
              lat: 25.2628902,
              long: 55.3532168,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
      ],
      assignedDrivers: [
        {
          id: "5f398ca62541ff4c2c823c9a",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f35a0ca3825eee594a1df1d",
            identifier: "SD01",
            firstName: "Simon",
            lastName: "Desni",
            phone: null,
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f31808f7a21a82615a96f8c",
      make: "Audi",
      model: "Car",
      year: "2019",
      vehicleNumber: "B0022",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: ";)",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [
        {
          id: "5f398cad2541ff4c2c823c9b",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f2b27ea16f55c9d3ce20639",
            identifier: "Number Two",
            firstName: "Second",
            lastName: "Breznik",
            phone: "+38631682640",
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f3180947a21a82615a96f8d",
      make: "Audi",
      model: "Car",
      year: "2019",
      vehicleNumber: "B0023",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: ";)",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [
        {
          id: "5f3d5ab94c2533001783b49a",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f3cfaa16fe6c4546401caa2",
            identifier: "SB009",
            firstName: "Simon",
            lastName: "Desni",
            phone: null,
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
        {
          id: "5f3d5ac14c2533001783b49b",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f3cfaa16fe6c4546401caa2",
            identifier: "SB009",
            firstName: "Simon",
            lastName: "Desni",
            phone: null,
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f33b493689bc1372d5e6f65",
      make: "Chevy ",
      model: "Suburban",
      year: "2019",
      vehicleNumber: "B0020",
      maxPassengers: 6,
      type: "sedan",
      enabled: true,
      notes: ":)",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b7a7442154fd8a8c13a",
        name: "SUV",
        maxPassengers: 2,
        __typename: "Vehicleclass",
      },
      driverschedules: [
        {
          id: "5f3d5c4b4c2533001783b4a3",
          vehicle: {
            id: "5f33b493689bc1372d5e6f65",
            vehicleNumber: "B0020",
            make: "Chevy ",
            model: "Suburban",
            year: "2019",
            maxPassengers: 6,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "McLovin",
            lastName: "Man",
            phone: "+971402224334",
            email: "Lovin@macin.com",
            identifier: "124",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f3d03744c2533001783b491",
            start: "2020-08-19T17:00:00.000Z",
            stop: null,
            type: "transfer",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-SA7LATGUV",
            origin: "booking",
            driverschedules: [
              {
                id: "5f3d5c4b4c2533001783b4a3",
                vehicle: {
                  id: "5f33b493689bc1372d5e6f65",
                  vehicleNumber: "B0020",
                  make: "Chevy ",
                  model: "Suburban",
                  year: "2019",
                  maxPassengers: 6,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "McLovin",
                  lastName: "Man",
                  phone: "+971402224334",
                  email: "Lovin@macin.com",
                  identifier: "124",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b7a7442154fd8a8c13a",
              name: "SUV",
              maxPassengers: 2,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f3d03744c2533001783b490",
              firstName: "James",
              lastName: "Games",
              email: "steffivgomes@gmail.com",
              phone: null,
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f3d02d84c2533001783b48d",
              name:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
              address:
                "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
              lat: 25.2531745,
              long: 55.3656728,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f3d02f44c2533001783b48e",
              name: "Burj Khalifa,Dubai - Dubai - United Arab Emirates",
              address: "Burj Khalifa,Dubai - Dubai - United Arab Emirates",
              lat: 25.197197,
              long: 55.27437639999999,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f3d5c5f4c2533001783b4a4",
          vehicle: {
            id: "5f33b493689bc1372d5e6f65",
            vehicleNumber: "B0020",
            make: "Chevy ",
            model: "Suburban",
            year: "2019",
            maxPassengers: 6,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "McLovin",
            lastName: "Man",
            phone: "+971402224334",
            email: "Lovin@macin.com",
            identifier: "124",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f3d5a7e4c2533001783b499",
            start: "2020-08-19T21:10:00.000Z",
            stop: null,
            type: "6hour",
            vehicleClass: null,
            flightNumber: "EK805",
            numberOfVehicles: "1",
            bookingId: "BB.-HHMJUWNQF",
            origin: "booking",
            driverschedules: [
              {
                id: "5f3d5c5f4c2533001783b4a4",
                vehicle: {
                  id: "5f33b493689bc1372d5e6f65",
                  vehicleNumber: "B0020",
                  make: "Chevy ",
                  model: "Suburban",
                  year: "2019",
                  maxPassengers: 6,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "McLovin",
                  lastName: "Man",
                  phone: "+971402224334",
                  email: "Lovin@macin.com",
                  identifier: "124",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b7a7442154fd8a8c13a",
              name: "SUV",
              maxPassengers: 2,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f3d59154c2533001783b498",
              firstName: "Dana",
              lastName: "Buchawiecki",
              email: "Danab@motusone.com",
              phone: "+971509664754",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "6008bb0114d07db7a001ebbd",
          vehicle: {
            id: "5f33b493689bc1372d5e6f65",
            vehicleNumber: "B0020",
            make: "Chevy ",
            model: "Suburban",
            year: "2019",
            maxPassengers: 6,
            plateNumber: "",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "McLovin",
            lastName: "Man",
            phone: "+971402224334",
            email: "Lovin@macin.com",
            identifier: "124",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "6008bb0014d07db7a001ebbc",
            start: "2021-01-29T07:21:00.000Z",
            stop: null,
            type: "Transfer",
            vehicleClass: "ADA",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-FXJPIS1OB",
            origin: "Booking",
            driverschedules: [
              {
                id: "6008bb0114d07db7a001ebbd",
                vehicle: {
                  id: "5f33b493689bc1372d5e6f65",
                  vehicleNumber: "B0020",
                  make: "Chevy ",
                  model: "Suburban",
                  year: "2019",
                  maxPassengers: 6,
                  plateNumber: "",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "McLovin",
                  lastName: "Man",
                  phone: "+971402224334",
                  email: "Lovin@macin.com",
                  identifier: "124",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5fabe11ad2a9012ba013e45b",
              name: "ADA",
              maxPassengers: 2,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5fa57c32aaac2611d29c1646",
              firstName: "edit",
              lastName: "testpassenger",
              email: "testpassenger@gmail.com",
              phone: "+971234523424",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f271bba7442154fd8a8c13c",
              name: "Palm Jumeirah",
              address: "Palm Jumeirah - Dubai - United Arab Emirates",
              lat: 25.1124317,
              long: 55.13897799999999,
              __typename: "Storedlocation",
            },
            to: {
              id: "5fa9f4cd7f2ba80017dfd6a1",
              name: "Maribor",
              address: "Europark, Pobreška cesta, Maribor, Slovenia",
              lat: 46.5535917,
              long: 15.6530349,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
      ],
      assignedDrivers: [
        {
          id: "5f3d5b084c2533001783b49c",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f3cfc924c2533001783b488",
            identifier: "124",
            firstName: "McLovin",
            lastName: "Man",
            phone: "+971402224334",
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f33b706689bc1372d5e6f66",
      make: "Audi",
      model: "Car0",
      year: null,
      vehicleNumber: "B009",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f33b7a0689bc1372d5e6f69",
      make: "Audi",
      model: "Vero",
      year: null,
      vehicleNumber: "B010",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: "20892084",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f33b826689bc1372d5e6f6c",
      make: "Audi",
      model: "Vero",
      year: null,
      vehicleNumber: "B010",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: "20892084",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f33b829689bc1372d5e6f6d",
      make: "Audi",
      model: "Vero",
      year: null,
      vehicleNumber: "B010",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: "20892084",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3454459bebfa0017922325",
      make: "Audi",
      model: "Car",
      year: "2019",
      vehicleNumber: "B0023",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: ";)",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3465903825eee594a1df0c",
      make: "Audi",
      model: "Car",
      year: "2019",
      vehicleNumber: "A001",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: ":O",
      plateNumber: "",
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3573303825eee594a1df16",
      make: "Audi",
      model: "Mondo",
      year: null,
      vehicleNumber: "B001",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3573fd3825eee594a1df18",
      make: "Audi",
      model: "Mik",
      year: null,
      vehicleNumber: "C001",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3575943825eee594a1df1a",
      make: "Audi",
      model: "888",
      year: null,
      vehicleNumber: "BB008",
      maxPassengers: 44,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b7a7442154fd8a8c13a",
        name: "SUV",
        maxPassengers: 2,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3575ce3825eee594a1df1b",
      make: "Audi",
      model: "000",
      year: null,
      vehicleNumber: "0003",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f359fc23825eee594a1df1c",
      make: "Audi",
      model: "Boot",
      year: null,
      vehicleNumber: "C001",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f35a2343825eee594a1df1e",
      make: "Audi",
      model: "First",
      year: null,
      vehicleNumber: "AA01",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f35a2643825eee594a1df1f",
      make: "Audi",
      model: "Make3",
      year: null,
      vehicleNumber: "AA001",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f35a47f3825eee594a1df20",
      make: "BMW",
      model: "B00",
      year: null,
      vehicleNumber: "B009",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f35a5b43825eee594a1df21",
      make: "BMW",
      model: "Testerino",
      year: null,
      vehicleNumber: "Z001",
      maxPassengers: 4,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: null,
      status: "allocated",
      vehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        maxPassengers: 0,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f3d03ff4c2533001783b494",
      make: "Audi",
      model: "A6",
      year: null,
      vehicleNumber: "3454",
      maxPassengers: 2,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: "W2345",
      status: "available",
      vehicleClass: {
        id: "5f271b7a7442154fd8a8c13a",
        name: "SUV",
        maxPassengers: 2,
        __typename: "Vehicleclass",
      },
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f6238c4973f8d3c9480cd2c",
      make: null,
      model: null,
      year: null,
      vehicleNumber: null,
      maxPassengers: null,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: null,
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5f6899897d1c4400175e926a",
      make: "Toyota",
      model: "Land Cruiser",
      year: null,
      vehicleNumber: "RAD 07IG",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: "07IG",
      status: "available",
      vehicleClass: {
        id: "5f688a8c7d1c4400175e925b",
        name: "SUV",
        maxPassengers: 4,
        __typename: "Vehicleclass",
      },
      driverschedules: [
        {
          id: "5f6b875503ef910017fa9543",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f688cb37d1c4400175e9269",
            start: "2020-09-21T15:30:00.000Z",
            stop: null,
            type: "24hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-JOYUHLYYX",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6b875503ef910017fa9543",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6c55b303ef910017fa9552",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6b9bb103ef910017fa9550",
            start: "2020-09-24T12:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-MT4IVBQWX",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6c55b303ef910017fa9552",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6c55b503ef910017fa9553",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6cd640e2d80f001750eb7f",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6cd61ae2d80f001750eb7e",
            start: "2020-09-25T08:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-LRVVB68W8",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6cd640e2d80f001750eb7f",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6cd648e2d80f001750eb80",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6e25a2d3890500172cc599",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6e258dd3890500172cc598",
            start: "2020-09-26T10:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-CXRTOSIVU",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6e25a2d3890500172cc599",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6e25a7d3890500172cc59a",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6f96137682660017486a4a",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6f96007682660017486a49",
            start: "2020-09-27T12:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-CWAGNPWED",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6f96137682660017486a4a",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6f96177682660017486a4b",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f70e12bafd190001770234a",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f70e113afd1900017702349",
            start: "2020-09-28T08:30:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-53Y7VR2IQ",
            origin: "booking",
            driverschedules: [
              {
                id: "5f70e12bafd190001770234a",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f70e12eafd190001770234b",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f743b466f725300178330e9",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f72260406310b0017e7ef5f",
            start: "2020-09-29T09:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-UBXRJYKZJ",
            origin: "booking",
            driverschedules: [
              {
                id: "5f743b466f725300178330e9",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f743b496f725300178330ea",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f743bae6f72530017833100",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f743ba46f725300178330ff",
            start: "2020-09-30T10:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-OJHXSVHXX",
            origin: "booking",
            driverschedules: [
              {
                id: "5f743bae6f72530017833100",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f743bb16f72530017833101",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f74dce86f72530017833117",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f74dcdc6f72530017833116",
            start: "2020-10-01T08:30:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-WKAXJHSSH",
            origin: "booking",
            driverschedules: [
              {
                id: "5f74dce86f72530017833117",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f77047723f9a70017c7a163",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f77046923f9a70017c7a162",
            start: "2020-10-02T09:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-621TPEBSM",
            origin: "booking",
            driverschedules: [
              {
                id: "5f77047723f9a70017c7a163",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f77047d23f9a70017c7a164",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f7ad496ab2c4c0017389d51",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f77321e23f9a70017c7a165",
            start: "2020-10-04T12:30:00.000Z",
            stop: null,
            type: "6hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-ZISEMRRTC",
            origin: "booking",
            driverschedules: [
              {
                id: "5f7ad496ab2c4c0017389d51",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "60086d5537a75a50fc663365",
          vehicle: {
            id: "5f6899897d1c4400175e926a",
            vehicleNumber: "RAD 07IG",
            make: "Toyota",
            model: "Land Cruiser",
            year: null,
            maxPassengers: 3,
            plateNumber: "07IG",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            email: "irafashagentillegena@gmail.com",
            identifier: "aime",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "60086d5537a75a50fc663364",
            start: "2021-01-22T16:49:00.000Z",
            stop: null,
            type: "Transfer",
            vehicleClass: "Sedan-Basic",
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "BB.-8A-HDIFM9",
            origin: "Booking",
            driverschedules: [
              {
                id: "60086d5537a75a50fc663365",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f271b6e7442154fd8a8c139",
              name: "Sedan-Basic",
              maxPassengers: 0,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f28a13e7950d03fd1ef5d85",
              firstName: "Blaž",
              lastName: "Breznik",
              email: "blazbreznik+44444@gmail.com",
              phone: "+38631682640",
              event: { id: "5f270baecc54999de0a9f579", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f274e8a8d31fa3364defaee",
              name: "Motus ONE",
              address: "MOTUS | ONE - Dubai - United Arab Emirates",
              lat: 25.089777,
              long: 55.152203,
              __typename: "Storedlocation",
            },
            to: {
              id: "5fb11e937fee2b9d34c2693b",
              name: "DXB test 3",
              address:
                "Dubai International Airport (DXB), Dubai - United Arab Emirates",
              lat: 25.2531745,
              long: 55.3656728,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
      ],
      assignedDrivers: [
        {
          id: "5f6b38531ac96c0017c33b6c",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f689a4b7d1c4400175e926b",
            identifier: "aime",
            firstName: "Aime",
            lastName: "Aime",
            phone: "+25078888721",
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5f6b87e503ef910017fa954e",
      make: "Toyota",
      model: "Land Cruiser ",
      year: null,
      vehicleNumber: "RAD420D",
      maxPassengers: 3,
      type: "sedan",
      enabled: true,
      notes: null,
      plateNumber: "RAD420D",
      status: "available",
      vehicleClass: {
        id: "5f688a8c7d1c4400175e925b",
        name: "SUV",
        maxPassengers: 4,
        __typename: "Vehicleclass",
      },
      driverschedules: [
        {
          id: "5f6c55b503ef910017fa9553",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6b9bb103ef910017fa9550",
            start: "2020-09-24T12:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-MT4IVBQWX",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6c55b303ef910017fa9552",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6c55b503ef910017fa9553",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6cd648e2d80f001750eb80",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6cd61ae2d80f001750eb7e",
            start: "2020-09-25T08:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-LRVVB68W8",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6cd640e2d80f001750eb7f",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6cd648e2d80f001750eb80",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6e25a7d3890500172cc59a",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6e258dd3890500172cc598",
            start: "2020-09-26T10:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-CXRTOSIVU",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6e25a2d3890500172cc599",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6e25a7d3890500172cc59a",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f688bc17d1c4400175e9267",
              name: "Marriot Hotel",
              address: "Kabeza Marriot, KK 189 Street, Kigali, Rwanda",
              lat: -1.9690107,
              long: 30.121752,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f6f96177682660017486a4b",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f6f96007682660017486a49",
            start: "2020-09-27T12:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-CWAGNPWED",
            origin: "booking",
            driverschedules: [
              {
                id: "5f6f96137682660017486a4a",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f6f96177682660017486a4b",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f70e12eafd190001770234b",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f70e113afd1900017702349",
            start: "2020-09-28T08:30:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-53Y7VR2IQ",
            origin: "booking",
            driverschedules: [
              {
                id: "5f70e12bafd190001770234a",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f70e12eafd190001770234b",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f743b496f725300178330ea",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f72260406310b0017e7ef5f",
            start: "2020-09-29T09:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-UBXRJYKZJ",
            origin: "booking",
            driverschedules: [
              {
                id: "5f743b466f725300178330e9",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f743b496f725300178330ea",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f743bb16f72530017833101",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f743ba46f725300178330ff",
            start: "2020-09-30T10:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-OJHXSVHXX",
            origin: "booking",
            driverschedules: [
              {
                id: "5f743bae6f72530017833100",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f743bb16f72530017833101",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f74dd1e6f72530017833119",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f74dd186f72530017833118",
            start: "2020-10-01T10:15:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-TB7L7BWJ0",
            origin: "booking",
            driverschedules: [
              {
                id: "5f74dd1e6f72530017833119",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f77047d23f9a70017c7a164",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f77046923f9a70017c7a162",
            start: "2020-10-02T09:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "2",
            bookingId: "NBA-Sight Inspection-621TPEBSM",
            origin: "booking",
            driverschedules: [
              {
                id: "5f77047723f9a70017c7a163",
                vehicle: {
                  id: "5f6899897d1c4400175e926a",
                  vehicleNumber: "RAD 07IG",
                  make: "Toyota",
                  model: "Land Cruiser",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "07IG",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Aime",
                  lastName: "Aime",
                  phone: "+25078888721",
                  email: "irafashagentillegena@gmail.com",
                  identifier: "aime",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
              {
                id: "5f77047d23f9a70017c7a164",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f96a33a554c1d00175b9438",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f96a2a7554c1d00175b9437",
            start: "2020-10-26T10:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-S1JGPN7RV",
            origin: "booking",
            driverschedules: [
              {
                id: "5f96a33a554c1d00175b9438",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f97ae456f119e001712cb69",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f991de47e246100173c7d9b",
            start: "2020-10-28T15:30:00.000Z",
            stop: null,
            type: "transfer",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "7XRWWCQ6H",
            origin: "booking",
            driverschedules: [
              {
                id: "5f97ae456f119e001712cb69",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f991b1b7e246100173c7d91",
              name: "Mini Bus",
              maxPassengers: 15,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f991de47e246100173c7d9a",
              firstName: "SC Johnson ",
              lastName: "Group",
              email: "zelda@hiside.co.za",
              phone: "+270847841479",
              event: { id: "5f991b1a7e246100173c7d89", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f991c927e246100173c7d98",
              name: "JOHNSON SC & SON OFFICE",
              address:
                "JOHNSON SC & SON OF SOUTH AFRICA (PTY) LTD, 192 Smit St, Fairland, Johannesburg, South Africa",
              lat: -26.1299199,
              long: 27.94578,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f991cc67e246100173c7d99",
              name: "Monate Game Lodge",
              address:
                "Monate Game Lodge, Boekenhout Street, Modimolle, South Africa",
              lat: -24.7450146,
              long: 28.6676244,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f9a7cbecbfd8f001764cd19",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f9a7ca8cbfd8f001764cd18",
            start: "2020-10-29T10:00:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-X_JPCP7P9",
            origin: "booking",
            driverschedules: [
              {
                id: "5f9a7cbecbfd8f001764cd19",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
        {
          id: "5f9afcf5cbfd8f001764cd2f",
          vehicle: {
            id: "5f6b87e503ef910017fa954e",
            vehicleNumber: "RAD420D",
            make: "Toyota",
            model: "Land Cruiser ",
            year: null,
            maxPassengers: 3,
            plateNumber: "RAD420D",
            __typename: "Vehicle",
          },
          driver: {
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            email: "reservation@attltd.rw",
            identifier: "RAD420D",
            __typename: "UsersPermissionsUser",
          },
          passengerschedule: {
            id: "5f9afca6cbfd8f001764cd2e",
            start: "2020-10-30T09:30:00.000Z",
            stop: null,
            type: "12hour",
            vehicleClass: null,
            flightNumber: null,
            numberOfVehicles: "1",
            bookingId: "NBA-Sight Inspection-JWKTCAPAF",
            origin: "booking",
            driverschedules: [
              {
                id: "5f9afcf5cbfd8f001764cd2f",
                vehicle: {
                  id: "5f6b87e503ef910017fa954e",
                  vehicleNumber: "RAD420D",
                  make: "Toyota",
                  model: "Land Cruiser ",
                  year: null,
                  maxPassengers: 3,
                  plateNumber: "RAD420D",
                  __typename: "Vehicle",
                },
                driver: {
                  firstName: "Ngabo ",
                  lastName: "Alexis",
                  phone: "+250788647755",
                  email: "reservation@attltd.rw",
                  identifier: "RAD420D",
                  __typename: "UsersPermissionsUser",
                },
                __typename: "Driverschedule",
              },
            ],
            requiredVehicleClass: {
              id: "5f688a8c7d1c4400175e925b",
              name: "SUV",
              maxPassengers: 4,
              __typename: "Vehicleclass",
            },
            passenger: {
              id: "5f688cb27d1c4400175e9268",
              firstName: "Yolam ",
              lastName: "Anderson",
              email: "YGolhor@thebal.com",
              phone: "+221776373012",
              event: { id: "5f688a8b7d1c4400175e9257", __typename: "Event" },
              __typename: "UsersPermissionsUser",
            },
            from: {
              id: "5f6f95b77682660017486a48",
              name:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              address:
                "Radisson Blu Hotel & Convention Centre, Kigali, KG 2 Roundabout, Kigali, Rwanda",
              lat: -1.9543819,
              long: 30.0927392,
              __typename: "Storedlocation",
            },
            to: {
              id: "5f688a8c7d1c4400175e9265",
              name: "-As directed-",
              address:
                "Passenger(s) will give instructions on where they want to go.",
              lat: 0,
              long: 0,
              __typename: "Storedlocation",
            },
            __typename: "Passengerschedule",
          },
          __typename: "Driverschedule",
        },
      ],
      assignedDrivers: [
        {
          id: "5f6c559303ef910017fa9551",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f6b887603ef910017fa954f",
            identifier: "RAD420D",
            firstName: "Ngabo ",
            lastName: "Alexis",
            phone: "+250788647755",
            company: null,
            driveratings: [],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
    {
      id: "5fa1c5ba4605de4281a806e1",
      make: "Honda",
      model: "Civic",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 4,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-THF",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fa1c67a4605de4281a806e3",
      make: "Ford",
      model: "Focus",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 4,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-THD",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fadfa452bf925fc014c37d3",
      make: "Ford",
      model: "Focus",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 1,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-THF",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb17008df0a8643d21517c4",
      make: "Ford",
      model: "Focus",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 12,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-THF",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb178f8df0a8643d21517e5",
      make: null,
      model: null,
      year: null,
      vehicleNumber: null,
      maxPassengers: null,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: null,
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb17e66df0a8643d2151820",
      make: "Ford",
      model: "Focus",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 4,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-THF",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb188c5df0a8643d2151829",
      make: "Ford",
      model: "Focus",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 3,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-CFV",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb2f26c9c394e8249a19bbc",
      make: "BMW",
      model: "z-260i",
      year: "2019",
      vehicleNumber: null,
      maxPassengers: 2,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-TEST",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb2f3d99c394e8249a19bbd",
      make: "BMW",
      model: "z-260i",
      year: "2019",
      vehicleNumber: null,
      maxPassengers: 2,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-TEST",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fb2ffdd9c394e8249a19bbf",
      make: "BMW",
      model: "z-260i",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 4,
      type: null,
      enabled: true,
      notes: null,
      plateNumber: "566-TEST",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [],
      __typename: "Vehicle",
    },
    {
      id: "5fbff92ea4e9afb9e1acfaa3",
      make: "Ford",
      model: "Focus",
      year: "2012",
      vehicleNumber: null,
      maxPassengers: 2,
      type: null,
      enabled: false,
      notes: null,
      plateNumber: "566-THD",
      status: null,
      vehicleClass: null,
      driverschedules: [],
      assignedDrivers: [
        {
          id: "5fbff92ea4e9afb9e1acfaa4",
          notes: "",
          start: null,
          stop: null,
          wholeEvent: true,
          driver: {
            id: "5f289d298d19e3785c525f70",
            identifier: "B0010",
            firstName: "Blaž",
            lastName: "Breznik",
            phone: "+38631682640",
            company: null,
            driveratings: [
              {
                id: "5fa9afc59eb72928745d7ec7",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac573c8e5c3001bcfc7d9c",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac593d8e5c3001bcfc7d9d",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac5aa08e5c3001bcfc7d9e",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5b308e5c3001bcfc7d9f",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5b488e5c3001bcfc7da0",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5b598e5c3001bcfc7da1",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5b658e5c3001bcfc7da2",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5be58e5c3001bcfc7da3",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5c328e5c3001bcfc7da4",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5c4c8e5c3001bcfc7da5",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5c818e5c3001bcfc7da6",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac5c878e5c3001bcfc7da7",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac5dd38e5c3001bcfc7da8",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5dd88e5c3001bcfc7da9",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac5e828e5c3001bcfc7daa",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5e928e5c3001bcfc7dab",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac5f008e5c3001bcfc7dac",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac5f708e5c3001bcfc7dad",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5f9f8e5c3001bcfc7dae",
                rating: 2,
                __typename: "Driverating",
              },
              {
                id: "5fac5fe58e5c3001bcfc7daf",
                rating: 3,
                __typename: "Driverating",
              },
              {
                id: "5fac5feb8e5c3001bcfc7db0",
                rating: 5,
                __typename: "Driverating",
              },
              {
                id: "5fac60008e5c3001bcfc7db1",
                rating: 4,
                __typename: "Driverating",
              },
              {
                id: "5fac60068e5c3001bcfc7db2",
                rating: 1,
                __typename: "Driverating",
              },
            ],
            __typename: "UsersPermissionsUser",
          },
          __typename: "Assignedvehicle",
        },
      ],
      __typename: "Vehicle",
    },
  ],
};
