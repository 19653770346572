import React from 'react';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import GetAllAccessRights from 'gql/AccessRights/GetAll';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import AccessRightsComponent from './AccessRights.Component';

const AccessRightsContainer = () => {
  const { data, loading } = useQuery(GetAllAccessRights, {
    variables: {},
  });
  _.remove(data && data.accessrights, { id: '5f95f1453de09f162d3d00a6' });

  return (
    <AccessRightsComponent
      data={(data && data.accessrights) || []}
      loading={loading}
    />
  );
};

export default withMainLayout(
  'Access Rights',
  {},
  withRouter(AccessRightsContainer)
);
