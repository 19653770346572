/* eslint-disable react/display-name */
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import CustomizedBadges from 'components_v2/Badge/Badge';

import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    '&:hover $iconContainer': {
      opacity: '0.3',
      marginRight: '10px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: palette.primary.light,
    borderLeft: '3px solid transparent',
  },
  selected: {
    background: 'rgba(255, 255, 255, 0.1)',
    '& $listItemText': { color: palette.primary.light, fontWeight: 'bold' },
    color: palette.primary.light,
    borderLeft: `3px solid ${palette.primary.light}`,
  },
  listItemText: {
    fontFamily: typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    color: palette.primary.light,
    marginLeft: 16,
  },
  active: {
    background: 'rgba(255, 255, 255, 0.1)',
    '& $listItemText': {
      color: palette.primary.light,
      fontSize: 12,
    },
    borderLeft: `3px solid ${palette.primary.light}`,
    color: palette.primary.light,
  },
  iconContainer: {
    fill: palette.primary.light,
    color: palette.primary.light,
  },
  mobile: {
    padding: '19px 28px',
    '&:hover $iconContainer': {
      marginRight: 0,
    },
  },
  mobileIconContainer: {
    width: '18px',
    height: '18px',
  },
  mobileActive: {
    background: 'rgba(255, 255, 255, 0.1)',
    '& $listItemText': {
      color: palette.primary.light,
      fontSize: 12,
    },
    borderBottom: `3px solid ${palette.primary.light}`,
    color: palette.primary.light,
  },
}));

const StyledMenuItem = React.memo(
  ({
    label,
    route,
    icon,
    location,
    important,
    padding,
    textAlign,
    open,
    mobile,
    counter,
    onClick,
    id,
  }) => {
    const classes = useStyles();
    const selected = location.pathname === route;
    const Icon = icon;

    return (
      <Link to={route} style={{ textDecoration: 'none' }}>
        <ListItem
          button
          className={clsx(classes.root, {
            [classes.active]: selected && !mobile,
            [classes.mobileActive]: selected && mobile,
            [classes.mobile]: mobile,
          })}
          style={{
            backgroundColor: important ? '#FF5001' : '',
            color: important ? '#fff' : '',
            paddingLeft: mobile ? '19px' : padding || '30px',
            textAlign: textAlign || {},
          }}
          onClick={onClick}
        >
          <section
            id={id}
            className={clsx(classes.iconContainer, {
              [classes.mobileIconContainer]: mobile,
            })}
          >
            {(counter && (
              <CustomizedBadges
                position="top"
                value={counter}
                style={{ fill: '#fff' }}
                children={<Icon />}
              />
            )) || <Icon />}
          </section>

          {!mobile && (
            <ListItemText
              primary={
                <Slide in={open} direction="left">
                  <p>{label}</p>
                </Slide>
              }
              classes={{
                primary: classes.listItemText,
              }}
            />
          )}
        </ListItem>
      </Link>
    );
  }
);

export default withRouter(StyledMenuItem);
