import gql from "graphql-tag";

export default gql`
  query GetAllDriversCustom(
    $event: ID
    $supplier: ID
    $pageSize: Int
    $startIndex: Int
    $sort: String
    $filterKey: String
  ) {
    allUsers(
      event: $event
      supplier: $supplier
      sort: $sort
      limit: $pageSize
      start: $startIndex
      filterKey: $filterKey
    ) {
      users {
        averageDriverRating
        id
        email
        firstName
        lastName
        fullName
        phone
        confirmed
        company
        identifier
        username
        notes
        profileImageThumbnail
        passengerPriority
        role {
          id
          name
        }
        shift
        driveratings {
          id
          rating
        }
        assignedVehicles {
          id
          notes
          created_at
          start
          stop
          wholeEvent
          vehicle {
            id
            type
            make
            model
            color
            plateNumber
            vehicleNumber
          }
        }
      }
      totalCount
    }
  }
`;
