import gql from "graphql-tag";

export default gql`
  query GetPassengerSchedule($id: ID!) {
    passengerschedules(where: { id: $id }) {
      id
      start
      stop
      vehicleClass
      additionalData
      requiredVehicleClass {
        id
        name
        maxLuggage
        maxPassengers
      }
      transferservice {
        id
        name
        enabled
      }
      numPassengers
      type
      flightNumber
      terminal
      notes
      productNotes
      luggageCount
      numberOfVehicles
      bookingId
      products
      documents
      stops
      savedStops {
        name
        type
        address
        lat
        long
        id
      }
      stops
      cancelled
      rate
      rateComment
      savedDocuments {
        id
        size
        url
        name
        uploadedBy {
          id
          firstName
          lastName
        }
      }
      rateChangedby {
        id
        firstName
        lastName
      }
      rateAppliedOn
      driverschedules {
        id
        note
        vehicle {
          id
          vehicleNumber
          make
          model
          year
          maxPassengers
          plateNumber
        }
        driver {
          id
          firstName
          lastName
          phone
          email
          identifier
        }
        ridestatuses {
          id
          status
        }
      }
      department {
        id
        name
        module {
          id
          name
          departments {
            id
            name
          }
        }
      }
      bookingEmailRecepients
      event {
        id
      }
      requiredVehicleClass {
        id
        name
      }
      passengerschedulechanges {
        id
        created_at
        status
        changes
        requestedBy {
          id
          firstName
          lastName
        }
      }
      from {
        id
        name
        address
        lat
        long
        type
      }
      to {
        id
        name
        address
        lat
        long
        type
      }
      passenger {
        id
        firstName
        lastName
        phone
        email
      }
      additionalPassengers {
        id
        firstName
        lastName
        fullName
        phone
        email
      }
      supplier {
        id
        email
        name
        phone
      }
    }
  }
`;
