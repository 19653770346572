import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { withRouter } from "react-router-dom";

import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';
import TextAreaInput from 'components_v2/Inputs/TextAreaInput/TextAreaInput';
import DatepickerInput from 'components_v2/Inputs/DatepickerInput/DatepickerInput';
import RadioGroupInput from 'components_v2/Inputs/RadioGroupInput/RadioGroupInput';
import TimezoneSelect from 'components_v2/Inputs/TimezoneSelect/TimezoneSelect';
import CountrySelect from 'components_v2/Inputs/CountrySelect/CountrySelect';
import Button from 'components_v2/Button/Button';
import { Add } from 'components_v2/Icons/Icons';
import Typography from 'components_v2/Typography/Typography';
import UploadImage from 'components_v2/Upload/UploadImage/UploadImage';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';
import ClientSelector from './ClientSelector/ClientSelector';
import LocationsSelector from './LocationsSelector/LocationsSelector';
import TeamMembersSelector from './TeamMembersSelector/TeamMembersSelector';
import TransferServices from '../TransferServices/TransferServices.Container';
import { projectTypeOptions } from './helpers';
import useStyles from './AddProject.style';
import CurrencySelect from '../../../Inputs/CurrencySelect/CurrencySelect';
import withFormState from "containers/Wrappers/withFormState";
import { createLocationWithState } from "utils/routerHelpers";



const ModulesInputList = ({
  modules,
  setFieldValue,
  values,
  errors,
  touched,
  handleNewTeamMemberClick
}) => {
  const classes = useStyles();

  const handleAddModule = () => {
    setFieldValue('modules', [
      ...modules,
      { order: modules.length + 1, departments: [{ order: 1 }], teamMembers: [] },
    ]);
  };

  const getIndexFromName = (name, nameBase = 'name') =>
    parseInt(name.split(nameBase)[1]) - 1;

  const handleTextInputChange = (nameBase) => ({ target }) => {
    const { value, name } = target;

    const index = getIndexFromName(name, nameBase);

    // Copy modules
    const newModules = [...modules];

    // Find and copy correct module with updated value
    const newModule = { ...modules[index], [nameBase]: value };

    // Splice in new module
    newModules.splice(index, 1, newModule);

    // Set field values
    setFieldValue('modules', newModules);
  };

  const modulesInputs = modules.map((module, index) => {
    const order = index + 1;

    const handleTeamMembersDone = (teamMembers) => {
      const formatted = teamMembers.map((data) => data.id);
      const newModules = [...modules];
      newModules[index].teamMembers = formatted;
      setFieldValue('modules', newModules)
    };

    const handleTeamMembersDelete = (setSelectedItems) => (row) => {
      const newTeamMembers = values.modules[index].teamMembers.filter((id) => id !== row.id);
      const newModules = [...values.modules];
      newModules[index].teamMembers = newTeamMembers;

      setFieldValue('modules', newModules);
      setSelectedItems(newTeamMembers);
    };

    const handleAddDepartment = () => {
      const newModules = [...modules];
      const newModule = { ...modules[index] };
      const newDepartments = [
        ...newModule.departments,
        { order: newModule.departments.length + 1 },
      ];
      newModule.departments = newDepartments;
      newModules.splice(index, 1, newModule);

      setFieldValue('modules', newModules);
    };

    const handleDeleteModule = () => {
      const newModules = values.modules.filter(
        (module, moduleIndex) => moduleIndex !== index
      );
      setFieldValue('modules', newModules);
    };

    const departments = module.departments
      ? module.departments.map((department, departmentIndex) => {
          const departmentOrder = departmentIndex + 1;

          const handleDepartmentChange = ({ target }) => {
            const { value } = target;

            const newDepartment = { ...department, name: value };
            const newDepartments = [...module.departments];
            newDepartments.splice(departmentIndex, 1, newDepartment);

            const newModule = { ...module, departments: newDepartments };
            const newModules = [...modules];
            newModules.splice(index, 1, newModule);

            setFieldValue('modules', newModules);
          };

          const handleDeleteDepartment = () => {
            const newDepartments = values.modules[index].departments.filter(
              (department, filterIndex) => filterIndex !== departmentIndex
            );

            const newModules = values.modules.map((module, moduleIndex) => {
              if (moduleIndex === index)
                return { ...module, departments: newDepartments };
              return module;
            });

            setFieldValue('modules', newModules);
          };

          return (
            <>
              <Grid md={4} sm={6} xs={12}>
                <TextInput
                  name={`name${departmentOrder}`}
                  label="Department name"
                  value={department.name}
                  onChange={handleDepartmentChange}
                  placeholder="Enter department name"
                />
              </Grid>

              {!department.id ? (
                <>
                  <Grid
                    className={classes.removeDepartmentButtonGrid}
                    md={4}
                    sm={12}
                  >
                    <Button
                      variant="simple"
                      primary
                      onClick={handleDeleteDepartment}
                    >
                      Remove department
                    </Button>
                    <Box marginBottom="16px" />
                  </Grid>
                </>
              ) : (
                <Grid md={8} sm={6} xs={0} />
              )}
            </>
          );
        })
      : [];

    return (
      <Box className={classes.moduleInputs} key={module.id || module.order}>
        <Grid className={classes.gridContainer} container>
          <Grid item xs={12}>
            <Typography
              customVariant="bodyLargeRegular"
              style={{ marginBottom: 16 }}
            >
              {`Module ${order}`}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name={`name${order}`}
              label="Module name"
              value={module.name}
              onChange={handleTextInputChange('name')}
              placeholder="Enter module name"
              error={
                touched?.modules?.length &&
                touched?.modules[index]?.order &&
                errors?.modules?.length &&
                errors?.modules[index]?.name
              }
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAreaInput
              name={`description${order}`}
              label="Module description"
              value={module.description}
              onChange={handleTextInputChange('description')}
              placeholder="Enter module description"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              customVariant="bodyLargeRegular"
              style={{ marginBottom: 16, marginTop: 16 }}
            >
              Add departments to the module
            </Typography>
          </Grid>
          {departments}
        </Grid>
        <Grid className={classes.gridContainer} container>
          <Grid item md={3} sm={12}>
            <Button
              startIcon={<Add />}
              variant="contained"
              secondary
              onClick={handleAddDepartment}
            >
              {module.departments.length
                ? `Add another department`
                : `Add department`}
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container>
          <Grid item xs={12}>
            <Typography
              customVariant="bodyLargeRegular"
              style={{ marginBottom: 16, marginTop: 32 }}
            >
              Add team members to the module
            </Typography>
            <TeamMembersSelector
              incomingItems={values.modules[index].teamMembers}
              customHandleDelete={handleTeamMembersDelete}
              handleNewTeamMemberClick={handleNewTeamMemberClick}
              values={values}
              onDone={handleTeamMembersDone}
              setFieldValue={setFieldValue}
              index={index}
            />
          </Grid>
        </Grid>

        {!module.id ? (
          <Grid className={classes.gridContainer} container>
            <Grid style={{ marginTop: 16 }} xs={12}>
              <Button variant="contained" primary onClick={handleDeleteModule}>
                Remove module
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    );
  });

  return (
    <Box>
      {values.modules.length ? <Box>{modulesInputs}</Box> : null}
      <Box
        className={
          values.modules.length
            ? classes.addAnotherModuleButtonContainer
            : classes.addModuleButtonContainer
        }
      >
        <Button
          startIcon={<Add />}
          variant="contained"
          secondary
          onClick={handleAddModule}
        >
          {values.modules.length ? 'Add another module' : 'Add a module'}
        </Button>
      </Box>
    </Box>
  );
};

const geoCodeService = { current: null, geocode: null };

const InfoForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  classes,
  setSubtitle,
  formStep,
  projectId,
  isUpdate,
  history,
  location,
  setFormState
}) => {
  const statusOptions = [
    {
      name: 'Draft',
    },
    {
      name: 'Live',
    },
    {
      name: 'Archived',
    },
  ];  

  const handleNewTeamMemberClick = (index) => () => {
    const { pathname, state } = createLocationWithState(
      `/user/new`,
      location,
      values,
      null,
      true,
      'modules',
      { index }
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } });
  }

  React.useEffect(() => {
    if (!geoCodeService.current && window.google) {
      geoCodeService.geocode = new window.google.maps.Geocoder();
    }
    if (!geoCodeService.current) {
      return undefined;
    }
  }, []);

  React.useEffect(() => {
    if (!values.country) return undefined;

    geoCodeService.geocode.geocode(
      { address: values.country.name },
      (results, status) => {
        if (status === 'OK') {
          const coords = results[0]?.geometry?.viewport;
          const southWestBound = coords.getSouthWest();
          const northEastBound = coords.getNorthEast();

          const viewport = [
            { lat: southWestBound.lat(), long: southWestBound.lng() },
            { lat: northEastBound.lat(), long: northEastBound.lng() },
          ];

          setFieldValue('viewport', viewport);
        }
      }
    );
  }, [JSON.stringify(values.country)]);

  const handleClientDone = (client) => {
    const formatted = client.map((data) => data.id);
    setFieldValue('client', formatted);
  };

  const handleCountryChange = ({ target }) => {
    const { value: country, name } = target;
    setFieldValue(name, country);
  };

  const handleLocationsDone = (locations) => {
    const formatted = locations.map((data) => data.id);
    setFieldValue('locationInput', formatted);
  };

  const handleTimezoneChange = ({ target }) => {
    const { name, value } = target;
    setFieldValue(name, value && value.value);
  };

  const handleNameChange = ({ target }) => {
    const { value } = target;

    setFieldValue('name', value);
    setSubtitle(value);
  };

  return (
    <>
      <FormSection defaultExpanded title="Enter the main project information">
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <TextInput
              name="name"
              label="Project name *"
              placeholder="Enter project name"
              value={values.name}
              onChange={handleNameChange}
              error={touched.name && errors.name}
            />
          </Grid>
          {isUpdate && (
            <Grid item md={4} sm={6} xs={12}>
              <SelectInput
                name="status"
                label="Status *"
                placeholder="Select event status"
                value={values.status}
                options={statusOptions}
                onChange={handleChange}
                errorMessage={touched.status && errors.status}
                isError={touched.status && errors.status}
              />
            </Grid>
          )}
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid className={classes.projectTypeRow} item md={4} sm={6} xs={12}>
            <RadioGroupInput
              name="type"
              label="Project type *"
              value={values.type}
              onChange={handleChange}
              options={projectTypeOptions}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <DatepickerInput
              name="start"
              label="Start date *"
              placeholder="Select start date"
              value={values.start}
              onChange={handleChange}
              error={touched.start && errors.start}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <DatepickerInput
              name="end"
              label="End date *"
              placeholder="Select end date"
              value={values.end}
              onChange={handleChange}
              error={touched.end && errors.end}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <CountrySelect
              name="country"
              label="Country *"
              placeholder="Select one"
              value={values.country}
              onChange={handleCountryChange}
              isError={touched.country && errors.country}
              errorMessage={touched.country && errors.country}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TimezoneSelect
              name="timezone"
              label="Timezone *"
              placeholder="Select one"
              value={values.timezone}
              onChange={handleTimezoneChange}
              isError={touched.timezone && errors.timezone}
              errorMessage={touched.timezone && errors.timezone}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            <CurrencySelect
              name="currency"
              label="Currency *"
              placeholder="Select one"
              value={values.currency}
              onChange={handleChange}
              isError={touched.currency && errors.currency}
              errorMessage={touched.currency && errors.currency}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={3}>
          <Grid className={classes.logoRow} item md={4} sm={6} xs={12}>
            <Typography
              customVariant="bodySmallRegular"
              style={{ marginBottom: 8 }}
            >
              Upload event logo
            </Typography>
            <UploadImage
              placeholder="Add event logo"
              defaultPreviewImage={values.logoImageUrl}
              onComplete={(data) => {
                setFieldValue('logoImageUrl', data.url);
              }}
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection title="Select the key locations for the project">
        <LocationsSelector
          values={values}
          onDone={handleLocationsDone}
          setFieldValue={setFieldValue}
          formStep={formStep}
          projectId={projectId}
        />
      </FormSection>
      <FormSection title="Select the client and their person of contact">
        <ClientSelector
          values={values}
          onDone={handleClientDone}
          setFieldValue={setFieldValue}
          error={touched.client && errors.client}
          formStep={formStep}
        />
      </FormSection>
      <FormSection title="Select your transfer services">
        <TransferServices
          setFieldValue={setFieldValue}
          projectId={projectId}
          transferservices={values?.transferservices || []}

        />
      </FormSection>
      <FormSection title="Create your project modules">
        <ModulesInputList
          modules={values.modules}
          setFieldValue={setFieldValue}
          handleNewTeamMemberClick={handleNewTeamMemberClick}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
        />
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default withFormState(withRouter(InfoForm));
