import gql from 'graphql-tag';

export default gql`
  query GetAllByProject($id: ID!) {
    driveratings(where: { event: $id }) {
      id
      rating
      comment
      event {
        id
        start
      }
      passenger {
        id
        firstName
        lastName
        fullName
      }
      driver {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`;
