import "../../../Bookings/mobile-actions.css";

export const getHamburgerMenuItems = ({history}) => [
  {
    label: "Edit",
    onClick: (row) => history.push(`/supplier/${row.id}/edit`),
  },
];
export const getMobileMenuItems = ({history, row}) => ({
  right: [
    {
      text: " ",
      onClick: () => history.push(`/supplier/${row.id}/edit`),
      className: "mobile-actions-edit",
    },
  ],
});
