import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../App.helper';

export const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: '16px',
  },
  boxWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      textAlign: 'right',
    },

    letterSpacing: '0.3px',
    display: 'flex',
  },
  firstSection: {
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    paddingRight: '24px',
    "& p":{
      textAlign: 'right'
    }
  },
  secondSection: {
    paddingLeft: '24px',
    paddingRight: '32px',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingRight: '16px',
    },
  },
  heading: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    color: 'rgba(0, 0, 0, 0.5)',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: '12px',
    },
  },
  content: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '34px',
    color: '#000000',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: '14px',
      lineHeight: '134.3%',
      marginTop: '8px',
    },
  },
  datePickerWrapper: {
    cursor: 'pointer',
    background: ' #FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      fill: '#4001C5',
    },
    '& svg': {
      width: '40px',
      height: '40px',
      padding: '7px',
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: '48px',
      height: '48px',
      '& svg': {
        width: '48px',
        height: '48px',
        padding: '13px',
      },
    },
  },
}));
