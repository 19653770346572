import React, { useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';
import { getIsDataPresent } from './PieGraph.helper';

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 30}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const PieGraph = ({ data, isLegendsVisible }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onPieEnter = (data, index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {getIsDataPresent(data) ? <ResponsiveContainer minHeight={214}>
        <PieChart onMouseEnter={onPieEnter}>
          <Tooltip />
          {isLegendsVisible && <Legend verticalAlign="top" height={36} />}
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            onMouseEnter={onPieEnter}
            data={data}

          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer> :
        <div style={{padding: 16}}>No data yet available. Please check later.</div>
      }
    </>
  );
};

export default PieGraph;
