import { makeStyles } from '@material-ui/core/styles';

import { mobileViewBreakpoint } from '../../App.helper';


const baseSchedulerContainerStyles = {
  margin: '16px 0 56px 0',
  position: 'relative',
  '&>div>div>div>div:first-of-type>div>div>table td div': {
    color: 'rgba(0,0,0,0.9)',
    paddingBottom: 8,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '134.3%',
    letterSpacing: '0.3px',
  },
  '&>div>div>div>div:first-of-type': {
    background: 'transparent',
  },

  '&>div>div>div>div:first-of-type>div': {
    background: 'transparent',
  },
};

export const useStyles = makeStyles((theme) => ({
  dateNavigationButton: {
    pointerEvents: 'auto',
  },
  titleContainer: (props) => ({
    display: 'flex',
    justifyContent: props.label ? 'space-between' : 'flex-end',
    alignItems: 'center',
    marginBottom: 8,
  }),

  selectContainer: {
    display: 'flex',
    width: 150,
    marginRight: 'auto',
    marginTop: 24,
    '& > div': {
      width: '100%',
    },
  },
  monthSchedulerContainer: {
    ...baseSchedulerContainerStyles,
    '&>div>div>div>div:first-of-type>div>div>table td': {
      background: 'transparent',
      borderRight: 'none',
    },
  },
  daySchedulerContainer: {
    ...baseSchedulerContainerStyles,
    '&>div>div>div>div:last-of-type>div>div:first-of-type': {
      border: 'none',
      boxShadow: '0px 4px 2px 2px rgba(0, 0, 0, 0.05)',
      background: 'transparent',
    },
    '&>div>div>div>div:last-of-type>div>div>table td:nth-of-type(even)': {
      background: 'transparent',
    },
    '&>div>div>div>div:last-of-type>div>div:first-of-type td': {
      border: 'none',
    },
    '&>div>div>div>div:first-of-type>div>div:first-of-type': {
      border: 'none',
      background: 'transparent',
      borderBottom: '1px solid rgba(0, 0, 0, 0.02)',
    },
    '&>div>div>div>div:first-of-type>div>div:last-of-type': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    '&>div>div>div>div:first-of-type div': {
      borderBottom: 'none',
    },
  },
  weekSchedulerContainer: {
    ...baseSchedulerContainerStyles,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: '40px 0 56px 0',
      left: -40,
      width: '110%',
    },
    '&>div>div>div>div:first-of-type div': {
      borderBottom: 'none',
    },
    '&>div>div>div>div:last-of-type>div>div:first-of-type': {
      border: 'none',
      boxShadow: '0px 4px 2px 2px rgba(0, 0, 0, 0.05)',
      background: 'transparent',
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        background: '#fff',
      },
    },
    '&>div>div>div>div:last-of-type>div>div>table td:nth-of-type(odd)': {
      background: '#F5F6F8',
    },
    '&>div>div>div>div:last-of-type>div>div>table td:nth-of-type(even)': {
      background: 'transparent',
    },
    '&>div>div>div>div:last-of-type>div>div:first-of-type td': {
      border: 'none',
    },
    '&>div>div>div>div:first-of-type>div>div:first-of-type': {
      border: 'none',
      background: 'transparent',
    },
  },
  appointment: {
    backgroundColor: '#fff',
    color: 'rgba(0,0,0,0.9)',
  },
  timeIndicatorLine: (props) => ({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: props.top,
    height: '2px',
    borderTop: `2px #000 solid`,
    width: '100%',
    transform: 'translate(0, -1px)',
  }),
  timeIndicatorTime: (props) => ({
    left: -23,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    background: '#000',
    padding: 8,
    borderRadius: 3,
    top: props.top,
    zIndex: 100,
  }),
}));
