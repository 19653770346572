import React from "react";
import "../../../Bookings/mobile-actions.css";
import Typography from "components_v2/Typography/Typography";
import { getMultipleEventsData } from "utils/listData";
import EmailDisplay from '../../../EmailDisplay/EmailDisplay'
import PhoneDisplay from "../../../PhoneDisplay/PhoneDisplay";
import UserSelect from "components_v2/Inputs/UserSelect/UserSelect";
import AccessRightsPicker from "components_v2/Inputs/AccessRightsPicker/AccessRightsPicker";
import "./UserList.css"

export const getMobileMenuItems = ({ history, row }) => ({
  right: [
    {
      text: " ",
      onClick: () => {
        if (row?.user)
          history.push(`/user/${row?.user?.id}/edit`)
        else
          history.push(`/user/${row.id}/edit`)
      },
      className: "mobile-actions-edit",
    },
  ],
});

export const getHamburgerMenuItems = ({ history }) => [
  {
    label: "Edit",
    onClick: (row) => {
      if (row?.user)
        history.push(`/user/${row.user.id}/edit`)
      else
        history.push(`/user/${row.id}/edit`)
    }
  },
];

export const getColumns = (projectId) =>
  projectId
    ? [
      {
        field: "user.fullName",
        valuesField: "user",
        headerName: "Name",
        width: 3,
        customSelect: UserSelect,
        getValue: (values, column) => {
          const newValue = { ...values[column.valuesField] };

          // Formats the user row values so that the UserSelect component recognizes the value provided to it and displays the correctly selected user when editing a row.
          if (values.id) {
            if(newValue.fullName) {
              newValue.name = newValue.fullName;
              delete newValue.fullName;
            }
            delete newValue.isSuperAdmin;
            delete newValue.__typename;
          }

          return newValue || null;
        }
      },
      {
        field: "user.email",
        editModeField: ["user", "email"],
        headerName: "Email",
        width: 3,
        isEditDisabled: true,
        render: data => (
          <EmailDisplay email={data?.user?.email}></EmailDisplay>)
      },
      {
        field: "user.phone",
        editModeField: ["user", "phone"],
        headerName: "Phone",
        width: 2,
        isEditDisabled: true,
        hideMobile: true,
        render: (data) => (
          <PhoneDisplay phone={data?.user?.phone}></PhoneDisplay>
        ),
      },
      {
        field: "user.company",
        editModeField: ["user", "company"],
        headerName: "Company",
        width: 2,
        isEditDisabled: true,
        hideMobile: true,
        render: (data) => (
          <Typography customVariant="bodySmallRegular">{`${data?.user?.company || ""
            }`}</Typography>
        ),
      },
      {
        field: "accessRight.name",
        valuesField: "accessRight",
        headerName: "Role",
        width: 2,
        hideMobile: true,
        isSortable: false,
        customSelect: AccessRightsPicker,
        isDisabled: () => {},
        getValue: (values, column) => {
          return values[column.valuesField] || null;
        }
        // render: (data) => (
        //   <Typography customVariant="bodySmallRegular">
        //     <>
        //       <>{data.user.isSuperAdmin && <>Super Admin</>} </>
        //       <>
        //         {!data.user.isSuperAdmin && data.accessRight
        //           ? data.accessRight.name
        //           : ""}
        //       </>
        //     </>
        //   </Typography>
        // ),
      },
    ]
    : [
      {
        field: "fullName",
        headerName: "Name",
        width: 3,
        render: (data) => (
          <Typography customVariant="bodySmallRegular">{`${data.fullName}`}</Typography>
        ),
      },
      {
        field: "email",
        headerName: "Email",
        width: 3,
        render: data => (
          <EmailDisplay email={data.email}></EmailDisplay>)
      },
      {
        field: "phone",
        headerName: "Phone",
        width: 2,
        render: (data) => (
          <PhoneDisplay phone={data.phone}></PhoneDisplay>
        ),
      },
      {
        field: "company",
        headerName: "Company",
        width: 2,
        hideMobile: true,
      },
      {
        field: "userEvents",
        headerName: "Projects",
        width: 2,
        isSortable: false,
        hideMobile: true,
        render: (data) => {
          const projectNames = (data.userEvents.map((userEvent) => (<Typography customVariant="bodySmallRegular">{userEvent.event.name}</Typography>))).slice(1);
          const [firstEvent, otherEventsCount] = getMultipleEventsData(
            data.userEvents
          );
          
          return otherEventsCount ? (
            <>
              <Typography customVariant="bodySmallRegular">
                {firstEvent}
              </Typography>
              <div className="user-list">{`+ ${otherEventsCount} more`}
                <div className="user-projects">
                  {projectNames}
                </div>
              </div>
            </>
          ) : (
            <Typography customVariant="bodySmallRegular">
              {firstEvent}
            </Typography>
          );
        },
      },
    ];
