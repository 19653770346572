const getCountValue = (count) => count >= 0 ? count : undefined;

export const getCountData = (countData) => {
  const finalData = {}

  if (!countData) return finalData;

  finalData.completedRidesCount = getCountValue(countData?.passengerScheduleCount?.completeRides);
  finalData.onGoingRidesCount = getCountValue(countData?.passengerScheduleCount?.ongoingRides);
  finalData.progressPercentage = countData?.passengerScheduleCount?.progressPercentage || 0;

  return finalData;
};