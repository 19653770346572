import { makeStyles } from "@material-ui/core";
import React from "react";
import ViewDocuments from "./ViewDocuments";
import Typography from "components_v2/Typography/Typography";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})

const ViewDocumentsOption = ({ data }) => {
  const classes = useStyles();
  return <>
    <ViewDocuments
      bookingId={data.bookingId}
      incomingItems={data.savedDocuments}
      data={data.savedDocuments}
      customButton={(toggleModal) => (
        <div
          id={`view-booking-documents-action-button-${data.id}`}
          className={classes.item}
          onClick={() => {
            toggleModal()
          }}>
          <Typography
            customVariant="bodySmallRegular"
          >
            View Documents
          </Typography>
        </div>
      )}
    />

  </>
}

export default ViewDocumentsOption