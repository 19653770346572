import React, { useState } from "react";
import moment from "moment";
import momentTimezone from "moment-timezone";
import DatepickerButton from "components_v2/Inputs/DatepickerButton/DatepickerButton";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import InputLabel from "../InputLabel/InputLabel";
import { useStyles } from "./Timezone.styles";
import { getSelectedDateRange } from "./TimezoneHelper";
import { mobileViewBreakpoint } from "../../../App.helper";

const Timezone = ({
  name,
  value, // Do not send null here. Either send undefined, do not send the key at all or send a proper value
  timezone,
  onChange,
  label,
  style,
  viewType = "week"
}) => {
  let timeoutId = null;
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const classes = useStyles();
  const [timezoneData, setTimezoneData] = useState({
    fullDate: null,
    offset: null,
    time: null,
    selectedDate: null,
    selectedDateRange: null
  });
  const processTimezoneData = val => {
    const fullDate =
      (timezone &&
        momentTimezone(val || undefined)
          .tz(timezone)
          .format()) ||
      moment(val || undefined).format();
    const offset = fullDate.substring(19);

    const time =
      (timezone &&
        momentTimezone(fullDate || undefined)
          .tz(timezone)
          .format("HH:mm")) ||
      moment(fullDate || undefined).format("HH:mm");

    const selectedDate = moment(fullDate || undefined).format("D MMM");
    const selectedDateRange = getSelectedDateRange(fullDate);
    setTimezoneData({
      fullDate,
      offset,
      time,
      selectedDate,
      selectedDateRange
    });
  };

  const handleChange = event => {
    processTimezoneData(event.target.value);
    clearInterval(timeoutId);
  };
  React.useEffect(() => {
    if (!value || timezoneData.fullDate !== value) {
      timeoutId = setInterval(() => {
        //processTimezoneData(value);
      }, 1000);
      processTimezoneData(value);
    }
    return () => {
      clearInterval(timeoutId);
    };
  }, [value, timezone]);
  React.useEffect(() => {
    if (
      timezoneData.fullDate &&
      moment(timezoneData.fullDate).format() !== moment(value).format() &&
      onChange
    ) {
      onChange({
        persist: () => {},
        target: {
          type: "change",
          name,
          value: timezoneData.fullDate
        }
      });
    }
  }, [timezoneData]);

  return (
    <div className={classes.inputWrapper} style={{ ...style }}>
      <InputLabel label={label} />
      <Box display="flex" alignItems="center">
        <div className={classes.boxWrapper}>
          {!isMobileView && (
            <Box
              display="flex"
              flexDirection="column"
              className={classes.firstSection}
            >
              <Typography className={classes.heading}>
                UTC {timezoneData.offset && timezoneData.offset[0]}{" "}
                {timezoneData.offset && timezoneData.offset.substring(1)}
              </Typography>
              <Typography className={classes.content}>
                {timezoneData.time}
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            className={classes.secondSection}
          >
            <Typography className={classes.heading}>
              {viewType === "day" ? "Today" : "Week Of"}
            </Typography>
            <Typography className={classes.content}>
              {viewType === "day"
                ? timezoneData.selectedDate
                : timezoneData.selectedDateRange}
            </Typography>
          </Box>
        </div>
        <Box
          className={classes.datePickerWrapper}
          display="flex"
          alignItems="center"
        >
          <DatepickerButton
            name={name}
            placeholder="Please enter your show date button"
            value={timezoneData.fullDate}
            onChange={handleChange}
            tooltip="Change date"
          />
        </Box>
      </Box>
    </div>
  );
};

export default Timezone;
