import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {Messages} from '../Icons/Icons';
import classNames from 'classnames';
import CustomizedBadges from '../Badge/Badge';
import { useStyles } from './MessagesTool.styles';

const MessagesTool = ({ messagesCount, onClick, currentTool }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classNames(classes.iconButton, {
        active: currentTool === 'messages',
      })}
      onClick={onClick}
    >
      <CustomizedBadges
        position="top"
        value={messagesCount}
        children={<Messages />}
      />
      <span
        className={classNames(classes.badgeButtonText, {
          active: currentTool === 'messages',
        })}
      >
        Messages
      </span>
    </IconButton>
  );
};

export default MessagesTool;
