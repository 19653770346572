import * as React from "react";
import { useState, useEffect } from "react";
import Vehicle from "../../Vehicle/Vehicle";
import { BaseControl, FlyToInterpolator } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import "./ActiveMarker.css";

// https://codesandbox.io/s/sleepy-elion-v4mpc?file=/src/index.js

export default function CustomMarkerContainer(props) {
  const [location, setLocation] = useState({
    lat: props.lat || 0.0,
    long: props.long || 0.0,
    h: 0
  });

  useEffect(() => {
    props.socket.on(props.id, function(data) {
      setLocation(data);

      if (props.trackedVehicle) {
        props.setViewport({
          ...props.viewport,
          latitude: data.lat,
          longitude: data.long,
          transitionDuration: 500,
          transitionInterpolator: new FlyToInterpolator()
        });
      }
    });
  }, []);

  return (
    <CustomMarker
      {...props}
      longitude={location.long}
      latitude={location.lat}
      heading={location.h}
    />
  );
}

class CustomMarker extends BaseControl {
  _render() {
    const {
      longitude,
      latitude,
      setActivePopover,
      id,
      isBusy,
      vehicleType,
      label,
      heading
    } = this.props;

    //console.log("the driver is here =>>");
    //console.log(driver.identifier);

    const [x, y] = this._context.viewport.project([longitude, latitude]);

    const rotate = heading - 90 || 0;

    const markerStyle = {
      position: "absolute",
      left: x,
      top: y,
      // transform: "rotate(" + heading + "deg)"
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`
    };

    if (longitude && latitude) {
      return (
        <>
          <Vehicle
            className="active-marker"
            onClick={() => setActivePopover(id)}
            ref={this._containerRef}
            //type="Sedan"
            rotate={rotate}
            isBusy={isBusy}
            type={vehicleType}
            label={label}
            customStyle={markerStyle}
            distance=""
            //label={driver?.identifier}
          />
        </>
      );
    }
    return null;
  }
}
