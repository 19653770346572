import * as React from 'react';


import 'mapbox-gl/dist/mapbox-gl.css';
import { useQuery } from '@apollo/react-hooks';
import GetAllRecentLocationsByDriverId from 'gql/Locations/GetAllRecentLocationsByDriverId';
import PolylineOverlay from './PolylineOverlay';

export default function RouteHistory({ driverId }) {
  const { data, loading } = useQuery(GetAllRecentLocationsByDriverId, {
    variables: { id: driverId },

    fetchPolicy: 'no-cache',
  });

  if (loading) return <></>;

  const points = data.locations.map((r) => [r.long, r.lat]);

  return <PolylineOverlay lineWidth={5} points={points} />;
}
