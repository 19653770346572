export const mockData = {
  user: {
    id: '5f67c3884dcb62e24cb4cbd8',
    userEvents: [
      {
        id: '5f67c38d4dcb62e24cb4cbd9',
        event: {
          id: '5f270baecc54999de0a9f579',
          name: 'NBA Finals',
          start: '2020-06-18',
          end: '2020-07-09',
          inChargeName: '',
          inChargeEmail: '',
          inChargeMobile: '',
          client: '',
          archived: false,
          alerts: [
            {
              id: '5fb6b09cdcf6a8228d57c6a2',
              status: 'unresolved',
              description: 'Car has been delayed due to traffic.',
              created_at: '2020-11-19T17:51:24.564Z',
              event: {
                id: '5f270baecc54999de0a9f579',
                name: 'NBA Finals',
                __typename: 'Event',
              },
              __typename: 'Alerts',
            },
          ],
          __typename: 'Event',
        },
        role: 'client',
        user: {
          id: '5f67c3884dcb62e24cb4cbd8',
          isSuperAdmin: true,
          __typename: 'UsersPermissionsUser',
        },
        accessRight: null,
        __typename: 'Userevent',
      },
      {
        id: '5f67c38d4dcb62e24cb4cbd9',
        event: {
          id: '5f270baecc54999de0a9f579',
          name: 'FIFA',
          start: '2020-06-18',
          end: '2020-07-09',
          inChargeName: '',
          inChargeEmail: '',
          inChargeMobile: '',
          client: '',
          archived: false,
          alerts: [
            {
              id: '5fb6b09cdcf6a8228d57c6a2',
              status: 'unresolved',
              created_at: '2020-11-19T17:51:24.564Z',
              event: {
                id: '5f270baecc54999de0a9f579',
                name: 'FIFA',
                __typename: 'Event',
              },
              __typename: 'Alerts',
            },
          ],
          __typename: 'Event',
        },
        role: 'client',
        user: {
          id: '5f67c3884dcb62e24cb4cbd8',
          isSuperAdmin: true,
          __typename: 'UsersPermissionsUser',
        },
        accessRight: null,
        __typename: 'Userevent',
      },
      {
        id: '5f67c38d4dcb62e24cb4cbd9',
        event: {
          id: '5f270baecc54999de0a9f579',
          name: 'Nascar',
          start: '2020-06-18',
          end: '2020-07-09',
          inChargeName: '',
          inChargeEmail: '',
          inChargeMobile: '',
          client: '',
          archived: false,
          alerts: [
            {
              id: '5fb6b09cdcf6a8228d57c6a2',
              status: 'unresolved',
              description: 'Car has been delayed due to traffic.',
              created_at: '2020-11-19T17:51:24.564Z',
              event: {
                id: '5f270baecc54999de0a9f579',
                name: 'Nascar',
                __typename: 'Event',
              },
              __typename: 'Alerts',
            },
          ],
          __typename: 'Event',
        },
        role: 'client',
        user: {
          id: '5f67c3884dcb62e24cb4cbd8',
          isSuperAdmin: true,
          __typename: 'UsersPermissionsUser',
        },
        accessRight: null,
        __typename: 'Userevent',
      },
    ],
    __typename: 'UsersPermissionsUser',
  },
};

export const mockEventDataPS = {
  user: {
    id: '5f67c3884dcb62e24cb4cbd8',
    userEvents: [
      {
        id: '5f67c38d4dcb62e24cb4cbd9',
        event: {
          id: '5f270baecc54999de0a9f579',
          passengerschedules: [
            {
              id: '5f29c23b678370f78808adbc',
              driverschedules: [
                {
                  id: '5f2d299d16f55c9d3ce20642',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f29c299678370f78808adbd',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f29c394678370f78808adbe',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f29c4ad0693e9ac508e9368',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f29c5c280d8bf1149844569',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f29c6297b1dcffab432958e',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f2d2a0f16f55c9d3ce20643',
              driverschedules: [
                {
                  id: '5f2d2a2116f55c9d3ce20644',
                  ridestatuses: [
                    {
                      id: '5f3ae80f0574f89ed01a3053',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3ae80f0574f89ed01a3054',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3ae80f0574f89ed01a3055',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3ae8120574f89ed01a3056',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3ae8120574f89ed01a3057',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3ae8130574f89ed01a3058',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d549cb602c73c11aa57',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d569cb602c73c11aa58',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d579cb602c73c11aa59',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d599cb602c73c11aa5a',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d6d9cb602c73c11aa5b',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d6f9cb602c73c11aa5c',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d709cb602c73c11aa5d',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d719cb602c73c11aa5e',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692d729cb602c73c11aa5f',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f2dd487d9045982b0e8bb32',
              driverschedules: [
                {
                  id: '5f2dd49bd9045982b0e8bb33',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f343f9a99a4c119f075949a',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f35b5683825eee594a1df2d',
              driverschedules: [
                {
                  id: '5f3d5c264c2533001783b4a1',
                  ridestatuses: [
                    {
                      id: '5fa9f52cd19cb05218398ac6',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fa9f5d7d19cb05218398ac7',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fa9f5dbd19cb05218398ac8',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fa9f5ecd19cb05218398ac9',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fa9f5f1d19cb05218398aca',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
                {
                  id: '5f3d5c294c2533001783b4a2',
                  ridestatuses: [
                    {
                      id: '5f3d5ca54c2533001783b4b2',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3d5ca54c2533001783b4b3',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3d5ca64c2533001783b4b4',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3d5ca84c2533001783b4b5',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3d5ca94c2533001783b4b6',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3d5ca94c2533001783b4b7',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f6927fec812aff65494cc5b',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f6927ffc812aff65494cc5c',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692800c812aff65494cc5d',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692801c812aff65494cc5e',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692812c812aff65494cc5f',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692812c812aff65494cc60',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692813c812aff65494cc61',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692814c812aff65494cc62',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f692815c812aff65494cc63',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f3d03744c2533001783b491',
              driverschedules: [
                {
                  id: '5f3d5c4b4c2533001783b4a3',
                  ridestatuses: [
                    {
                      id: '5f3d5c8b4c2533001783b4a6',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69275692708b11497e3a0a',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69275792708b11497e3a0b',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69275892708b11497e3a0c',
                      status: 'noShow',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69275a92708b11497e3a0e',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f3d5a7e4c2533001783b499',
              driverschedules: [
                {
                  id: '5f3d5c5f4c2533001783b4a4',
                  ridestatuses: [
                    {
                      id: '5f3d5c904c2533001783b4a7',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f3d5c904c2533001783b4a8',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274392708b11497e3a00',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274492708b11497e3a01',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274592708b11497e3a02',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274692708b11497e3a03',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274692708b11497e3a04',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274792708b11497e3a05',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274792708b11497e3a06',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274892708b11497e3a07',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274992708b11497e3a08',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5f69274a92708b11497e3a09',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f46b99a01c7565a84baeb0d',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f46b9eb90242c35c80b991c',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f46bb33e00cc47588a6b8a8',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f48edc490ca8ec1a4d3b802',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f48ee3b90ca8ec1a4d3b803',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f877dbec340b80017eb3272',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f88cccfd1ff2d00170d3a93',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f88cd86d1ff2d00170d3a97',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fa9f4ef7f2ba80017dfd6a2',
              driverschedules: [
                {
                  id: '5fa9f6b07f2ba80017dfd6a3',
                  ridestatuses: [
                    {
                      id: '5fab2ff94bdef85c64ef3a16',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fab2ffe4bdef85c64ef3a17',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fab30064bdef85c64ef3a18',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fab300a4bdef85c64ef3a19',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fab309a871e56ce08b23ab6',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac38a559c9628d52d02ddd',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac38a659c9628d52d02dde',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac78c57f99e48e42dec243',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac78c77f99e48e42dec244',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac834a7f99e48e42dec245',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac834b7f99e48e42dec246',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac850f7f99e48e42dec247',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac85117f99e48e42dec248',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac86097f99e48e42dec249',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac862f7f99e48e42dec24a',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac86607f99e48e42dec24b',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac86767f99e48e42dec24c',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac86847f99e48e42dec24d',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac87177f99e48e42dec24e',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac8bdb7f99e48e42dec24f',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac8bdd7f99e48e42dec250',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac92467f99e48e42dec251',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac93b87f99e48e42dec252',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac93ba7f99e48e42dec253',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fac93f47f99e48e42dec254',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5faca0c463a33e5600a5aff5',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5faca0c663a33e5600a5aff6',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5faca41463a33e5600a5aff7',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5faca41663a33e5600a5aff8',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5faca50363a33e5600a5aff9',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5faca50563a33e5600a5affa',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fad588eab25f7bba82ae79b',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fad588fab25f7bba82ae79c',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb066f472585195a0ba6bdc',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb070ca6347f78020001f82',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb0711d7d0d668664730319',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb0717f72e5cb95f424ee14',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb0719672e5cb95f424ee15',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb072f78cef9b841070c4fe',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb07362337dff63a47e4313',
              driverschedules: [
                {
                  id: '5fb07363337dff63a47e4315',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
                {
                  id: '5fb07363337dff63a47e4316',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
                {
                  id: '5fb07363337dff63a47e4314',
                  ridestatuses: [
                    {
                      id: '5fb087ba337dff63a47e4319',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb087c6337dff63a47e431b',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb087ca337dff63a47e431c',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb087ce337dff63a47e431d',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb087d2337dff63a47e431e',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb0887a337dff63a47e431f',
              driverschedules: [
                {
                  id: '5fb0887b337dff63a47e4320',
                  ridestatuses: [
                    {
                      id: '5fb08aa9337dff63a47e433d',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08aac337dff63a47e433e',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08aaf337dff63a47e433f',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08ab3337dff63a47e4340',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08ab6337dff63a47e4341',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb08d28974cce3d04ca20f7',
              driverschedules: [
                {
                  id: '5fb08d29974cce3d04ca20f8',
                  ridestatuses: [
                    {
                      id: '5fb08d80619934268c1b12c1',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08d9e619934268c1b12c4',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08da2619934268c1b12c5',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08da5619934268c1b12c6',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb08da9619934268c1b12c7',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb08db6619934268c1b12c8',
              driverschedules: [
                {
                  id: '5fb08db7619934268c1b12c9',
                  ridestatuses: [
                    {
                      id: '5fb112f37fee2b9d34c26910',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb112f97fee2b9d34c26913',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb112fd7fee2b9d34c26914',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb113007fee2b9d34c26915',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb113047fee2b9d34c26916',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb08e1a8834b08608014191',
              driverschedules: [
                {
                  id: '5fb08e1a8834b08608014192',
                  ridestatuses: [
                    {
                      id: '5fb11c2a7fee2b9d34c2692f',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb11cef7fee2b9d34c26931',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb11d057fee2b9d34c26932',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb11d0b7fee2b9d34c26933',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb11d107fee2b9d34c26934',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb168e7270f43c7aca9a630',
              driverschedules: [
                {
                  id: '5fb168e7270f43c7aca9a631',
                  ridestatuses: [
                    {
                      id: '5fb198aaffbca9859078c23e',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb198aeffbca9859078c23f',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb198b3ffbca9859078c240',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb198b8ffbca9859078c241',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19936ffbca9859078c242',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb169705731e4c5b0d46f60',
              driverschedules: [
                {
                  id: '5fb169705731e4c5b0d46f61',
                  ridestatuses: [
                    {
                      id: '5fb19ade7736b1af84a5475e',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19ae47736b1af84a5475f',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19af57736b1af84a54760',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19afa7736b1af84a54761',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19aff7736b1af84a54762',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb16abf7f27a78d48ffa023',
              driverschedules: [
                {
                  id: '5fb16abf7f27a78d48ffa024',
                  ridestatuses: [
                    {
                      id: '5fb19b0a7736b1af84a54763',
                      status: 'enroute',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19b0e7736b1af84a54764',
                      status: 'arrivedToPickup',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19b4df335b743b4152c31',
                      status: 'passengerInVehicle',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19b51f335b743b4152c32',
                      status: 'arrivedToDestination',
                      __typename: 'Ridestatus',
                    },
                    {
                      id: '5fb19b6df335b743b4152c33',
                      status: 'complete',
                      __typename: 'Ridestatus',
                    },
                  ],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb16dd57eae2e36ccda7f5d',
              driverschedules: [
                {
                  id: '5fb16dd57eae2e36ccda7f5e',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb16e69a32af0c70c076ced',
              driverschedules: [
                {
                  id: '5fb16e69a32af0c70c076cee',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb16f57a32af0c70c076cef',
              driverschedules: [
                {
                  id: '5fb16f57a32af0c70c076cf0',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb18d79df0a8643d215183b',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb18f84df0a8643d2151844',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb18ffbdf0a8643d2151849',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb1911fdf0a8643d215184e',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb19258df0a8643d2151853',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb19291a32af0c70c076cf1',
              driverschedules: [
                {
                  id: '5fb19292a32af0c70c076cf2',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb19cb15f13c35a67a4c673',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb30886caf9500017db7329',
              driverschedules: [
                {
                  id: '5fb30886caf9500017db732b',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
                {
                  id: '5fb30886caf9500017db732a',
                  ridestatuses: [],
                  __typename: 'Driverschedule',
                },
              ],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb7fc1bf8879d52b947ecd4',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fb801f0f8879d52b947ecd5',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fc0057da4e9afb9e1acfaa5',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fc01127a4e9afb9e1acfaa6',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fc153c72b7739e42882b204',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fc1541d2b7739e42882b205',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fc154592b7739e42882b206',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fceb298bb90f74678e65dc2',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5fd25401888ee65e5e674ca0',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
          ],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f67c3914dcb62e24cb4cbda',
        event: {
          id: '5f2711db7442154fd8a8c137',
          passengerschedules: [],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f84647609e0750017f3b1bf',
        event: {
          id: '5f78f276e57eac0017f8a61b',
          passengerschedules: [],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f84647709e0750017f3b1c0',
        event: {
          id: '5f78f25be57eac0017f8a613',
          passengerschedules: [],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f84648609e0750017f3b1c1',
        event: {
          id: '5f5df3ceaccf370017bfc77f',
          passengerschedules: [],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f84648709e0750017f3b1c2',
        event: {
          id: '5f5df418accf370017bfc787',
          passengerschedules: [],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f908dae3b770f00177a4669',
        event: {
          id: '5f84770209e0750017f3b1c4',
          passengerschedules: [
            {
              id: '5f8478d109e0750017f3b1d9',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8478f709e0750017f3b1db',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f84794a09e0750017f3b1dd',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f84798509e0750017f3b1df',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8479bf09e0750017f3b1e1',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8479ef09e0750017f3b1e3',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847a1709e0750017f3b1e5',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847a6009e0750017f3b1e7',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847b3809e0750017f3b1eb',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847b7109e0750017f3b1ed',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847c1709e0750017f3b1f1',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847c6109e0750017f3b1f3',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847c9f09e0750017f3b1f5',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847cec09e0750017f3b1f9',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847d4309e0750017f3b1fb',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847da109e0750017f3b1fd',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f847e1909e0750017f3b1ff',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8484ae09e0750017f3b201',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f84850c09e0750017f3b203',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f84854609e0750017f3b205',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8487da09e0750017f3b20d',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f84885909e0750017f3b20f',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8488f109e0750017f3b211',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
            {
              id: '5f8489fd09e0750017f3b217',
              driverschedules: [],
              __typename: 'Passengerschedule',
            },
          ],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
      {
        id: '5f908daf3b770f00177a466a',
        event: {
          id: '5f84770109e0750017f3b1c3',
          passengerschedules: [],
          __typename: 'Event',
        },
        __typename: 'Userevent',
      },
    ],
    __typename: 'UsersPermissionsUser',
  },
};
