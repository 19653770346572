import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../../../App.helper';

const useStyles = makeStyles((theme) => ({
  mapRow: {
    height: 500,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: 300,
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: 300,
    },
  },
}));

export default useStyles;
