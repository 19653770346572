import { productIconSelectionOptions } from 'utils/iconHelpers';


export const productSelectionColumns = [
  {
    field: 'icon',
    headerName: 'Icon',
    width: 2,
    type: 'icon',
    options: productIconSelectionOptions,
    label: 'Choose Icon',
  },
  {
    field: 'name',
    headerName: 'Product name',
    width: 2,
  },
  {
    field: "price",
    headerName: "Price",
    width: 2,
    type: "number"
  },

  {
    field: "description",
    headerName: "Description",
    width: 2,
    hideMobile: true
  },

  {
    field: "clientPricing",
    headerName: "Client Price",
    width: 2,
    type: "number"
  },

  {
    field: "supplierPricing",
    headerName: "Supplier Price",
    width: 2,
    type: "number"
  }
];

export const productSelectedColumns = [
  {
    field: 'icon',
    headerName: 'Icon',
    width: 2,
    type: 'icon',
    options: productIconSelectionOptions,
    label: 'Choose Icon',
  },
  {
    field: 'name',
    headerName: 'Product name',
    width: 2,
  },
  {
    field: "price",
    headerName: "Price",
    width: 2,
    type: "number"
  },

  {
    field: "description",
    headerName: "Description",
    width: 2,
    hideMobile: true
  },

  {
    field: "clientPricing",
    headerName: "Client Price",
    width: 2,
    type: "number"
  },

  {
    field: "supplierPricing",
    headerName: "Supplier Price",
    width: 2,
    type: "number"
  }
];