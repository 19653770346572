import gql from "graphql-tag";

export default gql`
  query GetClient($id: ID!) {
    clients(where: { id: $id }) {
      id
      companyName
      address
      lat
      long
      phone
      location
      contactName
      email
      contactDepartment
      contactPosition
      officeType
    }
  }
`;
