import React from "react";
import ListItem from "@material-ui/core/ListItem";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { Alert, Car, Busy } from "../../Icons/Icons";
import { useStyles } from "./AlertCard.styles";

const AlertCard = ({ cardDetails, history, isAlertPage }) => {
  const classes = useStyles();

  const getTypeIcon = type => {
    switch (type) {
      case "alert":
        return <Alert className={classes.iconTypeAlert} />;
      case "cancel":
        return <Car />;
      case "delay":
        return <Busy className={classes.iconTypeDelay} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <ListItem
        alignItems="flex-start"
        className={isAlertPage ? classes.alertPageCardItem : classes.cardItem}
      >
        <div
          className={classes.rightContent}
          onClick={() =>
            cardDetails.event &&
            history.push(`/project/${cardDetails.event.id}/alerts`)
          }
        >
          <Grid container>
            <Grid item xs={10} className={classes.basicInfo}>
              <div className={classes.content}>
                {isAlertPage ? (
                  <>
                    {cardDetails?.postedBy && (
                      <div className={classes.from}>
                        {cardDetails.postedBy.firstName}{" "}
                        {cardDetails.postedBy.lastName}
                      </div>
                    )}
                    {cardDetails.message}
                  </>
                ) : (
                  <>
                    {cardDetails?.event?.name && (
                      <div className={classes.from}>
                        {cardDetails.event.name}
                      </div>
                    )}
                    {cardDetails.description}
                  </>
                )}
              </div>
              {cardDetails.location && (
                <div className={classes.location}>
                  Location: {cardDetails.location}
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              <div className={classes.time}>
                {moment
                  .utc(
                    cardDetails?.created_at || cardDetails?.postedBy?.created_at
                  )
                  .format("DD/MM/YYYY [at] HH:mm")}
              </div>
              {getTypeIcon(cardDetails.type)}
            </Grid>{" "}
          </Grid>
        </div>
      </ListItem>
    </>
  );
};

export default withRouter(AlertCard);
