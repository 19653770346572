import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GetAccessRight from 'gql/AccessRights/GetOne';
import gql from 'graphql-tag';
import AccessRightsDetailsComponent from './AccessRightsDetails.Component';

const AccessRightsDetailsContainer = ({ accessRightsId }) => {
  const { data } = useQuery(GetAccessRight, {
    variables: { id: accessRightsId },
  });

  const UPDATE_GQL = gql`
    mutation UpdateAccessright($id: InputID!, $data: editAccessrightInput) {
      updateAccessright(input: { where: $id, data: $data }) {
        accessright {
          id:
        }
      }
    }
  `;
  const [save] = useMutation(
    UPDATE_GQL,
    {
      variables: { id: { id: accessRightsId } },
    }
  );
  return (
    <AccessRightsDetailsComponent
      save={save}
      data={(data && data.accessrights[0]) || {}}
    />
  );
};
export default AccessRightsDetailsContainer;
