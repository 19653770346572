import { useQuery } from '@apollo/react-hooks';
import GetBookingsByEventAndDate from 'gql/PassengerSchedules/GetAllByEventAndDate';
import moment from 'moment';
import SchedulerComponent from './Scheduler.Component';
import React, { useState } from "react";
import { getFilteredSchedules } from './helpers';

const SchedulerContainer = (props) => {
  const {
    isHelpTutorial,
    projectId,
    selectedDate,
    view,
    defaultView,
  } = props;

  const viewType = view || defaultView;

  const [filterKey, setFilterKey] = useState('');


  const getDateRange = (selectedDate, viewType) => {
    if (viewType === 'week')
      return {
        start: moment.utc(selectedDate).day(0).startOf('day').toISOString(),
        end: moment.utc(selectedDate).day(7).startOf('day').toISOString(),
      };
    if (viewType === 'day')
      return {
        start: moment.utc(selectedDate).startOf('day').toISOString(),
        end: moment.utc(selectedDate).endOf('day').toISOString(),
      };

    return {
      start: moment.utc().startOf('day').toISOString(),
      end: moment.utc().endOf('day').toISOString(),
    };
  };

  const { start, end } = getDateRange(selectedDate, viewType);

  const variables = { id: projectId, start, end };
  const { data = [], loading } = useQuery(GetBookingsByEventAndDate, {
    variables,
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial || props.type === 'project',
  });
  const [results, setResults] = React.useState([]);
  React.useEffect(() => {
    setResults(data?.passengerschedules || props.data);
  }, [data]);

  const handleChangeFilter = ({val}) => {
    setFilterKey(val)
  }

  let finalData = getFilteredSchedules({ schedules: results, filterKey });



  return (
    <SchedulerComponent
      {...props}
      data={finalData}
      numItems={data?.passengerSchedulesData?.passengerschedules?.length || 0}
      loading={loading}
      onDelete={id => {
        const newResults = results.filter(x => x.id !== id);
        setResults(newResults);
      }}
      onChangeFilter={handleChangeFilter}
    />
  );
};

export default SchedulerContainer;
