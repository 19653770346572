import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import GetAllByProject from 'gql/Passengers/GetAllByEvent.js';
import PassengersComponent from './Passengers.Component';
import { mockData } from './Passengers.mock';

const PassengersContainer = ({ projectId, isHelpTutorial }) => {
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })
  let { data, loading } = useQuery(GetAllByProject, {
   
    variables: { id: projectId,...listConfig, sort: listConfig.sort || 'id:desc'},
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }
 
  const currentData = data?.usersConnection?.values  || [];
  const count = data?.usersConnection?.aggregate?.count|| 0;
  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }
  return (
    <PassengersComponent
    count={count}
    listConfig={listConfig}
    handleListConfigChange={handleListConfigChange}
    data={currentData  || []} 
      loading={loading}
      projectId={projectId}
    />
  );
};


export default PassengersContainer;
