import React, { Component } from "react";
import auth from "../../utils/auth";
import { withSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import moment from "moment";

class MyComponent extends Component {
  constructor(props) {
    super(props);

    this.eventId = localStorage.getItem("selectedEvent");
    var user = auth.get("userInfo");
    this.pubnub = null;

    if (user && user.id) {
      /*this.pubnub = new PubNubReact({
        uuid: user.id,
        publishKey: "pub-c-016609f4-c43a-4099-b05c-e23085c1f98c",
        subscribeKey: "sub-c-95ee1298-6201-11e9-ae53-666e2ad6cdf0"
      });
      this.pubnub.init(this);*/
    }

    this.state = {};
  }

  fetchMessages = async (channels, count) => {
    return await this.pubnub.fetchMessages({ channels, count });
  };

  sendAlert = async (eventId, message, url, variant, severity) => {
    await this.pubnub.publish({
      message: { text: message, url, variant, severity },
      channel: eventId + ".alerts"
    });
  };

  componentWillMount() {
    const action = key => (
      <>
        <Button
          raised
          onClick={() => {
            alert(`I belong to snackbar with key ${key}`);
          }}
        >
          View
        </Button>
        <Button
          onClick={() => {
            this.props.closeSnackbar(key);
          }}
        >
          Dismiss
        </Button>
      </>
    );

    if (this.pubnub) {
      this.subscribeToAlerts();
      //this.subscribeToMessages();
      var that = this;

      this.pubnub.addListener({
        message: function(x) {
          if (x.channel === that.eventId + ".alerts") {
            that.props.enqueueSnackbar(x.message.text, {
              variant: x.message.variant ? x.message.variant : "info",
              autoHideDuration: 5000,
              action,
              preventDuplicate: true
            });
          }
        }
      });
    }
  }

  sendLocalNotification = (message, variant = "info") => {
    this.props.enqueueSnackbar(message, {
      variant
    });
  };

  subscribeToAlerts = () => {
    if (this.pubnub) {
      this.pubnub.subscribe({
        channels: [this.eventId + ".alerts"]
      });
    }
  };

  subscribeToDrivers = () => {
    if (this.pubnub) {
      this.pubnub.subscribe({
        channels: [this.eventId + ".drivers.*"]
      });
    }
  };

  subscribeToMessages = () => {
    if (this.pubnub) {
      this.pubnub.subscribe({
        channels: [this.eventId + ".messages.*"],
        withPresence: false
      });
    }
  };

  subscribeToChannel = channel => {
    if (this.pubnub) {
      this.pubnub.subscribe({
        channels: [channel],
        withPresence: false
      });
    }
  };

  subscribeToChannels = channels => {
    if (this.pubnub) {
      this.pubnub.subscribe({
        channels: channels,
        withPresence: false
      });
    }
  };

  addMomentTimestamps = messages => {
    return messages.map(rM => {
      var timestamp = rM.timetoken / 10000000;
      timestamp = timestamp.toString().split(".")[0];
      var postedAt = moment
        .unix(timestamp)
        .format("dddd, MMMM D, YYYY [at] HH:mm");
      var postedAtMoment = moment.unix(timestamp);
      return { ...rM, postedAt, postedAtMoment };
    });
  };

  getAllMessages = async (timetoken, channel) => {
    var start = timetoken;
    var responseNumber = 0;
    var allMessages = [];

    do {
      var fullResponse = await this.pubnub
        .history({
          channel,
          stringifiedTimeToken: true, // false is the default
          start,
          reverse: true
        })
        .then(async response => {
          var msgs = response.messages;
          responseNumber = msgs.length;
          start = response.startTimeToken;
          return response.messages;
        });

      allMessages.push(...fullResponse);
    } while (responseNumber === 100);

    return this.addMomentTimestamps(allMessages);

    /*
    pubnub.history(
      {
        channel: channel,
        stringifiedTimeToken: true, // false is the default
        start: timetoken // start time token to fetch
      },
      function(status, response) {
        var msgs = response.messages;
        var start = response.startTimeToken;
        var end = response.endTimeToken;
        // if msgs were retrieved, do something useful with them
        if (msgs != "undefined" && msgs.length > 0) {
          console.log(msgs.length);
          console.log("start : " + start);
          console.log("end : " + end);
        }
        // if 100 msgs were retrieved, there might be more; call history again
        if (msgs.length == 100) getAllMessages(start);
      }
    );*/
  };

  createChatNamePrefix = (eventId, type) => {
    return eventId + "." + type;
  };

  createDriverRoomName = (eventId, driverId) => {
    return eventId + ".drivers." + driverId;
  };

  createChatNameBetweenUsers = (userIdOne, userIdTwo) => {
    return [userIdOne, userIdTwo].sort().join("-");
  };

  componentWillUnmount() {
    if (this.pubnub) this.pubnub.unsubscribeAll();
  }

  render() {
    var PubNubContext = this.props.pubNubContext;
    return (
      <PubNubContext.Provider value={this}>
        {this.props.children}
      </PubNubContext.Provider>
    );
  }
}

export default withSnackbar(MyComponent);
