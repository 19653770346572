import gql from 'graphql-tag';

export default gql`
  mutation CreateUser($data: UserInput) {
    createUser(input: { data: $data }) {
      user {
        id
      }
    }
  }
`;
