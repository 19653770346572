import React, { useState, useEffect } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Typography from 'components_v2/Typography/Typography';
import clsx from 'clsx';
import { ChevronDown } from '../../Icons/Icons';
import { useCustomStyles } from './DataSelector.styles';
import { setFocusOnElement } from '../../../utils/helpers';
import Skeleton from "@material-ui/lab/Skeleton";

const DataSelector = ({
  options,
  value,
  allDataObject,
  history,
  urlPrefix,
  labelField,
  valueField,
  variant,
  isMenuBlocked,
  loading
}) => {
  const customClasses = useCustomStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLabel, setCurrentLabel] = useState(null);

  const handleClose = () => {
    setFocusOnElement(false);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setFocusOnElement(true, event.target.parentNode.parentNode);
    setAnchorEl(event.currentTarget);
  };

  const modifiedOptions = [...options];

  if (value) {
    _.remove(modifiedOptions, (option) => option[valueField] === value);
  }

  const renderLabel = (val) => {
    if (val !== 'all') {
      const foundOption = _.find(options, { [valueField]: val });
      return foundOption && foundOption[labelField];
    }
    if (val === 'all') {
      return (allDataObject && allDataObject[labelField]) || 'All';
    }
  };

  useEffect(() => {
    setCurrentLabel(renderLabel(value));
  }, [value, options]);

  if(loading){
    return <div><Skeleton width={"300px"} height={55} /></div>
  }
  return (
    <div
      className={clsx(customClasses.dataSelectorWrapper, {
        [customClasses.expanded]: anchorEl,
      })}
    >
      <Typography
        customVariant={variant}
        onClick={(event) => !isMenuBlocked && handleClick(event)}
        className={customClasses.previewLabel}
        style={isMenuBlocked && { cursor: 'unset' }}
      >
        <span>
          <span>{currentLabel}</span>
        </span>
        {!isMenuBlocked && value && (
          <div>
            <ChevronDown />
          </div>
        )}
      </Typography>

      <Menu
        classes={{ paper: customClasses.paper }}
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxHeight: '200px',
            width: '260px !important',
          },
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {_.map(modifiedOptions, (option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setCurrentLabel(renderLabel(option[valueField]));
              history.push(`${urlPrefix}${option[valueField]}`);
              handleClose();
            }}
            value={option.value}
          >
            <div className={customClasses.menuItemlabel}>
              {option[labelField]}
            </div>
          </MenuItem>
        ))}
        {(!value || value !== allDataObject[valueField] || value !== 'all') &&
          allDataObject && (
            <MenuItem
              className={customClasses.menuItems}
              onClick={() => {
                handleClose();
                history.push(allDataObject.url);
              }}
              value={allDataObject[valueField] || 'all'}
            >
              {allDataObject[labelField] || 'All'}
            </MenuItem>
          )}
      </Menu>
    </div>
  );
};

export default withRouter(DataSelector);
