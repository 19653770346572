import _ from "lodash";

export const getSortingOptions = ({ sortActions, listConfig }) => {
   if(listConfig) {
      const sortArray = _.split(listConfig?.sort, ':')

      if(listConfig?.sort && (sortArray && sortArray[0] !== 'id:')) {
         return listConfig?.sortHeader + ' - ' + ((sortArray[1] === "asc" && "Ascending") || 'Descending')
      }
      else {
        return "None"
      }
   }
   return (sortActions.currentSort && sortActions?.currentSort?.field !== null && (sortActions?.currentSort?.headerName + ' - ' + ((sortActions?.currentSort?.order === "asc" && "Ascending") || "Descending"))) || "None"
}