import React from "react";
import StyledMenuItem from "components_v2/Layout/StyledMenuItem";
import { withRouter } from "react-router-dom";
import {
  MenuVs,
  Folder,
  MenuHamburger
} from "components_v2/Icons/Icons";
import { Divider, List } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CustomizedBadges from "components_v2/Badge/Badge";
import { Category1, UserBig } from "../../Icons/Icons";

export const MobileNav = ({ openDesktopNav, userInfo }) => (
  <section
    style={{
      position: "fixed",
      bottom: "0",
      backgroundColor: "#122044",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      zIndex: 1200
    }}
  >
    <List
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-evenly",
        padding: 0
      }}
    >
      <StyledMenuItem
        mobile
        icon={MenuVs}
        open={() => {}}
        route="/"
        id="mobile-dashboard-menu"
      />

      {userInfo.isSuperAdmin && (
        <>
          <StyledMenuItem
            mobile
            icon={Folder}
            open={() => {}}
            route="/projects"
          />
          <StyledMenuItem
            mobile
            icon={Category1}
            open={() => {}}
            route="/dispatch"
          />
          <StyledMenuItem
            mobile
            icon={UserBig}
            open={() => {}}
            route="/users"
          />
        </>
      )}

      <Divider
        orientation="vertical"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          height: "50%"
        }}
      />

      <IconButton
        style={{
          padding: "19px 28px"
        }}
        onClick={openDesktopNav}
      >
        <CustomizedBadges
          position="top"
          value={36}
          style={{ fill: "#fff" }}
          children={<MenuHamburger />}
        />
      </IconButton>
    </List>
  </section>
);

export default withRouter(MobileNav);
