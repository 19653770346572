import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  CheckboxContainer,
  CheckboxSelectedContainer,
} from '../../Icons/Icons';

const StyledCheckbox = withStyles({
  root: {
    color: '#4001C5',
    '&$checked': {
      color: '#4001C5',
    },
    '&$disabled': {
      color: 'rgba(0, 0, 0, 0.1)',
    },
  },
  checked: {},
  disabled: {},
})((props) => <Checkbox color="default" {...props} />);

const CheckboxInput = ({
  label,
  name,
  value,
  onChange,
  isDisabled,
}) => (
  <>
    <FormControlLabel
      style={{ marginRight: !label && '0' }}
      control={
        <StyledCheckbox
          icon={<CheckboxContainer />}
          checkedIcon={<CheckboxSelectedContainer />}
          checked={value || ''}
          name={name}
          onChange={onChange}
          disabled={isDisabled}
        />
      }
      label={label}
    />
  </>
);

export default CheckboxInput;
