import find from "lodash/find";
import * as Yup from "yup";
import _ from "lodash";

import { formatChangedFormValues, formatValues } from "utils/formHelpers";

export const validationSchema = Yup.object().shape({
  location: Yup.object()
    .nullable()
    .required("Required"),
  name: Yup.string()
    .min(2, "Too short!")
    .required("Required"),
  type: Yup.object()
    .nullable()
    .required("Required"),
  functionalAreas: Yup.array().when("hasFunctionalAreas", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        location: Yup.object()
          .nullable()
          .required("Required!"),
        name: Yup.string().required("Required!"),
        type: Yup.object()
          .nullable()
          .required("Required")
      })
    )
  })
});

export const locationTypes = [
  { value: "hotel", name: "Hotel" },
  { value: "airport", name: "Airport" },
  { value: "trainStation", name: "Train Station" },
  { value: "venue", name: "Event venue" },
  { value: "assembly", name: "Assembly point" },
  { value: "other", name: "Other" }
];

export const functionalAreaTypes = [
  { value: "pickupPoint", name: "Pick Up Point" },
  { value: "dropoffPoint", name: "Drop Off Point" },
  { value: "parkingLot", name: "Parking Lot" }
];

const getNameAndAddressFromLocationAddress = locationAddress => {
  const split = locationAddress.split(", ");

  const name = split[0];
  split.splice(0, 1);
  const address = split.join(", ");

  return { name, address };
};

export const formatIncomingData = data => {
  const { parentLocation } = data;
  const type = find(locationTypes, { name: data.parentLocation.type });

  const { name, address } = getNameAndAddressFromLocationAddress(
    parentLocation.address
  );

  const location = {
    lat: parentLocation.lat,
    long: parentLocation.long,
    name,
    address
  };

  const functionalAreas = data.functionalAreas.map(
    ({ name, type, address: locationAddress, lat, long, id = null }) => {
      const formattedType = find(functionalAreaTypes, { name: type });
      const {
        name: addressName,
        address
      } = getNameAndAddressFromLocationAddress(locationAddress);
      const location = { lat, long, name: addressName, address };

      return {
        name,
        id,
        type: formattedType,
        location
      };
    }
  );

  const formatted = {
    contactFullName: parentLocation.contactFullName,
    contactPhone: parentLocation.contactPhone,
    contactEmail: parentLocation.contactEmail,
    identifier: parentLocation.identifier,
    notes: parentLocation.notes,
    name: parentLocation.name,
    type,
    location,
    functionalAreas
  };

  return formatted;
};

export const formatSubmissionValues = (values, initialValues, isUpdate) => {
  const formatConfig = {
    name: null,
    contactFullName: null,
    contactEmail: null,
    contactPhone: null,
    notes: null,
    identifier: null,
    type: "type.name",
    location: [
      { name: "lat", format: "location.lat" },
      { name: "long", format: "location.long" },
      {
        name: "address",
        format: vals => `${vals.location.name}, ${vals.location.address}`
      }
    ]
  };

  if (isUpdate) {
    return formatChangedFormValues(values, initialValues, formatConfig);
  }
  formatConfig.event = null;
  return formatValues(values, formatConfig);
};

export const formatFunctionalAreaValues = (
  values,
  initialValues,
  isUpdate,
  parentId
) => {
  const { functionalAreas } = values;
  const { functionalAreas: initialFunctionalAreas } = initialValues;
  let formattedValues = [];

  Object.keys(functionalAreas).forEach(key => {
    if (
      !isUpdate ||
      (!_.isEqual(functionalAreas[key], initialFunctionalAreas[key]) &&
        isUpdate)
    ) {
      const { name, type, location, id } = functionalAreas[key];
      const formatted = {
        name,
        type: type?.name,
        event: values.event,
        address: `${location?.name}, ${location?.address}`,
        lat: location?.lat,
        long: location?.long,
        parent: parentId
      };

      formattedValues = [...formattedValues, [formatted, id]];
    }
  });

  return formattedValues;
};
