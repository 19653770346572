import gql from "graphql-tag";

export default gql`
  query GetAlertComments($id: ID) {
    alertcomments(where: { alert: $id }) {
      id
      message
      resolved
      postedBy {
        created_at
        firstName
        lastName
        email
      }
    }
  }
`;
