import React from "react";
import _ from "lodash";
import auth from "./auth";
import { ArrowsChevronLeft } from "components_v2/Icons/Icons";

export const addUpdatedOrCreatedBy = (values, isUpdate, isCreatedBy) => {
  const { id } = auth.getUserInfo();
  const property = isUpdate ? "updated_by" : "created_by";

  const newValues = { ...values, [property]: id };

  if (isCreatedBy) newValues.createdBy = id;

  return newValues;
};

export const getDateFromDatePickerValue = value => value.split("T")[0];

export const getOverallMarginPrice = (supplierPrice, marginPercent) => {
  const multiplier = 1 + (marginPercent / 100);
  const unformattedPrice = supplierPrice * multiplier;

  const formatted = parseFloat(unformattedPrice.toFixed(2));

  return formatted;
};

export const getUploadDocuments = values =>
  Object.keys(values)
    .filter(key => key.startsWith("doc_"))
    .filter(key => !!values[key])
    .map(key => {
      const { id, size, url, name } = values[key];

      return {
        id,
        size,
        url,
        name
      };
    });

export const supplierPriceInputs = [
  {
    name: "supplierTransfer",
    label: "Transfer"
  },
  {
    name: "supplierSixHours",
    label: "6 hours"
  },
  {
    name: "supplierTwelveHours",
    label: "12 hours"
  },
  {
    name: "supplierTwentyFourHours",
    label: "24 hours"
  },
  {
    name: "supplierOvertime",
    label: "Overtime"
  },
  {
    name: "supplierNoShowDriver",
    label: "No-Show Driver"
  },
  {
    name: "supplierNoShowClient",
    label: "No-Show Client"
  },
  {
    name: "supplierPricePerKm",
    label: "Price per km"
  }
];

export const clientPriceInputs = [
  {
    name: "clientTransfer",
    label: "Transfer"
  },
  {
    name: "clientSixHours",
    label: "6 hours"
  },
  {
    name: "clientTwelveHours",
    label: "12 hours"
  },
  {
    name: "clientTwentyFourHours",
    label: "24 hours"
  },
  {
    name: "clientOvertime",
    label: "Overtime"
  },
  {
    name: "clientNoShowDriver",
    label: "No-Show Driver"
  },
  {
    name: "clientNoShowClient",
    label: "No-Show Client"
  },
  {
    name: "clientPricePerKm",
    label: "Price per km"
  }
];

const formatValue = (values, value, formatAction) => {
  if (typeof formatAction === "function") return formatAction(values);
  if (typeof formatAction === "string") return _.get(values, formatAction);
  if (
    formatAction &&
    typeof formatAction === "object" &&
    !formatAction?.length
  ) {
    const fieldValue = { [formatAction.name]: undefined };
    if (typeof formatAction.format === "function")
      fieldValue[formatAction.name] = formatAction.format(values);
    else if (typeof formatAction.format === "string")
      fieldValue[formatAction.name] = _.get(values, formatAction.format);
    else fieldValue[formatAction.name] = value;
    return fieldValue;
  }

  if (typeof formatAction === "object" && formatAction?.length) {
    let formattedValues = {};

    formatAction.forEach(field => {
      let fieldValue;
      if (typeof field.format === "function") fieldValue = field.format(values);
      else if (typeof field.format === "string")
        fieldValue = _.get(values, field.format);
      else fieldValue = value;

      formattedValues = { ...formattedValues, [field.name]: fieldValue };
    });
    return formattedValues;
  }
  return value;
};

export const formatChangedFormValues = (
  values,
  initialValues,
  formatConfig
) => {
  let formatted = {};

  Object.keys(values).forEach(field => {
    const currentValue = values[field];
    const initialValue = initialValues[field];
    const formatAction = formatConfig[field];

    if (field === "modules") {
      // TODO
    }

    if (
      !_.isEqual(currentValue, initialValue) ||
      formatAction?.forceSaveOnUpdate
    ) {
      if (formatAction === undefined) return;
      const formattedValue = formatValue(values, currentValue, formatAction);

      if (
        typeof formattedValue === "object" &&
        formattedValue?.length === undefined
      )
        formatted = { ...formatted, ...formattedValue };
      else formatted = { ...formatted, [field]: formattedValue };
    }
  });

  return formatted;
};

export const formatValues = (values, formatConfig) => {
  let formatted = {};

  Object.keys(values).forEach(field => {
    const currentValue = values[field];

    const formatAction = formatConfig[field];
    if (formatAction === undefined) return;

    const formattedValue = formatValue(values, currentValue, formatAction);
    if (typeof formattedValue === "object")
      formatted = { ...formatted, ...formattedValue };
    else formatted = { ...formatted, [field]: formattedValue };
  });

  return formatted;
};

export const addPasswordChangeValues = (valuesToSubmit, values) => {
  const password = values.generatePassword
    ? values.autoGenPassword
    : values.customPassword;

  return {
    ...valuesToSubmit,
    password,
    unhashed: password,
    willSendPassword: values.willSendPassword,
    sendPasswordEmail: values.sendPasswordEmail,
    requirePasswordChange: values.requirePasswordChange,
    email: values.email
  };
};

export const getContinueBodyAction = (history, previousFormState) => ({
  text: "Continue",
  onClick: () => {
    history.replace({
      pathname: previousFormState.fromPath,
      state: { hasState: true, removeRecent: true }
    });
  },
  icon: <ArrowsChevronLeft />
});

export const getSuccessHeader = ({ isUpdate, recordType }) => {
  const type = _.capitalize(recordType);

  return isUpdate ? `${type} Updated!` : `${type} Created!`;
};

export const getSuccessDescription = ({ isUpdate, recordType }) =>
  isUpdate
    ? `Congratulations, you have updated a ${recordType}.`
    : `Congratulations, a new ${recordType} has been created.`;

export const getFormTitle = ({ isUpdate, recordType }) =>
  isUpdate ? `Update an existing ${recordType}` : `Create a new ${recordType}`;

export const getFormDisplayText = ({ isUpdate, recordType }) => ({
  successHeader: getSuccessHeader({ isUpdate, recordType }),
  successDescription: getSuccessDescription({ isUpdate, recordType }),
  formTitle: getFormTitle({ isUpdate, recordType })
});