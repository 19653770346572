import React, { useState, useEffect } from "react";
import Typography from "components_v2/Typography/Typography";
import { useMutation } from "@apollo/react-hooks";
import ResendEmailConfirmation from "../../gql/PassengerSchedules/ResendEmailConfirmation";
import ResendEmailConfirmationModal from "./ResendEmailConfirmationModal/ResendEmailConfirmationModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})

const SendEmail = ({ data, id, onSendEmailLoading }) => {
  const classes = useStyles();

  const [
    resendEmailConfirmationModalData,
    setResendEmailConfirmationModalData
  ] = useState(null);

  const [
    resendEmailConfirmation,
    { loading: sendEmailLoading }
  ] = useMutation(ResendEmailConfirmation);

  const resendEmail = async (data) => {
    setResendEmailConfirmationModalData(null);

    await resendEmailConfirmation({
      variables: {
        ...data,
      },
    });
  };
  useEffect(() => {
    onSendEmailLoading && onSendEmailLoading(sendEmailLoading)
  },
    [sendEmailLoading])

  return <>
    <ResendEmailConfirmationModal
      data={resendEmailConfirmationModalData}
      onClickCancel={() => {
        setResendEmailConfirmationModalData(null);
      }}
      onClickSend={async data => {
        resendEmail(data);
      }}
    />
    <div
      className={classes.item}
      id={id}
      onClick={() => {
        setResendEmailConfirmationModalData({
          record: data
        })
      }}>
      <Typography
        customVariant="bodySmallRegular"
      >
        Send email confirmation
      </Typography>
    </div>
  </>
}

export default SendEmail;