import React from 'react';
import { Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import Typography from 'components_v2/Typography/Typography';
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import ListModal from 'components_v2/ListModal/ListModal';
import GetAllSuppliers from 'gql/Suppliers/GetAll';
import { createLocationWithState } from 'utils/routerHelpers';
import SupplierVehicleSelector from '../SupplierVehicleSelector/SupplierVehicleSelector';
import EmailDisplay from '../../../../EmailDisplay/EmailDisplay';
import withFormState from "containers/Wrappers/withFormState";

const selectionColumns = [
  {
    field: 'name',
    headerName: 'Company name',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'contactName',
    headerName: 'Contact person',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'phone',
    headerName: 'Contact phone',
    width: 3,
    titleWithText: true,
  },
  {
    field: 'email',
    headerName: 'Contact email',
    width: 3,
    titleWithText: true,
    render: (row) =>(
      <EmailDisplay email={row.email}></EmailDisplay>),
  },
];

const selectedColumns = (values, setFieldValue) => [
  {
    field: 'name',
    headerName: 'Company name',
    width: 12,
    render: (data) => (
      <Box>
        <Typography
          style={{ marginBottom: 16 }}
          customVariant="bodySmallRegular"
        >
          {data.name}
        </Typography>
        <SupplierVehicleSelector
          values={values}
          setFieldValue={setFieldValue}
          fleetSupplierId={data.id}
        />
      </Box>
    ),
  },
];

const FleetSupplierSelector = ({
  onDone,
  values,
  setFieldValue,
  history,
  location,
  error,
  formStep,
  setFormState
}) => {
  const customHandleDelete = (setSelectedItems) => (row) => {
    const newFleetSuppliers = values.fleetSuppliers.filter(
      (supplier) => supplier.id !== row.id
    );

    setFieldValue('fleetSuppliers', newFleetSuppliers);
    setSelectedItems(newFleetSuppliers.map((supplier) => supplier.id));
  };

  const handleNewSupplierClick = () => {
    const { pathname, state } = createLocationWithState(
      `/supplier/new`,
      location,
      values,
      formStep,
      true,
      "fleetSuppliers"
    );

    setFormState(state);
    history.replace({ pathname, state: { hasState: true } });
  };

  return (
    <ListModal
      title="Select suppliers"
      searchPlaceholder="Search for a supplier"
      query={GetAllSuppliers}
      dataSelector={(data) => data?.suppliers || []}
      queryOptions={{ fetchPolicy: 'no-cache' }}
      mainAction={{
        label: 'Done',
        onClick: onDone,
      }}
      secondaryAction={{
        label: 'Create a new supplier',
        onClick: handleNewSupplierClick,
      }}
      error={error}
      customHandleDelete={customHandleDelete}
      incomingItems={values.fleetSuppliers.map((supplier) => supplier.id)}
      columns={selectionColumns}
      renderSelectionColumns={selectedColumns(values, setFieldValue)}
      onModalClick={(toggleModal) => (
        <SelectActionButton onClick={toggleModal}>
          Select Suppliers
        </SelectActionButton>
      )}
    />
  );
};

export default withFormState(withRouter(FleetSupplierSelector));
