export const mockData = {
  vehicleclasses: [
    {
      id: '5f271b6e7442154fd8a8c139',
      name: 'Sedan-Basic',
      transferPricing: 0,
      sixHourPricing: 0,
      twelveHourPricing: 0,
      twentyFourHourPricing: 0,
      affiliatePricing: 0,
      overtimePricing: 0,
      transferAffiliatePricing: 80,
      sixHourAffiliatePricing: 80,
      twelveHourAffiliatePricing: 80,
      twentyFourHourAffiliatePricing: 80,
      overtimeAffiliatePricing: 2,
      noShowAffiliatePricing: 2,
      noShowPricing: 0,
      maxPassengers: 0,
      maxLuggage: 9,
      type: 'Mini-Bus',
      waitingTimePricing: 20,
      waitingTimeAffiliatePricing: 34,
      __typename: 'Vehicleclass',
    },
    {
      id: '5f271b7a7442154fd8a8c13a',
      name: 'SUV',
      transferPricing: 1,
      sixHourPricing: 1,
      twelveHourPricing: 1,
      twentyFourHourPricing: 1,
      affiliatePricing: 0,
      overtimePricing: 1,
      transferAffiliatePricing: 0,
      sixHourAffiliatePricing: 0,
      twelveHourAffiliatePricing: 0,
      twentyFourHourAffiliatePricing: 0,
      overtimeAffiliatePricing: 0,
      noShowAffiliatePricing: 2,
      noShowPricing: 1,
      maxPassengers: 2,
      maxLuggage: 8,
      type: null,
      waitingTimePricing: 1,
      waitingTimeAffiliatePricing: 0,
      __typename: 'Vehicleclass',
    },
    {
      id: '5fabe066d2a9012ba013e45a',
      name: 'Sprinter',
      transferPricing: 0,
      sixHourPricing: 0,
      twelveHourPricing: 0,
      twentyFourHourPricing: 0,
      affiliatePricing: 0,
      overtimePricing: 0,
      transferAffiliatePricing: 0,
      sixHourAffiliatePricing: 0,
      twelveHourAffiliatePricing: 0,
      twentyFourHourAffiliatePricing: 0,
      overtimeAffiliatePricing: 0,
      noShowAffiliatePricing: 0,
      noShowPricing: 0,
      maxPassengers: 6,
      maxLuggage: 7,
      type: 'Sedan',
      waitingTimePricing: 0,
      waitingTimeAffiliatePricing: 0,
      __typename: 'Vehicleclass',
    },
    {
      id: '5fabe11ad2a9012ba013e45b',
      name: 'ADA',
      transferPricing: 0,
      sixHourPricing: 0,
      twelveHourPricing: 0,
      twentyFourHourPricing: 0,
      affiliatePricing: 0,
      overtimePricing: 0,
      transferAffiliatePricing: 0,
      sixHourAffiliatePricing: 0,
      twelveHourAffiliatePricing: 0,
      twentyFourHourAffiliatePricing: 0,
      overtimeAffiliatePricing: 0,
      noShowAffiliatePricing: 0,
      noShowPricing: 0,
      maxPassengers: 2,
      maxLuggage: 8,
      type: 'ADA Van (Handicap)',
      waitingTimePricing: 0,
      waitingTimeAffiliatePricing: 0,
      __typename: 'Vehicleclass',
    },
    {
      id: '5fabe166d2a9012ba013e45c',
      name: 'Luggage',
      transferPricing: 0,
      sixHourPricing: 0,
      twelveHourPricing: 0,
      twentyFourHourPricing: 0,
      affiliatePricing: 0,
      overtimePricing: 0,
      transferAffiliatePricing: 0,
      sixHourAffiliatePricing: 0,
      twelveHourAffiliatePricing: 0,
      twentyFourHourAffiliatePricing: 0,
      overtimeAffiliatePricing: 0,
      noShowAffiliatePricing: 0,
      noShowPricing: 0,
      maxPassengers: 8,
      maxLuggage: 4,
      type: 'Luggage Van',
      waitingTimePricing: 0,
      waitingTimeAffiliatePricing: 0,
      __typename: 'Vehicleclass',
    },
  ],
};
