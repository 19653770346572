import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DynamicDriverList from 'components_v2/List/Variations/DriversList';
import DynamicVehicleList from 'components_v2/List/Variations/VehiclesList';
import Tabs from 'components_v2/Tabs/Tabs';
// import Skeleton from "@material-ui/lab/Skeleton";
import React from 'react';
import { mobileViewBreakpoint } from '../../../../App.helper';
import DataSelector from '../../../Inputs/DataSelector/DataSelector';
import { getSortedSuppliers } from '../Suppliers/helper';

const MainContainer = ({
  data,
  dataAll,
  selectedTab,
  onChangeTab,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const suppliersForDataSelector = getSortedSuppliers({suppliers: dataAll, sort: {
    field: 'name',
    order: 'asc'
  } })

  return (
    <>
      <DataSelector
        variant={(isMobileView && 'h4Bold') || 'h2Bold'}
        value={data.id}
        urlPrefix="/supplier/"
        options={suppliersForDataSelector || []}
        labelField="name"
        valueField="id:"
        allDataObject={{
          name: 'All Suppliers',
          id: 'all',
          url: '/suppliers',
        }}
      />
      <Tabs
        noBg
        scrollable
        noPadding
        selectedTab={selectedTab || 'vehicles'}
        onChangeTab={onChangeTab}
        tabs={[
          /* {
          label: "Projects",
          slug: "projects",
          component: <div style={{ marginTop: 24 }}>lol</div>
        }, */

          {
            label: 'Vehicles',
            slug: 'vehicles',
            component: (
              <div style={{ marginTop: 24 }}>
                <DynamicVehicleList />
              </div>
            ),
          },
          {
            label: 'Drivers',
            slug: 'drivers',
            component: (
              <div style={{ marginTop: 24 }}>
                <DynamicDriverList />
              </div>
            ),
          },
          /* {
          label: "Documents",
          slug: "documents",
          component: <div style={{ marginTop: 24 }}>lol</div>
        } */
        ]}
      />
    </>
  );
};
export default MainContainer;
