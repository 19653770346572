import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _ from "lodash";

import Button from 'components_v2/Button/Button';
import FullScreenPopover from 'components_v2/FullScreenPopover/FullScreenPopover';
import CreateVehicle from 'gql/Vehicles/Create';
import UpdateVehicle from 'gql/Vehicles/Update';
import SuccessCard from 'components_v2/Cards/SuccessCard/SuccessCard';
import Loading from 'components_v2/Loading/CircularProgress';
import AddVehicleForm from './AddVehicleForm';
import useStyles from './AddVehicle.styles';
import { getContinueBodyAction } from "utils/formHelpers";
import withFormState from "containers/Wrappers/withFormState";


import {
  getInitialInputValues,
  formatSubmissionValues,
  formatIncomingData,
  validationSchema,
  mergeReturnValues
} from './helpers';

const AddVehicle = ({
  history,
  location,
  match,
  data,
  refetchVehicle,
  transferservices,
  getPreviousFormState,
  setReturnValues
}) => {
  const classes = useStyles();
  const [vehicleId, setVehicleId] = useState(null);
  const previousFormState = getPreviousFormState(location);
  const previousFormStateValues = previousFormState?.values ? mergeReturnValues(previousFormState) : {};


  const { projectId, supplierId } = match.params;

  const initialInputValues = getInitialInputValues(supplierId, projectId);

  const isUpdate = !!data;
  const initialValues = isUpdate
    ? {
      ...initialInputValues,
      ...formatIncomingData(data),
      ...previousFormStateValues,
    }
    : {
      ...initialInputValues,
      projectId,
      ...previousFormStateValues,
    };

  const successHeader = `Vehicle ${isUpdate ? 'Updated' : 'Created'}!`;
  const successDescription = `Congratulations, a vehicle has been ${isUpdate ? 'updated' : 'created'
    }.`;
  const title = isUpdate
    ? 'Update an existing vehicle'
    : 'Create a new vehicle';

  const [
    createVehicle,
    { loading: createMutationLoading },
  ] = useMutation(CreateVehicle);

  const [
    updateVehicle,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateVehicle);

  const isLoading = createMutationLoading || updateMutationLoading;

  const handleSubmit = async (values, actions) => {
    const formattedValues = formatSubmissionValues(
      values,
      initialValues,
      isUpdate
    );

    if (isUpdate) {
      const id = data.id;
      const res = await updateVehicle({
        variables: {
          id: { id },
          data: formattedValues,
        },
      });

      if (res?.data?.updateVehicle) {
        setVehicleId(res.data.updateVehicle.vehicle.id);
        await refetchVehicle();
      }
    } else {
      const res = await createVehicle({
        variables: {
          data: formattedValues,
        },
      });

      if (res?.data?.createVehicle) {
        const vehicle = res.data.createVehicle.vehicle;
        setVehicleId(vehicle.id);
        setReturnValues(vehicle, previousFormState);

        actions.resetForm(initialInputValues);
      }
    }

    actions.setSubmitting(false);
  };

  const baseNavigationUrl = `${projectId ? 'project' : 'supplier'}/${projectId || supplierId
    }`;

  let mainBodyActions;

  if (!_.isEmpty(previousFormState)) mainBodyActions = [
    getContinueBodyAction(history, previousFormState),
  ];

  else mainBodyActions = [
    {
      text: "Add Another Vehicle",
      onClick: () => {
        if (isUpdate) {
          const url = `/${baseNavigationUrl}/vehicle/new`
          history.push(url)
        }
        setVehicleId(null)
      },
    },
  ];

  if (vehicleId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={
            previousFormState ? null : {
            text: "Setup Vehicles",
            onClick: () => {
              const url = `/${baseNavigationUrl}/vehicles`
              history.push(url)
            },
          }}
          secondaryAction={
            previousFormState ? null : {
            text: "Edit Vehicle",
            onClick: () => {
              if (!isUpdate) {
                const url = `/${baseNavigationUrl}/vehicle/${vehicleId}/edit`
                history.push(url)
              }
              setVehicleId(null)
            },
          }}
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    )

  return (
    <>
      {isLoading && <Loading fullPage />}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
        onCloseCallback={() => {
          history.goBack();
        }}
      >
        {(props) => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={title}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save vehicle
                </Button>
              }
              pageView
            >
              <AddVehicleForm {...props} classes={classes} transferservices={transferservices} />
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  )
};

export default withFormState(withRouter(AddVehicle));
