import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Do not run sentry on development environment
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://0cb8134ad4f14d2ebc850126ec3a59df@sentry.io/1833731',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
