import gql from "graphql-tag";

export const UpdateShuttlesScheduleItem = gql`
  mutation($id: InputID!, $data: editShuttlescheduleitemInput) {
    updateShuttlescheduleitem(input: { where: $id, data: $data }) {
      shuttlescheduleitem {
        id
      }
    }
  }
`;

export const CreateShuttlesScheduleItem = gql`
  mutation($data: ShuttlescheduleitemInput) {
    createShuttlescheduleitem(input: { data: $data }) {
      shuttlescheduleitem {
        id
      }
    }
  }
`;

export const CreateShuttleschedule = gql`
  mutation($data: ShuttlescheduleInput) {
    createShuttleschedule(input: { data: $data }) {
      shuttleschedule {
        id
      }
    }
  }
`;

export const UpdateShuttleschedule = gql`
  mutation($id: InputID!, $data: editShuttlescheduleInput) {
    updateShuttleschedule(input: { where: $id, data: $data }) {
      shuttleschedule {
        id
      }
    }
  }
`;
