import { List, MenuPointer } from '../Icons/Icons';

export const toggleIconBasedOptions = [
  { title: 'Map view', value: '1994', icon: MenuPointer },
  { title: 'List view', value: '1972', icon: List },
];

export const toggleTextBasedOptions = [
  { title: 'Active', value: 'active' },
  { title: 'Static', value: 'static' },
];
