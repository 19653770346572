import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
// import Skeleton from "@material-ui/lab/Skeleton";
import React from 'react';
import AddBooking from 'components_v2/Bookings/AddBooking.Component';
import GetEventGQL from 'gql/Events/GetOne';
import GetPassengerScheduleGQL from 'gql/PassengerSchedules/GetOne';
import CircularProgress from '../Loading/CircularProgress';
import ResendEmailConfirmation from '../../gql/PassengerSchedules/ResendEmailConfirmation';

const Container = ({ match, isPublic, location, ...otherProps }) => {
  const { bookingId, projectId } = match.params;

  const { data, loading } = useQuery(GetEventGQL, {
    variables: { id: match.params.projectId },
    fetchPolicy: 'no-cache',
  });
  const [
    resendEmailConfirmation,
  ] = useMutation(ResendEmailConfirmation);

  const isDuplicate =
    location?.pathname?.toLowerCase()?.indexOf('duplicate') > -1;
  const isReturnTrip =
    location?.pathname?.toLowerCase()?.indexOf('return') > -1;

  const {
    data: bookingData,
    loading: bookingLoading,
    refetch: refetchBooking,
  } = useQuery(GetPassengerScheduleGQL, {
    variables: { id: bookingId },
    skip: !bookingId,
    fetchPolicy: 'no-cache',
  });

  if (loading || bookingLoading)
    return (
      <>
        <CircularProgress fullPage />
      </>
    );

  return (
    <AddBooking
      projectId={projectId}
      availableProducts={data?.products}
      projectInfo={data}
      bookingData={bookingData?.passengerschedules[0]}
      refetchPassengerSchedule={refetchBooking}
      isPublic={isPublic}
      isDuplicate={isDuplicate}
      isReturnTrip={isReturnTrip}
      resendEmailConfirmation={resendEmailConfirmation}
      {...otherProps}
    />
  );
};

export default withRouter(Container);
