import React from "react";
import ErrorPage from "../../../components/views/Errors/ErrorPage";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError && process.env.NODE_ENV !== "development") {
      return <ErrorPage></ErrorPage>;
    }

    return this.props.children;
  }
}
