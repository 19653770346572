import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Badge from "components_v2/Badge/Badge";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Menu from "components_v2/Menu/Menu";
import DeleteConfirmationModal from "components_v2/Modal/DeleteConfirmationModal/DeleteConfirmationModal";
import "./ProjectFolder.css";

const useStyles = makeStyles(() => ({
  root: {
    width: 340,
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": { boxShadow: "0px 8px 28px rgba(0, 0, 0, 0.2)" }
  },
  avatar: {
    backgroundColor: "rgba(64, 1, 197, 0.05)",
    color: "#000",
    fontSize: 24,
    lineHeight: "34px",
    width: 72,
    height: 72,
    textTransform: "uppercase"
  }
}));

const ProjectFolder = ({
  avatar,
  name,
  id,
  userEventId,
  history,
  alerts,
  onLeaveProject,
  onArchiveProject
}) => {
  const classes = useStyles();
  const [confirmationModalData, setConfirmationModalData] = React.useState(
    null
  );

  let unresolvedAlerts = [];
  if (alerts) {
    unresolvedAlerts = _.filter(alerts, alert => alert.status === "unresolved");
  }

  const deleteRecord = async () => {
    const recordToDelete = confirmationModalData.recordId;
    const deleteType = confirmationModalData.deleteType;

    if (deleteType === "leave") {
      onLeaveProject(recordToDelete);
    } else if (deleteType === "archive") {
      onArchiveProject(recordToDelete);
    }

    setConfirmationModalData(null);
  };

  return (
    <>
      <DeleteConfirmationModal
        deleteConfirmationModalData={confirmationModalData}
        onClickCancel={() => {
          setConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteRecord();
        }}
      />
      <Card
        className={classes.root}
        onClick={() => {
          history.push(`/project/${id}`);
        }}
      >
        <CardHeader
          avatar={
            <Badge
              value={unresolvedAlerts.length}
              position="top"
              style={{ right: 5 }}
            >
              <Avatar aria-label="recipe" className={classes.avatar}>
                {!avatar && <>{name ? name.substring(0, 2) : ""}</>}
              </Avatar>
            </Badge>
          }
          action={
            <Menu
              items={[
                {
                  label: "Add booking",
                  onClick: (row) =>
                    history.push(
                      `/project/${row.id}/booking/new`
                    ),
                },
                {
                  label: "Leave project",
                  onClick: row => {
                    setConfirmationModalData({
                      deleteType: "leave",
                      headerText: `You will leave ${row.name}. You can only be re-added by an administrator.`,
                      description: `Do you wish to continue?`,
                      recordName: row.name,
                      recordId: row.userEventId,
                      confirmationType: "delete"
                    });
                  }
                },
                {
                  label: "Archive project",
                  onClick: row => {
                    setConfirmationModalData({
                      deleteType: "archive",
                      headerText: `You will archive ${row.name} for all users so it will no longer be visible. This can be un-done at any time.`,
                      description: `Do you wish to continue?`,
                      recordName: row.name,
                      recordId: row.id,
                      confirmationType: "delete"
                    });
                  }
                },

              ]}
              row={{ name, id, userEventId }}
            />

            // <IconButton
            //   style={{ padding: 0, marginTop: -8, marginRight: -5 }}
            //   aria-label="settings"
            // >
            //   <MoreHorizontal />something
            // </IconButton>
          }
          title={
            <Typography
              style={{
                fontWeight: "normal",
                marginLeft: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical"
              }}
              variant="h5"
            >
              {name}
            </Typography>
          }
        />
      </Card>
    </>
  );
};

export default withRouter(ProjectFolder);
