import React from "react";
import { Box } from "@material-ui/core";

import Typography from "components_v2/Typography/Typography";
import { NumberInput } from "components_v2/Inputs/NumberInput/NumberInput";
import DynamicList from "components_v2/List/DynamicList";
import Vehicle from "components_v2/Vehicle/Vehicle";
import useStyles from "./VehiclePriceInputs.style";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../../App.helper";

const VehiclePriceInputs = props => {
  const {
    values,
    vehicles,
    handleChange,
    getValue,
    disableQuantities,
    disableDescription,
    disabled
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const vehicleQuantities = vehicles.map(vehicle => (
    <Box className={classes.vehicleQuantityContainer} key={vehicle.id}>
      <Vehicle
        type={vehicle.vehicleClass.name}
        customStyle={{ position: "relative" }}
      />
      <Typography style={{ marginLeft: 16 }} customVariant="bodySmallRegular">
        {`x ${vehicle.quantity} ${vehicle.vehicleClass.name}`}
      </Typography>
    </Box>
  ));

  const priceInputColumns = values.transferservices
    .filter(service => service.enabled)
    .map(({ id, name }) => ({
      field: name,
      headerName: name,
      width: 1,
      render: data => {
        const handlePriceInputChange = ({ target }) => {
          handleChange(values, data, target, vehicles);
        };

        return (
          <Box key={name} className={classes.pricesItem}>
            <NumberInput
              name={id}
              value={getValue(data, id)}
              onChange={handlePriceInputChange}
              isDisabled={disabled}
              // icon={() => <AttachMoney className={classes.moneyAdornment} />}
              // adornmentPosition="start"
            />
          </Box>
        );
      }
    }));

  const columns = [
    {
      field: "name",
      headerName: "Per item (AED)",
      width: 2,
      render: data => (
        <Typography customVariant="bodySmallRegular">
          {`${data.quantity}x ${data.vehicleClass.name} (AED)`}
        </Typography>
      )
    },
    ...priceInputColumns
  ];

  // const supplierPrices = vehicles.map((vehicle) => {
  //   const getSupplierPriceFields = supplierPriceInputs.map(
  //     ({ name, label }) => (
  //       <Box key={name} className={classes.pricesItem}>
  //         <NumberInput
  //           name={name}
  //           label={label}
  //           value={values[name]}
  //           onChange={handleChange}
  //           step="0.01"
  //           icon={() => <AttachMoney className={classes.moneyAdornment} />}
  //           adornmentPosition="start"
  //         />
  //       </Box>
  //     )
  //   );

  //   return <Box className={classes.pricesContainer}>{supplierPriceFields}</Box>;
  // });

  return (
    <Box>
      {!disableQuantities ? (
        <Box className={classes.allVehicleQuantitiesContainer}>
          {vehicleQuantities}
        </Box>
      ) : null}
      {vehicles.length ? (
        <>
          {disableDescription ? null : (
            <Typography customVariant="bodySmallRegular">
              Enter the agreed upon prices
            </Typography>
          )}
          <DynamicList
            noResultsText="No vehicle price inputs"
            isMobileView={isMobileView}
            showHeader
            hideActions
            data={vehicles}
            columns={columns}
          />
        </>
      ) : null}
    </Box>
  );
};

export default VehiclePriceInputs;
