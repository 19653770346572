import gql from 'graphql-tag';

export default gql`
  mutation UpdateUserEvent($id: InputID!, $data: editUsereventInput) {
    updateUserevent(input: { where: $id, data: $data }) {
      userevent {
        id
      }
    }
  }
`;
