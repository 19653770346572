import gql from 'graphql-tag';

export default gql`
  mutation DeleteDocument($inputId: InputID!) {
    deleteDocument(input: { where: $inputId }) {
      document {
        id
      }
    }
  }
`;
