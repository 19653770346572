import React from 'react';
import {Box} from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import { FindInPage } from '@material-ui/icons';
import PropTypes from 'prop-types';

import withPublicLayout from 'components_v2/Layout/PublicLayout/withPublicLayout';
import EmptyStateCtaCard from 'components_v2/Cards/EmptyStateCtaCard/EmptyStateCtaCard';

const NotFound = ({ history }) => {
  const mainAction = {
    text: 'Go to homepage',
    onClick: () => {
      history.push('/');
    },
  };

  return (
    <Box margin="auto auto">
      <EmptyStateCtaCard
        headerText="Page Not Found"
        description="The page you are looking for does not exist."
        mainAction={mainAction}
        icon={<FindInPage style={{ width: 44, height: 'auto' }} />}
        size="large"
      />
    </Box>
  );
};

NotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withPublicLayout({ noAuthLinks: true }, withRouter(NotFound));
