import React, { useCallback, useMemo, useState } from 'react';
import find from 'lodash/find';
import { useMutation } from '@apollo/react-hooks';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import auth from 'utils/auth';
import HelperText from 'components_v2/Inputs/HelperText/HelperText';
import CreateDocument from 'gql/Documents/CreateDocument';
import DeleteDocument from 'gql/Documents/DeleteDocument';
import DocumentStatus from './DocumentStatus';

const baseStyle = {
  padding: '16px',
  minHeight: '64px',
  background: '#FFFFFF',
  border: '1px dashed rgba(64, 1, 197, 0.4)',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
};

const titleStyle = {
  fontSize: '14px',
  lineHeight: '20px',
  color: '#959393',
};

const activeStyle = {
  background: 'rgba(64, 1, 197, 0.05)',
  border: '1px dashed #4001C5',
};

const acceptStyle = {
  background: 'rgba(64, 1, 197, 0.05)',
  border: '1px dashed #4001C5',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const browseAnchor = {
  color: '#4001C5',
};

const FileDrop = (props) => {
  const [filesUploading, setFilesUploading] = useState([]);
  const [filesFinalizing, setFilesFinalizing] = useState([]);
  const [completedFiles, setCompletedFiles] = useState([]);

  const [
    createDocument,
  ] = useMutation(CreateDocument);

  const [
    deleteDocument,
  ] = useMutation(DeleteDocument);

  const handleDelete = async (file) => {
    await deleteDocument({ variables: { inputId: { id: file.id } } });

    setFilesUploading([]);
    setFilesFinalizing([]);

    setCompletedFiles((prevState) => [
      ...prevState.filter((completedFile) => completedFile.id !== file.id),
    ]);
    if (props.onDeleteCallback) props.onDeleteCallback(file);
    if (props.refetch) await props.refetch();
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (props.setHideAcceptedFiles) props.setHideAcceptedFiles(false);
    acceptedFiles.forEach((selectedFile, index) => {
      const identifier = `doc_${index}_${selectedFile.name.substr(
        0,
        selectedFile.name.lastIndexOf('.')
      )}`;

      setIsUploading(true);
      setFilesUploading((prevState) => [
        ...prevState,
        {
          status: 'uploading',
          identifier,
        },
      ]);
      const reader = new FileReader();
      reader.onload = async () => {
        const data = new FormData();
        data.append('files', selectedFile, selectedFile.name);

        const headers = {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('jwtToken')
          )}`,
        };

        const postUrl =
          process.env.REACT_APP_UPLOAD_ENDPOINT ||
          ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/upload");
        const result = await axios.post(postUrl, data, {
          headers,
        });

        setIsUploading(false);
        setFilesFinalizing((prevState) => [
          ...prevState,
          {
            status: 'uploading',
            identifier,
          },
        ]);

        const documentData = result.data[0];
        const currentUser = auth.getUserInfo();
        const createResponse = await createDocument({
          variables: {
            data: {
              name: documentData.name,
              url: documentData.url,
              size: documentData.size.toString(),
              uploadedBy: currentUser.id,
              created_by: currentUser.id,
            },
          },
        });

        if (
          createResponse &&
          createResponse.data &&
          createResponse.data.createDocument
        ) {
          const documentId = createResponse.data.createDocument.document.id;

          setCompletedFiles((prevState) => [
            ...prevState,
            {
              ...result.data[0],
              id: documentId,
              identifier,
            },
          ]);

          const returnDocument = {
            total: acceptedFiles ? acceptedFiles.length : 0,
            name: documentData.name,
            url: documentData.url,
            size: documentData.size.toString(),
            id: documentId,
            identifier,
          };

          if (props.moduleId) returnDocument.module = props.moduleId;

          props.onComplete(returnDocument);
        }
      };
      reader.readAsBinaryString(selectedFile);
    });
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });

  const [isUploading, setIsUploading] = useState(false);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const files = props.hideAcceptedFiles
    ? []
    : acceptedFiles.map((file, index) => {
      const identifier = `doc_${index}_${file.name.substr(
        0,
        file.name.lastIndexOf('.')
      )}`;
      const isUploading = !!find(filesUploading, {
        identifier,
      });

      const isFinalizing = !!find(filesFinalizing, {
        identifier,
      });

      const completedFile = find(completedFiles, {
        identifier,
      });

      const isCompleted = !!completedFile;

      const getStatus = (isUploading, isFinalizing, isCompleted) => {
        if (isCompleted) return 'completed';
        if (!isUploading && !isFinalizing) return 'deleted';
        if (isUploading && !isFinalizing) return 'uploading';
        return 'finalizing';
      };

      const status = getStatus(isUploading, isFinalizing, isCompleted);

      return (
        <DocumentStatus
          key={file.path}
          file={completedFile || file}
          status={status}
          onDelete={handleDelete}
        />
      );
    });

  const alreadyUploadedDocuments =
    props.documents &&
    props.documents.map((document, index) => (
      <DocumentStatus
        key={index}
        key={document.id}
        file={document}
        status="completed"
        onDelete={handleDelete}
      />
    ));

  return (
    <section className="container">
      <div>
        <div style={{ marginBottom: 16 }}>
          {alreadyUploadedDocuments}
          {files}
        </div>
      </div>
      <div
        {...getRootProps({
          style: {
            ...style,
            justifyContent: 'center',
            borderColor:
              ((isUploading || files.length > 0) && '#4001C5') ||
              'rgba(64, 1, 197, 0.4)',
          },
        })}
      >
        <input {...getInputProps()} />

        <p style={titleStyle}>
          Drop your files here, or <span style={browseAnchor}>browse</span>
        </p>
      </div>
      <HelperText text="Supports, JPG, PNGs and GIFS max file size 5MB" />
    </section>
  );
};

export default FileDrop;
