import gql from 'graphql-tag';

export default gql`
  query GetEnabledVehicleTrackingTypes {
    vehicletrackingtypes(where: {disabled: false}) {
      id:
      name
    }
  }
`;
