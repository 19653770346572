/* eslint-disable complexity */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import TitleWithText from 'components_v2/TitleWithText/TitleWithText';
import IconSelector from '../Inputs/IconSelector/IconSelector';
import EditableField from './EditableField';
import {getIsColumnsType} from './helper'
const getRenderValue = ({ 
  column,
  moduleTable,
  editMode,
  row,
  handleValueChange,
  customValueChange,
  stateValues
 }) => {
  if (editMode && column.isEditDisabled && column.editModeField) return  _.get(stateValues, column.editModeField);
  else if (column.titleWithText || (moduleTable && !column.editModeField)) {
    return (
      <TitleWithText
        label={column.headerName}
        text={
          editMode && !column.isEditDisabled
            ? <EditableField
                column={column}
                row={row} values={stateValues}
                handleValueChange={handleValueChange}
                customValueChange={customValueChange}
              />
            : column.render(row)
        }
      />
    );
  }
  return editMode && !column.isEditDisabled
    ? <EditableField
        column={column}
        row={row} values={stateValues}
        handleValueChange={handleValueChange}
        customValueChange={customValueChange}
      />
    : column.render(row);
};


const FieldValue = (props) => {
  const { 
    row,
    column,
    editMode,
    stateValues,
    moduleTable,
    handleValueChange,
    customValueChange
   } = props;

  let fieldValue = null;

  
  // if render function is specified then call it
  if (row  && column?.field && column?.render) {
    fieldValue = getRenderValue({ 
      column,
      moduleTable,
      editMode,
      row,
      handleValueChange,
      stateValues,
      customValueChange
     });
    return <>{fieldValue}</>;
  }


  // // if everything is set and no render function then print this
  if (
    row &&
    column?.field &&
    (row[column.field] !== null || row[column.field] !== undefined)
  ) {
      fieldValue = _.get(row, column.field); // set field value - check below if certain type then format it differently

    // if type is defined then we need to format it
    if (getIsColumnsType({column ,type:'date'}))
      fieldValue = moment
        .utc(fieldValue)
        .format('MMMM D, YYYY [at] HH:mm');

    if (getIsColumnsType({column ,type:'time'}) )
      fieldValue = moment.utc(fieldValue).format('HH:mm');
  }

  if (column.type && column.type === 'icon')
    fieldValue = (
      <IconSelector
        onChange={(event) => {
          handleValueChange(column, row, event.target.value);
        }}
        label={column.label || 'Select Icon +'}
        options={column.options}
        value={row[column.field]}
        disabled
      />
    );

  if (column.titleWithText || moduleTable) {
    return editMode && !column.isEditDisabled ? (
      <TitleWithText
        label={column.headerName}
        text={
          <EditableField
            column={column}
            row={row} values={stateValues}
            handleValueChange={handleValueChange}
            customValueChange={customValueChange}
          />
        }
      />
    ) : (
      <TitleWithText label={column.headerName} text={fieldValue} />
    );
  }



  return editMode && !column.isEditDisabled
    ? <EditableField
        column={column}
        row={row}
        values={stateValues}
        handleValueChange={handleValueChange}
        customValueChange={customValueChange}
      />
    : <>{fieldValue}</>;
};

export default FieldValue;