import React from "react";
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { Messages } from "../../Icons/Icons";
import _ from "lodash";
import Timeline from '@material-ui/lab/Timeline';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { getParsedSMSText } from "../BookingHelper";
import { IconButton, makeStyles, withStyles } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: "430px",
    overflowY: "auto",
  },
  contentSeparatorDot: {
    width: "19px",
    height: "19px",
    margin: "5px 0px",
  },
  contentSeparatorLine: {
    width: "3px",
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    fontSize: 14,

    '& svg': {
      display: 'none'
    },

    '&:hover': {
      '& svg': {
        display: 'block'
      },
    }
  },

  content: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    wordBreak: 'break-word'
  },

  icon: {
    width: 20,
    height: 20,

    '& svg': {
      width: 20,
      height: 20,
      cursor: 'pointer'
    }

  }
});

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(MuiTimelineItem);

const CopySMSModal = ({ sentSMSs, data, onClickCancel }) => {
  const classes = useStyles();

  return <Modal
    size="sm"
    pageView={!!data}
  >
    <SuccessCard
      icon={
        <Messages
          style={{
            color: "#FFFF",
            fill: "white",
            width: 40,
            height: 40,
            filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
          }}
        />
      }

      secondaryAction={{
        text: "Close",
        onClick: onClickCancel,
      }}
      headerSize="h4Bold"
      headerText={`SMS History`}
    >
      <div className={classes.root}>
      
        <Timeline>
          {
            _.map(sentSMSs, (step, index) => {
              /* Index used as key here because these items are not being reordered or filtered on the fly at the moment
              if this changes, a unique, persistant key will need to be implemented */
              return <TimelineItem key={index}>
                <TimelineSeparator className={classes.contentSeparator}>
                  <TimelineDot className={classes.contentSeparatorDot} color="primary" />
                  {(index !== sentSMSs.length - 1) && <TimelineConnector className={classes.contentSeparatorLine}/>}
                </TimelineSeparator>
                <TimelineContent>
                  <div className={classes.contentContainer}>
                    <div className={classes.content}>
                      {getParsedSMSText({ step, data })}
                    </div>
                    <div className={classes.icon} onClick={() => {
                      copy(getParsedSMSText({ step, data }))
                    }}>
                      <IconButton color="primary" component="span">
                        <FileCopyIcon />
                      </IconButton>
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            })
          }
        </Timeline>
      </div>
    </SuccessCard>
  </Modal>
}

export default CopySMSModal;