import React, {useState} from 'react';

import {
  TimelineItem,
  TimelineContent,
} from '@material-ui/lab';
import { Box, Grid } from '@material-ui/core';
import Button from "components_v2/Button/Button";
import StoredLocationSearch from 'components_v2/Inputs/StoredLocationSearch/StoredLocationSearch';
import useStyles from './StoredLocationTimeline.style';

const StoredLocationTimeline = ({
  values,
  projectId,
  setFieldValue,
  handleChange,
  errors,
  touched,
}) => {
  const classes = useStyles();
  const [isDisable, setIsDisable] = useState(true)
  
  const handleAddStop = () => {
    setFieldValue("stops", [
      ...values.stops,
      { order: values.stops.length + 1 }
    ]);
  };

  const handleStopsChange = (e) => {
    const newStops = [...values.stops];
    const { name, value } = e.target;
    const order = parseInt(name.split('stop')[1]);
   
    const indexOfCurrentStop = order - 1;
    newStops.splice(indexOfCurrentStop, 1, {
      ...value,
      order,
    });

    newStops[indexOfCurrentStop].name && setIsDisable(false);

    setFieldValue('stops', [...newStops]);
  };

  const stops = values.stops.map(({ order }, index) => {
    const name = `stop${order}`;
    const label = `Stop ${order}`;

    const handleStopDelete = () => {
      const newStops = values.stops
        .filter(stop => stop.order !== order)
        .map((stop, index) => ({ ...stop, order: index + 1 }));

      setFieldValue("stops", newStops);
    };

    return (
      <TimelineItem key={index+1}>
       
        <TimelineContent className={classes.newLocationContent}>
          <Box className={classes.stopInputContainer}>
            <StoredLocationSearch
              name={name}
              label={label}
              placeholder="Search locations"
              value={values.stops[index]}
              onChange={handleStopsChange}
              projectId={projectId}
              values={values}
              isDisabled={order > 1 && isDisable}
              returnField="stops"
              returnFieldMetadata={{ order: order || index + 1}}
            />
            {index > 1 && <Button variant="simple" onClick={handleStopDelete}>
              Delete stop
            </Button>}
          </Box>
        </TimelineContent>
      </TimelineItem>
    );
  });


  return (
    <Box className={classes.timelineContainer}>
      <Grid container spacing={3}>
      <Grid item md={6} xs={12} style={{paddingTop: 0}}>
        <StoredLocationSearch
          name="from"
          label="Pick up location *"
          placeholder="Search locations"
          value={values.from}
          onChange={handleChange}
          projectId={projectId}
          error={touched.from && errors.from}
          values={values}
          disableAsDirected
          returnField="from"
        />
        
      </Grid>
      
      <Grid item md={6} xs={12} style={{paddingTop: 0}}>
        <StoredLocationSearch
          name="to"
          label="Drop-off location *"
          placeholder="Search locations"
          value={values.to}
          onChange={handleChange}
          projectId={projectId}
          error={touched.to && errors.to}
          values={values}
          returnField="to"
        />
       
      </Grid>
      <Grid item md={12} xs={12} className={classes.stopsContainer}>
      {stops}
      </Grid>
      </Grid>
     
      <Button variant="simple" onClick={handleAddStop} style={{marginTop: 36}}>
        Add new stop
      </Button>
        
      
    </Box>
  );
};

export default StoredLocationTimeline;
