import React, { useState } from "react";
import Typography from "components_v2/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CopySMSModal from "./CopySMSModal/CopySMSModal";
import { getSentSMSs } from "./BookingHelper";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})

const CopySMS = ({ data, id }) => {
  const classes = useStyles();

  const [
    copySMSModalData,
    setCopySMSModalData
  ] = useState(null);

  const sentSMSs = getSentSMSs({ data })

  return <>
    <CopySMSModal
      sentSMSs={sentSMSs}
      data={copySMSModalData?.record}
      onClickCancel={() => {
        setCopySMSModalData(null);
      }}
    />
    <div
      className={classes.item}
      id={id}
      onClick={() => {
        setCopySMSModalData({
          record: data
        })
      }}>
      <Typography
        customVariant="bodySmallRegular"
      >
        Copy SMS
      </Typography>
    </div>
  </>
}

export default CopySMS;