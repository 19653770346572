import React from 'react';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import useStyles from './WeekViewDayScaleCell.style';
import { mobileViewBreakpoint } from '../../../App.helper';

const WeekViewDayScaleCell = ({
  setView,
  startDate,
  onDateChange,
  ...restProps
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const formatWeekViewDayScale = (date) =>
    moment(date).format(isMobileView ? 'ddd DD' : 'dddd DD');

  // Boolean prop to determine if today is the current day is not working as expected
  // const today = eventTimeZone
  //   ? moment(startDate).format('YYYY MM DD') ===
  //     momentTimezone(Date.now()).tz(eventTimeZone).format('YYYY MM DD')
  //   : false;

  const dayScaleClass = restProps.today
    ? classes.dayScaleCellToday
    : classes.dayScaleCell;

  const handleChangeView = () => {
    const eventObj = { target: { value: null } };

    const utcDate = moment.utc(startDate).format('YYYY-MM-DD');
    const utcTime = moment(new Date()).format('hh:mm');
    const newDateTime = `${utcDate} ${utcTime}`;
    eventObj.target.value = newDateTime;

    setView('day');
    onDateChange(eventObj);
  };

  // const handleWeekChange = (startDate, dayOfWeek, direction) => () => {
  //   const daysToAdd = dayOfWeek === 0 ? 7 : 1;
  //   const daysToSubtract = dayOfWeek === 0 ? 1 : 7;

  //   const currentUTCTime = moment.utc(new Date()).format('hh:mm');
  //   const newDate =
  //     direction === 'next'
  //       ? moment.utc(startDate).add(daysToAdd, 'days').format('YYYY-MM-DD')
  //       : moment
  //           .utc(startDate)
  //           .subtract(daysToSubtract, 'days')
  //           .format('YYYY-MM-DD');
  //   const newDateTime = `${newDate} ${currentUTCTime}`;
  //   onDateChange({ target: { value: newDateTime } });
  // };

  // const getClickHandler = (startDate) => {
  //   const dayOfWeek = parseInt(moment(startDate).format('d'));

  //   if (dayOfWeek > 0 && dayOfWeek < 6) return handleChangeView;
  //   return handleWeekChange(startDate, dayOfWeek);
  // };

  // const clickHandler = getClickHandler(startDate);

  return (
    <WeekView.DayScaleCell
      {...restProps}
      className={dayScaleClass}
      formatDate={formatWeekViewDayScale}
      onClick={handleChangeView}
      startDate={startDate}
      // today={today}
    />
  );
};

export default WeekViewDayScaleCell;
