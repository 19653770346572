export const mockData = {
  products: [
    {
      id: '5f9f469fd0f6302e104174f1',
      name: 'Champagne',
      description: '',
      price: 139,
      __typename: 'Product',
    },
  ],
};
