import React, { useState } from 'react';
import GetAllByEvent from 'gql/VehicleClasses/GetAllByEvent';
import UpdateVehicleclass from 'gql/VehicleClasses/UpdateVehicleclass';


import { withRouter } from 'react-router';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PricingSettingComponent from './PricingSettings.component';
import { mockData } from '../VehicleClasses/VehicleClasses.mock';
import { getSortedPricingSetting, getFilteredPricingSetting } from './helper'
const PricingSettingContainer = ({ projectId, isHelpTutorial }) => {
  let { data, loading, refetch } = useQuery(GetAllByEvent, {
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  //   const [
  //     createVehicleClass,
  //     { data: createMutationData, loading: createMutationLoading },
  //   ] = useMutation(CreateVehicleclass);

  const [
    updateVehicleClass,
  ] = useMutation(UpdateVehicleclass);
  const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
  let finalData = getFilteredPricingSetting({ pricingSetting: data?.vehicleclasses, filterKey });
  finalData = getSortedPricingSetting({ pricingSetting: finalData, sort })

  return (
    <PricingSettingComponent
      setFilterKey={setFilterKey}
      setSort={setSort}
      sort={sort}
      data={finalData || []}
      loading={loading}
      //   createVehicleClass={createVehicleClass}
      updateVehicleClass={updateVehicleClass}
      refetch={refetch}
      projectId={projectId}
    />
  );
};
export default withRouter(PricingSettingContainer);
