import * as Yup from "yup";
import { addUpdatedOrCreatedBy } from "utils/formHelpers";
import {
  checkIfEmailExists,
  checkIfEmailValid,
  checkIfUpdatedEmailExists
} from "utils/validationHelpers";
import asyncDebouncer from "utils/asyncDebouncer";

export const validationSchema = isUpdate =>
  Yup.object().shape({
    passengers: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
          .required("Required")
          .min(2, "Too short!"),
        lastName: Yup.string()
          .required("Required")
          .min(2, "Too short!"),
        /* TEMPORARY REMOVE VALIDATION
        email: isUpdate
        ? Yup.string()
            .required("Required")
            .test("Check Valid Email", "Email is invalid", checkIfEmailValid)
            .when('uneditedEmail', (uneditedEmail, schema) => schema.test(
              'Email Already Exists',
              'This email is already in use',
              asyncDebouncer((value) => checkIfUpdatedEmailExists(value, uneditedEmail), 400)
            ))
        : Yup.string()
            .required("Required")
            .test("Check Valid Email", "Email is invalid", checkIfEmailValid)
            .test(
              "Email Already Exists",
              "This email is already in use",
              asyncDebouncer(value => checkIfEmailExists(value), 400)
            ),*/
        passengerPriority: Yup.object()
          .nullable()
          .required("Required")
      })
    )
  });

export const priorityOptions = [
  { value: "normal", name: "Normal" },
  { value: "high", name: "High" },
  { value: "vip", name: "VIP" }
];

export const formatSubmissionValues = (values, isUpdate) => {
  const formatted = values.passengers.map(passenger => {
    const formattedValues = {
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      email: passenger.email ? passenger.email : "noemail@noemail.com",
      phone: passenger.phone,
      company: passenger.company,
      notes: passenger.notes,
      passengerPriority: passenger.passengerPriority.name,
      profileImage: passenger.profileImage
    };

    return addUpdatedOrCreatedBy(formattedValues, isUpdate);
  });

  if (isUpdate) return formatted;

  return formatted.map(passenger => ({
    ...passenger,
    username: Math.floor(Date.now() * Math.random()).toString(),
    password: values.password,
    role: values.role,
    event: values.event
  }));
};

export const formatIncomingData = data => ({
  passengers: [
    {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      company: data.company,
      notes: data.notes,
      passengerPriority: { name: data.passengerPriority },
      profileImage: data.profileImage
    }
  ]
});

export const formatPassengerValidations = results => {
  if (!results || !results.passengers) return {};

  const formatted = {};

  results.passengers.forEach((value, index) => {
    const field = `Passenger ${index + 1}`;

    formatted[field] = value;
  });

  return formatted;
};

export const getErrorMessage = (errors, touched, index, field) =>
  touched.passengers &&
  touched.passengers[index] &&
  touched.passengers[index][field] &&
  errors.passengers &&
  errors.passengers[index] &&
  errors.passengers[index][field];
