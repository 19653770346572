import DynamicList from 'components_v2/List/DynamicList';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { getColumns,getMobileMenuItems } from './ListOptions';
import DetailPanel from './DetailPanel/DetailPanel';
import {  useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from '../../App.helper';
  
const ServiceTrackingComponent = ({
  data,
  loading,
  trackDriver,
  refetch,
  trackingId,
  updateMutationLoading,
  history
}) => {
 
const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

return (
  <DynamicList
    isMobileView={isMobileView}
    showHeader
    data={data}
    title="Tracking"
    numRows={data.length}
    noResultsText="No service tracking records"
    columns={getColumns(trackDriver, data, isMobileView)}
    loading={loading}
    groupDateField="dateOfService"
    detailPanel={(row) => (
      <DetailPanel
        data={row}
        refetch={refetch}
        loading={updateMutationLoading}
      />
    )}
    getMobileMenuItems={(row) => getMobileMenuItems({ row })}
    actions={{
      edit: {
        onClick: ({ id }) => {
          const baseUrl = trackDriver ? `/driver/${trackingId}` : `/vehicle/${trackingId}`;
          history.push(`${baseUrl}/tracking/${id}/edit`);
        }
      },
      create: {
        onClick: () => {
          const baseUrl = trackDriver ? `/driver/${trackingId}` : `/vehicle/${trackingId}`;
          history.push(`${baseUrl}/tracking/new`);
        }
      },
    }}
  />
);
}
export default withRouter(ServiceTrackingComponent);
