import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box } from "@material-ui/core";
import BookingDetail from "components_v2/Bookings/BookingDetail.Component";
import Modal from "components_v2/Modal/Modal";

import Typography from "components_v2/Typography/Typography";
import useStyles from "./DayViewAppointment.style";
import { getStatusLabel } from "../../Bookings/BookingHelper";
import useChat from "hooks/useChat";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";
import GetAllUpcomingByProject from "../../Bookings/Gql/GetAllUpcomingByProject";
import { generateRidesListNew } from "../../Bookings/helpers";
import classNames from "classnames";


const DayViewAppointment = ({ appointment, startDate, history, onDelete }) => {
  const classes = useStyles();
  const [localData, setLocalData] = useState({});

  const getParsedAppointment = (localAppointment) => {
    return {
      ...localAppointment,
      passengerScheduleId: localAppointment?.id,
      driver: localAppointment?.driverschedules[0]?.driver,
      ...localAppointment?.driverschedules[0],
      ridestatuses: localAppointment?.driverschedules[0]?.ridestatuses,
      passengerScheduleCancelled: localAppointment?.cancelled,
      driverScheduleCancelled: localAppointment?.driverschedules[0]?.cancelled
    }
  }

  useEffect(() => {
    setLocalData(getParsedAppointment(appointment));
  }, [appointment])

  // lazy query for updating schedules
  const [
    getNewDriverSchedules,
    { loading: bookingUpdatesLoading, data: lazyData }
  ] = useLazyQuery(GetAllUpcomingByProject, { fetchPolicy: "no-cache" });

  useChat(
    "bookingUpdates",
    async ({ driverSchedules }) => {
      if (driverSchedules && driverSchedules[0] === getParsedAppointment(appointment).id) {
        await getNewDriverSchedules({
          variables: {
            driverScheduleIds: driverSchedules,
            limit: driverSchedules.length
          }
        });
      }
    }
  );

  useEffect(() => {
    if (!_.isEmpty(lazyData)) {
      const lazyLoadedPassengerSchedules = generateRidesListNew(lazyData?.allBookings?.bookings)
      setLocalData(lazyLoadedPassengerSchedules[0])
    }
  }, [lazyData])

  const addHighlightClass = () => {
    return !!bookingUpdatesLoading;
  }

  return (
    <Modal
      fullContainerWidth
      onModalClick={(toggleModal) => (
        <Box onClick={toggleModal} className={classNames(classes.appointment, { 'highlight-dynamic-list-item': addHighlightClass() })}>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginRight: 4 }}
          >
            Pickup Time:
          </Typography>
          <Typography customVariant="bodySmallBold" style={{ marginRight: 8 }}>
            {moment.utc(localData.start).format("HH:mm")}
          </Typography>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginRight: 4 }}
          >
            Client:
          </Typography>
          <Typography customVariant="bodySmallBold" style={{ marginRight: 8 }}>
            {localData.passenger
              ? `${localData.passenger.firstName || ''} ${localData.passenger.lastName || ''}`
              : ''}
          </Typography>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginRight: 4 }}
          > Booking ID:
          </Typography>
          <Typography customVariant="bodySmallBold" style={{ marginRight: 8 }}>
            {appointment.bookingId}
          </Typography>
          <Typography
            customVariant="bodySmallRegular"
            style={{ marginRight: 4 }}
          >
            Status:
          </Typography>
          <Typography customVariant="bodySmallBold">
            {getStatusLabel(localData) || ""}
          </Typography>

        </Box>
      )}
    >
      <BookingDetail
        bookingUpdatesLoading={bookingUpdatesLoading}
        history={history}
        projectId={localData.id}
        isSchedulePage
        startDate={startDate}
        data={localData}
        onDelete={onDelete}
      />
    </Modal>
  );
};

export default DayViewAppointment;
