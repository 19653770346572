import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import Tooltip from 'components_v2/Tooltip/Tooltip';

const getLabelColor = (props) => {
  if(props.labelColor) return props.labelColor;
  if (props.type === 'high') return '#C50202';
  if (props.type === 'medium') return '#CC4A01';
  if (props.type === 'low') return '#2B7479';
};

const useStyles = makeStyles({
  root: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    borderRadius: 90,
  },
  label: {
    padding: '8px 14px',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: (props) => getLabelColor(props),
  },
  disabled: {
    '& $label': {
      color: 'rgba(0, 0, 0, 0.3)',
    },
    background: 'rgba(0, 0, 0, 0.2)',
  },
});

const BrandChip = (props) => {
  const { children, type, description, labelColor, ...other } = props;
  const classes = useStyles({ type , labelColor});

  return (
    <Tooltip title={description || 'No additional description provided'}>
      <Chip
        classes={{
          root: classes.root,
          label: classes.label,
          disabled: classes.disabled,
        }}
        {...other}
      >
        {children}
      </Chip>
    </Tooltip>
  );
};

BrandChip.propTypes = {
  type: PropTypes.oneOf(['high', 'medium', 'low']),
};

export default BrandChip;
