import React from 'react';
import TourTooltipIntro from '../../components_v2/TourTooltip/TourTooltipIntro';
import TourTooltipDescription from '../../components_v2/TourTooltip/TourTooltipDescription';

const totalNumberOfSteps = 3;

export const tourConfig = [
  {
    content: <TourTooltipIntro />,
    locale: {
      skip: 'No thank you',
      next: 'Yes, show me',
    },
    placement: 'center',
    target: 'body',
  },

  {
    content: (
      <TourTooltipDescription
        content="Quickly look at all the current projects and how many active issues are in each."
        index={1}
        totalNumberOfSteps={totalNumberOfSteps}
      />
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '#top-tools',
    locale: {
      skip: 'Skip',
      back: 'Back',
      next: 'Next',
      last: 'Done',
    },
  },
  {
    content: (
      <TourTooltipDescription
        content="Quickly look at all the current projects and how many active issues are in each."
        index={2}
        totalNumberOfSteps={totalNumberOfSteps}
      />
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '#fab',
    locale: {
      skip: 'Skip',
      back: 'Back',
      next: 'Next',
      last: 'Done',
    },
  },
  {
    content: (
      <TourTooltipDescription
        content="Quickly look at all the current projects and how many active issues are in each."
        index={3}
        totalNumberOfSteps={totalNumberOfSteps}
      />
    ),
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '#sample-form',
    placement: 'bottom-end',
    locale: {
      skip: 'Skip',
      back: 'Back',
      next: 'Next',
      last: 'Done',
    },
  },
];
