import React from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";

const PuDo = ({ data }) => {
  const getStops = getStopsData => {
    let stops = "";
    if (getStopsData?.savedStops) {
      stops = getStopsData.savedStops;
      if (typeof getStopsData.savedStops === "string")
        stops = JSON.parse(getStopsData.savedStops);

      return _.join(
        _.map(
          stops,
          (stop, key) => `Stop ${key + 1}:${stop.name}, ${stop.address}`
        ),
        "\n"
      );
    } else if (getStopsData?.stops) {
      stops = getStopsData.stops;
      if (typeof getStopsData.stops === "string")
        stops = JSON.parse(getStopsData.stops);

      return _.join(
        _.map(
          stops,
          (stop, key) => `Stop ${key + 1}:${stop.name}, ${stop.address}`
        ),
        "\n"
      );
    }
    return stops;
  };

  return (
    <Typography
      style={{
        fontSize: 14,
        whiteSpace: "pre-wrap",
        flexDirection: "row"
      }}
    >
      Pickup:{" "}
      {data?.from && (
        <>
          <strong>{data?.from?.name || ""}</strong>, {data?.from?.address || ""}
        </>
      )}
      <br />
      <span style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
        {getStops(data)}
      </span>
      <br />
      Dropoff:
      {data?.to && (
        <>
          <strong> {data?.to?.name || ""}</strong>, {data?.to?.address || ""}
        </>
      )}
    </Typography>
  );
};

export default PuDo;
