import gql from 'graphql-tag';

export default gql`
  mutation CreatePassengerSchedules($input: [PassengerscheduleInput]) {
    createPassengerschedules(input: $input) {
      id
      bookingId
      isReocurring
      reocurringDates
      createdBy {
        email
      }
    }
  }
`;
