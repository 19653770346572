import React from "react";
import DynamicList from "components_v2/List/DynamicList";
import { StarBorder } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import FAB from "components_v2/Buttons/FAB/FAB";
import { FloatingActionNewSupplier } from "components_v2/Icons/Icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import DataSelector from "../../../Inputs/DataSelector/DataSelector";
import { getHamburgerMenuItems, getMobileMenuItems } from "./ListOptions";
import { mobileViewBreakpoint } from "../../../../App.helper";
import EmailDisplay from "../../../EmailDisplay/EmailDisplay";
import PhoneDisplay from "./../../../PhoneDisplay/PhoneDisplay";
import { getSortedSuppliers } from "./helper";

const MainContainer = ({ listConfig , count ,data, handleListConfigChange ,history, loading }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const suppliersForDataSelector = getSortedSuppliers({
    suppliers: data, sort: {
      field: 'name',
      order: 'asc'
    }
  })

  return (
    <div style={isMobileView ? { paddingBottom: 64 } : {}}>
      <FAB
        label="Add supplier"
        icon={FloatingActionNewSupplier}
        onClick={() => {
          history.push("/supplier/new");
        }}
      />

      <DataSelector
      
        variant={(isMobileView && "h4Bold") || "h2Bold"}
        value="all"
        urlPrefix="/supplier/"
        options={suppliersForDataSelector || []}
        labelField="name"
        loading={loading}
        valueField="id:"
        allDataObject={{
          name: "All Suppliers",
          id: "all",
          url: "/suppliers",
        }}
      />
      <DynamicList
    
      listConfig={{...listConfig, count}}
      onListConfigChange={handleListConfigChange}
        isMobileView={isMobileView}
        showHeader
        data={data}
        numRows={count}
        loading={loading}
        enableFreezeHeader
        noResultsText="No suppliers"
        actions={{
          rowClick: (row) => {
            history.push(`/supplier/${row.id}`);
          },
        }}
        hamburgerMenuItems={getHamburgerMenuItems({ history })}
        getMobileMenuItems={(row) => getMobileMenuItems({ history, row })}
        customActions={[
          {
            onClick: () => true,
            label: "Favorite",
            icon: StarBorder,
            successString: "Supplier added to your favorites",
          },
        ]}
        columns={[
          {
            field: "name",
            headerName: "Company Name",
            width: 3,
          },
          {
            field: "email",
            headerName: "Email",
            width: 2,
            render: (data) => <EmailDisplay email={data.email}></EmailDisplay>,
          },
          {
            field: "contactName",
            headerName: "Contact Name",
            width: 2,
          },
          {
            field: "phone",
            headerName: "Phone",
            width: 2,
            hideMobile: true,

            render: (data) => (
              <PhoneDisplay phone={data.phone}></PhoneDisplay>
            ),
          },
          {
            field: "address",
            headerName: "Address",
            width: 2,
            hideMobile: true,
          },
        ]}
      />
    </div>
  );
};
export default withRouter(MainContainer);
