import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ToastInfo,
  CheckboxContainer,
  CheckboxSelectedContainer,
  AlertFill,
  Alpha,
  Alert,
  Busy,
  NewDriver,
  Vacant,
  Mexico,
  Peru,
  Portugal,
  Southafrica,
  Spain,
  Uae,
  Admin,
  Dispatch,
  Operations,
  StatusAlert,
  Busy1,
  StatusBusy,
  Newdriver,
  StatusVacant,
  Disabled,
  Disabledvip,
  VIP,
  Active,
  Disabled1,
  ActiveDisabled,
  ActiveVip,
  Bus,
  Minibus,
  Sedan,
  Suv,
  Van,
  BusStatus,
  MinibusStatus,
  SedanStatus,
  SuvStatus,
  VanStatus,
  AccountCircle,
  AccountSquare,
  Add,
  AgendaView,
  Albums,
  Apps,
  Calendar,
  Call,
  Camera,
  Car,
  Category1,
  Category,
  ChartPie1,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clear,
  Clients,
  Crop,
  Delete,
  FunctionalDisabled,
  FunctionalDisabledVIP,
  Done,
  DownloadTo,
  Edit,
  Expand,
  Explore,
  Export,
  Facebook,
  FavDisabled,
  Fav,
  Feed,
  FilesLabeled,
  FilterList,
  FilterOutlined,
  Folder,
  GestureSwipeLeft,
  GestureSwipeRight2f,
  Goal,
  GroupAdd,
  Help,
  Hidden,
  Info,
  Instagram,
  Linkedin,
  List,
  Map,
  MenuHamburger,
  MenuLeftRight,
  MenuVs,
  Messages,
  MoreHorizontal,
  MoreVertical,
  NewBooking,
  NewClient,
  FunctionalNewDriver,
  NewLocation,
  NewPassenger,
  NewProject,
  NewSupplier,
  NewVehicle,
  Notice,
  Notifications,
  Pointer,
  Reminder,
  RotateLeft,
  RotateRight,
  Route,
  Save,
  SearchIcon,
  Settings,
  Share,
  SignIn,
  SignOut,
  Star,
  Subtract,
  Twitter,
  Upload,
  User,
  UsersSwitch,
  UserAdd,
  UserBig,
  FunctionalVIP,
  Visible,
  Waiting,
  ArrowsChevronDown,
  ArrowsChevronLeft,
  ArrowsChevronRight,
  ArrowsChevronUp,
  FloatingActionNewBooking,
  FloatingActionNewClient,
  FloatingActionNewDriver,
  FloatingActionNewLocation,
  FloatingActionNewPassenger,
  FloatingActionNewProject,
  FloatingActionNewSupplier,
  FloatingActionNewVehicle,
  Pin,
  MenuChartPie1,
  MenuFolder,
  MenuPointer,
  Distance,
  DistanceFull,
  Book,
  Champagne,
  Diamond,
  Fastfood,
  Flower,
  Gift,
  Light,
  Simcard,
  Waterbottle
} from '../Icons/Icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px',

    '& > svg': {
      margin: '10px',
    },
  },
}));

const Iconography = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="iconography">
      <DistanceFull />

      <ToastInfo />

      <Distance />

      <CheckboxContainer />

      <CheckboxSelectedContainer />

      <AlertFill />

      <Alpha />

      <Alert />

      <Busy />

      <NewDriver />

      <Vacant />

      <Mexico />

      <Peru />

      <Portugal />

      <Southafrica />

      <Spain />

      <Uae />

      <Admin />

      <Dispatch />

      <Operations />

      <StatusAlert />

      <Busy1 />

      <StatusBusy />

      <Newdriver />

      <StatusVacant />

      <Disabled />

      <Disabledvip />

      <VIP />

      <Active />

      <Disabled1 />

      <ActiveDisabled />

      <ActiveVip />

      <Bus />

      <Minibus />

      <Sedan />

      <Suv />

      <Van />

      <BusStatus />

      <MinibusStatus />

      <SedanStatus />

      <SuvStatus />

      <VanStatus />

      <AccountCircle />

      <AccountSquare />

      <Add />

      <AgendaView />

      <Albums />

      <Apps />

      <Calendar />

      <Call />

      <Camera />

      <Car />

      <Category1 />

      <Category />

      <ChartPie1 />

      <ChevronDown />

      <ChevronLeft />

      <ChevronRight />

      <ChevronUp />

      <Clear />

      <Clients />

      <Crop />

      <Delete />

      <FunctionalDisabled />

      <FunctionalDisabledVIP />

      <Done />

      <DownloadTo />

      <Edit />

      <Expand />

      <Explore />

      <Export />

      <Facebook />

      <FavDisabled />

      <Fav />

      <Feed />

      <FilesLabeled />

      <FilterList />

      <FilterOutlined />

      <Folder />

      <GestureSwipeLeft />

      <GestureSwipeRight2f />

      <Goal />

      <GroupAdd />

      <Help />

      <Hidden />

      <Info />

      <Instagram />

      <Linkedin />

      <List />

      <Map />

      <MenuHamburger />

      <MenuLeftRight />

      <MenuVs />

      <Messages />

      <MoreHorizontal />

      <MoreVertical />

      <NewBooking />

      <NewClient />

      <FunctionalNewDriver />

      <NewLocation />

      <NewPassenger />

      <NewProject />

      <NewSupplier />

      <NewVehicle />

      <Notice />

      <Notifications />

      <Pointer />

      <Reminder />

      <RotateLeft />

      <RotateRight />

      <Route />

      <Save />

      <SearchIcon />

      <Settings />

      <Share />

      <SignIn />

      <SignOut />

      <Star />

      <Subtract />

      <Twitter />

      <Upload />

      <User />

      <UsersSwitch />

      <UserAdd />

      <UserBig />

      <FunctionalVIP />

      <Visible />

      <Waiting />

      <ArrowsChevronDown />

      <ArrowsChevronLeft />

      <ArrowsChevronRight />

      <ArrowsChevronUp />

      <FloatingActionNewBooking />

      <FloatingActionNewClient />

      <FloatingActionNewDriver />

      <FloatingActionNewLocation />

      <FloatingActionNewPassenger />

      <FloatingActionNewProject />

      <FloatingActionNewSupplier />

      <FloatingActionNewVehicle />

      <Pin />

      <MenuChartPie1 />

      <MenuFolder />

      <MenuPointer />

      <Book />

      <Champagne />

      <Diamond />

      <Fastfood />

      <Flower />

      <Gift />

      <Light />

      <Simcard />

      <Waterbottle />
    </div>
  );
};

export default Iconography;
