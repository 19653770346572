export const mockAlertsGroupedData = {
  alertsConnection: {
    aggregate: { count: 1, __typename: 'AlertsAggregator' },
    __typename: 'AlertsConnection',
  },
};

export const mockRideRatingsGroupedData = {
  driveratingsConnection: {
    aggregate: {
      count: 4,
      avg: { rating: 3.75, __typename: 'DriveratingAggregatorAvg' },
      __typename: 'DriveratingAggregator',
    },
    groupBy: {
      rating: [
        {
          key: 5,
          connection: {
            aggregate: { count: 2, __typename: 'DriveratingAggregator' },
            __typename: 'DriveratingConnection',
          },
          __typename: 'DriveratingConnectionRating',
        },
        {
          key: 1,
          connection: {
            aggregate: { count: 1, __typename: 'DriveratingAggregator' },
            __typename: 'DriveratingConnection',
          },
          __typename: 'DriveratingConnectionRating',
        },
        {
          key: 4,
          connection: {
            aggregate: { count: 1, __typename: 'DriveratingAggregator' },
            __typename: 'DriveratingConnection',
          },
          __typename: 'DriveratingConnectionRating',
        },
      ],
      __typename: 'DriveratingGroupBy',
    },
    __typename: 'DriveratingConnection',
  },
};

export const mockPassengerSchedulesData = {
  passengerschedules: [
    {
      id: '5f29c23b678370f78808adbc',
      start: '2020-08-05T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f2d299d16f55c9d3ce20642',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c299678370f78808adbd',
      start: '2020-08-04T01:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c394678370f78808adbe',
      start: '2020-08-04T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c4ad0693e9ac508e9368',
      start: '2020-08-04T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c5c280d8bf1149844569',
      start: '2020-08-04T00:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f29c6297b1dcffab432958e',
      start: '2020-08-04T08:00:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f2d2a0f16f55c9d3ce20643',
      start: '2020-08-07T12:16:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f2d2a2116f55c9d3ce20644',
          ridestatuses: [
            {
              id: '5f3ae80f0574f89ed01a3053',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae80f0574f89ed01a3054',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae80f0574f89ed01a3055',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae8120574f89ed01a3056',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae8120574f89ed01a3057',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f3ae8130574f89ed01a3058',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d549cb602c73c11aa57',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d569cb602c73c11aa58',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d579cb602c73c11aa59',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d599cb602c73c11aa5a',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d6d9cb602c73c11aa5b',
              status: 'enroute',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d6f9cb602c73c11aa5c',
              status: 'arrivedToPickup',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d709cb602c73c11aa5d',
              status: 'passengerInVehicle',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d719cb602c73c11aa5e',
              status: 'arrivedToDestination',
              __typename: 'Ridestatus',
            },
            {
              id: '5f692d729cb602c73c11aa5f',
              status: 'complete',
              __typename: 'Ridestatus',
            },
          ],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f2dd487d9045982b0e8bb32',
      start: '2020-08-07T00:23:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [
        {
          id: '5f2dd49bd9045982b0e8bb33',
          ridestatuses: [],
          __typename: 'Driverschedule',
        },
      ],
      __typename: 'Passengerschedule',
    },
    {
      id: '5f343f9a99a4c119f075949a',
      start: '2020-08-12T21:11:00.000Z',
      stop: null,
      numberOfVehicles: '1',
      driverschedules: [],
      __typename: 'Passengerschedule',
    },
  ],
};

export const mockRideRatingListData = {
  driveratings: [
    {
      id: '5f6a4c5aca91dfd8889e95b9',
      rating: 5,
      comment: ':)))',
      event: {
        id: '5f270baecc54999de0a9f579',
        start: '2020-06-18',
        __typename: 'Event',
      },
      passenger: {
        id: '5f28a10f7950d03fd1ef5d84',
        firstName: 'John',
        lastName: 'Reegan',
        __typename: 'UsersPermissionsUser',
      },
      driver: {
        id: '5f2c668d16f55c9d3ce2063f',
        firstName: 'James',
        lastName: 'White',
        __typename: 'UsersPermissionsUser',
      },
      __typename: 'Driverating',
    },

    {
      id: '5f8789c7562cf7a8888acef5',
      rating: 5,
      comment: ':)))',
      event: {
        id: '5f270baecc54999de0a9f579',
        start: '2020-06-18',
        __typename: 'Event',
      },
      passenger: {
        id: '5f3cfc924c2533001783b488',
        firstName: 'Abdul',
        lastName: 'Mohammed',
        __typename: 'UsersPermissionsUser',
      },
      driver: {
        id: '5f6232e0112ad66664644621',
        firstName: 'John',
        lastName: 'Reegan',
        __typename: 'UsersPermissionsUser',
      },
      __typename: 'Driverating',
    },
  ],
};
