import { makeStyles } from '@material-ui/core/styles';

export const useCustomStyles = makeStyles({
  addRemoveWrapper: {
    marginBottom: (props) => (props.noMarginBot ? 0 : '16px'),

    '& .add-remove-input': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',

      '& .MuiInputBase-root': {
        width: (props) => (props.percentage || props.small ? 65 : 'initial'),
      },

      '& .number-input-wrapper-overrider': {
        flex: (props) => (props.percentage || props.small ? 'initial' : 1),

        '& input': {
          '-moz-appearance': 'textfield',
          padding: (props) =>
            props.percentage || props.small
              ? '10.5px 4px 10.5px 14px'
              : '10.5px 14px',

          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },
      '& .input-wrapper-overrider': {
        marginBottom: '0',
        marginRight: '16px',
        marginLeft: '16px',
      },
    },
  },
  percentageIcon: {
    fontSize: 14,
  },
});
