import { useQuery } from '@apollo/react-hooks';
import React ,{useState} from 'react';
import GetAllGQL from 'gql/Suppliers/GetAllByEvent';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import SuppliersComponent from './Suppliers.Component.jsx';
/*
const UPDATE_GQL = gql`
  mutation UpdateEvent($id: InputID!, $data: editEventInput) {
    updateEvent(input: { where: $id, data: $data }) {
      event {
        id:
      }
    }
  }
`; */

const SuppliersContainer = () => {
  /*
  const [save, { data: mutationData, loading: mutationLoading }] = useMutation(
    UPDATE_GQL,
    { variables: { id: { id: projectId } } }
  ); */
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })

  const { data, loading } = useQuery(GetAllGQL, {
    variables: {...listConfig, sort: listConfig.sort || 'id:desc'},
    fetchPolicy: 'no-cache',
  });
  const currentData = data?.suppliersConnection?.values  || [];
  const count = data?.suppliersConnection?.aggregate?.count|| 0;
  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }
  return (
    <>
      <SuppliersComponent
        
        count={count}
        listConfig={listConfig}
        loading={loading}
        handleListConfigChange={handleListConfigChange}
        data={(currentData) || []}
      />
    </>
  );
};

export default withMainLayout('Clients', {}, SuppliersContainer);

/*
const Container = ({ id, type, trackDriver }) => {
  const { data, loading } = useQuery(
    trackDriver ? GetAllByDriver : GetAllByVehicle,
    {
      variables: { id, type }
    }
  );

  if (loading) {
    return <div>loading..</div>;
  }

  return (
    <ServiceTrackingComponent
      loading={loading}
      data={
        data &&
        (trackDriver
          ? data.driverongoingtrackings
          : data.vehiclemaintenancetrackings)
      }
    />
  );
};

export default Container;
*/
