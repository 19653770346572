import gql from 'graphql-tag';

export default gql`
  query GetAllClients( $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String) {
    clientsConnection(where: {  _or: [
        { companyName_contains: $filterKey }
        { location_contains: $filterKey }
        {contactName_contains: $filterKey }
        {phone_contains: $filterKey }
        {address_contains: $filterKey }
    ] 
  } limit: $pageSize
  start: $startIndex
  sort: $sort
  ){
    aggregate {
      count
    }
    values {
    id
      contactName
      companyName
      location
      officeType
      address
      phone
      email
      lat
      long
      events {
        id
        name
        type
      }
    }
  }
  }
`;
