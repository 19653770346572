import { makeStyles } from '@material-ui/core/styles';
import { mobileViewBreakpoint } from '../../App.helper';

export const useStyles = makeStyles((theme) => ({
  tourTooltipIntroWrapper: {
    padding: '0 132px',
    background: '#000000',
    color: '#ffffff',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '0 64px',
    },
  },
  tooltipTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '38px',
    lineHeight: '44px',
    margin: '24px 0',
  },
}));
