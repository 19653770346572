import React from "react";
import auth from "../../utils/auth";
import MainLayout from "./MainLayout";

export default function withMainLayout(
  pageTitle,
  props = {},
  WrappedComponent
) {
  return class extends React.Component {
    render() {
      const userInfo = auth.get("userInfo") ? auth.get("userInfo") : {};

      const eventId = localStorage.getItem("selectedEvent");

      return (
        <MainLayout
          {...props}
          headerText={pageTitle}
          pubNubContext={this.props.pubNubContext}
          subheader={<></>}
          isHelpTutorial={this.props.isHelpTutorial}
        >
          <WrappedComponent
            {...this.props}
            userInfo={userInfo}
            eventId={eventId}
          />
        </MainLayout>
      );
    }
  };
}
