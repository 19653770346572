import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles(() => ({
  mobileFabWrapper: {
    bottom: '5rem !important',
    right: '1.5rem !important',
  },
  fabWrapper: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    border: '4px solid #ffffff',
    borderRadius: '43px',
    zIndex: 1200,

    '& > .MuiFab-root': {
      minHeight: '67px',
      minWidth: '67px',
      borderRadius: '43px',
      boxShadow: 'none',
      width: '67px',

      '& svg': {
        minHeight: '67px',
        minWidth: '67px',
        padding: '14px',
        borderRadius: '43px',
        background: '#122044',

        '& path': {
          fill: '#ffffff',
        },
      },

      '&.MuiFab-extended': {
        width: 'auto',
        background: '#ffffff',
        padding: 0,
        textTransform: 'none',
        color: '#4001C5',
        fontSize: '16px',
        fontWeight: 'bold',
        paddingRight: '16px',

        '& svg': {
          background: '#4001C5',
          marginRight: '16px',

          '& path': {},
        },
      },
    },
  },
}));

const FAB = ({ icon, label, onClick }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const Icon = icon;

  const classes = useStyles();

  const [variant, setVariant] = useState('circular');

  return (
    <div
      id="fab"
      className={clsx(classes.fabWrapper, {
        [classes.mobileFabWrapper]: isMobileView,
      })}
      onMouseOver={() => setVariant('extended')}
      onFocus={() => setVariant('extended')}
      onBlur={() => setVariant('circular')}
      onMouseOut={() => setVariant('circular')}
    >
      <Fab variant={variant} onClick={onClick}>
        <Icon />
        {variant === 'extended' && label}
      </Fab>
    </div>
  );
};

export default FAB;
