
export const getColumns = () => [
  {
    field: "name",
    headerName: "Name",
    isFullWidth: true,
    isDisabled: true,
    width: 1,
  },
  {
    field: "transferPricing",
    headerName: "Transfer Cost",
    isFullWidth: true,
    type: "number",
    width: 1,
  },
  {
    field: "trackTransferPricing",
    headerName: "Track Transfer Cost",
    isFullWidth: true,
    type: "number",
    width: 1,

  },
  {
    field: "fourHourPricing",
    headerName: "4 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    field: "sixHourPricing",
    headerName: "6 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    field: "twelveHourPricing",
    headerName: "12 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    field: "twentyFourHourPricing",
    headerName: "24 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    headerName: "Overtime Cost (per hour)",
    field: "overtimePricing",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    headerName: "No-show",
    field: "noShowPricing",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    headerName: "Waiting Time Cost (per hour)",
    field: "waitingTimePricing",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  }
];
