import * as Yup from "yup";
import {
  getUploadDocuments,
  formatChangedFormValues,
  formatValues
} from "utils/formHelpers";

export const validationSchema = Yup.object().shape({
  supplier: Yup.string()
    .nullable()
    .required("Required"),
  plateNumber: Yup.string()
    .min(1, "Too short!")
    .required("Required"),
  /*make: Yup.string()
    .min(2, "Too short!")
    .required("Required"),
  model: Yup.string()
    .min(2, "Too short!")
    .required("Required"),*/
  class: Yup.object()
    .required("Required")
    .nullable(),
  year: Yup.string()
    .min(4, "Too short!")
    .required("Required"),
  color: Yup.string()
    .min(2, "Too short!")
    .required("Required"),
  maxPassengers: Yup.string().required("Required"),
  maxLuggage: Yup.string().required("Required")
  // drivers: Yup.array().min(1, 'Select a driver').required('Required'),
});

export const getInitialInputValues = (supplierId, projectId) => ({
  documents: [],
  supplier: supplierId || "",
  event: projectId || "",
  plateNumber: "",
  vehicleNumber: "",
  make: "",
  model: "",
  class: null,
  year: "",
  color: "",
  maxPassengers: "",
  maxLuggage: "",
  drivers: [],
  pricingType: "default"
});

export const formatSubmissionValues = (values, initialValues, isUpdate) => {
  /*
    Format Config Notes
    null - no formatting, returns the value as is for the given field
    string - field name, can be nested e.g. 'name.first'
    function - receives values as arg, should return formatted value
    object - Two properties, name = name of field to output, format: format config type (function, string or null)
    array - Multiple objects, will return an object of all specified object configs {name, format}
  */
  const formatConfig = {
    vehicleNumber: null,
    plateNumber: null,
    make: null,
    model: null,
    class: [
      { name: "class", format: "class.name" },
      { name: "vehicleClass", format: "class.id" }
    ],
    year: null,
    imageUrl: null,
    interiorImageUrl: null,
    drivers: {
      name: "additionalData",
      format: vals => JSON.stringify(vals?.drivers?.map(d => d.id))
    },
    color: null,
    supplier: null,
    maxPassengers: vals => parseInt(vals.maxPassengers),
    maxLuggage: vals => parseInt(vals.maxLuggage),
    documents: vals => {
      const uploadedDocuments = getUploadDocuments(vals);
      const documents = [...(vals.documents || []), ...uploadedDocuments];
      return JSON.stringify(documents);
    },
    enabled: null,
    event: "projectId"
  };

  if (isUpdate)
    return formatChangedFormValues(values, initialValues, formatConfig);
  return formatValues(values, formatConfig);

  // return {
  //   vehicleNumber: values.vehicleNumber,
  //   plateNumber: values.plateNumber,
  //   make: values.make,
  //   model: values.model,
  //   vehicleClass: values.class.id,
  //   class: values.class.name,
  //   year: values.year,
  //   imageUrl: values.imageUrl,
  //   interiorImageUrl: values.interiorImageUrl,
  //   additionalData: JSON.stringify(values?.drivers?.map((d) => d.id)),
  //   color: values.color,
  //   supplier: values.supplier,
  //   maxPassengers: parseInt(values.maxPassengers),
  //   maxLuggage: parseInt(values.maxLuggage),
  //   documents: JSON.stringify(documents),
  //   [property]: values[property],
  //   enabled: values.enabled,
  //   event: values.projectId,
  // };
};

export const formatIncomingData = data => ({
  plateNumber: data.plateNumber,
  vehicleNumber: data.vehicleNumber,
  drivers: (data.additionalData || []).map(id => ({ id: id })),
  make: data.make,
  model: data.model,
  class: data.vehicleClass,
  year: data.year,
  imageUrl: data.imageUrl,
  interiorImageUrl: data.interiorImageUrl,
  color: data.color,
  maxPassengers: data.maxPassengers,
  maxLuggage: data.maxLuggage,
  documents: data.documents || [],
  supplier: data.supplier?.id || null,
  enabled: data.enabled
});

export const mergeReturnValues = previousFormState => {
  const { values, returnField, returnValues } = previousFormState;
  const newValues = { ...values };

  if (!returnField || !returnValues) return newValues;

  const returnValue = returnValues[returnField];

  if (returnField === "supplier") newValues[returnField] = returnValue?.id;
  else if (returnField === "drivers") newValues[returnField] = [returnValue];

  return newValues;
};
