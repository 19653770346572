import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Vehicles/GetOne';
import GetTransferServicesByEvent from "gql/TransferServices/GetAllByEvent"
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddVehicleComponent from './AddVehicle.Component';

const AddVehicleContainer = ({ match }) => {
  const { vehicleId, projectId } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: vehicleId },
    skip: !vehicleId,
    fetchPolicy: 'no-cache',
  });

  const { data: servicesData, loading: servicesLoading } = useQuery(GetTransferServicesByEvent, {
    variables: { id: projectId },
    skip: !projectId,
    fetchPolicy: 'no-cache',
  });
  

  if (loading || servicesLoading) return <Loading />;


  return (
    <AddVehicleComponent
      data={data?.vehicles[0]}
      transferservices={servicesData?.transferservices || []}
      vehicleId={vehicleId}
      refetchVehicle={refetch}
    />
  );
};

AddVehicleContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ vehicleId: PropTypes.string }),
  }),
};

export default withMainLayout(
  'Add Vehicle',
  {},
  withRouter(AddVehicleContainer)
);
