import { makeStyles } from '@material-ui/core/styles';

import { otherAppointmentsBaseStyles } from '../helpers';

export default makeStyles(() => ({
  timeTableCell: {
    height: 120,
    position: 'relative',
    '&:hover>div>button': {
      visibility: 'visible',
      opacity: 1,
    },
    '&>div>button': {
      visibility: 'hidden',
      opacity: 0,
      height: '100%',
    },
  },
  timeTableCellContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '&:hover': {
      '& + button': {
        visibility: 'hidden !important',
        opacity: '0 !important',
      }
    }
  },
  otherAppointments: {
    ...otherAppointmentsBaseStyles,
    width: 72,
  },
  dropdown: {
    position: 'absolute',
    background: '#fff',
    zIndex: 10,
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.15)',
  },
  viewLessButton: {
    background: 'rgba(64, 1, 197, 0.05)',
    border: '1px solid rgba(0, 0, 0, 0.02)',
    borderRadius: 90,
    width: 88,
    padding: 8,
    margin: '16px 8px',
    '&:hover': {
      background: 'rgba(64, 1, 197, 0.05)',
    },
    '&:hover span': {
      color: '#000',
    },
    '& span': {
      color: '#000',
    },
  },
}));
