import React from 'react';
import {
  Paper,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableFooter,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Typography from 'components_v2/Typography/Typography';
import getStyles from './Table.styles';

// Run storybook and navigate to Table => Primary => Docs => Show Code to see what the props should look like

const getTableCellClass = (columns, index, classes, data = [], rowIndex) => {
  if (rowIndex === data.length - 1) return classes.lastRowCellRoot;
  if (index === 0) return classes.firstCellRoot;
  if (index === columns.length - 1) return classes.lastCellRoot;
  return classes.midCellRoot;
};

const Table = ({ data, columns, mainAction, secondaryAction, noBorder }) => {
  const classes = getStyles({ noBorder });

  const tableHeadCells = columns.map((column, index) => {
    const cellRootClass = getTableCellClass(columns, index, classes, data, 0);
    const { align, name, width } = column;
    return (
      <TableCell
        classes={{ root: cellRootClass }}
        className={classes.headerCell}
        align={align}
        width={width}
      >
        <Typography
          style={{ color: 'rgba(0, 0, 0, 0.7)' }}
          customVariant="ctaRegular"
        >
          {name}
        </Typography>
      </TableCell>
    );
  });

  const tableBodyRows = data.map((row, rowIndex) => {
    let bodyRow = [];

    columns.forEach((column, index) => {
      const cellRootClass = getTableCellClass(
        columns,
        index,
        classes,
        data,
        rowIndex
      );
      const { render, field, align, width } = column;

      const tableCell = render ? (
        <>{render(row)}</>
      ) : (
        <Typography
          style={{ lineHeight: '20px', color: '#3F3B3B' }}
          customVariant="bodySmallRegular"
        >
          {row[field]}
        </Typography>
      );

      bodyRow = [
        ...bodyRow,
        <TableCell
          width={width}
          classes={{ root: cellRootClass }}
          align={align}
        >
          {tableCell}
        </TableCell>,
      ];
    });

    return <TableRow>{bodyRow}</TableRow>;
  });

  return (
    <TableContainer className={classes.paper} component={Paper}>
      <MuiTable>
        <TableHead>
          <TableRow>{tableHeadCells}</TableRow>
        </TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </MuiTable>
      {(mainAction || secondaryAction) && (
        <TableFooter className={classes.footer}>
          <>{secondaryAction}</>
          <>{mainAction}</>
        </TableFooter>
      )}
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  mainAction: PropTypes.element,
  secondaryAction: PropTypes.element,
  noBorder: PropTypes.bool,
};

export default Table;
