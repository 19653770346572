import gql from 'graphql-tag';

export default gql`
  mutation UpdateAlert($id: InputID!, $data: editAlertInput) {
    updateAlert(input: { where: $id, data: $data }) {
      alert {
        id
      }
    }
  }
`;
