import React from "react";
import { Box, Grid } from "@material-ui/core";
import Typography from "components_v2/Typography/Typography";
import FormSection from "components_v2/Inputs/FormSection/FormSection";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import ToggleSwitchButton from "components_v2/Inputs/ToggleSwitchButton/ToggleSwitchButton";
import PhoneInput from "components_v2/Inputs/PhoneInput/PhoneInput";
import TotalErrors from "components_v2/Inputs/TotalErrors/TotalErrors";
import GeneratePassword from "components_v2/Inputs/GeneratePassword/GeneratePassword";
import UploadImage from "components_v2/Upload/UploadImage/UploadImage";

const UserDetailsForm = ({
  values,
  touched,
  errors,
  handleChange,
  classes,
  isUpdate
}) => {

    return (
      <>
        <FormSection defaultExpanded title="Enter user details">
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <TextInput
                name="firstName"
                label="First Name *"
                placeholder="Enter first name"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && errors.firstName} />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextInput
                name="lastName"
                label="Last Name *"
                placeholder="Enter last name"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && errors.lastName} />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <TextInput
                name="email"
                label="Email *"
                placeholder="Enter email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && errors.email} />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <PhoneInput
                name="phone"
                label="Phone"
                placeholder="Enter phone number"
                value={values.phone}
                onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <TextInput
                name="company"
                label="Company"
                placeholder="Enter company"
                value={values.company}
                onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid className={classes.toggleContainer} item md={4} sm={6} xs={12}>
              <ToggleSwitchButton
                name="blocked"
                label="Disabled"
                value={values.blocked}
                onChange={handleChange} />
              <ToggleSwitchButton
                name="isSuperAdmin"
                label="Is Super Admin?"
                value={values.isSuperAdmin}
                onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer} container spacing={3}>
            <Grid className={classes.photoRow} md={6} xs={12} item>
              <Typography
                style={{ marginBottom: 8, color: "#3C3C3C" }}
                customVariant="bodySmallRegular"
              >
                Upload photo of user
              </Typography>
              <UploadImage
                placeholder="Add photo of user"
                defaultPreviewImage={values.profileImage}
                onComplete={data => {
                  handleChange({
                    persist: () => { },
                    target: {
                      type: "change",
                      name: "profileImage",
                      value: data.url
                    }
                  });
                } } />
            </Grid>
          </Grid>
        </FormSection>
        {isUpdate && <FormSection title="Configure user password">
          <>
            <ToggleSwitchButton
              name="updatePassword"
              label="Reset password"
              value={values.updatePassword}
              onChange={handleChange} />
            {values.updatePassword && <Box style={{ marginBottom: 32 }} />}
          </>
          {values.updatePassword ? (
            <GeneratePassword
              title="Configure driver password"
              handleChange={handleChange}
              values={values}
              gridContainerClass={classes.gridContainer}
              touched={touched}
              errors={errors} />
          ) : null}
        </FormSection>}
        <TotalErrors errors={errors} touched={touched} withSpacer />
      </>
    );
  };

export default UserDetailsForm;
