import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "components_v2/Rating/Rating";
import TextInput from "../../../Inputs/TextInput/TextInput";
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { mobileViewBreakpoint } from '../../../../App.helper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Logo from 'static/logo_blue_alt.png';
import Typography from 'components_v2/Typography/Typography';
import FormFooter from "../Login/FormFooter";
import Button from "components_v2/Button/Button";
import { Done } from "components_v2/Icons/Icons";
import Loading from "components_v2/Loading/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    minHeight: '100vh',
  },
  cardRoot: {
    background: '#FFF',
    position: 'relative',
    width: 697,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
    border: '1px solid rgba(0, 0, 0, 0.05)',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: '100%',
      display: 'flex',
      // flexDirection: 'column',
      // height: 'calc(100vh - 80px)',
      overflow: 'auto',
      paddingBottom: '80px',
      flex: 1,
      height: '100vh',
      alignItems: 'center'
    },
  },
  cardContentRoot: {
    padding: '40px 88px 0 88px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '20px',
      // paddingTop: '72px',
      width: 'calc(100% - 20px)'
    },

    '& .input-wrapper-overrider': {
      width: '360px',
      textAlign: 'left',

      '& textarea': {
        height: '80px !important',
      },

      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        width: 'calc(100% - 20px)',
      },
    }
  },
  doneIcon: {
    width: 53,
    height: "auto",
    filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
    "& path": {
      fill: "#FFF"
    }
  },

  footerFormWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      boxShadow: "0px -10px 17px rgba(0, 0, 0, 0.1)",
      background: "#fff",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0
    },

    "& > footer": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        background: "#fff"
      }
    }
  }
}));

export default function HoverRating({
  loading,
  onRatingChange,
  ratingData,
  icon,
  driverSchedule
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const iconContainer = icon ? (
    <div>
      <div
        style={{
          background: 'rgba(0, 0, 0, 0.8)',
          boxShadow: 'rgba(0, 0, 0, 0.3) 1px 8px 12px',
          width: 73,
          height: 73,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 32,
        }}
      >
        {icon}
      </div>
    </div>
  ) : null;

  const [currentComments, setCurrentComments] = useState("");
  const [currentRating, setCurrentRating] = useState(0);

  const handleRatingChange = rating => {
    setCurrentRating(rating)
  };

  const handleSubmit = () => {
    onRatingChange(currentRating, currentComments);
  }



  const getRatingForm = () => {
    return <>
      <Typography style={{ marginBottom: 16 }} customVariant={isMobileView ? "h4Bold" : "h3Bold"}>
        Please rate your ride
      </Typography>
      <Typography
        style={{ maxWidth: 391, marginBottom: '40px' }}
        customVariant="bodySmallRegular"
      >
        {driverSchedule?.passengerschedule?.from?.name} to {driverSchedule?.passengerschedule?.to?.name}
      </Typography>
      <div style={{ marginBottom: '40px' }}>
        <Rating
          onRatingChange={handleRatingChange}
          value={currentRating}
        />
      </div>
      <TextInput
        label="Comments"
        multiline
        onChange={event => setCurrentComments(event.target.value)}
        value={currentComments}
      />
    </>
  }

  const getRatingSubmittedForm = () => {
    return <>
      <div
        style={{
          background: 'rgba(0, 0, 0, 0.8)',
          boxShadow: 'rgba(0, 0, 0, 0.3) 1px 8px 12px',
          width: 73,
          height: 73,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 32,
          marginTop: '80px'
        }}
      >
        <Done className={classes.doneIcon} style={{}} />
      </div>

      <Typography style={{ marginBottom: 16, }} customVariant={isMobileView ? "h4Bold" : "h3Bold"}>
        Rating posted successfully
      </Typography>
      <Typography
        style={{ maxWidth: 391, marginBottom: '80px' }}
        customVariant="bodySmallRegular"
      >
        You have rated your ride {driverSchedule?.passengerschedule?.from?.name} to {driverSchedule?.passengerschedule?.to?.name} with {ratingData.rating} stars.
      </Typography>
    </>
  }

  const isRatingProvided = ratingData && (!!ratingData.rating || !!ratingData.comment)

  return (
    <div className={classes.root}>
      {loading && (
        <Loading
          fullPage
          backgroundPosition="absolute"
          backgroundWidth="100%"
          backgroundHeight="100%"
        />
      )}
      <MuiCard classes={{ root: classes.cardRoot }} style={!isRatingProvided ? { flexDirection: 'column', justifyContent: 'center' } : {}}>
        <CardContent
          classes={{ root: classes.cardContentRoot }}
        ><Link to="/">
            <img style={{ marginBottom: 48 }} src={Logo} alt="RDE logo" />
          </Link>
          {iconContainer}
          {isRatingProvided ? getRatingSubmittedForm() : getRatingForm()}
        </CardContent>
        {isRatingProvided ? <></> : <div className={classes.footerFormWrapper}>
          <FormFooter
            mainAction={
              <Button
                onClick={handleSubmit}
                variant="contained"
                type="Submit"
              >
                Submit
          </Button>
            }
          />
        </div>}
      </MuiCard>

    </div>
  );
}
