import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";

import GetEnabledVehicleTrackingTypes from "./Gql/GetEnabledVehicleTrackingTypes";
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import Loading from "components_v2/Loading/CircularProgress";
// import { createLocationWithState } from "utils/routerHelpers";

const VehicleTrackingTypeSelect = props => {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    // values,
    noMargin,
    error,
    // history,
    // location,
  } = props;

  const { data, loading } = useQuery(GetEnabledVehicleTrackingTypes, {
    fetchPolicy: "no-cache",
  });


  const options =
    !loading && data ?
    data?.vehicletrackingtypes
      .map(type => ({
        name: type.name,
        id: type.id
      })) :
      [
        {
          loading: true,
          name: "",
        }
      ];
  
  // TODO: Left this here for when we need to add the ability for users to add new vehicle types.
  // const optionsWithAddNewLink = (options && [
  //   ...options,
  //   { link: true, name: "Add New" }
  // ]) || [{ link: true, name: "Add New" }];

  return (
    <SelectInput
      name={name}
      label={label}
      noMargin={noMargin}
      placeholder={placeholder}
      getOptionDisabled={(option) => option.loading}
      value={value || ""}
      onChange={e => {
        // TODO: Left this here for when we need to add the ability for users to add new vehicle types.
        // if (e?.target?.value?.link) {
        //   const locationWithState = createLocationWithState(
        //     `/user/new`,
        //     location,
        //     values,
        //     null,
        //     true
        //   );
          
        //   history.replace(locationWithState)
        // } else onChange(e);
        onChange(e);
      }}
      options={options || []}
      isError={!!error}
      errorMessage={error}
      renderOption={option => (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {option.link ? (
            <div
              style={{
                width: "100%",
                height: 1,
                background: "#111",
                position: "relative",
                bottom: 6
              }}
            />
          ) : null}
          <div
            style={{
              color: option.link ? "#4001C5" : undefined,
              fontWeight: option.link ? 700 : undefined,
              width: option.link ? "100%" : undefined
            }}
            value={option.name}
          >
            {option.loading ? <Loading thickness={7.8} size={22} /> : null}
            {option.link || option.name}
          </div>
        </div>
      )}
    />
  );
};

export default withRouter(VehicleTrackingTypeSelect);
