import React from "react";
import Typography from "components_v2/Typography/Typography";
import { makeStyles } from "@material-ui/core";
import AssignDriver from "components_v2/Bookings/AssignDriver/AssignDriver";
import UpdateDriverSchedule from "gql/DriverSchedules/Update";
import { useMutation } from "@apollo/react-hooks";
import Button from "components_v2/Button/Button";
import _ from "lodash";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: (props) => !props.isActionButton && "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})

const AssignDriverOption = ({ isActionButton, data, setUpdatingBookings }) => {

  const [
    updateDriverSchedule,
  ] = useMutation(UpdateDriverSchedule);


  const classes = useStyles({ isActionButton });
  return <AssignDriver
    singleSelection
    noResults
    loadDataOnRender={false}
    projectId={data?.event?.id}
    supplierId={data?.supplier?.id || undefined}
    passengerScheduleStart={data.start}
    passengerScheduleType={data.type}
    requiredVehicleClass={data.requiredVehicleClass}
    values={{
      drivers: [
        {
          driver: data?.driver?.id || null,
          vehicle: data?.vehicle?.id || null,
        },
      ]
    }}
    incomingItems={[{
      driver: data?.driver?.id || null,
      vehicle: data?.vehicle?.id || null,
    }]}
    customButton={toggleModal => (
      <div
        id={`assign-driver-action-button-${data.id}`}
        className={classes.item}
        onClick={() => {
          toggleModal()
        }}>
        <Typography
          customVariant="bodySmallRegular"
        >
          {isActionButton ?
            <Button> Assign Driver</Button>
            : <>
              {data?.driver ? 'Re-assign Driver' :
                'Assign Driver'}
            </>}
        </Typography>
      </div>
    )}
    customHandleSelection={(selectedItems, setSelectedItems) => row => {
      let newItems = [...selectedItems];
      const selectedDriver = _.find(selectedItems, {
        driver: row?.driverInformation?.driver?.id,
        vehicle: row?.driverInformation?.vehicle?.id
      });

      if (selectedDriver) {
        newItems = selectedItems.filter(
          x =>
            x.driver !== selectedDriver.driver &&
            x.vehicle !== selectedDriver.vehicle
        );
      } else
        newItems.push({
          driver: row?.driverInformation?.driver?.id,
          vehicle: row?.driverInformation?.vehicle?.id
        });

      setSelectedItems([{
        driver: row?.driverInformation?.driver?.id,
        vehicle: row?.driverInformation?.vehicle?.id
      }]);
    }}
    customSelectionValue={(selectedItems, row) => {
      const selectedDriver = _.find(selectedItems, {
        driver: row?.driverInformation?.driver?.id,
        vehicle: row?.driverInformation?.vehicle?.id
      });

      if (selectedDriver) return true;
      return false;
    }}
    onDone={async (vehicles) => {
      if (vehicles?.length < 1) return;
      
      setUpdatingBookings([data.id]);

      const driver =
        vehicles[0].driverInformation.driver.id;
      const vehicle =
        vehicles[0].driverInformation?.vehicle
          ?.id || vehicles[0].vehicleId;
      // Update driver schedule
      await updateDriverSchedule({
        variables: {
          id: { id: data.id },
          data: {
            driver,
            vehicle,
          },
        },
      }
      );

      setUpdatingBookings([]);

      // Refetch bookings
      //await refetchPassengerSchedules();
    }}
  />
}

export default AssignDriverOption