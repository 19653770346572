import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import { injectProps } from 'utils/react';
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from '../../App.helper';

function getModalWidth({ size }) {
  if (size === 'small') return 400;
  if (size === 'medium') return '85%';
  if (size === 'full') return '100%';
}

function getModalHeight({ size }) {
  if (size === 'medium') return '85%';
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: (props) => getModalWidth(props),
    height: (props) => getModalHeight(props),
    maxWidth: (props) => (props.size === 'full' ? '100%' : 'initial'),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      width: () => '100%',
      height: () => '100%',
      maxWidth: () => '100%',
      borderRadius: 0,
    },
  },
}));

export default function SimpleModal({
  children,
  onModalClick,
  size = 'small',
  disableOutline,
  onOpenCallback,
  onCloseCallback,
  onCancelCallback,
  pageView,
  fullContainerWidth = false,
}) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const classes = useStyles({ size });
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);

  const toggleModal = () => {
    if (open) {
      setOpen(false);
      if (onCloseCallback) onCloseCallback();
    } else {
      setOpen(true);
      if (onOpenCallback) onOpenCallback();
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (onCloseCallback) onCloseCallback();
  };

  const handleCancel = () => {
    setOpen(false);
    if (onCloseCallback) onCloseCallback();
    if (onCancelCallback) onCancelCallback();
  }

  // Added this to pass the handleClose function to child component
  // in order to implement a close button within the child
  const child = injectProps(children, { handleClose, handleCancel });

  const baseModalStyle = {
    top: isMobileView ? '0' : `50%`,
    left: isMobileView ? '0' : `50%`,
    transform: isMobileView ? 'none' : `translate(-50%, -50%)`,
    right: isMobileView && '0',
    bottom: isMobileView && '0'
  };

  const modalStyle = disableOutline
    ? { ...baseModalStyle, outline: 'none' }
    : baseModalStyle;

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {child}
    </div>
  );

  return (
    <div style={{ width: fullContainerWidth ? '100%' : undefined }}>
      {onModalClick && onModalClick(toggleModal)}

      <Modal
        open={pageView || open}
        onClose={handleClose}
        onCancel={handleCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

SimpleModal.propTypes = {
  children: PropTypes.element,
  onModalClick: PropTypes.func,
  size: PropTypes.string,
  onOpenCallback: PropTypes.func,
  onCloseCallback: PropTypes.func,
};
