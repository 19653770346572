import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Button from 'components_v2/Button/Button';
import { createLocationWithState } from 'utils/routerHelpers';
import TimeIndicator from '../TimeIndicator/TimeIndicator';
import DayViewAppointment from '../DayViewAppointment/DayViewAppointment';
import { getAppointmentsForCell } from '../helpers';
import useStyles from './DayViewTimeTableCell.style';
import withFormState from "containers/Wrappers/withFormState";

const DayViewTimeTableCell = ({
  formatted,
  startDate,
  endDate,
  history,
  match,
  location,
  eventTimeZone,
  onDelete,
  setFormState,
  ...restProps
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const { projectId } = match.params;

  const cellAppointmentData = getAppointmentsForCell(
    formatted,
    startDate,
    endDate
  );

  const firstAppointment = cellAppointmentData[0];

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const cellAppointments =
    cellAppointmentData.length > 1 ? (
      <>
        <DayViewAppointment     onDelete={onDelete} history={history}appointment={firstAppointment} />
        <Box
          onClick={toggleExpanded}
          className={classes.otherAppointments}
        >{`+${cellAppointmentData.length - 1}...`}</Box>
      </>
    ) : (
      <>
        {firstAppointment && (
          <DayViewAppointment onDelete={onDelete}history={history}appointment={firstAppointment} />
        )}
      </>
    );

  const allAppointments = cellAppointmentData.map((appointment) => (
    <DayViewAppointment  onDelete={onDelete}history={history}appointment={appointment} />
  ));

  const viewMoreDropdown = (
    <Box className={classes.dropdown}>
      {allAppointments}
      <Button className={classes.viewLessButton} onClick={toggleExpanded}>
        View Less
      </Button>
    </Box>
  );

  return (
    <DayView.TimeTableCell className={classes.timeTableCell} {...restProps}>
      <TimeIndicator
        startDate={startDate}
        endDate={endDate}
        eventTimeZone={eventTimeZone}
      />
      <Box>
        {isExpanded && viewMoreDropdown}

        <Box className={classes.timeTableCellContent}>{cellAppointments}</Box>
        <Button
          onClick={() => {
            const { pathname, state } = createLocationWithState(
              `/project/${projectId}/booking/new`,
              location,
              {
                arrivalTime: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                reocurringDates: [moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss")],
                view: 'day',
              },
              null,
              true
            );

            setFormState(state);
            history.replace({ pathname, state: { hasState: true } });
          }}
          variant="contained"
          secondary
        >
          Add Booking
        </Button>
      </Box>
    </DayView.TimeTableCell>
  );
};

export default withFormState(withRouter(DayViewTimeTableCell));
