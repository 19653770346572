import React from "react";
import DynamicList from "components_v2/List/DynamicList";
import PropTypes from "prop-types";
import { getColumns, getMobileMenuItems } from "./ListOptions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../../../App.helper";
import DeleteConfirmationModal from "../../../Modal/DeleteConfirmationModal/DeleteConfirmationModal";
import _ from "lodash";
const VehicleClassesComponent = ({
  data,
  loading,
  createVehicleClass,
  updateVehicleClass,
  refetch,
  sort,
  setSort,
  setFilterKey,
  projectId,
  setDeleteConfirmationModalData,
  deleteVehicleClassesRecord,
  deleteConfirmationModalData,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  return (
    <>
      <DeleteConfirmationModal
        deleteConfirmationModalData={deleteConfirmationModalData}
        onClickCancel={() => {
          setDeleteConfirmationModalData(null);
        }}
        onClickConfirm={() => {
          deleteVehicleClassesRecord();
        }}
      />
      <DynamicList
        filterActions={[
          {
            type: "text",
            onChange: (val) => {
              setFilterKey(val)
            },
            label: "Search",
          },
        ]}
        sortActions={{
          currentSort: sort,
          onChangeSort: setSort
        }}
        idSuffix={'_vehicle_classes'}
        isMobileView={isMobileView}
        showHeader
        noResultsText="No vehicle classes"
        loading={loading}
        data={data}
        title="Vehicle Classes"
        numRows={data.length}
        getMobileMenuItems={(row) =>
          getMobileMenuItems({ row, setDeleteConfirmationModalData })
        }
        columns={getColumns(isMobileView)}
        actions={{
          edit: {
            onSave: async ({ id, values }) => {
              await updateVehicleClass({
                variables: {
                  id: { id },
                  data: {
                    ...values,
                    vehicles: values?.vehicles?.length ? _.map(values.vehicles, 'id:') : []
                    // icon: values.icon,
                  },
                },
              });

              await refetch();
            },
          },
          create: {
            onSave: async ({ values }) => {
              await createVehicleClass({
                variables: {
                  data: {
                    ...values,
                    // icon: values.icon,
                    event: projectId,
                  },
                },
              });

              await refetch();
            },
          },
          delete: {
            onClick: async (row) => {
              setDeleteConfirmationModalData({
                recordName: row.name,
                recordId: row.id,
                confirmationType: "delete",
                isNotDeleteAllowed: row.vehicles.length > 0,
                deleteNotAllowedReason:
                  "This vehicle class cannot be deleted because it is being used by the vehicles.",
              });
            },
          },
        }}
      />
    </>
  );
};
export default VehicleClassesComponent;
VehicleClassesComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
};
