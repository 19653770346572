import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import _ from "lodash";

import Button from 'components_v2/Button/Button';
import FullScreenPopover from 'components_v2/FullScreenPopover/FullScreenPopover';
import Loading from 'components_v2/Loading/CircularProgress';
import CreateSupplier from 'gql/Suppliers/CreateSupplier';
import UpdateSupplier from 'gql/Suppliers/UpdateSupplier';
import SuccessCard from 'components_v2/Cards/SuccessCard/SuccessCard';
import AddSupplierForm from './AddSupplierForm';
import useStyles from './AddSupplier.style';
import { validationSchema } from './helpers';
import { deepCopy } from '../../../../utils/helpers';
import { getContinueBodyAction } from "utils/formHelpers";
import withFormState from "containers/Wrappers/withFormState";

const initialInputValues = {
  name: '',
  email: '',
};

const AddSupplier = ({ history, location, data, refetchSupplier, getPreviousFormState, setReturnValues }) => {
  const classes = useStyles();
  const [supplierId, setSupplierId] = useState(null);
  const previousFormState = getPreviousFormState(location);

  const isUpdate = !!data;
  const initialValues = isUpdate
    ? { ...initialInputValues, ...data }
    : initialInputValues;
  const successHeader = isUpdate ? 'Supplier Updated!' : 'Supplier Created!';
  const successDescription = isUpdate
    ? 'Congratulations, you have updated a supplier.'
    : 'Congratulations, a new supplier has been created.';
  const title = isUpdate
    ? 'Update an existing supplier'
    : 'Create a new supplier';

  const [
    createSupplier,
    { loading: createMutationLoading },
  ] = useMutation(CreateSupplier);

  const [
    updateSupplier,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateSupplier);

  const isLoading = createMutationLoading || updateMutationLoading;

  const handleSubmit = async (values, actions) => {
    const localValues = deepCopy(values)
    let res;

    if (isUpdate) {
      const id = localValues.id;
      delete localValues.id;
      delete localValues.__typename;

      res = await updateSupplier({
        variables: {
          id: { id },
          data: localValues,
        },
      });
    } else
      res = await createSupplier({
        variables: {
          data: localValues,
        },
      });

    if (res?.data?.createSupplier) {

      const supplierData = { ...res.data.createSupplier.supplier, selectedVehicles: [] };
      setSupplierId(supplierData.id);
      if (!_.isEmpty(previousFormState)) setReturnValues(supplierData, previousFormState);

      actions.resetForm({});
    } else if (res?.data?.updateSupplier) {
      setSupplierId(res.data.updateSupplier.supplier.id);
      await refetchSupplier();
    }
    actions.setSubmitting(false);
  };

  let mainBodyActions;

  if (!_.isEmpty(previousFormState)) mainBodyActions = [
    getContinueBodyAction(history, previousFormState),
  ];

  else mainBodyActions = [
    {
      text: 'Add Additional Suppliers',
      onClick: () => {
        if (isUpdate) history.push(`/supplier/new`);
        setSupplierId(null);
      },
    },
  ];

const mainAction = previousFormState ?
  null :
  {
    text: 'See Supplier',
    onClick: () => {
      history.push(`/supplier/${supplierId}`);
    },
  };

const secondaryAction = previousFormState ?
  null :
  {
    text: 'Customize Supplier',
    onClick: async () => {
      setSupplierId(null);
      if (!isUpdate) history.push(`/supplier/${supplierId}/edit`);
    },
  };

  if (supplierId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        onCloseCallback={() => {
          history.goBack();
        }}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={mainAction}
          secondaryAction={secondaryAction}
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={title}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save supplier
                </Button>
              }
              onCloseCallback={() => {
                history.goBack();
              }}
              pageView
            >
              {AddSupplierForm({ ...props, classes })}
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddSupplier));
