import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import ActionPaddingWrapper from "./ActionPaddingWrapper";
import Tooltip from 'components_v2/Tooltip/Tooltip';
import { Delete } from '../Icons/Icons';
import { deleteActionButtonSelector, eventStopPropagation } from '../../App.helper';


const DeleteAction = (props) => {
  const {
    actions,
    row,
    moduleTable,
    newMode,
    newRow,
    setDynamicListState,
  } = props;

  if (newMode && newRow) return null;

  let disabled = false;
  let hidden = false;

  if (actions?.delete?.hidden && typeof actions?.delete?.hidden === "function") hidden = actions?.delete?.hidden(row);

  if (
    actions &&
    actions.delete &&
    actions.delete.disabled &&
    typeof actions.delete.disabled === 'function'
  ) {
    disabled = actions.delete.disabled(row);
  }

  return (
    <>
      {actions && actions.delete && actions.delete.onClick && !hidden && (
        <>
          <ActionPaddingWrapper moduleTable={moduleTable}>
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                id={`${deleteActionButtonSelector}${row.id}`}
                disabled={disabled}
                onClick={async (e) => {
                  eventStopPropagation({ event: e })

                  setDynamicListState({ loading: true });

                  await actions.delete.onClick(row);

                  setDynamicListState({ loading: false });
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </ActionPaddingWrapper>
        </>
      )}
    </>
  );
};

export default DeleteAction;