import { makeStyles } from '@material-ui/core';

export const useCustomStyle = makeStyles(() => ({
  accessRightsWrapper: {
    '& .MuiTableRow-root': {
      '& .MuiFormControlLabel-root': {
        marginRight: '0px',
        marginLeft: '0px',
      },
    },
  },
  saveWrapper: {
    margin: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));
