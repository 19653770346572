import gql from 'graphql-tag';

export default gql`
  mutation UpdateUser($id: InputID, $data: editUserInput) {
    updateUser(input: { where: $id, data: $data }) {
      user {
        id
      }
    }
  }
`;
