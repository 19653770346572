import gql from "graphql-tag";

export default gql`
  mutation CreateDriverongoingtrackings($data: DriverongoingtrackingInput) {
    createDriverongoingtracking(input: { data: $data }) {
      driverongoingtracking {
        id
      }
    }
  }
`;
