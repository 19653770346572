import React from "react";
import TourTooltipIntro from "components_v2/TourTooltip/TourTooltipIntro";
import TourTooltipDescription from "components_v2/TourTooltip/TourTooltipDescription";
import _ from "lodash";

export const tourConfig = isMobileView => {
  const totalNumberOfSteps = (isMobileView && 9) || 12;

  let steps = [
    {
      content: <TourTooltipIntro />,
      locale: {
        skip: "No thank you",
        next: "Yes, show me"
      },
      placement: "center",
      target: "body"
    },

    {
      content: (
        <TourTooltipDescription
          content="Here you will find an overview of the currently running projects "
          index={1}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 20,
      target: isMobileView ? "#mobile-dashboard-menu" : "#dashboard-menu",
      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    },
    {
      content: (
        <TourTooltipDescription
          content="Quickly look at all the current projects and how many active issues are in each."
          index={2}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 20,
      target: isMobileView
        ? ".hd-project-selector-wrapper"
        : "#current-projects-carousel",
      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    }
  ];

  if (!isMobileView) {
    steps = _.concat(
      steps,
      {
        content: (
          <TourTooltipDescription
            content="You can also look at all the active bookings, and learn about any important alerts at a glance"
            index={3}
            totalNumberOfSteps={totalNumberOfSteps}
          />
        ),
        floaterProps: {
          disableAnimation: false
        },
        spotlightPadding: 20,
        target: ".alert-tag",
        locale: {
          skip: "Skip",
          back: "Back",
          next: "Next",
          last: "Done"
        }
      },
      {
        content: (
          <TourTooltipDescription
            content="The system will give you a suggestion on how to solve the problem. You can also choose other actions in the 3 dots ( ... ) on the right."
            index={4}
            totalNumberOfSteps={totalNumberOfSteps}
          />
        ),
        floaterProps: {
          disableAnimation: true
        },
        spotlightPadding: 20,
        target: ".dynamic-list-item .MuiButton-text",

        locale: {
          skip: "Skip",
          back: "Back",
          next: "Next",
          last: "Done"
        }
      },
      {
        content: (
          <TourTooltipDescription
            content="Click on 'View project' to enter and explore the project page."
            index={5}
            totalNumberOfSteps={totalNumberOfSteps}
          />
        ),
        floaterProps: {
          disableAnimation: true
        },

        target: ".MuiPaper-root.MuiCard-root.MuiPaper-rounded:nth-child(1)",

        locale: {
          skip: "Skip",
          back: "Back",
          next: "Next",
          last: "Done"
        }
      }
    );
  }

  if (isMobileView) {
    steps = _.concat(steps, {
      content: (
        <TourTooltipDescription
          content="You can also look at all the active bookings"
          index={3}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: false
      },
      spotlightPadding: 20,
      target: ".dynamic-list-item",
      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    });
  }

  steps = _.concat(steps, {
    content: (
      <TourTooltipDescription
        content="Now you can see the active bookings and explore all details, focusing  for this event."
        index={(isMobileView && 4) || 6}
        totalNumberOfSteps={totalNumberOfSteps}
      />
    ),
    floaterProps: {
      disableAnimation: true
    },

    spotlightPadding: 20,
    target: "#booking-component",
    locale: {
      skip: "Skip",
      back: "Back",
      next: "Next",
      last: "Done"
    }
  });

  if (!isMobileView) {
    steps = _.concat(steps, {
      content: (
        <TourTooltipDescription
          content="Here you can see that there’s been an unresolved alert. You can click to get the details of what happened."
          index={7}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: false
      },
      spotlightPadding: 20,
      target: ".alert-tag",
      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    });
  }

  steps = _.concat(steps, {
    content: (
      <TourTooltipDescription
        content="You can see all the details of the ride, and also explore every milestone in the timeline."
        index={(isMobileView && 5) || 8}
        totalNumberOfSteps={totalNumberOfSteps}
      />
    ),
    floaterProps: {
      disableAnimation: true
    },
    spotlightPadding: 20,
    target: ".MuiStepper-horizontal",

    locale: {
      skip: "Skip",
      back: "Back",
      next: "Next",
      last: "Done"
    }
  });

  steps = _.concat(
    steps,
    {
      content: (
        <TourTooltipDescription
          content="The map will show you an overview of the cars with alerts and the cars available nearby."
          index={(isMobileView && 6) || 9}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 20,
      target: "#booking-component",

      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    },
    {
      content: (
        <TourTooltipDescription
          content="You can also click on the cars to view the booking details of each."
          index={(isMobileView && 7) || 10}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 20,
      target: ".active-marker",

      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    },
    {
      content: (
        <TourTooltipDescription
          content="And from here, you can also choose from a suggested action and more."
          index={(isMobileView && 8) || 11}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 20,
      target: ".active-driver-popup",

      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    },
    {
      content: (
        <TourTooltipDescription
          content="You can also click on the project title and select other active events to look at."
          index={(isMobileView && 9) || 12}
          totalNumberOfSteps={totalNumberOfSteps}
        />
      ),
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 20,
      target: "#project-selector",

      locale: {
        skip: "Skip",
        back: "Back",
        next: "Next",
        last: "Done"
      }
    }
  );
  /*
  steps = _.concat(steps, {
    content: (
      <TourTooltipDescription
        content="Create your first project. Use the floating (+) button to begin"
        index={(isMobileView && 10) || 13}
        totalNumberOfSteps={totalNumberOfSteps}
      />
    ),
    floaterProps: {
      disableAnimation: false,
    },
    spotlightPadding: 20,
    target: '#fab',
    locale: {
      skip: 'Skip',
      back: 'Back',
      next: 'Next',
      last: 'Done',
    },
  });*/

  return steps;
};
