import { ApolloProvider } from "@apollo/react-hooks";
import {
  MuiThemeProvider,
  withStyles,
  useTheme
} from "@material-ui/core/styles";

import MainDashboard from "components_v2/Views/Internal/Dashboard/Dashboard";
import HomeDashboard from "components_v2/Views/Internal/HomeDashboard/HomeDashboard";
import RateDriver from "components_v2/Views/Public/RateDriver/RateDriver.View";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import withUserRole from "components_v2/Wrappers/withUserRole";
import { PubNubProvider } from "pubnub-react";
import React, { useState, useEffect } from "react";
import JssProvider from "react-jss/lib/JssProvider";
import { Route, Router, Switch, Redirect, withRouter } from "react-router-dom";
import TourGuide from "components_v2/TourGuide/TourGuide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { EVENTS, ACTIONS } from "react-joyride";
import { tourConfig } from "./App.tour";
import History from "./components_v2/History/History";
import PubNubContainer from "./components_v2/Other/PubNubContainer";
import ErrorBoundary from "./components_v2/Views/Error/ErrorBoundary";
import Locations from "./components_v2/Locations/Locations.View";
import ForgotPassword from "./components_v2/Views/Public/Login/ForgotPassword";
import Login from "./components_v2/Views/Public/Login/Login";
import ResetPassword from "./components_v2/Views/Public/Login/ResetPassword";
import SetupPassword from "./components_v2/Views/Public/Login/SetupPassword";
import Playground from "./components_v2/Views/Playground";
import AddLocation from "./components_v2/Views/Internal/AddLocation/AddLocation.Container";
import AddPassenger from "./components_v2/Views/Internal/AddPassenger/AddPassenger.Container";
import AddSupplier from "./components_v2/Views/Internal/AddSupplier/AddSupplier.Container";
import AddUser from "./components_v2/Views/Internal/AddUser/AddUser.Container";
import AddBooking from "./components_v2/Bookings/AddBooking.Container";
import NotFoundView from "./components_v2/Views/Error/NotFound/NotFound";
import AddDriver from "./components_v2/Views/Internal/AddDriver/AddDriver.Container";
import AddProject from "./components_v2/Views/Internal/AddProject/AddProject.Container";
import AddVehicle from "./components_v2/Views/Internal/AddVehicle/AddVehicle.Container";
import AddTracking from "./components_v2/Views/Internal/AddTracking/AddTracking.Container";
import AddClient from "./components_v2/Views/Internal/AddClient/AddClient.Container";
import ClientsView from "./components_v2/Views/Internal/Clients/Clients";
import DriversView from "./components_v2/Views/Internal/Drivers/Drivers";
import DispatchView from "./components_v2/Views/Internal/Dispatch/Dispatch.Container";
import DriverView from "./components_v2/Views/Internal/Driver/Driver.Container";
import HelpView from "./components_v2/Views/Internal/Help/Help";
import ProjectsView from "./components_v2/Views/Internal/Projects/Projects";
import SupplierView from "./components_v2/Views/Internal/Supplier/Supplier.Container";
import SuppliersView from "./components_v2/Views/Internal/Suppliers/Suppliers.Container";
import UsersView from "./components_v2/Views/Internal/Users/Users.Container";
import VehicleView from "./components_v2/Views/Internal/Vehicle/Vehicle.Container";
import TrackRideView from "./components_v2/Views/Public/TrackRide/TrackRide.Container";
import LegacyApolloProvider from "./containers/ApolloProvider";
import auth from "./utils/auth";
import AccessRightsContainer from "./components_v2/AccessRights/AccessRights.Container";
import ScheduleContainer from "./components_v2/Views/Internal/Schedule/ScheduleContainer";
import theme from "./theme";
import Favorites from "./components_v2/Favorites/Favorites.container ";
import {
  getIsTutorialHomeDashboardPage,
  getIsTutorialPage,
  getIsTutorialProjectDashboardPage,
  mobileViewBreakpoint
} from "./App.helper";
import _ from "lodash";
import { useRouteData, RouteDataProvider } from "./context/route-data.context";
import apolloClient from "./apollo/client";
import { PubNubContext, pubnub } from "./pubnub";


const styles = () => ({
  root: {
    display: "flex"
  }
});

const hist = createBrowserHistory();

const PersistentDrawerLeft = props => {
  const usedTheme = useTheme();
  const isMobileView = useMediaQuery(
    usedTheme.breakpoints.down(mobileViewBreakpoint)
  );

  const [tourGuide, setTourGuide] = useState({
    run: false,
    steps: tourConfig(isMobileView),
    stepIndex: 0
  });

  const [tutorialPage, setTutorialPage] = useState(getIsTutorialPage(hist));

  useEffect(() => {
    if (tutorialPage) {
      setTourGuide({
        ...tourGuide,
        run: true,
        steps: tourConfig(isMobileView)
      });
    } else {
      setTourGuide({
        ...tourGuide,
        run: false,
        steps: tourConfig(isMobileView)
      });
    }
  }, [isMobileView, tutorialPage]);

  const handleTourGuideCallback = data => {
    const { action, index, type, lifecycle } = data;

    // If the element is not found then the user has directly landed somewhere wrongly, so push him back to help page
    if (EVENTS.TARGET_NOT_FOUND === type && index !== 8) {
      hist.push("/help");
      setTourGuide({
        ...tourGuide,
        run: false,
        stepIndex: 0
      });
    }

    // If the user skips of closes in the middle of the tutorial, move him back to the help page
    if (action === ACTIONS.SKIP || action === ACTIONS.CLOSE) {
      hist.push("/");
      setTourGuide({
        ...tourGuide,
        run: false,
        stepIndex: 0
      });
    }

    // If the user is in home dashboard page, then do the following:
    if (getIsTutorialHomeDashboardPage(hist)) {
      // If the user moves to home dashboard from project dashboard, then point to the last step
      if (!tourGuide.run && index === 13) {
        setTourGuide({
          ...tourGuide,
          run: true,
          stepIndex: 13
        });
      }

      // If the user clicks back from step 13, then move him to project dashboard
      if (
        action === ACTIONS.PREV &&
        lifecycle === "complete" &&
        ((index === 13 && !isMobileView) || (index === 10 && isMobileView))
      ) {
        hist.push("/help/project/5f270baecc54999de0a9f579");
      }

      // If the user reaches step 13, then move him to project creation
      if (
        action === ACTIONS.NEXT &&
        lifecycle === "complete" &&
        ((!isMobileView && index === 12) || (isMobileView && index === 10))
      ) {
        hist.push("/project/new");
      }

      // If the user reaches step 5, then pause the tour and move him to project dashboard
      if (
        action === ACTIONS.NEXT &&
        lifecycle === "complete" &&
        ((!isMobileView && index === 5) || (isMobileView && index === 3))
      ) {
        setTourGuide({
          ...tourGuide,
          run: false
        });
        hist.push("/help/project/5f270baecc54999de0a9f579");
      }
    }

    // If the user is in project dashboard page, then do the following:
    if (getIsTutorialProjectDashboardPage(hist)) {
      // Reloading ( f5 ) on project page should redirect back to help page
      if (lifecycle === "init" && index === 0) {
        hist.push("/help");
        setTourGuide({
          ...tourGuide,
          run: false,
          stepIndex: 0
        });
      }

      // If the user lands from home dashboard to project dashboard, start the tour
      if (!tourGuide.run) {
        setTourGuide({
          ...tourGuide,
          run: true,
          stepIndex: 6
        });
      }

      // If the user clicks back from step 6, then move him back to home dashboard
      if (
        action === ACTIONS.PREV &&
        lifecycle === "complete" &&
        ((index === 6 && !isMobileView) || (index === 4 && isMobileView))
      ) {
        hist.push("/help/dashboard");
      }

      // If the user clicks back from step 9, then move him back to list view
      if (
        action === ACTIONS.PREV &&
        lifecycle === "complete" &&
        ((index === 9 && !isMobileView) || (index === 6 && isMobileView))
      ) {
        document
          .querySelector(".MuiToggleButtonGroup-grouped:nth-child(2)")
          .click();
      }

      // After highlighting the alert tag, click on it to expand the row for the next step
      if (
        action === ACTIONS.NEXT &&
        lifecycle === "complete" &&
        ((!isMobileView && index === 7) || (isMobileView && index === 4))
      ) {
        if (
          !document.querySelector(".MuiCollapse-container.MuiCollapse-entered")
        ) {
          if (isMobileView) {
            document
              .querySelector(
                ".dynamic-list-item > .rc-swipeout > .rc-swipeout-content > div"
              )
              .click();
          } else {
            document.querySelector(".dynamic-list-item > div").click();
          }
        }
      }

      // If the user clicks back from Step 13, then click on the map view button and active marker vehicle
      if (
        action === ACTIONS.UPDATE &&
        lifecycle === "tooltip" &&
        ((index === 12 && !isMobileView) || (index === 9 && isMobileView))
      ) {
        document.querySelector(".MuiToggleButtonGroup-grouped").click();
      }

      // The map view toggle button needs to be clicked in order to view the map and highlight it
      if (
        action === ACTIONS.NEXT &&
        lifecycle === "ready" &&
        ((index === 9 && !isMobileView) || (index === 6 && isMobileView))
      ) {
        document.querySelector(".MuiToggleButtonGroup-grouped").click();
      }

      // The active marker vehicle needs to be clicked in order to view the popup and highlight it
      if (
        action === ACTIONS.NEXT &&
        lifecycle === "complete" &&
        ((index === 9 && !isMobileView) || (index === 7 && isMobileView))
      ) {
        document.querySelector(".active-marker").click();
      }

      // When moving from step 12 to backwards, map view should be open
      if (
        action === ACTIONS.PREV &&
        lifecycle === "complete" &&
        ((index === 12 && !isMobileView) || (index === 9 && isMobileView))
      ) {
        document.querySelector(".active-marker").click();
      }

      // Once the user has completed the tutorial on the project dashboard page, he needs to move back to the home dashboard page
      if (
        action === ACTIONS.NEXT &&
        lifecycle === "complete" &&
        ((index === 12 && !isMobileView) || (index === 9 && isMobileView))
      ) {
        hist.push("/");
        setTourGuide({
          ...tourGuide,
          run: false,
          stepIndex: 0
        });
      }
    }

    // Control the tutorial manually after each click
    if (
      EVENTS.STEP_AFTER === type &&
      ACTIONS.CLOSE !== action &&
      ACTIONS.SKIP !== action &&
      !(
        action === ACTIONS.NEXT &&
        lifecycle === "complete" &&
        ((index === 12 && !isMobileView) || (index === 9 && isMobileView))
      ) &&
      !(EVENTS.TARGET_NOT_FOUND === type && index !== 8) &&
      !(lifecycle === "init" && index === 0)
    ) {
      if (
        // Backwards from Step 12 is a special case; Moving to map view and then click on the active marker's popup - Too slow - Race condition
        // So, we will move the user to highlight to Step 10 and come back from there.
        action === ACTIONS.PREV &&
        lifecycle === "complete" &&
        ((index === 12 && !isMobileView) || (index === 9 && isMobileView))
      ) {
        setTourGuide({
          ...tourGuide,
          run: true,
          stepIndex: (!isMobileView && 10) || 7
        });
      } else if (
        // Backwards from Step 9 is a special case; Moving to list view and then click on the record and highlight on the stepper component - Too slow - Race condition
        // So, we will move the user to highlight to Step 7 and come back from there.
        action === ACTIONS.PREV &&
        lifecycle === "complete" &&
        ((index === 9 && !isMobileView) || (index === 6 && isMobileView))
      ) {
        setTourGuide({
          ...tourGuide,
          run: true,
          stepIndex: (!isMobileView && 7) || 4
        });
      } else {
        setTourGuide({
          ...tourGuide,
          run: true,
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
        });
      }
    }
  };

  return (
    <PubNubProvider client={pubnub}>
      <ErrorBoundary>
        <JssProvider>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <ApolloProvider client={apolloClient}>
                <LegacyApolloProvider>
                  <PubNubContainer pubNubContext={PubNubContext}>
                    <TourGuide
                      steps={tourGuide.steps}
                      run={tourGuide.run}
                      callback={handleTourGuideCallback}
                      stepIndex={tourGuide.stepIndex}
                    />

                    <Switch>
                      <Route
                        path="/project/:projectId/client/new"
                        render={() => <AddClient />}
                      />

                      <Route
                        path="/project/:projectId/location/:locationId/edit"
                        render={() => <AddLocation />}
                      />
                      <Route
                        path="/project/:projectId/location/new"
                        render={() => <AddLocation />}
                      />

                      <Route
                        path="/location/new"
                        render={() => <AddLocation />}
                      />

                      <Route
                        path="/project/:projectId/edit/:step"
                        render={() => (
                          <AddProject key={hist.location.pathname} />
                        )}
                      />
                      <Route
                        path="/project/:projectId/new/:step"
                        render={() => (
                          <AddProject key={hist.location.pathname} />
                        )}
                      />
                      <Route
                        path="/project/new/:step"
                        render={() => (
                          <AddProject key={hist.location.pathname} />
                        )}
                      />
                      <Route
                        path="/project/:projectId/new"
                        render={() => <AddProject />}
                      />
                      <Route
                        path="/project/new"
                        render={() => <AddProject />}
                      />
                      <Route
                        path="/project/:projectId/edit"
                        render={() => <AddProject />}
                      />
                      <Route
                        exact
                        path="/login"
                        render={() => (
                          <Login pubNubContext={PubNubContext} history={hist} />
                        )}
                      />
                      <Route
                        exact
                        path="/forgot-password"
                        render={() => (
                          <ForgotPassword
                            pubNubContext={PubNubContext}
                            history={hist}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/change-password/:id"
                        render={() => (
                          <ResetPassword
                            pubNubContext={PubNubContext}
                            history={hist}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/setup-password/:id"
                        render={() => (
                          <SetupPassword
                            pubNubContext={PubNubContext}
                            history={hist}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/booking/:projectId"
                        render={() => (
                          <AddBooking
                            isPublic
                            noModuleOrDepartmentSection
                            noSupplierSection
                            noDocumentSection
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/drivers"
                        render={() => <DriversView />}
                      />
                      <Route
                        exact
                        render={() =>
                          !auth.getToken() &&
                            !_.includes(hist.location.pathname, "trackride") &&
                            !_.includes(
                              hist.location.pathname,
                              "leavefeedback"
                            ) ? (
                            <Redirect to="/login" />
                          ) : (
                            <Switch>
                              <Route
                                exact
                                path="/project/:projectId/vehicle/:vehicleId/edit"
                                render={() => <AddVehicle />}
                              />
                              <Route
                                exact
                                path="/help/dashboard"
                                render={() => <HomeDashboard isHelpTutorial />}
                              />

                              <Route
                                path="/help/project/:projectId/"
                                render={() => <MainDashboard isHelpTutorial />}
                              />

                              <Route
                                path="/help/project/:projectId/:selectedTab"
                                render={() => <MainDashboard isHelpTutorial />}
                              />

                              <Route
                                exact
                                path="/"
                                render={() => <HomeDashboard />}
                              />

                              <Route
                                exact
                                path="/opsDashboard"
                                render={() => <HomeDashboard isOps />}
                              />

                              <Route
                                path="/project/:projectId/booking/new/driver/new"
                                render={() => <AddDriver />}
                              />
                              <Route
                                path="/public/driver/new"
                                render={() => <AddDriver isPublicDriver />}
                              />
                              <Route
                                path="/public/driver/:userId/edit"
                                render={() => <AddDriver isPublicDriver />}
                              />
                              <Route
                                exact
                                path="/supplier/:supplierId/driver/:userId/edit"
                                render={() => <AddDriver />}
                              />
                              <Route
                                exact
                                path="/supplier/:supplierId/driver/new"
                                render={() => <AddDriver />}
                              />
                              <Route
                                exact
                                path="/project/:projectId/driver/:userId/edit"
                                render={() => <AddDriver />}
                              />
                              <Route
                                exact
                                path="/project/:projectId/driver/new"
                                render={() => <AddDriver />}
                              />
                              <Route
                                path="/driver/:driverId/tracking/new"
                                render={() => <AddTracking />}
                              />
                              <Route
                                path="/driver/:driverId/tracking/:trackingId/edit"
                                render={() => <AddTracking />}
                              />
                              <Route
                                path="/driver/:driverId/:selectedTab"
                                render={() => <DriverView />}
                              />
                              <Route
                                exact
                                path="/driver/:driverId"
                                render={() => <DriverView />}
                              />
                              <Route
                                exact
                                path="/schedule"
                                render={() => <ScheduleContainer />}
                              />
                              <Route
                                path="/playground"
                                render={() => (
                                  <Playground
                                    pubNubContext={PubNubContext}
                                    history={hist}
                                  />
                                )}
                              />
                              <Route
                                path="/user/:userId/edit/:step"
                                render={() => <AddUser key={hist.location.pathname} />}
                              />
                              <Route
                                path="/user/:userId/edit"
                                render={() => <AddUser key={hist.location.pathname} />}
                              />
                              <Route
                                path="/user/:userId/new/:step"
                                render={() => <AddUser key={hist.location.pathname} />}
                              />
                              <Route
                                path="/user/new/:step"
                                render={() => <AddUser key={hist.location.pathname} />}
                              />
                              <Route
                                path="/user/new"
                                render={() => <AddUser key={hist.location.pathname} />}
                              />
                              <Route
                                path="/users"
                                render={() => <UsersView />}
                              />

                              <Route
                                path="/help"
                                render={() => (
                                  <HelpView isHelpTutorial history={hist} />
                                )}
                              />

                              <Route
                                path="/project/:projectId/passenger/:passengerId/edit"
                                render={() => <AddPassenger />}
                              />
                              <Route
                                path="/vehicle/:vehicleId/tracking/new"
                                render={() => <AddTracking />}
                              />
                              <Route
                                path="/vehicle/:vehicleId/tracking/:trackingId/edit"
                                render={() => <AddTracking />}
                              />

                              <Route
                                exact
                                path="/vehicle/new"
                                render={() => <AddVehicle />}
                              />
                              <Route
                                exact
                                path="/project/:projectId/vehicle/new"
                                render={() => <AddVehicle />}
                              />
                              <Route
                                exact
                                path="/supplier/:supplierId/vehicle/new"
                                render={() => <AddVehicle />}
                              />
                              <Route
                                exact
                                path="/supplier/:supplierId/vehicle/:vehicleId/edit"
                                render={() => <AddVehicle />}
                              />
                              <Route
                                path="/project/:projectId/passenger/new"
                                render={() => <AddPassenger />}
                              />
                              <Route
                                path="/project/:projectId/booking/new/passenger/new"
                                render={() => <AddPassenger />}
                              />

                              <Route
                                path="/project/:projectId/booking/new"
                                render={() => <AddBooking />}
                              />
                              <Route
                                path="/project/:projectId/booking/:bookingId/duplicate"
                                render={() => <AddBooking />}
                              />
                              <Route
                                path="/project/:projectId/booking/:bookingId/return"
                                render={() => <AddBooking />}
                              />
                              <Route
                                path="/project/:projectId/booking/:bookingId/edit"
                                render={() => <AddBooking />}
                              />
                              <Route
                                path="/project/:projectId/:selectedTab/:bookingId"
                                render={() => <MainDashboard />}
                              />
                              <Route
                                path="/project/:projectId/:selectedTab"
                                render={() => <MainDashboard />}
                              />

                              <Route
                                path="/project/:projectId/"
                                render={() => <MainDashboard />}
                              />
                              <Route
                                path="/locations"
                                render={() => <Locations />}
                              />

                              <Route
                                path="/projects/:selectedTab"
                                render={() => <ProjectsView />}
                              />
                              <Route
                                path="/projects"
                                render={() => <ProjectsView />}
                              />
                              <Route
                                path="/suppliers"
                                render={() => <SuppliersView />}
                              />

                              <Route
                                exact
                                path="/supplier/:supplierId/vehicle/:vehicleId/driver/new"
                                render={() => <AddDriver />}
                              />
                              <Route
                                path="/supplier/:supplierId/edit"
                                render={() => <AddSupplier />}
                              />
                              <Route
                                path="/supplier/:supplierId/:selectedTab"
                                render={() => <SupplierView />}
                              />
                              <Route
                                path="/supplier/new"
                                render={() => <AddSupplier />}
                              />

                              <Route
                                path="/supplier/:supplierId/"
                                render={() => <SupplierView />}
                              />
                              <Route
                                path="/trackride/:rideId"
                                render={() => <TrackRideView />}
                              />
                              <Route
                                path="/client/new"
                                render={() => <AddClient />}
                              />
                              <Route
                                path="/client/:clientId/edit"
                                render={() => <AddClient />}
                              />
                              <Route
                                path="/clients"
                                render={() => <ClientsView />}
                              />
                              <Route
                                path="/leavefeedback/:id"
                                render={() => <RateDriver />}
                              />
                              <Route
                                path="/dispatch/:projectId"
                                render={() => <DispatchView />}
                              />
                              <Route
                                path="/dispatch"
                                render={() => <DispatchView />}
                              />

                              <Route
                                path="/vehicle/:vehicleId/:selectedTab"
                                render={() => <VehicleView />}
                              />
                              <Route
                                path="/vehicle/:vehicleId"
                                render={() => <VehicleView />}
                              />

                              <Route
                                path="/accessrights"
                                render={() => <AccessRightsContainer />}
                              />

                              <Route
                                path="/favorites/:selectedTab"
                                render={() => <Favorites />}
                              />
                              <Route
                                path="/favorites"
                                render={() => <Favorites />}
                              />
                              <Route
                                path="/history"
                                render={() => <History />}
                              />
                            </Switch>
                          )
                        }
                      />

                      <Route path="*" render={() => <NotFoundView />} />
                    </Switch>
                  </PubNubContainer>
                </LegacyApolloProvider>
              </ApolloProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </JssProvider>
      </ErrorBoundary>
    </PubNubProvider>
  );
};

const MainApolloWrapper = props => (
  <ApolloProvider client={apolloClient}>
    <LegacyApolloProvider>
      <Router history={hist}>
        <RouteDataProvider>
          <MainWrapper {...props} />
        </RouteDataProvider>
      </Router>
    </LegacyApolloProvider>
  </ApolloProvider>
);

const MainWrapper = withRouter(withUserRole("vehicles", props => {

  const { routeData, onChange } = useRouteData();

  useEffect(() => {
    if (props.location.pathname !== routeData.currentPath) onChange({
      currentPath: props.location.pathname,
      prevPath: routeData.currentPath,
    });

  }, [props.location.pathname])


  return (
    <PersistentDrawerLeft {...props} />
  );
}));

export default withStyles(styles, { withTheme: true })(MainApolloWrapper);
