/* eslint-disable react/display-name */
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Grow from "@material-ui/core/Grow";
import StyledMenuItem from "components_v2/Layout/StyledMenuItem";
import { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import GetAllByUser from "gql/Events/GetAllByUser";
import auth from "utils/auth";
import { getUnresolvedAlerts } from "components_v2/Views/Internal/HomeDashboard/HomeDashboard.helper";
import useChat from "hooks/useChat";

import {
  MenuVs,
  Folder,
  Clients,
  Car,
  Category1,
  UserBig,
  Help,
  Messages,
  Notifications,
  Goal
} from "../../Icons/Icons";

import LogoPart1 from "../../../static/logo_part_1.png";
import LogoPart2 from "../../../static/logo_part_2.png";
import { mockData } from "../../Views/Internal/HomeDashboard/HomeDashboard.mock";
import { mobileViewBreakpoint } from "../../../App.helper";
import Img from "../../Img/Img";

const drawerExpandedWidth = 180;
const drawerCollapsedWidth = 90;
const useStyles = makeStyles(({ transitions, breakpoints }) => ({
  drawer: {
    position: "absolute",
    width: drawerExpandedWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerExpandedWidth,
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: drawerCollapsedWidth
  },
  drawerHidden: {
    width: "0 !important"
  },
  drawerPaperOpen: {
    width: drawerExpandedWidth,
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen
    }),
    paddingLeft: 0,
    borderRight: "0",
    background: "#122044",
    left: 0,
    overflow: "hidden",
    display: "block"
  },

  drawerPaperClose: {
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: drawerCollapsedWidth,
    paddingLeft: 0,
    borderRight: "0",
    background: "#122044",
    left: 0,
    overflow: "hidden",
    display: "block"
  },
  drawerPaperMobile: {
    width: drawerExpandedWidth,
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    }),
    paddingLeft: 0,
    borderRight: "0",
    background: "#122044",
    left: 0
  },
  footer: {
    width: "100%",
    borderTop: "2px solid rgba(255, 255, 255, 0.1)",
    padding: "20px 0",

    [breakpoints.down(mobileViewBreakpoint)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      padding: 0
    }
  },
  navContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90%",

    [breakpoints.down(mobileViewBreakpoint)]: {
      height: "unset",
      flex: 1
    }
  },
  LogoPart1: {
    height: "2rem"
  },
  LogoPart2: {
    height: "2rem",
    marginLeft: "5px"
  },
  header: {
    display: "flex",
    margin: "35px 30px"
  }
}));

const DesktopNav = React.memo(
  ({
    hidden,
    onOpen,
    onClose,
    drawerPaperClass,
    expanded,
    openRightSideBar,
    isHelpTutorial,
    isMobile,
    userInfo
  }) => {
    const [open, setOpen] = useState(false);
    const [localQueryData, setLocalQueryData] = useState({});

    const classes = useStyles();
    const onMouseOverHandler = event =>
      onOpen ? onOpen(event) : !open && setOpen(true);
    const onMouseLeaveHandler = event =>
      onClose ? onClose(event) : open && setOpen(false);

    useEffect(() => {
      setOpen(expanded);
    }, [expanded, hidden]);

    const theme = useTheme();
    const isMobileView = useMediaQuery(
      theme.breakpoints.down(mobileViewBreakpoint)
    );
    const { id } = auth.get("userInfo");

    let { data: queryData } = useQuery(GetAllByUser, {
      variables: { id: id },
      notifyOnNetworkStatusChange: true,
      skip: isHelpTutorial
    });
    useEffect(() => {
      setLocalQueryData(queryData);
    }, [queryData]);

    if (isHelpTutorial) {
      queryData = mockData;
    }

    const eventData = localQueryData?.user?.userEvents.map(userEvent => ({
      ...userEvent?.event,
      role: userEvent?.role
    }));

    const unresolvedAlerts = getUnresolvedAlerts(eventData);

    const notificationsCount1 = unresolvedAlerts?.length;
    // lazy query for updating schedules
    const [getNewAlerts, { data: lazyAlertsQueryData }] = useLazyQuery(
      GetAllByUser,
      {
        variables: { id: id },
        fetchPolicy: "no-cache",
        skip: isHelpTutorial
      }
    );

    useChat("alertUpdates", async () => {
      await getNewAlerts();
    });
    useChat("alertCreate", async () => {
      await getNewAlerts();
    });
    useEffect(() => {
      setLocalQueryData(lazyAlertsQueryData);
    }, [lazyAlertsQueryData]);
    return (
      <SwipeableDrawer
        anchor="right"
        open={!hidden}
        onClose={onClose || function() {}}
        onOpen={onOpen || function() {}}
        variant={!isMobile ? "permanent" : "temporary"}
        onMouseOver={onMouseOverHandler}
        onMouseLeave={onMouseLeaveHandler}
        // className={ clsx(classes.drawer,classes.drawerOpen)}
        // classes={{
        //   paper: clsx(drawerPaperClass,classes.drawerPaperOpen),
        // }}
        className={
          !isMobile
            ? clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            : classes.drawer
        }
        classes={
          !isMobile
            ? {
                paper: clsx(drawerPaperClass, {
                  [classes.drawerPaperOpen]: open,
                  [classes.drawerPaperClose]: !open,
                  [classes.drawerHidden]: hidden
                })
              }
            : { paper: clsx(drawerPaperClass, classes.drawerPaperMobile) }
        }
      >
        <header className={classes.header}>
          <Link to="/">
            <Img src={LogoPart1} alt="Motusone" className={classes.LogoPart1} />
            <Grow in={open} timeout={500}>
              <Img
                src={LogoPart2}
                alt="Motusone"
                className={classes.LogoPart2}
              />
            </Grow>
          </Link>
        </header>

        <aside className={classes.navContainer}>
          <List>
            {!isMobile && (
              <StyledMenuItem
                icon={MenuVs}
                open={open}
                label="Dashboard"
                route="/"
                id="dashboard-menu"
              />
            )}
            {!isMobile && userInfo.isSuperAdmin && (
              <StyledMenuItem
                icon={Folder}
                open={open}
                label="Projects"
                route="/projects"
              />
            )}

            {userInfo.isSuperAdmin && (
              <>
                <StyledMenuItem
                  icon={Clients}
                  open={open}
                  label="Clients"
                  route="/clients"
                />
                <StyledMenuItem
                  icon={Car}
                  open={open}
                  label="Suppliers"
                  route="/suppliers"
                />
              </>
            )}

            {!isMobile && userInfo.isSuperAdmin && (
              <StyledMenuItem
                icon={Category1}
                label="Dispatch"
                open={open}
                route="/dispatch"
              />
            )}

            {!isMobile && userInfo.isSuperAdmin && (
              <StyledMenuItem
                icon={UserBig}
                label="Users"
                open={open}
                route="/users"
              />
            )}

            {!isMobile && userInfo.isSuperAdmin && (
              <StyledMenuItem
                icon={Goal}
                label="Drivers"
                open={open}
                route="/drivers"
              />
            )}

            {isMobileView && (
              <>
                <StyledMenuItem
                  icon={Messages}
                  open={open}
                  label="Messages"
                  route="#"
                  counter={0}
                  onClick={() => openRightSideBar("messages")}
                />
                <StyledMenuItem
                  icon={Notifications}
                  open={open}
                  label="Alerts"
                  route="#"
                  counter={notificationsCount1}
                  onClick={() => openRightSideBar("alerts")}
                />
                <StyledMenuItem
                  icon={UserBig}
                  open={open}
                  label="My account"
                  route="#"
                  onClick={() => openRightSideBar("account")}
                />
              </>
            )}
          </List>

          <footer className={classes.footer}>
            <List>
              <StyledMenuItem
                icon={Help}
                open={open}
                label="Help"
                route="/help"
              />
            </List>
          </footer>
        </aside>
      </SwipeableDrawer>
    );
  }
);

export default withRouter(DesktopNav);
