import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import GetAll from "gql/Users/GetAll";
import SelectInput from "components_v2/Inputs/SelectInput/SelectInput";
import Loading from "components_v2/Loading/CircularProgress";
import { createLocationWithState } from "utils/routerHelpers";
import Typography from "components_v2/Typography/Typography";


const UserSelect = props => {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    values,
    noMargin,
    error,
    history,
    location,
  } = props;

  const { data, loading } = useQuery(GetAll, {
    fetchPolicy: "no-cache"
  });

  const options =
    !loading && data ?
    data.users
      .map(user => ({
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.fullName,
        email: user.email,
        phone: user.phone,
        company: user.company,
        role: user.role,
        id: user.id
      })) :
      [
        {
          loading: true,
          name: "",
        }
      ];

  const optionsWithAddNewLink = (options && [
    ...options,
    { link: true, name: "Add New" }
  ]) || [{ link: true, name: "Add New" }];

  return (
    <SelectInput
      name={name}
      label={label}
      noMargin={noMargin}
      placeholder={placeholder}
      filterOptions={
        createFilterOptions({
          stringify: option => option.name + option.email
        })
      }
      getOptionDisabled={(option) => option.loading}
      value={value || ""}
      onChange={e => {
        if (e?.target?.value?.link) {
          const locationWithState = createLocationWithState(
            `/user/new`,
            location,
            values,
            null,
            true
          );
          
          history.replace(locationWithState)
        } else onChange(e);
      }}
      options={optionsWithAddNewLink || []}
      isError={!!error}
      errorMessage={error}
      renderOption={option => (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {option.link ? (
            <div
              style={{
                width: "100%",
                height: 1,
                background: "#111",
                position: "relative",
                bottom: 6
              }}
            />
          ) : null}
          <div
            style={{
              color: option.link ? "#4001C5" : undefined,
              fontWeight: option.link ? 700 : undefined,
              width: option.link ? "100%" : undefined
            }}
            value={option.name}
          >
            {option.loading ? <Loading thickness={7.8} size={22} /> : null}
            {option.link ? option.name : <Typography customVariant="bodySmallBold">{option.name}</Typography> }
            <Typography customVariant="bodySmallRegular">{option.email}</Typography>
            <Typography customVariant="bodySmallRegular">{option.company}</Typography>

          </div>
        </div>
      )}
    />
  );
};

export default withRouter(UserSelect);
