import React from 'react';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import InputLabel from '../InputLabel/InputLabel';
import { useCustomStyles } from './TextAreaInput.styles';
import InputError from '../InputError/InputError';

const TextAreaInput = ({
  label,
  icon,
  placeholder,
  name,
  value,
  onChange,
  isDisabled,
  rows = 4,
  error,
}) => {
  const customClasses = useCustomStyles();

  return (
    <div className={customClasses.inputWrapper}>
      <InputLabel label={label} />
      <TextInput
        name={name}
        multiline
        rows={rows}
        isErrored={!!error}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
        icon={icon}
      />
      <InputError error={error} />
    </div>
  );
};

export default TextAreaInput;
