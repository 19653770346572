import { makeStyles } from '@material-ui/core/styles';

export const useCustomStyles = makeStyles(() => ({
  iconSelectorWrapper: {
    '& svg': {
      width: 32,
      height: 'auto',
    },
  },
  iconSelectorButton: {
    background: '#FFFFFF',
    border: '1px dashed #4001C5',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '4px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    width: 60,
    height: 60,
    letterSpacing: '0.3px',
    color: '#4001C5',
    cursor: 'pointer',
    textTransform: 'uppercase',

    '&:hover': {
      background: 'rgba(64, 1, 197, 0.05)',
      border: '1px solid #4001C5',
    },
  },
  valueSelected: {
    background: 'transparent',
    border: 'none',
    padding: '4px',

    '&:hover': {
      background: 'transparent',
      border: 'none',
    },
  },
  disabled: {
    visibility: 'hidden',
  },
  sortMenuRecords: {
    padding: 0,
    borderRadius: 0,

    '& .MuiList-root': {
      display: 'flex',
      padding: '4px !important',
      maxWidth: '296px',
      flexWrap: 'wrap',
      width: 'auto',

      '& > li': {
        margin: '4px',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        boxSizing: 'border-box',
        width: '64px',
        height: '64px',
        padding: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
}));
