import React from 'react';
import GetOne from 'gql/Drivers/GetOne.js';

import { withRouter } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import ViewDriverComponent from 'components_v2/Views/Internal/ViewDriver/ViewDriver.Component';

const ViewDriverContainer = ({ driverId  }) => {
  const { data, loading } = useQuery(GetOne, {
    variables: { id: driverId },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <ViewDriverComponent data={(data?.user) || {}} loading={loading} />
    </>
  );
};

export default withRouter(ViewDriverContainer);
