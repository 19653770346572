import gql from "graphql-tag";

export default gql`
  query GetAllPublicDrivers(
    $sort: String
    $pageSize: Int
    $startIndex: Int
    $filterKey: String
  ) {
    usersConnection(
      where: {
        role: 4
        isPublicDriver: true
        _or: [
          { firstName_contains: $filterKey }
          { lastName_contains: $filterKey }
          { phone_contains: $filterKey }
        ]
      }
      limit: $pageSize
      start: $startIndex
      sort: $sort
    ) {
      aggregate {
        count
      }
      values {
        id
        email
        firstName
        lastName
        fullName
        phone
        confirmed
        company
        identifier
        username
        notes
        profileImageThumbnail
        passengerPriority
        role {
          id
          name
        }
        shift
        averageDriverRating
        driveratings {
          id
          rating
        }
        driverschedules {
          id
          vehicle {
            id
            vehicleNumber
            make
            model
            year
            maxPassengers
            plateNumber
          }
          driver {
            firstName
            lastName
            phone
            email
            identifier
          }
          ridestatuses {
            id
            status
          }
          passengerschedule {
            id
            start
            stop
            type
            vehicleClass
            flightNumber
            numberOfVehicles
            bookingId
            type
            origin
            driverschedules {
              id
              vehicle {
                id
                vehicleNumber
                make
                model
                year
                maxPassengers
                plateNumber
              }
              driver {
                firstName
                lastName
                phone
                email
                identifier
              }
            }
            requiredVehicleClass {
              id
              name
            }
            passenger {
              id
              firstName
              lastName
              email
              phone
              event {
                id
              }
            }
            from {
              id
              name
              address
              lat
              long
            }
            to {
              id
              name
              address
              lat
              long
            }
          }
        }
        assignedVehicles {
          id
          notes
          created_at
          start
          stop
          wholeEvent
          vehicle {
            id
            type
            vehicleNumber
            driverschedules {
              id
              note
              passengerschedule {
                id
                start
                stop
                origin
                bookingId
                type
              }
              ridestatuses {
                id
                status
              }
            }
          }
        }
      }
    }
  }
`;
