import moment from "moment";
import React from "react";
import _ from "lodash";
import request from "./request";

export const getVehicleStatus = (driver, currentDayMoment) => {
  // gets the status of a ride for a driver and their vehicle
  if (!driver || !driver.driverschedules) return "No Vehicle";

  const driverSchedulesOverlapping = [];

  return driver.driverschedules.map(driverSchedule => {
    const startTime = moment.utc(driverSchedule.passengerschedule.start);
    var stopTime = driverSchedule.passengerschedule.stop
      ? moment.utc(driverSchedule.passengerschedule.stop)
      : null;

    const stopTimeOffset =
      driverSchedule.passengerschedule.type &&
        driverSchedule.passengerschedule.type.includes("hour")
        ? driverSchedule.passengerschedule.type.replace("hour", "")
        : 2;

    if (!stopTime) {
      stopTime = moment
        .utc(driverSchedule.passengerschedule.start)
        .add(stopTimeOffset, "hours");
    }

    // get current job the driver is on
    if (currentDayMoment.isBetween(startTime, stopTime, null, "[)")) {
      driverSchedulesOverlapping.push(driverSchedule);
      const statusLength = driverSchedule.ridestatuses.length;
      const rideStatus =
        statusLength > 0
          ? driverSchedule.ridestatuses[statusLength - 1]
          : "Scheduled";

      return rideStatus;
    }

    return driverSchedule
  });
};

export const checkVehicleAvailability = (
  vehicles,
  startTimeMoment,
  endTimeMoment
) => {
  const filteredVehicles = vehicles.map(vehicle => {
    // iterate through all vehicles

    const isUnavailable = vehicle.driverschedules.find(driverSchedule => {
      // is vehicle unavailable?
      if (!driverSchedule.passengerschedule) return false;

      const startTime = moment.utc(driverSchedule.passengerschedule.start);
      const stopTimeOffset =
        driverSchedule.passengerschedule.type &&
          driverSchedule.passengerschedule.type.includes("hour")
          ? driverSchedule.passengerschedule.type.replace("hour", "")
          : 2;

      const stopTime = moment
        .utc(driverSchedule.passengerschedule.start)
        .add(stopTimeOffset, "hours");

      const isStartBetween = startTimeMoment.isBetween(
        startTime,
        stopTime,
        null,
        "[]"
      );
      const isEndBetween = endTimeMoment.isBetween(
        startTime,
        stopTime,
        null,
        "[]"
      );

      return isStartBetween || isEndBetween;
    });

    return { ...vehicle, isAvailable: !isUnavailable };
  });

  return filteredVehicles;
};

export const filterVehiclesWithAvailableDriver = (
  vehiclesArray,
  startMoment,
  type
) => {
  if (!vehiclesArray) return [];


  const startTimeMoment = moment.utc(startMoment);
  const stopTimeOffset =
    type && type.includes("hour") ? type.replace("hour", "") : 2;

  const endMoment = moment.utc(startMoment).add(stopTimeOffset, "hours");

  /*
  var vehicles = vehiclesArray.filter(vehicle => {
    if (vehicle.driverschedules.length == 0) return true; // vehicle has no schedules assigned yet

    return false;
  }); */

  const vehicles = mapVehiclesToDriver(vehiclesArray, startTimeMoment, endMoment);

  //vehicles = checkVehicleAvailability(vehicles, startTimeMoment, endMoment);

  return vehicles.filter(x => x.driverInformation);
};

export const mapVehiclesToDriver = (vehiclesArray) => {
  var mappedRecords = [];

  vehiclesArray.map(vehicle => {
    let driverInformation = null;
    if (vehicle.assignedDrivers.length === 0)
      return { ...vehicle, driverInformation };

    vehicle.assignedDrivers.map(assignedVehicle => {

      driverInformation = assignedVehicle?.driver ? assignedVehicle : { driver: {} };

      const ranking = _.meanBy(driverInformation?.driver?.driveratings || [], "rating");
      driverInformation.driver.ranking =
        (!_.isNaN(ranking) && _.round(ranking, 1)) || null;

      mappedRecords.push({
        ...vehicle,
        driverInformation,
        id: assignedVehicle.id,
        vehicleId: vehicle.id,
      });

      return assignedVehicle
    });

    return vehicle
  });

  /*
  const vehicles = vehiclesArray.map(vehicle => {
    let driverInformation = null;
    if (vehicle.assignedDrivers.length == 0)
      return { ...vehicle, driverInformation };

    const driverList = getCurrentDriverInVehicle(
      vehicle.assignedDrivers,
      currentDayMoment
    );

    if (driverList.length > 0) {
      if (driverList[0].driver) {
        driverInformation = driverList[0];
      }
    }

    if (!driverInformation || !driverInformation.driver) {
      return { ...vehicle, driverInformation };
    }

    const ranking = _.meanBy(driverInformation.driver.driveratings, "rating");
    driverInformation.driver.ranking =
      (!_.isNaN(ranking) && _.round(ranking, 1)) || null;

    return { ...vehicle, driverInformation };
  });*/

  return mappedRecords;
};

export const getCurrentDriverInVehicle = (
  assignedDriversArray,
  currentDayMoment
) => {
  if (!assignedDriversArray) return [];

  const assignedDrivers = [];

  assignedDriversArray.map(assignedDriver => {
    if (assignedDriver.wholeEvent) {
      assignedDrivers.push(assignedDriver);
    } else if (
      currentDayMoment.isBetween(
        assignedDriver.start,
        assignedDriver.stop,
        null,
        "[)"
      )
    ) {
      assignedDrivers.push(assignedDriver);
    }
  });

  return assignedDrivers;
};

export const getDriversWithoutVehicle = driverList => {
  if (!driverList) return [];

  const availableDrivers = [];

  driverList.map(driver => {
    const hasWholeEventVehicle = driver.assignedVehicles.find(
      av => av.wholeEvent
    );

    if (!hasWholeEventVehicle) availableDrivers.push(driver);
  });

  return availableDrivers;
};

export const getAvailableVehicleForDriver = (
  assignedVehiclesArray,
  currentDayMoment
) => {
  if (!assignedVehiclesArray) return [];

  const availableVehicles = [];

  assignedVehiclesArray.map(assignedVehicle => {
    if (assignedVehicle.wholeEvent) {
      availableVehicles.push(assignedVehicle);
    } else {
      if (
        currentDayMoment.isBetween(
          assignedVehicle.start,
          assignedVehicle.stop,
          null,
          "[)"
        )
      ) {
        availableVehicles.push(assignedVehicle);
      }

      /*
      console.log(assignedVehicle.start);
      console.log(moment());
      console.log(moment(assignedVehicle.start));
      console.log(moment(assignedVehicle.stop));
      console.log(
        moment().isBetween(
          assignedVehicle.start,
          assignedVehicle.stop,
          null,
          "[)"
        )
      ); */
      // assignedVehicle.
    }
  });

  return availableVehicles;
};

export const VehicleLabel = ({ vehicle, small }) => {
  const plateNumberString = vehicle.plateNumber ? (
    <>[{vehicle.plateNumber}]</>
  ) : (
    ""
  );

  return (
    <span style={{ fontSize: small ? 13 : undefined }}>
      <b>{vehicle.vehicleNumber}</b> - {vehicle.make} {vehicle.model}{" "}
      {vehicle.year && <>{" - "}</>} {vehicle.year} - {plateNumberString}
    </span>
  );
};

export const DriverLabel = ({ driver, small }) => (
  <span style={{ fontSize: small ? 13 : undefined }}>
    {driver.identifier} - {driver.firstName} {driver.lastName} {driver.phone}
  </span>
);

export const getVehicleString = vehicle =>
  `${vehicle.vehicleNumber} - ${vehicle.make} ${vehicle.model}`;

export const completeVehicleAssignment = body => {
  const requestURL =
    process.env.REACT_APP_VEHICLE_ASSIGNMENT_CONFIRMATION ||
    ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/driverschedules/completeSchedule");

  request(requestURL, {
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("jwtToken"))}`
    }
  })
    .then(response => response)
    .catch(err => err);
};
