import React from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import Tooltip from 'components_v2/Tooltip/Tooltip';
import AddRemove from 'components_v2/Inputs/AddRemove/AddRemove';
import ToggleTextSwitchButton from 'components_v2/Inputs/ToggleTextSwitchButton/ToggleTextSwitchButton';
import Typography from 'components_v2/Typography/Typography';
import FormSection from 'components_v2/Inputs/FormSection/FormSection';
import TotalErrors from 'components_v2/Inputs/TotalErrors/TotalErrors';
import { getIndexBy } from 'utils/arrayHelpers';
import { getOverallMarginPrice } from 'utils/formHelpers';
import FleetSupplierSelector from './FleetSupplierSelector/FleetSupplierSelector';
import FleetSelector from './FleetSelector/FleetClassSelector.Container';
import VehiclePriceInputs from './VehiclePriceInputs/VehiclePriceInputs';
import { handleSupplierSelectorDone } from './helpers';

const PricesForm = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  classes,
  projectId,
  formStep,
}) => {
  const priceInputs = values.fleetSuppliers.map((supplier) => {
    const getPriceFieldValue = (data, name) => {
      const clientPrice = (data.clientPrices && data.clientPrices[name]) || 0;

      return values.clientPriceFormat === 'Overall'
        ? getOverallMarginPrice(
            data.supplierPrices[name],
            values.clientPriceMargin
          )
        : clientPrice;
    };

    const handlePriceInputChange = (values, data, target, vehicles) => {
      const { name, value } = target;

      const newFleetSuppliers = [...values.fleetSuppliers];
      const newVehicle = { ...data };
      const newPrices = data.clientPrices ? { ...data.clientPrices } : {};

      newPrices[name] = value;
      newVehicle.clientPrices = newPrices;

      const fleetSupplierIndex = getIndexBy(
        values.fleetSuppliers,
        'id:',
        supplier.id
      );
      const vehicleIndex = getIndexBy(vehicles, 'id:', data.id);

      newFleetSuppliers[fleetSupplierIndex].selectedVehicles.splice(
        vehicleIndex,
        1,
        newVehicle
      );

      setFieldValue('fleetSuppliers', newFleetSuppliers);
    };

    return (
      <Box>
        <Typography customVariant="bodySmallRegular">
          {supplier.name}
        </Typography>
        <VehiclePriceInputs
          values={values}
          setFieldValue={setFieldValue}
          vehicles={supplier.selectedVehicles}
          handleChange={handlePriceInputChange}
          getValue={getPriceFieldValue}
          disabled={values.clientPriceFormat === 'Overall'}
          disableQuantities
          disableDescription
        />
      </Box>
    );
  });

  return (
    <>
      <FormSection defaultExpanded title="Select your fleet suppliers">
        <Box className={classes.fleetRequirementsContainer}>
          <Typography
            style={{ marginBottom: 8 }}
            customVariant="bodySmallRegular"
          >
            Your requirements
          </Typography>
          <FleetSelector
            projectId={projectId}
            values={values}
            setFieldValue={setFieldValue}
            onDone={(selectedVehicleclasses) => {
              // Remove unselected vehicle classes
              const vehicleclassesRemoved = values.fleet.filter((vc) =>
                _.find(selectedVehicleclasses, { id: vc.id })
              );

              // Add newly selected vehicle classes
              const newVehicleClasses = selectedVehicleclasses.map((vc) => {
                const matchedVC = _.find(vehicleclassesRemoved, {
                  id: vc.id,
                });
                return matchedVC || { ...vc, quantity: 1 };
              });

              setFieldValue('fleet', newVehicleClasses);
            }}
            error={touched.fleet && errors.fleet}
            // isHelpTutorial={isHelpTutorial}
          />
        </Box>
        <Box>
          <Typography
            style={{ marginBottom: 8 }}
            customVariant="bodySmallRegular"
          >
            Chosen suppliers
          </Typography>
          <FleetSupplierSelector
            values={values}
            setFieldValue={setFieldValue}
            formStep={formStep}
            onDone={handleSupplierSelectorDone(values, setFieldValue)}
            error={touched.fleetSuppliers && errors.fleetSuppliers}
          />
        </Box>
      </FormSection>
      <FormSection title="Enter the client-facing prices">
        <Box className={classes.clientPriceFormatContainer}>
          <ToggleTextSwitchButton
            name="clientPriceFormat"
            label="Select price format"
            value={values.clientPriceFormat}
            onChange={handleChange}
            options={[
              { title: 'Overall Margin', value: 'Overall' },
              { title: 'Manual Input', value: 'Manual' },
            ]}
          />
        </Box>

        <Box className={classes.clientPriceMarginContainer}>
          <AddRemove
            percentage
            isDisabled={values.clientPriceFormat === 'Manual'}
            label={
              <div className={classes.tooltipLabelContainer}>
                Desired percentage margin
                <Tooltip
                  nocontainer
                  title="Sets the desired margin for client-facing prices."
                >
                  <HelpOutline className={classes.tooltipIcon} />
                </Tooltip>
              </div>
            }
            name="clientPriceMargin"
            value={values.clientPriceMargin}
            onChange={handleChange}
          />
        </Box>
        {priceInputs}
      </FormSection>
      <TotalErrors errors={errors} touched={touched} withSpacer />
    </>
  );
};

export default PricesForm;
