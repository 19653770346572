import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ShuttleScheduleItems from "./ShuttleScheduleItems";
import VehicleAssignmentItems from "./VehicleAssignmentItems";
import { Typography } from "@material-ui/core";
import { mobileViewBreakpoint } from "./../../../../App.helper";
const convertArrayToObjectById = array => {
  return array.reduce((obj, item) => {
    const { id, ...rest } = item;
    return {
      ...obj,
      [id]: rest
    };
  }, {});
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column"
    }
  },
  items: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "32px",
    "&:last-child": {
      paddingLeft: "0px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        paddingLeft: "32px"
      }
    }
  }
}));

const ScheduleDetails = ({
  shuttleScheduleItems,
  vehicleItems,
  locations,
  vehicles,
  name,
  refetch,
  shuttleScheduleId
}) => {
  const classes = useStyles();

  const locationsObject = convertArrayToObjectById(locations);
  const vehiclesObject = convertArrayToObjectById(vehicles);

  return (
    <section className={classes.root}>
      <div className={classes.items}>
        <ShuttleScheduleItems
          shuttleScheduleId={shuttleScheduleId}
          shuttleScheduleItems={shuttleScheduleItems}
          locationsObject={locationsObject}
          refetch={refetch}
        ></ShuttleScheduleItems>
      </div>
      <div className={classes.items}>
        <Typography style={{ paddingBottom: "8px" }} variant="h6">
          Assign available vehicles to {name}
        </Typography>
        <VehicleAssignmentItems
          shuttleScheduleId={shuttleScheduleId}
          vehicleItems={vehicleItems}
          vehiclesObject={vehiclesObject}
          refetch={refetch}
        ></VehicleAssignmentItems>
      </div>
    </section>
  );
};

export default ScheduleDetails;
