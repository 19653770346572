import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Star } from "../Icons/Icons";
import { mobileViewBreakpoint } from "../../App.helper";

const useStyles = makeStyles((theme) => ({
  starWrapper: (props) => ({
    alignItems: "center",
    display: "flex",
    
    "& > svg": {
      marginRight: props.direction === "left" && "8px",
      minWidth: 18,
    },
  }),
  ratingWrapper: (props) => ({
    fontSize: "14px",
    marginRight: props.direction === "right" && "8px",
    lineHeight: "134.3%",
    color: "rgba(0, 0, 0, 0.9)",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontWeight: "bold",
    },
  }),
}));
const StarRating = ({ rating, direction }) => {
  const classes = useStyles({ direction });
  return (
    <span className={classes.starWrapper}>
      {direction === "left" && <Star />}
      <span className={classes.ratingWrapper}>{rating || 0}</span>
      {direction === "right" && <Star />}
    </span>
  );
};
export default StarRating;
