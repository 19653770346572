export const mockRealSearchData = {
  locationNameContains: [
    {
      id: '5f271ba97442154fd8a8c13b',
      identifier: 'RDH0010',
      name: 'Dubai Airport Terminal 2 Departure',
      type: 'Event venue',
      address: 'Dubai - United Arab Emirates',
      lat: 25.2628902,
      long: 55.3532168,
      __typename: 'Storedlocation',
    },
    {
      id: '5f271bba7442154fd8a8c13c',
      identifier: '',
      name: 'Palm Jumeirah',
      type: 'hotel',
      address: 'Palm Jumeirah - Dubai - United Arab Emirates',
      lat: 25.1124317,
      long: 55.13897799999999,
      __typename: 'Storedlocation',
    },
    {
      id: '5f28a5cb7950d03fd1ef5d86',
      identifier: '',
      name: 'Some Hotel - South Korea :O',
      type: 'other',
      address: 'Some Hotel, Jinjam-dong, Daejeon, South Korea',
      lat: 36.312748,
      long: 127.31992,
      __typename: 'Storedlocation',
    },
  ],
  locationIdentifierContains: [
    {
      id: '5fa041e2dd9b233faed24576',
      identifier: 'gohawks',
      name: 'CenturyLink Field',
      type: 'TODO: Add Types',
      address: 'Occidental Avenue South, Seattle, WA, USA',
      lat: 47.5951518,
      long: -122.3316394,
      __typename: 'Storedlocation',
    },
    {
      id: '5fa182fa1068022ef6943f24',
      identifier: 'changedId',
      name: 'Zions Gate Records',
      type: 'Event venue',
      address: 'East Pike Street, Seattle, WA, USA',
      lat: 47.614266,
      long: -122.317833,
      __typename: 'Storedlocation',
    },
    {
      id: '5fa70b903ddc05424f6645da',
      identifier: 'seahawks-stadium',
      name: 'Century LInk FIeld',
      type: 'Event venue',
      address:
        'CenturyLink Field,  Occidental Avenue South,  Seattle,  WA,  USA',
      lat: 47.5951518,
      long: -122.3316394,
      __typename: 'Storedlocation',
    },
  ],
  passengerFirstNameContains: [
    {
      id: '5f3d03744c2533001783b490',
      firstName: 'James',
      lastName: 'Jacob',
      email: 'steffivgomes@gmail.com',
      identifier: null,
      phone: null,
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f3d59154c2533001783b498',
      firstName: 'John',
      lastName: 'Reegan',
      email: 'john@gmail.com',
      identifier: null,
      phone: '+971509664754',
      __typename: 'UsersPermissionsUser',
    },
  ],
  passengerLastNameContains: [
    {
      id: '5f3d03744c2533001783b490',
      firstName: 'James',
      lastName: 'White',
      email: 'steffivgomes@gmail.com',
      identifier: null,
      phone: null,
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f3d59154c2533001783b498',
      firstName: 'James',
      lastName: 'White',
      email: 'james123@gmail.com',
      identifier: null,
      phone: '+971509664754',
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f688cb27d1c4400175e9268',
      firstName: 'Yolam ',
      lastName: 'Anderson',
      email: 'yolam@thebal.com',
      identifier: null,
      phone: '+971509664754',
      __typename: 'UsersPermissionsUser',
    },
  ],
  passengerIdentifierContains: [],
  driverFirstNameContains: [
    {
      id: '5f289d298d19e3785c525f70',
      firstName: 'Peter',
      lastName: 'Paul',
      email: 'peter+90@gmail.com',
      identifier: 'B0010',
      phone: '+971509664754',
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f689a4b7d1c4400175e926b',
      firstName: 'Aime',
      lastName: 'Aime',
      email: 'irafashagentillegena@gmail.com',
      identifier: 'aime',
      phone: '+971509664754',
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f6b887603ef910017fa954f',
      firstName: 'Ngabo ',
      lastName: 'Alexis',
      email: 'reservation@attltd.rw',
      identifier: 'RAD420D',
      phone: '+971509664754',
      __typename: 'UsersPermissionsUser',
    },
  ],
  driverLastNameContains: [
    {
      id: '5f3cfc924c2533001783b488',
      firstName: 'McLovin',
      lastName: 'Man',
      email: 'lovin@macin.com',
      identifier: '124',
      phone: '+971402224334',
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f689a4b7d1c4400175e926b',
      firstName: 'Aime',
      lastName: 'Aime',
      email: 'irafashagentillegena@gmail.com',
      identifier: 'aime',
      phone: '+971509664774',
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f6b887603ef910017fa954f',
      firstName: 'Ngabo ',
      lastName: 'Alexis',
      email: 'reservation@attltd.rw',
      identifier: 'RAD420D',
      phone: '+971509664111',
      __typename: 'UsersPermissionsUser',
    },
  ],
  driverIdentifierContains: [
    {
      id: '5f689a4b7d1c4400175e926b',
      firstName: 'Aime',
      lastName: 'Aime',
      email: 'irafashagentillegena@gmail.com',
      identifier: 'aime',
      phone: '+971509660000',
      __typename: 'UsersPermissionsUser',
    },
    {
      id: '5f6b887603ef910017fa954f',
      firstName: 'Ngabo ',
      lastName: 'Alexis',
      email: 'reservation@attltd.rw',
      identifier: 'RAD420D',
      phone: '+971509667845',
      __typename: 'UsersPermissionsUser',
    },
  ],
};
