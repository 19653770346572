import React, { useState } from "react";
import Typography from "components_v2/Typography/Typography";
import { useMutation } from "@apollo/react-hooks";
import SendDriverLinkConfirmation from "../../gql/PassengerSchedules/SendDriverLinkConfirmation";
import SendDriverLinkConfirmationModal from "./SendDriverLinkConfirmationModal/SendDriverLinkConfirmationModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  item: {
    cursor: 'pointer',
    padding: "16px 24px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.02)"
    }
  },
})

const SendDriverLink = ({ data, id }) => {
  const classes = useStyles();

  const [
    sendDriverLinkConfirmationModalData,
    setSendDriverLinkConfirmationModalData
  ] = useState(null);

  const [
    sendDriverLinkConfirmation,
  ] = useMutation(SendDriverLinkConfirmation);

  const sendDriverLink = async (data) => {
    setSendDriverLinkConfirmationModalData(null);

    await sendDriverLinkConfirmation({
      variables: {
        ...data,
      },
    });
  };


  return <>
    <SendDriverLinkConfirmationModal
      data={sendDriverLinkConfirmationModalData}
      onClickCancel={() => {
        setSendDriverLinkConfirmationModalData(null);
      }}
      onClickSend={async data => {
        sendDriverLink(data);
      }}
    />
    <div
      className={classes.item}
      id={id}
      onClick={() => {
        setSendDriverLinkConfirmationModalData({
          record: data
        })
      }}>
      <Typography
        customVariant="bodySmallRegular"
      >
        Send driver link
      </Typography>
    </div>
  </>
}

export default SendDriverLink;