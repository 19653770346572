import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import GetAllByProject from 'gql/Modules/GetAllByProject';
import { useQuery } from '@apollo/react-hooks';
import { mockData } from '../../Modules/ModuleSettingsContainer.mock';
import _ from 'lodash';
import { Collapse } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';



import { ChevronDown } from '../../../../Icons/Icons';
import Skeleton from "@material-ui/lab/Skeleton";
import { setFocusOnElement } from '../../../../../utils/helpers';

const StyledMenu = withStyles(() => ({
  paper: {
    padding: 0,
    width: '100%',
    '& ul': {
      padding: '0 !important',
    },
  },
  list: {
    height: 'auto',
    maxHeight: '200px',
    width: '100% !important',

  },
}))((props) => <Menu {...props} />);


const styles = () => ({
  root: {
    display: 'inline-flex',
    cursor: 'pointer',
    width: '100%',

    '& header': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '&:hover $title': {
      fill: '#000000',
    },
  },
  expanded: {
    '& $action': {
      fill: '#000000',
      transform: 'rotate(-180deg)',
    },
  },
  headerWrapper: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    fill: '#000000',
    transition: 'all 0.3s',
  },
  title: {
    display: 'flex',
    flexDirection: 'row'
  },
});

const MobileModulesSlider = ({ classes, menuOffset, projectId, isHelpTutorial, onSelectModule, selectedModule }) => {
  const menuRef = useRef();
  let { data, loading } = useQuery(GetAllByProject, {
    variables: { id: projectId },
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    data = mockData;
  }
  const modules = data ? data.modules || [] : [];

  let dataToDisplay = []
  const handleClose = () => {
    setFocusOnElement(false);
    setExpanded(false);
  };
  const handleClick = (event) => {
    setFocusOnElement(true, event.target.parentNode.parentNode);
    setExpanded(true);
  }
  const getAnchorPosition = (ref, offsetTop = 50, offsetLeft = 0) => {
    if (!ref || !ref.current) return { top: 0, left: 0 };

    const { current } = ref;
    const rect = current.getBoundingClientRect();
    const top = rect.y + rect.height + offsetTop;
    const left = rect.x + offsetLeft;
    return { top, left };
  };
  const anchorPosition = getAnchorPosition(
    menuRef,
    0,
    menuOffset && menuOffset.left
  );
  const [expanded, setExpanded] = useState(false);

  const handleChange = (card) => {
    onSelectModule(card)
    handleClose()
  }
  dataToDisplay = _.concat(dataToDisplay, _.filter(modules, (module) => module.id !== selectedModule?.id));
  if (selectedModule?.id) {
    dataToDisplay = _.concat(dataToDisplay, [{
      id: 0,
      name: 'All Modules'
    }]);
  }

  return loading ? 
  <Skeleton variant="text"
    width={'100%'}
    height={60}
    style={{ marginTop: '14px', marginBottom: 8 }}
  />
  
 :  <>
    <section
      style={{ paddingLeft: '0px' }}
      className={clsx(classes.root, {
        [classes.expanded]: expanded,
        'project-selector__expanded': expanded,
      })}
    >
      <header ref={menuRef}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          padding: '8px',
          background: '#ffffff',
          marginTop: '32px',
          width: '100%'
        }}
        onClick={(event) => handleClick(event)}
        id="project-selector"
      >
        <div
          className={clsx([classes.title], 'project-selector__title')}
        >
          <div style={{ paddingRight: '2px' }}>
            {selectedModule.name}
          </div>
        </div>
        <div
          className={clsx(
            [classes.action],
            'project-selector__action'
          )}
        >
          <ChevronDown />
        </div>
      </header>

    </section>
    <StyledMenu
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      open={expanded}
      onClose={handleClose}
      TransitionComponent={Collapse}
    >

      {_.map(dataToDisplay, (card, index) => (
        <MenuItem
          key={index}
          value={card.id}
          onClick={() => handleChange(card)}
          style={{
            paddingTop: 16,
            paddingBottom: 16,
            background: !card?.id && 'rgba(0, 0, 0, 0.02)'
          }}
        >
          <>
            <div style={{ fontSize: '16px', color: !card?.id && '#4001c5', fontWeight: !card?.id && 'bold' }}>
              {card.name}
            </div>
          </>
        </MenuItem>
      ))}
    </StyledMenu>
  </>


}
export default withRouter(withStyles(styles)(MobileModulesSlider));



