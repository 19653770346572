import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cardItem: {
    cursor: 'pointer',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0px 8px 28px transparent',
    '&:lastChild': {
      border: 'none',
    },
    '&:hover': {
      boxShadow: '0px 8px 28px rgba(0, 0, 0, 0.2)',
      borderBottom: '1px solid #ffffff',
      borderRadius: '6px',
    },
  },
  inline: {
    display: 'inline',
  },
  image: {
    width: '72px',
    height: '72px',
    marginRight: '16px',
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  title: {
    marginTop: '10px',
    marginBottom: '8px',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '134.3%',
    color: '#000000',
  },
  content: {
    fontSize: '14px',
    lineHeight: '134.3%',
    letterSpacing: '-0.2px',
    color: 'rgba(0, 0, 0, 0.8)',
    wordBreak: 'break-all',
  },
  supplementInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .MuiBadge-badge': {
      fontStyle: 'normal',
      fontWeight: '900',
      fontSize: '14.2045px',
      lineHeight: '17px',
      textAlign: 'center',
      color: '#FFFFFF',
      width: '30px',
      height: '30px',
      borderRadius: '30px',
      marginTop: '6px',
    },
  },
  from: {
    fontWeight: 'bold',
  },
  time: {
    fontSize: '14px',
    lineHeight: '134.3%',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '10px',
    marginBottom: '8px',
  },
  basicInfo: {
    marginRight: '16px',
  },
}));
