import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  cellBase: {
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'top',
    padding: 8,
    height: 200,
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    textAlign: 'right',

    '&:last-of-type': {
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    },
  },
  todayDateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#4001C5',
    color: '#fff',
    borderRadius: '50%',
    width: 40,
    height: 40,
    marginLeft: 'auto',
    fontWeight: 'bold',
    boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.15)',
    zIndex: 10,
  },
}));
