import React from "react";
import { useStyles } from "./TourTooltipIntro.styles";
import "./react-joyride.css";
import "./car.css";

const TourTooltipIntro = () => {
  const classes = useStyles();

  return (
    <div className={classes.tourTooltipIntroWrapper}>
      <div className={classes.tooltipTitle}>Welcome to Motus Ride</div>
      <div className={classes.tooltipQuestion}>
        Do you want us to give you a walkthrough of the platform?
      </div>
      <div className="car">
        <div className="weel weel1"></div>
        <div className="weel weel2"></div>
      </div>
    </div>
  );
};

export default TourTooltipIntro;
