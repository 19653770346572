/* eslint-disable complexity */
import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Menu from 'components_v2/Menu/Menu';
import { PDFExport } from '@progress/kendo-react-pdf';
import PageTemplate from 'components/Pdf/PageTemplate';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PieGraph from '../Charts/PieGraph/PieGraph';
import StatusGraph from '../Charts/StatusGraph/StatusGraph';
import BarGraph from '../Charts/BarGraph/BarGraph';
import CounterGraph from '../Charts/CounterGraph/CounterGraph';
import GetGroupedByProject from '../../gql/DriverRatings/GetGroupedByProject';
import RideRatingsList from './RideRatingsList';
import classNames from "classnames";
import { ChevronDown } from '../Icons/Icons';
import {getCurrentPieChart ,getCurrentBarChart} from './ReportingHelper'
import DynamicReportSkeleton from 'components_v2/List/DynamicReportSkeleton';

import {
  parseRideRatingsData,
  parseRideTakenBookedData,
  parsePieChartData,
  parseAlertsPieChartData,
  parseAllAlertsData,
  getAlertsCount,
  parseByTransferTypeData,
  parseByVehicleTypeData,
  parseByModuleTypeData,getCurrentCounterGraph
} from './ReportingHelper';
import GetAllByEventCompressed from '../../gql/PassengerSchedules/GetAllByEventCompressed';
import GetAllGroupedByEvent from '../../gql/Alerts/GetAllGroupedByEvent';
import GetAllGQL from '../../gql/Alerts/GetAllByEvent';
import { mockData } from '../Alerts/Alerts.mock';
import {
  mockAlertsGroupedData,
  mockRideRatingsGroupedData,
  mockPassengerSchedulesData,
} from './Reporting.mock';
import { mobileViewBreakpoint } from '../../App.helper';
import { transferTypebarConfig, transferVehiclebarConfig, transferModulebarConfig } from '../SampleForm/bar_data';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chartWrapper: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    minHeight: '216px',
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      minHeight: '0',
    },
  },
  rideRatings: {
    marginTop: '55px',
  },
  topToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  chartTypes: {
    width: 'auto'
  },
  flexCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexContainer:{
    display:"flex",
    flexWrap: "wrap",
  },
  unresolvedIssuesContainer:{
    paddingRight: 10,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '10px 0px',
    },
  },
  rideStatusContainer:{
    paddingLeft: 10,
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: '10px 0px 20px 0px',
    },
  }
}));

const Reporting = ({ reportData, selectedProjectId, isHelpTutorial }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));
  const classes = useStyles();
  const [currentBarChart, setCurrentBarChart] = useState('ridesBVsRidesT');
  const [currentPieChart, setCurrentPieChart] = useState('ridesOverview');
  const [currentCounterGraph, setCurrentCounterGraph] = useState(
    'unresolvedIssues'
  );

  let { data: alertsAllData } = useQuery(
    GetAllGQL,
    {
      variables: { id: selectedProjectId },
      fetchPolicy: 'no-cache',
      skip: isHelpTutorial,
    }
  );

  if (isHelpTutorial) {
    alertsAllData = mockData;
  }

  let parsedAllAlertsData = parseAllAlertsData(alertsAllData);

  let { data: alertsGroupedData, loading: alertsGroupedDataLoading } = useQuery(
    GetAllGroupedByEvent,
    {
      variables: { id: selectedProjectId },
      fetchPolicy: 'no-cache',
      skip: isHelpTutorial,
    }
  );

  if (isHelpTutorial) {
    alertsGroupedData = mockAlertsGroupedData;
  }

  let {
    data: alertsUnresolvedGroupedData,

  } = useQuery(GetAllGroupedByEvent, {
    variables: { id: selectedProjectId, status: 'unresolved' },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    alertsUnresolvedGroupedData = mockAlertsGroupedData;
  }

  let {
    data: alertsResolvedGroupedData,

  } = useQuery(GetAllGroupedByEvent, {
    variables: { id: selectedProjectId, status: 'resolved' },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    alertsResolvedGroupedData = mockAlertsGroupedData;
  }

  let {
    data: rideRatingsGroupedData,
    loading: rideRatingsGroupedLoading,
  } = useQuery(GetGroupedByProject, {
    variables: { id: selectedProjectId },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });

  if (isHelpTutorial) {
    rideRatingsGroupedData = mockRideRatingsGroupedData;
  }

  let {
    data: passengerSchedulesData,
    loading: passengerSchedulesDataLoading,
  } = useQuery(GetAllByEventCompressed, {
    variables: { id: selectedProjectId },
    fetchPolicy: 'no-cache',
    skip: isHelpTutorial,
  });



  if (isHelpTutorial) {
    passengerSchedulesData = mockPassengerSchedulesData;
  }

  if ( 
    rideRatingsGroupedLoading ||
    passengerSchedulesDataLoading ||
    alertsGroupedDataLoading
  )
    return <DynamicReportSkeleton />;

  const parsedRideTakenBookedData = parseRideTakenBookedData(
    passengerSchedulesData
  );

  const parsedByTransferTypeData = parseByTransferTypeData(
    passengerSchedulesData
  );

  const parsedByVehicleTypeData = parseByVehicleTypeData(
    passengerSchedulesData
  );

  const parsedByModuleTypeData = parseByModuleTypeData(passengerSchedulesData);

  const parsedPieChartData = parsePieChartData(
    alertsGroupedData,
    passengerSchedulesData
  );
  const parsedAlertsPieChartData = parseAlertsPieChartData(
    alertsUnresolvedGroupedData,
    alertsResolvedGroupedData
  );
  return (
    <div className={classes.root}>
      
      <div className={classes.topToolbar}>
        {/*  <Button startIcon={<Share />} variant="simple">
          Share
        </Button>
        <Button
          startIcon={<DownloadTo />}
          variant="simple"
          onClick={() => pdfExportComponent.save()}
        >
          Export
        </Button> */}
      </div>{' '}
      <PDFExport
        forcePageBreak=".page-break"
        ref={(component) => (component)}
        paperSize="A4"
        scale={0.5}
        margin="3cm"
        fileName="reports"
        pageTemplate={(props) => (
          <PageTemplate {...props} style={{ marginTop: 5 }} title="Reports" />
        )}
      >
        <Grid container spacing={3}>
          <Grid item sm={12} md={12} xs={12} lg={6} className={classes.flexContainer}>
           
              <Grid item sm={12} md={12} xs={12} lg={6} className={classes.unresolvedIssuesContainer}>
                <Menu
                  items={[
                    {
                      label: 'Unresolved',
                      onClick: () => setCurrentCounterGraph('unresolvedIssues'),
                    },
                    {
                      label: 'Resolved',
                      onClick: () => setCurrentCounterGraph('resolvedIssues'),
                    },
                    {
                      label: 'All',
                      onClick: () => setCurrentCounterGraph('allIssues'),
                    },
                  ]}
                  render={(handleClick) => (
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                      onClick={handleClick}
                    >
                      <Typography
                        style={{
                          fontWeight: 'normal',
                        }}
                        variant={isMobileView ?'h5': 'h4'}
                      >
                        {getCurrentCounterGraph({currentCounterGraph ,currentCounter:'unresolvedIssues'})  && (
                          <>
                            <span style={{ fontWeight: 'normal' }}>
                              Unresolved issues
                            </span>{' '}
                            (
                            {getAlertsCount(
                              parsedAllAlertsData.unresolvedAlerts
                            )}
                            )
                          </>
                        )}
                         {getCurrentCounterGraph({currentCounterGraph ,currentCounter: 'resolvedIssues'}) && (
                          <>
                            <span style={{ fontWeight: 'normal' }}>
                              Resolved issues
                            </span>{' '}
                            (
                            {getAlertsCount(parsedAllAlertsData.resolvedAlerts)}
                            )
                          </>
                        )}
                        {getCurrentCounterGraph({currentCounterGraph ,currentCounter:'allIssues'}) && (
                          <>
                            <span style={{ fontWeight: 'normal' }}>
                              All issues
                            </span>{' '}
                            ({getAlertsCount(parsedAllAlertsData.allAlerts)})
                          </>
                        )}{' '}
                      </Typography>
                      <ChevronDown />
                    </div>
                  )}
                />
                <div className={classes.chartWrapper}>
                  {getCurrentCounterGraph({currentCounterGraph ,currentCounter:'unresolvedIssues'})&&  (
                    <CounterGraph data={parsedAllAlertsData.unresolvedAlerts} />
                  )}
                  {getCurrentCounterGraph({currentCounterGraph ,currentCounter:'resolvedIssues'}) &&(
                    <CounterGraph data={parsedAllAlertsData.resolvedAlerts} />
                  )}
                  {getCurrentCounterGraph({currentCounterGraph,currentCounter: 'allIssues'}) && (
                    <CounterGraph data={parsedAllAlertsData.allAlerts} />
                  )}
                </div>
              </Grid>
              {isMobileView && (
                <Grid item sm={12} xs={12} md={12} lg={12} className={classes.rideStatusContainer}>
                  <Typography variant="h5">
                    <span style={{ fontWeight: 'normal' }}>Ride Status</span>{' '}
                  </Typography>

                  <div className={classes.chartWrapper}>
                    <StatusGraph
                      rating={
                       
                        rideRatingsGroupedData?.driveratingsConnection?.aggregate?.avg?.rating
                      }
                      data={parseRideRatingsData(rideRatingsGroupedData)}
                    />
                  </div>
                </Grid>
              )}
              <Grid item md={12} xs={12} sm={12} lg={6}>
                <Menu
                  items={[
                    {
                      label: 'Rides overview',
                      onClick: () => setCurrentPieChart('ridesOverview'),
                    },
                    {
                      label: 'Issue status',
                      onClick: () => setCurrentPieChart('resUnresIssues'),
                    },
                  ]}
                  render={(handleClick) => (
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                      onClick={handleClick}
                    >
                      <Typography
                        style={{
                          fontWeight: 'normal',
                        }}
                        variant={isMobileView ? 'h5': 'h4'}
                      >
                        {getCurrentPieChart({currentPieChart ,pieChart:'ridesOverview'})   && (
                          <span style={{ fontWeight: 'normal' }}>
                            Rides overview
                          </span>
                        )}
                        {getCurrentPieChart({currentPieChart ,pieChart : 'resUnresIssues' }) && (
                          <span style={{ fontWeight: 'normal' }}>
                            Issue status
                          </span>
                        )}{' '}
                      </Typography>
                      <ChevronDown />
                    </div>
                  )}
                />
                {getCurrentPieChart({currentPieChart ,pieChart: 'ridesOverview' })  && (
                  <div className={classNames(classes.chartWrapper, classes.flexCentered)} >
                    <PieGraph
                      raph
                      isLegendsVisible={false}
                      data={parsedPieChartData}
                    />
                  </div>
                )}
                { getCurrentPieChart ({currentPieChart, pieChart: 'resUnresIssues'}) && (
                  <div className={classNames(classes.chartWrapper, classes.flexCentered)}>
                    <PieGraph
                      isLegendsVisible={false}
                      data={parsedAlertsPieChartData}
                    />
                  </div>
                )}
              </Grid>
              {!isMobileView && (
                <Grid item md={12} xs={12} lg={12} sm={12} >
                  <Typography variant="h4">
                    <span style={{ fontWeight: '400' }}>Ride Ratings</span> (
                    {
                      rideRatingsGroupedData?.driveratingsConnection?.aggregate?.count}
                    )
                  </Typography>

                  <div className={classes.chartWrapper}>
                    <StatusGraph
                      rating={
                     
                        rideRatingsGroupedData?.driveratingsConnection?.aggregate?.avg?.rating
                      }
                      data={parseRideRatingsData(rideRatingsGroupedData)}
                    />
                  </div>
                </Grid>
              )}
            
          </Grid>
          <Grid item md={12} lg={6} xs={12} sm={12}>
            <Menu
              items={[
                {
                  label: 'Rides booked vs Rides taken',
                  onClick: () => setCurrentBarChart('ridesBVsRidesT'),
                },
                {
                  label: 'By transfer type',
                  onClick: () => setCurrentBarChart('byTransferType'),
                },
                {
                  label: 'By vehicle type',
                  onClick: () => setCurrentBarChart('byVehicleType'),
                },
                {
                  label: 'By module type',
                  onClick: () => setCurrentBarChart('byModuleType'),
                },
              ]}
              render={(handleClick) => (
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                  onClick={handleClick}
                >
                  <Typography
                    style={{
                      fontWeight: 'normal',
                    }}
                    variant={isMobileView ?'h5':'h4'}
                  >
                    {getCurrentBarChart({currentBarChart ,barChart:'ridesBVsRidesT'})   && (
                      <span style={{ fontWeight: 'normal' }}>
                        Rides booked vs Rides taken
                      </span>
                    )}
                    {getCurrentBarChart({currentBarChart ,barChart:'byTransferType' }) && (
                      <span style={{ fontWeight: 'normal' }}>
                        By transfer type
                      </span>
                    )}
                    {getCurrentBarChart({currentBarChart ,barChart:'byVehicleType' })&& (
                      <span style={{ fontWeight: 'normal' }}>
                        By vehicle type
                      </span>
                    )}
                    {getCurrentBarChart ({currentBarChart, barChart: 'byModuleType'})  && (
                      <span style={{ fontWeight: 'normal' }}>
                        By module type
                      </span>
                    )}{' '}
                  </Typography>
                  <ChevronDown />
                </div>
              )}
              className={classes.chartTypes}
            />
            {getCurrentBarChart({currentBarChart ,barChart: 'ridesBVsRidesT'})   && (<div className={classNames(classes.chartWrapper, classes.flexCentered)}>
              <BarGraph
                data={parsedRideTakenBookedData}
                config={reportData.rbrt.barConfig}
              />
            </div>)}
            {getCurrentBarChart ({currentBarChart ,barChart:'byTransferType'}) && (<div className={classNames(classes.chartWrapper, classes.flexCentered)}>
              <BarGraph
                data={parsedByTransferTypeData}
                config={transferTypebarConfig}
              />
            </div>)}
            {getCurrentBarChart({currentBarChart ,barChart: 'byVehicleType'}) && (<div className={classNames(classes.chartWrapper, classes.flexCentered)}>
              <BarGraph
                data={parsedByVehicleTypeData}
                config={transferVehiclebarConfig}
              />
            </div>)}
            {getCurrentBarChart({currentBarChart ,barChart:'byModuleType'})   && (<div className={classNames(classes.chartWrapper, classes.flexCentered)}>
              <BarGraph
                data={parsedByModuleTypeData}
                config={transferModulebarConfig}
              />
            </div>)}
          </Grid>
        </Grid>
        <div className={classes.rideRatings}>
          <RideRatingsList
            projectId={selectedProjectId}
            pageLoading={rideRatingsGroupedLoading}
            isHelpTutorial={isHelpTutorial}
          />
        </div>
      </PDFExport>
    </div>
  );
};
export default Reporting;
