import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: 'rgba(64, 1, 197, 0.4)',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#4001C5',
    },
  },
});

function Progress(props) {
  const classes = useStyles();

  return (
    <LinearProgress
      classes={{ root: classes.root }}
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '99999',
      }}
    />
  );
}

export default Progress;
