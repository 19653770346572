import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/Events/GetOne';
// import UpdateProduct from 'gql/Products/Update';
// import CreateProduct from 'gql/Products/Create';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddProjectComponent from './AddProject.Component';

const AddProjectContainer = ({ match }) => {
  const { projectId, step } = match.params;

  const { data = null, loading, refetch } = useQuery(GetOne, {
    variables: { id: projectId },
    skip: !projectId,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  return (
    <AddProjectComponent
      data={data?.events[0]}
      eventProducts={data?.products}
      refetchProject={refetch}
      projectId={projectId}
      stepParam={parseInt(step)}
    />
  );
};

AddProjectContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ projectId: PropTypes.string }),
  }),
};

export default withMainLayout(
  'Add Driver',
  {},
  withRouter(AddProjectContainer)
);
