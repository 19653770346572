import moment from "moment";
import { allSteps } from "../components_v2/Bookings/BookingHelper";
import request from "./request";
import _ from "lodash";

export const findSchedulesForDate = (date, passengerScheduleData) =>
  passengerScheduleData.filter(
    x =>
      moment.utc(x.start).format("YYYY-MM-DD") ===
      moment.utc(date).format("YYYY-MM-DD")
  );

export const convertTransferTypeToHours = type => {
  if (type === "transfer") return 2;
  if (type === "trackTransfer") return 2;
  if (type === "4hour") return 4;
  if (type === "6hour") return 6;
  if (type === "12hour") return 12;
  if (type === "24hour") return 24;
};

export const convertTransferTypeToString = type => {
  if (type === "transfer") return "Airport Transfer";
  if (type === "trackTransfer") return "Track Transfer";
  if (type === "4hour") return "4 hour service";
  if (type === "6hour") return "6 hour service";
  if (type === "12hour") return "12 hour service";
  if (type === "24hour") return "24 hour service";
};

export const convertRideStatusToLabel = status => {
  const foundStatus = _.find(allSteps, (step) => step.label === status);
  if (foundStatus) return foundStatus.label
  return ""
};

export const getCurrentScheduleStatusLabel = status => {
  if (status === 'noShow') return "No Show"
  return convertRideStatusToLabel(status)
};

export const getPassengerScheduleStatus = passengerSchedule => {
  if (
    passengerSchedule.passengerschedulechanges.filter(
      i => i.status === "pending"
    ).length > 0
  )
    return "changeRequested";

  if (passengerSchedule.cancelled) {
    return "cancelled";
  }

  if (
    passengerSchedule.driverschedules.find(dS =>
      dS.ridestatuses.find(rS => rS.status === "noShow")
    )
  ) {
    return "noShow";
  }

  if (
    passengerSchedule.driverschedules.find(dS =>
      dS.ridestatuses.find(rS => rS.status === "complete")
    )
  ) {
    return "completed";
  }

  if (
    passengerSchedule.driverschedules.find(dS =>
      dS.ridestatuses.find(rS => rS.status === "enroute")
    )
  ) {
    return "started";
  }

  if (
    passengerSchedule.driverschedules.length ===
    passengerSchedule.numberOfVehicles
  )
    return "ready";

  return "new";
};

export const mapScheduleStatusToColor = status => {
  if (status === "changeRequested") return "#fab4164a";
  if (status === "completed") return "#02b7956e";
  if (status === "cancelled") return "#d0021175";
  if (status === "started") return "#eec60d6b";
  if (status === "ready") return "#aed97c6b";
  if (status === "noShow") return "#a070ff6b";

  return "#FFF";
};

// export const

export const completeVehicleAssignment = body => {
  const requestURL =
    process.env.REACT_APP_VEHICLE_ASSIGNMENT_CONFIRMATION ||
    ("http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/driverschedules/completeSchedule");

  request(requestURL, {
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("jwtToken"))}`
    }
  })
    .then(response => response)
    .catch(err => err);
};
