import _ from 'lodash';
// import Skeleton from "@material-ui/lab/Skeleton";
import React from 'react';
import { Box } from '@material-ui/core';
import ListModal from 'components_v2/ListModal/ListModal';
import { withRouter } from 'react-router-dom';
import SelectActionButton from 'components_v2/SelectActionButton/SelectActionButton';
import AddRemove from 'components_v2/Inputs/AddRemove/AddRemove';
import Typography from 'components_v2/Typography/Typography';
import staffOptions from 'utils/requiredStaff';
import { getStaffIcon } from 'utils/iconHelpers';
import useStyles from './StaffSelector.style';

const Container = ({
  values,
  setFieldValue,
  error,
}) => {
  const classes = useStyles();

  const getIndexBy = (arr, name, value) => {
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i][name] === value) {
        return i;
      }
    }
    return false;
  };

  const getValue = (requiredStaff, id) => {
    // Find product
    const staff = _.find(requiredStaff, { id: id });

    return staff ? staff.quantity : 1;
  };

  const renderStaffName = (row) => {
    const Icon = getStaffIcon(row.name);

    return (
      <Box className={classes.staffNameContainer}>
        {Icon && <Icon />}
        <Typography style={{ marginLeft: 16 }} customVariant="bodySmallRegular">
          {row.name}
        </Typography>
      </Box>
    );
  };

  const selectionColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 12,
      render: renderStaffName,
    },
  ];

  const selectedColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 10,
      render: renderStaffName,
    },
    {
      field: 'quantity',
      headerName: 'quantity',
      width: 2,
      render: (row) => (
        <AddRemove
          small
          noMarginBot
          value={getValue(values.requiredStaff, row.id)}
          onChange={({ target }) => {
            const newRequiredStaff = [...values.requiredStaff];

            const staffIndex = getIndexBy(values.requiredStaff, 'id:', row.id);

            let newStaff;

            if (!staffIndex && staffIndex !== 0) {
              newStaff = { id: row.id, quantity: target.value };
              newRequiredStaff.push(newStaff);
            } else {
              const oldStaff = values.requiredStaff[staffIndex] || {};
              newStaff = { ...oldStaff, quantity: target.value };
              newRequiredStaff.splice(staffIndex, 1, newStaff);
            }
            setFieldValue('requiredStaff', newRequiredStaff);
          }}
        />
      ),
    },
  ];

  // const customHandleDelete = (setSelectedItems) => (row) => {
  //   const newIds = values.productIds.filter((id) => id !== row.id);
  //   const newProducts = values.products.filter(
  //     (product) => product.id !== row.id
  //   );

  //   setFieldValue('products', newProducts);
  //   setFieldValue('productIds', newIds);
  //   setSelectedItems(newIds);
  // };

  return (
    <ListModal
      title="Select staff needed"
      searchPlaceholder="Search for staff type"
      // customHandleDelete={isUpdate ? customHandleDelete : null}
      mainAction={{
        label: 'Done',
        onClick: (items) => {
          const itemsWithQuantity = items.map((item) => {
            if (!item.quantity && item.quantity !== 0)
              return { ...item, quantity: 1 };
            return item;
          });
          setFieldValue('requiredStaff', itemsWithQuantity);
        },
      }}
      error={error}
      data={staffOptions}
      incomingItems={values.requiredStaff.map((staff) => staff.id)}
      columns={selectionColumns}
      renderSelectionColumns={selectedColumns}
      disableQuery
      onModalClick={(toggleModal) => (
        <SelectActionButton onClick={toggleModal}>
          Select required staff
        </SelectActionButton>
      )}
    />
  );
};

export default withRouter(Container);
