import React from 'react';
import ProgressBar from 'components_v2/Highlight/components/ProgressBar';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StarRating from 'components_v2/StarRating/StarRating';
import { mobileViewBreakpoint } from '../../../App.helper';

const useStyles = makeStyles(() => ({
  mobileViewWrapper: {
    minHeight: '81px',
    width: '100%',
    padding: '16px',
    '& > span': {
      fontSize: '14px',
      lineHeight: '134.3%',
      color: 'rgba(0, 0, 0, 0.9)',
    },

    '& > svg': {
      marginRight: '6px',
    },
  },
  statusRecordMobileView: {
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center',
    '& > span': {
      marginRight: '10px',
    },
    '& > div': {
      flex: 1,
    },
  },
  statusRecord: {
    display: 'flex',
    margin: '16px 24px',
    alignItems: 'center',

    '&:first-child': {
      marginTop: '34px',
    },

    '&:last-child': {
      marginBottom: '34px',
    },

    '& > div': {
      flex: 1,
    },
  },
}));

const StatusGraph = ({ data, rating }) => {
  const classes = useStyles();
  const theme = useTheme();
  const progressRate = (rating / 5) * 100;

  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));
  return (
    <>
      {(!isMobileView && (
        <div>
          {_.map(data, (entry, index) => (
            <div key={index} className={classes.statusRecord}>
              {entry.type === 'star' && (
                <StarRating direction="right" rating={entry.name} />
              )}
              <ProgressBar percent={entry.value} />
            </div>
          ))}
        </div>
      )) || (
          <div className={classes.mobileViewWrapper}>
            <span>Rides Ratings</span>

            <div className={classes.statusRecordMobileView}>
              <StarRating direction="left" rating={rating} />

              <ProgressBar percent={progressRate} />
            </div>
          </div>
        )}
    </>
  );
};

export default StatusGraph;
