import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";

import Create from "gql/Departments/Create";
import Delete from "gql/Departments/Delete";
import Update from "gql/Departments/Update";
import DynamicList from "components_v2/List/DynamicList";
import Loading from "components_v2/Loading/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MenuFolder } from "components_v2/Icons/Icons";
import { mobileViewBreakpoint } from "../../../../App.helper";
import { getFilteredDepartments, getSortedDepartments } from "./helper";

const DepartmentSettings = ({
  departments,
  moduleId,
  projectId,
  refetchModules
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const [deleteDepartment, { loading: deleteDepartmentLoading }] = useMutation(
    Delete
  );

  const [updateDepartment, { loading: updateDepartmentLoading }] = useMutation(
    Update
  );

  const [createDepartment, { loading: createDepartmentLoading }] = useMutation(
    Create
  );

  const [filterKey, setFilterKey] = useState("");
  const [sort, setSort] = useState(null);

  const loading =
    deleteDepartmentLoading ||
    updateDepartmentLoading ||
    createDepartmentLoading;

  let finalData = getFilteredDepartments({ departments, filterKey });
  finalData = getSortedDepartments({ departments: finalData, sort });

  return (
    <Box style={{ padding: "24px 40px", position: "relative" }}>
      {loading && (
        <Loading
          fullPage
          backgroundWidth="100%"
          backgroundHeight="100%"
          backgroundPosition="absolute"
        />
      )}
      <DynamicList
        filterActions={[
          {
            type: "text",
            onChange: val => {
              setFilterKey(val);
            },
            label: "Search"
          }
        ]}
        sortActions={{
          currentSort: sort,
          onChangeSort: setSort
        }}
        isMobileView={isMobileView}
        title="Departments"
        noResultsText="No departments"
        smallTitle
        showHeader
        data={finalData}
        numRows={finalData.length}
        columns={[
          {
            field: "name",
            headerName: "Department Name",
            width: 3,
            render: ({ name }) => (
              <section
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <MenuFolder />
                </div>
                <div>{name}</div>
              </section>
            )
          }
        ]}
        actions={{
          edit: {
            onSave: async ({ id, values: { name } }) => {
              await updateDepartment({
                variables: {
                  id: { id },
                  data: {
                    name
                  }
                }
              });

              await refetchModules();
            }
          },
          delete: {
            onClick: async ({ id }) => {
              await deleteDepartment({
                variables: {
                  id: { id: id }
                }
              });

              await refetchModules();
            }
          },
          create: {
            onSave: async ({ values: { name } }) => {
              await createDepartment({
                variables: {
                  data: {
                    name,
                    event: projectId,
                    module: moduleId
                  }
                }
              });

              await refetchModules();
            }
          }
        }}
      />
    </Box>
  );
};

export default DepartmentSettings;
