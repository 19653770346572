import gql from "graphql-tag";

export default gql`
  query GetAllSuppliers($id: ID , $sort: String, $pageSize: Int, $startIndex: Int, $filterKey: String)  {
    suppliersConnection(where: { event: $id
      _or: [
        { name_contains: $filterKey }
        { contactName_contains: $filterKey }
        { email_contains: $filterKey }
        { phone_contains: $filterKey }
        { address_contains: $filterKey }
      ] 
    }
    limit: $pageSize
    start: $startIndex
    sort: $sort
    ) {
      aggregate {
        count
      } 
      values {
      id
      name
      notes
      email
      contactName
      phone
      address
    }
  }
  }
`;
