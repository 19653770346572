import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import GetAllByProject from "gql/DriverRatings/GetAllByProject";
import GetAllByDriver from "gql/DriverRatings/GetAllByDriver";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DynamicList from "../List/DynamicList";
import {
  getColumns,
  actions
} from "./RideRatingsConfig";
import { mockRideRatingListData } from "./Reporting.mock";
import { mobileViewBreakpoint } from "../../App.helper";
import {getFilteredDriveRatings, getSortedDriveRatings} from './helper'
function getGqlQueryParams(
  projectId,
  driverId,
  getAllByProject,
  getAllByDriver
) {
  if (projectId) return { gql: getAllByProject, variables: { id: projectId } };
  if (driverId) return { gql: getAllByDriver, variables: { id: driverId } };
}
const RideRatingsList = props => {
  const { projectId, driverId, pageLoading, isHelpTutorial } = props;
  const { gql, variables } = getGqlQueryParams(
    projectId,
    driverId,
    GetAllByProject,
    GetAllByDriver
  );
  let { data, loading } = useQuery(gql, {
    variables,
    fetchPolicy: "no-cache",
    skip: isHelpTutorial
  });

  if (isHelpTutorial) {
    data = mockRideRatingListData;
  }

  /* 
    Added the pageLoading prop so that two LinearProgress components
     do not stack on top of each other if this component and the page data are loading at the same time
  */
  const theme = useTheme();

  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));

  const { driveratings } = data || {};
  const columns = getColumns(projectId, driverId, isMobileView);
  const [filterKey, setFilterKey] = useState('')
  const [sort, setSort] = useState(null);
  let finalData = getFilteredDriveRatings({ driveRatings: driveratings, filterKey });
  finalData = getSortedDriveRatings({ driveRatings: finalData, sort })
  if (loading && pageLoading) return null;
  return (
    <DynamicList
      isMobileView={isMobileView}
      groupDateField="event.start"
      showHeader
      filterActions={[
        {
          type: "text",
          onChange: (val) => {
            setFilterKey(val)
          },
          label: "Search",
        },
      ]}
      sortActions={{
        currentSort: sort,
        onChangeSort: setSort
      }}
      data={finalData || []}
      title="Ride Ratings"
      noResultsText="No ride ratings"
      numRows={(finalData && finalData.length) || 0}
      columns={columns}
      actions={actions}
      hideActions={isMobileView}
      loading={loading && !pageLoading}
      //hamburgerMenuItems={getHamburgerMenuItems(history)}
      detailPanel={(row) => (
        <div style={{ padding: 16 }}>
          {" "}
          {row.comment ? `"${row.comment}"` : "No rating provided"}
        </div>
      )}
    />
  );
};
RideRatingsList.propTypes = {
  driverId: PropTypes.string,
  projectId: PropTypes.string,
  pageLoading: PropTypes.bool
};
export default withRouter(RideRatingsList);
