import momentTimezone from 'moment-timezone';
import moment from 'moment';

export const getTimezoneOffset = (val, timezone) => {
  const fullDate =
    (timezone &&
      momentTimezone(val || undefined)
        .tz(timezone)
        .format()) ||
    moment(val || undefined).format();

  return fullDate.substring(19);
};

export const getSelectedDateRange = (fullDate) => {
  const currentDayOfWeek = parseInt(moment(fullDate || undefined).format('d'));

  const daysUntilEndOfWeek = 6 - currentDayOfWeek;

  const startOfWeek = moment(fullDate || undefined)
    .subtract(currentDayOfWeek, 'days')
    .format('D MMM');
  const endOfWeek = moment(fullDate || undefined)
    .add(daysUntilEndOfWeek, 'days')
    .format('D MMM');

  return `${startOfWeek} - ${endOfWeek}`;
};
