import * as Yup from 'yup';

import {
  checkIfEmailExists,
  checkIfEmailValid,
  checkIfUpdatedEmailExists,
} from 'utils/validationHelpers';
import {
  formatChangedFormValues,
  formatValues,
  addPasswordChangeValues,
} from 'utils/formHelpers';
import asyncDebouncer from 'utils/asyncDebouncer';


const getCustomPasswordValidation = (isUpdate) => {
  if (isUpdate) return Yup.string().when(['generatePassword', 'updatePassword'], {
    is: (generatePassword, updatePassword) =>
      !generatePassword && updatePassword,
    then: Yup.string()
      .min(6, 'Must be at least 6 characters')
      .required('Required'),
    otherwise: Yup.string(),
  });

  return undefined;
};

const getSendPasswordEmailValidation = (isUpdate) => {
  if (isUpdate) return Yup.string().when(['willSendPassword', 'updatePassword'], {
    is: (willSendPassword, updatePassword) =>
      willSendPassword && updatePassword,
    then: Yup.string().email('Email is invalid').required('Required'),
    otherwise: Yup.string(),
  });
  
  return undefined;
};

export const validationSchema = (isUpdate) =>
  Yup.object().shape({
    firstName: Yup.string().required('Required').min(2, 'Too short!'),
    lastName: Yup.string().required('Required').min(2, 'Too short!'),
    email: isUpdate
      ? Yup.string()
          .required('Required')
          .test('Check Valid Email', 'Email is invalid', checkIfEmailValid)
          .when('uneditedEmail', (uneditedEmail, schema) => schema.test(
            'Email Already Exists',
            'This email is already in use',
            asyncDebouncer((value) => checkIfUpdatedEmailExists(value, uneditedEmail), 400)
          ))
      : Yup.string()
          .required('Required')
          .test('Check Valid Email', 'Email is invalid', checkIfEmailValid)
          .test(
            'Email Already Exists',
            'This email is already in use',
            asyncDebouncer((value) => checkIfEmailExists(value), 400)
          ),
    customPassword: getCustomPasswordValidation(isUpdate),
    sendPasswordEmail: getSendPasswordEmailValidation(isUpdate),
  });

export const formatSubmissionValues = (values, initialValues, isUpdate) => {
  const formatConfig = {
    firstName: null,
    lastName: null,
    email: null,
    company: null,
    blocked: null,
    isSuperAdmin: null,
    profileImage: null,
  };

  if (isUpdate && !values.updatePassword)
    return formatChangedFormValues(values, initialValues, formatConfig);

  if (isUpdate && values.updatePassword) {
    const changed = formatChangedFormValues(
      values,
      initialValues,
      formatConfig
    );
    return addPasswordChangeValues(changed, values);
  }

  // Create driver format config additions
  formatConfig.role = null;
  formatConfig.password = null;
  const formatted = formatValues(values, formatConfig);
  formatted.username = Date.now().toString();
  return formatted;
};

export const formatIncomingData = (data) => ({
  ...data,
  generatePassword: true,
  willSendPassword: true,
  requirePasswordChange: true,
  uneditedEmail: data.email,
});
