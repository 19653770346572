import React from 'react';
import PropTypes from 'prop-types';

import RideRatingsList from 'components_v2/Reporting/RideRatingsList';
import Tabs from 'components_v2/Tabs/Tabs';
import Typography from 'components_v2/Typography/Typography';
import ServiceTrackingContainer from '../../../ServiceTracking/ServiceTracking.Container';
import ViewDriverContainer from '../ViewDriver/ViewDriver.Container';
import { Edit } from '../../../Icons/Icons';

const MainContainer = ({isInlineView, data, selectedTab, onChangeTab, driverId }) => (
  <>
 
    {!isInlineView && <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '275px',
      }}
    >
      <Typography customVariant="h2Bold">
      
        {`${data.fullName}`}
      </Typography>
      <a
        onClick={() => onChangeTab('edit')}
        style={{ cursor: 'pointer', fill: '#ccc' }}
      >
        <Edit />
      </a>
    </div>}
    <Tabs
      noBg
      scrollable
      noPadding
      selectedTab={selectedTab}
      onChangeTab={onChangeTab}
      tabs={[
        {
          label: 'Basic Information',
          slug: 'information',
          component: (
            // <div
            //   style={{ marginTop: 24 }}
            // >{`${data.firstName} ${data.lastName}`}</div>
            <ViewDriverContainer driverId={driverId} />
          ),
        },
        {
          label: 'Tracking',
          slug: 'tracking',
          component: (
            <div style={{ marginTop: 24 }}>
              <ServiceTrackingContainer id={driverId} trackDriver />
            </div>
          ),
        },
        {
          label: 'Ratings',
          slug: 'ratings',
          component: (
            <div style={{ marginTop: 24 }}>
              <RideRatingsList driverId={driverId} />
            </div>
          ),
        },
      ]}
    />
  </>
);

MainContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedTab: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired,
};

export default MainContainer;
