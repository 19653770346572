import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from 'components_v2/Tooltip/Tooltip';
import InputLabel from '../InputLabel/InputLabel';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    background: 'rgba(0, 0, 0, 0.05)',
  },
  button: {
    border: 'none',
    background: 'transparent',
    borderRadius: '6px',
    padding: 0,
    height: '40px',
    width: '40px',
    '&:hover': {
      background: 'transparent',
      '& > span > span > svg > path': {
        fill: '#4001C5',
      },
    },

    '&.Mui-selected': {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',

      '&:hover': {
        backgroundColor: '#FFFFFF',
      },

      '& > span > span > svg > path': {
        fill: '#4001C5',
      },
    },

    '& > span > span': {
      display: 'flex',
      padding: '11px',
    },

    '&:not(:first-child)': {
      marginLeft: 0,
    },
  },
}));

const ToggleIconButton = ({
  name,
  value,
  options,
  onChange,
  label,
}) => {
  const classes = useStyles();

  return (
    <>
      <InputLabel label={label} />
      <ToggleButtonGroup
        className={classes.buttonGroup}
        value={value}
        exclusive
        onChange={
          // TODO: NEED TO REVISIT
          (event, value) =>
            onChange({
              persist: () => {},
              target: {
                type: 'change',
                name,
                value,
              },
            })
        }
      >
        {options.map((option, index) => {
          const Icon = option.icon;

          return (
            <ToggleButton
              key={index}
              value={option.value}
              className={classes.button}
              disabled={value === option.value}
            >
              <Tooltip title={option.title}>
                <Icon />
              </Tooltip>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </>
  );
};

export default ToggleIconButton;
