export const counterGraphData = [{
  name: 'No Show Drivers',
  value: '2',
  isHighlighted: true
},
{
  name: 'No Show Passengers',
  value: '2',
},
{
  name: 'Client Complaint',
  value: '1',
}]