import { NetworkStatus } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/react-hooks';
import FAB from 'components_v2/Buttons/FAB/FAB';
import { FloatingActionNewProject } from 'components_v2/Icons/Icons';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import Loading from 'components_v2/Loading/CircularProgress';
import ProjectList from 'components_v2/ProjectList/ProjectList';
import Scheduler from 'components_v2/Scheduler/Scheduler.Container';
import GetAll from 'gql/Events/GetAll';
import UpdateEvent from 'gql/Events/Update';
import moment from 'moment';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ProjectsView from './Projects.View';

const Projects = (props) => {
  const { data, loading, refetch, networkStatus } = useQuery(GetAll, {
    variables: {},
    // fetchPolicy: 'no-cache',
  });

  const [
    updateProject,
    { loading: updateMutationLoading },
  ] = useMutation(UpdateEvent);

  const { match, history } = props;
  const { selectedTab } = match.params;

  const handleTabChange = (value) => {
    history.push(`/projects/${value}`);
  };

  const handleProjectRowClick = (urlToProjectDetails) => {
    history.push(urlToProjectDetails);
  };

  const handleArchiveClick = async (row, status) => {
    await updateProject({
      variables: {
        id: { id: row.id },
        data: {
          status,
        },
      },
      notifyOnNetworkStatusChange: true,
    });
    await refetch();
  };

  const [projectSelectorLoading, setProjectSelectorLoading] = useState(true);

  const isProjectsLoading =
    loading ||
    updateMutationLoading ||
    projectSelectorLoading ||
    networkStatus === NetworkStatus.refetch;

  /* REVISIT THIS - not sure why we added it
  const events =
    data && data.events ? data.events.filter((x) => x.type != 'corporate') : []; */

  const events = data?.events ? data.events : [];

  const currentDate = moment();

  // History events - either archived or the current date is after the end date and status is live
  const filterArchived = (event) => event.status === 'Archived';
  const archivedEvents = events.filter(filterArchived);
  const archivedCount = archivedEvents.length;

  const filterHistory = (event) =>
    moment(currentDate).isAfter(event.end, 'day') &&
    event.status !== 'Archived' &&
    event.status !== 'Draft';
  const historicEvents = events.filter(filterHistory);
  const historicCount = historicEvents.length;

  // Upcoming events - not archived and current date is before the start date or status is draft
  const filterUpcoming = (event) =>
    (event.status !== 'Archived' &&
      moment(currentDate).isBefore(event.start, 'day')) ||
    event.status === 'Draft';
  const upcomingEvents = events.filter(filterUpcoming);
  const upcomingCount = upcomingEvents.length;

  // Current events - not archived, not draft and the current date is between the start and end date, inclusive
  const filterCurrent = (event) =>
    event.status !== 'Archived' &&
    event.status !== 'Draft' &&
    moment(currentDate).isBetween(event.start, event.end, 'day', []);
  const currentEvents = events.filter(filterCurrent);
  const currentCount = currentEvents.length;

  const [selectedDate, setSelectedDate] = useState(moment.utc(new Date()));

  const handleDateChange = ({ target }) => {
    const { value } = target;
    setSelectedDate(value);
  };

  const tabs = [
    {
      label: `Schedule`,
      slug: 'schedule',
      component: (
        <div style={{ marginTop: 40 }}>
          <Scheduler
            title={
              selectedDate ? moment.utc(selectedDate).format('MMMM YYYY') : ''
            }
            numItems={events ? events.length : 0}
            label="Projects"
            data={events}
            type="project"
            defaultView="month"
            view="month"
            currentDate={selectedDate}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
        </div>
      ),
    },
    {
      label: `Upcoming (${upcomingCount})`,
      slug: 'upcoming',
      component: (
        <div style={{ marginTop: 40 }}>
          <ProjectList
            events={upcomingEvents}
            title="Upcoming"
            selectedTab={selectedTab}
            onProjectRowClick={handleProjectRowClick}
            loading={isProjectsLoading}
          />
        </div>
      ),
    },
    {
      label: `Current (${currentCount})`,
      slug: 'current',
      component: (
        <div style={{ marginTop: 40 }}>
          <ProjectList
            events={currentEvents}
            title="Current"
            selectedTab={selectedTab}
            onProjectRowClick={handleProjectRowClick}
            loading={isProjectsLoading}
          />
        </div>
      ),
    },
    {
      label: `History (${historicCount})`,
      slug: 'history',
      component: (
        <div style={{ marginTop: 40 }}>
          <ProjectList
            events={historicEvents}
            title="History"
            selectedTab={selectedTab}
            onProjectRowClick={handleProjectRowClick}
            loading={isProjectsLoading}
            onArchiveClick={(row) => handleArchiveClick(row, 'Archived')}
          />
        </div>
      ),
    },
    {
      label: `Archived (${archivedCount})`,
      slug: 'archived',
      component: (
        <div style={{ marginTop: 40 }}>
          <ProjectList
            events={archivedEvents}
            title="Archived"
            selectedTab={selectedTab}
            onProjectRowClick={handleProjectRowClick}
            loading={isProjectsLoading}
            onRestoreClick={(row) => handleArchiveClick(row, 'Draft')}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {isProjectsLoading && <Loading fullPage />}
      <FAB
        label="Add project"
        icon={FloatingActionNewProject}
        onClick={() => {
          history.push('/project/new');
        }}
      />

      <ProjectsView
        loading={isProjectsLoading}
        tabs={tabs}
        selectedDate={selectedDate}
        selectedTab={selectedTab}
        data={data}
        handleTabChange={handleTabChange}
        loading={loading}
        setProjectSelectorLoading={setProjectSelectorLoading}
      />
    </>
  );
};

export default withMainLayout('Projects', {}, withRouter(Projects));
