import gql from "graphql-tag";

export default gql`
  query GetAllPassengerSchedules($id: ID) {
    passengerschedules(where: { event: $id }) {
      id
      start
      stop
      notes
      numberOfVehicles
      numPassengers
      bookingId
      productNotes
      type
      event {
        id
        name
      }
      createdBy {
        id
        firstName
        lastName
        email
        phone
      }
      requiredVehicleClass {
        id
        name
        transferPricing
        trackTransferPricing
        fourHourPricing
        sixHourPricing
        twelveHourPricing
        twentyFourHourPricing
        maxPassengers
      }
      department {
        id
        name
        module {
          id
          name
        }
      }
      passenger {
        id
        firstName
        lastName
        email
        phone
      }
      from {
        id
        name
        address
        lat
        long
      }
      to {
        id
        name
        address
        lat
        long
      }
      driverschedules {
        id
        vehicle {
          id
          vehicleNumber
          make
          model
          year
          plateNumber
        }
        driver {
          id
          firstName
          lastName
          identifier
          phone
        }
        ridestatuses {
          id
          status
          created_at
          location
        }
      }
    }
  }
`;
