export const setWidth = ({ fullScreen, width }) => {
    return fullScreen ? "100%" : width
}
export const setHeight = ({ fullScreen, mapHeight }) => {
    return fullScreen ? "100%" : mapHeight
}
export const getRideStatues=({driverSchedule})=>{
    return driverSchedule?.ridestatuses.find((x) => x.status === "complete")}
export const getIsLoading=({loading ,driversLoading})=>{
    return !loading && !driversLoading}
export const getWidthHeight=({width,height})=>{
return width && height
}
export const getDriverSchedule=({driverSchedule})=>{
    return driverSchedule ? driverSchedule.driver.id : null
    }