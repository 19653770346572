import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import moment from "moment";
import qs from "query-string";
import { withRouter } from "react-router-dom";

import React, { useState } from "react";
import UpdateDriverSchedule from "gql/DriverSchedules/Update";
import { NetworkStatus } from "@apollo/client";
import useChat from "hooks/useChat";
import GetBookingsByEvent from "./Gql/GetAllByEvent";
import BookingComponent from "./Booking.Component";
import { mockGetAllUpcomingByProject } from "./Booking.mock";
import UpdatePassengerSchedule from "../../gql/PassengerSchedules/Update";
import { mapObject } from "utils/objectHelpers";

function upsert(array, item) {
  // (1)
  const i = array.findIndex(_item => _item.id === item.id);
  // eslint-disable-next-line no-param-reassign
  if (i > -1) array[i] = item;
  // (2)
  else array.push(item);
}

const generateRidesListNew = passengerschedules => {
  const allRides = [];

  if (passengerschedules?.length) {
    passengerschedules.map(schedule => {
      allRides.push({
        ...schedule,
        ...((schedule?.driverschedules?.length &&
          schedule?.driverschedules[0]) ||
          []),
        passengerScheduleAlerts:
          (schedule?.driverschedules?.length &&
            schedule?.driverschedules[0]?.alerts) ||
          [],
        passengerScheduleId: schedule?.id,
        passengerScheduleCancelled: schedule?.cancelled,
        driverScheduleCancelled:
          schedule?.driverschedules?.length &&
          schedule?.driverschedules[0]?.cancelled
      });
    });
  }

  return allRides;
};

const Container = ({
  type,
  match,
  location,
  projectId,
  withMapView,
  isTimezoneVisible,
  isMapStatic,
  isHelpTutorial,
  selectedModule,
  startDate,
  onUpdateBookings,
  userEventIds
}) => {
  const parsedSearch = qs.parse(location.search);

  const searchOptions = mapObject(parsedSearch, (key, value) => {
    if (key === "passengers") return value.split(',').map(val => parseInt(val));
  });

  const [totalCount, setTotalCount] = React.useState(0);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [results, setResults] = React.useState([]);
  const [updatingBookingRecords, setUpdatingBookingRecords] = React.useState(
    []
  );
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [listConfig, setListConfig] = useState({
    sort: type === "History" ? "start:desc" : "start:asc",
    sortHeader: "Pickup time",
    pageSize: 10,
    startIndex: 0,
    filterKey: "",
    count: 0
  });

  const bookingId = match.params.bookingId;

  useChat("bookingUpdates", async ({ driverSchedules, passengerSchedules }) => {
    if (driverSchedules && passengerSchedules) {
      setUpdatingBookingRecords(driverSchedules);
      onUpdateBookings && onUpdateBookings();
      await getNewDriverSchedules({
        variables: {
          passengerSchedules,
          limit: passengerSchedules.length,
          filterKey: ""
        }
      });
    }
    // when bookings update we need to refetch
  });
  // useChat(
  //   "bookingCreate",
  //   async ({ driverSchedules }) => {
  //     setUpdatingBookingRecords(driverSchedules);
  //     onUpdateBookings && onUpdateBookings()
  //     await getNewDriverSchedules({
  //       variables: {
  //         driverScheduleIds: driverSchedules,
  //         limit: driverSchedules.length
  //       }
  //     });

  //     // when bookings Create we need to refetch
  //   }
  // );

  // lazy query for updating schedules
  const [
    getNewDriverSchedules,
    { loading: bookingUpdatesLoading, data: lazyData }
  ] = useLazyQuery(GetBookingsByEvent, { fetchPolicy: "no-cache" });

  React.useEffect(() => {
    if (lazyData && bookingUpdatesLoading === false) {
      const newResults = [...results];

      for (const schedule of lazyData?.passengerschedulesConnection?.values) {
        upsert(newResults, schedule);
      }

      // const newResults = [...results, ...data.passengerschedules];
      setResults(newResults);
    }
  }, [lazyData]);

  React.useEffect(() => {
    if (results?.length) {
      setUpdatingBookingRecords(
        _.map(lazyData?.allBookings?.bookings, _.property("id:"))
      );
      setTimeout(() => {
        setUpdatingBookingRecords([]);
      }, 5000);
    }
  }, [JSON.stringify(results)]);

  const { pageSize, startIndex, filterKey, sort } = listConfig;

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  React.useEffect(() => {
    setStart(
      type === "History" || bookingId
        ? undefined
        : moment
            .utc()
            .subtract(1, "days")
            .toISOString()
    ); // if it's the history tab or a booking id is present then don't filter starting today - I would want to look at older bookings
    setEnd(type === "History" ? moment.utc().toISOString() : undefined);
  }, []);

  let { data, loading, refetch, networkStatus } = useQuery(GetBookingsByEvent, {
    variables: {
      event: projectId || undefined,
      userEvents: userEventIds || undefined,
      pageSize,
      startIndex,
      start,
      end,
      bookingId: bookingId || undefined,
      module: selectedModule !== 0 ? selectedModule : undefined,
      sort,
      filterKey,
      ...searchOptions
    },
    fetchPolicy: "no-cache",
    // pollInterval: 10000,
    skip: isHelpTutorial,
    notifyOnNetworkStatusChange: true
  });

  const [{ loading: updatePSLoading }] = useMutation(UpdatePassengerSchedule);

  React.useEffect(() => {
    setResults([]);
  }, [startDate, projectId, selectedModule]);

  React.useEffect(() => {
    if (data && loading === false) {
      if (results.length === 1) {
        // Empty block
      }

      const { aggregate, values } = { ...data?.passengerschedulesConnection };

      setTotalCount(aggregate?.count || 0);
      setResults(values || []);

      setInitialLoading(false);
    }
  }, [JSON.stringify(data)]);

  if (isHelpTutorial) {
    data = mockGetAllUpcomingByProject;
  }

  const [updateDriverSchedule, { loading: updateLoading }] = useMutation(
    UpdateDriverSchedule
  );

  const onDeleteLoading = () => {
    setIsDeleteLoading(isDeleteLoading);
  };

  return (
    <>
      <BookingComponent
        updatingBookingRecords={updatingBookingRecords}
        isMapStatic={isMapStatic}
        loading={
          loading ||
          updateLoading ||
          updatePSLoading ||
          networkStatus === NetworkStatus.refetch ||
          isDeleteLoading
        }
        setListConfig={setListConfig}
        listConfig={listConfig}
        data={generateRidesListNew(results)}
        totalRows={totalCount}
        type={type}
        withMapView={withMapView}
        projectId={projectId}
        isTimezoneVisible={isTimezoneVisible}
        isHelpTutorial={isHelpTutorial}
        refetchPassengerSchedules={refetch}
        updateDriverSchedule={updateDriverSchedule}
        initialLoading={initialLoading}
        updateCancelStatus={() => {}}
        onDelete={async () => {
          await refetch();
          setIsDeleteLoading(false);
        }}
        onDeleteLoading={onDeleteLoading}
      />
    </>
  );
};

export default withRouter(Container);
