import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AccordionDetails from 'components_v2/Accordion/AccordionDetails';
import AccordionSummary from 'components_v2/Accordion/AccordionSummary';
import AccordionWrapper from 'components_v2/Accordion/AccordionWrapper';
import Typography from 'components_v2/Typography/Typography';

const useStyles = makeStyles({
  root:{
    '& .MuiCollapse-entered': {
      marginBottom: '8px',
    },
  },
});

export default function Accordion({
  summary,
  summaryComponent,
  contentHeight,
  children,
  id,
  size,
  noPadding,
  defaultExpanded,
  relativeWrapper
}) {
  const classes = useStyles({ size, relativeWrapper });
  const ariaControls = `${id}-content`;
  const summaryId = `${id}-header`;

  const details =
    typeof children === 'string' ? (
      <Typography customVariant="bodyLargeRegular">{children}</Typography>
    ) : (
      <div style={{ width: '100%', height: contentHeight || 'auto' }}>
        {children}
      </div>
    );

  return (
    <AccordionWrapper
      defaultExpanded={defaultExpanded}
      className={classes.root}
      relativeWrapper={relativeWrapper}
    >
      <AccordionSummary
        summary={summary}
        ariaControls={ariaControls}
        summaryId={summaryId}
        summaryComponent={summaryComponent}
      />
      <AccordionDetails noPadding={noPadding}>{details}</AccordionDetails>
    </AccordionWrapper>
  );
}
