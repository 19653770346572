export const getIsTutorialHomeDashboardPage = (history) =>
  history.location.pathname === '/help/dashboard';

export const getIsTutorialProjectDashboardPage = (history) =>
  history.location.pathname === '/help/project/5f270baecc54999de0a9f579';

export const getIsTutorialPage = (history) =>
  getIsTutorialHomeDashboardPage(history) ||
  getIsTutorialProjectDashboardPage(history);
export const editActionButtonSelector = "edit-action-button_";
export const deleteActionButtonSelector = "delete-action-button_";

export const mobileViewBreakpoint = 'md';
export const smallMobileViewBreakpoint = 'sm';
export const extraSmallMobileBreakpoint = 'xs';

export const eventStopPropagation = ({ event }) => event?.stopPropagation()