import gql from 'graphql-tag';

export default gql`
  mutation DeleteModule($id: InputID!) {
    deleteModule(input: { where: $id }) {
      module {
        id
      }
    }
  }
`;
