import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import _ from 'lodash';

import DocumentUpload from 'components_v2/Upload/UploadDocument';
import Typography from 'components_v2/Typography/Typography';
import Loading from 'components_v2/Loading/CircularProgress';
import useStyles from './DetailPanel.style.js';

const DetailPanel = ({ data, refetch, updateRecord, loading }) => {
  const parsedDocuments = data.documents ? JSON.parse(data.documents) : [];
  const classes = useStyles();

  const [savedDocuments, setSavedDocuments] = useState([]);
  const [hideAcceptedFiles, setHideAcceptedFiles] = useState(false);

  const stringifiedSavedDocuments = JSON.stringify(savedDocuments);

  const update = async (data, id, refetch) => {
    await updateRecord({
      variables: {
        id: { id },
        data,
      },
    });
    setHideAcceptedFiles(true);
    await refetch();
  };

  const formatDataForSubmission = (documents) => ({
    dateOfService: data.dateOfService,
    description: data.description,
    type: data.type.value,
    vehicle: data.vehicle ? data.vehicle.id : undefined,
    cost: data.cost,
    documents,
  });

  const getAllDocuments = (savedDocuments, parsedDocuments) => {
    const formattedSavedDocuments = savedDocuments.map((document) => {
      const keys = _.keys(document).filter((key) => key !== 'total');
      const documentWithoutTotal = _.pick(document, keys);
      return documentWithoutTotal;
    });

    return [...parsedDocuments, ...formattedSavedDocuments];
  };

  useEffect(() => {
    const total = savedDocuments[0] ? savedDocuments[0].total : null;

    if (savedDocuments.length === total) {
      const allDocuments = getAllDocuments(savedDocuments, parsedDocuments);
      const newRecord = formatDataForSubmission(JSON.stringify(allDocuments));
      update(newRecord, data.id, refetch);
    }
  }, [stringifiedSavedDocuments]);

  return (
    <Box className={classes.detailPanelContainer}>
      {loading ? <Loading fullPage /> : null}
      <Typography customVariant="bodySmallRegular" style={{ marginBottom: 16 }}>
        Documents:
      </Typography>
      <DocumentUpload
        setHideAcceptedFiles={setHideAcceptedFiles}
        hideAcceptedFiles={hideAcceptedFiles}
        documents={parsedDocuments}
        onDeleteCallback={async (document) => {
          const newDocuments = parsedDocuments.filter(
            (parsedDocument) => parsedDocument.id !== document.id
          );

          const newRecord = formatDataForSubmission(
            JSON.stringify(newDocuments)
          );
          await update(newRecord, data.id, refetch);
        }}
        onComplete={async (document) => {
          setSavedDocuments((prevState) => [...prevState, document]);
        }}
      />
    </Box>
  );
};

export default DetailPanel;
