export const sortByData = [
  {
    label: 'Critical',
    other: '(02)',
    value: 'critical',
    isHighlighted: true,
  },
  {
    label: 'Major',
    other: '(22)',
    value: 'major',
    isHighlighted: true,
  },
  {
    label: 'Minor',
    other: '(123)',
    value: 'Minor',
    isHighlighted: false,
  },
];
