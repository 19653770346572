import React from "react";
import Vehicle from "components_v2/Vehicle/Vehicle";
import Tooltip from "components_v2/Tooltip/Tooltip";
import { editActionButtonSelector } from "../../../../App.helper";

export const getMobileMenuItems = ({ row, setDeleteConfirmationModalData }) => {
  return {
    left: [
      {
        text: " ",
        onClick: (row) => {
          setDeleteConfirmationModalData({
            recordName: row.name,
            recordId: row.id,
            confirmationType: "delete",
            isNotDeleteAllowed: row.vehicles.length > 0,
            deleteNotAllowedReason:
              "This vehicle class cannot be deleted because it is being used by the vehicles.",
          });
        },
        className: "mobile-actions-delete",
      },
    ],
    right: [
      {
        text: " ",
        onClick: () => {
          const clickedButton = document.querySelector(
            "#" + editActionButtonSelector + row.id + "_vehicle_classes"
          );
          if (clickedButton) clickedButton.click();
        },
        className: "mobile-actions-edit",
      },
    ],
  };
};

export const getColumns = (isMobileView) => [
  // {
  //   headerName: 'Icon',
  //   field: 'icon',
  //   width: 2,
  //   type: 'icon',
  //   options: iconSelectorOptionsData,
  //   render: (data) => (
  //     <IconSelector
  //       readOnly
  //       name="iconSelector1"
  //       options={iconSelectorOptionsData}
  //       value={data.icon}
  //     />
  //   ),
  // },

  {
    field: "name",
    headerName: "Name",
    width: 2,
  },
  {
    field: "type",
    headerName: "Type",
    width: 3,
    options: [
      { value: "Sedan", name: "Sedan" },
      { value: "SUV", name: "SUV" },
      { value: "Van", name: "Van" },
      { value: "Mini-Van", name: "Mini-Van" },
      { value: "Mini-Bus", name: "Mini-Bus" },
      { value: "Sprinter", name: "Sprinter" },
      { value: "Coaster Bus", name: "Coaster Bus" },
      { value: "Motor Coach", name: "Motor Coach" },
      { value: "Luggage Van", name: "Luggage Van" },
      { value: "ADA Van (Handicap)", name: "ADA Van (Handicap)" },
    ],
    render: (data) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {" "}
        <Tooltip title={data.type}>
          <Vehicle type={data.type} customStyle={{ position: "relative" }} />{" "}
        </Tooltip>
      </div>
    ),
  },
  {
    field: "maxPassengers",
    headerName: isMobileView ? "Max pax" : "Max passengers",
    width: 3,
    type: "number",
    render: (data) =>
      data.maxPassengers && (
        <>
          {data.maxPassengers} {isMobileView ? "" : "passenger(s)"}
        </>
      ),
  },

  {
    field: "maxLuggage",
    headerName: "Max luggage",
    width: 3,
    type: "number",
    hideMobile: true,
    render: (data) => data.maxLuggage && <>{data.maxLuggage} small piece(s)</>,
  },
];
