import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import CustomizedBadges from '../Badge/Badge';
import { useStyles } from './MessageCard.styles';

const MessageCard = ({ cardDetails, onChannelClick }) => {

  const classes = useStyles();

  const latestMessage =
    (cardDetails.state.messages &&
      cardDetails.state.messages.length > 0 &&
      cardDetails.state.messages[cardDetails.state.messages.length - 1]) ||
    null;

  return (
    <ListItem
      alignItems="flex-start"
      className={classes.cardItem}
      onClick={(event) => onChannelClick(event, cardDetails)}
    >
      <ListItemAvatar>
        <Avatar alt="Remy Sharp" className={classes.image} />
      </ListItemAvatar>
      <div className={classes.rightContent}>
        <div className={classes.basicInfo}>
          <div className={classes.title}>{cardDetails.data.name}</div>
          <div className={classes.content}>
            {latestMessage && latestMessage.user && latestMessage.user.name && (
              <strong className={classes.from}>
                {latestMessage.user.name}:{' '}
              </strong>
            )}
            {latestMessage && latestMessage.text}
          </div>
        </div>
        <div className={classes.supplementInfo}>
          <div className={classes.time}>
            {' '}
            {latestMessage &&
              moment
                .utc(latestMessage && latestMessage.created_at)
                .format('HH:mm')}
          </div>
          <CustomizedBadges position="top" value={cardDetails.countUnread()} />
        </div>
      </div>
    </ListItem>
  );
};

export default MessageCard;
