import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { useQuery } from "@apollo/react-hooks";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import EventIcon from "@material-ui/icons/DateRange";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import Loading from "components/Loading/LinearProgress";
import GetAllByUser from "gql/Events/GetAllByUser";
import auth from "utils/auth";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 20px",
    backgroundColor: "#4c5260",
    color: "#fff",
    borderRadius: 5
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 175
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class SimpleSelect extends React.Component {
  state = {
    event: localStorage.getItem("selectedEvent"),
    anchorEl: null
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.value === "new") {
      this.props.history.push("/events/new");
      localStorage.setItem("selectedEvent", "");
    } else {
      this.props.history.push("/");
      window.location.reload();

      localStorage.setItem("selectedEvent", event.target.value);
    }
  };

  handleNewChange = id => {
    if (id === "new") {
      this.props.history.push("/events/new");
      localStorage.setItem("selectedEvent", "");
    } else {
      this.props.history.push("/");
      window.location.reload();

      localStorage.setItem("selectedEvent", id);
    }
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    var id = localStorage.getItem("selectedEvent");
    /*
    if (!id) {
      localStorage.setItem("selectedEvent", "5d7d4adf4c62b0124068e240");
      id = "5d7d4adf4c62b0124068e240";
    }*/

    var loggedUserInfo = auth.get("userInfo");

    const { loading, data } = useQuery(GetAllByUser,
      {
        variables: { id: loggedUserInfo.id },
        fetchPolicy: "no-cache",
      });

    if (loading || !data) return <Loading />;

          var eventData = data.user.userEvents.map(userEvent => {
            return { ...userEvent.event };
          });

          eventData = eventData.sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          );

          var eventId = localStorage.getItem("selectedEvent");
          var event = eventData.find(e => e.id === eventId);

          /*
          if (event == null) {
            localStorage.setItem("selectedEvent", eventData[0].id);
            event = eventData[0];
          }*/

          if (eventData.length === 1 || !eventId) {
            // hide the picker for now
            return <span></span>;
          }

          return (
            <>
              {" "}
              <List
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <ListItem alignItems="flex-start" style={{ paddingLeft: 20 }}>
                  <Avatar>
                    <EventIcon />
                  </Avatar>

                  <ListItemText
                    primary={
                      <Typography
                        variant="p"
                        style={{ color: "#ccc", fontSize: 13 }}
                      >
                        {event.name}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          className={classes.inline}
                          color="textPrimary"
                          style={{ color: "#ccc" }}
                        >
                          <small>
                            {moment.utc(event.start).format("DD MMM")}
                            {" - "}
                            {moment.utc(event.end).format("DD MMM")}
                          </small>
                        </Typography>
                      </React.Fragment>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton aria-label="Comments">
                      <ExpandIcon style={{ color: "#ccc" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
                style={{ zIndex: 99999 }}
              >
                <MenuItem disabled value="0">
                  <small>
                    <em>Select a project from the list below</em>
                  </small>
                </MenuItem>

                {eventData.map(
                  event =>
                    (event.id === eventId || !event.archived) && (
                      <MenuItem
                        onClick={() => this.handleNewChange(event.id)}
                        key={event.id}
                        value={event.id}
                        style={{
                          paddingTop: 8,
                          paddingBottom: 8,
                          backgroundColor:
                            event.id === id && !event.archived && "#ccc"
                        }}
                      >
                        <>
                          <span
                            style={{
                              fontWeight:
                                event.id === id && !event.archived && "bold",
                              fontStyle: event.archived && "italic",
                              color: event.archived && "gray",
                              fontSize: 12
                            }}
                          >
                            {event.name}
                          </span>
                        </>
                      </MenuItem>
                    )
                )}

                <Divider />
                <MenuItem
                  value={"new"}
                  onClick={() => this.handleNewChange("new")}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      <span style={{ fontSize: 12 }}>Create New Project</span>
                    }
                  />
                </MenuItem>
              </Menu>
            </>
          );
  }
}

export default withRouter(withStyles(styles)(SimpleSelect));
