import moment from "moment";
import { getFilteredData } from "../List/helper";

export const formatData = (type, data) => {
  if (type === "project")
    return data.map(({ name, start, end, id }) => ({
      title: name,
      startDate: start,
      endDate: end,
      id
    }));
  return data.map(({ passenger, start, ...otherFields }) => ({
    title: passenger
      ? `${passenger.firstName || ""} ${passenger.lastName || ""}`
      : "",
    start,
    startDate: start,
    passenger,
    ...otherFields
  }));
};

export const getAppointmentsForCell = (appointments, startDate, endDate) =>
  appointments
    .filter(appointment => {
      const startDateTime = moment(startDate).format("YYYY-MM-DD:HH:mm:ss");
      const endDateTime = moment(endDate).format("YYYY-MM-DD:HH:mm:ss");
      const appointmentDateTime = moment
        .utc(appointment.startDate)
        .format("YYYY-MM-DD:HH:mm:ss");

      if (
        appointmentDateTime >= startDateTime &&
        appointmentDateTime < endDateTime
      )
        return (
          appointmentDateTime >= startDateTime &&
          appointmentDateTime < endDateTime
        );
    })
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

export const formatTimeScaleDate = date => moment(date).format("H:mm");
export const formatDayScaleDate = date => moment(date).format("dddd DD");

export const appointmentBaseStyles = {
  width: "100%",
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  padding: 8,
  borderRadius: 6,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    backgroundColor: "#fff"
  },
  "&:hover>div>div": {
    color: "#000"
  },
  "&>div:first-child": {
    boxShadow: "none"
  },
  "&>div:last-child": {
    boxShadow: "none"
  },
  "&>div>div": {
    color: "#000"
  }
};

export const otherAppointmentsBaseStyles = {
  borderRadius: 90,
  background: "rgba(64, 1, 197, 0.05)",
  border: "1px solid rgba(0, 0, 0, 0.02)",
  padding: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer"
};

export const getFilteredSchedules = ({ schedules, filterKey }) => {
  return getFilteredData({ data: schedules, filterKey });
};

export const getEventTimezone = ({ eventData }) => {
  return eventData?.events && eventData.events[0]?.timezone;
};
