import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import { useState, useEffect } from "react";
import ReactMapGL, { Popup } from "react-map-gl";
import { getBoundsForPoints } from "utils/mapboxHelpers";
import Typography from "components_v2/Typography/Typography";
import AutoSizer from "components_v2/AutoSizer/AutoSizer";
import LocationMarker from "./Marker/LocationMarker";
import GLMarker from "./Marker/Marker";
import DirectionsRoute from "./DirectionsRoute";

const MarkerMap = props => {
  const {
    markers,
    showRoute,
    displayPopups,
    width = "100%",
    height = "100%",
    onDragEnd,
    draggableMarkers,
    markerType,
    asDirected,
    initialFocusRegion,
    onMapClick
  } = props;

  const [viewport, setViewport] = useState({
    latitude: initialFocusRegion?.lat || 46.500595,
    longitude: initialFocusRegion?.long || 15.749993,
    zoom: 16
  });



  const [duration, setDuration] = useState(0);

  const [showPopup, setShowPopup] = useState(null);

  const markersJSON = JSON.stringify(markers);
  useEffect(() => {
    setViewport(prevState => ({ ...prevState, height, width }));
  }, [height, width]);

  useEffect(() => {
    if (markers.length === 1 || asDirected) {
      const bound = {
        latitude: markers[0].lat,
        longitude: markers[0].long,
        zoom: 16,
        height,
        width
      };
      setViewport(prevState => ({
        ...prevState,
        ...bound,
        height,
        width
      }));
    } else if (markers.length > 1) {
      const bound = getBoundsForPoints(markers);
      setViewport(prevState => ({ ...prevState, ...bound, height, width }));
    }
  }, [markersJSON, markers]);

  const handleOnClick = (data) => {
    if (data) {
      const { latitude, longitude, name, address } = data;
      setShowPopup({ latitude, longitude, name, address });
    } else {
      setShowPopup(null)
    }
  }

  const markerElements = markers.map((marker, index) => (
    <>
      {displayPopups && (
        <Popup latitude={marker.lat} longitude={marker.long} anchor="top" offsetTop={20}>
          <Typography customVariant="bodySmallRegular">
            {marker?.name || ''}
          </Typography>
        </Popup>
      )}
      {markerType === "glMarker" ? (
        <div>
          <GLMarker
            lat={marker.lat}
            long={marker.long}
            onDragEnd={onDragEnd}
            draggable={draggableMarkers}
          />
        </div>
      ) : (
        <LocationMarker
          name={marker.name}
          address={marker.address}
          lat={marker.lat}
          long={marker.long}
          index={index}
          count={markers.length}
          duration={duration}
          asDirected={asDirected}
          onClick={handleOnClick}
        />
      )}
    </>
  ));

  return (
    <>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken="pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA"
        onViewportChange={(nextViewport) =>
          setViewport({
            ...nextViewport,
            height,
            width,
          })
        }
        onClick={(event) => onMapClick?.(event)}
      >
        {showRoute && markers && !asDirected && markers.length > 1 && (
          <DirectionsRoute
            stops={markers.map((marker) => [marker.long, marker.lat])}
            onRouteData={() => { }}
            setDuration={setDuration}
          />
        )}

        {markerElements}
        {showPopup && (
          <Popup latitude={showPopup.latitude} longitude={showPopup.longitude} anchor="top" offsetTop={20}>
            <Typography customVariant="bodySmallRegular">
              <strong>{showPopup.name} </strong>
              <br />
              {showPopup?.address || ''}
            </Typography>
          </Popup>
        )}
      </ReactMapGL>
    </>
  );
};

export default function ResponsiveMap({
  markers,
  showRoute,
  displayPopups,
  width = "100%",
  height = "100%",
  noPadding,
  draggableMarkers,
  onDragEnd,
  markerType,
  asDirected,
  initialFocusRegion,
  onMapClick
}) {
  return (
    <AutoSizer noPadding={noPadding}>
      <MarkerMap
        markers={markers}
        showRoute={showRoute}
        displayPopups={displayPopups}
        width={width}
        height={height}
        draggableMarkers={draggableMarkers}
        onDragEnd={onDragEnd}
        markerType={markerType}
        asDirected={asDirected}
        initialFocusRegion={initialFocusRegion}
        onMapClick={onMapClick}
      />
    </AutoSizer>
  );
}
