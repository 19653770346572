import gql from "graphql-tag";
//
export default gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      whatsapp
      confirmed
      blocked
      languages
      company
      licenseNumber
      nationality
      gender
      passportNumber
      visaNumber
      isSuperAdmin
      identifier
      username
      documents
      notes
      passengerPriority
      profileImage
      profileImageThumbnail
      supervisor
      isPublicDriver
      shift
      breaks
      supplier {
        id
      }
      role {
        id
        name
      }
      userEvents {
        id
        event {
          id
          name
        }
        role
        accessRight {
          id
        }
      }
      assignedVehicles {
        vehicle {
          id
          make
          model
          year
          vehicleNumber
          type
        }
      }
    }
  }
`;
