import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Loading from 'components_v2/Loading/LinearProgress';
import GetOne from 'gql/StoredLocations/GetOne';
import GetAllByParent from 'gql/StoredLocations/GetAllByParent';
import GetOneEvent from 'gql/Events/GetOne';
import withMainLayout from 'components_v2/Layout/withMainLayout';
import AddLocationComponent from './AddLocation.Component';

const AddLocationContainer = ({ match }) => {
  const { locationId, projectId } = match.params;

  const {
    data: parentLocationData = null,
    loading: parentLocationLoading,
    refetch: refetchParentLocation,
  } = useQuery(GetOne, {
    variables: { id: locationId },
    skip: !locationId,
    fetchPolicy: 'no-cache',
  });

  const {
    data: functionalAreasData = null,
    loading: functionalAreasLoading,
    refetch: refetchFunctionalAreas,
  } = useQuery(GetAllByParent, {
    variables: { id: locationId },
    skip: !locationId,
    fetchPolicy: 'no-cache',
  });

  const { data: eventData = null, loading: eventLoading } = useQuery(
    GetOneEvent,
    {
      variables: { id: projectId },
      fetchPolicy: 'no-cache',
      skip: !projectId,
    }
  );

  if (parentLocationLoading || functionalAreasLoading || eventLoading)
    return <Loading />;

  const data = {
    parentLocation: parentLocationData?.storedlocations[0],
    functionalAreas: functionalAreasData?.storedlocations,
  };

  return (
    <AddLocationComponent
      data={data}
      refetchLocation={refetchParentLocation}
      refetchFunctionalAreas={refetchFunctionalAreas}
      projectData={(eventData?.events && eventData.events[0]) || {}}
    />
  );
};

AddLocationContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ locationId: PropTypes.string }),
  }),
};

export default withMainLayout(
  'Add Location',
  {},
  withRouter(AddLocationContainer)
);
