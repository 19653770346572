import { withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import WarningIcon from "@material-ui/icons/Warning";
import Clear from "@material-ui/icons/Clear";
import StepConnector from "@material-ui/core/StepConnector";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import Tooltip from "components_v2/Tooltip/Tooltip";
import clsx from "clsx";
import moment from "moment";
import gql from "graphql-tag";
import { convertRideStatusToLabel } from "../../utils/passengerScheduleHelpers";
import { StepButton } from "@material-ui/core";
import _ from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../App.helper";
import DeleteRideStatus from "../../gql/RideStatus/Delete";
import DeleteRideStatusConfirmationModal from "../Bookings/DeleteRideStatusConfirmationModal/DeleteRideStatusConfirmationModal";
import {
  allSteps,
  getActiveStepIndex,
  getIsCancelled,
  getStatusLabelWithTime
} from "../Bookings/BookingHelper";

const CREATE_GQL = gql`
  mutation createRidestatus($data: RidestatusInput) {
    createRidestatus(input: { data: $data }) {
      ridestatus {
        id
      }
    }
  }
`;

const TimelineStepConnector = withStyles(() => ({
  root: {
    height: "3px",
    borderRadius: "4px",

    "& .MuiStepConnector-line": {
      border: "none"
    },

    background: "rgba(0, 0, 0, 0.1)"
  },
  active: {
    background: "rgba(0, 0, 0, 0.1)"
  },
  completed: {
    background: "rgba(0, 0, 0, 0.9)"
  }
}))(StepConnector);

const VerticalTimelineStepConnector = withStyles({
  root: {
    "& .MuiStepConnector-vertical": {
      padding: "0px 0px 0px"
    }
  }
})(StepConnector);

const stepIconStyles = makeStyles(() => ({
  root: {
    display: "flex",

    "& .MuiSvgIcon-root": {
      fill: "rgba(0, 0, 0, 0.1)",
      "&:hover": {
        fill: "#4001C5"
      }
    }
  },
  active: {},
  completed: {
    "& .MuiSvgIcon-root": {
      fill: "#4001C5"
    }
  },
  error: {
    backgroundColor: "#C40202",
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiSvgIcon-root": {
      fill: "#fff",
      width: "0.6em",
      height: "0.6em"
    }
  }
}));

const StepIcon = ({ completed, active, error }) => {
  const classes = stepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.error]: error
      })}
    >
      {error ? <WarningIcon /> : <FiberManualRecordIcon />}
    </div>
  );
};

const timelineStyles = theme => ({
  root: {
    width: "100%",
    "& .MuiStepButton-root": {
      padding: "4px 4px",
      margin: "-28px -4px",
      width: "2em",
      height: "2em",
      borderRadius: "100%"
    },
    "& .MuiGrid-container": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        display: "flex !important"
      }
    }
  },
  label: {
    "& .MuiStepLabel-iconContainer": {
      padding: "0px",
      cursor: "pointer"
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      "& .MuiStepLabel-label": {
        fontSize: 14,
        display: "flex",
        alignItems: "center"
      }
    }
  },
  stepper: {
    background: "transparent",
    boxShadow: "none",
    "& .MuiStep-root": {
      padding: "0px"
    },
    "& .MuiStepConnector-vertical": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginLeft: "13px",
        padding: "0px",
        paddingTop: "2px"
      }
    },
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: "normal"
    },

    "& .MuiStepButton-root": {
      width: "100%"
    },
    padding: "0px"
  },
  clearWrapper: {
    fill: "#cc4a01"
  },
  gridWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "flex"
    }
  },
  clearGridWrapper: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "flex",
      alignItems: "flex-end"
    }
  },
  notAllowed: {
    cursor: "not-allowed !important",

    "& .MuiStepLabel-iconContainer": {
      cursor: "not-allowed !important"
    }
  },
  clear: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fill: "white",
    cursor: "pointer",
    width: "16px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      top: "unset",
      left: "unset",
      transform: "unset",
      fill: "unset",
      position: "unset",
      marginLeft: 12,
      width: 24
    }
  }
});

const Timeline = ({
  classes,
  completedSteps,
  className,
  data,
  isBooking,
  isHelpTutorial,
  setIsLoading,
  isLoading
}) => {
  const [createTimelineItem, { loading }] = useMutation(CREATE_GQL);

  const [
    deleteRideStatusConfirmation,
    { loading: rideStatusLoading }
  ] = useMutation(DeleteRideStatus);

  const deleteRideStatus = async id => {
    setDeleteRideStatusConfirmationModalData(null);

    await deleteRideStatusConfirmation({
      variables: {
        id: id
      }
    });

    // Refetch bookings
    // await refetchPassengerSchedules();
  };

  const usedTheme = useTheme();
  const isMobileView = useMediaQuery(
    usedTheme.breakpoints.down(mobileViewBreakpoint)
  );

  useEffect(() => {
    if (setIsLoading && typeof setIsLoading === "function") {
      // if (loading === false && rideStatusLoading === false) {
      //   setTimeout(() => {
      //     setIsLoading(loading || rideStatusLoading);
      //   }, 500);
      // }
      // else
      setIsLoading(loading || rideStatusLoading);
    }
  }, [loading, rideStatusLoading, setIsLoading, isLoading]);

  const steps = {
    scheduled: null,
    driverConfirmed: null,
    enroute: null,
    arrivedToPickup: null,
    passengerInVehicle: null,
    arrivedToDestination: null,
    complete: null
  };

  const stepToLabel = (label, created_at) =>
    `${moment(created_at).format("HH:MM")} - ${label}`;

  if (!isHelpTutorial) {
    Object.keys(steps).forEach(status => {
      const completedStep = completedSteps
        .filter(c => c.status === status)
        ?.map(c => ({
          label: stepToLabel(c.label, c.created_at),
          isError: c.isError
        }))[0] || { status, label: convertRideStatusToLabel(status) };

      steps[status] = completedStep;
    });
  }

  const activeStep = getActiveStepIndex(completedSteps);

  const [
    deleteRideStatusConfirmationModalData,
    setDeleteRideStatusConfirmationModalData
  ] = useState(null);

  const handleClickClearStep = ({ status, data }) => {
    if (data?.ridestatuses?.length) {
      setDeleteRideStatusConfirmationModalData({
        recordName: status.label,
        recordId: _.findLast(
          data.ridestatuses,
          rideStatus => status.value === rideStatus.status
        ).id,
        confirmationType: "delete"
      });
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <DeleteRideStatusConfirmationModal
        data={deleteRideStatusConfirmationModalData}
        onClickCancel={() => {
          setDeleteRideStatusConfirmationModalData(null);
        }}
        onClickConfirm={async id => {
          deleteRideStatus(id);
        }}
      />
      <Stepper
        activeStep={activeStep}
        orientation={isMobileView && isBooking ? "vertical" : "horizontal"}
        className={classes.stepper}
        connector={
          isMobileView && isBooking ? (
            <VerticalTimelineStepConnector />
          ) : (
            <TimelineStepConnector />
          )
        }
      >
        {_.map(allSteps, (status, index) => (
          <Step key={status.value}>
            <Tooltip
              nocontainer
              title={getStatusLabelWithTime({ status, completedSteps, data })}
            >
              <StepButton>
                <StepLabel
                  className={clsx(classes.label, {
                    [classes.notAllowed]: index !== activeStep
                  })}
                  error={status && status.isError}
                  StepIconComponent={StepIcon}
                  onClick={async () => {
                    if (
                      data?.vehicle &&
                      data?.driver &&
                      index === activeStep &&
                      !getIsCancelled(data)
                    ) {
                      const saveObject = {
                        driverschedule: data?.id,
                        vehicle: data?.vehicle.id,
                        driver: data?.driver.id,
                        status: status?.value,
                        source: "web"
                        /* location */
                      };

                      await createTimelineItem({
                        variables: {
                          data: saveObject
                        }
                      });
                    }
                  }}
                >
                  {" "}
                  {isMobileView &&
                    isBooking &&
                    getStatusLabelWithTime({ status, completedSteps, data })}
                  {index === activeStep - 1 &&
                    index !== 0 &&
                    isBooking &&
                    !getIsCancelled(data) && (
                      <Clear
                        className={classes.clear}
                        onClick={() => handleClickClearStep({ status, data })}
                      />
                    )}
                </StepLabel>
              </StepButton>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withStyles(timelineStyles)(Timeline);
