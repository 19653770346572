import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Modal from "components_v2/Modal/Modal";
import Typography from "components_v2/Typography/Typography";
import { getPathWithoutStep } from "utils/routerHelpers";
import PopoverForm from "./PopoverForm/PopoverForm";
import useStyles from "./FullScreenPopover.style";
import CIB from "../Buttons/CIB/CIB";
import { ArrowsChevronLeft, Clear } from "../Icons/Icons";
import { useRouteData } from "context/route-data.context";
import withFormState from "containers/Wrappers/withFormState";

const FullScreenPopover = ({
  children,
  currentStep,
  disableLayout,
  disableStepper,
  handleClose,
  handleBack,
  hideHeaderButtons,
  history,
  location,
  // match,
  mainAction,
  numberOfSteps,
  onSubmit,
  secondaryAction,
  setCurrentStep,
  title,
  subtitle,
  pageView,
  // useHandleClose
  getPreviousFormState,
  removeFormStateItem,
}) => {
  const classes = useStyles();
  const previousFormState = getPreviousFormState(location);

  useEffect(() => {
    if (location?.state?.removeRecent)removeFormStateItem();
  }, []);

  const { routeData: { prevPath } } = useRouteData();

  const handleBackClick = () => {
    const previousFormState = getPreviousFormState(location);
    
    if (
      previousFormState &&
      previousFormState?.fromPath
    )
      history.replace({
        pathname: previousFormState.fromPath,
        state: { hasState: true, removeRecent: true }
      });
    else if (handleBack || !numberOfSteps || currentStep === 1) {
      
      if (handleBack) handleBack();
      else if (!prevPath) history.replace("/");
      else history.goBack();
    }
    else if (setCurrentStep && typeof setCurrentStep === "function") {
      const formStep = currentStep > 2 ? `/${currentStep - 1}` : ""

      const pathWithoutStep = getPathWithoutStep(currentStep, location)
      history.replace(`${pathWithoutStep}${formStep}`)

      setCurrentStep((prevState) => prevState - 1)
    }

    else if(!prevPath) history.replace("/");
    else history.goBack();
  }

  const handleCloseClick = () => {
    
    if (previousFormState && previousFormState?.fromPath)
      history.replace({
        pathname: previousFormState.fromPath,
        state: { hasState: true, removeRecent: true },
      });
    
    else if (handleClose) handleClose();
    else if (!prevPath) history.replace('/');
    else history.goBack();
  }

  return (
    <Box className={classes.container}>
      <header className={classes.header}>
        <Box className={classes.headerButtonContainer}>
          {hideHeaderButtons ? null : (
            <Box className={classes.backButtonContainer}>
              <CIB
                onClick={handleBackClick}
                className={{ marginRight: 32 }}
                icon={ArrowsChevronLeft}
              />
              <Typography customVariant="h5Regular">Back</Typography>
            </Box>
          )}
          <Box className={classes.mobileHeaderTitle}>
            <Typography customVariant="h5Bold">{title}</Typography>
            {subtitle && (
              <Typography customVariant="bodyLargeBold">{subtitle}</Typography>
            )}
          </Box>
          {hideHeaderButtons ? null : (
            <CIB onClick={handleCloseClick} icon={Clear} />
          )}
        </Box>
        <Typography customVariant="h2Bold">{title}</Typography>
        {subtitle && <Typography customVariant="h4Bold">{subtitle}</Typography>}
      </header>
      {!!numberOfSteps && (
        <Box className={classes.headerSteps}>
          <Typography customVariant="ctaRegular">{`Step ${currentStep} of ${numberOfSteps}`}</Typography>
        </Box>
      )}
      <PopoverForm
        currentStep={currentStep}
        disableLayout={disableLayout}
        disableStepper={disableStepper}
        mainAction={mainAction}
        numberOfSteps={numberOfSteps}
        onSubmit={onSubmit}
        secondaryAction={secondaryAction}
        pageView={pageView}
      >
        {children}
      </PopoverForm>
    </Box>
  )
};

FullScreenPopover.propTypes = {
  children: PropTypes.element,
  currentStep: PropTypes.number,
  disableLayout: PropTypes.bool,
  disableStepper: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  mainAction: PropTypes.element,
  numberOfSteps: PropTypes.number,
  onSubmit: PropTypes.func,
  secondaryAction: PropTypes.element,
  setCurrentStep: PropTypes.func,
  title: PropTypes.string,
  pageView: PropTypes.bool
};

const FullScreenPopoverContainer = props => {
  const {
    onModalClick,
    children,
    onOpenCallback,
    onCloseCallback,
    pageView,
    ...other
  } = props;

  return (
    <Modal
      onCloseCallback={onCloseCallback}
      onOpenCallback={onOpenCallback}
      size="full"
      onModalClick={onModalClick}
      pageView={pageView}
    >
      <FullScreenPopover {...other} pageView={pageView}>
        {children}
      </FullScreenPopover>
    </Modal>
  );
};

FullScreenPopoverContainer.propTypes = {
  children: PropTypes.element,
  currentStep: PropTypes.number,
  disableLayout: PropTypes.bool,
  disableStepper: PropTypes.bool,
  mainAction: PropTypes.element,
  numberOfSteps: PropTypes.number,
  onModalClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  secondaryAction: PropTypes.element,
  setCurrentStep: PropTypes.func,
  title: PropTypes.string,
  onOpenCallback: PropTypes.func,
  onCloseCallback: PropTypes.func,
  pageView: PropTypes.bool
};

export default withFormState(withRouter(FullScreenPopoverContainer));
