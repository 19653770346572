export const mockGetAllUpcomingByProject = {
  passengerschedules: [
    {
      id: "5fb0887a337dff63a47e431f",
      start: "2020-11-15T01:46:33.000Z",
      stop: null,
      vehicleClass: "Sedan-Basic",
      origin: "Booking",
      created_at: "2020-11-15T01:46:34.696Z",
      type: "6 Hour",
      flightNumber: "BB-3420",
      terminal: "3",
      numPassengers: "1",
      numberOfVehicles: "1",
      bookingId: "BB.-XAAZV06DJ",
      billed: false,
      notes: null,
      cancelled: false,
      event: {
        id: "5f270baecc54999de0a9f579",
        name: "NBA Finals",
        timezone: "Asia/Dubai",
        __typename: "Event"
      },
      requiredVehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        transferPricing: 0,
        sixHourPricing: 0,
        twelveHourPricing: 0,
        twentyFourHourPricing: 0,
        maxPassengers: 0,
        __typename: "Vehicleclass"
      },
      department: {
        id: "5f975edb69eeee18d55e498a",
        name: "24 Hours",
        __typename: "Department"
      },
      createdBy: {
        id: "5f26eb1dabc1bc0a31f344ff",
        firstName: "John",
        lastName: "Reegan",
        email: "john789@gmail.com",
        phone: "+971509664774",
        __typename: "UsersPermissionsUser"
      },
      passenger: {
        id: "5f3d59154c2533001783b498",
        firstName: "James",
        lastName: "White",
        email: "james741@gmail.com",
        phone: "+971509664754",
        passengerPriority: "high",
        notes: null,
        __typename: "UsersPermissionsUser"
      },
      from: {
        id: "5f3d02d84c2533001783b48d",
        name:
          "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
        address:
          "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
        lat: 25.2531745,
        long: 55.3656728,
        __typename: "Storedlocation"
      },
      to: {
        id: "5f274e8a8d31fa3364defaee",
        name: "Burj Khalifa",
        address: "Burj Khalifa - Dubai - United Arab Emirates",
        lat: 25.089777,
        long: 55.152203,
        __typename: "Storedlocation"
      },
      driverschedules: [
        {
          id: "5fb0887b337dff63a47e4320",
          alerts: [
            {
              id: "5fb6b09cdcf6a8228d57c6a2",
              type: "high",
              status: "unresolved",
              __typename: "Alerts"
            }
          ],
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            plateNumber: "BF-2430",
            __typename: "Vehicle"
          },
          driverMock: {
            id: "5f289d298d19e3785c525f70",
            firstName: "Abdul",
            lastName: "Mohammed",
            identifier: "B0010",
            phone: "+9715096647787",
            __typename: "UsersPermissionsUser"
          },
          ridestatuses: [
            {
              id: "5fb08aa9337dff63a47e433d",
              status: "enroute",
              created_at: "2020-11-15T01:55:53.319Z",
              location: {
                timestamp: 1605405352986,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08aac337dff63a47e433e",
              status: "arrivedToPickup",
              created_at: "2020-11-15T01:55:56.125Z",
              location: {
                timestamp: 1605405355857,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08aaf337dff63a47e433f",
              status: "passengerInVehicle",
              created_at: "2020-11-15T01:55:59.703Z",
              location: {
                timestamp: 1605405359376,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08ab3337dff63a47e4340",
              status: "arrivedToDestination",
              created_at: "2020-11-15T01:56:03.613Z",
              location: {
                timestamp: 1605405363346,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08ab6337dff63a47e4341",
              status: "complete",
              created_at: "2020-11-15T01:56:06.784Z",
              location: {
                timestamp: 1605405366523,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            }
          ],
          unconfirmedAlertSent: false,
          overtimeHours: 0,
          waitingTime: 0,
          __typename: "Driverschedule"
        }
      ],
      __typename: "Passengerschedule"
    },
    {
      id: "5fb08d28974cce3d04ca20f7",
      start: "2020-11-15T02:06:31.000Z",
      stop: null,
      vehicleClass: "Sedan-Basic",
      origin: "Booking",
      created_at: "2020-11-15T02:06:32.395Z",
      type: "12 Hour",
      flightNumber: null,
      terminal: null,
      numPassengers: "1",
      numberOfVehicles: "1",
      bookingId: "BB.--PCIXTMTT",
      billed: false,
      notes: null,
      cancelled: false,
      event: {
        id: "5f270baecc54999de0a9f579",
        name: "Nascar",
        timezone: "Asia/Dubai",
        __typename: "Event"
      },
      requiredVehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        transferPricing: 0,
        sixHourPricing: 0,
        twelveHourPricing: 0,
        twentyFourHourPricing: 0,
        maxPassengers: 0,
        __typename: "Vehicleclass"
      },
      department: {
        id: "5f975edb69eeee18d55e498a",
        name: "24 Hours",
        __typename: "Department"
      },
      createdBy: {
        id: "5f26eb1dabc1bc0a31f344ff",
        firstName: "Jack",
        lastName: "Reeves",
        email: "jack4912@gmail.com",
        phone: "+971509664755",
        __typename: "UsersPermissionsUser"
      },
      passenger: {
        id: "5f3d59154c2533001783b498",
        firstName: "Jack",
        lastName: "Reeves",
        email: "jack871@gmail.com",
        phone: "+971509664754",
        passengerPriority: "high",
        notes: null,
        __typename: "UsersPermissionsUser"
      },
      from: {
        id: "5f271ba97442154fd8a8c13b",
        name: "Dubai Airport Terminal 2 Departure",
        address: "Dubai - United Arab Emirates",
        lat: 25.2628902,
        long: 55.3532168,
        __typename: "Storedlocation"
      },
      to: {
        id: "5f3d02d84c2533001783b48d",
        name:
          "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
        address:
          "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
        lat: 25.2531745,
        long: 55.3656728,
        __typename: "Storedlocation"
      },
      driverschedules: [
        {
          id: "5fb08d29974cce3d04ca20f8",
          alerts: [],
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            plateNumber: "BF-2430",
            __typename: "Vehicle"
          },
          driver: {
            id: "5f289d298d19e3785c525f70",
            firstName: "Abu",
            lastName: "Mohammed",
            identifier: "B0010",
            phone: "+971508664754",
            __typename: "UsersPermissionsUser"
          },
          ridestatuses: [
            {
              id: "5fb08d80619934268c1b12c1",
              status: "enroute",
              created_at: "2020-11-15T02:08:00.577Z",
              location: {
                timestamp: 1605406079937,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08d9e619934268c1b12c4",
              status: "arrivedToPickup",
              created_at: "2020-11-15T02:08:30.932Z",
              location: {
                timestamp: 1605406110644,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08da2619934268c1b12c5",
              status: "passengerInVehicle",
              created_at: "2020-11-15T02:08:34.370Z",
              location: {
                timestamp: 1605406114041,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08da5619934268c1b12c6",
              status: "arrivedToDestination",
              created_at: "2020-11-15T02:08:37.491Z",
              location: {
                timestamp: 1605406117193,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb08da9619934268c1b12c7",
              status: "complete",
              created_at: "2020-11-15T02:08:41.357Z",
              location: {
                timestamp: 1605406120925,
                mocked: false,
                coords: {
                  altitude: 297,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003446,
                  speed: 0,
                  longitude: 15.7508258,
                  accuracy: 14.232000350952148
                }
              },
              __typename: "Ridestatus"
            }
          ],
          unconfirmedAlertSent: false,
          overtimeHours: 0,
          waitingTime: 0,
          __typename: "Driverschedule"
        }
      ],
      __typename: "Passengerschedule"
    },
    {
      id: "5fb08db6619934268c1b12c8",
      start: "2020-11-15T02:08:53.000Z",
      stop: null,
      vehicleClass: "Sedan-Basic",
      origin: "Booking",
      created_at: "2020-11-15T02:08:54.798Z",
      type: "12 Hour",
      flightNumber: null,
      terminal: null,
      numPassengers: "1",
      numberOfVehicles: "1",
      bookingId: "BB.-JXAW5HBS2",
      billed: false,
      notes: null,
      cancelled: false,
      event: {
        id: "5f270baecc54999de0a9f579",
        name: "FIFA",
        timezone: "Asia/Dubai",
        __typename: "Event"
      },
      requiredVehicleClass: {
        id: "5f271b6e7442154fd8a8c139",
        name: "Sedan-Basic",
        transferPricing: 0,
        sixHourPricing: 0,
        twelveHourPricing: 0,
        twentyFourHourPricing: 0,
        maxPassengers: 0,
        __typename: "Vehicleclass"
      },
      department: {
        id: "5f975edb69eeee18d55e498a",
        name: "24 Hours",
        __typename: "Department"
      },
      createdBy: {
        id: "5f26eb1dabc1bc0a31f344ff",
        firstName: "John",
        lastName: "Reegan",
        email: "john852@gmail.com",
        phone: "+971509664777",
        __typename: "UsersPermissionsUser"
      },
      passenger: {
        id: "5f3d59154c2533001783b498",
        firstName: "Harry",
        lastName: "Santos",
        email: "harry412@gmail.com",
        phone: "+971509664754",
        passengerPriority: "high",
        notes: null,
        __typename: "UsersPermissionsUser"
      },
      from: {
        id: "5f271ba97442154fd8a8c13b",
        name: "Dubai Airport Terminal 2 Departure",
        address: "Dubai - United Arab Emirates",
        lat: 25.2628902,
        long: 55.3532168,
        __typename: "Storedlocation"
      },
      to: {
        id: "5f3d02d84c2533001783b48d",
        name:
          "Dubai International Airport (DXB) - Dubai - United Arab Emirates",
        address:
          "Dubai International Airport (DXB) - Dubai - United Arab Emirates, ",
        lat: 25.2531745,
        long: 55.3656728,
        __typename: "Storedlocation"
      },
      driverschedules: [
        {
          id: "5fb08db7619934268c1b12c9",
          alerts: [],
          vehicle: {
            id: "5f271bdb7442154fd8a8c13d",
            vehicleNumber: "B0020",
            make: "Audi v2",
            model: "Car",
            year: "2019",
            plateNumber: "BF-2430",
            __typename: "Vehicle"
          },
          driver: {
            id: "5f289d298d19e3785c525f70",
            firstName: "Peter",
            lastName: "Paul",
            identifier: "B0010",
            phone: "+38631682640",
            __typename: "UsersPermissionsUser"
          },
          ridestatuses: [
            {
              id: "5fb112f37fee2b9d34c26910",
              status: "enroute",
              created_at: "2020-11-15T11:37:23.008Z",
              location: {
                timestamp: 1605440241395,
                mocked: false,
                coords: {
                  altitude: 297.1000061035156,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003418,
                  speed: 0,
                  longitude: 15.7508248,
                  accuracy: 15.722000122070312
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb112f97fee2b9d34c26913",
              status: "arrivedToPickup",
              created_at: "2020-11-15T11:37:29.583Z",
              location: {
                timestamp: 1605440247989,
                mocked: false,
                coords: {
                  altitude: 297.1000061035156,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003418,
                  speed: 0,
                  longitude: 15.7508248,
                  accuracy: 15.722000122070312
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb112fd7fee2b9d34c26914",
              status: "passengerInVehicle",
              created_at: "2020-11-15T11:37:33.100Z",
              location: {
                timestamp: 1605440251520,
                mocked: false,
                coords: {
                  altitude: 297.1000061035156,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003418,
                  speed: 0,
                  longitude: 15.7508248,
                  accuracy: 15.722000122070312
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb113007fee2b9d34c26915",
              status: "arrivedToDestination",
              created_at: "2020-11-15T11:37:36.626Z",
              location: {
                timestamp: 1605440255017,
                mocked: false,
                coords: {
                  altitude: 297.1000061035156,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003418,
                  speed: 0,
                  longitude: 15.7508248,
                  accuracy: 15.722000122070312
                }
              },
              __typename: "Ridestatus"
            },
            {
              id: "5fb113047fee2b9d34c26916",
              status: "complete",
              created_at: "2020-11-15T11:37:40.385Z",
              location: {
                timestamp: 1605440258807,
                mocked: false,
                coords: {
                  altitude: 297.1000061035156,
                  heading: 0,
                  altitudeAccuracy: null,
                  latitude: 46.5003418,
                  speed: 0,
                  longitude: 15.7508248,
                  accuracy: 15.722000122070312
                }
              },
              __typename: "Ridestatus"
            }
          ],
          unconfirmedAlertSent: false,
          overtimeHours: 0,
          waitingTime: 0,
          __typename: "Driverschedule"
        }
      ],
      __typename: "Passengerschedule"
    }
  ]
};

export const mockGetAllHistoricByProject = {};
