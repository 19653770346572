import React, { useState, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import Slider from "@material-ui/core/Slider";
import Typography from "components_v2/Typography/Typography";
import Loading from "components_v2/Loading/CircularProgress";
import Cropper from "react-easy-crop";
import { getCroppedImg, readFile } from "./canvasUtils";
import Button from "components_v2/Button/Button";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    width: "100%",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    border: "1px dashed rgba(64, 1, 197, 0.4)",
    borderRadius: "6px",
    cursor: "pointer",
    position: "relative"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
});

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#4001C5"
      },
      track: {
        color: "#4001C5"
      },
      rail: {
        color: "#4001C5"
      }
    }
  }
});

const UploadImage = ({
  placeholder,
  onComplete,
  defaultPreviewImage = null
}) => {
  const defaultCropOptions = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 10 / 10
  };

  const [isUploading, setIsUploading] = useState(false);
  const [highestResImage, setHighestResImage] = useState(defaultPreviewImage);

  const [imageSrc, setImageSrc] = useState(null);
  const [cropOptions, setCropOptions] = useState(defaultCropOptions);
  const [imageOptions, setImageOptions] = useState({});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const classes = useStyles();

  const inputRef = useRef(null);
  const handleFileChange = async e => {
    if (e.target?.files?.length) {
      const file = e.target.files[0];

      setImageOptions({
        name: file.name,
        type: file.type,
        lastModified: file.lastModified
      });

      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  const handleChange = async (blob, filename) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("files", blob, filename);

    const headers = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("jwtToken"))}`
    };

    const postUrl =
      process.env.REACT_APP_UPLOAD_ENDPOINT ||
      "http://localhost:" + process.env.REACT_APP_BACKEND_PORT + "/upload";
    const result = await axios.post(postUrl, data, {
      headers
    });

    setHighestResImage(result.data[0].url);

    if (result.data[0]?.formats) {
      setHighestResImage(result.data[0].formats.thumbnail.url);
    }

    if (result.data[0]?.formats?.small) {
      setHighestResImage(result.data[0].formats.small.url);
    }

    if (result.data[0]?.formats?.medium) {
      setHighestResImage(result.data[0].formats.medium.url);
    }

    if (result.data[0]?.formats?.large) {
      setHighestResImage(result.data[0].formats.large.url);
    }

    setIsUploading(false);

    onComplete(result.data[0]);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") inputRef.current.click();
  };

  const onCropChange = crop => {
    setCropOptions({
      ...cropOptions,
      crop
    });
  };

  const onCropComplete = (_, croppedAreaPixels) =>
    setCroppedAreaPixels(croppedAreaPixels);

  const onZoomChange = zoom => {
    setCropOptions({
      ...cropOptions,
      zoom
    });
  };

  const handleCropFinished = async () => {
    const croppedImageBlob = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      imageOptions
    );

    handleChange(croppedImageBlob, imageOptions.name);

    setCropOptions(defaultCropOptions);
    setImageOptions({});
    setImageSrc(null);
  };

  if (imageSrc) {
    return (
      <>
        <Box
          role="button"
          tabIndex={0}
          className={classes.container}
          onKeyPress={({ key }) => key === "Enter" && handleCropFinished()}
        >
          <Cropper
            image={imageSrc}
            crop={cropOptions.crop}
            zoom={cropOptions.zoom}
            aspect={cropOptions.aspect}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </Box>
        <footer
          style={{
            marginTop: "10px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            <Typography
              customVariant="bodySmallRegular"
              component="strong"
              style={{ color: "#4001C5", paddingRight: "20px" }}
            >
              Zoom:
            </Typography>
            <ThemeProvider theme={muiTheme}>
              <Slider
                value={cropOptions.zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom)}
              />
            </ThemeProvider>
          </div>
          <Button variant="contained" onClick={handleCropFinished}>
            Confirm
          </Button>
        </footer>
      </>
    );
  }

  return (
    <Box
      role="button"
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}
      className={classes.container}
    >
      {isUploading && <Loading style={{ position: "absolute" }} />}
      {!highestResImage && !isUploading && (
        <Typography
          style={{ color: "rgb(149, 147, 147)" }}
          customVariant="bodySmallRegular"
        >
          {placeholder}
        </Typography>
      )}
      {highestResImage && (
        <>
          <img src={highestResImage} alt="Preivew" className={classes.image} />
          <Typography
            style={{
              position: "absolute",
              bottom: "-32px",
              color: "#4001C5"
            }}
            customVariant="bodySmallRegular"
          >
            Change image
          </Typography>
        </>
      )}

      <input
        name="imageUpload"
        type="file"
        ref={inputRef}
        hidden
        onChange={handleFileChange}
      />
    </Box>
  );
};

UploadImage.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  defaultPreviewImage: PropTypes.string.isRequired
};

export default UploadImage;
