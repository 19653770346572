import React from 'react';
import { IconButton } from '@material-ui/core';
import { FilterOutlined } from "../../../Icons/Icons";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  filterIconBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0',
    width: '40px',
    height: '40px',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '6px',
    marginRight:'8px',
  
   '&:hover': {
      background: '#FFFFFF',
      fill: '#4001C5'
    },

    
  },
}));

const FilterButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.filterIconBtnWrapper}>
      <FilterOutlined />
    </IconButton>
  );
};

export default FilterButton;
