/* eslint-disable complexity */
import { useQuery } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AlertsContainer from "components_v2/Alerts/Alerts.Container.jsx";
import Bookings from "components_v2/Bookings/Booking.Container.jsx";
import Highlight from "components_v2/Highlight/Highlight.Container";
import Timezone from "components_v2/Inputs/Timezone/Timezone";
import withMainLayout from "components_v2/Layout/withMainLayout";
import Locations from "components_v2/Locations/Locations.Container.jsx";
import Passengers from "components_v2/Passengers/Passengers.Container.jsx";
import ProjectSelector from "components_v2/ProjectSelector/ProjectSelector";
import DynamicDriverList from "components_v2/List/Variations/DriversList";
import Reporting from "components_v2/Reporting/Reporting";
import DynamicVehicleList from "components_v2/List/Variations/VehiclesList";
import Scheduler from "components_v2/Scheduler/Scheduler.Container.jsx";
import Tabs from "components_v2/Tabs/Tabs";
import Typography from "components_v2/Typography/Typography";
import Documents from "components_v2/Views/Partial/Documents/Documents.Container.jsx";
import ProjectDetails from "components_v2/Views/Partial/ProjectDetails/ProjectDetails.Container.jsx";
import GetEventTimezoneGQL from "gql/Events/GetTimezone";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Loading from "components_v2/Loading/LinearProgress";
import GetPassengerScheduleCount from "gql/PassengerSchedules/PassengerScheduleCount";

import { barConfig, barData } from "../../../SampleForm/bar_data";
import { counterGraphData } from "../../../SampleForm/counter_graph_data";
import { pieData } from "../../../SampleForm/pie_data";
import { statusGraphData } from "../../../SampleForm/status_graph_data";
import withUserRole from "components_v2/Wrappers/withUserRole";
import {
  getFAB,
  getSelectedDateValue,
  getGridStyles,
  getEventTimeZone,
  getViewType,
  getSavedView,
  getCustomVariantFontSize,
  getIsLoadingTabs,
  getIsScheduleTab
} from "./DashboardHelper";
import { checkUserPermission } from "utils/permissionHelpers";
import { mobileViewBreakpoint } from "../../../../App.helper";
import ModulesSlider from "./ModulesSlider/ModulesSlider";
import MobileModulesSlider from "./ModulesSlider/MobileModuleSlider";
import DynamicListSkeleton from "components_v2/List/DynamicListSkeleton";
import { getCountData } from "utils/countHelpers";
import { useDate } from "components_v2/Hooks/use-date.hook";

const PersistentDrawerLeft = ({
  match,
  history,
  location,
  isHelpTutorial,
  allPermissions
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const [schedulerView, setSchedulerView] = useState(undefined);
  const [datePickerView, setDatePickerView] = useState(undefined);

  const { selectedDate, setSelectedDate, dayStart, dayEnd } = useDate();

  const handleTabChange = value => {
    history.push(`/project/${match.params.projectId}/${value}`);
  };

  const { projectId, selectedTab, bookingId } = match.params;

  useEffect(() => {
    if (getIsScheduleTab({ selectedTab })) setDatePickerView("week");
    else setDatePickerView("day");
  }, [selectedTab]);

  const reportData = {
    unresolvedIssues: counterGraphData,
    ridesOverview: pieData,
    rideRatings: statusGraphData,
    rbrt: {
      barData,
      barConfig
    }
  };

  let {
    data: countData,
    loading: countDataLoading,
    refetch: refetchCountData
  } = useQuery(GetPassengerScheduleCount, {
    // variables: { id: projectId, start: dayStart, end: dayEnd },
    variables: { id: projectId, start: dayStart, end: null },
    skip: isHelpTutorial
  });

  const handleUpdateBookings = async () => {
    await refetchCountData();
  };

  // let {
  //   data: passengerSchedulesData,
  //   loading: passengerSchedulesDataLoading,
  //   refetch: passengerSchedulesDataRefetch
  // } = useQuery(GetAllByEventCompressed, {
  //   variables: { id: projectId },
  //   skip: isHelpTutorial
  // });

  const handleDateChange = ({ target }) => {
    const { value } = target;

    setSelectedDate(value);
  };

  useEffect(() => {
    const savedView = getSavedView({ location });

    setSchedulerView(savedView);
    // Work In Progress: setting selected date is causing an infinite state setting loop... I think
    // setSelectedDate(savedDate);

    const refetchPassengerSchedules = async () => {
      await refetchCountData();
    };

    refetchPassengerSchedules();
  }, []);

  if (isHelpTutorial) {
    // passengerSchedulesData = mockPassengerSchedulesData;
  }

  let { data: eventTimezoneData } = useQuery(GetEventTimezoneGQL, {
    variables: { id: projectId },
    fetchPolicy: "no-cache"
    // skip: isHelpTutorial
  });

  const {
    onGoingRidesCount,
    progressPercentage = 0.0,
    completedRidesCount
  } = getCountData(countData);

  const FAB = getFAB(projectId, selectedTab, history);

  const eventTimeZone = getEventTimeZone({ eventData: eventTimezoneData });

  const [selectedModule, setSelectedModule] = useState({
    id: 0,
    name: "All Modules"
  });
  const [selectedModuleId, setSelectedModuleId] = useState(0);
  const handleSelectModule = module => {
    setSelectedModule(module);
    setSelectedModuleId(module.id);
  };

  // lazy query for updating schedules

  const initialTabs = [
    {
      label: "Bookings",
      slug: "bookings",
      component: (
        <Grid
          container
          style={{
            marginTop: isMobileView ? 32 : 24,
            flexDirection: isMobileView && "column"
          }}
        >
          <Grid
            item
            container
            style={isMobileView ? { flexDirection: "column" } : {}}
          >
            <Grid item>
              <Highlight
                dayStart={dayStart}
                dayEnd={dayEnd}
                isHelpTutorial={isHelpTutorial}
                passengerSchedulesLoading={countDataLoading}
                progress={progressPercentage}
                projectId={projectId}
                ongoing={onGoingRidesCount}
                completed={completedRidesCount}
              />
            </Grid>
            <Grid
              item
              style={{
                width: !isMobileView && "calc(100% - 430px)",
                overflow: !isMobileView && "hidden",
                paddingLeft: !isMobileView && "16px"
              }}
            >
              {!isMobileView ? (
                <ModulesSlider
                  projectId={projectId}
                  isHelpTutorial={isHelpTutorial}
                  onSelectModule={handleSelectModule}
                  selectedModule={selectedModule}
                ></ModulesSlider>
              ) : (
                <MobileModulesSlider
                  menuOffset={{ top: 40, left: -10 }}
                  projectId={projectId}
                  isHelpTutorial={isHelpTutorial}
                  onSelectModule={handleSelectModule}
                  selectedModule={selectedModule}
                />
              )}
            </Grid>
          </Grid>
          <Grid item md={12} style={{ marginTop: isMobileView ? 32 : -40 }}>
            <Bookings
              projectId={projectId}
              bookingId={bookingId}
              type="Upcoming"
              withMapView
              startDate={selectedDate}
              isHelpTutorial={isHelpTutorial}
              selectedModule={selectedModuleId}
              onUpdateBookings={handleUpdateBookings}
            />
          </Grid>
        </Grid>
      )
    },
    {
      label: "Schedule",
      slug: "schedule",
      component: (
        <div style={{ marginTop: 24 }}>
          <Scheduler
            title={moment.utc(Date.now()).format("MMMM YYYY")}
            label="Bookings"
            type="booking"
            selectedDate={selectedDate}
            defaultView="week"
            handleDateChange={handleDateChange}
            view={schedulerView}
            setView={setSchedulerView}
            eventTimeZone={eventTimeZone}
            projectId={projectId}
          />
        </div>
      )
    },
    /*
    {
      label: "Dispatch",
      slug: "dispatch",
      component: (
        <div style={{ marginTop: 24 }}>
          <BookingDispatchContainer history={history} projectId={projectId} />
        </div>
      )
    },

    {
      label: "Shuttles",
      slug: "shuttles",
      component: (
        <div style={{ marginTop: 24 }}>
          <ScheduleContainer
            projectId={projectId}
            isHelpTutorial={isHelpTutorial}
          />
        </div>
      )
    },*/
    {
      label: "History",
      slug: "history",
      component: (
        <div style={{ marginTop: 24 }}>
          <Bookings
            projectId={projectId}
            type="History"
            isHelpTutorial={isHelpTutorial}
            onUpdateBookings={handleUpdateBookings}
          />
        </div>
      )
    },
    {
      label: `Alerts`,
      slug: "alerts",
      component: (
        <div style={{ marginTop: 24 }}>
          <AlertsContainer
            projectId={projectId}
            title="Alerts"
            isHelpTutorial={isHelpTutorial}
          />
        </div>
      )
    },
    {
      label: `Reporting`,
      slug: "reporting",
      component: (
        <div style={{ marginTop: 24 }}>
          <Reporting
            reportData={reportData}
            selectedProjectId={projectId}
            title="Reporting"
            isHelpTutorial={isHelpTutorial}
          />
        </div>
      )
    },
    {
      label: "Project Details",
      slug: "details",
      component: (
        <div style={{ marginTop: 24 }}>
          <ProjectDetails
            projectId={projectId}
            isHelpTutorial={isHelpTutorial}
          />
        </div>
      )
    },
    {
      label: "Documents",
      slug: "documents",
      component: (
        <div style={{ marginTop: 24 }}>
          <Documents projectId={projectId} isHelpTutorial={isHelpTutorial} />
        </div>
      )
    },

    {
      label: "Passengers",
      slug: "passengers",
      component: (
        <div style={{ marginTop: 24 }}>
          <Passengers projectId={projectId} isHelpTutorial={isHelpTutorial} />
        </div>
      )
    },
    {
      label: "Vehicles",
      slug: "vehicles",
      isConditional: true,
      condition: checkUserPermission(allPermissions, "vehicles").canView,
      conditionalComponent: (
        <div style={{ marginTop: 24 }}>
          <DynamicVehicleList
          // isHelpTutorial={isHelpTutorial}
          />
        </div>
      ),
      component: (
        <div style={{ marginTop: 24 }}>
          <DynamicListSkeleton />
        </div>
      )
    },
    {
      label: "Drivers",
      slug: "drivers",
      isConditional: true,
      condition: checkUserPermission(allPermissions, "drivers").canView,
      conditionalComponent: (
        <div style={{ marginTop: 24 }}>
          <DynamicDriverList
          // isHelpTutorial={isHelpTutorial}
          />
        </div>
      ),
      component: (
        <div style={{ marginTop: 24 }}>
          <DynamicListSkeleton />
        </div>
      )
    },
    {
      label: "Locations",
      slug: "locations",
      component: (
        <div style={{ marginTop: 24 }}>
          <Locations projectId={projectId} isHelpTutorial={isHelpTutorial} />
        </div>
      )
    }
  ];

  const conditionalTabsLoading =
    checkUserPermission(allPermissions, "drivers").canView === null ||
    checkUserPermission(allPermissions, "vehicles").canView === null ||
    countDataLoading;

  const tabDependencies = [
    schedulerView,
    selectedDate,
    eventTimeZone,
    projectId,
    JSON.stringify(selectedModule),
    selectedModuleId
  ];

  return (
    <div style={isMobileView ? { paddingBottom: 64 } : {}}>
      {FAB}
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={getGridStyles({ isMobileView })}
      >
        <Grid
          item
          lg={6}
          style={
            isMobileView
              ? {
                  marginTop: "20px"
                }
              : {}
          }
        >
          <ProjectSelector
            isHelpTutorial={isHelpTutorial}
            showSeeAllProjects
            selectedProjectId={projectId}
            render={projectName => (
              <Typography
                customVariant={getCustomVariantFontSize({ isMobileView })}
              >
                {projectName}
              </Typography>
            )}
          />
        </Grid>
        <Grid
          item
          lg={3}
          xs={12}
          justify="flex-end"
          style={isMobileView ? { width: "100%" } : {}}
        >
          <Timezone
            value={getSelectedDateValue({ selectedDate })}
            onChange={handleDateChange}
            style={{
              marginBottom: 0,
              display: "flex",
              justifyContent: "flex-end"
            }}
            timezone={eventTimeZone}
            viewType={getViewType({ schedulerView, datePickerView })}
          />
        </Grid>
      </Grid>
      <Tabs
        scrollable
        noBg
        noPadding
        dependencies={tabDependencies}
        conditionalTabsLoading={conditionalTabsLoading}
        selectedTab={selectedTab}
        onChangeTab={handleTabChange}
        tabs={initialTabs}
      />

      {getIsLoadingTabs({
        passengerSchedulesDataLoading: countDataLoading
      }) && <Loading />}
    </div>
  );
};

export default withMainLayout(
  "Dashboard",
  {},
  withRouter(withUserRole("vehicles", PersistentDrawerLeft))
);
