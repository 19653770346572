import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  filterInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '24px 0',
  },
  searchInputContainer: {
    width: 300,
  },
});
