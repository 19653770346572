import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DynamicList from 'components_v2/List/DynamicList';
import { useQuery } from '@apollo/react-hooks';
import GetAll from 'gql/Vehicles/GetAll';

import SearchInput from 'components_v2/Inputs/SearchInput/SearchInput';
import Typography from 'components_v2/Typography/Typography';
import { Clear } from 'components_v2/Icons/Icons';
import Button from 'components_v2/Button/Button';
import Loading from 'components_v2/Loading/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CIB from '../Buttons/CIB/CIB';

import { mobileViewBreakpoint } from '../../App.helper';
import { filterByValue, getSkip, getIsContentFilteredData, } from './helpers';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    overflowY: 'auto',
    height: '40vh',
    '@media screen and (min-height: 1000px)': {
      height: '53vh',
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: 'unset',
      flex: 1,
    },
  },
}));

// eslint-disable-next-line complexity
const ListModalContent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const {
    handleClose,
    handleCancel,
    handleCloseCallback,
    searchPlaceholder,
    afterSearch,
    getAfterSearch,
    searchValue,
    setSearchValue,
    filteredData,
    title,
    subtitle,
    secondaryAction,
    mainAction,
    customMainAction,
    customSecondaryAction,
    selectedItems,
    setSelectedItems,
    disableActions,
    customListActions = {},
    loadDataOnRender = true,
    isHelpTutorial = false,
    setListData,
    query = GetAll,
    queryOptions,
    data,
    disableQuery,
    dataSelector,
    helpTutorialMockData,
    disableSelection,
    incomingItems,
    customHandleSelection,
    customSelectionValue
  } = props;

  const { data: queryData, loading } = useQuery(
    query,
    {
      ...queryOptions,
      skip: getSkip({
        disableQuery,
        isHelpTutorial,
        loadDataOnRender,
        queryOptions,
      })

    },
    {
      fetchPolicy: 'no-cache',
    }
  );

  let contentFilteredData = filteredData;

  if (data && disableQuery) contentFilteredData = data; // if data was passed in through data object
  if (getIsContentFilteredData({ query, loading, disableQuery, loadDataOnRender }))
    contentFilteredData = dataSelector ? dataSelector(queryData) : queryData; // if a query is provided

  if (searchValue && searchValue.length > 0) {
    contentFilteredData = filterByValue(contentFilteredData, searchValue);
  }

  // const contentFilteredDataJSON = JSON.stringify(contentFilteredData);
  // const helpTutorialMockDataJSON = JSON.stringify(helpTutorialMockData);

  useEffect(() => {
    if (isHelpTutorial) setListData(helpTutorialMockData);
    else if (!loadDataOnRender) setListData(contentFilteredData);
  }, [
    JSON.stringify(contentFilteredData)
    ]);

  const handleCheckboxClick = (row) => {
    let newItems = [...selectedItems];
    if (selectedItems.includes(row.id)) {
      newItems = selectedItems.filter((item) => item !== row.id);
    } else {
      newItems.push(row.id);
    }

    setSelectedItems(newItems);
  };

  const handleRadioClick = (enableUnselect = false) => (row) => {
    if (enableUnselect && selectedItems[0] === row.id) setSelectedItems([]);
    else setSelectedItems([row.id]);
  };

  const getSelectionHandler = (customHandleSelection, singleSelection, enableRadioUnselect) => {
    if (customHandleSelection) return customHandleSelection(selectedItems, setSelectedItems);
    if (singleSelection) return handleRadioClick(enableRadioUnselect);
    return handleCheckboxClick;
  }

  const defaultActions = {
    // rowClick: props.singleSelection ? handleRadioClick : handleCheckboxClick,
    rowClick: props.rowClick,
    checkbox: {
      onClick: getSelectionHandler(customHandleSelection, props.singleSelection, props.enableRadioUnselect),
      disabled: (row, selectedItems) => {
        if (props.selectionDisabled)
          return props.selectionDisabled(row, selectedItems);
        return false;
      },
      value: (row) => customSelectionValue ? customSelectionValue(selectedItems, row) : selectedItems.includes(row.id),
    },
  };

  const isLoading = loading && !loadDataOnRender;

  const onClose = () => {
    handleClose();
    if (handleCloseCallback && typeof handleCloseCallback === 'function')
      handleCloseCallback();
  };

  const handleCloseButton = () => {
    setSelectedItems(incomingItems);
    if (handleCancel && typeof handleCancel === 'function')
      handleCancel();
    if (handleCloseCallback && typeof handleCloseCallback === 'function')

      handleCloseCallback();

  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: isMobileView ? 'auto' : 'hidden',
      }}
    >
      <div
        style={{
          padding: isMobileView ? '24px' : '88px 88px 0 88px',
          flex: isMobileView && 1,
          position: 'relative',
          display: isMobileView && 'flex',
          flexDirection: isMobileView && 'column',
          height: isMobileView && '100%',
          overflow: isMobileView && 'auto',
        }}
      >
        {isLoading && (
          <Loading
            fullPage
            backgroundHeight="100%"
            backgroundWidth="100%"
            backgroundPosition="absolute"
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
          }}
        >
          <Typography customVariant="h4Bold">{title}</Typography>
          <CIB onClick={handleCloseButton} icon={Clear} />
        </div>
        <Typography style={{ marginBottom: 16 }} customVariant="h5Regular">
          {subtitle}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 429 }}>
            <SearchInput
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
            />
          </div>
          {/* {props.singleSelection ? null : <CheckboxInput label="Select all" />} */}

          <div style={{ marginLeft: 16, display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {afterSearch}
            {getAfterSearch ? getAfterSearch(selectedItems) : null}
          </div>
        </div>

        <div className={classes.listContainer}>
          <DynamicList
            {...props}
            moduleTable
            disableSelection={disableSelection}
            isMobileView={isMobileView}
            showHeader
            data={filteredData}
            title={undefined}
            singleSelection={props.singleSelection}
            actions={
              disableActions ? {} : { ...defaultActions, ...customListActions }
            }
          />
        </div>
      </div>
      {!disableSelection && (
        <footer
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: !isMobileView ? 'flex-end' : 'flex-end',
            padding: !isMobileView ? '32px 72px' : '16px 24px',
            background: !isMobileView ? 'rgba(0, 0, 0, 0.02)' : '#FFFFFF',
            boxShadow: isMobileView && '0px -10px 17px rgba(0, 0, 0, 0.1)',
            position: !isMobileView && 'fixed',
            bottom: !isMobileView && 0,
            left: !isMobileView && 0,
            right: !isMobileView && 0,
          }}
        >
          <span style={{ marginRight: !isMobileView && 72 }}>
            {!disableSelection && customSecondaryAction}
            {!disableSelection && secondaryAction && (
              <Button
                variant="simple"
                onClick={() => secondaryAction.onClick(selectedItems)}
              >
                {secondaryAction.label}
              </Button>
            )}
          </span>
          {!disableSelection && customMainAction}
          {!disableSelection && mainAction && (
            <Button
              variant="contained"
              onClick={() => {
                const filterSelected = customSelectionValue
                  ? (x) => customSelectionValue(selectedItems, x)
                  : (x) => selectedItems.includes(x.id);
                const selectedObjects = filteredData.filter(filterSelected);
                mainAction.onClick(selectedObjects);
                onClose();
              }}
            >
              {mainAction.label}
            </Button>
          )}
        </footer>
      )}
    </div>
  );
};


export default ListModalContent;
