import {getFilteredData ,getSortedData} from '../../../List/helper'
export const getFilteredShuttleSheduleItems = ({ shuttleSheduleItems, filterKey }) => {
    return getFilteredData({ data: shuttleSheduleItems, filterKey })
}
export const getSortedShuttleSheduleItems = ({ shuttleSheduleItems, sort }) => {
    return getSortedData({ data: shuttleSheduleItems, sort })
  }

  export const getFilteredShuttleShedules = ({ shuttleSchedules, filterKey }) => {
      return getFilteredData({ data: shuttleSchedules, filterKey })
  }
  export const getSortedShuttleShedules = ({ shuttleSchedules, sort }) => {
      return getSortedData({ data: shuttleSchedules, sort })
    }  