import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from "lodash";
import { withStyles } from '@material-ui/core/styles';

import DocumentPreview from 'components_v2/DocumentPreview/DocumentPreview';
import Modal from 'components_v2/Modal/Modal';
import ListItem from './ListItem';
import "./DynamicListItem.css";

const BasicListItem = (props) => {

  const {
    actions,
    actionsOverlay,
    columns,
    customActions,
    detailPanel,
    disableSelection,
    documentPreview,
    editMode: initialEditMode,
    getMobileMenuItems,
    hamburgerMenuItems,
    highlightRecords,
    getHamburgerMenuItems,
    hideActions = false,
    idSuffix,
    isAutoClose,
    isMobileView,
    loadingRecords,
    mainColumnsWidth,
    moduleTable,
    newMode,
    newRow,
    onClick,
    renderLeftBorder,
    row,
    invisibleComponent,
    selectedItems,
    setNewMode,
    singleSelection
  } = props;

  const [state, setState] = useState({
    open: false,
    overlayOpen: false,
    checked: [],
    editMode: initialEditMode || false /* need extra conditions here */,
    values:   row,
    loading: false,
  });

  const { 
    open,
    loading,
    overlayOpen,
    values:stateValues,
    editMode
    } = state;

  const setItemState = (values) => {
    setState((prevState) => ({ ...prevState, ...values }));
  };

  useEffect(() => {
    setItemState({ values: row });

  }, [JSON.stringify(row)]);

  const setOverlayOpen = (status) => {
    setItemState({ overlayOpen: status });
  };

  const customValueChange = (column, row, newValue, handleChange) => {
    const values = { ...stateValues };

    const newValues = handleChange(column, row, newValue, values);

    setItemState({ values: newValues });
  }

  const handleValueChange = (column, row, newValue) => {
    const values = { ...stateValues };

    if (column.type === 'number')
      values[column.field] = parseFloat(newValue);
    else if (column.type === "checkbox") values[column.field] = !values[column.field];
    else if (column.options && column.field !== "icon")
      values[column.field] = newValue?.value || undefined;
    else if (column.customSelect && column.valuesField) values[column.valuesField] = newValue;
    else values[column.field] = newValue;

    setItemState({ values });
  };

  const addHighlightClass = () => {
    if (highlightRecords?.length && row?.id) {
      return _.includes(highlightRecords, row?.id)
    }

    return false;
  }

  const isCheckboxVisible = actions?.checkbox?.onClick;
  
  const listItemProps = {
    actions,
    actionsOverlay,
    addHighlightClass,
    columns,
    customActions,
    customValueChange,
    detailPanel,
    disableSelection,
    editMode,
    getHamburgerMenuItems,
    getMobileMenuItems,
    hamburgerMenuItems,
    handleValueChange,
    hideActions,
    idSuffix,
    invisibleComponent,
    isAutoClose,
    isCheckboxVisible,
    isMobileView,
    loading,
    loadingRecords,
    mainColumnsWidth,
    moduleTable,
    newMode,
    newRow,
    onClick,
    open,
    overlayOpen,
    renderLeftBorder,
    row,
    selectedItems,
    setDynamicListState: setItemState,
    setNewMode,
    setOverlayOpen,
    singleSelection,
    stateValues,
  }
    
  // Return logic
  if (documentPreview)
    return (
      <Modal
        size="full"
        onModalClick={(toggleModal) => (
          <ListItem { ...listItemProps } toggleModal={toggleModal} />
        )}
      >
        <DocumentPreview fileSize={row?.size} src={row?.url} />
      </Modal>
    );

  return (
    <ListItem { ...listItemProps } />
  )
};


export default withRouter(
  withStyles({
    root: {
      color: 'rgba(255, 255, 255, 0.75)',
      paddingLeft: 10,
    },
    listItemText: {
      fontSize: 10,
      color: 'rgba(255, 255, 255, 0.45)',
      paddingLeft: 0,
      textTransform: 'uppercase',
    },
    listItemTextRoot: { paddingLeft: '15px !important' },
  })(BasicListItem));
