export default [
  { code: 'afn', name: 'Afghan Afghani - AFN' },
  { code: 'all', name: 'Albanian Lek - ALL' },
  { code: 'dzd', name: 'Algerian Dinar - DZD' },
  { code: 'aoa', name: 'Angolan Kwanza - AOA' },
  { code: 'ars', name: 'Argentine Peso - ARS' },
  { code: 'amd', name: 'Armenian Dram - AMD' },
  { code: 'awg', name: 'Aruban Florin - AWG' },
  { code: 'aud', name: 'Australian Dollar - AUD' },
  { code: 'azn', name: 'Azerbaijani Manat - AZN' },
  { code: 'bsd', name: 'Bahamian Dollar - BSD' },
  { code: 'bhd', name: 'Bahraini Dinar - BHD' },
  { code: 'bdt', name: 'Bangladeshi Taka - BDT' },
  { code: 'bbd', name: 'Barbadian Dollar - BBD' },
  { code: 'byn', name: 'Belarusian Ruble - BYN' },
  { code: 'bzd', name: 'Belize Dollar - BZD' },
  { code: 'bmd', name: 'Bermudan Dollar - BMD' },
  { code: 'btn', name: 'Bhutanese Ngultrum - BTN' },
  { code: 'btc', name: 'Bitcoin - BTC' },
  { code: 'bob', name: 'Bolivian Boliviano - BOB' },
  { code: 'bam', name: 'Bosnia-Herzegovina Convertible Mark - BAM' },
  { code: 'bwp', name: 'Botswanan Pula - BWP' },
  { code: 'brl', name: 'Brazilian Real - BRL' },
  { code: 'gbp', name: 'British Pound Sterling - GBP' },
  { code: 'bnd', name: 'Brunei Dollar - BND' },
  { code: 'bgn', name: 'Bulgarian Lev - BGN' },
  { code: 'bif', name: 'Burundian Franc - BIF' },
  { code: 'xof', name: 'CFA Franc BCEAO - XOF' },
  { code: 'xaf', name: 'CFA Franc BEAC - XAF' },
  { code: 'xpf', name: 'CFP Franc - XPF' },
  { code: 'khr', name: 'Cambodian Riel - KHR' },
  { code: 'cad', name: 'Canadian Dollar - CAD' },
  { code: 'cve', name: 'Cape Verdean Escudo - CVE' },
  { code: 'kyd', name: 'Cayman Islands Dollar - KYD' },
  { code: 'clp', name: 'Chilean Peso - CLP' },
  { code: 'clf', name: 'Chilean Unit of Account (UF) - CLF' },
  { code: 'cnh', name: 'Chinese Yuan (Offshore) - CNH' },
  { code: 'cny', name: 'Chinese Yuan - CNY' },
  { code: 'cop', name: 'Colombian Peso - COP' },
  { code: 'kmf', name: 'Comorian Franc - KMF' },
  { code: 'cdf', name: 'Congolese Franc - CDF' },
  { code: 'crc', name: 'Costa Rican Colón - CRC' },
  { code: 'hrk', name: 'Croatian Kuna - HRK' },
  { code: 'cuc', name: 'Cuban Convertible Peso - CUC' },
  { code: 'cup', name: 'Cuban Peso - CUP' },
  { code: 'czk', name: 'Czech Republic Koruna - CZK' },
  { code: 'dkk', name: 'Danish Krone - DKK' },
  { code: 'djf', name: 'Djiboutian Franc - DJF' },
  { code: 'dop', name: 'Dominican Peso - DOP' },
  { code: 'xcd', name: 'East Caribbean Dollar - XCD' },
  { code: 'egp', name: 'Egyptian Pound - EGP' },
  { code: 'ern', name: 'Eritrean Nakfa - ERN' },
  { code: 'etb', name: 'Ethiopian Birr - ETB' },
  { code: 'eur', name: 'Euro - EUR' },
  { code: 'fkp', name: 'Falkland Islands Pound - FKP' },
  { code: 'fjd', name: 'Fijian Dollar - FJD' },
  { code: 'gmd', name: 'Gambian Dalasi - GMD' },
  { code: 'gel', name: 'Georgian Lari - GEL' },
  { code: 'ghs', name: 'Ghanaian Cedi - GHS' },
  { code: 'gip', name: 'Gibraltar Pound - GIP' },
  { code: 'xau', name: 'Gold Ounce - XAU' },
  { code: 'gtq', name: 'Guatemalan Quetzal - GTQ' },
  { code: 'ggp', name: 'Guernsey Pound - GGP' },
  { code: 'gnf', name: 'Guinean Franc - GNF' },
  { code: 'gyd', name: 'Guyanaese Dollar - GYD' },
  { code: 'htg', name: 'Haitian Gourde - HTG' },
  { code: 'hnl', name: 'Honduran Lempira - HNL' },
  { code: 'hkd', name: 'Hong Kong Dollar - HKD' },
  { code: 'huf', name: 'Hungarian Forint - HUF' },
  { code: 'isk', name: 'Icelandic Króna - ISK' },
  { code: 'inr', name: 'Indian Rupee - INR' },
  { code: 'idr', name: 'Indonesian Rupiah - IDR' },
  { code: 'irr', name: 'Iranian Rial - IRR' },
  { code: 'iqd', name: 'Iraqi Dinar - IQD' },
  { code: 'ils', name: 'Israeli New Sheqel - ILS' },
  { code: 'jmd', name: 'Jamaican Dollar - JMD' },
  { code: 'jpy', name: 'Japanese Yen - JPY' },
  { code: 'jep', name: 'Jersey Pound - JEP' },
  { code: 'jod', name: 'Jordanian Dinar - JOD' },
  { code: 'kzt', name: 'Kazakhstani Tenge - KZT' },
  { code: 'kes', name: 'Kenyan Shilling - KES' },
  { code: 'kwd', name: 'Kuwaiti Dinar - KWD' },
  { code: 'kgs', name: 'Kyrgystani Som - KGS' },
  { code: 'lak', name: 'Laotian Kip - LAK' },
  { code: 'lbp', name: 'Lebanese Pound - LBP' },
  { code: 'lsl', name: 'Lesotho Loti - LSL' },
  { code: 'lrd', name: 'Liberian Dollar - LRD' },
  { code: 'lyd', name: 'Libyan Dinar - LYD' },
  { code: 'mop', name: 'Macanese Pataca - MOP' },
  { code: 'mkd', name: 'Macedonian Denar - MKD' },
  { code: 'mga', name: 'Malagasy Ariary - MGA' },
  { code: 'mwk', name: 'Malawian Kwacha - MWK' },
  { code: 'myr', name: 'Malaysian Ringgit - MYR' },
  { code: 'mvr', name: 'Maldivian Rufiyaa - MVR' },
  { code: 'imp', name: 'Manx pound - IMP' },
  { code: 'mro', name: 'Mauritanian Ouguiya (pre-2018) - MRO' },
  { code: 'mru', name: 'Mauritanian Ouguiya - MRU' },
  { code: 'mur', name: 'Mauritian Rupee - MUR' },
  { code: 'mxn', name: 'Mexican Peso - MXN' },
  { code: 'mdl', name: 'Moldovan Leu - MDL' },
  { code: 'mnt', name: 'Mongolian Tugrik - MNT' },
  { code: 'mad', name: 'Moroccan Dirham - MAD' },
  { code: 'mzn', name: 'Mozambican Metical - MZN' },
  { code: 'mmk', name: 'Myanma Kyat - MMK' },
  { code: 'nad', name: 'Namibian Dollar - NAD' },
  { code: 'npr', name: 'Nepalese Rupee - NPR' },
  { code: 'ang', name: 'Netherlands Antillean Guilder - ANG' },
  { code: 'twd', name: 'New Taiwan Dollar - TWD' },
  { code: 'nzd', name: 'New Zealand Dollar - NZD' },
  { code: 'nio', name: 'Nicaraguan Córdoba - NIO' },
  { code: 'ngn', name: 'Nigerian Naira - NGN' },
  { code: 'kpw', name: 'North Korean Won - KPW' },
  { code: 'nok', name: 'Norwegian Krone - NOK' },
  { code: 'omr', name: 'Omani Rial - OMR' },
  { code: 'pkr', name: 'Pakistani Rupee - PKR' },
  { code: 'xpd', name: 'Palladium Ounce - XPD' },
  { code: 'pab', name: 'Panamanian Balboa - PAB' },
  { code: 'pgk', name: 'Papua New Guinean Kina - PGK' },
  { code: 'pyg', name: 'Paraguayan Guarani - PYG' },
  { code: 'pen', name: 'Peruvian Nuevo Sol - PEN' },
  { code: 'php', name: 'Philippine Peso - PHP' },
  { code: 'xpt', name: 'Platinum Ounce - XPT' },
  { code: 'pln', name: 'Polish Zloty - PLN' },
  { code: 'qar', name: 'Qatari Rial - QAR' },
  { code: 'ron', name: 'Romanian Leu - RON' },
  { code: 'rub', name: 'Russian Ruble - RUB' },
  { code: 'rwf', name: 'Rwandan Franc - RWF' },
  { code: 'shp', name: 'Saint Helena Pound - SHP' },
  { code: 'svc', name: 'Salvadoran Colón - SVC' },
  { code: 'wst', name: 'Samoan Tala - WST' },
  { code: 'sar', name: 'Saudi Riyal - SAR' },
  { code: 'rsd', name: 'Serbian Dinar - RSD' },
  { code: 'scr', name: 'Seychellois Rupee - SCR' },
  { code: 'sll', name: 'Sierra Leonean Leone - SLL' },
  { code: 'xag', name: 'Silver Ounce - XAG' },
  { code: 'sgd', name: 'Singapore Dollar - SGD' },
  { code: 'sbd', name: 'Solomon Islands Dollar - SBD' },
  { code: 'sos', name: 'Somali Shilling - SOS' },
  { code: 'zar', name: 'South African Rand - ZAR' },
  { code: 'krw', name: 'South Korean Won - KRW' },
  { code: 'ssp', name: 'South Sudanese Pound - SSP' },
  { code: 'xdr', name: 'Special Drawing Rights - XDR' },
  { code: 'lkr', name: 'Sri Lankan Rupee - LKR' },
  { code: 'sdg', name: 'Sudanese Pound - SDG' },
  { code: 'srd', name: 'Surinamese Dollar - SRD' },
  { code: 'szl', name: 'Swazi Lilangeni - SZL' },
  { code: 'sek', name: 'Swedish Krona - SEK' },
  { code: 'chf', name: 'Swiss Franc - CHF' },
  { code: 'syp', name: 'Syrian Pound - SYP' },
  { code: 'std', name: 'São Tomé and Príncipe Dobra (pre-2018) - STD' },
  { code: 'stn', name: 'São Tomé and Príncipe Dobra - STN' },
  { code: 'tjs', name: 'Tajikistani Somoni - TJS' },
  { code: 'tzs', name: 'Tanzanian Shilling - TZS' },
  { code: 'thb', name: 'Thai Baht - THB' },
  { code: 'top', name: "Tongan Pa'anga - TOP" },
  { code: 'ttd', name: 'Trinidad and Tobago Dollar - TTD' },
  { code: 'tnd', name: 'Tunisian Dinar - TND' },
  { code: 'try', name: 'Turkish Lira - TRY' },
  { code: 'tmt', name: 'Turkmenistani Manat - TMT' },
  { code: 'ugx', name: 'Ugandan Shilling - UGX' },
  { code: 'uah', name: 'Ukrainian Hryvnia - UAH' },
  { code: 'aed', name: 'United Arab Emirates Dirham - AED' },
  { code: 'usd', name: 'United States Dollar - USD' },
  { code: 'uyu', name: 'Uruguayan Peso - UYU' },
  { code: 'uzs', name: 'Uzbekistan Som - UZS' },
  { code: 'vuv', name: 'Vanuatu Vatu - VUV' },
  { code: 'vef', name: 'Venezuelan Bolívar Fuerte (Old) - VEF' },
  { code: 'ves', name: 'Venezuelan Bolívar Soberano - VES' },
  { code: 'vnd', name: 'Vietnamese Dong - VND' },
  { code: 'yer', name: 'Yemeni Rial - YER' },
  { code: 'zmw', name: 'Zambian Kwacha - ZMW' },
  { code: 'zwl', name: 'Zimbabwean Dollar - ZWL' },
];
