import React from 'react';

import languageOptions from 'utils/languages';
import SelectInput from 'components_v2/Inputs/SelectInput/SelectInput';

const LanguageSelect = (props) => {
  const { name, label, placeholder, value, onChange, noMargin, error } = props;

  return (
    <SelectInput
      name={name}
      label={label}
      placeholder={placeholder}
      value={value || []}
      onChange={onChange}
      options={languageOptions}
      noMargin={noMargin}
      isError={error}
      errorMessage={error}
      isMultiple
      autoComplete="off"
    />
  );
};

export default LanguageSelect;
