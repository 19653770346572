import React from 'react';
import { useQuery, useMutation } from "@apollo/react-hooks"

import ProductSelector from "./ProductSelector";
import UpdateProduct from 'gql/Products/Update';
import CreateProduct from 'gql/Products/Create';
import GetAllByEvent from 'gql/Products/GetAllByEvent';

const ProductSelectorContainer = (props) => {
  const { data = null, refetch } = useQuery(GetAllByEvent, {
    variables: { id: props.projectId },
    fetchPolicy: 'no-cache',
  });

  const [createProduct] = useMutation(CreateProduct);
  const [updateProduct] = useMutation(UpdateProduct);

  const products = data?.products || [];

  return (
    <ProductSelector
      products={products}
      refetch={refetch}
      createProduct={createProduct}
      updateProduct={updateProduct}
      {...props}
      
    />
  )
}

export default ProductSelectorContainer
