export default [
  {
    name: 'Pacific/Niue',
    alternativeName: 'Niue Time',
    mainCities: ['Alofi'],
    formatted: '-11:00 Niue Time - Alofi',
    group: ['Pacific/Niue'],
  },
  {
    name: 'Pacific/Midway',
    alternativeName: 'Samoa Time',
    mainCities: ['Midway'],
    formatted: '-11:00 Samoa Time - Midway',
    group: ['Pacific/Midway'],
  },
  {
    name: 'Pacific/Rarotonga',
    alternativeName: 'Cook Islands Time',
    mainCities: ['Avarua'],
    formatted: '-10:00 Cook Islands Time - Avarua',
    group: ['Pacific/Rarotonga'],
  },
  {
    name: 'America/Adak',
    alternativeName: 'Hawaii-Aleutian Time',
    mainCities: ['Adak'],
    formatted: '-09:00 Hawaii-Aleutian Time - Adak',
    group: ['America/Adak'],
  },
  {
    name: 'Pacific/Honolulu',
    alternativeName: 'Hawaii-Aleutian Time',
    mainCities: ['Honolulu', 'East Honolulu'],
    formatted: '-10:00 Hawaii-Aleutian Time - Honolulu, East Honolulu',
    group: ['Pacific/Honolulu'],
  },
  {
    name: 'Pacific/Tahiti',
    alternativeName: 'Tahiti Time',
    mainCities: ['Faaa', 'Papeete'],
    formatted: '-10:00 Tahiti Time - Faaa, Papeete',
    group: ['Pacific/Tahiti'],
  },
  {
    name: 'Pacific/Marquesas',
    alternativeName: 'Marquesas Time',
    mainCities: ['Marquesas'],
    formatted: '-09:30 Marquesas Time - Marquesas',
    group: ['Pacific/Marquesas'],
  },
  {
    name: 'America/Anchorage',
    alternativeName: 'Alaska Time',
    mainCities: ['Anchorage', 'Juneau'],
    formatted: '-08:00 Alaska Time - Anchorage, Juneau',
    group: [
      'America/Anchorage',
      'America/Juneau',
      'America/Metlakatla',
      'America/Nome',
      'America/Sitka',
      'America/Yakutat',
    ],
  },
  {
    name: 'Pacific/Gambier',
    alternativeName: 'Gambier Time',
    mainCities: ['Gambier'],
    formatted: '-09:00 Gambier Time - Gambier',
    group: ['Pacific/Gambier'],
  },
  {
    name: 'America/Los_Angeles',
    alternativeName: 'Pacific Time',
    mainCities: ['Los Angeles', 'San Diego'],
    formatted: '-07:00 Pacific Time - Los Angeles, San Diego',
    group: ['America/Los_Angeles'],
  },
  {
    name: 'America/Tijuana',
    alternativeName: 'Pacific Time',
    mainCities: ['Tijuana', 'Mexicali'],
    formatted: '-07:00 Pacific Time - Tijuana, Mexicali',
    group: ['America/Tijuana'],
  },
  {
    name: 'America/Vancouver',
    alternativeName: 'Pacific Time',
    mainCities: ['Vancouver', 'Surrey'],
    formatted: '-07:00 Pacific Time - Vancouver, Surrey',
    group: ['America/Vancouver'],
  },
  {
    name: 'America/Whitehorse',
    alternativeName: 'Pacific Time',
    mainCities: ['Whitehorse', 'Dawson'],
    formatted: '-07:00 Pacific Time - Whitehorse, Dawson',
    group: ['America/Dawson', 'America/Whitehorse'],
  },
  {
    name: 'Pacific/Pitcairn',
    alternativeName: 'Pitcairn Time',
    mainCities: ['Adamstown'],
    formatted: '-08:00 Pitcairn Time - Adamstown',
    group: ['Pacific/Pitcairn'],
  },
  {
    name: 'America/Hermosillo',
    alternativeName: 'Mexican Pacific Time',
    mainCities: ['Hermosillo', 'Ciudad Obregón'],
    formatted: '-07:00 Mexican Pacific Time - Hermosillo, Ciudad Obregón',
    group: ['America/Hermosillo'],
  },
  {
    name: 'America/Edmonton',
    alternativeName: 'Mountain Time',
    mainCities: ['Calgary', 'Edmonton'],
    formatted: '-06:00 Mountain Time - Calgary, Edmonton',
    group: [
      'America/Cambridge_Bay',
      'America/Edmonton',
      'America/Inuvik',
      'America/Yellowknife',
    ],
  },
  {
    name: 'America/Ojinaga',
    alternativeName: 'Mountain Time',
    mainCities: ['Ciudad Juárez', 'Chihuahua'],
    formatted: '-06:00 Mountain Time - Ciudad Juárez, Chihuahua',
    group: ['America/Chihuahua', 'America/Mazatlan', 'America/Ojinaga'],
  },
  {
    name: 'America/Denver',
    alternativeName: 'Mountain Time',
    mainCities: ['Denver', 'El Paso'],
    formatted: '-06:00 Mountain Time - Denver, El Paso',
    group: ['America/Boise', 'America/Denver'],
  },
  {
    name: 'America/Dawson_Creek',
    alternativeName: 'Mountain Time',
    mainCities: ['Fort St. John', 'Creston'],
    formatted: '-07:00 Mountain Time - Fort St. John, Creston',
    group: ['America/Creston', 'America/Dawson_Creek', 'America/Fort_Nelson'],
  },
  {
    name: 'America/Phoenix',
    alternativeName: 'Mountain Time',
    mainCities: ['Phoenix', 'Tucson'],
    formatted: '-07:00 Mountain Time - Phoenix, Tucson',
    group: ['America/Phoenix'],
  },
  {
    name: 'America/Belize',
    alternativeName: 'Central Time',
    mainCities: ['Belize City', 'San Ignacio'],
    formatted: '-06:00 Central Time - Belize City, San Ignacio',
    group: ['America/Belize'],
  },
  {
    name: 'America/Chicago',
    alternativeName: 'Central Time',
    mainCities: ['Chicago', 'Houston'],
    formatted: '-05:00 Central Time - Chicago, Houston',
    group: [
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
    ],
  },
  {
    name: 'America/Guatemala',
    alternativeName: 'Central Time',
    mainCities: ['Guatemala City', 'Mixco'],
    formatted: '-06:00 Central Time - Guatemala City, Mixco',
    group: ['America/Guatemala'],
  },
  {
    name: 'America/Managua',
    alternativeName: 'Central Time',
    mainCities: ['Managua', 'León'],
    formatted: '-06:00 Central Time - Managua, León',
    group: ['America/Managua'],
  },
  {
    name: 'America/Mexico_City',
    alternativeName: 'Central Time',
    mainCities: ['Mexico City', 'Iztapalapa'],
    formatted: '-05:00 Central Time - Mexico City, Iztapalapa',
    group: [
      'America/Bahia_Banderas',
      'America/Matamoros',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
    ],
  },
  {
    name: 'America/Costa_Rica',
    alternativeName: 'Central Time',
    mainCities: ['San José', 'Limón'],
    formatted: '-06:00 Central Time - San José, Limón',
    group: ['America/Costa_Rica'],
  },
  {
    name: 'America/El_Salvador',
    alternativeName: 'Central Time',
    mainCities: ['San Salvador', 'Soyapango'],
    formatted: '-06:00 Central Time - San Salvador, Soyapango',
    group: ['America/El_Salvador'],
  },
  {
    name: 'America/Regina',
    alternativeName: 'Central Time',
    mainCities: ['Saskatoon', 'Regina'],
    formatted: '-06:00 Central Time - Saskatoon, Regina',
    group: ['America/Regina', 'America/Swift_Current'],
  },
  {
    name: 'America/Tegucigalpa',
    alternativeName: 'Central Time',
    mainCities: ['Tegucigalpa', 'San Pedro Sula'],
    formatted: '-06:00 Central Time - Tegucigalpa, San Pedro Sula',
    group: ['America/Tegucigalpa'],
  },
  {
    name: 'America/Winnipeg',
    alternativeName: 'Central Time',
    mainCities: ['Winnipeg', 'Brandon'],
    formatted: '-05:00 Central Time - Winnipeg, Brandon',
    group: [
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
    ],
  },
  {
    name: 'Pacific/Galapagos',
    alternativeName: 'Galapagos Time',
    mainCities: ['Galapagos'],
    formatted: '-06:00 Galapagos Time - Galapagos',
    group: ['Pacific/Galapagos'],
  },
  {
    name: 'America/Rio_Branco',
    alternativeName: 'Acre Time',
    mainCities: ['Rio Branco', 'Cruzeiro do Sul'],
    formatted: '-05:00 Acre Time - Rio Branco, Cruzeiro do Sul',
    group: ['America/Eirunepe', 'America/Rio_Branco'],
  },
  {
    name: 'America/Bogota',
    alternativeName: 'Colombia Time',
    mainCities: ['Bogotá', 'Cali'],
    formatted: '-05:00 Colombia Time - Bogotá, Cali',
    group: ['America/Bogota'],
  },
  {
    name: 'America/Havana',
    alternativeName: 'Cuba Time',
    mainCities: ['Havana', 'Santiago de Cuba'],
    formatted: '-04:00 Cuba Time - Havana, Santiago de Cuba',
    group: ['America/Havana'],
  },
  {
    name: 'Pacific/Easter',
    alternativeName: 'Easter Island Summer Time',
    mainCities: ['Easter'],
    formatted: '-06:00 Easter Island Summer Time - Easter',
    group: ['Pacific/Easter'],
  },
  {
    name: 'America/Atikokan',
    alternativeName: 'Eastern Time',
    mainCities: ['Atikokan'],
    formatted: '-05:00 Eastern Time - Atikokan',
    group: ['America/Atikokan'],
  },
  {
    name: 'America/Cancun',
    alternativeName: 'Eastern Time',
    mainCities: ['Cancún', 'Chetumal'],
    formatted: '-05:00 Eastern Time - Cancún, Chetumal',
    group: ['America/Cancun'],
  },
  {
    name: 'America/Grand_Turk',
    alternativeName: 'Eastern Time',
    mainCities: ['Cockburn Town'],
    formatted: '-04:00 Eastern Time - Cockburn Town',
    group: ['America/Grand_Turk'],
  },
  {
    name: 'America/Cayman',
    alternativeName: 'Eastern Time',
    mainCities: ['George Town'],
    formatted: '-05:00 Eastern Time - George Town',
    group: ['America/Cayman'],
  },
  {
    name: 'America/Jamaica',
    alternativeName: 'Eastern Time',
    mainCities: ['Kingston', 'New Kingston'],
    formatted: '-05:00 Eastern Time - Kingston, New Kingston',
    group: ['America/Jamaica'],
  },
  {
    name: 'America/Nassau',
    alternativeName: 'Eastern Time',
    mainCities: ['Nassau', 'Lucaya'],
    formatted: '-04:00 Eastern Time - Nassau, Lucaya',
    group: ['America/Nassau'],
  },
  {
    name: 'America/New_York',
    alternativeName: 'Eastern Time',
    mainCities: ['New York City', 'Brooklyn'],
    formatted: '-04:00 Eastern Time - New York City, Brooklyn',
    group: [
      'America/Detroit',
      'America/Indiana/Indianapolis',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/New_York',
    ],
  },
  {
    name: 'America/Panama',
    alternativeName: 'Eastern Time',
    mainCities: ['Panamá', 'San Miguelito'],
    formatted: '-05:00 Eastern Time - Panamá, San Miguelito',
    group: ['America/Panama'],
  },
  {
    name: 'America/Port-au-Prince',
    alternativeName: 'Eastern Time',
    mainCities: ['Port-au-Prince', 'Carrefour'],
    formatted: '-04:00 Eastern Time - Port-au-Prince, Carrefour',
    group: ['America/Port-au-Prince'],
  },
  {
    name: 'America/Toronto',
    alternativeName: 'Eastern Time',
    mainCities: ['Toronto', 'Montréal'],
    formatted: '-04:00 Eastern Time - Toronto, Montréal',
    group: [
      'America/Iqaluit',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Thunder_Bay',
      'America/Toronto',
    ],
  },
  {
    name: 'America/Guayaquil',
    alternativeName: 'Ecuador Time',
    mainCities: ['Guayaquil', 'Quito'],
    formatted: '-05:00 Ecuador Time - Guayaquil, Quito',
    group: ['America/Guayaquil'],
  },
  {
    name: 'America/Lima',
    alternativeName: 'Peru Time',
    mainCities: ['Lima', 'Arequipa'],
    formatted: '-05:00 Peru Time - Lima, Arequipa',
    group: ['America/Lima'],
  },
  {
    name: 'America/Manaus',
    alternativeName: 'Amazon Time',
    mainCities: ['Manaus', 'Campo Grande'],
    formatted: '-04:00 Amazon Time - Manaus, Campo Grande',
    group: [
      'America/Boa_Vista',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Manaus',
      'America/Porto_Velho',
    ],
  },
  {
    name: 'America/St_Kitts',
    alternativeName: 'Atlantic Time',
    mainCities: ['Basseterre'],
    formatted: '-04:00 Atlantic Time - Basseterre',
    group: ['America/St_Kitts'],
  },
  {
    name: 'America/Montserrat',
    alternativeName: 'Atlantic Time',
    mainCities: ['Brades', 'Plymouth'],
    formatted: '-04:00 Atlantic Time - Brades, Plymouth',
    group: ['America/Montserrat'],
  },
  {
    name: 'America/Barbados',
    alternativeName: 'Atlantic Time',
    mainCities: ['Bridgetown'],
    formatted: '-04:00 Atlantic Time - Bridgetown',
    group: ['America/Barbados'],
  },
  {
    name: 'America/St_Lucia',
    alternativeName: 'Atlantic Time',
    mainCities: ['Castries'],
    formatted: '-04:00 Atlantic Time - Castries',
    group: ['America/St_Lucia'],
  },
  {
    name: 'America/Port_of_Spain',
    alternativeName: 'Atlantic Time',
    mainCities: ['Chaguanas', 'Mon Repos'],
    formatted: '-04:00 Atlantic Time - Chaguanas, Mon Repos',
    group: ['America/Port_of_Spain'],
  },
  {
    name: 'America/Martinique',
    alternativeName: 'Atlantic Time',
    mainCities: ['Fort-de-France', 'Le Lamentin'],
    formatted: '-04:00 Atlantic Time - Fort-de-France, Le Lamentin',
    group: ['America/Martinique'],
  },
  {
    name: 'America/St_Barthelemy',
    alternativeName: 'Atlantic Time',
    mainCities: ['Gustavia'],
    formatted: '-04:00 Atlantic Time - Gustavia',
    group: ['America/St_Barthelemy'],
  },
  {
    name: 'America/Halifax',
    alternativeName: 'Atlantic Time',
    mainCities: ['Halifax', 'Moncton'],
    formatted: '-03:00 Atlantic Time - Halifax, Moncton',
    group: [
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Moncton',
    ],
  },
  {
    name: 'Atlantic/Bermuda',
    alternativeName: 'Atlantic Time',
    mainCities: ['Hamilton'],
    formatted: '-03:00 Atlantic Time - Hamilton',
    group: ['Atlantic/Bermuda'],
  },
  {
    name: 'America/St_Vincent',
    alternativeName: 'Atlantic Time',
    mainCities: ['Kingstown', 'Kingstown Park'],
    formatted: '-04:00 Atlantic Time - Kingstown, Kingstown Park',
    group: ['America/St_Vincent'],
  },
  {
    name: 'America/Kralendijk',
    alternativeName: 'Atlantic Time',
    mainCities: ['Kralendijk'],
    formatted: '-04:00 Atlantic Time - Kralendijk',
    group: ['America/Kralendijk'],
  },
  {
    name: 'America/Guadeloupe',
    alternativeName: 'Atlantic Time',
    mainCities: ['Les Abymes', 'Baie-Mahault'],
    formatted: '-04:00 Atlantic Time - Les Abymes, Baie-Mahault',
    group: ['America/Guadeloupe'],
  },
  {
    name: 'America/Blanc-Sablon',
    alternativeName: 'Atlantic Time',
    mainCities: ['Lévis'],
    formatted: '-04:00 Atlantic Time - Lévis',
    group: ['America/Blanc-Sablon'],
  },
  {
    name: 'America/Marigot',
    alternativeName: 'Atlantic Time',
    mainCities: ['Marigot'],
    formatted: '-04:00 Atlantic Time - Marigot',
    group: ['America/Marigot'],
  },
  {
    name: 'America/Aruba',
    alternativeName: 'Atlantic Time',
    mainCities: ['Oranjestad', 'Tanki Leendert'],
    formatted: '-04:00 Atlantic Time - Oranjestad, Tanki Leendert',
    group: ['America/Aruba'],
  },
  {
    name: 'America/Lower_Princes',
    alternativeName: 'Atlantic Time',
    mainCities: ['Philipsburg'],
    formatted: '-04:00 Atlantic Time - Philipsburg',
    group: ['America/Lower_Princes'],
  },
  {
    name: 'America/Tortola',
    alternativeName: 'Atlantic Time',
    mainCities: ['Road Town'],
    formatted: '-04:00 Atlantic Time - Road Town',
    group: ['America/Tortola'],
  },
  {
    name: 'America/Dominica',
    alternativeName: 'Atlantic Time',
    mainCities: ['Roseau'],
    formatted: '-04:00 Atlantic Time - Roseau',
    group: ['America/Dominica'],
  },
  {
    name: 'America/St_Thomas',
    alternativeName: 'Atlantic Time',
    mainCities: ['Saint Croix', 'Charlotte Amalie'],
    formatted: '-04:00 Atlantic Time - Saint Croix, Charlotte Amalie',
    group: ['America/St_Thomas'],
  },
  {
    name: 'America/Grenada',
    alternativeName: 'Atlantic Time',
    mainCities: ["Saint George's"],
    formatted: "-04:00 Atlantic Time - Saint George's",
    group: ['America/Grenada'],
  },
  {
    name: 'America/Antigua',
    alternativeName: 'Atlantic Time',
    mainCities: ['Saint John’s'],
    formatted: '-04:00 Atlantic Time - Saint John’s',
    group: ['America/Antigua'],
  },
  {
    name: 'America/Puerto_Rico',
    alternativeName: 'Atlantic Time',
    mainCities: ['San Juan', 'Bayamón'],
    formatted: '-04:00 Atlantic Time - San Juan, Bayamón',
    group: ['America/Puerto_Rico'],
  },
  {
    name: 'America/Santo_Domingo',
    alternativeName: 'Atlantic Time',
    mainCities: ['Santo Domingo', 'Santiago de los Caballeros'],
    formatted:
      '-04:00 Atlantic Time - Santo Domingo, Santiago de los Caballeros',
    group: ['America/Santo_Domingo'],
  },
  {
    name: 'America/Anguilla',
    alternativeName: 'Atlantic Time',
    mainCities: ['The Valley'],
    formatted: '-04:00 Atlantic Time - The Valley',
    group: ['America/Anguilla'],
  },
  {
    name: 'America/Thule',
    alternativeName: 'Atlantic Time',
    mainCities: ['Thule'],
    formatted: '-03:00 Atlantic Time - Thule',
    group: ['America/Thule'],
  },
  {
    name: 'America/Curacao',
    alternativeName: 'Atlantic Time',
    mainCities: ['Willemstad'],
    formatted: '-04:00 Atlantic Time - Willemstad',
    group: ['America/Curacao'],
  },
  {
    name: 'America/La_Paz',
    alternativeName: 'Bolivia Time',
    mainCities: ['Santa Cruz de la Sierra', 'Cochabamba'],
    formatted: '-04:00 Bolivia Time - Santa Cruz de la Sierra, Cochabamba',
    group: ['America/La_Paz'],
  },
  {
    name: 'America/Guyana',
    alternativeName: 'Guyana Time',
    mainCities: ['Georgetown', 'Linden'],
    formatted: '-04:00 Guyana Time - Georgetown, Linden',
    group: ['America/Guyana'],
  },
  {
    name: 'America/Caracas',
    alternativeName: 'Venezuela Time',
    mainCities: ['Caracas', 'Maracaibo'],
    formatted: '-04:00 Venezuela Time - Caracas, Maracaibo',
    group: ['America/Caracas'],
  },
  {
    name: 'America/St_Johns',
    alternativeName: 'Newfoundland Time',
    mainCities: ["St. John's", 'Mount Pearl'],
    formatted: "-02:30 Newfoundland Time - St. John's, Mount Pearl",
    group: ['America/St_Johns'],
  },
  {
    name: 'America/Argentina/Buenos_Aires',
    alternativeName: 'Argentina Time',
    mainCities: ['Buenos Aires', 'Córdoba'],
    formatted: '-03:00 Argentina Time - Buenos Aires, Córdoba',
    group: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Catamarca',
      'America/Argentina/Cordoba',
      'America/Argentina/Jujuy',
      'America/Argentina/La_Rioja',
      'America/Argentina/Mendoza',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
    ],
  },
  {
    name: 'America/Sao_Paulo',
    alternativeName: 'Brasilia Time',
    mainCities: ['São Paulo', 'Rio de Janeiro'],
    formatted: '-03:00 Brasilia Time - São Paulo, Rio de Janeiro',
    group: [
      'America/Araguaina',
      'America/Bahia',
      'America/Belem',
      'America/Fortaleza',
      'America/Maceio',
      'America/Recife',
      'America/Santarem',
      'America/Sao_Paulo',
    ],
  },
  {
    name: 'America/Santiago',
    alternativeName: 'Chile Summer Time',
    mainCities: ['Santiago', 'Puente Alto'],
    formatted: '-04:00 Chile Summer Time - Santiago, Puente Alto',
    group: ['America/Santiago'],
  },
  {
    name: 'Atlantic/Stanley',
    alternativeName: 'Falkland Islands Time',
    mainCities: ['Stanley'],
    formatted: '-03:00 Falkland Islands Time - Stanley',
    group: ['Atlantic/Stanley'],
  },
  {
    name: 'America/Cayenne',
    alternativeName: 'French Guiana Time',
    mainCities: ['Cayenne', 'Matoury'],
    formatted: '-03:00 French Guiana Time - Cayenne, Matoury',
    group: ['America/Cayenne'],
  },
  {
    name: 'Antarctica/Palmer',
    alternativeName: 'GMT-03:00',
    mainCities: ['Palmer', 'Rothera'],
    formatted: '-03:00 GMT-03:00 - Palmer, Rothera',
    group: ['Antarctica/Palmer', 'Antarctica/Rothera'],
  },
  {
    name: 'America/Punta_Arenas',
    alternativeName: 'GMT-03:00',
    mainCities: ['Punta Arenas', 'Puerto Natales'],
    formatted: '-03:00 GMT-03:00 - Punta Arenas, Puerto Natales',
    group: ['America/Punta_Arenas'],
  },
  {
    name: 'America/Asuncion',
    alternativeName: 'Paraguay Summer Time',
    mainCities: ['Asunción', 'Ciudad del Este'],
    formatted: '-04:00 Paraguay Summer Time - Asunción, Ciudad del Este',
    group: ['America/Asuncion'],
  },
  {
    name: 'America/Miquelon',
    alternativeName: 'St. Pierre & Miquelon Time',
    mainCities: ['Saint-Pierre'],
    formatted: '-02:00 St. Pierre & Miquelon Time - Saint-Pierre',
    group: ['America/Miquelon'],
  },
  {
    name: 'America/Paramaribo',
    alternativeName: 'Suriname Time',
    mainCities: ['Paramaribo', 'Lelydorp'],
    formatted: '-03:00 Suriname Time - Paramaribo, Lelydorp',
    group: ['America/Paramaribo'],
  },
  {
    name: 'America/Montevideo',
    alternativeName: 'Uruguay Time',
    mainCities: ['Montevideo', 'Salto'],
    formatted: '-03:00 Uruguay Time - Montevideo, Salto',
    group: ['America/Montevideo'],
  },
  {
    name: 'America/Godthab',
    alternativeName: 'West Greenland Time',
    mainCities: ['Nuuk'],
    formatted: '-02:00 West Greenland Time - Nuuk',
    group: ['America/Godthab'],
  },
  {
    name: 'America/Noronha',
    alternativeName: 'Fernando de Noronha Time',
    mainCities: ['Noronha'],
    formatted: '-02:00 Fernando de Noronha Time - Noronha',
    group: ['America/Noronha'],
  },
  {
    name: 'Atlantic/South_Georgia',
    alternativeName: 'South Georgia Time',
    mainCities: ['Grytviken'],
    formatted: '-02:00 South Georgia Time - Grytviken',
    group: ['Atlantic/South_Georgia'],
  },
  {
    name: 'Atlantic/Azores',
    alternativeName: 'Azores Time',
    mainCities: ['Ponta Delgada'],
    formatted: '+00:00 Azores Time - Ponta Delgada',
    group: ['Atlantic/Azores'],
  },
  {
    name: 'Atlantic/Cape_Verde',
    alternativeName: 'Cape Verde Time',
    mainCities: ['Praia', 'Mindelo'],
    formatted: '-01:00 Cape Verde Time - Praia, Mindelo',
    group: ['Atlantic/Cape_Verde'],
  },
  {
    name: 'America/Scoresbysund',
    alternativeName: 'East Greenland Time',
    mainCities: ['Scoresbysund'],
    formatted: '+00:00 East Greenland Time - Scoresbysund',
    group: ['America/Scoresbysund'],
  },
  {
    name: 'Africa/Abidjan',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Abidjan', 'Abobo'],
    formatted: '+00:00 Greenwich Mean Time - Abidjan, Abobo',
    group: ['Africa/Abidjan'],
  },
  {
    name: 'Africa/Accra',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Accra', 'Kumasi'],
    formatted: '+00:00 Greenwich Mean Time - Accra, Kumasi',
    group: ['Africa/Accra'],
  },
  {
    name: 'Africa/Bamako',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Bamako', 'Sikasso'],
    formatted: '+00:00 Greenwich Mean Time - Bamako, Sikasso',
    group: ['Africa/Bamako'],
  },
  {
    name: 'Africa/Bissau',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Bissau', 'Bafatá'],
    formatted: '+00:00 Greenwich Mean Time - Bissau, Bafatá',
    group: ['Africa/Bissau'],
  },
  {
    name: 'Africa/Conakry',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Camayenne', 'Conakry'],
    formatted: '+00:00 Greenwich Mean Time - Camayenne, Conakry',
    group: ['Africa/Conakry'],
  },
  {
    name: 'Africa/Dakar',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Dakar', 'Pikine'],
    formatted: '+00:00 Greenwich Mean Time - Dakar, Pikine',
    group: ['Africa/Dakar'],
  },
  {
    name: 'America/Danmarkshavn',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Danmarkshavn'],
    formatted: '+00:00 Greenwich Mean Time - Danmarkshavn',
    group: ['America/Danmarkshavn'],
  },
  {
    name: 'Europe/Isle_of_Man',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Douglas'],
    formatted: '+01:00 Greenwich Mean Time - Douglas',
    group: ['Europe/Isle_of_Man'],
  },
  {
    name: 'Europe/Dublin',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Dublin', 'Cork'],
    formatted: '+01:00 Greenwich Mean Time - Dublin, Cork',
    group: ['Europe/Dublin'],
  },
  {
    name: 'Africa/Freetown',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Freetown', 'Bo'],
    formatted: '+00:00 Greenwich Mean Time - Freetown, Bo',
    group: ['Africa/Freetown'],
  },
  {
    name: 'Atlantic/St_Helena',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Jamestown'],
    formatted: '+00:00 Greenwich Mean Time - Jamestown',
    group: ['Atlantic/St_Helena'],
  },
  {
    name: 'Africa/Lome',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Lomé', 'Sokodé'],
    formatted: '+00:00 Greenwich Mean Time - Lomé, Sokodé',
    group: ['Africa/Lome'],
  },
  {
    name: 'Europe/London',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['London', 'Birmingham'],
    formatted: '+01:00 Greenwich Mean Time - London, Birmingham',
    group: ['Europe/London'],
  },
  {
    name: 'Africa/Monrovia',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Monrovia', 'Gbarnga'],
    formatted: '+00:00 Greenwich Mean Time - Monrovia, Gbarnga',
    group: ['Africa/Monrovia'],
  },
  {
    name: 'Africa/Nouakchott',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Nouakchott', 'Nouadhibou'],
    formatted: '+00:00 Greenwich Mean Time - Nouakchott, Nouadhibou',
    group: ['Africa/Nouakchott'],
  },
  {
    name: 'Africa/Ouagadougou',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Ouagadougou', 'Bobo-Dioulasso'],
    formatted: '+00:00 Greenwich Mean Time - Ouagadougou, Bobo-Dioulasso',
    group: ['Africa/Ouagadougou'],
  },
  {
    name: 'Atlantic/Reykjavik',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Reykjavík', 'Kópavogur'],
    formatted: '+00:00 Greenwich Mean Time - Reykjavík, Kópavogur',
    group: ['Atlantic/Reykjavik'],
  },
  {
    name: 'Europe/Jersey',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Saint Helier'],
    formatted: '+01:00 Greenwich Mean Time - Saint Helier',
    group: ['Europe/Jersey'],
  },
  {
    name: 'Europe/Guernsey',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Saint Peter Port'],
    formatted: '+01:00 Greenwich Mean Time - Saint Peter Port',
    group: ['Europe/Guernsey'],
  },
  {
    name: 'Africa/Banjul',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Serekunda', 'Brikama'],
    formatted: '+00:00 Greenwich Mean Time - Serekunda, Brikama',
    group: ['Africa/Banjul'],
  },
  {
    name: 'Africa/Sao_Tome',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['São Tomé'],
    formatted: '+00:00 Greenwich Mean Time - São Tomé',
    group: ['Africa/Sao_Tome'],
  },
  {
    name: 'Antarctica/Troll',
    alternativeName: 'Greenwich Mean Time',
    mainCities: ['Troll'],
    formatted: '+02:00 Greenwich Mean Time - Troll',
    group: ['Antarctica/Troll'],
  },
  {
    name: 'Atlantic/Canary',
    alternativeName: 'Western European Time',
    mainCities: ['Las Palmas de Gran Canaria', 'Santa Cruz de Tenerife'],
    formatted:
      '+01:00 Western European Time - Las Palmas de Gran Canaria, Santa Cruz de Tenerife',
    group: ['Atlantic/Canary'],
  },
  {
    name: 'Europe/Lisbon',
    alternativeName: 'Western European Time',
    mainCities: ['Lisbon', 'Porto'],
    formatted: '+01:00 Western European Time - Lisbon, Porto',
    group: ['Atlantic/Madeira', 'Europe/Lisbon'],
  },
  {
    name: 'Atlantic/Faroe',
    alternativeName: 'Western European Time',
    mainCities: ['Tórshavn'],
    formatted: '+01:00 Western European Time - Tórshavn',
    group: ['Atlantic/Faroe'],
  },
  {
    name: 'Africa/Algiers',
    alternativeName: 'Central European Time',
    mainCities: ['Algiers', 'Boumerdas'],
    formatted: '+01:00 Central European Time - Algiers, Boumerdas',
    group: ['Africa/Algiers'],
  },
  {
    name: 'Europe/Amsterdam',
    alternativeName: 'Central European Time',
    mainCities: ['Amsterdam', 'Rotterdam'],
    formatted: '+02:00 Central European Time - Amsterdam, Rotterdam',
    group: ['Europe/Amsterdam'],
  },
  {
    name: 'Europe/Andorra',
    alternativeName: 'Central European Time',
    mainCities: ['Andorra la Vella', 'les Escaldes'],
    formatted: '+02:00 Central European Time - Andorra la Vella, les Escaldes',
    group: ['Europe/Andorra'],
  },
  {
    name: 'Europe/Belgrade',
    alternativeName: 'Central European Time',
    mainCities: ['Belgrade', 'Niš'],
    formatted: '+02:00 Central European Time - Belgrade, Niš',
    group: ['Europe/Belgrade'],
  },
  {
    name: 'Europe/Berlin',
    alternativeName: 'Central European Time',
    mainCities: ['Berlin', 'Hamburg'],
    formatted: '+02:00 Central European Time - Berlin, Hamburg',
    group: ['Europe/Berlin', 'Europe/Busingen'],
  },
  {
    name: 'Europe/Malta',
    alternativeName: 'Central European Time',
    mainCities: ['Birkirkara', 'Qormi'],
    formatted: '+02:00 Central European Time - Birkirkara, Qormi',
    group: ['Europe/Malta'],
  },
  {
    name: 'Europe/Bratislava',
    alternativeName: 'Central European Time',
    mainCities: ['Bratislava', 'Košice'],
    formatted: '+02:00 Central European Time - Bratislava, Košice',
    group: ['Europe/Bratislava'],
  },
  {
    name: 'Europe/Brussels',
    alternativeName: 'Central European Time',
    mainCities: ['Brussels', 'Antwerpen'],
    formatted: '+02:00 Central European Time - Brussels, Antwerpen',
    group: ['Europe/Brussels'],
  },
  {
    name: 'Europe/Budapest',
    alternativeName: 'Central European Time',
    mainCities: ['Budapest', 'Debrecen'],
    formatted: '+02:00 Central European Time - Budapest, Debrecen',
    group: ['Europe/Budapest'],
  },
  {
    name: 'Europe/Copenhagen',
    alternativeName: 'Central European Time',
    mainCities: ['Copenhagen', 'Århus'],
    formatted: '+02:00 Central European Time - Copenhagen, Århus',
    group: ['Europe/Copenhagen'],
  },
  {
    name: 'Europe/Gibraltar',
    alternativeName: 'Central European Time',
    mainCities: ['Gibraltar'],
    formatted: '+02:00 Central European Time - Gibraltar',
    group: ['Europe/Gibraltar'],
  },
  {
    name: 'Europe/Ljubljana',
    alternativeName: 'Central European Time',
    mainCities: ['Ljubljana', 'Maribor'],
    formatted: '+02:00 Central European Time - Ljubljana, Maribor',
    group: ['Europe/Ljubljana'],
  },
  {
    name: 'Arctic/Longyearbyen',
    alternativeName: 'Central European Time',
    mainCities: ['Longyearbyen'],
    formatted: '+02:00 Central European Time - Longyearbyen',
    group: ['Arctic/Longyearbyen'],
  },
  {
    name: 'Europe/Luxembourg',
    alternativeName: 'Central European Time',
    mainCities: ['Luxembourg', 'Esch-sur-Alzette'],
    formatted: '+02:00 Central European Time - Luxembourg, Esch-sur-Alzette',
    group: ['Europe/Luxembourg'],
  },
  {
    name: 'Europe/Madrid',
    alternativeName: 'Central European Time',
    mainCities: ['Madrid', 'Barcelona'],
    formatted: '+02:00 Central European Time - Madrid, Barcelona',
    group: ['Africa/Ceuta', 'Europe/Madrid'],
  },
  {
    name: 'Europe/Monaco',
    alternativeName: 'Central European Time',
    mainCities: ['Monaco', 'Monte-Carlo'],
    formatted: '+02:00 Central European Time - Monaco, Monte-Carlo',
    group: ['Europe/Monaco'],
  },
  {
    name: 'Europe/Oslo',
    alternativeName: 'Central European Time',
    mainCities: ['Oslo', 'Bergen'],
    formatted: '+02:00 Central European Time - Oslo, Bergen',
    group: ['Europe/Oslo'],
  },
  {
    name: 'Europe/Paris',
    alternativeName: 'Central European Time',
    mainCities: ['Paris', 'Marseille'],
    formatted: '+02:00 Central European Time - Paris, Marseille',
    group: ['Europe/Paris'],
  },
  {
    name: 'Europe/Podgorica',
    alternativeName: 'Central European Time',
    mainCities: ['Podgorica', 'Nikšić'],
    formatted: '+02:00 Central European Time - Podgorica, Nikšić',
    group: ['Europe/Podgorica'],
  },
  {
    name: 'Europe/Prague',
    alternativeName: 'Central European Time',
    mainCities: ['Prague', 'Brno'],
    formatted: '+02:00 Central European Time - Prague, Brno',
    group: ['Europe/Prague'],
  },
  {
    name: 'Europe/Rome',
    alternativeName: 'Central European Time',
    mainCities: ['Rome', 'Milan'],
    formatted: '+02:00 Central European Time - Rome, Milan',
    group: ['Europe/Rome'],
  },
  {
    name: 'Europe/San_Marino',
    alternativeName: 'Central European Time',
    mainCities: ['San Marino'],
    formatted: '+02:00 Central European Time - San Marino',
    group: ['Europe/San_Marino'],
  },
  {
    name: 'Europe/Sarajevo',
    alternativeName: 'Central European Time',
    mainCities: ['Sarajevo', 'Banja Luka'],
    formatted: '+02:00 Central European Time - Sarajevo, Banja Luka',
    group: ['Europe/Sarajevo'],
  },
  {
    name: 'Europe/Skopje',
    alternativeName: 'Central European Time',
    mainCities: ['Skopje', 'Bitola'],
    formatted: '+02:00 Central European Time - Skopje, Bitola',
    group: ['Europe/Skopje'],
  },
  {
    name: 'Europe/Stockholm',
    alternativeName: 'Central European Time',
    mainCities: ['Stockholm', 'Göteborg'],
    formatted: '+02:00 Central European Time - Stockholm, Göteborg',
    group: ['Europe/Stockholm'],
  },
  {
    name: 'Europe/Tirane',
    alternativeName: 'Central European Time',
    mainCities: ['Tirana', 'Durrës'],
    formatted: '+02:00 Central European Time - Tirana, Durrës',
    group: ['Europe/Tirane'],
  },
  {
    name: 'Africa/Tunis',
    alternativeName: 'Central European Time',
    mainCities: ['Tunis', 'Sfax'],
    formatted: '+01:00 Central European Time - Tunis, Sfax',
    group: ['Africa/Tunis'],
  },
  {
    name: 'Europe/Vaduz',
    alternativeName: 'Central European Time',
    mainCities: ['Vaduz'],
    formatted: '+02:00 Central European Time - Vaduz',
    group: ['Europe/Vaduz'],
  },
  {
    name: 'Europe/Vatican',
    alternativeName: 'Central European Time',
    mainCities: ['Vatican City'],
    formatted: '+02:00 Central European Time - Vatican City',
    group: ['Europe/Vatican'],
  },
  {
    name: 'Europe/Vienna',
    alternativeName: 'Central European Time',
    mainCities: ['Vienna', 'Graz'],
    formatted: '+02:00 Central European Time - Vienna, Graz',
    group: ['Europe/Vienna'],
  },
  {
    name: 'Europe/Warsaw',
    alternativeName: 'Central European Time',
    mainCities: ['Warsaw', 'Łódź'],
    formatted: '+02:00 Central European Time - Warsaw, Łódź',
    group: ['Europe/Warsaw'],
  },
  {
    name: 'Europe/Zagreb',
    alternativeName: 'Central European Time',
    mainCities: ['Zagreb', 'Split'],
    formatted: '+02:00 Central European Time - Zagreb, Split',
    group: ['Europe/Zagreb'],
  },
  {
    name: 'Europe/Zurich',
    alternativeName: 'Central European Time',
    mainCities: ['Zürich', 'Genève'],
    formatted: '+02:00 Central European Time - Zürich, Genève',
    group: ['Europe/Zurich'],
  },
  {
    name: 'Africa/Casablanca',
    alternativeName: 'GMT+01:00',
    mainCities: ['Casablanca', 'Rabat'],
    formatted: '+01:00 GMT+01:00 - Casablanca, Rabat',
    group: ['Africa/Casablanca'],
  },
  {
    name: 'Africa/El_Aaiun',
    alternativeName: 'GMT+01:00',
    mainCities: ['Laayoune', 'Dakhla'],
    formatted: '+01:00 GMT+01:00 - Laayoune, Dakhla',
    group: ['Africa/El_Aaiun'],
  },
  {
    name: 'Africa/Bangui',
    alternativeName: 'West Africa Time',
    mainCities: ['Bangui', 'Bimbo'],
    formatted: '+01:00 West Africa Time - Bangui, Bimbo',
    group: ['Africa/Bangui'],
  },
  {
    name: 'Africa/Malabo',
    alternativeName: 'West Africa Time',
    mainCities: ['Bata', 'Malabo'],
    formatted: '+01:00 West Africa Time - Bata, Malabo',
    group: ['Africa/Malabo'],
  },
  {
    name: 'Africa/Brazzaville',
    alternativeName: 'West Africa Time',
    mainCities: ['Brazzaville', 'Pointe-Noire'],
    formatted: '+01:00 West Africa Time - Brazzaville, Pointe-Noire',
    group: ['Africa/Brazzaville'],
  },
  {
    name: 'Africa/Porto-Novo',
    alternativeName: 'West Africa Time',
    mainCities: ['Cotonou', 'Abomey-Calavi'],
    formatted: '+01:00 West Africa Time - Cotonou, Abomey-Calavi',
    group: ['Africa/Porto-Novo'],
  },
  {
    name: 'Africa/Douala',
    alternativeName: 'West Africa Time',
    mainCities: ['Douala', 'Yaoundé'],
    formatted: '+01:00 West Africa Time - Douala, Yaoundé',
    group: ['Africa/Douala'],
  },
  {
    name: 'Africa/Kinshasa',
    alternativeName: 'West Africa Time',
    mainCities: ['Kinshasa', 'Masina'],
    formatted: '+01:00 West Africa Time - Kinshasa, Masina',
    group: ['Africa/Kinshasa'],
  },
  {
    name: 'Africa/Lagos',
    alternativeName: 'West Africa Time',
    mainCities: ['Lagos', 'Kano'],
    formatted: '+01:00 West Africa Time - Lagos, Kano',
    group: ['Africa/Lagos'],
  },
  {
    name: 'Africa/Libreville',
    alternativeName: 'West Africa Time',
    mainCities: ['Libreville', 'Port-Gentil'],
    formatted: '+01:00 West Africa Time - Libreville, Port-Gentil',
    group: ['Africa/Libreville'],
  },
  {
    name: 'Africa/Luanda',
    alternativeName: 'West Africa Time',
    mainCities: ['Luanda', 'N’dalatando'],
    formatted: '+01:00 West Africa Time - Luanda, N’dalatando',
    group: ['Africa/Luanda'],
  },
  {
    name: 'Africa/Ndjamena',
    alternativeName: 'West Africa Time',
    mainCities: ["N'Djamena", 'Moundou'],
    formatted: "+01:00 West Africa Time - N'Djamena, Moundou",
    group: ['Africa/Ndjamena'],
  },
  {
    name: 'Africa/Niamey',
    alternativeName: 'West Africa Time',
    mainCities: ['Niamey', 'Zinder'],
    formatted: '+01:00 West Africa Time - Niamey, Zinder',
    group: ['Africa/Niamey'],
  },
  {
    name: 'Africa/Bujumbura',
    alternativeName: 'Central Africa Time',
    mainCities: ['Bujumbura', 'Muyinga'],
    formatted: '+02:00 Central Africa Time - Bujumbura, Muyinga',
    group: ['Africa/Bujumbura'],
  },
  {
    name: 'Africa/Gaborone',
    alternativeName: 'Central Africa Time',
    mainCities: ['Gaborone', 'Francistown'],
    formatted: '+02:00 Central Africa Time - Gaborone, Francistown',
    group: ['Africa/Gaborone'],
  },
  {
    name: 'Africa/Harare',
    alternativeName: 'Central Africa Time',
    mainCities: ['Harare', 'Bulawayo'],
    formatted: '+02:00 Central Africa Time - Harare, Bulawayo',
    group: ['Africa/Harare'],
  },
  {
    name: 'Africa/Khartoum',
    alternativeName: 'Central Africa Time',
    mainCities: ['Khartoum', 'Omdurman'],
    formatted: '+02:00 Central Africa Time - Khartoum, Omdurman',
    group: ['Africa/Khartoum'],
  },
  {
    name: 'Africa/Kigali',
    alternativeName: 'Central Africa Time',
    mainCities: ['Kigali', 'Butare'],
    formatted: '+02:00 Central Africa Time - Kigali, Butare',
    group: ['Africa/Kigali'],
  },
  {
    name: 'Africa/Blantyre',
    alternativeName: 'Central Africa Time',
    mainCities: ['Lilongwe', 'Blantyre'],
    formatted: '+02:00 Central Africa Time - Lilongwe, Blantyre',
    group: ['Africa/Blantyre'],
  },
  {
    name: 'Africa/Lubumbashi',
    alternativeName: 'Central Africa Time',
    mainCities: ['Lubumbashi', 'Mbuji-Mayi'],
    formatted: '+02:00 Central Africa Time - Lubumbashi, Mbuji-Mayi',
    group: ['Africa/Lubumbashi'],
  },
  {
    name: 'Africa/Lusaka',
    alternativeName: 'Central Africa Time',
    mainCities: ['Lusaka', 'Kitwe'],
    formatted: '+02:00 Central Africa Time - Lusaka, Kitwe',
    group: ['Africa/Lusaka'],
  },
  {
    name: 'Africa/Maputo',
    alternativeName: 'Central Africa Time',
    mainCities: ['Maputo', 'Matola'],
    formatted: '+02:00 Central Africa Time - Maputo, Matola',
    group: ['Africa/Maputo'],
  },
  {
    name: 'Africa/Windhoek',
    alternativeName: 'Central Africa Time',
    mainCities: ['Windhoek', 'Rundu'],
    formatted: '+02:00 Central Africa Time - Windhoek, Rundu',
    group: ['Africa/Windhoek'],
  },
  {
    name: 'Asia/Damascus',
    alternativeName: 'Eastern European Time',
    mainCities: ['Aleppo', 'Damascus'],
    formatted: '+03:00 Eastern European Time - Aleppo, Damascus',
    group: ['Asia/Damascus'],
  },
  {
    name: 'Asia/Amman',
    alternativeName: 'Eastern European Time',
    mainCities: ['Amman', 'Zarqa'],
    formatted: '+03:00 Eastern European Time - Amman, Zarqa',
    group: ['Asia/Amman'],
  },
  {
    name: 'Europe/Athens',
    alternativeName: 'Eastern European Time',
    mainCities: ['Athens', 'Thessaloníki'],
    formatted: '+03:00 Eastern European Time - Athens, Thessaloníki',
    group: ['Europe/Athens'],
  },
  {
    name: 'Asia/Beirut',
    alternativeName: 'Eastern European Time',
    mainCities: ['Beirut', 'Ra’s Bayrūt'],
    formatted: '+03:00 Eastern European Time - Beirut, Ra’s Bayrūt',
    group: ['Asia/Beirut'],
  },
  {
    name: 'Europe/Bucharest',
    alternativeName: 'Eastern European Time',
    mainCities: ['Bucharest', 'Sector 3'],
    formatted: '+03:00 Eastern European Time - Bucharest, Sector 3',
    group: ['Europe/Bucharest'],
  },
  {
    name: 'Africa/Cairo',
    alternativeName: 'Eastern European Time',
    mainCities: ['Cairo', 'Alexandria'],
    formatted: '+02:00 Eastern European Time - Cairo, Alexandria',
    group: ['Africa/Cairo'],
  },
  {
    name: 'Europe/Chisinau',
    alternativeName: 'Eastern European Time',
    mainCities: ['Chisinau', 'Tiraspol'],
    formatted: '+03:00 Eastern European Time - Chisinau, Tiraspol',
    group: ['Europe/Chisinau'],
  },
  {
    name: 'Asia/Hebron',
    alternativeName: 'Eastern European Time',
    mainCities: ['East Jerusalem', 'Gaza'],
    formatted: '+03:00 Eastern European Time - East Jerusalem, Gaza',
    group: ['Asia/Gaza', 'Asia/Hebron'],
  },
  {
    name: 'Europe/Helsinki',
    alternativeName: 'Eastern European Time',
    mainCities: ['Helsinki', 'Espoo'],
    formatted: '+03:00 Eastern European Time - Helsinki, Espoo',
    group: ['Europe/Helsinki'],
  },
  {
    name: 'Europe/Kaliningrad',
    alternativeName: 'Eastern European Time',
    mainCities: ['Kaliningrad', 'Chernyakhovsk'],
    formatted: '+02:00 Eastern European Time - Kaliningrad, Chernyakhovsk',
    group: ['Europe/Kaliningrad'],
  },
  {
    name: 'Europe/Kiev',
    alternativeName: 'Eastern European Time',
    mainCities: ['Kyiv', 'Kharkiv'],
    formatted: '+03:00 Eastern European Time - Kyiv, Kharkiv',
    group: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
  },
  {
    name: 'Europe/Mariehamn',
    alternativeName: 'Eastern European Time',
    mainCities: ['Mariehamn'],
    formatted: '+03:00 Eastern European Time - Mariehamn',
    group: ['Europe/Mariehamn'],
  },
  {
    name: 'Asia/Nicosia',
    alternativeName: 'Eastern European Time',
    mainCities: ['Nicosia', 'Limassol'],
    formatted: '+03:00 Eastern European Time - Nicosia, Limassol',
    group: ['Asia/Famagusta', 'Asia/Nicosia'],
  },
  {
    name: 'Europe/Riga',
    alternativeName: 'Eastern European Time',
    mainCities: ['Riga', 'Daugavpils'],
    formatted: '+03:00 Eastern European Time - Riga, Daugavpils',
    group: ['Europe/Riga'],
  },
  {
    name: 'Europe/Sofia',
    alternativeName: 'Eastern European Time',
    mainCities: ['Sofia', 'Plovdiv'],
    formatted: '+03:00 Eastern European Time - Sofia, Plovdiv',
    group: ['Europe/Sofia'],
  },
  {
    name: 'Europe/Tallinn',
    alternativeName: 'Eastern European Time',
    mainCities: ['Tallinn', 'Tartu'],
    formatted: '+03:00 Eastern European Time - Tallinn, Tartu',
    group: ['Europe/Tallinn'],
  },
  {
    name: 'Africa/Tripoli',
    alternativeName: 'Eastern European Time',
    mainCities: ['Tripoli', 'Benghazi'],
    formatted: '+02:00 Eastern European Time - Tripoli, Benghazi',
    group: ['Africa/Tripoli'],
  },
  {
    name: 'Europe/Vilnius',
    alternativeName: 'Eastern European Time',
    mainCities: ['Vilnius', 'Kaunas'],
    formatted: '+03:00 Eastern European Time - Vilnius, Kaunas',
    group: ['Europe/Vilnius'],
  },
  {
    name: 'Asia/Jerusalem',
    alternativeName: 'Israel Time',
    mainCities: ['Jerusalem', 'Tel Aviv'],
    formatted: '+03:00 Israel Time - Jerusalem, Tel Aviv',
    group: ['Asia/Jerusalem'],
  },
  {
    name: 'Africa/Johannesburg',
    alternativeName: 'South Africa Time',
    mainCities: ['Cape Town', 'Durban'],
    formatted: '+02:00 South Africa Time - Cape Town, Durban',
    group: ['Africa/Johannesburg'],
  },
  {
    name: 'Africa/Mbabane',
    alternativeName: 'South Africa Time',
    mainCities: ['Manzini', 'Mbabane'],
    formatted: '+02:00 South Africa Time - Manzini, Mbabane',
    group: ['Africa/Mbabane'],
  },
  {
    name: 'Africa/Maseru',
    alternativeName: 'South Africa Time',
    mainCities: ['Maseru', 'Mafeteng'],
    formatted: '+02:00 South Africa Time - Maseru, Mafeteng',
    group: ['Africa/Maseru'],
  },
  {
    name: 'Asia/Kuwait',
    alternativeName: 'Arabian Time',
    mainCities: ['Al Aḩmadī', 'Ḩawallī'],
    formatted: '+03:00 Arabian Time - Al Aḩmadī, Ḩawallī',
    group: ['Asia/Kuwait'],
  },
  {
    name: 'Asia/Baghdad',
    alternativeName: 'Arabian Time',
    mainCities: ['Baghdad', 'Basrah'],
    formatted: '+03:00 Arabian Time - Baghdad, Basrah',
    group: ['Asia/Baghdad'],
  },
  {
    name: 'Asia/Qatar',
    alternativeName: 'Arabian Time',
    mainCities: ['Doha', 'Ar Rayyān'],
    formatted: '+03:00 Arabian Time - Doha, Ar Rayyān',
    group: ['Asia/Qatar'],
  },
  {
    name: 'Asia/Bahrain',
    alternativeName: 'Arabian Time',
    mainCities: ['Manama', 'Al Muharraq'],
    formatted: '+03:00 Arabian Time - Manama, Al Muharraq',
    group: ['Asia/Bahrain'],
  },
  {
    name: 'Asia/Riyadh',
    alternativeName: 'Arabian Time',
    mainCities: ['Riyadh', 'Jeddah'],
    formatted: '+03:00 Arabian Time - Riyadh, Jeddah',
    group: ['Asia/Riyadh'],
  },
  {
    name: 'Asia/Aden',
    alternativeName: 'Arabian Time',
    mainCities: ['Sanaa', 'Hudaida'],
    formatted: '+03:00 Arabian Time - Sanaa, Hudaida',
    group: ['Asia/Aden'],
  },
  {
    name: 'Africa/Addis_Ababa',
    alternativeName: 'East Africa Time',
    mainCities: ['Addis Ababa', 'Dire Dawa'],
    formatted: '+03:00 East Africa Time - Addis Ababa, Dire Dawa',
    group: ['Africa/Addis_Ababa'],
  },
  {
    name: 'Indian/Antananarivo',
    alternativeName: 'East Africa Time',
    mainCities: ['Antananarivo', 'Toamasina'],
    formatted: '+03:00 East Africa Time - Antananarivo, Toamasina',
    group: ['Indian/Antananarivo'],
  },
  {
    name: 'Africa/Asmara',
    alternativeName: 'East Africa Time',
    mainCities: ['Asmara', 'Keren'],
    formatted: '+03:00 East Africa Time - Asmara, Keren',
    group: ['Africa/Asmara'],
  },
  {
    name: 'Africa/Dar_es_Salaam',
    alternativeName: 'East Africa Time',
    mainCities: ['Dar es Salaam', 'Mwanza'],
    formatted: '+03:00 East Africa Time - Dar es Salaam, Mwanza',
    group: ['Africa/Dar_es_Salaam'],
  },
  {
    name: 'Africa/Djibouti',
    alternativeName: 'East Africa Time',
    mainCities: ['Djibouti', "'Ali Sabieh"],
    formatted: "+03:00 East Africa Time - Djibouti, 'Ali Sabieh",
    group: ['Africa/Djibouti'],
  },
  {
    name: 'Africa/Juba',
    alternativeName: 'East Africa Time',
    mainCities: ['Juba', 'Winejok'],
    formatted: '+03:00 East Africa Time - Juba, Winejok',
    group: ['Africa/Juba'],
  },
  {
    name: 'Africa/Kampala',
    alternativeName: 'East Africa Time',
    mainCities: ['Kampala', 'Gulu'],
    formatted: '+03:00 East Africa Time - Kampala, Gulu',
    group: ['Africa/Kampala'],
  },
  {
    name: 'Indian/Mayotte',
    alternativeName: 'East Africa Time',
    mainCities: ['Mamoudzou', 'Koungou'],
    formatted: '+03:00 East Africa Time - Mamoudzou, Koungou',
    group: ['Indian/Mayotte'],
  },
  {
    name: 'Africa/Mogadishu',
    alternativeName: 'East Africa Time',
    mainCities: ['Mogadishu', 'Hargeysa'],
    formatted: '+03:00 East Africa Time - Mogadishu, Hargeysa',
    group: ['Africa/Mogadishu'],
  },
  {
    name: 'Indian/Comoro',
    alternativeName: 'East Africa Time',
    mainCities: ['Moroni', 'Moutsamoudou'],
    formatted: '+03:00 East Africa Time - Moroni, Moutsamoudou',
    group: ['Indian/Comoro'],
  },
  {
    name: 'Africa/Nairobi',
    alternativeName: 'East Africa Time',
    mainCities: ['Nairobi', 'Mombasa'],
    formatted: '+03:00 East Africa Time - Nairobi, Mombasa',
    group: ['Africa/Nairobi'],
  },
  {
    name: 'Europe/Istanbul',
    alternativeName: 'GMT+03:00',
    mainCities: ['Istanbul', 'Ankara'],
    formatted: '+03:00 GMT+03:00 - Istanbul, Ankara',
    group: ['Europe/Istanbul'],
  },
  {
    name: 'Europe/Minsk',
    alternativeName: 'Moscow Time',
    mainCities: ['Minsk', "Homyel'"],
    formatted: "+03:00 Moscow Time - Minsk, Homyel'",
    group: ['Europe/Minsk'],
  },
  {
    name: 'Europe/Moscow',
    alternativeName: 'Moscow Time',
    mainCities: ['Moscow', 'Saint Petersburg'],
    formatted: '+03:00 Moscow Time - Moscow, Saint Petersburg',
    group: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol'],
  },
  {
    name: 'Antarctica/Syowa',
    alternativeName: 'Syowa Time',
    mainCities: ['Syowa'],
    formatted: '+03:00 Syowa Time - Syowa',
    group: ['Antarctica/Syowa'],
  },
  {
    name: 'Asia/Tehran',
    alternativeName: 'Iran Time',
    mainCities: ['Tehran', 'Mashhad'],
    formatted: '+04:30 Iran Time - Tehran, Mashhad',
    group: ['Asia/Tehran'],
  },
  {
    name: 'Asia/Yerevan',
    alternativeName: 'Armenia Time',
    mainCities: ['Yerevan', 'Gyumri'],
    formatted: '+04:00 Armenia Time - Yerevan, Gyumri',
    group: ['Asia/Yerevan'],
  },
  {
    name: 'Asia/Baku',
    alternativeName: 'Azerbaijan Time',
    mainCities: ['Baku', 'Ganja'],
    formatted: '+04:00 Azerbaijan Time - Baku, Ganja',
    group: ['Asia/Baku'],
  },
  {
    name: 'Asia/Tbilisi',
    alternativeName: 'Georgia Time',
    mainCities: ['Tbilisi', 'Kutaisi'],
    formatted: '+04:00 Georgia Time - Tbilisi, Kutaisi',
    group: ['Asia/Tbilisi'],
  },
  {
    name: 'Asia/Dubai',
    alternativeName: 'Gulf Time',
    mainCities: ['Dubai', 'Sharjah'],
    formatted: '+04:00 Gulf Time - Dubai, Sharjah',
    group: ['Asia/Dubai'],
  },
  {
    name: 'Asia/Muscat',
    alternativeName: 'Gulf Time',
    mainCities: ['Muscat', 'Seeb'],
    formatted: '+04:00 Gulf Time - Muscat, Seeb',
    group: ['Asia/Muscat'],
  },
  {
    name: 'Indian/Mauritius',
    alternativeName: 'Mauritius Time',
    mainCities: ['Port Louis', 'Beau Bassin-Rose Hill'],
    formatted: '+04:00 Mauritius Time - Port Louis, Beau Bassin-Rose Hill',
    group: ['Indian/Mauritius'],
  },
  {
    name: 'Indian/Reunion',
    alternativeName: 'Réunion Time',
    mainCities: ['Saint-Denis', 'Saint-Paul'],
    formatted: '+04:00 Réunion Time - Saint-Denis, Saint-Paul',
    group: ['Indian/Reunion'],
  },
  {
    name: 'Europe/Samara',
    alternativeName: 'Samara Time',
    mainCities: ['Samara', 'Volgograd'],
    formatted: '+04:00 Samara Time - Samara, Volgograd',
    group: [
      'Europe/Astrakhan',
      'Europe/Samara',
      'Europe/Saratov',
      'Europe/Ulyanovsk',
      'Europe/Volgograd',
    ],
  },
  {
    name: 'Indian/Mahe',
    alternativeName: 'Seychelles Time',
    mainCities: ['Victoria'],
    formatted: '+04:00 Seychelles Time - Victoria',
    group: ['Indian/Mahe'],
  },
  {
    name: 'Asia/Kabul',
    alternativeName: 'Afghanistan Time',
    mainCities: ['Kabul', 'Kandahār'],
    formatted: '+04:30 Afghanistan Time - Kabul, Kandahār',
    group: ['Asia/Kabul'],
  },
  {
    name: 'Indian/Kerguelen',
    alternativeName: 'French Southern & Antarctic Time',
    mainCities: ['Port-aux-Français'],
    formatted: '+05:00 French Southern & Antarctic Time - Port-aux-Français',
    group: ['Indian/Kerguelen'],
  },
  {
    name: 'Indian/Maldives',
    alternativeName: 'Maldives Time',
    mainCities: ['Male'],
    formatted: '+05:00 Maldives Time - Male',
    group: ['Indian/Maldives'],
  },
  {
    name: 'Antarctica/Mawson',
    alternativeName: 'Mawson Time',
    mainCities: ['Mawson'],
    formatted: '+05:00 Mawson Time - Mawson',
    group: ['Antarctica/Mawson'],
  },
  {
    name: 'Asia/Karachi',
    alternativeName: 'Pakistan Time',
    mainCities: ['Karachi', 'Lahore'],
    formatted: '+05:00 Pakistan Time - Karachi, Lahore',
    group: ['Asia/Karachi'],
  },
  {
    name: 'Asia/Dushanbe',
    alternativeName: 'Tajikistan Time',
    mainCities: ['Dushanbe', 'Khujand'],
    formatted: '+05:00 Tajikistan Time - Dushanbe, Khujand',
    group: ['Asia/Dushanbe'],
  },
  {
    name: 'Asia/Ashgabat',
    alternativeName: 'Turkmenistan Time',
    mainCities: ['Ashgabat', 'Türkmenabat'],
    formatted: '+05:00 Turkmenistan Time - Ashgabat, Türkmenabat',
    group: ['Asia/Ashgabat'],
  },
  {
    name: 'Asia/Tashkent',
    alternativeName: 'Uzbekistan Time',
    mainCities: ['Tashkent', 'Namangan'],
    formatted: '+05:00 Uzbekistan Time - Tashkent, Namangan',
    group: ['Asia/Samarkand', 'Asia/Tashkent'],
  },
  {
    name: 'Asia/Qyzylorda',
    alternativeName: 'West Kazakhstan Time',
    mainCities: ['Kyzylorda', 'Aktobe'],
    formatted: '+05:00 West Kazakhstan Time - Kyzylorda, Aktobe',
    group: [
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Atyrau',
      'Asia/Oral',
      'Asia/Qyzylorda',
    ],
  },
  {
    name: 'Asia/Yekaterinburg',
    alternativeName: 'Yekaterinburg Time',
    mainCities: ['Yekaterinburg', 'Chelyabinsk'],
    formatted: '+05:00 Yekaterinburg Time - Yekaterinburg, Chelyabinsk',
    group: ['Asia/Yekaterinburg'],
  },
  {
    name: 'Asia/Colombo',
    alternativeName: 'India Time',
    mainCities: ['Colombo', 'Dehiwala-Mount Lavinia'],
    formatted: '+05:30 India Time - Colombo, Dehiwala-Mount Lavinia',
    group: ['Asia/Colombo'],
  },
  {
    name: 'Asia/Kolkata',
    alternativeName: 'India Time',
    mainCities: ['Mumbai', 'Delhi'],
    formatted: '+05:30 India Time - Mumbai, Delhi',
    group: ['Asia/Kolkata'],
  },
  {
    name: 'Asia/Kathmandu',
    alternativeName: 'Nepal Time',
    mainCities: ['Kathmandu', 'Pokhara'],
    formatted: '+05:45 Nepal Time - Kathmandu, Pokhara',
    group: ['Asia/Kathmandu'],
  },
  {
    name: 'Asia/Dhaka',
    alternativeName: 'Bangladesh Time',
    mainCities: ['Dhaka', 'Chittagong'],
    formatted: '+06:00 Bangladesh Time - Dhaka, Chittagong',
    group: ['Asia/Dhaka'],
  },
  {
    name: 'Asia/Thimphu',
    alternativeName: 'Bhutan Time',
    mainCities: ['Thimphu', 'Punākha'],
    formatted: '+06:00 Bhutan Time - Thimphu, Punākha',
    group: ['Asia/Thimphu'],
  },
  {
    name: 'Asia/Almaty',
    alternativeName: 'East Kazakhstan Time',
    mainCities: ['Almaty', 'Karagandy'],
    formatted: '+06:00 East Kazakhstan Time - Almaty, Karagandy',
    group: ['Asia/Almaty', 'Asia/Qostanay'],
  },
  {
    name: 'Asia/Urumqi',
    alternativeName: 'GMT+06:00',
    mainCities: ['Zhongshan', 'Ürümqi'],
    formatted: '+06:00 GMT+06:00 - Zhongshan, Ürümqi',
    group: ['Asia/Urumqi'],
  },
  {
    name: 'Indian/Chagos',
    alternativeName: 'Indian Ocean Time',
    mainCities: ['Chagos'],
    formatted: '+06:00 Indian Ocean Time - Chagos',
    group: ['Indian/Chagos'],
  },
  {
    name: 'Asia/Bishkek',
    alternativeName: 'Kyrgyzstan Time',
    mainCities: ['Bishkek', 'Osh'],
    formatted: '+06:00 Kyrgyzstan Time - Bishkek, Osh',
    group: ['Asia/Bishkek'],
  },
  {
    name: 'Asia/Omsk',
    alternativeName: 'Omsk Time',
    mainCities: ['Omsk', 'Tara'],
    formatted: '+06:00 Omsk Time - Omsk, Tara',
    group: ['Asia/Omsk'],
  },
  {
    name: 'Antarctica/Vostok',
    alternativeName: 'Vostok Time',
    mainCities: ['Vostok'],
    formatted: '+06:00 Vostok Time - Vostok',
    group: ['Antarctica/Vostok'],
  },
  {
    name: 'Indian/Cocos',
    alternativeName: 'Cocos Islands Time',
    mainCities: ['West Island'],
    formatted: '+06:30 Cocos Islands Time - West Island',
    group: ['Indian/Cocos'],
  },
  {
    name: 'Asia/Yangon',
    alternativeName: 'Myanmar Time',
    mainCities: ['Yangon', 'Mandalay'],
    formatted: '+06:30 Myanmar Time - Yangon, Mandalay',
    group: ['Asia/Yangon'],
  },
  {
    name: 'Indian/Christmas',
    alternativeName: 'Christmas Island Time',
    mainCities: ['Flying Fish Cove'],
    formatted: '+07:00 Christmas Island Time - Flying Fish Cove',
    group: ['Indian/Christmas'],
  },
  {
    name: 'Antarctica/Davis',
    alternativeName: 'Davis Time',
    mainCities: ['Davis'],
    formatted: '+07:00 Davis Time - Davis',
    group: ['Antarctica/Davis'],
  },
  {
    name: 'Asia/Hovd',
    alternativeName: 'Hovd Time',
    mainCities: ['Khovd', 'Ölgii'],
    formatted: '+07:00 Hovd Time - Khovd, Ölgii',
    group: ['Asia/Hovd'],
  },
  {
    name: 'Asia/Bangkok',
    alternativeName: 'Indochina Time',
    mainCities: ['Bangkok', 'Samut Prakan'],
    formatted: '+07:00 Indochina Time - Bangkok, Samut Prakan',
    group: ['Asia/Bangkok'],
  },
  {
    name: 'Asia/Ho_Chi_Minh',
    alternativeName: 'Indochina Time',
    mainCities: ['Ho Chi Minh City', 'Hanoi'],
    formatted: '+07:00 Indochina Time - Ho Chi Minh City, Hanoi',
    group: ['Asia/Ho_Chi_Minh'],
  },
  {
    name: 'Asia/Phnom_Penh',
    alternativeName: 'Indochina Time',
    mainCities: ['Phnom Penh', 'Takeo'],
    formatted: '+07:00 Indochina Time - Phnom Penh, Takeo',
    group: ['Asia/Phnom_Penh'],
  },
  {
    name: 'Asia/Vientiane',
    alternativeName: 'Indochina Time',
    mainCities: ['Vientiane', 'Pakse'],
    formatted: '+07:00 Indochina Time - Vientiane, Pakse',
    group: ['Asia/Vientiane'],
  },
  {
    name: 'Asia/Novosibirsk',
    alternativeName: 'Novosibirsk Time',
    mainCities: ['Novosibirsk', 'Krasnoyarsk'],
    formatted: '+07:00 Novosibirsk Time - Novosibirsk, Krasnoyarsk',
    group: [
      'Asia/Barnaul',
      'Asia/Krasnoyarsk',
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Tomsk',
    ],
  },
  {
    name: 'Asia/Jakarta',
    alternativeName: 'Western Indonesia Time',
    mainCities: ['Jakarta', 'Surabaya'],
    formatted: '+07:00 Western Indonesia Time - Jakarta, Surabaya',
    group: ['Asia/Jakarta', 'Asia/Pontianak'],
  },
  {
    name: 'Antarctica/Casey',
    alternativeName: 'Australian Western Time',
    mainCities: ['Casey'],
    formatted: '+08:00 Australian Western Time - Casey',
    group: ['Antarctica/Casey'],
  },
  {
    name: 'Australia/Perth',
    alternativeName: 'Australian Western Time',
    mainCities: ['Perth', 'Rockingham'],
    formatted: '+08:00 Australian Western Time - Perth, Rockingham',
    group: ['Australia/Perth'],
  },
  {
    name: 'Asia/Brunei',
    alternativeName: 'Brunei Darussalam Time',
    mainCities: ['Bandar Seri Begawan', 'Kuala Belait'],
    formatted:
      '+08:00 Brunei Darussalam Time - Bandar Seri Begawan, Kuala Belait',
    group: ['Asia/Brunei'],
  },
  {
    name: 'Asia/Makassar',
    alternativeName: 'Central Indonesia Time',
    mainCities: ['Makassar', 'Denpasar'],
    formatted: '+08:00 Central Indonesia Time - Makassar, Denpasar',
    group: ['Asia/Makassar'],
  },
  {
    name: 'Asia/Macau',
    alternativeName: 'China Time',
    mainCities: ['Macau'],
    formatted: '+08:00 China Time - Macau',
    group: ['Asia/Macau'],
  },
  {
    name: 'Asia/Shanghai',
    alternativeName: 'China Time',
    mainCities: ['Shanghai', 'Beijing'],
    formatted: '+08:00 China Time - Shanghai, Beijing',
    group: ['Asia/Shanghai'],
  },
  {
    name: 'Asia/Hong_Kong',
    alternativeName: 'Hong Kong Time',
    mainCities: ['Hong Kong', 'Kowloon'],
    formatted: '+08:00 Hong Kong Time - Hong Kong, Kowloon',
    group: ['Asia/Hong_Kong'],
  },
  {
    name: 'Asia/Irkutsk',
    alternativeName: 'Irkutsk Time',
    mainCities: ['Irkutsk', 'Ulan-Ude'],
    formatted: '+08:00 Irkutsk Time - Irkutsk, Ulan-Ude',
    group: ['Asia/Irkutsk'],
  },
  {
    name: 'Asia/Kuala_Lumpur',
    alternativeName: 'Malaysia Time',
    mainCities: ['Kota Bharu', 'Kuala Lumpur'],
    formatted: '+08:00 Malaysia Time - Kota Bharu, Kuala Lumpur',
    group: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
  },
  {
    name: 'Asia/Manila',
    alternativeName: 'Philippine Time',
    mainCities: ['Quezon City', 'Manila'],
    formatted: '+08:00 Philippine Time - Quezon City, Manila',
    group: ['Asia/Manila'],
  },
  {
    name: 'Asia/Singapore',
    alternativeName: 'Singapore Time',
    mainCities: ['Singapore', 'Woodlands'],
    formatted: '+08:00 Singapore Time - Singapore, Woodlands',
    group: ['Asia/Singapore'],
  },
  {
    name: 'Asia/Taipei',
    alternativeName: 'Taipei Time',
    mainCities: ['Taipei', 'Kaohsiung'],
    formatted: '+08:00 Taipei Time - Taipei, Kaohsiung',
    group: ['Asia/Taipei'],
  },
  {
    name: 'Asia/Ulaanbaatar',
    alternativeName: 'Ulaanbaatar Time',
    mainCities: ['Ulan Bator', 'Erdenet'],
    formatted: '+08:00 Ulaanbaatar Time - Ulan Bator, Erdenet',
    group: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
  },
  {
    name: 'Australia/Eucla',
    alternativeName: 'Australian Central Western Time',
    mainCities: ['Eucla'],
    formatted: '+08:45 Australian Central Western Time - Eucla',
    group: ['Australia/Eucla'],
  },
  {
    name: 'Asia/Dili',
    alternativeName: 'East Timor Time',
    mainCities: ['Dili', 'Maliana'],
    formatted: '+09:00 East Timor Time - Dili, Maliana',
    group: ['Asia/Dili'],
  },
  {
    name: 'Asia/Jayapura',
    alternativeName: 'Eastern Indonesia Time',
    mainCities: ['Ambon', 'Jayapura'],
    formatted: '+09:00 Eastern Indonesia Time - Ambon, Jayapura',
    group: ['Asia/Jayapura'],
  },
  {
    name: 'Asia/Tokyo',
    alternativeName: 'Japan Time',
    mainCities: ['Tokyo', 'Yokohama'],
    formatted: '+09:00 Japan Time - Tokyo, Yokohama',
    group: ['Asia/Tokyo'],
  },
  {
    name: 'Asia/Pyongyang',
    alternativeName: 'Korean Time',
    mainCities: ['Pyongyang', 'Hamhŭng'],
    formatted: '+09:00 Korean Time - Pyongyang, Hamhŭng',
    group: ['Asia/Pyongyang'],
  },
  {
    name: 'Asia/Seoul',
    alternativeName: 'Korean Time',
    mainCities: ['Seoul', 'Busan'],
    formatted: '+09:00 Korean Time - Seoul, Busan',
    group: ['Asia/Seoul'],
  },
  {
    name: 'Pacific/Palau',
    alternativeName: 'Palau Time',
    mainCities: ['Ngerulmud'],
    formatted: '+09:00 Palau Time - Ngerulmud',
    group: ['Pacific/Palau'],
  },
  {
    name: 'Asia/Chita',
    alternativeName: 'Yakutsk Time',
    mainCities: ['Chita', 'Yakutsk'],
    formatted: '+09:00 Yakutsk Time - Chita, Yakutsk',
    group: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
  },
  {
    name: 'Australia/Darwin',
    alternativeName: 'Australian Central Time',
    mainCities: ['Darwin', 'Alice Springs'],
    formatted: '+09:30 Australian Central Time - Darwin, Alice Springs',
    group: ['Australia/Darwin'],
  },
  {
    name: 'Australia/Brisbane',
    alternativeName: 'Australian Eastern Time',
    mainCities: ['Brisbane', 'Gold Coast'],
    formatted: '+10:00 Australian Eastern Time - Brisbane, Gold Coast',
    group: ['Australia/Brisbane', 'Australia/Lindeman'],
  },
  {
    name: 'Pacific/Guam',
    alternativeName: 'Chamorro Time',
    mainCities: ['Dededo Village', 'Yigo Village'],
    formatted: '+10:00 Chamorro Time - Dededo Village, Yigo Village',
    group: ['Pacific/Guam'],
  },
  {
    name: 'Pacific/Saipan',
    alternativeName: 'Chamorro Time',
    mainCities: ['Saipan'],
    formatted: '+10:00 Chamorro Time - Saipan',
    group: ['Pacific/Saipan'],
  },
  {
    name: 'Pacific/Chuuk',
    alternativeName: 'Chuuk Time',
    mainCities: ['Chuuk'],
    formatted: '+10:00 Chuuk Time - Chuuk',
    group: ['Pacific/Chuuk'],
  },
  {
    name: 'Antarctica/DumontDUrville',
    alternativeName: 'Dumont-d’Urville Time',
    mainCities: ['DumontDUrville'],
    formatted: '+10:00 Dumont-d’Urville Time - DumontDUrville',
    group: ['Antarctica/DumontDUrville'],
  },
  {
    name: 'Pacific/Port_Moresby',
    alternativeName: 'Papua New Guinea Time',
    mainCities: ['Port Moresby', 'Lae'],
    formatted: '+10:00 Papua New Guinea Time - Port Moresby, Lae',
    group: ['Pacific/Port_Moresby'],
  },
  {
    name: 'Asia/Vladivostok',
    alternativeName: 'Vladivostok Time',
    mainCities: ['Vladivostok', 'Khabarovsk'],
    formatted: '+10:00 Vladivostok Time - Vladivostok, Khabarovsk',
    group: ['Asia/Ust-Nera', 'Asia/Vladivostok'],
  },
  {
    name: 'Australia/Adelaide',
    alternativeName: 'Australian Central Time',
    mainCities: ['Adelaide', 'Adelaide Hills'],
    formatted: '+09:30 Australian Central Time - Adelaide, Adelaide Hills',
    group: ['Australia/Adelaide', 'Australia/Broken_Hill'],
  },
  {
    name: 'Australia/Sydney',
    alternativeName: 'Australian Eastern Time',
    mainCities: ['Sydney', 'Melbourne'],
    formatted: '+10:00 Australian Eastern Time - Sydney, Melbourne',
    group: [
      'Australia/Currie',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Sydney',
    ],
  },
  {
    name: 'Pacific/Bougainville',
    alternativeName: 'GMT+11:00',
    mainCities: ['Arawa'],
    formatted: '+11:00 GMT+11:00 - Arawa',
    group: ['Pacific/Bougainville'],
  },
  {
    name: 'Pacific/Kosrae',
    alternativeName: 'Kosrae Time',
    mainCities: ['Kosrae', 'Palikir - National Government Center'],
    formatted:
      '+11:00 Kosrae Time - Kosrae, Palikir - National Government Center',
    group: ['Pacific/Kosrae', 'Pacific/Pohnpei'],
  },
  {
    name: 'Australia/Lord_Howe',
    alternativeName: 'Lord Howe Time',
    mainCities: ['Lord Howe'],
    formatted: '+10:30 Lord Howe Time - Lord Howe',
    group: ['Australia/Lord_Howe'],
  },
  {
    name: 'Antarctica/Macquarie',
    alternativeName: 'Macquarie Island Time',
    mainCities: ['Macquarie'],
    formatted: '+11:00 Macquarie Island Time - Macquarie',
    group: ['Antarctica/Macquarie'],
  },
  {
    name: 'Pacific/Noumea',
    alternativeName: 'New Caledonia Time',
    mainCities: ['Nouméa', 'Mont-Dore'],
    formatted: '+11:00 New Caledonia Time - Nouméa, Mont-Dore',
    group: ['Pacific/Noumea'],
  },
  {
    name: 'Asia/Sakhalin',
    alternativeName: 'Sakhalin Time',
    mainCities: ['Yuzhno-Sakhalinsk', 'Magadan'],
    formatted: '+11:00 Sakhalin Time - Yuzhno-Sakhalinsk, Magadan',
    group: ['Asia/Magadan', 'Asia/Sakhalin', 'Asia/Srednekolymsk'],
  },
  {
    name: 'Pacific/Guadalcanal',
    alternativeName: 'Solomon Islands Time',
    mainCities: ['Honiara'],
    formatted: '+11:00 Solomon Islands Time - Honiara',
    group: ['Pacific/Guadalcanal'],
  },
  {
    name: 'Pacific/Efate',
    alternativeName: 'Vanuatu Time',
    mainCities: ['Port-Vila'],
    formatted: '+11:00 Vanuatu Time - Port-Vila',
    group: ['Pacific/Efate'],
  },
  {
    name: 'Pacific/Tarawa',
    alternativeName: 'Gilbert Islands Time',
    mainCities: ['Tarawa'],
    formatted: '+12:00 Gilbert Islands Time - Tarawa',
    group: ['Pacific/Tarawa'],
  },
  {
    name: 'Pacific/Majuro',
    alternativeName: 'Marshall Islands Time',
    mainCities: ['Majuro', 'Kwajalein'],
    formatted: '+12:00 Marshall Islands Time - Majuro, Kwajalein',
    group: ['Pacific/Kwajalein', 'Pacific/Majuro'],
  },
  {
    name: 'Pacific/Nauru',
    alternativeName: 'Nauru Time',
    mainCities: ['Yaren'],
    formatted: '+12:00 Nauru Time - Yaren',
    group: ['Pacific/Nauru'],
  },
  {
    name: 'Pacific/Norfolk',
    alternativeName: 'Norfolk Island Time',
    mainCities: ['Kingston'],
    formatted: '+11:00 Norfolk Island Time - Kingston',
    group: ['Pacific/Norfolk'],
  },
  {
    name: 'Asia/Kamchatka',
    alternativeName: 'Petropavlovsk-Kamchatski Time',
    mainCities: ['Petropavlovsk-Kamchatsky', 'Yelizovo'],
    formatted:
      '+12:00 Petropavlovsk-Kamchatski Time - Petropavlovsk-Kamchatsky, Yelizovo',
    group: ['Asia/Anadyr', 'Asia/Kamchatka'],
  },
  {
    name: 'Pacific/Funafuti',
    alternativeName: 'Tuvalu Time',
    mainCities: ['Funafuti'],
    formatted: '+12:00 Tuvalu Time - Funafuti',
    group: ['Pacific/Funafuti'],
  },
  {
    name: 'Pacific/Wake',
    alternativeName: 'Wake Island Time',
    mainCities: ['Wake'],
    formatted: '+12:00 Wake Island Time - Wake',
    group: ['Pacific/Wake'],
  },
  {
    name: 'Pacific/Wallis',
    alternativeName: 'Wallis & Futuna Time',
    mainCities: ['Mata-Utu'],
    formatted: '+12:00 Wallis & Futuna Time - Mata-Utu',
    group: ['Pacific/Wallis'],
  },
  {
    name: 'Pacific/Fiji',
    alternativeName: 'Fiji Summer Time',
    mainCities: ['Suva', 'Lautoka'],
    formatted: '+12:00 Fiji Summer Time - Suva, Lautoka',
    group: ['Pacific/Fiji'],
  },
  {
    name: 'Pacific/Auckland',
    alternativeName: 'New Zealand Time',
    mainCities: ['Auckland', 'Wellington'],
    formatted: '+12:00 New Zealand Time - Auckland, Wellington',
    group: ['Pacific/Auckland'],
  },
  {
    name: 'Pacific/Enderbury',
    alternativeName: 'Phoenix Islands Time',
    mainCities: ['Enderbury'],
    formatted: '+13:00 Phoenix Islands Time - Enderbury',
    group: ['Pacific/Enderbury'],
  },
  {
    name: 'Pacific/Fakaofo',
    alternativeName: 'Tokelau Time',
    mainCities: ['Fakaofo'],
    formatted: '+13:00 Tokelau Time ',
    group: ['Pacific/Fakaofo'],
  },
  {
    name: 'Pacific/Tongatapu',
    alternativeName: 'Tonga Time',
    mainCities: ['Nuku‘alofa'],
    formatted: '+13:00 Tonga Standard Time',
    group: ['Pacific/Tongatapu'],
  },
  {
    name: 'Pacific/Kiritimati',
    alternativeName: 'Line Islands Time',
    mainCities: ['Kiritimati'],
    formatted: '+14:00 Line Islands Time',
    group: ['Pacific/Kiritimati'],
  },
];
