import DynamicList from "components_v2/List/DynamicList";
import React from "react";
import _ from "lodash";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { getColumns } from "./listOptions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../../../App.helper";

const TransferServices = ({
  refetch,
  services,
  createService,
  updateService,
  deleteService,
  projectId,
  setFieldValue,
  setServicesData,
  loading,
  setFilterKey,
  sort,
  setSort
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const columns = getColumns(projectId);

  return (
    <DynamicList
      filterActions={[
        {
          type: "text",
          onChange: val => {
            setFilterKey(val);
          },
          label: "Search"
        }
      ]}
      sortActions={{
        currentSort: sort,
        onChangeSort: setSort
      }}
      isMobileView={isMobileView}
      showHeader
      loading={loading}
      data={services}
      noResultsText="No transfer services"
      title="Transfer Services"
      numRows={services.length}
      columns={columns}
      // getMobileMenuItems={(row) => getMobileMenuItems({ row })}
      actions={{
        edit: {
          onSave: async ({ id, values }) => {
            if (!projectId) {
              const newServices = [...services];
              const serviceIndex = _.findIndex(newServices, ["id:", id]);

              if (serviceIndex > -1)
                newServices[serviceIndex] = { id: id, ...values };

              setServicesData(newServices);
              setFieldValue("transferservices", newServices);
            } else {
              await updateService({
                variables: {
                  id: { id },
                  data: {
                    name: values.name,
                    duration: parseInt(values.duration),
                    enabled: values.enabled
                  }
                }
              });
              await refetch();
            }
          }
        },
        create: {
          onSave: async ({ values }) => {
            if (!projectId) {
              const newServices = [
                ...services,
                { ...values, id: `default_${services.length + 1}` }
              ];

              setServicesData(newServices);
              setFieldValue("transferservices", newServices);
            } else {
              await createService({
                variables: {
                  data: {
                    name: values.name,
                    duration: parseInt(values.duration),
                    enabled:
                      values.enabled === undefined ? false : values.enabled,
                    event: projectId
                  }
                }
              });
              await refetch();
            }
          }
        },
        delete: {
          onClick: async ({ id }) => {
            if (!projectId) {
              const newServices = services.filter(service => service.id !== id);
              setServicesData(newServices);
              setFieldValue('transferservices', newServices);
            } else {
              await deleteService({
                variables: {
                  id
                }
              });

              await refetch();
            }
          }
        }
      }}
    />
  );
};

export default withRouter(TransferServices);
