import React, { useState, useEffect } from "react";
import InfiniteCalendar, {
  Calendar,
  defaultMultipleDateInterpolation,
  withMultipleDates
} from "react-infinite-calendar";
import moment from "moment";
import Button from "components_v2/Button/Button";
import Modal from "components_v2/Modal/Modal";
import TextInput from "components_v2/Inputs/TextInput/TextInput";
import { Calendar as CalendarIcon } from "components_v2/Icons/Icons";
import InputError from "components_v2/Inputs/InputError/InputError";
import "react-infinite-calendar/styles.css";
import "./MultiDatePicker.css";
const MultiDatePicker = props => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    setDates([...props.selected]);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <InfiniteCalendar
        Component={withMultipleDates(Calendar)}
        interpolateSelection={defaultMultipleDateInterpolation}
        selected={dates}
        onSelect={selectedDate => {
          let newDates;
          if (
            dates.map(date => date.valueOf()).includes(selectedDate.valueOf())
          )
            newDates = dates.filter(
              date => date.valueOf() !== selectedDate.valueOf()
            );
          else newDates = [...dates, moment(selectedDate)];

          setDates(newDates);
        }}
        {...props}
      />
      <div
        style={{
          display: "flex",
          direction: "column",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 5,
          background: "#fff",
          padding: 8,
          justifyContent: "space-between"
        }}
      >
        <Button
          onClick={() => {
            setDates([...props.selected]);
            props.handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            props.onDone(dates);
            props.handleClose();
          }}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

const MultiDatePickerModal = props => {
  const { error, placeholder, ...otherProps } = props;

  const formatDates = dates =>
    dates?.map(date => moment(date).format("YYYY-MM-DD")).join(", ") || [];

  return (
    <Modal
      onModalClick={toggleModal => (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextInput
              readOnly
              placeholder={placeholder}
              onClick={toggleModal}
              value={formatDates(props.selected)}
              icon={CalendarIcon}
            />
          </div>
          <InputError error={error} />
        </>
      )}
    >
      <MultiDatePicker {...otherProps} />
    </Modal>
  );
};

export default MultiDatePickerModal;
