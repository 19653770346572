import gql from "graphql-tag";

export default gql`
  query GetAllDriverSchedule($id: ID!) {
    driverschedule(id: $id) {
      id
      cancelled
      ridestatuses {
        id
        status
        created_at
        location
      }
      vehicle {
        id
        vehicleNumber
        make
        model
        year
        plateNumber
        color
      }
      passengerschedule {
        id
        start
        stop
        stops
        savedStops {
          name
          type
          address
          lat
          long
          id
        }
        transferservice {
          id
          name
        }
        vehicleClass
        origin
        created_at
        type
        flightNumber
        terminal
        numPassengers
        numberOfVehicles
        bookingId
        billed
        notes
        cancelled
        documents
        supplier {
          id
        }
        passenger {
          id
          firstName
          lastName
          fullName
        }
        from {
          id
          name
          address
          lat
          long
        }
        to {
          id
          name
          address
          lat
          long
        }
        event {
          id
          name
        }
        requiredVehicleClass {
          id
          name
          transferPricing
          trackTransferPricing
          fourHourPricing
          sixHourPricing
          twelveHourPricing
          twentyFourHourPricing
          maxPassengers
        }
        department {
          id
          name
          module {
            id
            name
          }
        }
        createdBy {
          id
          firstName
          lastName
          fullName
          email
          phone
        }
        passenger {
          id
          firstName
          lastName
          fullName
          email
          phone
          passengerPriority
          notes
        }
        documents
        savedDocuments {
          id
          size
          url
          name
          uploadedBy {
            id
            firstName
            lastName
          }
        }
      }
      driveratings {
        id
        rating
        comment
      }
      driver {
        id
        firstName
        lastName
        fullName
      }
      overtimeHours
    }
  }
`;
