import { editActionButtonSelector } from "../../../../App.helper";

export const getMobileMenuItems = ({ row }) => {
  return {
    right: [
      {
        text: " ",
        onClick: () => {
          const clickedButton = document.querySelector(
            "#" + editActionButtonSelector + row.id + "_supplier_pricing"
          );
          if (clickedButton) clickedButton.click();
        },
        className: "mobile-actions-edit"
      }
    ]
  };
};

export const getColumns = () => [
  {
    field: "name",
    headerName: "Name",
    width: 1,
    isFullWidth: true,
    isDisabled: true
  },
  {
    field: "transferAffiliatePricing",
    headerName: "Transfer Cost",
    isFullWidth: true,
    type: "number",
    width: 1,
  },
  {
    field: "trackTransferAffiliatePricing",
    headerName: "Track Transfer Cost",
    isFullWidth: true,
    type: "number",
    width: 1,
  },
  {
    field: "fourHourAffiliatePricing",
    headerName: "4 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    field: "sixHourAffiliatePricing",
    headerName: "6 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    field: "twelveHourAffiliatePricing",
    headerName: "12 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    field: "twentyFourHourAffiliatePricing",
    headerName: "24 Hour Cost",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    headerName: "Overtime Cost (per hour)",
    field: "overtimeAffiliatePricing",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  },
  {
    headerName: "No-show",
    field: "noShowAffiliatePricing",
    isFullWidth: true,
    type: "number",
    isSortable: false,
    hideMobile: true,
    width: 1,
  },
  {
    headerName: "Waiting Time Cost (per hour)",
    field: "waitingTimeAffiliatePricing",
    isFullWidth: true,
    type: "number",
    hideMobile: true,
    width: 1,
  }
];
