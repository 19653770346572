import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import DynamicList from "components_v2/List/DynamicList";
import { getHamburgerMenuItems, getColumns, actions, getMobileMenuItems } from "./ListOptions";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../App.helper";

const PassengersComponent = ({count, handleListConfigChange,
  listConfig, data, loading, history, projectId  }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down(mobileViewBreakpoint));
  return (
    <DynamicList
    listConfig={{...listConfig, count}}
    onListConfigChange={handleListConfigChange}
     
      isMobileView={isMobileView}
      showHeader
      data={data}
      noResultsText="No passengers"
      loading={loading}
      title="Passengers"
      enableFreezeHeader
      headerFreezeLeftContent="Passengers"
      numRows={count}
      hamburgerMenuItems={getHamburgerMenuItems({ history, projectId })}
      getMobileMenuItems={(row) =>
        getMobileMenuItems({ history, projectId, row })
      }
      columns={getColumns(isMobileView)}
      actions={actions}
      projectId={projectId}
    />
  );
}
  ;

PassengersComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default withRouter(PassengersComponent);
