import React, { useEffect } from 'react';
import TextInput from 'components_v2/Inputs/TextInput/TextInput';
import { SearchIcon } from '../../Icons/Icons';
import { useStyles, useCustomStyles } from './SearchInput.styles';
import InputError from '../InputError/InputError';
import InputLabel from '../InputLabel/InputLabel';
import { useDebounce } from "../../Hooks/use-debounce.hook";
import { useState } from 'react';


const SearchInput = ({ name, label, placeholder, value, onChange, error }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm === searchTerm) {
      onChange && onChange(searchTerm)
    }
  }, [
    debouncedSearchTerm
  ])


  return (
    <div className={customClasses.searchWrapper}>
      <InputLabel label={label} />

      <TextInput
        className={classes.searchInput}
        name={name}
        label={label}
        placeholder={placeholder}
        value={searchTerm}
        isErrored={!!error}
        onChange={(e) => {
          setSearchTerm(e?.target?.value)
        }}
        icon={SearchIcon}
      />
      <InputError error={error} />
    </div>
  );
};

export default SearchInput;
