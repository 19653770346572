import React, { useState } from "react";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";

import Button from "components_v2/Button/Button";
import FullScreenPopover from "components_v2/FullScreenPopover/FullScreenPopover";
import CreateUser from "gql/Users/CreatePassenger";
import UpdateUser from "gql/Users/Update";
import Loading from "components_v2/Loading/CircularProgress";
import SuccessCard from "components_v2/Cards/SuccessCard/SuccessCard";
import AddPassengerForm from "./AddPassengerForm";
import { getContinueBodyAction } from "utils/formHelpers";
import useStyles from "./AddPassenger.style";
import { deepCopy } from "utils/helpers";
import withFormState from "containers/Wrappers/withFormState";
import {
  formatSubmissionValues,
  formatIncomingData,
  validationSchema
} from "./helpers";

const AddDriver = ({
  history,
  location,
  match,
  data,
  refetchPassenger,
  projectData,
  getPreviousFormState,
  setReturnValues
}) => {
  const classes = useStyles();
  const [passengerId, setPassengerId] = useState(null);
  const { projectId } = match.params;
  const previousFormState = getPreviousFormState(location);

  const initialInputValues = {
    password: "MOTUS!123",
    role: 1,
    event: projectId,
    passengers: [
      {
        passengerPriority: { name: "Normal" },
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        notes: ""
      }
    ]
  };

  const isUpdate = !!data;
  const initialValues = isUpdate
    ? { ...initialInputValues, ...formatIncomingData(data) }
    : initialInputValues;

  const successHeader = `${passengerId?.length ? "Passengers" : "Passenger"} ${isUpdate ? "Updated" : "Created"}!`;
  const successDescription = `Congratulations, ${passengerId?.length ? "passengers have" : "a passenger has"} been ${isUpdate ? "updated" : "created"
    }.`;

  const title = isUpdate
    ? "Update an existing passenger"
    : "Create new passengers";

  const [createPassenger, { loading: createMutationLoading }] = useMutation(
    CreateUser
  );

  const [updatePassenger, { loading: updateMutationLoading }] = useMutation(
    UpdateUser
  );

  const isLoading = createMutationLoading || updateMutationLoading;

  const handleSubmit = async (values, actions) => {
    const localValues = deepCopy(values);
    const formattedValues = formatSubmissionValues(localValues, isUpdate);

    if (isUpdate) {
      const id = initialValues.passengers[0].id;
      const res = await updatePassenger({
        variables: {
          id: { id },
          data: formattedValues[0]
        }
      });

      if (res?.data?.updateUser) {
        setPassengerId(res.data.updateUser.user.id);
        await refetchPassenger();
      }
    } else {
      const results = await Promise.all(
        formattedValues.map(passenger =>
          createPassenger({
            variables: {
              data: passenger
            }
          })
        )
      );

      if (results?.length) {
        setPassengerId(results);
        const passengers = results.map(res => (res?.data?.createUser?.user));
        setReturnValues(passengers, previousFormState);
        actions.resetForm(initialInputValues);
      }
    }

    actions.setSubmitting(false);
  };

  let mainBodyActions;

  if (!_.isEmpty(previousFormState))
    mainBodyActions = [getContinueBodyAction(history, previousFormState)];
  else
    mainBodyActions = [
      {
        text: "Add Additional Passengers",
        onClick: () => {
          if (isUpdate) history.push(`/project/${projectId}/passenger/new`);
          setPassengerId(null);
        }
      }
    ];

  if (passengerId)
    return (
      <FullScreenPopover
        previousFormState={previousFormState}
        numberOfSteps={0}
        currentStep={1}
        disableLayout
        onCloseCallback={() => {
          history.goBack();
        }}
        pageView
      >
        <SuccessCard
          headerText={successHeader}
          description={successDescription}
          mainAction={
            previousFormState
              ? null
              : {
                  text: "See Passengers",
                  onClick: () => {
                    history.push(`/project/${projectId}/passengers`);
                  }
                }
          }
          secondaryAction={
            isUpdate
              ? {
                  text: "Customize Passenger",
                  onClick: () => {
                    setPassengerId(null);
                  }
                }
              : null
          }
          mainBodyActions={mainBodyActions}
        />
      </FullScreenPopover>
    );

  return (
    <>
      {isLoading && <Loading fullPage />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange
      >
        {props => (
          <Form>
            <FullScreenPopover
              previousFormState={previousFormState}
              title={title}
              subtitle={projectData.name}
              numberOfSteps={0}
              currentStep={1}
              mainAction={
                <Button type="submit" variant="contained">
                  Save passenger(s)
                </Button>
              }
              onCloseCallback={() => {
                history.goBack();
              }}
              pageView
            >
              <AddPassengerForm
                {...props}
                classes={classes}
                isUpdate={isUpdate}
              />
            </FullScreenPopover>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withFormState(withRouter(AddDriver));
