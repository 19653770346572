export default [
  {
    id: 'default_1',
    name: "Airport Transfer",
    duration: null,
    enabled: true,
  },
  {
    id: 'default_2',
    name: "Transfer",
    duration: null,
    enabled: true,
  },
  {
    id: 'default_3',
    name: "4 Hour Service",
    duration: 60 * 4,
    enabled: true,
  },
  {
    id: 'default_4',
    name: "6 Hour Service",
    duration: 60 * 6,
    enabled: true,
  },
  {
    id: 'default_5',
    name: "12 Hour Service",
    duration: 60 * 12,
    enabled: true,
  },
  {
    id: 'default_6',
    name: "24 Hour Service",
    duration: 60 * 24,
    enabled: true,
  },
];