import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';

const useStyles = makeStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: 6,
    marginBottom: 8,
    overflow: 'hidden',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
    transition: 'all 200ms ease-in-out',
    '& .MuiCollapse-wrapper': {
      position: (props) => props.relativeWrapper ? 'relative' : undefined,
    },

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      boxShadow: ' 0px 8px 28px rgba(0, 0, 0, 0.2)',
    },
    '&$expanded': {
      margin: 0,
      overflow: 'visible',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&:last-child': {
        marginBottom: 8,
      },
    },
  },
});

export default function AccordionWrapper({ defaultExpanded, children, relativeWrapper }) {
  const classes = useStyles({ relativeWrapper });

  return (
    <MuiAccordion className={classes.root} defaultExpanded={defaultExpanded}>
      {children}
    </MuiAccordion>
  );
}
