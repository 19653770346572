import { useTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FAB from 'components_v2/Buttons/FAB/FAB';
import { FloatingActionNewDriver } from 'components_v2/Icons/Icons';
import DynamicList from 'components_v2/List/DynamicList';
// import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import DriverViewContainer from 'components_v2/Views/Internal/Driver/DriverView.Container';
import SelectionModal from 'components_v2/Modal/SelectionModal/SelectionModal';
import GetAllDriversCustom from './Gql/GetAllDriversCustom';
import { mobileViewBreakpoint } from '../../../App.helper';
import { createLocationWithState } from 'utils/routerHelpers';
import {
  getDriversHamburgerMenuItems,
  getDriverMobileMenuItems,
  handleAddBooking
} from './menuItems';
import { driverColumns } from './columns';

// Add driver contains the FAB
const MainContainer = ({
  isHelpTutorial,
  title,
  history,
  location,
  match,
  publicOnly,
}) => {
  const theme = useTheme();
 
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const { projectId, supplierId } = match.params;
  const id = projectId || supplierId;
  const type = projectId ? 'project' : 'supplier';
  const [listConfig, setListConfig] = useState({
    sort: 'id:desc',
    pageSize: 10,
    startIndex: 0,
    filterKey: '',
    count: 0
  })
  const getQueryOptions = (publicOnly, id) => {
    const fetchPolicy = 'no-cache';

    if (publicOnly)
      return {
        query: GetAllDriversCustom,
        options: {
          variables:{ ...listConfig, sort: listConfig.sort || 'id:desc' },
          fetchPolicy,
        },
      };
    if (id) {
      const variables = { ...listConfig, sort: listConfig.sort || 'id:desc' };
      if (projectId) variables.event = id;
      else if (supplierId) variables.supplier = id;

      return {
        query: GetAllDriversCustom,
        options: {
          variables,
          fetchPolicy,
          skip: !id,
        },
      };
    }

    return { query: undefined, options: undefined };
  };

  const { query, options } = getQueryOptions(publicOnly, id);

  const { data: driversData, loading } = useQuery(query, {
    ...options,
    skip: isHelpTutorial || !query,
  });

  const data = driversData?.allUsers?.users  || [];
  const count = driversData?.allUsers?.totalCount || 0;

  const [mobileModalData, setMobileModalData] = useState(null);

  function handleNewVehicleClick() {
    const locationWithState = createLocationWithState(
      `/project/${projectId}/vehicle/new`,
      location,
      {},
      null,
      true
    );

    history.push(locationWithState)
  }
  
  const handleListConfigChange = (listConfig) => {
    setListConfig(listConfig)
  }
  return (
    <>
      <SelectionModal
        data={mobileModalData}
        onClickCancel={() => {
          setMobileModalData(null);
        }}
        onClickConfirm={(vehicleId) => handleAddBooking({ driverId: mobileModalData?.id, vehicleId: vehicleId, projectId: projectId, history: history })}
      />
      <FAB
        label="Add driver"
        icon={FloatingActionNewDriver}
        onClick={() => {
          history.push(publicOnly ? '/public/driver/new' : `/${type}/${id}/driver/new`);
        }}
      />
      <DynamicList
       
        listConfig={{...listConfig, count}}
        onListConfigChange={handleListConfigChange}
        isMobileView={isMobileView}
        showHeader
        data={data}
        title={title}
        noResultsText="No drivers"
        loading={loading}
        numRows={count}
        enableFreezeHeader
        headerFreezeLeftContent="Drivers"
        getHamburgerMenuItems={(row) => getDriversHamburgerMenuItems({ history, handleNewClick: handleNewVehicleClick, type, id, publicOnly, row })}
        getMobileMenuItems={(row) =>
          getDriverMobileMenuItems({ history, handleNewClick: handleNewVehicleClick, type, row, id, publicOnly, setMobileModalData, mobileModalData, })
        }
        detailPanel={(row) => <DriverViewContainer data={row} />}
        actions={
          {
            // rowClick: (row) => {
            //   history.push(`/driver/${row.id}`);
            // },
            /*
        primary: {
          onClick: (row) => {},
          label: (row) => 'Add Bookings',
        }, */
          }
        }
        columns={driverColumns}
      />
    </>
  );
};

export default withRouter(MainContainer);
