import { withStyles } from '@material-ui/core/styles';
import Typography from 'components_v2/Typography/Typography';
import React, { useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import DynamicListHeader from 'components_v2/List/DynamicListHeader';
import DynamicListBody from 'components_v2/List/DynamicListBody';
import DynamicListSkeleton from 'components_v2/List/DynamicListSkeleton';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { useScrollTrigger } from '@material-ui/core';
import Filters from 'components_v2/Inputs/Filters/Filters';
import TablePagination from './Modules/TablePagination';
import { Add, DownloadTo } from '../Icons/Icons';
import Button from '../Button/Button';
import { deepCopy } from '../../utils/helpers';
import SortBy from "components_v2/Inputs/SortBy/SortBy";
import { getNextSortOrder } from "./helper";
import { getPageIndex } from '../../utils/list-config';


const groupDataByDate = (data, field) => {
  const res = data.reduce((acc, curr) => {
    const localAcc = deepCopy(acc);

    if (!localAcc[moment.utc(_.propertyOf(curr)(field)).format('D MMMM')])
      localAcc[moment.utc(_.propertyOf(curr)(field)).format('D MMMM')] = [];
    localAcc[moment.utc(_.propertyOf(curr)(field)).format('D MMMM')].push(curr);
    return localAcc;
  }, {});

  return res;
};

const BasicListHeader = withRouter(
  withStyles({
    root: {
      color: 'rgba(255, 255, 255, 0.75)',
      paddingLeft: 10,
    },
   
    // eslint-disable-next-line complexity
  })((props) => {
    const {
      showHeader,
      title,
      smallTitle = false,
      numRows,
      data,
      groupDateField,
      moduleTable,
      hideActions = false,
      loading,
      hideEmptyString,
      actions,
      exportHeaders,
      exportDataFormatter,
      isMobileView = false,
      initialLoading,
      beforeFilters,
      noResultsText,
      isAutoClose = false,
      sortActions,
      columns,
      initialFilterKey,
      onClearAllFilters,
      listConfig,
      onListConfigChange,
    } = props;

    // add a check

    const [newMode, setNewMode] = useState(false);
    const [filters, setFilters] = useState(props.filterActions);
    const scrollTrigger = useScrollTrigger({
      // target: scrollTarget,
      disableHysteresis: true,
      threshold: 530,
    });



    let mainColumnsWidth = 9;
    if (moduleTable) {
      mainColumnsWidth = 11;
    }
    if (hideActions || isMobileView) {
      mainColumnsWidth = 12;
    }

    if (groupDateField) {
      var groupedData = groupDataByDate(data, groupDateField);
    }

    const getTitleTypogVariant = (smallTitle, isMobileView) => {
      if (smallTitle) return (isMobileView && 'bodyLargeRegular') || 'h5Bold';
      return (isMobileView && 'h5Bold') || 'h4Bold';
    };

    const handleChangePage = (event, newPage) => {
      onListConfigChange({ ...listConfig, startIndex: (newPage * listConfig?.pageSize) })
    }

    const handleChangeRowsPerPage = (event) => {
      onListConfigChange({ ...listConfig, startIndex: 0, pageSize: event.target.value })
    }

    const handleFilterChange = (event) => {
      onListConfigChange && onListConfigChange({ ...listConfig, startIndex: 0, filterKey: event })
    }

    const handleSortChange = (event) => {
      const sortOrder = getNextSortOrder({ event, sortActions, listConfig })

      if (listConfig) {
        if (sortOrder)
          onListConfigChange({ ...listConfig, sort: event?.target?.value?.field + ":" + sortOrder, sortHeader: event?.target?.value?.headerName })
        else
          onListConfigChange({ ...listConfig, sort: null, sortHeader: null })
      } else {
        if (sortOrder)
          return sortActions?.onChangeSort({ field: event?.target?.value?.field, order: sortOrder, headerName: event?.target?.value?.headerName })
        else
          return sortActions?.onChangeSort({ field: null, order: null })
      }

    }

    const finalData = groupedData || data;

    const pageIndex = getPageIndex(listConfig)

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: isMobileView ? 'flex-start' : '', justifyContent: !isMobileView ? 'space-between' : '' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
            <div>
              {title && (
                <div style={{ marginTop: isMobileView ? '2px' : 0, marginRight: '12px' }}>
                  <Typography
                    customVariant={getTitleTypogVariant(smallTitle, isMobileView)}
                    style={{ marginBottom: 2 }}
                  >
                    <span style={{ fontWeight: "normal" }}>{title}</span>

                    {numRows > 0 && <> ({numRows})</>}
                  </Typography>
                </div>
              )}
            </div>
            <div>
              {exportHeaders && !isMobileView && (
                <CSVLink
                  data={
                    exportDataFormatter ? data.map(exportDataFormatter) : data
                  }
                  headers={exportHeaders}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    startIcon={<DownloadTo />}
                    variant="simple"
                    onClick={() => { }}
                  >
                    Export
                  </Button>
                </CSVLink>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column"
            }}
          >
            <div style={{display: "flex"}}>
              {isMobileView && (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  {(filters || listConfig) && (
                    <div>
                      <Filters filters={filters} listConfig={listConfig} setFilters={setFilters} initialFilterKey={initialFilterKey} onClearAll={onClearAllFilters} onChange={handleFilterChange} isOnChangeAvailable={onListConfigChange} />
                    </div>
                  )}
                  {beforeFilters}
                </div>
              )}

           
            {!isMobileView && (
              <div style={{ flexDirection: "row", display: "flex" }}>
                {beforeFilters}

                {(filters || listConfig) && (
                  <>
                    <Filters filters={filters} listConfig={listConfig} setFilters={setFilters} initialFilterKey={initialFilterKey} onClearAll={onClearAllFilters} onChange={handleFilterChange} isOnChangeAvailable={onListConfigChange} />
                  </>
                )}
              </div>
            )}
            {(sortActions || listConfig) && <SortBy
              listConfig={listConfig}
              name="Sorted by"
              sortActions={sortActions}
              // value={sortByData[0]}
              // list={sortByData}
              columns={columns}
              onChange={handleSortChange}
            />
            }
            </div>
            <div>
              {actions && actions.create && (actions.create.onSave || actions.create.onClick) && (
                  <Button
                    startIcon={<Add />}
                    variant="simple"
                    onClick={() => {
                      if (actions.create.onClick) actions.create.onClick();
                      else setNewMode(true);
                    }}
                  >
                    Add new
                  </Button>
                )}
            </div>
          </div>


        </div>
        {showHeader && !moduleTable && (
          <DynamicListHeader
            {...props}
            mainColumnsWidth={mainColumnsWidth}
            scrollTrigger={scrollTrigger}
          />
        )}
        {/* RENDER SKELETON IF  LOADING */}
        {loading && <DynamicListSkeleton />}
        {/** IF NO DATA THEN DISPLAY A NO RESULTS STRING */}
        {!hideEmptyString &&
          !loading &&
          !initialLoading &&
          (data.length === 0 || !data) &&
          !newMode && (
            <Typography
              customVariant="bodySmallBold"
              style={{ textAlign: "center" }}
            >
              {noResultsText || "No results."}
            </Typography>
          )}
        {/* RENDER BODY IF NOT LOADING */}
        {/* <div
          ref={node => {
            if (node) {
              setScrollTarget(node);
            }
          }}
        >
        </div> */}
        {!loading && <>
          <DynamicListBody
            {...props}
            data={finalData}
            newMode={newMode}
            setNewMode={setNewMode}
            mainColumnsWidth={mainColumnsWidth}
            isAutoClose={isAutoClose}
          />
          {
            listConfig && <div>
              <TablePagination
                count={listConfig?.count > -1 ? listConfig?.count : finalData.length}
                page={pageIndex}
                rowsPerPage={listConfig?.pageSize}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          }</>}

      </>
    );
  })
);

export default BasicListHeader;