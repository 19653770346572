import React from "react";

import Typography from "components_v2/Typography/Typography";
import '../Bookings/mobile-actions.css';
import PhoneDisplay from '../PhoneDisplay/PhoneDisplay'
export const getHamburgerMenuItems = ({ history, projectId }) => [

  {
    label: "Edit",
    onClick: (row) =>
      history.push(`/project/${projectId}/location/${row.id}/edit`)
  },
];

export const getMobileMenuItems = ({ history, projectId, row }) => ({
  right: [
    {
      text: " ",
      onClick: () =>
        history.push(`/project/${projectId}/location/${row.id}/edit`),
      className: "mobile-actions-edit",
    },
  ],
});

export const getColumns = () => [
  {
    field: "name",
    headerName: "Location name",
    width: 2,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">{data.name}</Typography>
    ),
  },

  {
    field: "type",
    headerName: "Location type",
    width: 2,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">{data.type}</Typography>
    ),
  },

  {
    field: "address",
    headerName: "Address",
    width: 3,
    render: (data) => (
      <Typography customVariant="bodySmallRegular">{data.address}</Typography>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",
    isSortable: false,
    width: 2,
    hideMobile: true,
    render: (data) => (
      <PhoneDisplay phone={data.contactPhone}></PhoneDisplay>
    ),
  },
  {
    field: "functionalAreas",
    headerName: "Functional Areas",
    width: 2,
    hideMobile: true,
    isSortable: false,
    render: (data) => (
      <>
        {data?.children?.length > 1 && (
          <>
            {data.children[0].name} <br />+ {data.children.length - 1} more
          </>
        )}
        {data?.children?.length === 1 && (
          <>{data.children[0].name}</>
        )}
        {data?.children?.length === 0 && <>None</>}
      </>
    ),
  },
];

export const actions = {
  rowClick: () => { },
};
