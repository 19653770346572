import React from "react";
// import Modal from '@material-ui/core/Modal';
import Modal from "components_v2/Modal/Modal";
import SuccessCard from "../../Cards/SuccessCard/SuccessCard";
import { WarningWhite } from "../../Icons/Icons";

const DeleteConfirmationModal = ({
  deleteConfirmationModalData,
  onClickCancel,
  onClickConfirm,
}) => {
  return (
    <Modal
      size="sm"
      pageView={deleteConfirmationModalData?.confirmationType === "delete"}
    >
      <SuccessCard
        icon={
          <WarningWhite
            style={{
              color: "#FFF",
              fill: "white",
              width: 40,
              height: 40,
              filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.8))",
            }}
          />
        }
        mainAction={
          (deleteConfirmationModalData?.isNotDeleteAllowed? undefined : {
            text: "Confirm",
            onClick: onClickConfirm,
          })
        }
        secondaryAction={{
          text: "Cancel",
          onClick: onClickCancel,
        }}
        headerText={
          (deleteConfirmationModalData?.isNotDeleteAllowed ? deleteConfirmationModalData?.deleteNotAllowedReason :
            deleteConfirmationModalData?.headerText || `Are you sure you want to delete ${deleteConfirmationModalData?.recordName}?`)
        }
        description={
          (deleteConfirmationModalData?.isNotDeleteAllowed ? undefined :
            deleteConfirmationModalData?.description || "This cannot be undone")
        }
      />
    </Modal>
  );
};

export default DeleteConfirmationModal;
