import gql from "graphql-tag";

export default gql`
  query GetEvent($id: ID!) {
    events(where: { id: $id }) {
      id
      userevents(sort: "id:desc") {
        id
        user {
          id
          firstName
          lastName
          fullName
          email
          phone
          company
          isSuperAdmin
        }
        role
        accessRight {
          id
          name
        }
      }
    }
  }
`;
