import * as React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { mobileViewBreakpoint } from "../../App.helper";
import Typography from "components_v2/Typography/Typography";
import { useTheme } from "@material-ui/core/styles";
import ReactMapGL, { FlyToInterpolator, Popup } from "react-map-gl";
import { easeCubic } from "d3-ease";
import "mapbox-gl/dist/mapbox-gl.css";
import BookingDetail from "components_v2/Bookings/BookingDetail.Component";
import { Box, Slide } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import GetAllLastLocationsForDrivers from "gql/Locations/GetAllLastLocationsForDrivers";
import GetAllDrivers from "gql/Drivers/GetAll";
import GetDriverScheduleByDriverId from "gql/DriverSchedules/GetDriverScheduleByDriverId";
import { formatLocationHistory } from "utils/driverTrackingHelpers.js";
import { getBoundsForPoints } from "utils/mapboxHelpers";
import RouteHistory from "./RouteHistory";
import ActiveMarker from "./ActiveMarker/ActiveMarker";
import LocationMarker from "./Marker/LocationMarker";
import { mockData, mockDriverData } from "./Map.mock";
import Loading from "components_v2/Loading/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ClickAwayListener } from "@material-ui/core";
import {
  setWidth,
  setHeight,
  getRideStatues,
  getDriverSchedule,
  getIsLoading,
  getWidthHeight
} from "./helper";
import { Pin } from "../Icons/Icons";
const socket = require("socket.io-client")(
  process.env.REACT_APP_SOCKETIO ||
    "ws://localhost:" + process.env.REACT_APP_BACKEND_PORT
);

/*
const markerElements = markers.map((marker, index) => (
  <LocationMarker lat={marker.lat} long={marker.long} />
));
*/

// eslint-disable-next-line complexity
export default function Map(props) {
  const {
    locationsData = [],
    searchKey,
    width,
    height,
    fullScreen,
    filterActions,
    noTracking,
    mapHeight = 800,
    driverSchedule,
    isHelpTutorial,
    projectId,
    viewportCoordinates = [],
    history,
    isTrackRide,
    trackDriverScheduleData,
    bookingUpdatesLoading,
    isDispatchPage,
    isBookingPage
  } = props;

  let isActiveMarkerClicked = false;

  const flyToViewportOptions = {
    transitionDuration: 3000,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubic
  };
  const usedTheme = useTheme();
  const isMobileView = useMediaQuery(
    usedTheme.breakpoints.down(mobileViewBreakpoint)
  );

  const [viewport, setViewport] = useState(
    isHelpTutorial
      ? {
          width: setWidth({ fullScreen, width }),
          height: setHeight({ fullScreen, mapHeight }),
          latitude: 46.500595,
          longitude: 15.749993,
          zoom: 8
        }
      : {
          width: setWidth({ fullScreen, width }),
          height: setHeight({ fullScreen, mapHeight }),
          latitude: 13.500595,
          longitude: 42.749993,
          zoom: 3
        }
  );

  const [showPopup, setShowPopup] = useState(null);

  const handleOnClick = data => {
    if (data) {
      const { latitude, longitude, name, type, address } = data;
      setShowPopup({ latitude, longitude, name, type, address });
    } else {
      setShowPopup(null);
    }
  };

  useEffect(() => {
    if (viewportCoordinates.length < 2) return;

    const bound = getBoundsForPoints(viewportCoordinates);
    const { latitude, longitude, zoom } = bound;
    setViewport(prevState => ({
      ...prevState,
      ...flyToViewportOptions,
      latitude,
      longitude,
      zoom: zoom + 2,
      height,
      width
    }));
  }, [JSON.stringify(viewportCoordinates)]);

  useEffect(() => {
    if (getWidthHeight({ width, height })) {
      setViewport(prevState => ({
        ...prevState,
        width: fullScreen ? "100%" : width,
        height: fullScreen ? height : mapHeight
      }));
    }
  }, [width, height]);

  const [activeDriver, setActiveDriver] = useState(
    getDriverSchedule({ driverSchedule })
  );
  let { data: driverData, loading: driversLoading } = useQuery(GetAllDrivers, {
    fetchPolicy: "no-cache",
    variables: { id: projectId || undefined },
    skip: isHelpTutorial
  });

  // for when a user clicks on a car, get all schedules

  let { data: driverScheduleData, loading: driverScheduleLoading } = useQuery(
    GetDriverScheduleByDriverId,
    {
      fetchPolicy: "no-cache",
      variables: { id: activeDriver?.id },
      skip: !activeDriver?.id
    }
  );

  if (isHelpTutorial) {
    driverData = mockDriverData;
  }

  let { data, loading } = useQuery(GetAllLastLocationsForDrivers, {
    fetchPolicy: "no-cache",
    // variables: { id: id }
    skip: isHelpTutorial
  });

  if (isHelpTutorial) {
    data = mockData;
  }

  let driverLocationData = [];

  if (getIsLoading({ loading, driversLoading })) {
    driverLocationData = formatLocationHistory(data, driverData);
  }
  const filteredDriverLocationData =
    (searchKey &&
      _.filter(driverLocationData, driver =>
        _.includes(
          _.pick(driver, ["firstName", "lastName", "phone"]),
          searchKey
        )
      )) ||
    driverLocationData;

  return (
    <>
      {loading && <Loading fullPage />}
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        {filterActions && (
          <Box
            position="absolute"
            top="16px"
            left="16px"
            right="16px"
            zIndex={5}
          >
            {filterActions}
          </Box>
        )}

        {!noTracking && (
          <Slide
            direction="right"
            in={activeDriver?.id}
            mountOnEnter
            unmountOnExit
            style={{
              position: isMobileView ? "fixed" : "absolute",
              bottom: isMobileView ? "0" : "",
              padding: isMobileView ? "0px" : "",
              top: isMobileView && "0",
              zIndex: 1200,
              maxHeight: isMobileView ? "100%" : "calc(100% - 56px)",
              display: "flex",
              height: isMobileView ? "100%" : "auto",
              alignItems: isMobileView && "flex-start",
              left: isMobileView && "0",
              right: isMobileView && "0",
              overflow: "auto"
            }}
            className="active-driver-popup"
          >
            <div>
              <ClickAwayListener
                onClickAway={() => {
                  if (!isActiveMarkerClicked) {
                    // setActiveDriver(null);
                  } else {
                    isActiveMarkerClicked = false;
                  }
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: isMobileView ? "100%" : "calc(100% - 56px)",
                    maxHeight: isMobileView ? "100%" : "calc(100% - 56px)",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                >
                  {(isDispatchPage || isBookingPage) && (
                    <BookingDetail
                      isDriverScheduled={
                        !_.isEmpty(
                          driverScheduleData?.driverScheduleCurrentUpcoming
                            ?.driverschedules
                        )
                      }
                      expanded={_.isEmpty(
                        driverScheduleData?.driverScheduleCurrentUpcoming
                          ?.driverschedules
                      )}
                      isTrackRide={isTrackRide}
                      data={{
                        ...driverScheduleData?.driverScheduleCurrentUpcoming
                          ?.driverschedules[0]?.passengerschedule,
                        driver:
                          driverScheduleData?.driverScheduleCurrentUpcoming
                            ?.driverschedules[0] && activeDriver,
                        passengerScheduleCancelled:
                          driverScheduleData?.driverScheduleCurrentUpcoming
                            ?.driverschedules[0]?.passengerschedule?.cancelled,
                        ridestatuses:
                          driverScheduleData?.driverScheduleCurrentUpcoming
                            ?.driverschedules[0]?.ridestatuses
                      }}
                      history={history}
                      loading={driverScheduleLoading}
                      onClose={() => setActiveDriver(null)}
                      isCompactView
                      projectId={projectId}
                      isDispatchPage={isDispatchPage}
                    />
                  )}

                  {isTrackRide && (
                    <BookingDetail
                      bookingUpdatesLoading={bookingUpdatesLoading}
                      isDriverScheduled={!_.isEmpty(trackDriverScheduleData)}
                      expanded={_.isEmpty(trackDriverScheduleData)}
                      isTrackRide={isTrackRide}
                      data={{
                        ...trackDriverScheduleData.passengerschedule,
                        ...trackDriverScheduleData,
                        passengerScheduleId:
                          trackDriverScheduleData.passengerschedule.id,
                        vehicle: trackDriverScheduleData?.vehicle,
                        driver: trackDriverScheduleData.driver,
                        passengerScheduleCancelled:
                          trackDriverScheduleData?.passengerschedule?.cancelled,
                        ridestatuses: trackDriverScheduleData?.ridestatuses
                      }}
                      history={history}
                      onClose={() => setActiveDriver(null)}
                      isCompactView
                      projectId={projectId}
                    />
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </Slide>
        )}
        <ReactMapGL
          {...viewport}
          mapboxApiAccessToken="pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA"
          onViewportChange={
            props.trackedVehicle
              ? () => {}
              : nextViewport => setViewport(nextViewport)
          }
        >
          {activeDriver?.id && <RouteHistory driverId={activeDriver?.id} />}

          {driverSchedule?.ridestatuses
            .filter(x => x?.location?.coords)
            .map((marker, index) => (
              // return;

              <LocationMarker
                key={index}
                lat={marker.location.coords.latitude}
                long={marker.location.coords.longitude}
              />
            ))}

          {locationsData?.map((location, index) => {
            return (
              <>
                <LocationMarker
                  icon={Pin}
                  key={index}
                  lat={location.lat}
                  long={location.long}
                  name={location.name}
                  type={location.type}
                  address={location.address}
                  onClick={handleOnClick}
                />
                {showPopup && (
                  <Popup
                    latitude={showPopup.latitude}
                    longitude={showPopup.longitude}
                    anchor="top"
                    offsetTop={20}
                  >
                    <Typography customVariant="bodySmallRegular">
                      <strong>
                        {showPopup.name}
                        {showPopup.type ? ` - ${showPopup.type}` : ""}
                      </strong>
                      <br />
                      {showPopup?.address || ""}
                    </Typography>
                  </Popup>
                )}
              </>
            );
          })}

          {/*
        <DirectionsRoute
          stops={[
            [15.7349174, 46.5038033],
            [15.5828533, 46.4830978],
            [15.6349744, 46.4328782],
            [15.4331735, 46.4264754]
          ]}
        /> */}

          {getRideStatues({ driverSchedule }) ? (
            <></>
          ) : (
            <>
              {filteredDriverLocationData.map((driver, index) => (
                <ActiveMarker
                  key={index}
                  lat={driver.lat}
                  long={driver.long}
                  heading={driver.h}
                  id={driver.id}
                  vehicleType={
                    driver?.assignedVehicles[0]?.vehicle?.vehicleClass?.type
                  }
                  label={driver?.assignedVehicles[0]?.vehicle?.vehicleNumber}
                  driver={driver}
                  socket={socket}
                  setViewport={setViewport}
                  viewport={viewport}
                  setActivePopover={() => {
                    isActiveMarkerClicked = true;
                    setTimeout(() => {
                      setActiveDriver(driver);
                    });
                  }}
                />
              ))}
            </>
          )}
        </ReactMapGL>
      </div>
    </>
  );
}
